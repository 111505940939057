import { action, makeObservable, observable } from 'mobx'

export default class HouseholdStore {
  @observable isLoading = false
  @observable isOpened = false


  constructor() {
    makeObservable(this)
  }

  @action.bound
  toggle(state = undefined) { this.isOpened = state === undefined ? !this.isOpened : state }
}
