import { prop, uniqBy } from 'ramda'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import FlipMove from 'react-flip-move'
import { useTranslate } from 'react-redux-multilingual'
import TooltipD from '../Tooltip'
import { Plus } from './filterControl'

const styles = {
  tooltip: { bottom: '0', right: '0' },
}

const isInclude = (value: string | null | undefined, searchString: string): boolean => {
  if(value) {
    return value.toLowerCase().includes(searchString)
  }

  return false
}

type ElementType = {
  id: number
  name: string
  imei: number
  typeName?: string
}

type MultiItemProps = {
  name: string
  result: {id: number}[]
  units?: ElementType[]
  onStartFilter: (section: string, item: ElementType, solo?: boolean) => void
}

const MultiItem: FC<MultiItemProps> = ({ name, result, units = [], onStartFilter }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className="filter-parameter__multy">
      <div className="filter-parameter__multy-title" onClick={toggleOpen}>
        <div className="filter-parameter__multy-main">
          <p>{name}</p>
        </div>
        {open ? <div className="mylti-open"/> : <div className="mylti-close"/>}
      </div>
      {open &&
        <div className="filter-parameter__multy-wrap">
          <FlipMove
            staggerDelayBy={10}
            appearAnimation={{
              from: { opacity: '0.1', transform: 'translateX(-100%)' },
              to  : { transform: '' },
            }}
          >
            {units.map(item =>
              <MultiItemChildren
                {...item}
                result={result}
                key={item.id}
                onStartFilter={onStartFilter}
              />,
            )}
          </FlipMove>
        </div>
      }
    </div>
  )
}

type MultiItemChildrenProps = {
  result: {id: number}[]
  onStartFilter: (section: string, item: ElementType, solo?: boolean) => void
} & ElementType

// react-flip-move фигово работает с функциональными компонентами, поэтому откатил обратно на класс
class MultiItemChildren extends React.Component {
  // @ts-ignore-error
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
    }

    this.setChecked = this.setChecked.bind(this)
  }

  setChecked() {
    this.props.onStartFilter('machine', { id: this.props.id, name: this.props.name, imei: this.props.imei }, true)
  }
  
  componentWillMount(){
    if(this.props.result.find(param => param.id === this.props.id)){
      this.setState({ checked: true })
    } else {
      this.setState({ checked: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.result !== this.props.result){
      if(nextProps.result.find(param => param.id === this.props.id)){
        this.setState({ checked: true })
      } else {
        this.setState({ checked: false })
      }
    }
  }

  render() {
    return (
      <div className="filter-parameter__item">
        <input
          type="radio"
          id={this.props.id}
          checked={this.state.checked}
          readOnly
        />
        <label
          htmlFor={this.props.id}
          onClick={() => this.setChecked()}
        ><font>{this.props.name}</font></label>
      </div>
    )
  }
}


type ItemType = {
  id: number
  name: string
  units: ElementType[]
}

type FilterItemMultiRadioProps = {
  name: string
  items: ItemType[]
  result: {id: number}[]
  onStartFilter: (section: string, item: ElementType, solo?: boolean) => void
}

export const FilterItemMultiRadio: FC<FilterItemMultiRadioProps> = ({ name, items, result, onStartFilter }) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState<ElementType[]>([])
  const rootRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value)

    if(value) {
      const searchText = value.toLowerCase()

      const list = items.reduce((acc: ElementType[], curent: ItemType): ElementType[] => {
        return [
          ...acc,
          ...curent.units.filter(({ typeName, name: elementName }) =>
            isInclude(typeName, searchText) ||
            isInclude(elementName, searchText) ||
            isInclude(curent.name, searchText)),
        ]}, [])

      setSearchList(uniqBy(prop('id'), list))
    }
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(open && (!rootRef.current || !rootRef.current.contains(event.target as Node))) {
      setOpen(false)
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  return (
    <div className="filter-parameter">
      <p
        tabIndex={0}
        role="button"
        onClick={toggleOpen}
      >
        <span>{name}</span>
        <Plus/>
      </p>
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {open &&
        <div
          className="filter-parameter__open multy"
          ref={rootRef}
        >
          <div className="filter-parameter__wrap-multy radio-list">
            <input
              className="filter-parameter__input"
              type="text"
              placeholder={translate('search')}
              value={search}
              onChange={onSearch}
            />
            {search && searchList.length ?
              <div style={{ marginTop: '10px' }}>
                {searchList.map(item =>
                  <MultiItemChildren
                    {...item}
                    result={result}
                    key={item.id}
                    onStartFilter={onStartFilter}
                  />,
                )}
              </div>
              : search && !searchList.length ?
                <p style={{ marginBottom: '-10px' }}>{translate('not_of_data')}</p>
                : items.map(item =>
                  <MultiItem
                    result={result}
                    {...item}
                    key={item.id}
                    onStartFilter={onStartFilter}
                  />,
                )}
          </div>
        </div>
        }
      </ReactCSSTransitionGroup>
      <TooltipD text={translate('Select one unit of equipment')} style={styles.tooltip}/>
    </div>
  )
}
