import { CheckBox, icons } from '@agdt/agrotronic-react-components'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SingleCheckbox } from '../../../SingleCheckbox'

const { ArrowUpIcon, ArrowDownIcon } = icons

export type TProps = {
  data: {
    farmId: number
    id: number
    isLeaf: boolean
    checked: boolean
    name: string
    unit: string
    setMachines: (farmId: number) => void
    setSingleMachines: (unit: string) => void
  }

  resize: (newHeight: number, shouldForceUpdate?: boolean) => void
  isOpen: boolean
  height: number
  style: { height: number, width: number }
  toggle: () => void
}

export default function Node({
  data,
  resize,
  isOpen,
  height,
  style,
  toggle,
}: TProps) {
  const divRef = useRef<HTMLDivElement>(null)

  const checkAll = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    data.setMachines(data.farmId)
  }

  const toggleHandler = useCallback(e => {
    e.stopPropagation()
    toggle()
  }, [toggle])

  useEffect(() => {
    if(divRef.current && divRef.current.clientHeight) {
      const divHeight = divRef.current.clientHeight

      if(divHeight > 34) {
        resize(Math.max(height, divHeight), true)
      }
    }
  }, [data.name, height])

  return (
    <div style={{ ...style, marginLeft: data.isLeaf ? 10 : 0 }}>
      {!data.isLeaf
        ? <MultiItemContainer isOpen={isOpen} ref={divRef}>
          <MultiItemTitle onClick={toggleHandler} >
            <CheckBoxWrapper>
              <CheckBox checked={data.checked} onChange={checkAll} semiChecked />
              <CheckBoxLabel>{data.name}</CheckBoxLabel>
            </CheckBoxWrapper>

            <IconWrapper>{isOpen ? <ArrowUpIcon/> : <ArrowDownIcon/>}</IconWrapper>
          </MultiItemTitle>
        </MultiItemContainer>

        : <SingleCheckbox
          value={data.unit}
          name={data.name}
          key={data.id}
          checked={data.checked}
          setChecked={data.setSingleMachines}
        />
      }
    </div>
  )
}

const MultiItemContainer = styled.div<{ isOpen?: boolean }>`
  border-bottom: ${({ isOpen }) => isOpen ? 'none' : '1px solid rgba(32, 32, 32, 0.1)'};
  padding: 10px 0;
  overflow: hidden;
`

const MultiItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  padding-right: 10px;
  cursor: pointer;
`

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 85%;
`

const IconWrapper = styled.span`
  min-width: 24px;
`

const CheckBoxLabel = styled.span`
  max-width: 125px;
  word-break: break-word;
`
