import { TProps as TWidgetProps, Widget } from 'components/Widget'
import { HeaderElement } from 'components/Widget/Header'
import ResizeButton from 'components/Widget/Header/ResizeButton'
import { TitleAndCounter, TitleContainer } from 'components/Widget/Header/Title'
import SystemContextMenu from 'components/Widget/SystemContextMenu'
import { observer } from 'mobx-react'
import { filter, pipe, propOr, repeat, sum, values } from 'ramda'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { useWidgetResize } from '../common/hooks'
import { TUseWidgetResizeProps } from '../common/hooks/useWidgetResize'
import { NotAvailableMessage } from '../common/NotAvailableMessage'
import NoteMessage from '../common/NoteMessage'

type TProps = {
  className: string
  config: {
    isResizable: boolean
  }

  onClose: () => void
} & TWidgetProps & TUseWidgetResizeProps

function SquareInfoWidget({ className, changeConfig, style, onClose, config, ...restProps }: TProps) {
  const { isCompact, toggleSize } = useWidgetResize(config, changeConfig)
  const t = useTranslate()
  const summary = getStore('summary')

  const items = useMemo(() =>

    /*@ts-expect-error*/
    pipe(propOr([], 'SUM_HARVESTING'), filter(item => item > 0))(summary.value),[summary.value],
  )

  const total = useMemo(() => pipe(values, sum)(items), [items])

  return (
    <Widget
      className={className}
      style={style}
      {...restProps}
      notAvailable={summary.dataLoadingError}
    >
      <>
        <HeaderStyled
          isCompact={isCompact}
          isSubtitle={!isCompact}
          notAvailable={summary.dataLoadingError}
        >
          <TitleContainer className="draggable" >
            <TitleAndCounter>
              <TitleCaptionStyled>{t('Combine Harvesters')}</TitleCaptionStyled>
            </TitleAndCounter>

            {!isCompact && <SubtitleStyled notAvailable={summary.dataLoadingError}>
              {t('Total amount of cultivated area')}
            </SubtitleStyled>}
          </TitleContainer>

          <ResizeButton
            toggle={toggleSize}
            isCompact={isCompact}
            iconColor={summary.dataLoadingError ? '#A1A1A1' : 'white'}
          />

          <SystemContextMenu onClose={onClose} iconColor={summary.dataLoadingError ? '#A1A1A1' : 'white'} />
        </HeaderStyled>

        {summary.dataLoadingError
          ? <NotAvailableMessage text={t('service is not available')}/>
          : <>
            <BigCounter isCompact={isCompact}>{ total.toFixed(2) } {t('ha')}</BigCounter>
            {!isCompact && <NoteMessageStyled iconColor="#ffffff" />}

            {!isCompact && <CultureTable>
              { Object
                .entries(items)

                /*@ts-expect-error*/
                .concat(repeat(['—', '0'], Math.max(10 - (items.length || 0), 0)))

                .map(([name, value], index) => <Culture

                  key={index}

                  //         Do now show underline border at the least row and show underline only if data exists
                  {...!name || !((index+1) % 5) ? { isLast: true } : {}}
                >
                  <CultureTitle>{name}</CultureTitle>
                  <CultureValue>{(Number(value).toFixed(2) + ' ' + t('ha'))}</CultureValue>
                </Culture>,
                ) }
            </CultureTable>}
          </>}
      </>
    </Widget>
  )
}

export default observer(SquareInfoWidget)

const HeaderStyled = styled(HeaderElement)<{ notAvailable: boolean }>`
  background: ${({ notAvailable }) => notAvailable ? 'none' : '#51B5F3'};
  color: ${({ notAvailable }) => notAvailable ? '#202020' : 'white'};
  
  border-bottom: ${({ isCompact, notAvailable }) => isCompact
    ? 'none'
    : `1px dashed ${ notAvailable ? '#202020' : 'white' }`};
`

const TitleCaptionStyled = styled.h3`
  /* Bold 14 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
`

const SubtitleStyled = styled.h4<{ notAvailable: boolean }>`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  color: ${({ notAvailable }) => notAvailable ? '#A1A1A1' : 'white'};
`

const BigCounter = styled.div<{ isCompact: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: ${({ isCompact }) => isCompact ? '85px' : '70px'};
  background: #51B5F3;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;

  padding-left: 12px;
`

const NoteMessageStyled = styled(NoteMessage)`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;

  background: #47A4DD;
  margin: 0;
  padding-left: 10px;

  height: 28px;
`

const CultureTable = styled.div`
  background: #323232;
  width: 100%;
  height: 141px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 6px;
`

const Culture = styled.div<{ isLast?: boolean }>`
  display: flex;
  min-width: calc(50% - 20px);
  height: 19px;

  border-bottom: ${({ isLast }) => isLast ? 'none' : '1px dashed rgba(255, 255, 255, 0.5)'};

  margin-left: 10px;
  margin-right: 10px;
  margin-top: 4px;
`

const CultureTitle = styled.div`
  flex-grow: 1;

  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* Color 9 */
  color: #FFFFFF;

`

const CultureValue = styled.div`
  /* Bold 10 */
  font-style: normal;
  font-weight: bold;
  font-size: 10px;

  /* identical to box height, or 160% */
  display: flex;
  align-items: center;
  color: #FFFFFF;

  opacity: unset;
`
