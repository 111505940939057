import { Input, Spinner } from '@agdt/agrotronic-react-components'
import React, { FC, useCallback } from 'react'
import { TTranslate } from 'types'

type Props = {
  dataIsSave?: boolean
  error: boolean
  disabled: boolean
  helperText: string
  disableClear?: boolean
  required?: boolean
  label: string
  testid: string
  value: string
  initValue?: string
  t: TTranslate
  handleOnBlur: () => void
  setValidateError: (value: string) => void
  setValue: (value: string | boolean | undefined) => void
  validator?: (value: string) => string | undefined
  onEnter?: () => void
  postProcessValue?: (arg: string) => string
}

export const ValidateInput: FC<Props> = ({
  dataIsSave,
  disableClear,
  disabled,
  error,
  handleOnBlur,
  helperText,
  initValue,
  label,
  onEnter,
  postProcessValue,
  required,
  setValidateError,
  setValue,
  t,
  testid,
  validator,
  value,
}) => {
  const handleValidate = useCallback((fieldValue: string) => {
    const validatorError = validator && fieldValue !== initValue && validator(fieldValue)
    setValidateError(typeof validatorError === 'string' ? t(validatorError) : '')
  }, [initValue, setValidateError, t, validator])

  const handleOnChange = useCallback(fieldValue => {
    setValue(fieldValue)
    handleValidate(fieldValue)
  }, [])

  return <Input
    data-testid={`${testid}-input`}
    disableClear={disableClear}
    disabled={dataIsSave || disabled}
    error={error}
    helperText={helperText}
    icon={dataIsSave ? <Spinner color='#c3c3c3'/> : undefined}
    label={label}
    onBlur={handleOnBlur}
    onChange={handleOnChange}
    onEnter={onEnter}
    postProcessValue={postProcessValue}
    required={required}
    value={value}
  />
}
