import {
  Badge,
  HeaderRow,
  icons,
  SortableHeaderCell,
  Table as GenericTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@agdt/agrotronic-react-components'
import { ascend, clone, descend, find, groupBy, keys, pathOr, prop, sort } from 'ramda'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { DetailsImportToExcel } from './DetailsImportToExcel'
import { DownloadExcelButton } from './DownloadExcelButton'

const { CloseIcon } = icons

type Unit = {
  model: string
  number: number
  uploads: string
  id: string
}

type UnloadingResultProps = {
  units: Record<string, Unit>
}

type Settings = {
  id: string
  title: string
  sort: 'asc' | 'desc' | undefined
}

export const UnloadingResult: FC<UnloadingResultProps> = ({ units }) => {
  const t = useTranslate()

  const [settings, setSettings] = useState<Settings[]>([
    { id: 'model', sort: 'asc', title: t('osht') },
    { id: 'uploads', sort: undefined, title: t('num of unloading') },
  ])

  const toggleSort = useCallback((id, order) => {
    const newSettings = clone(settings)

    newSettings.map(s => s.id === id ? s.sort = order : s.sort = undefined)
    setSettings(newSettings)
  }, [settings])

  const sortedUnits = useMemo(() => {
    const valuesUnits = Object.values(units)

    // @ts-expect-error
    const sortField = find(prop('sort'), settings)

    if(!sortField) { return valuesUnits }

    const sortFieldId = prop('id', sortField)
    const sortFieldDirection = prop('sort', sortField)
    const byProp = prop(sortFieldId)

    return sort(
      sortFieldDirection === 'asc' ? ascend(byProp) : descend(byProp),
      valuesUnits,
    )
  }, [settings, units])

  const [shownUnitDetails, setShownUnitDetails] = useState<Unit | null>(null)

  const preparedDetailsReport: Record<string, Unit[]> = useMemo(() => {
    if(shownUnitDetails) {
      // @ts-expect-error
      return groupBy(prop('agriculture'))(pathOr([], [shownUnitDetails.id, 'details'], units))
    }

    return {}
  }, [shownUnitDetails, units])

  return (
    <TableWrapper>
      <StyledGenericTable>
        <StyledTableHeader>
          <StyledHeaderRow apperance={'large'}>
            {settings.map(column =>
              <StyledSortableHeaderCell
                id={column.id}
                key={column.id}
                onChangeSort={toggleSort}
                sort={column.sort}
              >
                {column.title}
              </StyledSortableHeaderCell>)}

            <ExcelButtonWrapper>
              <DownloadExcelButton items={sortedUnits} resultFormat={settings} />
            </ExcelButtonWrapper>
          </StyledHeaderRow>
        </StyledTableHeader>

        <TableBody>
          {sortedUnits.map(unit => <TableRow
            key={unit.id}
            onClick={() => setShownUnitDetails(unit)}
            apperance={'large'}
          >
            <StyledTableCell>
              <UnitModel>{unit.model}</UnitModel>
              <UnitNumber>{unit.number}</UnitNumber>
            </StyledTableCell>
            <StyledTableCell>
              {unit.uploads}
            </StyledTableCell>
          </TableRow>,
          )}
        </TableBody>
      </StyledGenericTable>

      {shownUnitDetails && <DetailsTableWrapper>
        <TableTitle>
          <TitleUnit>
            <TitleUnitModel>
              {shownUnitDetails.model}
            </TitleUnitModel>
            <TitleUnitNumber>
              {shownUnitDetails.number}
            </TitleUnitNumber>
          </TitleUnit>

          <DetailsImportToExcel
            unit={`${shownUnitDetails.model} (${shownUnitDetails.number})`}
            items={preparedDetailsReport}
          />

          <StyledCloseIcon onClick={() => setShownUnitDetails(null)} >
            <CloseIcon color='#000000' size={24}/>
          </StyledCloseIcon>
        </TableTitle>

        <DetailsTable>
          <table>
            <tbody>
              {keys(preparedDetailsReport).map(culture => <React.Fragment key={culture}>
                <StyledTr>
                  <StyledTh>
                    {culture}
                  </StyledTh>
                </StyledTr>
                {preparedDetailsReport[culture].map(transport => <StyledTr key={`${transport.model}-${culture}`}>
                  <StyledTd>
                    <UnitModel>{
                      JSON.stringify(transport.model) || t('Unknown transport')
                    }<StyledBadge count={transport.uploads} size={10} /></UnitModel>
                    <UnitNumber>{transport.number}</UnitNumber>
                  </StyledTd>
                </StyledTr>)}
              </React.Fragment>)
              }
            </tbody>
          </table>
        </DetailsTable>
      </DetailsTableWrapper>}
    </TableWrapper>
  )
}

const ExcelButtonWrapper = styled(TableCell)`
  position: absolute;
  right: 24px;
`

const StyledTr = styled.tr`
  border-left: 1px solid #e6e6e6;
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -3px;
  padding-left: 5px;

  & > div {
    width: fit-content;
    height: 16px;
    padding: 0 5px;
    color: #ffffff;
    font-size: 10px;
    line-height: 17px;
    white-space: nowrap;
    text-align: center;
    border-radius: 8px;
  }
`

const StyledGenericTable = styled(GenericTable)`
  grid: none;
`

const TableTitle = styled.div`
  height: 80px;
  position: relative;
  border-left: 1px solid #e6e6e6;
`

const TitleUnit = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 15px;
`

const TitleUnitModel = styled.div`
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
`

const TitleUnitNumber = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #7d7d7d;
`

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
`

const StyledTableHeader = styled(TableHeader)`
  background: #ffffff;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
`

const DetailsTableWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  width: 484px;
  background: #ffffff;
`

const DetailsTable = styled.div`
  overflow-y: auto;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
    width: 5px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #D10B41;
  }
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
`

const TableWrapper = styled.div`
  position: relative;
`

const UnitModel = styled.div`
  position: relative;
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
`

const UnitNumber = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
`

const StyledSortableHeaderCell = styled(SortableHeaderCell)`
  font-size: 14px;
  text-transform: uppercase;
  max-width: 46em;

  & span {
    color: #000000;
    font-size: 14px;
  }
`

const StyledTableCell = styled(TableCell)`
  max-width: 46em;
  flex-direction: column;
  align-items: start;
`

const StyledHeaderRow = styled(HeaderRow)`
  width: 100%;
  position: relative;
`

const StyledTh = styled.th`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 44px;
  background: #F3F3F3;
  text-transform: uppercase;
  padding-left: 24px;
`

const StyledTd = styled(TableCell)`
  display: flex;
  justify-content: center;
  height: 60px;
  padding-left: 24px;
  flex-direction: column;
  align-items: start;
`
