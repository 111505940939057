import { Spinner, TabContent, TabItem } from '@agdt/agrotronic-react-components'
import { Map, Record } from 'immutable'
import { observer } from 'mobx-react'
import { flip, isEmpty, path, toPairs } from 'ramda'
import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { clearReport, pushReportByPath } from 'reducers/reports/actions'
import { getAllAggregates, getTargets2, getVehicleEquipment } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import styled, { css } from 'styled-components'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import { useUserTimezone } from 'hooks'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import EquipmentResultTable from './ResultTable'

type TReport = Map<'load', boolean> & Map<'report', object> & Map<'vehicleReport', object>

type TProps = FilterControlConfig & {users: unknown}

type TVehicle = {
  equipment: number
  work: number
  area: number
  details: unknown[]
  imei: unknown
  name: string
}

type TVehicleEquipment = Record<string, TVehicle[]>

type TUnit = {
  area: number
  work: number
  details: unknown[]
  id: unknown
  imei: unknown
  model: string
  modelName?: string
  name: string
  number: string
}

type TAggregateUnit = TUnit & {
  code: number
  modelName: string
  modelTypeName: string
}

const styles = {
  wrapCenter: { display: 'flex', justifyContent: 'center' },
  button    : {
    marginBottom: '10px',
    background  : '#78C800',
    border      : '1px solid #78C800',
  },
}

const ReportEquipment: FC<TProps> = props => {
  const t = useTranslate()
  const report = useSelector((state: AppStateType) => state.reports.get('equipment') as TReport) // todo избавиться от immutablejs
  const period = props.filter?.[1]?.result?.[0]

  const tabs = useMemo(() => [
    { id: 'sht', label: t('sht') },
    { id: 'aggregates', label: t('aggregates') },
  ], [t])

  const [currentTab, setCurrentTab ] = useState(tabs[0])

  const filterIsEmpty = useMemo(() =>
    isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items), [props.filter, props.users],
  )

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={t('Select a different report or change the filtering conditions')}
        title={t('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          disableButton={report.get('load')}
          translate={t}
          {...props}
        />

        {report.get('load') &&
      <div style={styles.wrapCenter}>
        <Spinner />
      </div>}

        {report.get('report') && !isEmpty(report.get('report')) && <>
          <TabsContainer>
            {tabs.map(tab =>
              <StyledTabItem
                active={tab.id === currentTab.id}
                key={tab.id}
                onSelect={setCurrentTab}
                tab={tab}
                noBottomBorder={true}
              />,
            )}
          </TabsContainer>

          <StyledTabContent>
            {currentTab.id === tabs[0].id && report.get('report') && period && <EquipmentResultTable
              period={period}
              units={report.get('report')}
            />}

            {currentTab.id === tabs[1].id && report.get('vehicleReport') && period && <EquipmentResultTable
              period={period}
              reportName='vehicleReport'
              units={report.get('vehicleReport')}
            />}
          </StyledTabContent>
        </>}

        {isEmpty(report.get('report')) && !report.get('load') && <NoDataWrapper>{t('no data')}</NoDataWrapper>}
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      id      : 0,
      items   : [],
      name    : 'machine',
      result  : [],
      section : 'machine',
      type    : 'checkbox-machine',
      validate: true,
    },
    {
      id      : 2,
      name    : 'select_a_date',
      result  : [],
      section : 'time',
      type    : 'date',
      validate: true,
    },
  ],

  callback: data => async dispatch => {
    dispatch(pushReportByPath({ data: true, reportPath: ['equipment', 'load'] }))
    const { getDateWithUserTimeZone } = useUserTimezone()

    try {
      const units = await getTargets2({ registers: ['TYP_HARVESTER_NUMBER'] })
      const equipment = await getAllAggregates<void, TAggregateUnit[]>()

      const vehiclesEquipment = await getVehicleEquipment<Record<string, unknown>, TVehicleEquipment>({
        from: getDateWithUserTimeZone(Date.parseDateTimeFromCalendarApi(data.time[0].from)),
        id  : data.machine.map(({ imei }) => Number(imei)),
        to  : getDateWithUserTimeZone(Date.parseDateTimeFromCalendarApi(data.time[0].to)),
      }) || {}

      const groupedEquipment = equipment?.reduce<Record<number, TAggregateUnit>>((acc, item) => {
        if(item.code) { acc[item.code] = item }

        return acc
      },{})

      const report = Object.entries(vehiclesEquipment).reduce<Record<string, TUnit>>((acc, [imei, vehicles]) => {
        for(const vehicle of vehicles) {
          if(!vehicle.equipment || vehicle.equipment === -1) { continue }

          const unit: TUnit = {
            ...vehicle,
            id       : vehicle.equipment,
            model    : groupedEquipment?.[vehicle.equipment].modelTypeName || '-',
            modelName: groupedEquipment?.[vehicle.equipment].modelName || '-',
            number   : groupedEquipment?.[vehicle.equipment].name || '-',
          }

          // @ts-expect-error
          const flipPath: (params: object) => string = flip(path)(units[imei])

          if(acc[imei]) {
            acc[imei].work = acc[imei].work + vehicle.work
            acc[imei].area = acc[imei].area + vehicle.area
            acc[imei].details.push(unit)
          } else {
            acc[imei] = {
              area   : vehicle.area,
              details: [unit],
              id     : imei,
              imei   : imei,
              model  : flipPath(['model']),
              name   : flipPath(['name']),
              number : flipPath(['registers', 'TYP_HARVESTER_NUMBER']) || flipPath(['name']),
              work   : vehicle.work,
            }
          }
        }

        return acc
      }, {})

      dispatch(pushReportByPath({ data: report, reportPath: ['equipment', 'report'] }))

      const aggregateReport = toPairs(vehiclesEquipment).reduce<Record<number, TUnit>>((acc, [imei, vehicles]) => {
        for(const vehicle of vehicles) {
          if(!vehicle.equipment || vehicle.equipment === -1) { continue }

          // @ts-expect-error
          const getUnitParam = flip(path)(units[imei])

          const unit = {
            ...vehicle,
            id    : imei,
            imei  : imei,
            model : getUnitParam(['model']),
            name  : getUnitParam(['name']),
            number: getUnitParam(['registers', 'TYP_HARVESTER_NUMBER']) || getUnitParam(['name']),
          }

          if(acc[vehicle.equipment]) {
            acc[vehicle.equipment].work = acc[vehicle.equipment].work + vehicle.work
            acc[vehicle.equipment].area = acc[vehicle.equipment].area + vehicle.area
            acc[vehicle.equipment].details.push(unit)
          } else {
            acc[vehicle.equipment] = {
              area     : vehicle.area,
              details  : [unit],
              id       : path([vehicle.equipment, 'code'])(groupedEquipment),
              imei     : imei,
              model    : groupedEquipment?.[vehicle.equipment].modelTypeName || '-',
              modelName: groupedEquipment?.[vehicle.equipment].modelName || '-',
              name     : groupedEquipment?.[vehicle.equipment].name || '-',
              number   : groupedEquipment?.[vehicle.equipment].name || '-',
              work     : vehicle.work,
            }
          }
        }

        return acc
      }, {})

      dispatch(pushReportByPath({ data: aggregateReport, reportPath: ['equipment', 'vehicleReport'] }))
    } catch(e) {
      errorMessage(e)
    }

    dispatch(pushReportByPath({ data: false, reportPath: ['equipment', 'load'] }))
  },

  clear: () => dispatch => {
    dispatch(clearReport(ReportType.Equipment))
  },

  // @ts-expect-error
})(observer(ReportEquipment))

const NoDataWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888886;
`

const StyledTabContent = styled(TabContent)`
  border: none;
  border-right: 1px solid #e6e6e6;
  height: auto;
  overflow-y: unset;
  padding: 0;
  margin-top: 8px;
  background: none;
`

const StyledTabItem = styled(TabItem)`
  border: none;
  font-size: 16px;
  font-weight: 700;
  position: inherit;
  padding: 0;
  margin-right: 32px;
  cursor: pointer;
  background: none;

  ${({ active }) => active
    ? css`
      border-bottom: 2px solid #D10029;
      margin-top: 2px;
  ` : css`
      background-color: inherit;
      color: rgba(0, 0, 0, .36);
  `}

  > div{
    display: none;
  };
`

const TabsContainer = styled.ul`
  display: flex;
  flex: 1 0 50px;
  height: 50px;
  width: 100%;
  padding: 0 0 0 24px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
`
