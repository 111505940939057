import React from 'react'

const F1300Icon = ({ color='#202020', size = 20 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2581 10.9677C13.3272 10.9677 14.1936 11.8342 14.1936 12.9032C14.1936 13.9723 13.3272 14.8387 12.2581 14.8387C11.1891 14.8387 10.3226 13.9723 10.3226 12.9032C10.3226 11.8342 11.1891 10.9677 12.2581 10.9677ZM5.16135 10.9677C6.23039 10.9677 7.09684 11.8342 7.09684 12.9032C7.09684 13.9723 6.23039 14.8387 5.16135 14.8387C4.09232 14.8387 3.22587 13.9723 3.22587 12.9032C3.22587 11.8342 4.09232 10.9677 5.16135 10.9677ZM17.4194 14.8387H14.8388V12.2581C16.2639 12.2581 17.4194 13.4136 17.4194 14.8387ZM12.2581 5.80646H16.1291L15.4839 10.3226H12.2581C10.833 10.3226 9.67748 11.4781 9.67748 12.9032V14.1936H7.742V12.9032C7.742 11.4781 6.58651 10.3226 5.16135 10.3226C3.73619 10.3226 2.58071 11.4781 2.58071 12.9032H1.93555V10.3226L2.58071 9.03226L7.742 8.3871H10.3226L10.5994 6.45162H9.03232L9.12458 5.80646H10.691H11.613V6.45162V8.3871H12.2581V5.80646ZM5.16135 13.5484C5.51748 13.5484 5.80651 13.2594 5.80651 12.9032C5.80651 12.5471 5.51748 12.2581 5.16135 12.2581C4.80522 12.2581 4.51619 12.5471 4.51619 12.9032C4.51619 13.2594 4.80522 13.5484 5.16135 13.5484ZM12.2581 13.5484C12.6143 13.5484 12.9033 13.2594 12.9033 12.9032C12.9033 12.5471 12.6143 12.2581 12.2581 12.2581C11.902 12.2581 11.613 12.5471 11.613 12.9032C11.613 13.2594 11.902 13.5484 12.2581 13.5484Z" fill={color}/>
    </svg>
  )
}

export default F1300Icon
