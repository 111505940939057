import { MapActionsType,MapActionTypeEnum } from './actions'
import * as State from './state'

export default function map(state = State.init(), action: MapActionsType): State.Map {
  switch(action.type) {
    case MapActionTypeEnum.CENTER_POLYGON:
      return State.setPolygon(state, action.payload)

    default:
      return state
  }
}
