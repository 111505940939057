import Tippy from '@tippyjs/react'
import React, { FC } from 'react'
import { TTranslate } from 'types'
import TooltipD from 'ui/Tooltip'

type ControlTrackProps = {
  model: string
  id: number
  type: string
  showTypeHarvest: boolean
  showTypeLoad: boolean
  showTypeProductivity: boolean
  showTypeSpeed: boolean
  deleteTrack: () => void
  translate: TTranslate
  switchTypeTrack: (value: {type: string, id: number}) => void
}

export const ControlTrack: FC<ControlTrackProps> = ({
  id,
  model,
  type,
  switchTypeTrack,
  deleteTrack,
  showTypeSpeed,
  showTypeHarvest,
  showTypeLoad,
  showTypeProductivity,
  translate,
}) => {
  const onClick = (typeValue: string) => () => {
    switchTypeTrack({ id, type: typeValue })
  }

  return (
    <div className="tracking-machine__bt-wr">
      {showTypeHarvest &&
      <Tippy content={translate('Track by mode')}>
        <button
          onClick={onClick('TRACK_HARVEST')}
          className={
            type === 'TRACK_HARVEST'
              ? ' tracking-machine__bt bt7 active'
              : 'tracking-machine__bt bt7'
          }
        />
      </Tippy>
      }
      {showTypeSpeed &&
      <Tippy content={translate('Track by speed')}>
        <button
          onClick={onClick('TRACK_SPEED')}
          className={
            type === 'TRACK_SPEED'
              ? 'tracking-machine__bt t1 active'
              : 'tracking-machine__bt t1'
          }
        />
      </Tippy>
      }
      {showTypeLoad &&
      <Tippy content={translate('Track by load')}>
        <button
          onClick={onClick('TRACK_LOAD')}
          className={
            type === 'TRACK_LOAD'
              ? 'tracking-machine__bt t2 active'
              : 'tracking-machine__bt t2'
          }
        />
      </Tippy>
      }
      {showTypeProductivity &&
      <Tippy content={translate('Productivity')}>
        <button
          onClick={onClick(`TRACK_PRODUCTIVITY_${model}`)}
          className={
            type === `TRACK_PRODUCTIVITY_${model}`
              ? 'tracking-machine__bt bt9 active'
              : 'tracking-machine__bt bt9'
          }
        />
      </Tippy>
      }
      <Tippy content={translate('Delete track')}>
        <button
          onClick={deleteTrack}
          className={'tracking-machine__bt t3'}
        />
      </Tippy>
      <TooltipD
        text={translate('Track control panel (select track type, delete track)')}
        style={{ top: '-30px' }}
      />
    </div>
  )
}


