import cloneDeep from 'lodash.clonedeep'
import { ascend, filter, flatten, isEmpty, map, minBy, pick, pipe, prop, reduce, sortWith } from 'ramda'
import { createSelector } from 'reselect'

/*@ts-expect-error*/
export function getCourse(lngCurr, lngPrev, latCurr, latPrev) {
  let ret = 0
  let dx = (lngCurr - lngPrev) * Math.PI / 180

  let dy =
    Math.log(Math.tan(Math.PI / 4 + latCurr * Math.PI / 360)) -
    Math.log(Math.tan(Math.PI / 4 + latPrev * Math.PI / 360))

  if(dx || dy) {
    ret = 90 - Math.atan2(dy, dx) * 180 / Math.PI
    while(ret < 0) {ret += 360}
    while(ret >= 360) {ret -= 360}
    ret = Math.round(ret)
  }

  return ret
}

export const machinesSelector = createSelector(

  /*@ts-expect-error*/
  (newMachinesMap, _oldMachinesMap) => {
    return newMachinesMap
  },

  /*@ts-expect-error*/
  (_newMachinesMap, oldMachinesMap) => {
    return oldMachinesMap
  },
  (newMachinesMap, oldMachinesMap) => {
    if(!oldMachinesMap.length) {
      /*@ts-expect-error*/
      newMachinesMap = newMachinesMap.map(item => {
        return {
          ...item,
          isOpened: false,
        }
      })

      return newMachinesMap
    }

    const res = oldMachinesMap

    /*@ts-expect-error*/
      .filter(U => newMachinesMap.find(N => N.id === U.id))

      /*@ts-expect-error*/
      .map(prevUser => {
        const nextUser = newMachinesMap.find(

          /*@ts-expect-error*/
          item => item.id === prevUser.id,
        )

        if(nextUser) {
          /*@ts-expect-error*/
          prevUser.units.units = nextUser.units.units.map(nextUnit => {
            let course

            const prevUnit = prevUser.units.units.find(

              /*@ts-expect-error*/
              item => item.id === nextUnit.id,
            )

            if(prevUnit) {
              if(
                nextUnit.data.LATITUDE &&
                nextUnit.data.LONGITUDE &&
                prevUnit.data &&
                prevUnit.data.LONGITUDE &&
                prevUnit.data.LATITUDE
              ) {
                course = getCourse(
                  nextUnit.data.LONGITUDE.value,
                  prevUnit.data.LONGITUDE.value,
                  nextUnit.data.LATITUDE.value,
                  prevUnit.data.LATITUDE.value,
                )
              }

              prevUnit.data = nextUnit.data
              prevUnit.course = course
            }

            return !prevUnit
              ? nextUnit

              : prevUnit
          })
        }

        return prevUser
      })

    /*@ts-expect-error*/
    const newUsers = newMachinesMap.filter(N => !oldMachinesMap.find(U => N.id === U.id))

    return newUsers && newUsers.length > 0 ? res.concat(newUsers) : res
  },
)

export const filteredUsersOfType = createSelector(
  state => {
    /*@ts-expect-error*/
    return state.users.get('users')
  }, users => {
    return cloneDeep(users.toJS())
  },
)

const getSearchingHouseholds = createSelector(

  /*@ts-expect-error*/
  [ filteredUsersOfType, state => state.users.get('searchHousehold') ],
  (machines, search) => {
    if(!search) {
      return machines
    }

    /*@ts-expect-error*/
    return cloneDeep(machines).filter(user => {
      if(user.name.toLowerCase().includes(search.toLowerCase())) {
        return true
      }

      /*@ts-expect-error*/
      const findUnit = user.units.ownedUnits.filter(unit =>
        unit.name.toLowerCase().includes(search.toLowerCase()),
      )

      if(findUnit.length) {
        user.units.ownedUnits = findUnit
        return true
      }

      return false
    })
  },
)

/*@ts-expect-error*/
function pad(num, size) {
  var s = '0000' + num
  return s.substr(s.length-size)
}

export const getServiceData = createSelector(
  [filteredUsersOfType],
  filteredUsers => {
    const serviceParams = ['TO_250', 'TO_500', 'TO_1000', 'TO_1500']

    return pipe(

      /*@ts-expect-error*/
      map(U => U.units.units),
      flatten,

      map(U => {
        /*@ts-expect-error*/
        const data = pick(serviceParams)(U.data)

        if(isEmpty(data)) {
          /*@ts-expect-error*/
          return { ...U, serviceType: undefined, serviceValue: Infinity }
        }

        /*@ts-expect-error*/
        const [serviceTypeLabel, value] = pipe(
          Object.entries,
          map(([K, V]) => [K, V.value || Infinity]),

          /*@ts-expect-error*/
          reduce(minBy(([K, V]) => V), ['', Infinity]),
        )(data)

        /*@ts-expect-error*/
        return { ...U, serviceType: serviceTypeLabel, serviceValue: value }
      }),

      /*@ts-expect-error*/
      filter(U => U.serviceValue <= 30),

      sortWith([
        ascend(prop('typeName')),

        ascend(({ serviceType, serviceValue }) => {
          return -serviceParams.indexOf(serviceType) + '_' + pad(serviceValue.toFixed(0), 4)
        }),
      ]),
    )(filteredUsers)
  },
)

export const getStatusesModelsHouseholds = createSelector(
  [ getSearchingHouseholds ],
  users => users.reduce(

    /*@ts-expect-error*/
    (result, user) => {
      /*@ts-expect-error*/
      user.units.ownedUnits.map(unit => {
        /*@ts-expect-error*/
        if(!result.models.some(item => item === unit.typeName)) {
          result.models.push(unit.typeName)
        }

        /*@ts-expect-error*/
        if(!result.statuses.some(item => item === unit.data.STATUS.valueF)) {
          result.statuses.push(unit.data.STATUS.valueF)
        }
      })

      return result
    },
    { models: [], statuses: [] },
  ),
)

export const getSimpifiedUsers = createSelector(
  users => {
    return users
  },
  users => {
    /*@ts-expect-error*/
    return users.reduce((list, user) => {
      /*@ts-expect-error*/
      const userTemp = user.units.ownedUnits.map(({ id, name, typeName, model, data, imei }) => {
        return { id, imei, model, name, status: data.STATUS, typeName }
      })

      if(userTemp.length) {list.push({ id: user.id, name: user.name, units: userTemp })}

      return list
    }, [])
  },
)

export const getListUnitsFromSimplifiedUsers = createSelector(
  users => {
    return users
  },
  users => {
    /*@ts-expect-error*/
    return users.reduce((list, user) => [...list, ...user.units], [])
  },
)

export const getStatusesAndModelsMachinesList = createSelector(
  units => {
    return units
  },
  units => {
    /*@ts-expect-error*/
    return units.reduce((result, unit) => {
      if(!result.statuses.includes(unit.status.valueF)) {
        result.statuses.push(unit.status.valueF)
      }

      if(!result.models.includes(unit.typeName)) {
        result.models.push(unit.typeName)
      }

      return result
    }, { models: [], statuses: [] })
  },
)

export const getFilteredStatusesModelsHouseholds = createSelector(
  [ getSearchingHouseholds,
    state => ({

      /*@ts-expect-error*/
      models: state.users.get('modelsHousehold'),

      /*@ts-expect-error*/
      statuses: state.users.get('statusesHousehold'),
    }),
  ],

  (machines, filterValue) => {
    /*@ts-expect-error*/
    return machines.reduce((newList, item) => {
      /*@ts-expect-error*/
      const necessaryMachines = item.units.ownedUnits.filter(machine => {
        const show = {
          models: filterValue.models.length
            ? filterValue.models.includes(machine.typeName)
            : true,
          statuses: filterValue.statuses.length
            ? filterValue.statuses.includes(machine.data.STATUS.valueF)
            : true,
        }

        return show.models && show.statuses
      })

      if(necessaryMachines.length) {
        /*@ts-expect-error*/
        necessaryMachines.sort(a => {
          if(a.track && a.track.activeTrack) {
            return -2
          } else if(a.data.STATUS.value) {
            return -1
          } else {
            return 1
          }
        })

        newList.push({
          id      : item.id,
          isOpened: item.isOpened,
          name    : item.name,

          /*@ts-expect-error*/
          numberOfWatchedMachines: item.units.units.filter(unit => unit.watch).length,
          units                  : necessaryMachines,
        })
      }

      return newList
    }, [])
  },
)
