export type SortParam = 'FIRST_MOTOR' |
'ACTIVE' |
'TIME' |
'SPEED' |
'AVER_SPEED_COMBINE' |
'INC_SUMM_FUEL' |
'INC_SUMM_AREA' |
'AREA_PER_TIME'

type SettingsMachineSort = Readonly<{
  param: SortParam | null
  min: boolean
}>

type SettingsMachine = Readonly<{
  sort: SettingsMachineSort
}>

export type Settings = Readonly<{
  help: boolean
  machine: SettingsMachine
  startTimeOffset: number
}>

export const init = (): Settings => ({
  help   : false,
  machine: {
    sort: {
      min  : true,
      param: null,
    },
  },
  startTimeOffset: Number(new Date()) % 60000,
})

export const sort = (settings: Settings, param: SortParam ): Settings => ({
  ...settings,
  machine: {
    sort: {
      min: settings.machine.sort.param === param ? !settings.machine.sort.min : true,
      param,
    },
  },
})

export const toggleHelp = (settings: Settings): Settings => ({
  ...settings,
  help: !settings.help,
})
