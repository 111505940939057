import { dissocPath, isEmpty, isNil } from 'ramda'
import { FilterType } from 'reducers/notification/state'
import { assertNever } from 'utils/assertNever'

type InitType = {
  machines?: unknown[]
  machinesList?: unknown[]
  statuses?: unknown[]
  models?: unknown[]
  filter?: FilterType
}

type StateType = {
  category: {
    id: number
    items: string[]
    name: string
    result: string[]
    section: string
    type: string
    search?: unknown
    colors?: string[]
  }
  machine: {
    farmsWithFilteredUnites: unknown[]
    id: number
    machinesList: unknown[]
    models: unknown[]
    name: string
    openedItems: unknown[]
    result: object
    section: string
    statuses: unknown[]
    type: string
    validate: boolean
  }
  status: {
    colors: string[]
    id: number
    items: string[]
    name: string
    result: string[]
    section: string
    type: string
  }
}

export enum NotificationPageActionsEnum {
  INIT_MACHINES = 'INIT_MACHINES',
  SET_FILTERED_LIST = 'SET_FILTERED_LIST',
  SET_MACHINE = 'SET_MACHINE',
  SET_MACHINES = 'SET_MACHINES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_STATUSES = 'SET_STATUSES',
  SET_ALL_MACHINES = 'SET_ALL_MACHINES',
  CLEAR_ALL_MACHINES = 'CLEAR_ALL_MACHINES',
  SET_SELECT_ITEMS = 'SET_SELECT_ITEMS',
  INIT_RESULT = 'INIT_RESULT',
  RESET = 'RESET'
}

type NotificationPageActionsType = {
  type: NotificationPageActionsEnum.INIT_MACHINES
  payload: {
    machinesList: unknown[]
    statuses: unknown[]
    models: unknown[]
  }
} | {
  type: NotificationPageActionsEnum.SET_FILTERED_LIST
  payload: unknown[]
} | {
  type: NotificationPageActionsEnum.SET_MACHINE
  payload: {
    farmId: number | string
    unit: unknown
  }
} | {
  type: NotificationPageActionsEnum.SET_MACHINES
  payload: number | string
} | {
  type: NotificationPageActionsEnum.SET_CATEGORIES
  payload?: string
} | {
  type: NotificationPageActionsEnum.SET_STATUSES
  payload?: string
} | {
  type: NotificationPageActionsEnum.SET_ALL_MACHINES
} | {
  type: NotificationPageActionsEnum.CLEAR_ALL_MACHINES
} | {
  type: NotificationPageActionsEnum.SET_SELECT_ITEMS
  payload: null | unknown
} | {
  type: NotificationPageActionsEnum.INIT_RESULT
  payload: Record<string, unknown[] | object>
} | {
  type: NotificationPageActionsEnum.RESET
  payload: InitType
}

export const init = ({
  machines = [],
  machinesList = [],
  statuses = [],
  models = [],
  filter = {},
}: InitType): StateType => ({
  category: {
    id   : 3,
    items: [
      'engine',
      'fuel_system',
      'adapter_reaper',
      'inclined_camera_feeder',
      'cabin',
      'thresher',
      'cleaning',
      'chassis',
      'hopper',
      'stacker',
      'hydraulics',
      'electrics',
      'notification',
    ],

    name   : 'category',
    result : filter?.category ?? [],
    section: 'category',
    type   : 'checkbox',
  },
  machine: {
    farmsWithFilteredUnites: machines,
    id                     : 0,
    machinesList           : machinesList,
    models                 : models,
    name                   : 'machine',
    openedItems            : [],
    result                 : filter?.machine ?? {},
    section                : 'machine',
    statuses               : statuses,
    type                   : 'checkbox-machine',
    validate               : true,
  },
  status: {
    colors : ['#D10B41', '#F6C547', '#15CE70', '#0072CF'],
    id     : 2,
    items  : ['critical', 'not_critical', 'informational', 'service'],
    name   : 'status',
    result : filter?.status ?? [],
    section: 'status',
    type   : 'checkbox',
  },
})

export const reducer = (state: StateType, action: NotificationPageActionsType): StateType => {
  switch(action.type) {
    case NotificationPageActionsEnum.INIT_MACHINES:
      const { machinesList, statuses, models } = action.payload
      return {
        ...state,
        machine: {
          ...state.machine,
          machinesList,
          models,
          statuses,
        },
      }

    case NotificationPageActionsEnum.SET_FILTERED_LIST:
      return {
        ...state,
        machine: {
          ...state.machine,
          farmsWithFilteredUnites: action.payload,
        },
      }

    case NotificationPageActionsEnum.SET_MACHINE: {
      //@ts-expect-error
      const units = state.machine.result[action.payload.farmId]

      if(isNil(units) || Array.isArray(units) && isEmpty(units)) {
        return {
          ...state,
          machine: {
            ...state.machine,
            result: {
              ...state.machine.result,
              [action.payload.farmId]: [action.payload.unit],
            },
          },
        }
      }

      //@ts-expect-error
      const selectedUnit = units.find(unit => unit.id === action.payload.unit.id)

      if(!selectedUnit) {
        return {
          ...state,
          machine: {
            ...state.machine,
            result: {
              ...state.machine.result,
              [action.payload.farmId]: [...units, action.payload.unit],
            },
          },
        }
      }

      if(units.length === 1) {
        return dissocPath(['machine', 'result', action.payload.farmId], state)
      }

      return {
        ...state,
        machine: {
          ...state.machine,
          result: {
            ...state.machine.result,

            //@ts-expect-error
            [action.payload.farmId]: units.filter(unit => unit.id !== action.payload.unit.id),
          },
        },
      }
    }

    case NotificationPageActionsEnum.SET_MACHINES: {
      //@ts-expect-error
      const units = state.machine.result[action.payload]

      //@ts-expect-error
      const selectedFarm = state.machine.farmsWithFilteredUnites.find(farm => farm.id === action.payload)

      if(isNil(units) || isEmpty(units)) {
        return {
          ...state,
          machine: {
            ...state.machine,
            result: {
              ...state.machine.result,

              //@ts-expect-error
              [action.payload]: selectedFarm.units,
            },
          },
        }
      }

      //@ts-expect-error
      if(units.length >= selectedFarm.units.length) {
        return dissocPath(['machine', 'result', action.payload], state)
      }

      return {
        ...state,
        machine: {
          ...state.machine,
          result: {
            ...state.machine.result,

            //@ts-expect-error
            [action.payload]: selectedFarm.units,
          },
        },
      }
    }

    case NotificationPageActionsEnum.SET_CATEGORIES:
      if(action.payload !== undefined) {
        const findCategory = state.category.result.findIndex(
          accItem => action.payload === accItem,
        )

        return {
          ...state,
          category: {
            ...state.category,
            result: findCategory > -1
              ? state.category.result.filter((_, index) => index !== findCategory)
              : [...state.category.result, action.payload],
          },
        }
      }

      return {
        ...state,
        category: {
          ...state.category,
          result: [],
        },
      }

    case NotificationPageActionsEnum.SET_STATUSES:
      if(action.payload !== undefined) {
        const findStatus = state.status.result.findIndex(
          accItem => action.payload === accItem,
        )

        return {
          ...state,
          status: {
            ...state.status,
            result: findStatus > -1
              ? state.status.result.filter((_, index) => index !== findStatus)
              : [...state.status.result, action.payload],
          },
        }
      }

      return {
        ...state,
        status: {
          ...state.status,
          result: [],
        },
      }

    case NotificationPageActionsEnum.SET_ALL_MACHINES: {
      return {
        ...state,
        machine: {
          ...state.machine,
          result: state.machine.farmsWithFilteredUnites.reduce((acc: object, farm) => ({
            ...acc,

            //@ts-expect-error
            [farm.id]: farm.units,
          }), {}),
        },
      }
    }

    case NotificationPageActionsEnum.CLEAR_ALL_MACHINES: {
      return {
        ...state,
        machine: {
          ...state.machine,
          result: {},
        },
      }
    }

    case NotificationPageActionsEnum.SET_SELECT_ITEMS:
      if(action.payload != null) {
        const findSelected = state.machine.openedItems.findIndex(
          item => action.payload === item,
        )

        return {
          ...state,
          machine: {
            ...state.machine,
            openedItems: findSelected > -1
              ? state.machine.openedItems.filter((_, index) => index !== findSelected)
              : [...state.machine.openedItems, action.payload],
          },
        }
      }

      return {
        ...state,
        machine: {
          ...state.machine,
          openedItems: [],
        },
      }

    case NotificationPageActionsEnum.INIT_RESULT:
      const initState = init({})
      const keys = Object.keys(state) as Array<keyof StateType>

      return keys.reduce((acc: StateType, key) => ({
        ...acc,
        [key]: {
          ...acc[key],
          result: action.payload[key] || initState[key].result,
        },
      }), { ...state })

    case NotificationPageActionsEnum.RESET:
      return init(action.payload)

    default:
      return assertNever(action, state)
  }
}
