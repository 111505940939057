import React, { FC, useCallback,useEffect, useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Calendar } from 'react-date-range'
import { useTranslate } from 'react-redux-multilingual'
import TooltipD from '../Tooltip'
import { Plus } from './filterControl'

type ElementType = {
  to: string
  from: string
}

type Props = {
  section: string
  name: string
  onStartFilter: (section: string, item: ElementType) => void
}

export const FilterDateSolo: FC<Props> = ({ name, section, onStartFilter }) => {
  const format = 'dddd, D MMMM YYYY'
  const [open, setOpen] = useState(false)
  const [day, setDay] = useState<Date | null>(null)
  const rootRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(open && (!rootRef.current || !rootRef.current.contains(event.target as Node))) {
      setOpen(false)
    }
  }, [open])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleSelect = (date:Date) => {
    setDay(date)
  }

  const applyDate = () => {
    if(day) {
      onStartFilter(section, {
        from: `${day.format('YYMMDD').toString()}000000000`,
        to  : `${day.format('YYMMDD')}235959000`,
      })
    }

    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  return (
    <div className="filter-parameter date-solo" ref={rootRef}>
      <p
        tabIndex={0}
        role="button"
        onClick={toggleOpen}
      >
        <span>{name}</span><Plus/>
      </p>
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {open &&
          <div className="filter-parameter__open date-solo" >
            <div className="filter-parameter__inputs">
              <input type="text" value={day ? day.format(format).toString() : ''}/>
            </div>


            <Calendar
              weekStartsOn={1}
              onChange={handleSelect}
            />

            <div className="filter-parameter__buttons">
              <button onClick={applyDate}>{translate('accept')}</button>
            </div>
          </div>
        }
      </ReactCSSTransitionGroup>
      <TooltipD text={translate('selecting_date')} style={{ right: '0', top: '7px' }}/>
    </div>
  )
}
