import { Geometry, MultiLineString } from 'geojson'
import shp, { FeatureCollectionWithFilename } from 'shpjs'

// TODO: Move out to agrotronic-libs/helpers when it will ready
export const convertFileToArrayBuffer = (file: File): Promise<ArrayBuffer> => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  reader.onload = () => resolve(reader.result as ArrayBuffer)
  reader.onerror = reject
})


export type TTryParseShape = FeatureCollectionWithFilename | FeatureCollectionWithFilename[]

export const tryParseShape = async (buffer: ArrayBuffer): Promise<TTryParseShape | undefined> => {
  try {
    return await shp(buffer)
  } catch{
    return undefined
  }
}

const type2Coordinates = (item: Geometry) => {
  switch(item.type) {
    case 'LineString':
      return [item.coordinates]

    case 'MultiLineString':
    case 'Polygon':
      return item.coordinates

    default:
      return []
  }
}

// TODO: Move out to agrotronic-libs/helpers when it will ready
export const getMultiLine = (shapeArray: Geometry[]) => {
  const acc: MultiLineString = {
    coordinates: [],
    type       : 'MultiLineString',
  }

  for(const item of shapeArray) {
    acc.coordinates.push(...type2Coordinates(item))
  }

  return acc
}
