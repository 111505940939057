import { hooks, IconButton,icons } from '@agdt/agrotronic-react-components'
import React, { FC,useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

const { usePopup } = hooks
const { ContextMenuIcon } = icons

type Props = {
  iconColor?: string
}

export const ContextMenu: FC<Props> = ({ children, iconColor = '#A1A1A1' }) => {
  const popup = usePopup()
  const popupRef = useRef(null)
  const iconProps = useMemo(() => ({ color: iconColor }), [iconColor])
  const rootRef = useRef<HTMLDivElement>(null)

  const clickOutside = useCallback(e => {
    if(rootRef.current && e.target !== rootRef.current && !rootRef.current.contains(e.target)) {
      popup.close()
    }
  }, [popup])

  useEffect(() => {
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('click', clickOutside)
    }
  }, [clickOutside])

  return (
    <MenuRoot ref={rootRef}>
      {/*@ts-expect-error*/}
      <IconButton iconProps={iconProps} title={''} icon={ContextMenuIcon} onClick={popup.toggle} />
      {popup.isShown && <Overlay onClick={popup.close} />}
      {popup.isShown && <MenuContainer ref={popupRef} onClick={popup.close}>{ children }</MenuContainer>}
    </MenuRoot>
  )
}

const MenuRoot = styled.div`

`

const MenuContainer = styled.ul`
  position: absolute;
  right: 10px;
  top: 25px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14);
  background: #ffffff;
  border-radius: 2px;
  z-index: 100;
  padding: 8px 0 8px 0;
`

const Overlay = styled.div`
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
