import React from 'react'
import { TSVGIconProps } from 'types'

export const LayoutTwo = ({ color = '#A1A1A1', size = 22, className }: TSVGIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#LayoutTwoClip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9048 1.04762H2.09524C1.51665 1.04762 1.04762 1.51665 1.04762 2.09524V3.66667H20.9524V2.09524C20.9524 1.51665 20.4833 1.04762 19.9048 1.04762ZM20.9524 4.71429H1.04762V19.9048C1.04762 20.4833 1.51665 20.9524 2.09524 20.9524H19.9048C20.4833 20.9524 20.9524 20.4833 20.9524 19.9048V4.71429ZM0 4.71429V3.66667V2.09524C0 0.93807 0.93807 0 2.09524 0H19.9048C21.0619 0 22 0.93807 22 2.09524V19.9048C22 21.0619 21.0619 22 19.9048 22H2.09524C0.93807 22 0 21.0619 0 19.9048V4.71429ZM2.09524 9.06191V7.33333H5.76191V9.06191H2.09524ZM2.09524 18.8571V10.2142H10.4762V18.8571H2.09524ZM11.5238 10.2142V18.8571H19.9048V10.2142H11.5238ZM6.80952 9.06191V7.33333H10.4762V9.06191H6.80952ZM11.5238 7.33333V9.06191H15.1905V7.33333H11.5238ZM16.2384 9.06191V7.33333H19.905V9.06191H16.2384Z"
          fill={color}
        />
        <path
          d="M1 2C1 1.44772 1.44772 1 2 1H20C20.5523 1 21 1.44772 21 2V4H1V2Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="LayoutTwoClip0">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
