import React, { FC } from 'react'
import styled from 'styled-components'
import { gradientGenerator, layersSchema } from '../utils'

type WeatherMapLegendProps = {
   currentWeatherLayer: string
}

export const WeatherMapLegend: FC<WeatherMapLegendProps> = ({ currentWeatherLayer }) => {
  const element = layersSchema.find(({ code }) => code === currentWeatherLayer)
  const units = element?.units || ''
  const legend = element?.legend || []

  return <LegendContainer>
    <LegendDetails>
      <Units>{units}</Units>
      <div>
        <ScaleDividers>
          {legend.map(({ value }) => <Divider key={`${ currentWeatherLayer }_${ value }`}>{ value }</Divider>)}
        </ScaleDividers>
        <ScaleGradient gradient={gradientGenerator(legend)}/>
      </div>
    </LegendDetails>
  </LegendContainer>
}

const LegendContainer = styled.div`
  background: none;
  border-width: 0;
  bottom: 90px;
  box-shadow: none;
  display: block;
  height: 45px;
  min-width: 327px;
  position: absolute;
  right: 15px;
  z-index: 410;
`

const LegendDetails = styled.div`
  align-items: center;
  background-color: #0D1011;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  color:#fff;
  display: flex;
  font-size: 10px;
  height: 45px;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: .9;
  padding: 2pt 4pt;
  transition: box-shadow .1s ease-out;
`

const Units = styled.div`
  padding: 0 8px 13px 6px;
`

const Divider = styled.div`
  padding: 0 4px;
`

const ScaleDividers = styled.div`
  display:flex;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 100%;
`

const ScaleGradient = styled.div<{ gradient: string }>`
  background-image: ${({ gradient }) => `linear-gradient(to right, ${ gradient })`};
  border-radius: 4pt;
  height: 10px;
  opacity: .9;
  min-width: 275px;
`
