import {DropdownList} from '@agdt/agrotronic-react-components'
import React, {useState} from 'react'
import {useTranslate} from 'react-redux-multilingual'
import styled, {css} from 'styled-components'

type TDropdownChildProps = {
  text: string
  select: (value: string) => void
  isSelected: boolean
  value: string
}

const DropdownChild = ({ text, select, isSelected, value }: TDropdownChildProps) => {
  const handleSelect = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    select(value)
  }

  return (
    <DropDownChildContainer onClick={handleSelect} isSelected={isSelected}>
      {text}
    </DropDownChildContainer>
  )
}

type TSelectorProps = {
  className?: string
  first?: boolean
  last?: boolean
  renderList: string[]
  result: string[]
  select: (value: string) => void
  textAfterSelect: string
}

export const Selector = ({
  className,
  first,
  last,
  renderList,
  result,
  select,
  textAfterSelect,
}: TSelectorProps) => {
  const [showSelector, onShowSelector] = useState(false)
  const translate = useTranslate()

  const handleToggle = () => onShowSelector(!showSelector)

  return (
    <DropdownListContainer className={className}>
      <DropdownList
        listChildren={[
          <DropdownChild
            key="all"
            text={translate('All')}
            value=""
            select={select}
            isSelected={!result.length}
          />,
          ...renderList.map(item =>
            <DropdownChild
              key={item}
              text={item}
              value={item}
              select={select}
              isSelected={result.includes(item)}
            />,
          ),
        ]}
        isPopupOpen={showSelector}
        onHidePopup={handleToggle}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        maxHeight="250px"
        border="1px solid #eae9e9"
        boxShadow="2px -1px 4px 1px rgba(0, 0, 0, 0.1)"
      >
        {/*//@ts-expect-error*/}
        <ButtonSelector onClick={handleToggle} first={first} last={last}>
          <ButtonSelectorText>
            {result.length ? `${result[result.length - 1]}` : textAfterSelect}
          </ButtonSelectorText>
          <CountSelected>
            {result.length > 1 ? ` +${result.length - 1}` : ''}
          </CountSelected>
        </ButtonSelector>
      </DropdownList>
    </DropdownListContainer>
  )
}

const DropdownListContainer = styled.div`
  width: 100%;
`

const ButtonSelector = styled.div<{showSelector: boolean, first: boolean, last:boolean}>`
  border: ${({ showSelector }) =>
    showSelector ? '1px solid rgba(234,14,14,0.8)' : '1px solid #C9CCD4'};
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  cursor: pointer;
  border-radius: 2px;
  background-color: ${({ showSelector }) =>
    showSelector ? 'rgba(234,14,14,0.12)' : '#ffffff'};

  &:hover {
    border: 1px solid #202020;
  }

  ${({ first }) =>
    first &&
    css`
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `}
  ${({ last }) =>
    last &&
    css`
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    `}
`

const ButtonSelectorText = styled.span`
  color: #202020;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CountSelected = styled.span`
  color: #D10029;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
`

const DropDownChildContainer = styled.div<{isSelected: boolean}>`
  padding: 5px;
  font-size: 11px;
  border-bottom: 1px solid #eae9e9;
  min-height: 25px;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(209, 0, 41,0.8)' : '#ffffff'};
  color: ${({ isSelected }) => isSelected ? '#ffffff' : '#202020'};

  &:hover {
    background-color: #D10029;
    color: #ffffff;
  }
`
