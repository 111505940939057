import { DivIcon } from 'leaflet'
import { LoadingMarkersInfo } from 'queries/trackEvents'
import React, { FC } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { TTranslate } from 'types'

type UnloadingLoadingMarkerProps = {
  t: TTranslate
  marker: Required<LoadingMarkersInfo>
  icon: DivIcon
}

export const UnloadingLoadingMarker: FC<UnloadingLoadingMarkerProps> = ({ icon, marker, t }) => <Marker
  icon={icon}
  position={[marker.lat, marker.lon]}
  zIndexOffset={10000}
>
  <Popup position={[marker.lat, marker.lon]}>
    <div className="marker-popup">
      <p className="marker-popup__name">
        {t('time')}: {Date.parseDateTimeFromApi(marker.time).user.format('DD.MM.YY HH:mm:ss')}
      </p>
    </div>
  </Popup>
</Marker>
