import React from 'react'

export type TProps = {
  className?: string
  size?: number
  color?: string
  isCompact?: boolean
}

export const ResizeIcon = ({
  className,
  size = 20,
  color = 'red',
  isCompact = true,
}: TProps) => <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  {isCompact && <path
    key="isCompact-view"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5 7C5 7.55 4.55 8 4 8C3.45 8 3 7.55 3 7V4C3 3.45 3.45 3 4 3H7C7.55 3 8 3.45 8 4C8 4.55 7.55 5 7 5H5V7ZM3 13C3 12.45 3.45 12 4 12C4.55 12 5 12.45 5 13V15H7C7.55 15 8 15.45 8 16C8 16.55 7.55 17 7 17H4C3.45 17 3 16.55 3 16V13ZM15 15H13C12.45 15 12 15.45 12 16C12 16.55 12.45 17 13 17H16C16.55 17 17 16.55 17 16V13C17 12.45 16.55 12 16 12C15.45 12 15 12.45 15 13V15ZM13 5C12.45 5 12 4.55 12 4C12 3.45 12.45 3 13 3H16C16.55 3 17 3.45 17 4V7C17 7.55 16.55 8 16 8C15.45 8 15 7.55 15 7V5H13Z"
    fill={color}
  />}

  {!isCompact && <path
    key="isNonCompact-view"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 6H6V4C6 3.45 6.45 3 7 3C7.55 3 8 3.45 8 4V7C8 7.55 7.55 8 7 8H4C3.45 8 3 7.55 3 7C3 6.45 3.45 6 4 6ZM6 14H4C3.45 14 3 13.55 3 13C3 12.45 3.45 12 4 12H7C7.55 12 8 12.45 8 13V16C8 16.55 7.55 17 7 17C6.45 17 6 16.55 6 16V14ZM13 17C13.55 17 14 16.55 14 16V14H16C16.55 14 17 13.55 17 13C17 12.45 16.55 12 16 12H13C12.45 12 12 12.45 12 13V16C12 16.55 12.45 17 13 17ZM14 4V6H16C16.55 6 17 6.45 17 7C17 7.55 16.55 8 16 8H13C12.45 8 12 7.55 12 7V4C12 3.45 12.45 3 13 3C13.55 3 14 3.45 14 4Z"
    fill={color}
  />}

</svg>

export default ResizeIcon
