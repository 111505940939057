import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import { MarkerType } from 'ui/Map/mapUnit'
import { columns } from '../../constants'

export type DataFueling = {
  from: number
  imei: number
  marker: MarkerType
  to: number
}

export type ReportInfo = {
  imei: number
  to: number
  eventName: string
  sign: string
  lat: number
  lon: number
  from: number
  volume: number
  end: number
  start: number
} & Record<string, string>

type RowProps = {
  active: boolean
  isLoading: boolean
  pushDataFueling: (value: DataFueling) => void
  requestFrom: number
  requestTo: number
  report: ReportInfo
}

export const Row: FC<RowProps> = ({
  active,
  isLoading,
  pushDataFueling,
  report,
  requestFrom,
  requestTo,
}) => {
  const handlerPushDataFueling = () => {
    if(isLoading) {
      return
    }

    pushDataFueling({
      from  : requestFrom,
      imei  : report.imei,
      marker: {
        end      : moment(report.to).format('HH:mm:ss'),
        eventName: report.eventName,
        eventType: report.sign === 'DRAIN' ? 1 : 0,
        lat      : report.lat,
        lon      : report.lon,
        start    : moment(report.from).format('HH:mm:ss'),
        startTime: report.from,
        value    : Number(report.volume.toFixed(1)),
      },

      to: requestTo,
    })
  }

  return <StyledTr
    active={active}
    onClick={handlerPushDataFueling}
  >
    {columns.map(column => <td key={column.id}><p>{report[column.id]}</p></td> )}
  </StyledTr>
}

const StyledTr = styled.tr<{active: boolean}>`
  background: ${({ active }) => active ? '#efefef' : 'none'}
  cursor: pointer;
`
