import { ReportInfo } from 'containers/Watch/Fueling/Result/Row'
import Dygraph from 'dygraphs'
import moment from 'moment'
import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { TTranslate } from 'types'
import { StatusHelper } from '../Machines/result'
import TooltipD from '../Tooltip'

const colorsStatus = [
  { title: 'M0', color: '#b1b1b1' },
  { title: 'M1', color: '#06AAF5' },
  { title: 'M2', color: '#202020' },
  { title: 'M3', color: '#D10B41' },
  { title: 'M4', color: '#78C800' },
  { title: 'M5', color: '#d61fd6' },
]

const styles = {
  legend: {
    display     : 'flex',
    marginBottom: -20,
    marginLeft  : 20,
  },
}

type GraphicDigraphState = {
  graphic: unknown
  showFuel: boolean
  showSpeed: boolean
}

export type IndicatorsType = {
  values: {
    FUEL: {
      value: number[]
    }
    SPEED: {
      value: number[]
    }
  }
  background: Record<string, {
    duration: {
      beg: number
      end: number
    }[]
    value: number
  }>
  time: number[]
}

type GraphicDigraphProps = {
  imei: number
  indicators: IndicatorsType
  listEvent?: ReportInfo[]
  data: {
    imei: string | number
  }
  translate: TTranslate
}

@withTranslate
export class GraphicDigraph extends React.PureComponent<GraphicDigraphProps, GraphicDigraphState> {
  state = {
    graphic  : null,
    showFuel : true,
    showSpeed: true,
  }

  graphicRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.createGraphic(this.props)
  }

  componentDidUpdate(prevProps: GraphicDigraphProps) {
    if(prevProps.imei !== this.props.imei) {
      this.createGraphic(this.props)
    }
  }

  createGraphic = (nextProps: GraphicDigraphProps) => {
    const fuel = nextProps.indicators.values.FUEL
    const modes = nextProps.indicators.background
    const time = nextProps.indicators.time
    const speed = nextProps.indicators.values.SPEED
    const listEvent = nextProps.listEvent?.filter(item => +item.imei === +nextProps.data.imei)

    const data = time.reduce((acc: number[][], item, index) => [
      ...acc,
      [item, fuel.value[index], speed.value[index]],
    ], [])

    if(!this.graphicRef.current) {
      return
    }

    this.setState({
      graphic: new Dygraph(
        this.graphicRef.current,
        data,
        {
          connectSeparatedPoints: true,
          interactionModel      : Dygraph.defaultInteractionModel,
          showRangeSelector     : true,
          xAxisHeight           : 30,
          legendFormatter       : params => {
            let range = null
            let fuelObject = null

            Object.values(modes).forEach(mode => {
              if(mode.duration.find(item => params.x >= item.beg && params.x <= item.end)) {
                range = mode.value
              }
            })

            const findEvent = listEvent?.find(event => event.from <= params.x && event.to >= params.x)

            if(findEvent) {
              const findData = time.find(d => d === params.x)

              if(findData) {
                fuelObject = {
                  type : findEvent.sign,
                  value: findEvent.sign === 'FILING'
                    ? findEvent.end - findEvent.start
                    : findEvent.start - findEvent.end,
                }
              } else {
                fuelObject = null
              }
            } else {
              fuelObject = null
            }

            if(params.x == null) {
              return '<br>' + params.series.map(series => series.dashHTML + ' ' + series.labelHTML).join('<br>')
            }

            let html = `<div class="wrap-label">
                <p>${this.props.translate('time')}</p>
                <p>${moment(params.xHTML).format('DD.MM.YY')} ${moment(params.xHTML).format('HH:mm:ss')}</p>
              </div>
              <div class="wrap-label">
                <p>${this.props.translate('mode')}</p>
                <p>
                  ${range && range >= 1 && range<=5 ? this.props.translate(`M${range}`) : this.props.translate('M0')}
                </p>
              </div>`

            params.series.forEach(series => {
              if(!series.isVisible){return}

              if(series.label !== this.props.translate('FILING') && series.label !== this.props.translate('DRAIN')) {
                let labeledData = `<div class="wrap-label__item">
                  <p>${series.labelHTML}</p>
                  <p>${series.yHTML ? series.yHTML : '-'}</p>
                </div>`

                if(series.isHighlighted) {
                  //@ts-expect-error
                  labeledData = `<div class="wrap-label__children">${series.labeledData}</div>`
                }

                html += labeledData
              }
            })

            if(fuelObject) {
              html = html + `<div class="wrap-label">
                <p>${fuelObject.type === 'DRAIN' ? this.props.translate('DRAIN') : this.props.translate('FILING')}</p>
                <p>${fuelObject.value.toFixed(1)}</p>
              </div>`
            }

            return html
          },
          underlayCallback: (canvas, area, g) => {
            //@ts-expect-error
            const eventHighlight = eventh => {
              if(eventh.sign === 'DRAIN') {
                canvas.fillStyle = '#c70606'
              } else if(eventh.sign === 'FILING') {
                canvas.fillStyle = '#78C800'
              }

              var canvas_left_x = g.toDomXCoord(eventh.from) || 0
              var canvas_right_x = g.toDomXCoord(eventh.to) || 0
              var canvas_width = canvas_right_x - canvas_left_x
              canvas.fillRect(canvas_left_x, area.y, canvas_width, 30)
            }

            listEvent?.map(item => eventHighlight(item))

            //@ts-expect-error
            const highlightPeriod = (x_start, x_end, mod) => {
              const mode = []
              mode[0] = 'rgba(177, 177, 177, 0.2)'
              mode[1] = 'rgba(6, 170, 245, 0.2)'
              mode[2] = 'rgba(32, 32, 32, 0.2)'
              mode[3] = 'rgba(209, 11, 65, 0.2)'
              mode[4] = 'rgba(120, 200, 0, 0.2)'
              mode[5] = 'rgba(214, 31, 214, 0.2)'
              canvas.fillStyle = mode[mod]
              const canvas_width = (g.toDomXCoord(x_end) || 0) - (g.toDomXCoord(x_start) || 0)
              canvas.fillRect(g.toDomXCoord(x_start) || 0, area.y, canvas_width, area.h)
            }

            Object.values(modes).forEach(({ duration, value }) => {
              duration.forEach(({ beg, end }) => {
                highlightPeriod(beg, end, value)
              })
            })
          },
          labels: [
            this.props.translate('date'),
            this.props.translate('level fuel, l'),
            `${this.props.translate('speed')}, ${this.props.translate('km/h')}`,
          ],
          colors: ['#020202', '#06AAF5', '#78C800', '#c70606'],
          series: {
            [`${this.props.translate('speed')}, ${this.props.translate('km/h')}`]: { axis: 'y2' },
          },
          legend: 'follow',
          axes  : {
            x: {
              axisLabelFormatter: text => {
                return `<span class="dygraph-span x">
                  ${moment.unix(Number(text) / 1000).format('DD.MM.YY')}<br/>${moment.unix(Number(text) / 1000).format('HH:mm:ss')}
                </span>`
              },
            },
            y: {
              axisLabelFormatter: text => {
                return `<span class="dygraph-span y">${Number(text).toFixed(1)}</span>`
              },
            },
          },
        },
      ),
    })
  };

  handleShowFuel = () => {
    //@ts-expect-error
    this.setState({ showFuel: !this.state.showFuel }, () => this.state.graphic.setVisibility(0, this.state.showFuel))
  }

  handleShowSpeed = () => {
    //@ts-expect-error
    this.setState({ showSpeed: !this.state.showSpeed }, () => this.state.graphic.setVisibility(1, this.state.showSpeed))
  }

  render() {
    return (
      <div>
        <TooltipD text={this.props.translate('fuel graphics')} style={{ left: '0' }}/>
        <div style={styles.legend}>
          <div>
            <div className="watch__control-item">
              <div className="watch__colorpicker" onClick={this.handleShowFuel}>
                <div className="watch__colorpicker-color" style={{ backgroundColor: '#000000' }}/>
              </div>
              <input
                type="checkbox"
                checked={this.state.showFuel}
              />
              <label onClick={this.handleShowFuel}>
                {this.props.translate('fuel')}
              </label>
            </div>
            <div className="watch__control-item">
              <div className="watch__colorpicker" onClick={this.handleShowSpeed}>
                <div className="watch__colorpicker-color" style={{ backgroundColor: '#06AAF5' }}/>
              </div>
              <input
                type="checkbox"
                checked={this.state.showSpeed}
              />
              <label onClick={this.handleShowSpeed}>
                {this.props.translate('speed')}
              </label>
            </div>
          </div>
          <span className="machine-list__status-legend">
            {colorsStatus.map(item =>
              <StatusHelper
                key={item.title}
                title={item.title}
                color={item.color}
                translate={this.props.translate}
              />,
            )}
          </span>
        </div>
        <div
          id="graphic-dygraph"
          ref={this.graphicRef}
        />
      </div>
    )
  }
}
