import { NotificationTasksActionsType,NotificationTasksActionTypeEnum } from './actions'
import * as State from './state'


export default function notificationTasks(
  state = State.init(),
  action: NotificationTasksActionsType,
): State.NotificationTasks {
  switch(action.type) {
    case NotificationTasksActionTypeEnum.GET_NOTIFICATION_TASKS:
      return State.setTasks(state, action.payload)

    case NotificationTasksActionTypeEnum.EDIT_NOTIFICATION_TASK:
      return State.editTask(state, action.payload)

    default:
      return state
  }
}
