import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React, { FC, useCallback,useEffect,useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { useTranslate } from 'react-redux-multilingual'
import TooltipD from '../Tooltip'
import { Plus } from './filterControl'

const RadioGroup = Radio.Group

type FilterRadioProps = {
  name: string
  items: string[]
  section: string
  onStartFilter: (section: string, item: string, solo: boolean) => void
}

export const FilterRadio: FC<FilterRadioProps> = ({ name, items, section, onStartFilter }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(items[0])
  const rootRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(open && (!rootRef.current || !rootRef.current.contains(event.target as Node))) {
      setOpen(false)
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  const toggleOpen = () => {
    setOpen(!open)
  }

  useEffect(()=> {
    setSelectedOption(items[0])
  }, [items])

  const changeChecked = (event: RadioChangeEvent) => {
    setSelectedOption(event.target.value)
    onStartFilter(section, event.target.value, true)
  }

  return (
    <div className="filter-parameter">
      <p
        tabIndex={0}
        role="button"
        onClick={toggleOpen}
      >
        <span>{name}</span>
        <Plus/>
      </p>
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {open &&
          <div className="filter-parameter__open" ref={rootRef}>
            <RadioGroup
              onChange={changeChecked}
              value={selectedOption}
            >
              {items.map((item, index) => <Radio key={index} value={item}>{item}</Radio>)}
            </RadioGroup>
          </div>
        }
      </ReactCSSTransitionGroup>
      <TooltipD text={translate('Single parameter selection')} style={{ right: '0', top: '7px' }}/>
    </div>
  )
}
