import { Button, icons, Spinner } from '@agdt/agrotronic-react-components'
import React, { FC } from 'react'
import styled from 'styled-components'

type ExportToExcelButtonProps = {
  disabled: boolean
  title: string
  onClickHandler: () => void
}

export const ExportToExcelButton: FC<ExportToExcelButtonProps> = ({ disabled, onClickHandler, title }) => <ExportButton
  disabled={disabled}
  onClick={onClickHandler}
  variant='border'
>
  {!disabled && <>
    <icons.XlsIcon size='24' color='#32BE6E'/>
    <TitleButton>{title}</TitleButton>
  </>}

  {disabled && <Spinner color='#a19a9a' size={20}/>}
</ExportButton>

export default ExportToExcelButton

const ExportButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
  position: absolute;
  right: 16px;
  top: 22px;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding: 0 4px;
`
