import { Switch } from '@agdt/agrotronic-react-components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { UserInfoType } from 'reducers/user/state'
import styled from 'styled-components'
import { CodeConfirmWrapper } from './CodeConfirmWrapper'

type Props = {
  disabled?: boolean
  user: UserInfoType
  handleUpdateUserInfo: (fieldName: string, value: unknown) => void
}

export const MfaSwitch: FC<Props> = ({ disabled, handleUpdateUserInfo, user }) => {
  const t = useTranslate()
  const [isChecked, setIsChecked] = useState<boolean>(!!user.useMfa)
  const [showSmSConfirmation, setShowSmSConfirmation] = useState(false)

  const handleToggleSwitch = () => {
    setIsChecked(!isChecked)
    setShowSmSConfirmation(true)
  }

  const handleCloseModal = useCallback(() => {
    setIsChecked(!!user.useMfa)
  }, [user.useMfa])

  useEffect(() => {
    setIsChecked(!!user.useMfa)
  }, [user.useMfa])

  return <>
    <SwitchWrapper>
      <Switch disabled={disabled} isChecked={isChecked} onChangeHandler={handleToggleSwitch}/>
      <span>{t('Two-factor authentication')}</span>
    </SwitchWrapper>

    <CodeConfirmWrapper
      closeHandler={handleCloseModal}
      handleUpdateUserInfo={handleUpdateUserInfo}
      isChecked={isChecked}
      operatorEmail={user.operatorEmail}
      operatorTelNumber={user.operatorTelNumber}
      setShowConfirmation={setShowSmSConfirmation}
      showConfirmation={showSmSConfirmation}
    />
  </>
}

const SwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-top: 0;

  & > span {
    font-size: 16px;
    padding-left: 20px;
  }
`
