import { Button, icons } from '@agdt/agrotronic-react-components'
import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { LANG_RU } from '../../../../constants/lang'
import { AppStateType } from '../../../../reducers/store'
import { TOperator } from '../ResultTable/OperatorContent'
import { createExcelFile } from './ToExcellHandler'

type TProps = {
  report: Record<string, TOperator>
  withoutFilters: unknown
}

export const ToExcelComponent: FC<TProps> = ({
  report,
  withoutFilters,
}) => {
  const t = useTranslate()
  const lang = useSelector((state: AppStateType) => state.user.info.lang || LANG_RU)
  const userLogin = useSelector((state: AppStateType) => state.user.info.operatorLogin || state.user.info.login || '-')

  const exportToExcelHandler = useCallback(
    () => createExcelFile({ lang, report, t, userLogin, withoutFilters }),
    [report, t, userLogin, withoutFilters, lang],
  )

  return <ExportButton onClick={exportToExcelHandler} variant="border">
    <icons.XlsIcon size="24" color="#32BE6E"/>
    <TitleButton>{t('Download')}</TitleButton>
  </ExportButton>
}

const ExportButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
  position: absolute;
  right: 16px;
  top: 22px;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding-left: 4px;
`
