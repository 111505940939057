import React from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { Plus } from '../../../Filter/filterControl'

type TProps = {
  closePopupControl: () => void
  createNewGeoZone: () => void
}

export const GeoZoneControl = ({ closePopupControl, createNewGeoZone }: TProps) => {
  const t = useTranslate()

  return <div className="geoZone-controll__controll">
    <div className="geoZone-controll__title">
      <p>{t('creating geozones')}</p>
      <div
        className="geoZone-controll__close"
        onClick={closePopupControl}
        role="button"
        tabIndex={0}
      />
    </div>
    <div
      className="geoZone-controll__add"
      onClick={createNewGeoZone}
      role="button"
      tabIndex={0}
    >
      <p>{t('Create')}</p>
      <Plus/>
    </div>
  </div>
}
