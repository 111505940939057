import { formats } from '@agdt/agrotronic-react-components'
import React, { FC, useEffect, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { TimeReportType } from 'reducers/reports/state'
import { TTranslate, TWorkMode } from 'types'
import { LANG_RU } from '../../constants/lang'
import TooltipD from '../../ui/Tooltip'
import { colorsStatus } from '../../utils'
import { ChartA } from '../Graphic/chart'

type Props = {
  sumData: boolean
  title: string
  tooltip: string
  report: TimeReportType[]
}

type Mode = Exclude<TWorkMode, 'M0' | 'M1' | 'M2' | 'M4'>

type SortOfStatusType = {
  name: string
  value: number
  duration?: number
} & {
  [Key in Mode]?: number
}

type SortOfTimeType = Record<string, number| string>

const makeSortOfStatus = (report: TimeReportType[], translate: TTranslate): SortOfStatusType[] =>
  report.reduce((result: SortOfStatusType[], item: TimeReportType): SortOfStatusType[] => {
    for(let key in item.modes) {
      if(['M3', 'M5', 'M6'].includes(key)) {
        const findMode = result.find(element => element.value === 3)
        const modeValue = item.modes[key as Mode]

        if(findMode) {
          const keyValue = findMode[key as Mode]
          findMode[key as Mode] = keyValue ? modeValue + keyValue : modeValue
        } else {
          result.push({
            [key]: modeValue,
            name : translate('Idle'),
            value: 3,
          })
        }
      } else {
        const findMode = result.find(
          element => element.value === Number(key[1]),
        )

        const duration = item.modes[key as TWorkMode]

        if(findMode) {
          const findModeDuration = findMode.duration
          findMode.duration = findModeDuration ? findModeDuration + duration : duration
        } else {
          result.push({
            duration,
            name : translate(key),
            value: Number(key[1]),
          })
        }
      }
    }

    return result
  }, [])
    .sort((a, b) => a.value - b.value)

const makeSortOfTime = (report: TimeReportType[], translate: TTranslate) =>
  report.sort((a, b) => Number(a.from) - Number(b.from))
    .reduce((result: SortOfTimeType[], item: TimeReportType): SortOfTimeType[] => {
      return [
        ...result,
        Object.keys(item.modes).reduce((acc, element) => {
          return {
            ...acc,
            [translate(element)]: item.modes[element as TWorkMode],
          }
        }, {
          name: formats.date.dateViewFormatter(formats.api.parseDateTimeFromApi(item.from), LANG_RU),
        }),
      ]
    }, [])

export const AnalyticGraphicBar: FC<Props> = ({
  sumData,
  title,
  tooltip,
  report,
}) => {
  const translate = useTranslate()
  const [sortOfTime, setSortOfTime] = useState<SortOfTimeType[]>(makeSortOfTime(report, translate))
  const [sortOfStatus, setSortOfStatus] = useState<SortOfStatusType[]>(makeSortOfStatus(report, translate))

  useEffect(() => {
    setSortOfStatus(makeSortOfStatus(report, translate))
    setSortOfTime(makeSortOfTime(report, translate))
  }, [report, translate])

  return (
    <div className="analytic-bar">
      <TooltipD text={tooltip} style={{ left: '-15px' }} />
      {sortOfTime.length &&
        <div className="analytic-bar__top">
          <p>
            {title}
            <span>
              {sortOfTime[0].name} —{' '}
              {sortOfTime[sortOfTime.length - 1].name}
            </span>
          </p>
        </div>
      }
      <div className="analytic-bar__body">
        <ChartA
          report={
            sumData
              ? sortOfStatus
              : sortOfTime
          }
          sumData={sumData}
          colorsStatus={Object.keys(colorsStatus).reduce((acc: Record<string, string>, item) => {
            acc[translate(item)] = colorsStatus[item as TWorkMode]
            return acc
          }, {})}
        />
      </div>
    </div>
  )
}
