import { action, configure, makeObservable, observable } from 'mobx'

configure({ enforceActions: 'observed' })

export default class ConfirmStore {
  @observable title = 'Подтвердите действие';
  @observable text = '';
  responseCb: Function | null = null;

  constructor() {
    makeObservable(this)
  }

  @action.bound
  askForConfirmation({ title, text }: {title: string, text: string}) {
    this.title = title || this.title
    this.text = text
    return new Promise(resolve => { this.responseCb = resolve })
  }

  @action.bound
  confirm() {
    if(this.responseCb) {
      this.responseCb(true)
    }

    this.text = ''
  }

  @action.bound
  cancel() {
    if(this.responseCb) {
      this.responseCb(false)
    }

    this.text = ''
  }
}
