import { CurrentUserApi, CurrentUserStore } from '@agdt/user'
import { ConfigContext } from 'containers/ValidateUser'
import moment from 'moment'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import AccountsTreeStore from 'stores/AccountsTreeStore'
import ConfirmStore from 'stores/ConfirmStore'
import ContextStore from 'stores/ContextStore'
import SummaryStore from 'stores/dictionaries/SummaryStore'
import dictionariesStore from 'stores/DictionariesStore'
import HouseholdsStore from 'stores/Households/HouseholdsStore'
import HouseholdUnitsStore from 'stores/Households/HouseholdUnitsStore'
import { NoticesStore } from 'stores/Notices'
import { urlApi } from '../constants/env'
import { LANG_RU } from '../constants/lang'
import GeoZones from './GeoZones'
import { ActivityWidgetStore, MapWidgetStore } from './HomeWidgets'

const registry = {
  accountsTree  : new AccountsTreeStore(),
  activityWidget: new ActivityWidgetStore(),
  confirmStore  : new ConfirmStore(),
  context       : new ContextStore(),
  currentUser   : new CurrentUserStore(new CurrentUserApi(urlApi)),
  dictionaries  : dictionariesStore,
  geoZones      : new GeoZones(),
  households    : HouseholdsStore(),
  householdUnits: HouseholdUnitsStore(),
  mapWidget     : new MapWidgetStore(),
  notices       : new NoticesStore(),
  summary       : new SummaryStore(),
}

async function init() {
  await registry.dictionaries.currentUser.waitValue()

  //@ts-expect-error
  const lang = registry.dictionaries.currentUser.value.lang || LANG_RU
  moment.locale(lang.toLowerCase())

  //@ts-expect-error
  if(registry.dictionaries.currentUser.value.accountType === 'user') {
    registry.dictionaries.operatorUsersAccounts.getValue()
  }
}


export type TStoreName = keyof typeof registry | 'accountsTree'
export type TStore = typeof registry[TStoreName]
export type TRegistry = typeof registry
export const getStoresRegistry = () => registry

export function getStore<P extends TStoreName>(name: P): TRegistry[P] {
  if(registry && registry[name] !== undefined) {
    return registry[name]
  } else { throw Error('Get undefined store') }
}

export function InitStore({ children }: PropsWithChildren<{}>) {
  const t = useTranslate()
  const context = useContext(ConfigContext)

  useEffect(() => {
    registry.context.setTranslate(t)
  }, [t])

  useEffect(() => {
    if(!context?.farm.isLoading) {
      init()
    }
  }, [context?.farm.isLoading])

  return <>{children}</>
}
