import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

type TStyledProps = {
  isStratchable: boolean
  notAvailable: boolean
}

export type TProps = {
  children: ReactNode
  className?: string
  onMouseDown: () => void
  onTouchStart: () => void
  onMouseUp: () => void
  onTouchEnd: () => void
  style: CSSProperties
} & TStyledProps

export const Widget = ({
  children,
  className,
  isStratchable,
  notAvailable,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
  onTouchStart,
  style,
}: TProps) => <WidgetContainer
  className={className}
  style={style}
  onMouseDown={onMouseDown}
  onTouchStart={onTouchStart}
  onMouseUp={onMouseUp}
  onTouchEnd={onTouchEnd}
  isStratchable={isStratchable}
  notAvailable={notAvailable}
>
  { children }
</WidgetContainer>


const WidgetContainer = styled.div<TStyledProps>`
  overflow: ${({ isStratchable }) => isStratchable ? 'inherit' : 'hidden'};
  background: ${({ notAvailable }) => notAvailable ? 'rgba(50, 50, 50, 0.8)' : '#FFFFFF'};
  color: ${({ notAvailable }) => notAvailable ? 'white' : 'inherit'};
  
  /* Button-shadow */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 2px;
`
