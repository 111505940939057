import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

type TProps = {
  isActive: boolean
  onClick: () => void
  color: string
  title: string
  count: number
}

const TotalFilter = ({ isActive, onClick, color, title, count }: TProps) => {
  const [isHover, setHover] = useState(false)
  const mouseEnter = useCallback(() => setHover(true), [setHover])
  const mouseOut = useCallback(() => setHover(false), [setHover])

  return (
    <TotalFilterElement
      onClick={onClick}
      color={color}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseOut}
      isActive={isActive}
      isHover={isHover}
    >
      <TotalFilterHeader>
        <Circle color={color} />
        <TotalFilterHeaderCaption>{ title }</TotalFilterHeaderCaption>
      </TotalFilterHeader>

      <VerticalSpacer />
      <TotalFilterCounter>{count}</TotalFilterCounter>
      <ColoredFooter color={color} isHover={isHover || isActive} />
    </TotalFilterElement>)
}

export default observer(TotalFilter)

const hoverStyles = css`
  border: 1px solid #D10029;
`

const activeStyles = css`
  border: 1px solid #D10029;
  margin-top: 8px;
  box-sizing: border-box;
  
  /* Button-shadow */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.02);
`

const TotalFilterElement = styled.div<{ isActive: boolean, isHover: boolean }>`
  flex: 1 1 0;
  width: 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  line-height: 0;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #ffffff;

  background: #FFFFFF;
  /* color stroke */

  border: 1px solid #E2E5EC;
  box-sizing: border-box;

  /* Тень */
  border-radius: 2px;

  min-width: 92px;
  height: 93px;

  margin-right: 8px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 12px;
  
  ${ ({ isActive }) => isActive ? activeStyles : '' }
  ${ ({ isHover }) => isHover ? hoverStyles : '' }
`

const TotalFilterHeader = styled.header`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 8px;
  width: 100%;
`

const Circle = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;

  /* Color 5 */
  border: 3px solid ${({ color }) => color};
  box-sizing: border-box;
`

const TotalFilterHeaderCaption = styled.div`
  width: 80%;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #000000;

  text-align: start;
  margin-left: 5px;
  margin-top: 1px;
`

const VerticalSpacer = styled.div`
  flex-grow: 1;
`

const TotalFilterCounter = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #000000;
  width: 100%;
  margin-left: 8px;
`

const ColoredFooter = styled.footer<{ isHover: boolean }>`
  height: 5px;
  width: calc(100% + 2px);
  background: ${({ color }) => color};
  border-color: ${({ color }) => color};
  position: relative;
  border-bottom-left-radius: 2px;
  border-top: none;
  top: 1px;
  border-bottom-right-radius: 2px;
  border-bottom: 1px solid ${({ color, isHover }) => isHover ? '#D10029' : color};
  border-left: 1px solid ${({ color, isHover }) => isHover ? '#D10029' : color};
  border-right: 1px solid ${({ color, isHover }) => isHover ? '#D10029 ': color};
`
