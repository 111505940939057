import Filter from 'pages/Notification/Filter'
import MachinesList from 'pages/Notification/MachinesList'
import NotificationTable from 'pages/Notification/NotificationTable'
import SearchMachines from 'pages/Notification/SearchMachines'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { getStore } from '../../stores/storesRegistry'

const Notifications = () => {
  const store = getStore('notices')
  const t = useTranslate()

  useEffect(() => {
    store.initialLoad()

    return () => { store.unload() }
  }, [])

  return <PageWrapperStyled>
    <SidebarStyled>
      <SearchMachines translate={t}/>
      <MachinesList/>
    </SidebarStyled>
    <PageStyled>
      <Filter/>
      <NotificationTable/>
    </PageStyled>
  </PageWrapperStyled>
}

const PageWrapperStyled = styled.div`
  display: flex;
  background-color: #fff;
`

const SidebarStyled = styled.aside`
  width: 300px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  border-right: 1px solid #E2E5EC;
`

const PageStyled = styled.main`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  height: calc(100vh - 60px);
`

export default Notifications
