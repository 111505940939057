import { AppDispatch } from 'reducers/store'
import { userInfo } from 'reducers/user/actions'
import { userLogin } from 'reducers/users/actions'
import { pushTrack } from 'reducers/users/actions'

export const useDemoUser = async (dispatch: AppDispatch, lang: string) => {
  const getData = (fileName: string) =>
    fetch(`${process.env.PUBLIC_URL}/demo/${fileName}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept        : 'application/json',
      },
      credentials: 'same-origin',
    })

  Promise.all([getData(`users_${lang}`), getData('user')])
    .then(response => {
      return { farmings: response[0].json(), user: response[1].json() }
    })
    .then(combined => {
      combined.farmings
        .then(farmings => {
          dispatch(userLogin(farmings.users, true))
        })
        .catch(err => console.error(err))

      combined.user
        .then(user => dispatch(userInfo(user)))
        .catch(err => console.log(err))
    })
    .catch(err => console.error(err))
}

export const demoTrack = async (imei: number, dispatch: AppDispatch) => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/demo/track.json`, {
      headers: {
        Accept        : 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })

    const json = await response.json()
    dispatch(pushTrack(json, imei, false, true))
  } catch(err) {
    console.error(err)
  }
}

export const demoNewDetail = async (callback: (value: { registers: Record<string, object> }[]) => void) => {
  try {
    const response = await fetch(
      `${process.env.PUBLIC_URL}/demo/newDetails.json`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept        : 'application/json',
        },
        credentials: 'same-origin',
      },
    )

    const json = await response.json()
    callback(json)
  } catch(err) {
    console.error(err)
  }
}

export const demoMultinotices = (dispatch: AppDispatch, lang: string) => {
  try {
    fetch(`${process.env.PUBLIC_URL}/demo/multiNotices_${lang}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept        : 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => {dispatch(json)})
  } catch(err) {
    console.error(err)
  }
}
