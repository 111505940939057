import { hooks } from '@agdt/agrotronic-react-components'
import { demoRequest } from 'services/apiService/demoQueries'
import { getAccountsTree, getAllEmployee, getOperatorUsersAccounts } from 'services/apiService/modules'
import DictionaryStore from 'stores/dictionaries/DictionaryStore'
import UnitsStore from 'stores/dictionaries/UnitsStore'
import { UserStore } from 'stores/dictionaries/UserStore'
import { TAccount, TEmployee, TUser } from 'types'

const { useDemoMode } = hooks

const isDemo = useDemoMode()

export class DictionariesStore {
  currentUser = new UserStore()

  employees = new DictionaryStore<TEmployee>(!isDemo ?
    getAllEmployee.bind(null, { limit: 0 })
    : demoRequest.bind(undefined, 'employees'),
  )

  usersTree = new DictionaryStore<TUser>(!isDemo
    ? getAccountsTree.bind(null, { limit: 0 })
    : demoRequest.bind(undefined, 'accounts'),
  )

  operatorUsersAccounts = new DictionaryStore<TAccount>(!isDemo
    ? getOperatorUsersAccounts.bind(null, { limit: 0 })
    : demoRequest.bind(undefined, 'operatorUsersAccounts'),
  )

  targets2 = new UnitsStore()

  // onUpdate(data: TWithId<any>, type: TActionType, entity: 'catalog') {
  // }
}

export default new DictionariesStore()
