import React from 'react'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { mapParams } from '../../../constants'
import { CategoryWrapper, Title } from '../../common/Styled'
import Legend from './Legend'

type TProps = {
  id: string
  translate: TTranslate
  typeCreateMap: string | null
}

const MapLegend = ({
  id,
  translate,
  typeCreateMap,
}: TProps) => <CategoryWrapper>
  <Title>{translate('Map Legend Management')}</Title>

  <LegendWrapper>
    <Legend
      id={id}
      mapType={mapParams.find(item=> item.type === typeCreateMap)}
    />
  </LegendWrapper>
</CategoryWrapper>

export default MapLegend

const LegendWrapper = styled.div`
  display: flex;
  margin: 15px 10px 0 10px ;
  justify-content: space-between;
`
