import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export type TProps = {
  children: React.ReactNode
  setValue: (value: string) => void
  value: string
}

const EditableLegendValue = ({ children, setValue, value }: TProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    if(inputRef.current && isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing, inputRef])

  const handleOnChange = useCallback(event => {
    setInputValue(event.target.value)
  }, [])

  const handleOnBlur = useCallback(() => {
    setIsEditing(false)
    setValue(inputValue)
  }, [inputValue, setValue])

  const handleOnClick = useCallback(() => {
    if(isEditing) { return }

    setIsEditing(true)
    setInputValue(value)
  }, [isEditing, value])

  const handleOnKeyDown = useCallback(event => {
    if(event.key !== 'Enter') { return }

    setIsEditing(false)
    setValue(inputValue)
  }, [inputValue, setValue])

  return <LegendItemWrapper
    onClick={value ? handleOnClick : undefined}
    showPointer={Boolean(!isEditing && value)}
  >
    {isEditing && <StyledInput
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      ref={inputRef}
      size={2}
      type="text"
      value={inputValue}
    />}

    {!isEditing && <StyledValue>{value}</StyledValue>}

    {children}
  </LegendItemWrapper>
}

const StyledValue = styled.span`
  border: 1px transparent;
  padding: 1px;
`

const StyledInput = styled.input`
  border: 1px solid #a5a3a3;
  border-radius: 2px;
  padding: 1px;
`

const LegendItemWrapper = styled.div<{ showPointer: boolean }>`
  align-items: center;
  cursor:  ${({ showPointer }) => showPointer && 'pointer'};
  display: flex;
`

export { EditableLegendValue }
