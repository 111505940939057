import 'leaflet.fullscreen/Control.FullScreen'
import 'ui/Map/leaflet-fullscreen.css'
import { hooks } from '@agdt/agrotronic-react-components'
import { MAP_CENTER } from 'constants/map'
import L, { Marker,PopupEvent } from 'leaflet'
import { observer } from 'mobx-react'
import { pathOr } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { Map } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { getStore } from 'stores/storesRegistry'
import { TUnit } from 'types'
import MarkerCluster from 'ui/Map/Cluster'
import { PopupMarker } from 'ui/Map/EventMarkers/PopupMarker'
import { MapLayer } from 'ui/Map/MapLayer'
import MarkerState from 'ui/Map/markerState'
import { MapControls } from './MapControls'
import { MapTypeControl } from './MapTypeControl'

const createClusterCustomIcon = (cluster: L.MarkerCluster) => {
  return L.divIcon({
    className: 'marker-cluster-custom',
    html     : `<span>${cluster.getChildCount()}</span>`,
    iconSize : L.point(40, 40, true),
  })
}

export const MapComponent = observer(() => {
  const mapWidgetStore = getStore('mapWidget')
  const markers = mapWidgetStore.markers
  const locale = hooks.getLang()
  const mapRef = useRef<Map>(null)
  const popupMarker = useRef<HTMLDivElement>(null)
  const popup = useRef<L.Popup>()
  const [popupUnit, setPopupUnit] = useState<TUnit | null>(null)
  const t = useTranslate()

  const initMapChangeBounds = () => {
    const bounds = markers.map(marker => marker.position)

    if(bounds.length) {
      mapRef.current?.leafletElement.fitBounds(bounds)
    }
  }

  useEffect(() => {
    if(mapRef.current) {
      // Add zoom buttons on map
      L.control
        .zoom({
          zoomInTitle : t('zoom in'),
          zoomOutTitle: t('zoom out'),
        })
        .addTo(mapRef.current.leafletElement)

      // Add fullscreen button on map
      L.control
        .fullscreen({
          title      : t('fullscreen'),
          titleCancel: t('exit fullscreen'),
        })
        .addTo(mapRef.current.leafletElement)

      if(mapRef.current.leafletElement) {
        mapRef.current.leafletElement.on('enterFullscreen', () => {
          mapRef.current?.leafletElement.invalidateSize()
        })

        mapRef.current.leafletElement.on('exitFullscreen', () => {
          mapRef.current?.leafletElement.invalidateSize()
        })
      }

      if(markers.length) {
        initMapChangeBounds()
      }

      mapRef.current.leafletElement.on({
        popupclose: onPopupClose,
      })

      popup.current = L.popup()
    }

    return () => {
      if(mapRef.current) {
        mapRef.current.leafletElement.off({
          popupclose: onPopupClose,
        })
      }
    }
  }, [])

  useEffect(() => {
    initMapChangeBounds()

    // If changed markers count force flush buffer to avoid add already deleted markers
    mapRef.current?.leafletElement.fire('flushbuffer')
  }, [markers])

  const onPopupOpen = (unit: TUnit, marker: Marker) => {
    if(popupMarker.current && mapRef.current && popup.current) {
      popup.current.setLatLng(marker.getLatLng())
        .setContent(popupMarker.current)
        .openOn(mapRef.current.leafletElement)
    }

    setPopupUnit(unit)
  }

  const onPopupClose = (layer: PopupEvent) => {
    // On close popup clear style if popup was in css transfort processs
    const element = layer.popup.getElement()

    if(element) {
      element.style.transform = ''
      setPopupUnit(null)
    }
  }

  return <>
    <Map
      ref={mapRef}
      center={MAP_CENTER}
      zoom={10}
      minZoom={2}
      maxZoom={18}
      maxBounds={[
        [-190, -180],
        [90, 180],
      ]}
      fadeAnimation={false}
      zoomAnimation={false}
      markerZoomAnimation={false}
      zoomControl={false} // Hide default zoomControl buttons
    >
      <MapLayer lang={locale} mapType={mapWidgetStore.selectedMapType}/>

      <MapControls
        initMapChangeBounds={initMapChangeBounds}
        t={t}
        mapRef={mapRef}
      />

      <MapTypeControl
        mapRef={mapRef}
        t={t}
      />

      <MarkerCluster
        key={mapWidgetStore.clusterKey}
        showCoverageOnHover={false}
        iconCreateFunction={createClusterCustomIcon}
        removeOutsideVisibleBounds={true}
        animateAddingMarkers={false}
      >
        {mapWidgetStore.markers.map(marker => <MarkerState
          key={`${marker.imei}`}
          openPopup={onPopupOpen}
          popup={popupUnit?.id === marker.id ? popup.current : undefined}
          position={[marker.data.LATITUDE.value, marker.data.LONGITUDE.value]}
          status={pathOr(0, ['data', 'STATUS', 'value'], marker)}
          unit={marker}
        />)}
      </MarkerCluster>
    </Map>

    <div style={{ display: 'none' }}>
      <div ref={popupMarker}>
        {/*@ts-expect-error*/}
        {popupUnit && <PopupMarker
          {...popupUnit}
          translate={t}
        />}
      </div>
    </div>
  </>
})
