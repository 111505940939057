import { isEmpty } from 'ramda'
import React, { FC, useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { ValidateInput } from '../common/ValidateInput'

type Props = {
  dataIsSave?: boolean
  disableClear?: boolean
  disabled: boolean
  fieldName: string
  required?: boolean
  label: string
  initValue?: string
  testid: string
  formatter?: (value: string) => string
  handleSave: (field: string, value: unknown) => void
  postProcessValue?: (value: string) => string
  validator?: (value: string) => string | undefined
}

export const ValidatedInputWrapper: FC<Props> = ({
  dataIsSave,
  disableClear,
  disabled,
  fieldName,
  formatter,
  handleSave,
  initValue,
  label,
  postProcessValue,
  required,
  testid,
  validator,
}) => {
  const [value, setValue] = useState<string | boolean | undefined>(initValue)
  const [validateError, setValidateError] = useState<string>('')
  const t = useTranslate()

  const handleOnBlur = useCallback(() => {
    if(!validateError && value !== initValue) {
      handleSave(fieldName, isEmpty(value) ? null : value)
    }
  },[fieldName, handleSave, initValue, validateError, value])

  return <ValidateInput
    dataIsSave={dataIsSave}
    disableClear={disableClear}
    disabled={disabled}
    error={Boolean(validateError)}
    handleOnBlur={handleOnBlur}
    helperText={validateError}
    initValue={initValue}
    label={label}
    onEnter={handleOnBlur}
    postProcessValue={postProcessValue}
    required={required}
    setValidateError={setValidateError}
    setValue={setValue}
    t={t}
    testid={testid}
    validator={validator}
    value={formatter ? formatter(String(value)) : String(value)}
  />
}
