import moment from 'moment'
import { layersSchema } from '.'

export const gradientGenerator = (legendArray: {color: string}[]) => {
  let stopPoint = 0
  const stopPointStep = 100 / legendArray.length

  return legendArray.map(({ color }) => {
    const gradient = `${color} ${stopPoint}%`
    stopPoint = stopPoint + stopPointStep
    return gradient
  }).join(' ,')
}

export const dateRangeGenerator = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  step: moment.DurationInputArg1,
  stepUnit: moment.unitOfTime.DurationConstructor,
  arr = [start],
): moment.MomentInput[] => {
  const nextDate = moment(start).add(step, stepUnit)

  if(nextDate.isAfter(end)) {
    return arr
  }

  return dateRangeGenerator(nextDate, end, step, stepUnit, arr.concat(nextDate.valueOf()))
}

export const isStartOfDay = (dateTime: moment.MomentInput) => moment(dateTime).startOf('day').isSame(dateTime)

export const closestItems = (items: number[], currentItem: number) => items.reduce(
  (prev, curr) => Math.abs(curr - currentItem) < Math.abs(prev - currentItem) ? curr : prev,
)

export const weatherLayerIsShowed = (currentLayer: string) => currentLayer !== layersSchema[0].code
