import { ReportsActionsType,ReportsActionTypeEnum } from './actions'
import * as State from './state'

export default function reports(state = State.init(), action: ReportsActionsType): State.Reports {
  switch(action.type) {
    case ReportsActionTypeEnum.PUSH_REPORT:
      return State.pushReports(state, action.payload)

    case ReportsActionTypeEnum.REPORT_ERROR:
      return State.setError(state, action.payload, 'Error load of report')

    case ReportsActionTypeEnum.CLEAR_ERROR_REPORT:
      return State.setError(state, action.payload, '')

    case ReportsActionTypeEnum.START_LOAD_REPORT:
      return State.startLoadReport(state, action.payload)

    case ReportsActionTypeEnum.FINISH_LOAD_REPORT:
      return State.finishLoadReport(state, action.payload)

    case ReportsActionTypeEnum.FINISH_EXPORT_LOAD_REPORT:
      return State.finishLoadReport(state, action.payload)

    case ReportsActionTypeEnum.CLEAR_RESULT:
      return State.clearResult(state, action.payload)

    case ReportsActionTypeEnum.PUSH_TRACK_REPORT:
      return State.pushTrackReport(state, action.payload)

    case ReportsActionTypeEnum.PUSH_INDICATORS_REPORT:
      return State.pushIndicatorsReport(state, action.payload)

    case ReportsActionTypeEnum.STOP_LOAD_REPORT:
      return State.stopLoadReport(state, action.payload)

    case ReportsActionTypeEnum.CLEAR_FIXED_DATA_FILTER:
      return State.clearFixedDataFilter(state)

    case ReportsActionTypeEnum.FIXED_DATA_FILTER:
      return State.setFixedDataFilter(state, action.payload)

    case ReportsActionTypeEnum.TOOGLE_CLEANING_REPORT:
      return State.toggleCleaningReport(state)

    case ReportsActionTypeEnum.PUSH_CLEANING_REPORT:
      return State.pushCleaningReport(state, action.payload)

    case ReportsActionTypeEnum.TOGGLE_UNLOADING_REPORT:
      return State.toggleUnloadingReport(state)

    case ReportsActionTypeEnum.PUSH_UNLOADING_REPORT:
      return State.pushUnloadingReport(state, action.payload)

    case ReportsActionTypeEnum.PUSH_UNLOADING_CARRIER_REPORT:
      return State.pushUnloadingCarrierReport(state, action.payload)

    case ReportsActionTypeEnum.PUSH_REPORT_BY_PATH:
      return State.pushReportByPath(state, action.payload)

    case ReportsActionTypeEnum.CHANGE_DENSITY:
      return State.changeDensity(state, action.payload)

    default: {
      return state
    }
  }
}
