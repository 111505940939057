import { useCallback } from 'react'
import { C_COMPACT_HEIGHT, C_MIDDLE_HEIGHT } from '../../WidgetsGallery/widgets.config'

export type TUseWidgetResizeProps = {
  config: { h: number }
  changeConfig: (payload: { h: number }) => void
}

export const useWidgetResize = (config: { h: number }, changeConfig: (payload: { h: number }) => void) => {
  const isCompact = config.h <= C_COMPACT_HEIGHT

  const toggleSize = useCallback(() => {
    changeConfig({ h: isCompact ? C_MIDDLE_HEIGHT : C_COMPACT_HEIGHT })
  }, [isCompact, changeConfig])

  return { isCompact, toggleSize }
}
