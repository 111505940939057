import { Tooltip } from 'antd'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { TTranslate } from 'types'
import { notificationNumName } from 'utils'
import { link } from '../../../../router'
import { getStore } from '../../../../stores/storesRegistry'

type PopupMarkerProps = {
  main: string
  name: string
  id: number
  type: string
  imei: number
  minInfoMode?: boolean
  data?: {
    ACTIVE?: {
      valueF: string
    }
    STATUS?: {
      value: number
    }
    SPEED?: {
      valueF: string
    }
    AGRICULTURE?: {
      value: string
    }
    NUM_MESSAGES_ALL?: {
      value: number
    }
  } & Record<string, {value: number}>
  isLeasing?: boolean
  state: string
  translate: TTranslate
}

type Status = {
  id: number
  count: number
  color: string
  status_descr: string
}

export const PopupMarker: FC<PopupMarkerProps> = ({
  state,
  isLeasing,
  data,
  main,
  name,
  id,
  imei,
  minInfoMode,
  type,
  translate,
}) => {
  const householdUnit = getStore('householdUnits').getUnit(imei)
  const history = useHistory()

  const notificationNumNameKeys = Object.keys(notificationNumName) as Array<keyof typeof notificationNumName>

  const onClickType = () => {
    if(!minInfoMode) {
      history.push(`${link.detail}?machine=${id}`)
    }
  }

  const onClickToolTip = (value: number) => () => {
    history.push(
      `${link.notifications}?machine=${id}&status=${value}`,
    )
  }

  const onClickMarker = () => {
    history.push(`${link.households}?machine=${type}`)
    householdUnit.setWatched()
  }

  return (
    <div className="marker-popup">
      <p
        onClick={onClickType}
        style={minInfoMode ? { cursor: 'default', textDecoration: 'none' } : {}}
        className="marker-popup__type"
      >
        {type}
      </p>
      <p className="marker-popup__name">{name}</p>
      <p className="marker-popup__name">{main}</p>
      {Boolean(data?.ACTIVE) && <p className="marker-popup__name">{data?.ACTIVE?.valueF}</p>}
      {Boolean(data?.STATUS?.value) &&
      (data?.STATUS?.value === 4 || data?.STATUS?.value === 1) &&
      !isLeasing &&
        <p className="marker-popup__name">
          {translate('speed')} {data.SPEED && data.SPEED.valueF != null ? data.SPEED.valueF : '-'}
        </p>
      }
      {Boolean(data?.AGRICULTURE) &&
        <p className="marker-popup__name">
          {translate('agriculture')}: {data?.AGRICULTURE?.value}
        </p>
      }
      {!minInfoMode && Boolean(data?.NUM_MESSAGES_ALL?.value) &&
        <div className="marker-popup__name">
          {translate('notifications')}:
          <div className="activity__all-active">
            {notificationNumNameKeys
              .reduce((acc: Status[], message) => {
                if(data?.[message]?.value) {
                  let findStatus = acc.find(
                    statusItem =>
                      statusItem.id === notificationNumName[message].value,
                  )

                  if(findStatus) {
                    findStatus.count += data[message].value
                  } else {
                    acc.push({
                      color       : notificationNumName[message].color,
                      count       : data[message].value,
                      id          : notificationNumName[message].value,
                      status_descr: notificationNumName[message].name,
                    })
                  }
                }

                return acc
              }, [])
              .map(item =>
                <Tooltip key={item.id} placement="top" title={item.status_descr}>
                  <div
                    onClick={onClickToolTip(item.id)}
                    className={'activity__all-number'}
                    style={{ background: item.color }}
                  >
                    <span>{item.count}</span>
                  </div>
                </Tooltip>,
              )}
          </div>
        </div>
      }

      <div className="marker-popup__wrap">
        <p
          onClick={onClickMarker}
          style={minInfoMode ? { cursor: 'default', textDecoration: 'none' } : {}}
          className="marker-popup__state"
        >
          {state}
        </p>
        {!minInfoMode &&
          <div
            onClick={onClickMarker}
            className="marker-popup__toggleWatch"
          />
        }
      </div>
    </div>
  )}
