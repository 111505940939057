import { icons } from '@agdt/agrotronic-react-components'
import Donut from 'components/Donut'
import { TProps as TWidgetProps, Widget } from 'components/Widget'
import Item from 'components/Widget/ContextMenu/Item'
import { Header } from 'components/Widget/Header'
import { observer } from 'mobx-react'
import { map, pair, pick, pipe, prop, sum, toPairs, values } from 'ramda'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { ResponsiveContainer } from 'recharts'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import TooltipD from 'ui/Tooltip'
import { modesSchema } from 'utils'
import { TUseWidgetResizeProps, useWidgetResize } from '../common/hooks/useWidgetResize'
import { NotAvailableMessage } from '../common/NotAvailableMessage'
import NoteMessage from '../common/NoteMessage'

const { EditIcon } = icons

const styles = {
  tooltips: {
    diagram: { top: 192, left: 202 },
  },
}

const donatConfig = {
  TIME_HARVEST        : ['TIME_HARVEST'],
  TIME_IDLE           : ['TIME_STOP_HARVEST_FULL','TIME_STOP_HARVEST','TIME_STOP_DON'],
  TIME_MOVE_NO_HARVEST: ['TIME_MOVE_NO_HARVEST'],
  TIME_OFF            : ['TIME_OFF'],
}

type TProps = {
  className: string
  config: {
    isResizable: boolean
  }

  onClose: () => void
} & TWidgetProps & TUseWidgetResizeProps

function TimeWidget({ className, changeConfig, style, onClose, config, ...restProps }: TProps) {
  const t = useTranslate()
  const summary = getStore('summary')

  //const T = (['TIME_STOP_DON', 'TIME_MOVE_NO_HARVEST', 'TIME_HARVEST', 'TIME_OFF'] as const).map(r => summary.value[r])

  const total = pipe(

    /*@ts-expect-error*/
    map(K => Number(summary.value[K] || 0)),
    sum,

    // value in ms = value / 1000 / 60 / 60 hours
    S => (S / 3600000).toFixed(1),
  )(['TIME_STOP_DON', 'TIME_MOVE_NO_HARVEST', 'TIME_HARVEST', 'TIME_OFF'])

  const donutData = useMemo(() => {
    let donutArrayData = map(pipe(

      // Calculate summ of group parameters
      /*@ts-expect-error*/
      ([K, V]) => pair(K, sum(values(pick(V, summary.value))) / 3600000),

      // Map result object
      ([K, V]) => ({

        /*@ts-expect-error*/
        color: modesSchema[K].color,

        /*@ts-expect-error*/
        title  : `${t(modesSchema[K].name)}`,
        tooltip: `${V < 10 ? V.toFixed(1) : Math.round(V).toLocaleString()}`,
        value  : V,
      }),
    ))(toPairs(donatConfig))

    // Remove zero values
    donutArrayData = donutArrayData.filter(prop('value'))

    return donutArrayData.length
      ? donutArrayData

      : [{
        color  : modesSchema.TIME_NO_LINK.color,
        title  : t(modesSchema.TIME_NO_LINK.name),
        tooltip: `${t('M0')} 100%`,
        value  : 100,
      }]
  }, [summary.value])

  const WidgetContextMenu = useMemo(() => <>
    <Item Icon={EditIcon} onClick={() => {}}>{t('settings')}</Item>
  </>, [])

  const { isCompact, toggleSize } = useWidgetResize(config, changeConfig)

  return (
    <Widget
      className={className}
      style={style}
      {...restProps}
      notAvailable={summary.dataLoadingError}
    >
      <Header
        counter={summary.dataLoadingError ? '' : `${total.toLocaleString()} ${t('h')}`}
        {...isCompact ? {} : { subtitle: t('amountOfHours') }}
        title={t('time')}
        contextMenu={WidgetContextMenu}
        onClose={onClose}
        isCompact={isCompact}
        isResizable={config.isResizable}
        toggleSize={toggleSize}
      />
      {summary.dataLoadingError
        ? <NotAvailableMessage text={t('service is not available')}/>

        : <>
          {isCompact && <CompactLook>
            {donutData.map(D => <Mode key={D.title}>
              <Title>{ D.title }{' '}({t('h')})</Title>
              <Count color={D.color}>{D.tooltip}</Count>
            </Mode>)}
          </CompactLook>}

          {!isCompact && <>
            <TooltipD
              text={t('main diagram')}
              style={styles.tooltips.diagram}
            />

            <ResponsiveContainer width="100%" height={200}>
              <Donut
                width={380} height={210}
                title={`${total} ${t('h')}`}
                data={donutData}
                animation
                labels
              />
            </ResponsiveContainer>

            <NoteMessage />
          </>}
        </>}
    </Widget>
  )
}

export default observer(TimeWidget)

const CompactLook = styled.div`
  display: flex;
  justify-content: space-around;
  height: 92px;
  align-items: flex-end;
  padding-bottom: 13px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 8px;

  /* Color 3 */
  color: #202020;

  padding: 4px;
  min-height: 30px;
  display: block;
`

const Mode = styled.div`
  background: #F7F9FA;
  border-radius: 2px;
  padding-right: 5px;
  height: 65px;
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 10px;
  width: 85px;

  :first-child {
    padding-left: 6px;
  }

  :last-child {
    padding-right: 6px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Count = styled.div`
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 15px;
  /* identical to box height, or 60% */

  display: flex;
  width: 100%;
  justify-content: center;

  color: ${({ color }) => color};
`
