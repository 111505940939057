import { StopIntervalMarkerInfo } from 'queries/trackEvents'
import React, { FC, useMemo } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { TTranslate } from 'types'
import { marker1 } from '../../markersType'

const parseTime = (time: string) => Date.parseDateTimeFromApi(time).getTime()

const filterMap: Record<string, {
  filter: (one: number, two: number) => boolean
  mode: string
}> = {
  'M2<1': {
    filter: (beg:number, end:number) => end - beg < 60000,
    mode  : 'M2',
  },
  'M2>1<10': {
    filter: (beg:number, end:number) => end - beg > 60000 && end - beg < 600000,
    mode  : 'M2',
  },
  'M2>10': {
    filter: (beg:number, end:number) => end - beg > 600000,
    mode  : 'M2',
  },
  'M3<1': {
    filter: (beg:number, end:number) => end - beg < 60000,
    mode  : 'M3',
  },
  'M3>1': {
    filter: (beg:number, end:number) => end - beg > 60000,
    mode  : 'M3',
  },
}

type StopIntervalMarkersProps = {
  markers: Record<string, Required<StopIntervalMarkerInfo>[]>
  t: TTranslate
  selectedOptions: Set<string>
}

export const StopIntervalMarkers: FC<StopIntervalMarkersProps> = ({ markers, selectedOptions, t }) => {
  const filteredMarkers = useMemo(() => {
    return Array.from(selectedOptions).reduce((acc: Required<StopIntervalMarkerInfo>[], item) => {
      const elements = markers[filterMap[item].mode]?.filter(marker =>
        filterMap[item].filter(parseTime(marker.beg), parseTime(marker.end)))

      if(elements) {
        acc.push(
          ...elements,
        )
      }

      return acc
    }, [])
  },[selectedOptions.size, markers])

  return <>{filteredMarkers.map(marker => <Marker
    key={marker.end + marker.beg}
    position={[marker.lat, marker.lon]}
    icon={marker1}
    zIndexOffset={10000}
  >
    <Popup position={[marker.lat, marker.lon]}>
      <div className="marker-popup">
        <p className="marker-popup__name">
          {t('Start')}:{' '}
          {Date.parseDateTimeFromApi(marker.beg).user.format('DD.MM.YY HH:mm:ss')}
        </p>
        <p className="marker-popup__name">
          {t('Finish')}:{' '}
          {Date.parseDateTimeFromApi(marker.end).user.format('DD.MM.YY HH:mm:ss')}
        </p>
      </div>
    </Popup>
  </Marker>)}</>
}
