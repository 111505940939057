import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { LANG_RU } from '../../../../../constants/lang'
import { AppStateType } from '../../../../../reducers/store'
import { ExportToExcelButton } from '../../../common/ExportToExcelButton'
import { createExcelFile, TReportProps } from './ToExcellHandler'

type TProps = Omit<TReportProps, 'userLogin' | 'lang'>

export const ToExcel: FC<TProps> = ({
  data,
  machineName,
  params,
  reportBackground,
  reportData,
  reportFrom,
  reportTo,
  showedParamsIds,
  t,
}) => {
  const [loadReport, setLoadReport] = useState(false)
  const userLogin = useSelector(({ user: { info } }: AppStateType) => info.operatorLogin || info.login || '')
  const lang = useSelector((state: AppStateType) => state.user.info.lang) || LANG_RU

  const exportToExcelHandler = useCallback(() => {
    setLoadReport(true)

    createExcelFile({
      data,
      machineName,
      params,
      reportBackground,
      reportData,
      reportFrom,
      reportTo,
      showedParamsIds,
      t,
      userLogin,
      lang,
    })
      .then(() => setLoadReport(false))
      .catch(() => {
        errorMessage(t('Error creating file'))
        setLoadReport(false)
      })
  },
  [data, machineName, params, reportBackground, reportFrom, reportTo, showedParamsIds, t, userLogin, lang],
  )

  return <ExcelExportButtonWrapper>
    <ExportToExcelButton disabled={loadReport} onClickHandler={exportToExcelHandler} title={t('Download')}/>
  </ExcelExportButtonWrapper>
}

const ExcelExportButtonWrapper = styled.div`
  position: relative;
   & > button {
     top: -18px;
   }
`
