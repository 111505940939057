import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { isEmpty } from 'ramda'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { LANG_RU } from '../../../../../../constants/lang'

const levelsMap = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
}

const formatCells = (
  sheet: {
    getCell: (value: string) => {
      value: object
    }
  },
  level: 1 | 2 | 3 | 4,
  number: number,
  data: {
    title: string
    subtitle: string
  },
) => {
  sheet.getCell(`${levelsMap[level]}${number}`).value = {
    'richText': [{
      'font': { 'size': 8, 'color': { 'argb': '00000000' }, 'name': 'Calibri', 'scheme': 'minor' },
      'text': `${data.title} \n`,
    },
    {
      'font': { 'size': 7, 'color': { 'argb': 'ff7d7d7d' }, 'name': 'Calibri', 'scheme': 'minor' },
      'text': `${data.subtitle} \n`,
    },
    ],
  }
}

const timeFormat = (dateTime: Date) =>
  dateTime.toLocaleString(LANG_RU, { hour: 'numeric', minute: 'numeric' })

let cursor = 0

type DataInfo = {
  area: string
  timeAllWorkModes: number
  timeHarvest: number
  allWorkModesPerformance: string
  harvestPerformance: string
}

const getReportData = (data: DataInfo) => [
  data.area,
  prepareDuration(data.timeAllWorkModes),
  prepareDuration(data.timeHarvest),
  data.allWorkModesPerformance,
  data.harvestPerformance,
]

//@ts-expect-error
const iterateReport = (sheet, number, data, level) => {
  sheet.insertRow(number, [
    ...new Array(level - 1),
    data.title,
    ...new Array(4 - level),
    ...getReportData(data),
  ])

  if(level <= 4) {
    formatCells(sheet, level, number, data)
    sheet.mergeCells(cursor, level, cursor, 4)
  }

  //выравнивание генерируемого контента
  sheet.getCell(`F${cursor}`).alignment = { horizontal: 'right', vertical: 'middle' }
  sheet.getCell(`G${cursor}`).alignment = { horizontal: 'right', vertical: 'middle' }

  ++cursor
  sheet.getRow(number).outlineLevel = level

  if(data.children) {
    //@ts-expect-error
    data.children.forEach(reportItem => {
      iterateReport(sheet, cursor, reportItem, level + 1)
    })
  }
}

export type PreparedReport = {
  area: number
  timeAllWorkModes: number
  timeHarvest: number
  allWorkModesPerformance: number
  harvestPerformance: number
}

type CreateExcelFileArgs = {
  userLogin: string
  t: TTranslate
  reportDate: string
  preparedReport: PreparedReport[]
  selectedUnitsNames: string[]
  selectedEmployeesNames: string[]
  levelsTitles: {
    level1: string
    level2: string
  }
}

export const createExcelFile = async ({
  levelsTitles,
  selectedEmployeesNames,
  selectedUnitsNames,
  preparedReport,
  reportDate,
  t,
  userLogin,
}: CreateExcelFileArgs) => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  let withFilters = []
  !isEmpty(selectedUnitsNames) && withFilters.push(`${t('sht')}: ${selectedUnitsNames.join(', ')}`)
  !isEmpty(selectedEmployeesNames) && withFilters.push(`${t('operators')}: ${selectedEmployeesNames.join(', ')}`)

  const total = preparedReport.reduce((acc, report) => {
    acc.area = acc.area + (report.area || 0)
    acc.timeAllWorkModes = acc.timeAllWorkModes + report.timeAllWorkModes
    acc.timeHarvest = acc.timeHarvest + report.timeHarvest
    acc.allWorkModesPerformance = acc.allWorkModesPerformance + (report.allWorkModesPerformance || 0)
    acc.harvestPerformance = acc.harvestPerformance + (report.harvestPerformance || 0)
    return acc
  }, { allWorkModesPerformance: 0, area: 0, harvestPerformance: 0, timeAllWorkModes: 0, timeHarvest: 0 })

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/performance.xlsx`)

    //@ts-expect-error
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  //@ts-expect-error
  for(const row of sheet.model.rows) {
    if(row.cells[0].value && row.cells[0].value.indexOf('#DATA') === 0) {
      cursor = row.number

      preparedReport.forEach(reportItem => {
        iterateReport(sheet, cursor, reportItem, 1)
      })
    }
  }

  // Remove placeholder
  sheet.spliceRows(cursor, 1)

  // Merge for fix lib bug
  sheet.mergeCells(`A${cursor}:D${cursor}`)

  //create header
  // eslint-disable-next-line max-len
  sheet.getRow(1).getCell(5).value = `${t('formed')}: ${ userLogin }, ${formats.date.dateViewFormatter(createDateTime, 'ru')}, ${timeFormat(createDateTime)}`
  sheet.getRow(2).getCell(1).value = `${t('performance report for the period')}: ${reportDate}`
  sheet.getCell('A3').value = `${t('the report is generated with filters')}: ${withFilters.join(', ')}`
  sheet.getCell('A4').value = levelsTitles.level1
  sheet.getCell('B5').value = levelsTitles.level2

  sheet.getCell('E4').value = `${t('Treated area')}, ${t('ha')}`
  sheet.getCell('F4').value = `${t('total running time')}, ${t('hh:mm:ss')}`
  sheet.getCell('G4').value = `${t('incl')} ${t('harvest')}, ${t('hh:mm:ss')}`
  sheet.getCell('H4').value = `${t('Average performance sum')}, ${t('ha/h')}`
  sheet.getCell('I4').value = `${t('Average performance run mode')}, ${t('ha/h')}`

  //задаётся форматирование для столбцов
  sheet.getColumn('E').numFmt = '#,##0.##'
  sheet.getColumn('F').numFmt = 'HH:MM:SS'
  sheet.getColumn('G').numFmt = 'HH:MM:SS'
  sheet.getColumn('H').numFmt = '#,##0.##'
  sheet.getColumn('I').numFmt = '#,##0.##'

  //create footer
  const rowTotal = sheet.getRow(cursor)
  rowTotal.outlineLevel = 1
  rowTotal.getCell(1).value = `${t('total according to the report')}:`.toUpperCase()

  //@ts-expect-error
  getReportData(total).map((value, index) => rowTotal.getCell(5 + index).value = value)

  // Force download report
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'performance_report.xlsx'
    anchor.click()
    window.URL.revokeObjectURL(url)
  })
}
