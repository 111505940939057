import { hooks,Select, SelectItem } from '@agdt/agrotronic-react-components'
import { isNil } from 'ramda'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { TMapParams } from '../../constants'

type TProps = {
  handleSelect: (type: string) => void
  data: TMapParams
  title?: string
  handleClear: () => void
}

export default function SelectMapType({
  data,
  handleClear,
  handleSelect,
  title,
}: TProps) {
  const popupState = hooks.usePopup()
  const t = useTranslate()

  const onChange = useCallback(v => {
    if(isNil(v)) {
      handleClear()
    }
  }, [])

  return (
    <Select
      disableClear={!Boolean(title)}
      isPopupOpen={popupState.isShown}
      label={t('Map type')}
      onChange={onChange}
      onHidePopup={popupState.close}
      onShowPopup={popupState.open}
      title={title || t('Select map tool')}
    >
      {data.map(item =>
        <SelectItem
          key={item.type}
          item={item}
          onClick={v => handleSelect(v.type)}
        >
          {t(item.name)}
        </SelectItem>,
      )}
    </Select>
  )
}
