import { formats, Select, SelectItem, utils } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
import React, { FC,useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { UserInfoType } from 'reducers/user/state'
import styled from 'styled-components'
import { GMT } from 'utils'
import { emailValidators, notEmptyValidator, passwordValidators, phoneValidator, validate } from './common/validators'
import { LoginInput } from './LoginInput'
import { MfaSwitch } from './MfaSwitch'
import { UserPhoneEmailInputs } from './UserPhoneEmailInputs'
import { ValidatedInputWrapper } from './ValidatedInputWrapper'

const { phone } = formats

const { screenResolutions } = utils

type Props = {
  user: UserInfoType
  disabled: boolean
  handleSave: (field: string, value: unknown) => void
  isFieldSave: Record<string, boolean>
  updateUserInfo: (value: UserInfoType) => void
}

export const Info: FC<Props> = ({ disabled, handleSave, isFieldSave, updateUserInfo, user }) => {
  const [GMTOpen, setGMTOpen] = useState(false)
  const handleGMTOpen = () => setGMTOpen(!GMTOpen)
  const t = useTranslate()
  const notEmptyValidate = validate(notEmptyValidator)

  const passwordValidate = useCallback((value: string): string | undefined => {
    if(isEmpty(value)) {
      return
    }

    return validate(passwordValidators)(value)
  }, [])

  const handleUpdateUserInfo = useCallback((fieldName, value) => {
    updateUserInfo({
      ...user,
      [fieldName]: value,
    })
  }, [updateUserInfo, user])

  const handleSetGMT = (value: string) => handleSave('gmt', value)

  const onClickZone = (zone: string) => () => {
    handleSetGMT(zone)
  }

  return <Block>
    <Title>{t('profile')}</Title>

    <InputBlocks>
      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorSurname']}
        disabled={disabled}
        disableClear
        fieldName={'operatorSurname'}
        handleSave={handleSave}
        initValue={user.lastName}
        label={t('Surname')}
        required
        testid='profile-info__surname'
        validator={notEmptyValidate}
      />

      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorFirstName']}
        disabled={disabled}
        disableClear
        fieldName={'operatorFirstName'}
        handleSave={handleSave}
        initValue={user.fullName}
        label={t('Name')}
        required
        testid='profile-info__name'
        validator={notEmptyValidate}
      />

      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorSecondName']}
        data-testid='profile-info__middlename-input'
        disabled={disabled}
        disableClear
        fieldName={'operatorSecondName'}
        handleSave={handleSave}
        initValue={user.middleName}
        label={t('middle name')}
        testid='profile-info__middlename'
      />

      <Select
        disableClear
        disabled={disabled || isFieldSave['gmt']}
        isPopupOpen={GMTOpen}
        label="GMT"
        onHidePopup={handleGMTOpen}
        onShowPopup={handleGMTOpen}
        title={user?.gmt && user.gmt > 0 ? `+${user.gmt}` : String(user.gmt)}
      >
        {GMT.map(zone =>
          <SelectItem
            key={zone}
            onClick={onClickZone(zone)}
          >
            {zone}
          </SelectItem>,
        )}
      </Select>

      {user.accountType === 'user' && <>
        <LoginInput
          dataIsSave={isFieldSave['operatorLogin']}
          disabled={disabled}
          disableClear
          fieldName={'operatorLogin'}
          handleSave={handleSave}
          initValue={user.operatorLogin}
          label={t('Login')}
          required
          testid='profile-info__login'
        />

        <ValidatedInputWrapper
          dataIsSave={isFieldSave['operatorPassword']}
          data-testid='profile-info__password-input'
          disabled={disabled}
          disableClear
          fieldName={'operatorPassword'}
          handleSave={handleSave}
          initValue={''}
          label={t('Password')}
          testid='profile-info__password'
          validator={passwordValidate}
        />
      </>}

      {/*для пользовательской учётной записи требуется подтверждение телефона или почты */}
      {user.accountType === 'user' && <>
        <UserPhoneEmailInputs
          disabled={disabled}
          handleUpdateUserInfo={handleUpdateUserInfo}
          t={t}
          user={user}
        />

        <MfaSwitch
          handleUpdateUserInfo={handleUpdateUserInfo}
          user={user}
        />
      </>}

      {/* TODO после отключения учётных записей предприятий нужно удалить эти поля */}
      {user.accountType === 'account' && <>
        <ValidatedInputWrapper
          dataIsSave={isFieldSave['telNumber']}
          disableClear
          disabled={disabled}
          fieldName={'telNumber'}
          formatter={phone.phoneMask}
          handleSave={handleSave}
          initValue={user.telNumber}
          label={t('Phone')}
          postProcessValue={phone.onlyDigits}
          required
          testid='profile-info__account-phone'
          validator={validate(phoneValidator)}
        />

        <ValidatedInputWrapper
          dataIsSave={isFieldSave['email']}
          disableClear
          disabled={disabled}
          fieldName={'email'}
          handleSave={handleSave}
          initValue={user.email}
          label="E-mail"
          required
          testid='profile-info__account-email'
          validator={validate(emailValidators)}
        />
      </>}

    </InputBlocks>
  </Block>
}

const Block = styled.div`
  background-color: #fff;
  padding: 24px 24px 52px 24px;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: ${screenResolutions.SXGA}) {
    padding: 24px;
  };
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 17px;
`

const InputBlocks = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 24px;
  @media (max-width: ${screenResolutions.SXGA}) {
    grid-template-columns: repeat(2, 1fr);
  };
  @media (max-width: ${screenResolutions.XGA}) {
    grid-template-columns: auto;
  };
`
