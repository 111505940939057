import LegendItemsList from 'components/LegendItemsList'
import React, { FC } from 'react'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { Control } from 'utils/LeafletCustomControl'
import { mapTypes } from '..'

type LegendProps = {
  unitName: string
  legendValues: number[]
  t: TTranslate
  setLegendValues: (id: string, values: number[]) => void
}

export const Legend: FC<LegendProps> = ({ unitName, legendValues, setLegendValues, t }) => {
  const type = 'TRACK_SPEED'

  return <Control position='bottomright'>
    <LegendContainer>
      <LegendContainerName>
        <p>{unitName}</p>
        <p>{`${t(mapTypes[type].name)}, ${t(mapTypes[type].measure)}`}</p>
      </LegendContainerName>
      <LegendItemsList
        colors={mapTypes[type].colors}
        id={unitName}
        legendValues={legendValues}
        setLegendValues={setLegendValues}
        t={t}
        values={mapTypes[type].values}
      >
        {color => <LegendItem color={color} />}
      </LegendItemsList>
    </LegendContainer>
  </Control>
}

const LegendContainer = styled.div`
  height: 50px;
  overflow: hidden;
  margin: auto;
  bottom: 30px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  z-index: 9000;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  background-color: #f5f5f5;
`

const LegendContainerName = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 27px;
`

const LegendItem = styled.span`
  background-color: ${({ color }) => color};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  ~ span {
    margin-right: 17px;
  }
`
