import { TProps as TWidgetProps,Widget } from 'components/Widget'
import React from 'react'
import styled from 'styled-components'
import Activity from 'ui/Activity'

type TProps = {
  onClose: () => void
} & TWidgetProps

export const ActivityWidget = ({ className, onClose, ...restProps }: TProps) => <WidgetStyled
  className={className}
  {...restProps}
  isStratchable={true}
>
  <Activity onClose={onClose} />
</WidgetStyled>

const WidgetStyled = styled(Widget)`
  overflow-y: hidden;
`
