import { AppMenu, HelpMenu, SiteControl as Logo, utils } from '@agdt/agrotronic-react-components'
import { Privilege } from '@agdt/api'
import { isOldSuperAdmin } from '@agdt/user'
import UserMenu from 'components/UserMenu'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { useHistory } from 'react-router-dom'
import { useDemoMode } from 'services/hooks'
import styled from 'styled-components'
import { TUser } from 'types'
import { LANG_RU } from '../../constants/lang'
import { filterTypeUsers } from '../../reducers/users/actions'
import { getStore } from '../../stores/storesRegistry'
import DesktopMenu from './Menu/Desktop'
import { useMenuItems } from './Menu/useMenuItems'

const { screenResolutions } = utils

export type TProps = {
  children?: React.ReactNode
  user: TUser
}

function Header({ children, user }: TProps) {
  const history = useHistory()
  const t = useTranslate()
  const currentUserStore = getStore('currentUser')

  // API пользователя не имплементированно, поэтому как временное решение администратор определяется по логину "admin".
  // user?.login только для этого и, вероятно, будет удалено.
  const menuItems = useMenuItems({
    isAdmin            : user?.isAdmin,
    isAdminLogin       : isOldSuperAdmin(user),
    isSysAdmin         : currentUserStore.isSysAdmin,
    isOrgAdmin         : currentUserStore.isOrgAdmin,
    canEditOrganization: currentUserStore.hasPrivilege(
      Privilege.IdEnum.EDIT_ORGANIZATION,
    ),
  })

  const isDemo = useDemoMode()

  useEffect(() => {
    if(!isDemo) {currentUserStore.loadCurrentUser()}
  }, [])

  return <>
    <Container >
      <LogoStyled
        lang={user?.lang !== LANG_RU ? 'en' : 'ru'}
        withBackground
      />

      <DesktopMenu
        items={menuItems}
        user={user}
      />

      {/*@ts-expect-error*/}
      <HelpMenu t={t} gotoLink={history.push} />

      <HidableUserMenu>
        <UserMenu info={user} />
      </HidableUserMenu>
    </Container>
    {children}
  </>
}

export default connect(
  state => ({

    /*@ts-expect-error*/
    user: state.user.info,
  }),
  dispatch => ({

    /*@ts-expect-error*/
    filterUnits: (models, id) => dispatch(filterTypeUsers(models, id)),
  }))(Header)

//@ts-ignore пишет что ts-expect-error не используется, если убрать ошибка, вероятно нужно разбираться с AppMenu из компонентов
const Container = styled(AppMenu)`
  height: 60px;
  justify-content: space-between;
  align-items: center;
  color: white;
  transition-property: background-color;
  transition-duration: .5s;
  @media (max-width: ${screenResolutions.PAL}) {
    position: sticky;
    top: 0;
    z-index: 9999;
  };
`

const HidableUserMenu = styled.div`
  @media (max-width: ${screenResolutions.PAL}) {
    display: none;
  };
`

const LogoStyled = styled(Logo)`
  @media (max-width: ${screenResolutions.PAL}) {
    background-color: transparent;
  };
`
