import moment from 'moment/moment'
import React, { FC } from 'react'

export type ActiveRange = {
  from: moment.MomentInput
  to: moment.MomentInput
}

export type ColorType = {
  day: string
  month: string
  year: string
  color: string
}

type DayProps = {
  day: moment.Moment
  permissions?: number
  history?: number
  activeRange?: ActiveRange
  format: string
  isSoloCalendar: boolean
  oneActiveDay?: moment.MomentInput
  colors?: ColorType[]
  setRange: (value: moment.Moment) => void
}

export const Day: FC<DayProps> = ({
  day,
  permissions,
  history,
  activeRange,
  format,
  isSoloCalendar,
  oneActiveDay,
  colors,
  setRange,
}) => {
  const isInPeriod = () => {
    if(permissions && activeRange?.from) {
      return Math.abs(moment(activeRange.from).diff(day, 'days')) < permissions
    }

    if(history && Math.abs(moment().diff(day, 'days')) >= history) {
      return false
    }

    return true
  }

  const getActiveClass = () => {
    if(activeRange?.from && !activeRange.to && moment(day).format(format) ===
    moment(activeRange.from).format(format)) {
      return '--startSolo'
    }

    if(!isInPeriod() || !isAfter) {
      return '--shadow'
    }

    return activeRange?.from
      ? moment(day).isAfter(activeRange.from, 'day') && moment(day).isBefore(activeRange.to, 'day')
        ? '--active'
        : moment(day).format(format) ===
        moment(activeRange.to).format(format)
          ? '--end'
          : moment(day).format(format) ===
        moment(activeRange.from).format(format)
            ? '--start'
            : ''
      : ''
  }

  const isAfter = moment().isAfter(day)
  const today = moment(day).format(format) === moment().format(format)

  const activeSolo =
    moment(oneActiveDay).format(format) === moment(day).format(format) && (!today || isSoloCalendar)

  const colorItem =
    isAfter && isInPeriod() && colors?.length
      ? colors.find(
        color =>
          color.day === moment(day).format('D') &&
            color.month === moment(day).format('M') &&
            color.year === moment(day).format('YYYY'),
      )
      : null

  const onClick = () => {
    if(isAfter && isInPeriod()) {
      setRange(day)
    }
  }

  return (
    <div
      onClick={onClick}
      style={
        activeSolo
          ? {
            animation : 'border-opacity 3s infinite ease-in-out',
            background: colorItem?.color || 'none',
          }

          : { ...colorItem && { background: colorItem.color } }
      }
      className={`Date-Month__day Date-Month__day${getActiveClass()}`}
    >
      {moment(day).format('DD')}
    </div>
  )
}

type DayShadowProps = {
  day: moment.MomentInput
  activeRange?: ActiveRange
  format: string
}

export const DayShadow: FC<DayShadowProps> = ({ day, activeRange, format }) => {
  const active = activeRange?.from
    ? moment(day) < moment(activeRange.to) && moment(day) > moment(activeRange.from)
      ? '--active'
      : moment(day).format(format) === moment(activeRange.to).format(format)
        ? '--start'
        : moment(day).format(format) ===
        moment(activeRange.from).format(format)
    : ''

  return (
    <div className={`Date-Month__day Date-Month__day--shadow ${active}`}>
      {moment(day).format('DD')}
    </div>
  )
}
