import { constants, Select, Tree } from '@agdt/agrotronic-react-components'
import {
  assocPath,
  forEachObjIndexed, fromPairs,
  groupBy,
  head,
  identity,
  isEmpty,
  keys,
  map,
  mapObjIndexed,
  pathOr,
  pick,
  pickBy,
  pipe,
  prop,
  T, values,
} from 'ramda'
import React, { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'

const C_CLEAR_ALL = Symbol('clearall')
const typeKey = (category: unknown, id: unknown) => id ? `type-${category}-${id}` : `category-${category}`
const C_TYPE_REGEXP = /type-(?<categoryId>\d+)-((?<id>.+))/
const C_CAT_REGEXP = /category-(?<categoryId>\d+)/

export type Option = {
  name: string
  items?: {
    name: string
    id: unknown
  }[]
  categoryId: string | number
}

type GetTypeNameType = (value: unknown) => string

type SearchReducerArgs = {
  value?: string | null
  inOptions: Option[]
  getTypeName: GetTypeNameType
}

const searchReducer = ({ value, inOptions, getTypeName }: SearchReducerArgs) => {
  let options = inOptions
  const trimmedValue = value?.trim().toLowerCase() || ''

  if(trimmedValue) {
    options = options.map(element => ({
      ...element,
      ...element.items && {
        items: element.items.filter(item =>
          item.name.toLowerCase().includes(trimmedValue) ||
          getTypeName(item)?.toLowerCase().includes(trimmedValue),
        ),
      },
    })).filter(element =>
      element.items?.length ||
      element.name.toLowerCase().includes(trimmedValue) ||
      getTypeName(element)?.toLowerCase().includes(trimmedValue),
    )
  }

  const newGroups = options.reduce((acc, { categoryId, items }) => ({
    ...acc,
    ...categoryId && items && {
      [categoryId]: groupBy(
        getTypeName,
        items.map(item => ({ ...item, pathId: [categoryId, item.id] })),
      ),
    },
  }), {})

  return { groups: newGroups, items: options, value }
}

const fillCheckedTypesForTypeName = (getTypeName: GetTypeNameType) => (
  checked: Record<string| number, unknown[]>,
  { items, groups } : {
    items : {
      categoryId: string | number
    }[]
    groups: Record<number, Record<string| number, unknown[]>>
  },
) => {
  const checkedGroups: Record<string, unknown> = {}

  const totalItemsByCategory = mapObjIndexed(
    pathOr(0, [0, 'items', 'length']),

    //@ts-expect-error
    groupBy(prop('categoryId'), items),
  )

  forEachObjIndexed((categories, categoryId) => {
    let checkedForCat = 0

    forEachObjIndexed((typeUnits, typeName) => {
      //@ts-expect-error
      const checkedCountForType: number = pipe(

        //@ts-expect-error // pick only values for current type
        pick(groupBy(getTypeName, typeUnits)[typeName].map(prop('id'))),

        // Filter only checked values
        pickBy(identity),

        // to array
        keys,
      )(checked[categoryId] || []).length

      checkedForCat += checkedCountForType

      checkedGroups[typeKey(categoryId, typeName)] = checkedCountForType

        // If checked only part of group
        && checkedCountForType !== groups[categoryId][typeName].length
        ? null

        // Otherwise check / uncheck group
        : checkedCountForType === groups[categoryId][typeName].length
    }, categories)

    //@ts-expect-error
    checkedGroups[typeKey(categoryId)] = checkedForCat && checkedForCat !== totalItemsByCategory[categoryId]
      ? null
      : checkedForCat === totalItemsByCategory[categoryId]
  }, groups)

  return checkedGroups
}

//@ts-expect-error
const reducer = (inOptions: Option[], getTypeName: GetTypeNameType) => (state, action) => {
  const fillCheckedTypes = fillCheckedTypesForTypeName(getTypeName)
  const { checked, search } = state
  const allItems = searchReducer( { getTypeName, inOptions } )

  if(action.search) {
    return {
      checked,
      groups: fillCheckedTypes(checked, allItems),
      search: searchReducer({
        ...search,
        getTypeName,
        inOptions,
        value: action.value,
      }),
    }
  } else if(action.id === C_CLEAR_ALL) {
    return {
      checked: {},
      groups : fillCheckedTypes(
        {},
        { groups: [], items: [] },
      ),
      search,
    }
  } else if(action.id.match(C_TYPE_REGEXP)){
    const newChecked = { ...checked }
    const targetId = action.id.match(C_TYPE_REGEXP)

    //@ts-expect-error
    for(const U of allItems.groups[targetId.groups.categoryId][targetId.groups.id]) {
      if(!newChecked[targetId.groups.categoryId]) {
        newChecked[targetId.groups.categoryId] = {}
      }

      newChecked[targetId.groups.categoryId][U.id] = action.state
    }

    return {
      checked: newChecked,
      groups : fillCheckedTypes(newChecked, allItems),
      search,
    }
  } else if(action.id.match(C_CAT_REGEXP)){
    const newChecked = { ...checked }
    const targetId = action.id.match(C_CAT_REGEXP)

    //@ts-expect-error
    for(const items of values(allItems.groups[targetId.groups.categoryId])) {
      for(const U of items) {
        if(!newChecked[targetId.groups.categoryId]) {
          newChecked[targetId.groups.categoryId] = {}
        }

        newChecked[targetId.groups.categoryId][U.id] = action.state
      }
    }

    return {
      checked: newChecked,
      groups : fillCheckedTypes(newChecked, allItems),
      search,
    }
  } else if(action.isLeaf && action.id.match(/^(\d+)$/)){
    const newChecked = assocPath(action.pathId.map(String), action.state, checked)

    return {
      checked: newChecked,

      //@ts-expect-error
      groups: fillCheckedTypes(newChecked, allItems),
      search,
    }
  }

  return state
}

type MultiselectWithSearchProps = {
  filterName: string
  checkedOptionsIds: unknown
  label: unknown
  onHideList?: () => void
  options: Option[]
  searchInputLabel: unknown
  setCheckedIds: (name: string, record: Record<string, number[]>) => void
  width?: string
  noGroups?: boolean
  popupFitContent?: unknown
  getTypeName?: GetTypeNameType
}

export const MultiselectWithSearch: FC<MultiselectWithSearchProps> = ({
  filterName,
  checkedOptionsIds,
  label,
  onHideList,
  options,
  searchInputLabel,
  setCheckedIds,
  width,
  noGroups = false,
  popupFitContent,
  getTypeName = prop('typeName'),
}) => {
  const [showList, setShowList] = useState(false)
  const t = useTranslate()
  const treeRef = useRef()
  const treeContainer = useRef<HTMLDivElement>(null)

  const [state, dispatchCheck] = useReducer(
    useMemo(() => reducer(options, getTypeName as GetTypeNameType), [options]),

    useMemo(() => {
      const initialSearch = searchReducer({
        getTypeName: getTypeName as GetTypeNameType,
        inOptions  : options,
        value      : '',
      })

      const initialChecked = checkedOptionsIds ?

        //@ts-expect-error
        mapObjIndexed(pipe(groupBy(identity), map(T)), checkedOptionsIds)
        : {}

      return {
        checked: initialChecked,
        groups : fillCheckedTypesForTypeName(getTypeName as GetTypeNameType)(initialChecked, initialSearch),
        search : initialSearch,
      }
    }, [checkedOptionsIds, options]),
  )

  const checkedItemsNames = useMemo(() => {
    const names = []

    for(const [catId, checkedItems]of Object.entries(state.checked)) {
      const optionsCat = options.find(C => C.categoryId === Number(catId))

      //@ts-expect-error
      const optionsCatItems = pipe(groupBy(U => String(U.id)), mapObjIndexed(head))(optionsCat?.items || [])

      //@ts-expect-error
      for(const id in checkedItems) {
        //@ts-expect-error
        if(!checkedItems[id]) { continue }

        const item = optionsCatItems[Number(id)]

        if(item) {
          //@ts-expect-error
          names.push(item.name)
        }
      }
    }

    return names
  }, [options, state.checked])

  const toggleChecked = useCallback((id, pathId, checked, isLeaf) => {
    dispatchCheck({ id: String(id), isLeaf, pathId, state: checked })
  }, [])

  const clearAll = useCallback(() => {
    dispatchCheck({ id: C_CLEAR_ALL })
  }, [])

  const setSearchValue = useCallback(value => { dispatchCheck({ search: true, value }) }, [])

  const clickOutside = useCallback(event => {
    if(!treeContainer.current || !treeContainer.current.contains(event.target)) {
      if(showList) {
        setShowList(false)
        onHideList && onHideList()
      }
    }
  }, [showList])

  const treeWalker = useCallback(function* treeWalker() {
    //@ts-expect-error
    const stack = state.search.items.map(item => ({

      //@ts-expect-error
      checked     : state.groups[typeKey(item.categoryId)],
      nestingLevel: 0,

      node: {
        children: noGroups

        //@ts-expect-error
          ? item.items.map(I => ({ ...I, pathId: [item.categoryId, I.id] }))
          : state.search.groups[item.categoryId],

        //@ts-expect-error
        id    : typeKey(item.categoryId),
        name  : item.name,
        pathId: [item.categoryId],
      },
    }), [])

    while(stack.length !== 0) {
      const {
        node: { children = [], id, pathId, name },
        nestingLevel,
        checked,
      } = stack.pop()

      const isOpened = yield {
        checked,
        id,
        isLeaf: !children || children.length === 0,
        name,
        nestingLevel,

        //@ts-expect-error
        onChange: (idElement, isChecked) => {
          toggleChecked(idElement, pathId, isChecked, !children || children.length === 0)
        },
      }

      if(!isEmpty(children) && (isOpened || !isEmpty(state.search.value))) {
        if(nestingLevel === 0 && !noGroups) {
          const catId = id.match(C_CAT_REGEXP).groups.categoryId

          Object.entries(children).forEach(([K, U]) => stack.push({
            checked     : state.groups[typeKey(catId, K)],
            nestingLevel: nestingLevel + 1,
            node        : {
              children: U,
              id      : typeKey(catId, K),

              //@ts-expect-error
              name  : getTypeName(head(U)),
              pathId: [catId, K],
            },
          }))
        } else {
          for(let i = children.length - 1; i >= 0; i--) {
            stack.push({
              checked     : pathOr(false, children[i].pathId, state.checked),
              nestingLevel: nestingLevel + 1,
              node        : children[i],
            })
          }
        }
      }
    }
  }, [state.search.items, state.search.groups, state.groups, state.checked, noGroups, toggleChecked])

  useEffect(() => {
    forEachObjIndexed((items: Array<string | number>, catId) => {
      items.forEach(id => {
        if(!pathOr(false, [catId, id], state.checked)) {
          toggleChecked(id, [catId, id], true, true )
        }
      })
    }, checkedOptionsIds)

    forEachObjIndexed((items, catId) => {
      forEachObjIndexed((checked, id) => {
        //@ts-expect-error
        if(checked && (!checkedOptionsIds[catId] || !checkedOptionsIds[catId].includes(Number(id)))) {
          toggleChecked(id, [catId, id], false, true )
        }
      }, items)
    }, state.checked)
  }, [checkedOptionsIds])

  useEffect(() => {
    if(treeRef.current){
      //@ts-expect-error
      treeRef.current.recomputeTree({
        refreshNodes: true,
      })
    }
  }, [state.checked, state.groups])

  useEffect(() => {
    if(treeRef.current){
      //@ts-expect-error
      treeRef.current.recomputeTree({

        //@ts-expect-error
        ...fromPairs(keys(state.search.groups).map(I => [typeKey(I), !isEmpty(state.search.value)])),
      })
    }
  }, [state.search.items])

  useEffect(() => {
    setCheckedIds(filterName, mapObjIndexed(
      pipe(pickBy(identity), keys, map(Number)),
      state.checked,
    ))
  }, [filterName, state.checked])

  const onShowPopup = () => {
    setShowList(true)
  }

  return (
    <StyledSelect
      disableClear
      fitContent={popupFitContent}
      isPopupOpen={showList}
      label={label}
      onShowPopup={onShowPopup}
      onHidePopup={clickOutside}
      title={checkedItemsNames.join(', ')}
      width={width}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <FormWrapper ref={treeContainer}>
        <Tree

          //@ts-expect-error
          searchInputLabel={searchInputLabel}
          treeWalker={treeWalker}
          ref={treeRef}
          setSearchValue={setSearchValue}
          searchValue={state.search.value}
          defaultWidht={350}
        />
      </FormWrapper>

      {isEmpty(state.search.items)
        ? <EmptyListWrapper>{t('')}</EmptyListWrapper>
        : <ClearAllWrapper onClick={clearAll}>{t('clear')}</ClearAllWrapper>
      }
    </StyledSelect>
  )
}

const FormWrapper = styled.div`
  width: 320px;
  ${constants.css.scrollStyleMixin};
  overflow-y: auto;
  padding-right: 8px;
  margin-left: 24px;
  display: flex;
  flex-grow: 1;
`

const EmptyListWrapper = styled.div`
  color: #777373;
  text-align: center;
  padding: 5px 0 3px 0;
  font-size: 14px;
`

const ClearAllWrapper = styled.div`
  color: #D10029;
  float: right;
  font-size: 16px;
  margin: 0 24px 9px 0;
  cursor: pointer;
`

const StyledSelect = styled(Select)`
  width: ${({ width }) => width || '350px' };
  margin-left: 15px;
`
