import { Button, formats, icons } from '@agdt/agrotronic-react-components'
import { LANG_RU } from 'constants/lang'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import { LEVEL_TITLES } from '../../constants'
import { createExcelFile,PreparedReport } from './ToExcellHandler'

const { dateViewFormatter, timeFormatter } = formats.date

type ToExcelProps = {
  selectedDate: {
    from: string
    to: string
  }
  checkedFiltersIds: {
    machines?: number[][]
    employees?: number[][]
  }
  units: {
    id: number
    name: string
  }[]
  employees: {
    id: number
    operatorSurname: string
    operatorFirstName: string
  }[]
  preparedReport: PreparedReport[]
  currentTab: 'OPERATOR_SHT' | 'SHT_OPERATOR'
}

export const ToExcel: FC<ToExcelProps> = ({
  checkedFiltersIds,
  currentTab,
  employees,
  preparedReport,
  selectedDate,
  units,
}) => {
  const userLogin = useSelector((state: AppStateType) => state.user.info.operatorLogin || state.user.info.login) || ''
  const t = useTranslate()

  const exportToExcelHandler = () => {
    const selectedUnitsNames = units.reduce<string[]>(
      (acc, item) => checkedFiltersIds?.machines?.[1]?.includes(item.id) ? acc.concat(item.name) : acc,
      [])

    const selectedEmployeesNames = employees.reduce<string[]>(
      (acc, employee) => checkedFiltersIds?.employees?.[1]?.includes(employee.id)
        ? acc.concat(`${employee?.operatorSurname || ''} ${employee?.operatorFirstName || ''}`)
        : acc,
      [])

    const parsedFrom = formats.api.parseDateTimeFromCalendarApi(selectedDate.from)
    const parsedTo = formats.api.parseDateTimeFromCalendarApi(selectedDate.to)

    const reportDate = `${dateViewFormatter(parsedFrom, LANG_RU)}, ${timeFormatter(parsedFrom, LANG_RU)} - ` +
    `${dateViewFormatter(parsedTo, LANG_RU)}, ${timeFormatter(parsedTo, LANG_RU)}`

    const levelsTitles = { level1: t(LEVEL_TITLES[currentTab][1]), level2: t(LEVEL_TITLES[currentTab][2]) }

    createExcelFile({
      selectedEmployeesNames, selectedUnitsNames, levelsTitles, preparedReport, reportDate, t, userLogin })
  }

  return <ExportButton onClick={exportToExcelHandler} variant="border">
    <>
      <icons.XlsIcon size="24" color="#32BE6E"/>
      <TitleButton>{t('Download')}</TitleButton>
    </>
  </ExportButton>
}

const ExportButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
  margin-top: 10px;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding-left: 4px;
`
