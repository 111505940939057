import { AppDispatch } from 'reducers/store'
import { FilterType } from './state'

export enum NotificationActionTypeEnum {
  CHANGE_LOADING_NOTIFICATIONS = 'App/Notification/CHANGE_LOADING_NOTIFICATIONS',
  SET_SELECTED_MACHINE = 'App/Notification/SET_SELECTED_MACHINE',
  SET_DATE_RANGE = 'App/Notification/SET_DATE_RANGE',
  SET_FILTER = 'App/Notification/SET_FILTER',
  SET_PAGE = 'App/Notification/SET_PAGE',
  SET_PAGE_SIZE = 'App/Notification/SET_PAGE_SIZE',
  SET_REGISTER_DATA = 'App/Notification/SET_REGISTER_DATA',
  SET_REGISTER = 'App/Notification/SET_REGISTER',
  CLEAR_REGISTER = 'App/Notification/CLEAR_REGISTER',
  SET_REGISTER_VALUES = 'App/Notification/SET_REGISTER_VALUES',
  SET_CURRENT_REGISTER = 'App/Notification/SET_CURRENT_REGISTER',
  CHANGE_LOADING_CALENDAR_PAINTING = 'App/Notification/CHANGE_LOADING_CALENDAR_PAINTING',
}

export type NotificationActionsType = {
  type: NotificationActionTypeEnum.CHANGE_LOADING_NOTIFICATIONS
  payload: boolean
} | {
  type: NotificationActionTypeEnum.CHANGE_LOADING_CALENDAR_PAINTING
  payload: boolean
} | {
  type: NotificationActionTypeEnum.SET_SELECTED_MACHINE
  payload: unknown
} | {
  type: NotificationActionTypeEnum.SET_DATE_RANGE
  payload: unknown
} | {
  type: NotificationActionTypeEnum.SET_FILTER
  payload: FilterType
} | {
  type: NotificationActionTypeEnum.SET_REGISTER_VALUES
  payload: unknown
} | {
  type: NotificationActionTypeEnum.SET_REGISTER
  payload: unknown
} | {
  type: NotificationActionTypeEnum.CLEAR_REGISTER
} | {
  type: NotificationActionTypeEnum.SET_REGISTER_DATA
  payload: unknown
} | {
  type: NotificationActionTypeEnum.SET_CURRENT_REGISTER
  payload: unknown
} | {
  type: NotificationActionTypeEnum.SET_PAGE
  payload: number
} | {
  type: NotificationActionTypeEnum.SET_PAGE_SIZE
  payload: number
}

export const changeLoadingNotifications = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch({ payload: data, type: NotificationActionTypeEnum.CHANGE_LOADING_NOTIFICATIONS })
}

export const changeLoadingCalendarPainting = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch({ payload: data, type: NotificationActionTypeEnum.CHANGE_LOADING_CALENDAR_PAINTING })
}

export const setRegisterData = (data: unknown) => ({
  payload: data,
  type   : NotificationActionTypeEnum.SET_REGISTER_DATA,
})

export const clearRegister = () => ({ type: NotificationActionTypeEnum.CLEAR_REGISTER })

export const setRegister = (data: unknown) => ({ payload: data, type: NotificationActionTypeEnum.SET_REGISTER })

export const setRegisterValue = (data: unknown) => ({
  payload: data,
  type   : NotificationActionTypeEnum.SET_REGISTER_VALUES,
})

export const setCurrentRegister = (data: unknown) => ({
  payload: data,
  type   : NotificationActionTypeEnum.SET_CURRENT_REGISTER,
})

export const setFilter = (data: FilterType) => ({ payload: data, type: NotificationActionTypeEnum.SET_FILTER })

export const setPage = (page: number) => ({
  payload: page,
  type   : NotificationActionTypeEnum.SET_PAGE,
})

export const setPageSize = (pageSize: number) => ({
  payload: pageSize,
  type   : NotificationActionTypeEnum.SET_PAGE_SIZE,
})
