import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { identity, isEmpty, keys, times } from 'ramda'
import { prepareDuration } from 'ui/Map/duration'
import { TTranslate } from '../../../../types'
import { TimeInfo } from '../../../../ui/Filter/initFilter'
import { LANG_RU } from '../../../../constants/lang'

const columns = [
  {
    id   : 'model',
    title: 'model',
  },
  {
    id   : 'name',
    title: 'serial n',
  },
  {
    id   : 'holding',
    title: 'holding',
  },
  {
    id   : 'farm',
    title: 'farming',
  },
  {
    formatter: (sensor: boolean, translate: TTranslate): string => sensor ? translate('Yes') : translate('No'),
    id       : 'threshing_sensor',
    title    : 'Threshing sensor used',
  },
]

const periodColumns = [
  {
    id   : 'period_number',
    title: 'n period',
  },
  {
    formatter: (date: string): string => Date.parseDateTimeFromApi(date).format('DD.MM.YY HH:mm:ss'),
    id       : 'from',
    title    : 'startHarvesting',
  },
  {
    formatter: (date: string): string => Date.parseDateTimeFromApi(date).format('DD.MM.YY HH:mm:ss'),
    id       : 'to',
    title    : 'endHarvesting',
  },
  {
    formatter: (period: number): string => prepareDuration(period),
    id       : 'periodHarvesting',
    title    : 'periodHarvesting',
  },
  {
    formatter: (timeHarvest: number): string => prepareDuration(timeHarvest),
    id       : 'timeHarvest',
    title    : 'Harvesting time for period',
  },
  {
    id   : 'agriculture',
    title: 'harvested agriculture',
  },
  {
    id   : 'density',
    title: 'density',
  },
  {
    id   : 'harvest',
    title: 'Harvested crop for period',
  },
]

export type TReport = Record<
  string,
  Record<string | 'model' | 'name' | 'holding' | 'farm' | 'threshing_sensor', unknown>
    & {data: Omit<TimeInfo, 'today'>[] | Record<string, string>[]} // today исключено, т.к. по коду не испольуется
>

type TProps = {
  from: string
  to: string
  login: string
  report: TReport
  t: TTranslate
}

type TMergeCount = {
  r: number
  c: number
}

type TMerge = {
  s: TMergeCount
  e: TMergeCount
}

type TReportItem = {
  currentRow: number
  merges: TMerge[]
  units: unknown[]
}

export const createExcelFile = async ({ from, login, report, t, to }: TProps) => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  let cursor = 3

  const reportItems = keys(report).reduce<TReportItem>((acc, key) => {
    const unitsReport = columns.map(column => column.formatter
      ? column.formatter(!!report[key][column.id], t)
      : report[key][column.id],
    )

    if(!isEmpty(report[key].data)) {
      acc.units.push(...report[key].data.map((D, index: number) => unitsReport.concat(periodColumns.map(column => {
        if(column.id === 'period_number') {
          return index + 1
        }

        if(column.id === 'periodHarvesting') {

        }

        const data: string = column.id === 'periodHarvesting'
          ? Date.parseDateTimeFromApi(D.to).getTime() - Date.parseDateTimeFromApi(D.from).getTime()

          // @ts-expect-error
          : D[column.id]

        // @ts-expect-error
        return column.formatter ? column.formatter(data) : data
      }))))

      const addedRows = report[key].data.length - 1

      acc.merges.push(...times(identity, columns.length).map(num => ({
        e: { c: num + 1, r: acc.currentRow + addedRows },
        s: { c: num + 1, r: acc.currentRow },
      })))

      acc.currentRow = acc.currentRow + addedRows
    } else {
      acc.units.push(unitsReport)
    }

    acc.currentRow = acc.currentRow + 1
    return acc
  }, { currentRow: 4, merges: [], units: [] })

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/commonReport.xlsx`)

    // @ts-expect-error
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  //resize title row for full report length
  sheet.mergeCells(1, 2, 1, columns.length + periodColumns.length)

  //unmerge merged cells
  sheet.unMergeCells(2, 1, 2, 2)

  //resize title row for full report length
  sheet.mergeCells(2, 1, 2, columns.length + periodColumns.length)

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('B1').value = `${t('formed')}: ${ login }, ${formats.date.dateViewFormatter(createDateTime, LANG_RU)}, ${formats.date.timeFormatter(createDateTime, LANG_RU)}`
  // eslint-disable-next-line max-len
  sheet.getCell('A2').value = `${t('report cleaning')} ${Date.parseDateTimeFromCalendarApi(from).format('DD.MM.YY HH:mm:ss')} - ${Date.parseDateTimeFromCalendarApi(to).format('DD.MM.YY HH:mm:ss')}`

  // Generate column titles
  sheet.insertRow(cursor, [...columns.map(({ title }) => t(title)), ...periodColumns.map(({ title }) => t(title))])
  ++cursor

  // Generate report
  reportItems.units.forEach(unit => {
    sheet.insertRow(cursor, unit)

    ++cursor
  })

  // merge report cells
  reportItems.merges.forEach(M => {
    sheet.mergeCells(M.s.r, M.s.c, M.e.r, M.e.c)
  })

  // remove unused row
  sheet.spliceRows(cursor, 1)

  //resize footer for full report length
  sheet.mergeCells(cursor, 2, cursor, columns.length + periodColumns.length)

  //fix row size
  sheet.getColumn(1).width = 25
  sheet.getColumn(13).width = 30

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'cleaning_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
