import { isEmpty, isNil, length, reverse } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { VariableSizeTree } from 'react-vtree'
import styled from 'styled-components'
import { scrollStyleMixin } from '../../../../utils/styles'
import Node from './Node'

const C_ITEM_SIZE = 34

//@ts-expect-error
const TreePresenter = ({ items, openedFarm, result, setMachines, setMachine }) => {
  const treeRef = useRef(null)

  useEffect(() => {
    if(treeRef && treeRef.current) {
      //@ts-expect-error
      treeRef.current.recomputeTree({
        refreshNodes: true,
      })
    }
  }, [])

  const reversedItems = useMemo(() => reverse(items), [items])

  const treeWalker = useCallback(function* (){
    //@ts-expect-error
    const stack = []

    reversedItems.forEach(I => stack.push({

      //@ts-expect-error
      checked     : !isNil(result[I.id]) && (result[[I.id]].length >= length(I.units) ? true : null),
      nestingLevel: 0,
      node        : I,

      //@ts-expect-error
      nodeId: `${I.id}_${I.name}`,
    }))

    while(stack.length !== 0) {
      const {

        //@ts-expect-error
        node: { units = [], id, name },

        //@ts-expect-error
        nodeId,

        //@ts-expect-error
        nestingLevel,

        //@ts-expect-error
        checked,

        //@ts-expect-error
        setSingleMachines,

        //@ts-expect-error
      } = stack.pop()

      const isOpened = yield {
        checked,
        defaultHeight  : C_ITEM_SIZE,
        farmId         : id,
        id             : nodeId,
        isLeaf         : isEmpty(units),
        isOpenByDefault: openedFarm.includes(id),
        name,
        nestingLevel,
        setMachines,
        setSingleMachines,
      }

      if(units.length !== 0 && isOpened) {
        for(let i = units.length - 1; i >= 0; i--) {
          stack.push({

            //@ts-expect-error
            checked          : result[id] && result[id].some(r => r.imei === units[i].imei),
            nestingLevel     : nestingLevel + 1,
            node             : units[i],
            nodeId           : `${id}_${units[i].id}_${units[i].name}`,
            setSingleMachines: () => setMachine(id, units[i]),
          })
        }
      }
    }
  }, [reversedItems, result])

  return <ListStyled
    height={300}
    ref={treeRef}
    treeWalker={treeWalker}
    itemData={C_ITEM_SIZE}
  >
    {Node}
  </ListStyled>
}

export default TreePresenter

//@ts-expect-error
const ListStyled = styled(VariableSizeTree)`
  overflow-x: hidden !important;
  ${scrollStyleMixin}
`
