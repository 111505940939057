import 'leaflet-polylinedecorator'
import 'leaflet.marker.slideto/Leaflet.Marker.SlideTo.js'
import 'leaflet.polyline.snakeanim/L.Polyline.SnakeAnim.js'
import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React, { FC, useCallback, useState } from 'react'
import { Map } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import HouseholdUnitStore from 'stores/Households/HouseholdUnitStore'
import { ActiveTrackPathType } from 'stores/Households/TrackStore'
import { getStore } from 'stores/storesRegistry'
import { TTranslate } from 'types'
import { Control } from 'utils/LeafletCustomControl'
import { productivityValues } from '../constants'
import EventMarkers from '../EventMarkers'
import { Legend } from './Legend'
import { TrackItem, TrackType } from './TrackItem'

export const mapTypes = {
  TRACK_SPEED: {
    colors : ['#202020', '#06a9f5', '#78c800', '#fb8b14', '#d10a40'],
    measure: 'km/h',
    name   : 'speed',
    values : [0, 3, 6, 9, 12],
  },
}

type InfoProps = {
  style?: React.CSSProperties
  name: string
  color: [number, string | number][]
}

const Info: FC<InfoProps> = ({ color, name, style }) =>
  <Control position="bottomright">
    <div className="tracking-page__info" style={style || {}}>
      <p>{name}</p>
      {color.map((item, index) =>
        <span key={index} className={`tracking-page__color cl${item[0]}`}>
          {item[1]}
        </span>,
      )}
    </div>
  </Control>

type PanelProps = {
  style?: React.CSSProperties
  translate: TTranslate
  type: string
  unitName?: string
  legendValues?: Record<string, number[]>
  setLegendValues?: (id: string, values: number[]) => void
}

export const Panel: FC<PanelProps> = ({
  type,
  translate,
  style,
  unitName,
  legendValues,
  setLegendValues,
}) => {
  if(type === 'TRACK_HARVEST') {
    return (
      <Info
        name={translate('mode')}
        color={[[2, translate('M1')], [3, translate('M4')]]}
        style={style}
      />
    )
  }

  if(type === 'TRACK_SPEED' && unitName && legendValues && setLegendValues) {
    return (
      <Legend
        legendValues={legendValues[unitName]}
        setLegendValues={setLegendValues}
        t={translate}
        unitName={unitName}
      />
    )
  }

  if(type === 'TRACK_LOAD') {
    return (
      <Info
        name={translate('Engine load')}
        color={[[2, 25], [3, 50], [4, 75], [6, 100]]}
        style={style}
      />
    )
  }

  if(type === 'TRACK_PRODUCTIVITY_KUK') {
    return (
      <Info
        name={`${translate('Productivity')}, ${translate('t/ha')}`}
        color={productivityValues['KUK']}
        style={style}
      />
    )
  }

  if(type === 'TRACK_PRODUCTIVITY_ZUK') {
    return (
      <Info
        name={`${translate('Productivity')}, ${translate('cwt/ha')}`}
        color={productivityValues['ZUK']}
        style={style}
      />
    )
  }

  return null
}

type TrackProps = {
  deleteTrack: (value: {imei: number}) => void
  map: Map
  arrow: boolean
  noZoom: boolean
}

const Track: FC<TrackProps> = ({ deleteTrack, map, arrow, noZoom }) => {
  const t = useTranslate()
  const [legendValues, setLegendValues] = useState<Record<string, number[]>>({})
  const householdUnits = getStore('householdUnits')

  const handleSetLegendValues = useCallback((name, value) => {
    setLegendValues({ ...legendValues, [name]: value })
  }, [legendValues])

  const getTrackColor = (name: string, speed: number, type: string) => {
    const values = legendValues[name] || (type=== 'TRACK_SPEED' ? mapTypes['TRACK_SPEED'].values : [])
    const index = values.findIndex(element => speed < element)
    return mapTypes.TRACK_SPEED.colors[index > -1 ? index : mapTypes.TRACK_SPEED.colors.length - 1]
  }

  // Переопределение цветов для редактируемой легенды трека скорости
  const getColoredTrack = (unit: HouseholdUnitStore) => {
    if(unit.track.type === 'TRACK_SPEED') {
      //получаем из готового трека дынные по точкам
      const rawTrack = unit.track.activeTrack[unit.track.type].reduce(
        (acc: ActiveTrackPathType[], item) => acc.concat(item.path), [],
      )

      //преобразуем данные по точкам с учетом значений из легенды
      return rawTrack.reduce((result: TrackType[], item) => {
        const lastLineSpeed = result.length ? result[result.length - 1] : undefined
        const color = getTrackColor(unit.unit.name, item.SPEED, unit.track.type)

        if(lastLineSpeed) {
          lastLineSpeed.path.push(item)

          if(color !== lastLineSpeed.color) {
            result.push({ color, path: [item] })
          }
        } else {
          result.push({ color, path: [item] })
        }

        return result
      }, [])
    }

    return unit.track.activeTrack[unit.track.type === `TRACK_PRODUCTIVITY_${unit.unit.model}`
      ? `TRACK_PRODUCTIVITY_${unit.unit.model}`
      : unit.track.type
    ]
  }

  return (
    <div>
      { !isEmpty(householdUnits.unitsWithTrack) && householdUnits.unitsWithTrack.map(unit =>
        <div key={unit.imei}>
          <EventMarkers
            imei={unit.imei}
            translate={t}
          />

          <TrackItem
            animateTrack={unit.track.animateTrack}
            arrow={arrow}
            corridor={unit.unit.data.ADAPTER?.value || 9}
            deleteTrack={deleteTrack}
            different={unit.track.different}
            endDateIsToday={unit.track.endDateIsToday}
            imei={unit.imei}
            isWatched={unit.isWatched}
            manipulation={true}
            map={map}
            model={unit.unit.model}
            name={unit.unit.name}
            noZoom={noZoom}
            polylineKey={unit.imei}
            productivity={unit.track.type === `TRACK_PRODUCTIVITY_${unit.unit.model}`}
            static={unit.track.static}
            track={getColoredTrack(unit)}
            trackUpdated={unit.track.trackUpdated}
            type={unit.track.type}
          />

          <Panel
            type={unit.track.type}
            translate={t}
            unitName={unit.unit.name}
            legendValues={legendValues}
            setLegendValues={handleSetLegendValues}
          />
        </div>)
      }
    </div>
  )
}

export default observer(Track)
export { TrackItem } from './TrackItem'
