import React from 'react'
import styled from 'styled-components'
import Hint from '../Hint'

type TStyled = {
  max: number
  value?: string | number
}

type TProps = {
  caption: string
  children?: React.ReactNode
  color: string
  hint?: string

} & TStyled

const RowGraphBar = ({ color, value, children, max, caption, hint }: TProps) =>
  <Hint {...{ hint }} title={caption}>
    <GraphBarDiv {...{ color, value, max }}>
      {children}
    </GraphBarDiv>
  </Hint>


const GraphBarDiv = styled.div<TStyled>`
  background: ${({ color }) => color};
  width: ${({ value, max }) => Number(value) * 100 / max || 2}%;
  height: 100%;
`

export default RowGraphBar
