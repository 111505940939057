import { Spinner } from '@agdt/agrotronic-react-components'
import { List, Map } from 'immutable'
import { pathOr } from 'ramda'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushIndicators,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import styled from 'styled-components'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import { ReportType } from '../../../reducers/reports/state'
import { AppStateType } from '../../../reducers/store'
import { TTranslate } from '../../../types'
import { ResultFilter } from './result'

type TProps = FilterControlConfig & {
  translate: TTranslate
}

type TPerformanceIndicatorsReport = Map<'detailLoad', List<unknown>>
  & Map<'numberLoad', number>
  & Map<'onStopReport', boolean>
  & Map<'performanceIndicators', List<unknown>>
  & Map<'request', object>
  & Map<'resultLoad', List<unknown>>

export const PerformanceIndicators: FC<TProps> = props => {
  const report = useSelector<AppStateType, TPerformanceIndicatorsReport>(state =>
    state.reports.get('performanceIndicators') as TPerformanceIndicatorsReport)

  const { filter } = props
  const detailLoadSize = report.get('detailLoad').size
  const resultLoadSize = report.get('resultLoad').size
  const performanceIndicatorsReport = report.get('performanceIndicators')
  const dispatch = useDispatch()

  const finishLoadReportAction = (type: ReportType) => dispatch(finishLoadReport(type))

  useEffect(() => {
    if(resultLoadSize >= detailLoadSize) {
      finishLoadReportAction(ReportType.PerformanceIndicators)
    }
  }, [detailLoadSize, finishLoadReport, resultLoadSize])

  const jsPerformanceIndicatorsReport = useMemo(() => {
    return performanceIndicatorsReport.toJS()
  }, [performanceIndicatorsReport])


  // todo https://agdt.evateam.ru/project/Task/AT-2479
  // белый экран при раскрытии хозяйства в фильте машины
  // на dev всё ОК, значит сломалось во время переезда на TS
  return <div className="watch__wrap">
    <FilterControl
      button={true}
      disableButton={detailLoadSize !== resultLoadSize}
      {...props}
    />

    {!report.get('onStopReport') && detailLoadSize !== resultLoadSize && <SpinWrapper><Spinner/></SpinWrapper>}

    {!report.get('onStopReport') && performanceIndicatorsReport.size > 0 && <ResultFilter
      machineName={pathOr('', ['0', 'result', '0', 'name'], filter)}
      report={jsPerformanceIndicatorsReport}
      reportFrom={pathOr('', ['1', 'result', '0', 'from'], filter)}
      reportTo={pathOr('', ['1', 'result', '0', 'to'], filter)}
    />}
  </div>
}

export default InitFilter({
  callback: data => dispatch => {
    dispatch(clearReport(ReportType.PerformanceIndicators))

    pushIndicators(dispatch, data, ReportType.PerformanceIndicators)
      .then(json => {
        dispatch(pushReport({ data: json, resultLoad: [], type: ReportType.PerformanceIndicators }))
      })
      .catch(() => {
        dispatch(stopLoadReport(ReportType.PerformanceIndicators))
        dispatch(errorReport(ReportType.PerformanceIndicators))
      })
  },

  clear: () => dispatch => {
    dispatch(clearReport(ReportType.PerformanceIndicators))
  },

  default: [
    {
      id      : 0,
      items   : [],
      name    : 'machine',
      result  : [],
      section : 'machine',
      type    : 'radio-machine',
      validate: true,
    },
    {
      id      : 1,
      name    : 'select_a_date',
      result  : [],
      section : 'time',
      type    : 'date',
      validate: true,
    },
  ],

// @ts-expect-error
})(PerformanceIndicators)


const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
`
