
import { isEmpty } from 'ramda'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushFueling,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import { prepareDuration } from '../../../ui/Map/duration'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { FuelingResult as Result } from './Result'

export const Fueling: FC<FilterControlConfig> = props => {
  const t = useTranslate()
  const stateDispatch = useDispatch()
  const users = useSelector((state: AppStateType) => state.users.get('users'))

  //@ts-expect-error
  const detailLoad = useSelector((state: AppStateType) => state.reports.get('fueling').get('detailLoad'))

  //@ts-expect-error
  const resultLoad = useSelector((state: AppStateType) => state.reports.get('fueling').get('resultLoad'))

  //@ts-expect-error
  const report = useSelector((state: AppStateType) => state.reports.get('fueling').get('fueling').toJS())

  //@ts-expect-error
  const indicators = useSelector((state: AppStateType) => state.reports.get('fueling').get('indicators'))

  //@ts-expect-error
  const request = useSelector((state: AppStateType) => state.reports.get('fueling').get('request'))

  //@ts-expect-error
  const track = useSelector((state: AppStateType) => state.reports.get('fueling').get('track'))

  const onLoadEnd = detailLoad.size !== resultLoad.size

  const finishLoadReportAction = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  useEffect(() => {
    if(resultLoad.size >= detailLoad.size) {
      finishLoadReportAction(ReportType.Fueling)
    }
  }, [resultLoad.size, detailLoad.size, finishLoadReportAction])

  const reportWithFuelingAndDrain = useMemo(() => {
    if(!report.length) {
      return
    }

    //@ts-expect-error
    return report[0].reduce((acc, machine) => {
      if(machine.fillings.length) {
        let foundUnit: object

        //@ts-expect-error
        users?.forEach(user => {
          const unit = user.units.units.find((U: {imei: string | number}) => +U.imei === machine.imei)

          if(unit) {
            foundUnit = unit
          }
        })

        //@ts-expect-error
        const fillingAndDrain = machine.fillings.map(item => {
          const from = Date.parseDateTimeFromApi(item.from).user
          const to = Date.parseDateTimeFromApi(item.to).user

          return {
            ...item,
            dateStart      : from.format('DD.MM.YY'),
            duration       : prepareDuration(to.getTime() - from.getTime()),
            eventName      : t(item.sign),
            from,
            fuel           : item.volume.toFixed(1),
            fuelVolumeEnd  : item.fuelVolumeEnd.toFixed(1),
            fuelVolumeStart: item.fuelVolumeStart.toFixed(1),
            imei           : machine.imei,

            //@ts-expect-error
            model: foundUnit.typeName,

            //@ts-expect-error
            name     : foundUnit.name,
            timeEnd  : to.format('HH:mm:ss'),
            timeStart: from.format('HH:mm:ss'),
            to,
          }})

        return acc.concat(fillingAndDrain)
      }

      return acc
    }, [])
  }, [report, users, t])

  const filterIsEmpty = useMemo(() =>
    isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items), [props.filter])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={t('Select a different report or change the filtering conditions')}
        title={t('There is no data on which to build this report')}
      >
        <FilterControl
          disableButton={onLoadEnd}
          button={true}
          translate={t}
          {...props}
        />

        <Result
          {...props}
          reportWithFuelingAndDrain={reportWithFuelingAndDrain}
          showSpin={onLoadEnd}
          report={report}
          request={request}
          indicators={indicators}
          track={track}
        />
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
      filter  : 'FUEL.l',
    },
    {
      id      : 1,
      type    : 'date',
      name    : 'select_a_date',
      section : 'time',
      result  : [],
      validate: true,
    },
  ],

  callback: data => dispatch => {
    data.callbackOk = (json: unknown[]) => {
      dispatch(pushReport({ data: json, type: ReportType.Fueling, resultLoad: [] }))
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.Fueling))
      dispatch(errorReport(ReportType.Fueling))
    }

    dispatch(clearReport(ReportType.Fueling))
    pushFueling(dispatch, data, ReportType.Fueling)
  },

  clear: () => dispatch => dispatch(clearReport(ReportType.Fueling)),

  //@ts-expect-error
})(Fueling)
