import React from 'react'
import { Map, MapProps } from 'react-leaflet'
import { TTranslate } from 'types'
import TooltipD from 'ui/Tooltip'
import { Control } from 'utils/LeafletCustomControl'

export type TProps = {
  initMapChangeBounds: () => void
  mapRef: React.RefObject<Map<MapProps, L.Map>>
  t: TTranslate
}

export const MapControls = ({ initMapChangeBounds, mapRef, t }: TProps) => {
  const appendTo = mapRef.current?.container

  return <div>
    <TooltipD
      appendTo={appendTo}
      text={t('The selection type a map')}
      style={{ top: '90px', right: '8px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
    />

    <TooltipD
      appendTo={appendTo}
      text={t('Change zoom of map Can use scroll of mouse or multitouch')}
      style={{ top: '56px', left: '75px' }}
    />

    <TooltipD
      appendTo={appendTo}
      text={t('Switch on/ switch off fullscreen mode')}
      style={{ top: '155px', left: '75px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
    />

    <Control position="topleft">
      <div className="map__center">
        <button
          onClick={initMapChangeBounds}
        />
      </div>
    </Control>

    <TooltipD
      appendTo={appendTo}
      text={t('Show center of a map')}
      style={{ top: '220px', left: '75px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
    />
  </div>
}
