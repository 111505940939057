import { TLang } from '../../types'

export type IteratorsType = {
  close: () => void
}

export type UserInfoType = Readonly<{
  accountType?: string
  email?: string
  fullName?: string
  middleName?: string
  lastName?: string
  photo?: string
  operatorTelNumber?: string
  operatorEmail?: string
  useMfa?: boolean
  gmt?: number
  operatorLogin?: string
  telNumber?: string
  lang?: TLang
  id?: number
  accountId?: number
  name?: string
  login?: string
}>

export type ConfigType = Readonly<{
  VEHICLE_COUNT?: number
  NOTICES?: string
  NOTICES_PERIOD?: number
  HISTORY?: number
  REGISTERS?: string
  REGISTERS_PERIOD?: number
  SERVER_GENERATED_REPORTS_PERIOD?: number
  PARAMETERS?: string
  CLIENT_GENERATED_REPORTS_PERIOD?: number
}>

export type User = Readonly<{
  preLoaded: boolean
  info: UserInfoType
  config: ConfigType
  demoMode: boolean
  accessToken: string
  connect: boolean
  iterators: IteratorsType | null
}>

export const init = (): User => ({
  accessToken: '',
  config     : {},
  connect    : false,
  demoMode   : false,
  info       : {},
  iterators  : null,
  preLoaded  : true,
})

export const stopPreload = (state: User): User => ({
  ...state,
  preLoaded: false,
})

export const setInfo = (state: User, info: UserInfoType ): User => ({
  ...state,
  connect: true,
  info,
})

export const setConfig = (state: User, config: ConfigType ): User => ({
  ...state,
  config,
})

export const setDemoMode = (state: User): User => ({
  ...state,
  demoMode: true,
})

export const setIterators = ( state: User, iterators: IteratorsType | null): User => {
  if(state.iterators) {
    // @todo разобраться
    state.iterators.close()
  }

  return {
    ...state,
    iterators,
  }
}
