import { icons } from '@agdt/agrotronic-react-components'
import { pathOr } from 'ramda'
import React, { FC, useMemo, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { ColumnSettings, ReportTable, Unit } from '../../common/Table'

type TProps = {
  period: {
    from: string
    to: string
  }
  reportName?: string
  units: object
}

const { CloseIcon } = icons
const toFloorFixed = (value: number): string => String(Math.floor(value)).padStart(2, '0')
export const timeFormatter = (seconds: number): string =>
  `${toFloorFixed(seconds / 3600)}:${toFloorFixed(seconds % 3600 / 60)}`
export const areaFormatter = (value: number): string => value.toFixed(2)

const EquipmentResultTable: FC<TProps>= ({ period, reportName, units }) => {
  const t = useTranslate()

  const columnSettings: ColumnSettings[] = useMemo(() => [
    { additionalFields: ['number', 'modelName'], id: 'model', sort: 'asc', title: t('osht') },
    { formatter: timeFormatter, id: 'work', sort: undefined, title: `${t('operating time')}, ${t('eh')}` },
    { formatter: areaFormatter, id: 'area', sort: undefined, title: `${t('operating time')}, ${t('ha')}` },
  ], [t])

  const columnDetailsSettings: ColumnSettings[] = useMemo(() => [
    { additionalFields: ['number', 'modelName'], id: 'model', sort: 'asc', title: t('aggregate') },
    { formatter: timeFormatter, id: 'work', sort: undefined, title: `${t('operating time')}, ${t('eh')}` },
    { formatter: areaFormatter, id: 'area', sort: undefined, title: `${t('operating time')}, ${t('ha')}` },
  ], [t])

  const [shownUnitDetails, setShownUnitDetails] = useState<Unit | null>(null)

  const preparedDetailsReport = useMemo(() => {
    if(shownUnitDetails) {
      return pathOr([], [shownUnitDetails.id, 'details'], units)
    }

    return {}
  }, [shownUnitDetails, units])

  return (
    <TableWrapper>
      <ReportTable
        columnSettings={reportName === 'vehicleReport' ? columnDetailsSettings : columnSettings}
        period={period}
        reportName={reportName}
        setShownUnitDetails={setShownUnitDetails}
        units={units}
      />

      {shownUnitDetails && <DetailsTableWrapper>
        <TableTitle>
          <TitleUnit>
            <TitleUnitModel>
              {shownUnitDetails.model}
            </TitleUnitModel>

            <AdditionalTitle>
              <AdditionalTitleNumber>{shownUnitDetails.number}</AdditionalTitleNumber>
              <AdditionalTitleName>{shownUnitDetails.modelName}</AdditionalTitleName>
            </AdditionalTitle>

          </TitleUnit>

          <StyledCloseIcon onClick={() => setShownUnitDetails(null)} >
            <CloseIcon color='#000000' size={24}/>
          </StyledCloseIcon>
        </TableTitle>

        <DetailsTable>
          <ReportTable
            columnSettings={reportName === 'vehicleReport' ? columnSettings : columnDetailsSettings}
            isDetailsTable
            units={preparedDetailsReport}
          />
        </DetailsTable>
      </DetailsTableWrapper>}
    </TableWrapper>
  )
}

const TableTitle = styled.div`
  height: 80px;
  position: relative;
  border-left: 1px solid #e6e6e6;
`

const TitleUnit = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 15px;
`

const TitleUnitModel = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 20px;
`

const AdditionalTitle = styled.div`
`

const AdditionalTitleNumber = styled.span`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
`

const AdditionalTitleName = styled.span`
  color: #666666;
  font-size: 16px;
  font-weight: 400;
`

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
`

const DetailsTableWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  width: 625px;
  background: #ffffff;
`

const DetailsTable = styled.div`
  overflow-y: auto;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
    width: 5px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #D10B41;
  }
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
`

const TableWrapper = styled.div`
  position: relative;
`

export default EquipmentResultTable
