import { GeoJsonObject } from 'geojson'
import React, { FC, RefObject,useCallback, useEffect, useState } from 'react'
import { Map } from 'react-leaflet'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { addGeoZone, updateGeoZoneStore } from 'reducers/geoZone/actions'
import { StaticType } from 'reducers/geoZone/state'
import { createObject, updateObjectById } from 'services/apiService/modules'
import useDebounce from '../../../../utils/useDebounce'
import { DataPolygon } from './DataPolygon'

type CreateGeoZoneProps = {
  typeSave: boolean
  newGeoZone: {
    points: [number, number][]
    id: number
  }[]
  areaPolygon: number | null
  closeGeoZoneConstructor: () => void
  currentMapRef: RefObject<Map>
  isDeleteModeActive: boolean
  idZone: unknown
  lastPoint: [number, number] | null
  nameNewGeoZone: string | number
  setNameNewGeoZone: (value: string) => void
  toggleDeleteMode: () => void
}

export const CreateGeoZone: FC<CreateGeoZoneProps> = ({
  areaPolygon,
  closeGeoZoneConstructor,
  currentMapRef,
  isDeleteModeActive,
  idZone,
  lastPoint,
  nameNewGeoZone,
  newGeoZone,
  setNameNewGeoZone,
  toggleDeleteMode,
  typeSave,
}) => {
  const stateDispatch = useDispatch()
  const t = useTranslate()
  const [inputValue, setInputValue] = useState(nameNewGeoZone)
  const onValueChanged = useCallback(e => setInputValue(e.target.value), [])
  const debouncedInputValue = useDebounce(inputValue, 500)

  const addGeoZoneHandler = (data: StaticType) => {
    stateDispatch(addGeoZone(data))
  }

  const updateGeoZoneStoreHandler = (data?: StaticType) => {
    stateDispatch(updateGeoZoneStore(data))
  }

  const onSaveGeoZone = useCallback(() => {
    const points: [number, number][] = newGeoZone[0].points.map(item => [
      item[1],
      item[0],
    ])

    points.push(points[0])

    if(typeSave) {
      updateObjectById({
        id      : idZone,
        location: {
          coordinates: [points],
          type       : 'Polygon',
        },

        name: inputValue,
      })
        .then(() => {
          updateGeoZoneStoreHandler({
            id      : newGeoZone[0].id,
            location: {
              coordinates: [points],
              type       : 'Polygon',
            } as unknown as GeoJsonObject,
          })

          setTimeout(() => currentMapRef.current?.leafletElement.fitBounds(points.reverse()))
        })
    } else {
      createObject({
        location: {
          coordinates: [points],
          type       : 'Polygon',
        },
        name: inputValue,
      })
        .then(result => {
          addGeoZoneHandler(result as StaticType)

          // Fix bug
          setTimeout(() => currentMapRef.current?.leafletElement.fitBounds(points.reverse()))
        })
    }
  }, [addGeoZoneHandler, idZone, inputValue, newGeoZone, typeSave, updateGeoZoneStoreHandler])

  useEffect(() => {
    setNameNewGeoZone(String(debouncedInputValue))
  }, [debouncedInputValue, setNameNewGeoZone])

  return <div className="geoZone-controll__controll">
    <div className="geoZone-controll__title">
      <p>
        {typeSave
          ? t('Geozone edit')
          : t('New geozone')}
      </p>
      <div
        className="geoZone-controll__close"
        onClick={closeGeoZoneConstructor}
        role="button"
        tabIndex={0}
      />
    </div>
    <input
      className="geoZone-controll__input"
      onChange={onValueChanged}
      placeholder={t('Name geozone')}
      type="text"
      value={inputValue}
    />

    <DataPolygon
      areaPolygon={areaPolygon}
      isDeleteModeActive={isDeleteModeActive}
      lastPoint={lastPoint}
      nameNewGeoZone={nameNewGeoZone}
      newGeoZone={newGeoZone}
      saveElement={onSaveGeoZone}
      toggleDeleteMode={toggleDeleteMode}
    />
  </div>
}
