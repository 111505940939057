export const tooltips = [
  {
    style: { left: '75px', top: '56px' },
    text : 'Change zoom of map Can use scroll of mouse or multitouch',
  },
  {
    style: { left: '75px', top: '160px' }, // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
    text : 'Switch on/ switch off fullscreen mode',
  },
  {
    style: { right: '8px', top: '115px', zIndex: '1000' },
    text : 'shape',
  },
  {
    style: { right: '8px', top: '165px' },
    text : 'Turning on the display of directions on the map',
  },
  {
    style: { right: '8px', top: '215px' },
    text : 'The selection type a map',
  },
  {
    style: { right: '8px', top: '60px', zIndex: '1000' },
    text : 'creating geozones',
  },
  {
    style: { right: '8px', top: '265px' },
    text : 'Select map tile',
  },
]
