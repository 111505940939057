import { Spin } from 'antd'
import { isEmpty, length, prop, reverse, sortBy } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { getOperators } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import Filters from './Filters'
import OperatorsResultTable from './ResultTable'
import { TOperator } from './ResultTable/OperatorContent'
import { ToExcelComponent } from './ToExcell'

type TReportOperator = Omit<TOperator, 'name'> & {
  firstName: string
  secondName: string
  surname: string
}

type TReportRecord = {
  id: number
  operator: TReportOperator
  ownerName: string
  timestampEdit: string
}

const getPrepareReport = (report: TReportRecord[]): Record<number, TOperator> =>
  report.reduce<Record<number, TOperator>>((acc, operator) => {
    if(acc[operator.operator.id]) {
      if(!acc[operator.operator.id].farms.filter(({ ownerName }) => ownerName === operator.ownerName )) {
        acc[operator.operator.id].farms.push({ name: operator.ownerName, timestamp: operator.timestampEdit })
      }
    } else {
      acc[operator.operator.id] = {
        ...operator.operator,
        auditLog: length(operator.operator.auditLog)
          ? reverse(sortBy(prop('timestampCreate'), operator.operator.auditLog))
          : operator.operator.auditLog,

        farms: [{ name: operator.ownerName, timestamp: operator.timestampEdit }],

        // eslint-disable-next-line max-len
        name: `${operator.operator.surname || ''} ${operator.operator.firstName || ''} ${operator.operator.secondName || ''}`,
      }
    }

    return acc
  }, {})

const ReportOperators = () => {
  const t = useTranslate()
  const [reportIsLoading, setReportIsLoading] = useState(false)
  const [report, setReport ] = useState<Record<number, TOperator>>({})
  const [filteredOperators, setFilteredOperators] = useState<Record<number, TOperator>>({})
  const [checkedOperatorsIds, setCheckedOperatorsIds] = useState<Record<number, number[] | null>>({})

  useEffect(() => {
    setReportIsLoading(true)

    getOperators<void, TReportRecord[]>().then(result => {
      const loadedReport = getPrepareReport(result || [])

      setReport(loadedReport)
      setFilteredOperators(loadedReport)
      setReportIsLoading(false)
    }).catch(() => {
      errorMessage(t('operators service is not available'))
      setReportIsLoading(false)
    })
  }, [t])

  const operators = useMemo(() => Object.values(report), [report])

  const setCheckedIds = ids => {
    const filtered = ids[1]
      ? Object.fromEntries(Object.entries(report).filter(([_, { id }]) => ids[1]?.includes(id)))
      : report

    setCheckedOperatorsIds(ids)
    setFilteredOperators(filtered)
  }

  return (
    <div className="watch__wrap">
      <ReportHeader>
        {!isEmpty(report) && <Filters
          checkedOperatorsIds={checkedOperatorsIds}
          onHideList={() => {}}
          operators={operators}
          setCheckedOperatorsIds={setCheckedIds}
        />}

        {!isEmpty(report) && <ToExcelComponent
          report={filteredOperators}
          withoutFilters={isEmpty(checkedOperatorsIds)}
        />}
      </ReportHeader>

      {reportIsLoading &&
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>}

      {!isEmpty(filteredOperators) &&
          <OperatorsResultTable
            report={filteredOperators}
          />}

      {isEmpty(report) && !reportIsLoading && <NoDataWrapper>{t('no data')}</NoDataWrapper>}
    </div>
  )
}

export default ReportOperators

const ReportHeader = styled.div`
  display: flex;
  background-color: #ffffff;
  position: relative;
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const NoDataWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888886;
`
