import React, { FC } from 'react'
import { TTranslate } from 'types'

const options = [
  {
    id   : 'z1',
    name : 'M3<1',
    title: 'idle_time_<_1',
  },
  {
    id   : 'z2',
    name : 'M3>1',
    title: 'idle_time_>_1',
  },
  {
    id   : 'z3',
    name : 'M2<1',
    title: 'stop_<_1',
  },
  {
    id   : 'z4',
    name : 'M2>1<10',
    title: 'stop_1_10',
  },
  {
    id   : 'z5',
    name : 'M2>10',
    title: 'stop_>_10',
  },
]

type StopOptionsProps = {
  translate: TTranslate
  shownMarkers: Set<string>
  toggleMarkers: (type: 'stopIntervals', name: string) => void
}

export const StopOptions: FC<StopOptionsProps> = ({ toggleMarkers, shownMarkers, translate }) => {
  const onChange = (name: string) => () => {
    toggleMarkers('stopIntervals', name)
  }

  return (
    <div className='tracking-machine__settings'>
      <p>{translate('downtime')}</p>

      {options.map(option =>
        <div className='tracking-machine__item' key={option.id}>
          <input
            checked={shownMarkers.has(option.name)}
            id={option.id}
            onChange={onChange(option.name)}
            type='checkbox'
          />
          <label htmlFor={option.id}>
            {translate(option.title)}
          </label>
        </div>,
      )}
    </div>
  )
}

