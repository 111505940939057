import { FC } from 'react'
import React from 'react'

type TProps = {
  className?: string
  size?: number
  selected?: boolean
}

export const NormalGraph: FC<TProps> = ({ className, size = 20 }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 0.999973C16.8977 0.997981 16.0024 1.89001 16.0004 2.99239C15.9995 3.51196 16.2012 4.01146 16.5627 4.38466L12.9087 11.6913C12.8286 11.6781 12.7478 11.6698 12.6667 11.6667C12.4085 11.6672 12.1529 11.7179 11.914 11.816L9.07734 8.62603C9.24293 8.33325 9.33105 8.00306 9.33336 7.66669C9.33336 6.56212 8.43793 5.66669 7.33336 5.66669C6.22879 5.66669 5.33336 6.56212 5.33336 7.66669C5.33531 8.14411 5.50957 8.60478 5.82402 8.96403L2.35734 15.036C2.23953 15.0133 2.11996 15.0013 2 15C0.89543 15 0 15.8954 0 17C0 18.1045 0.89543 19 2 19C3.10457 19 4 18.1045 4 17C3.99805 16.5225 3.82379 16.0619 3.50934 15.7026L6.97602 9.63064C7.34816 9.70415 7.73379 9.6647 8.08336 9.51731L10.92 12.7073C10.7554 13.0003 10.6682 13.3305 10.6667 13.6666C10.6634 14.7712 11.5561 15.6693 12.6607 15.6727C13.7652 15.676 14.6634 14.7833 14.6667 13.6787C14.6682 13.1576 14.4664 12.6565 14.104 12.282L17.758 4.97532C17.8381 4.98861 17.9189 4.99685 18 4.99997C19.1046 4.99997 20 4.10454 20 2.99997C20 1.8954 19.1046 0.999973 18 0.999973ZM2 17.6666C1.6318 17.6666 1.33332 17.3682 1.33332 17C1.33332 16.6318 1.6318 16.3333 2 16.3333C2.3682 16.3333 2.66668 16.6318 2.66668 17C2.66668 17.3682 2.3682 17.6666 2 17.6666ZM7.33332 8.33329C6.96512 8.33329 6.66664 8.03482 6.66664 7.66661C6.66664 7.29841 6.96512 6.99993 7.33332 6.99993C7.70152 6.99993 8 7.29841 8 7.66661C8 8.03482 7.70152 8.33329 7.33332 8.33329ZM12.6667 14.3333C12.2985 14.3333 12 14.0348 12 13.6666C12 13.2984 12.2985 12.9999 12.6667 12.9999C13.0349 12.9999 13.3334 13.2984 13.3334 13.6666C13.3333 14.0348 13.0348 14.3333 12.6667 14.3333ZM18 3.66665C17.6318 3.66665 17.3333 3.36818 17.3333 2.99997C17.3333 2.63177 17.6318 2.33329 18 2.33329C18.3682 2.33329 18.6667 2.63177 18.6667 2.99997C18.6667 3.36818 18.3682 3.66665 18 3.66665Z"
        fill="#A1A1A1"
      />
    </svg>
  )
}
