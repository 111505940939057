import { Spinner } from '@agdt/agrotronic-react-components'
import React, { useCallback, useEffect, useState } from 'react'
import { Map } from 'react-leaflet'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { RouteComponentProps } from 'react-router-dom'
import { setModelsHousehold, setSearchHousehold, setStatusesHousehold } from 'reducers/users/actions'
import { getStatusesModelsHouseholds } from 'reducers/users/selectors'
import { link } from 'router'
import styled from 'styled-components'
import { Selector } from 'ui/Filter/selector'
import TooltipD from 'ui/Tooltip'
import useDebounce from '../../utils/useDebounce'
import { HouseHoldsList } from './HouseHold'

const styles = {
  tooltip: { right: '-30px', top: '-8px' },
}

type TProps = {
  handleSetModelsHousehold: (model: string | unknown) => void
  handleSetSearchHousehold: (text: string) => void
  handleSetStatusesHousehold: (status: string | unknown) => void
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
  mapRef: Map
  models: string[]
  searchValue: string
  statuses: string[]
  statusesModels: { models: string[], statuses: string[] }
}

const Households = ({
  handleSetModelsHousehold,
  handleSetSearchHousehold,
  handleSetStatusesHousehold,
  history,
  location,
  mapRef,
  models,
  searchValue,
  statuses,
  statusesModels,
}: TProps) => {
  const t = useTranslate()
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>()
  const debouncedInputValue = useDebounce(searchInputValue, 300)

  const handleSetSearchText = useCallback(e => {
    setSearchInputValue(e.target.value)
  }, [])

  useEffect(() => {
    if(debouncedInputValue !== undefined) {
      handleSetSearchHousehold(debouncedInputValue)
    }
  }, [debouncedInputValue, handleSetSearchHousehold])

  //при переходе с других страниц (при нажатии кнопки отслеживать) или по ссылке из попапа на карте "показать в списке"
  // добавлять номер машины в поле поиска для того чтобы потом можно было вернуться к списку хозяйств.
  useEffect(() => {
    setSearchInputValue(searchValue)
    handleSetSearchHousehold(searchValue)
  }, [searchValue])

  useEffect(() => {
    if(location) {
      const machine = new URLSearchParams(location.search).get('machine')

      if(machine) {
        handleSetSearchHousehold(machine)
        history.push(link.households)
      }
    }
  }, [handleSetSearchHousehold, history, location])

  return (
    <HouseholdsWrapper>
      <div className="tracking-page__search">
        <TooltipD
          text={t('List of households and units of user equipment with the search function by name')}
          style={styles.tooltip}
        />
        <input
          className="tracking-page__input"
          onChange={handleSetSearchText}
          placeholder={t('search_for')}
          type="text"
          value={searchInputValue}
        />
        {
          // eslint-disable-next-line
          debouncedInputValue == searchInputValue && <button className="tracking-page__button" />
        }

        {
          // eslint-disable-next-line
          debouncedInputValue != searchInputValue &&
          <SpinnerStyled color="#D10B41" size={31} />
        }
      </div>

      <div className="tracking-page__filter">
        <Selector
          renderList={statusesModels.models}
          result={models}
          select={handleSetModelsHousehold}
          textAfterSelect={t('by_model')}
        />
        <Selector
          renderList={statusesModels.statuses}
          result={statuses}
          select={handleSetStatusesHousehold}
          textAfterSelect={t('by_active')}
        />
      </div>

      <div className="tracking-page__items" id="wrapper-machine">
        <HouseHoldsList
          mapRef={mapRef}
        />
      </div>
    </HouseholdsWrapper>
  )
}

export default connect(state => ({

  //@ts-expect-error
  models: state.users.get('modelsHousehold'),

  //@ts-expect-error
  searchValue: state.users.get('searchHousehold'),

  //@ts-expect-error
  statuses      : state.users.get('statusesHousehold'),
  statusesModels: getStatusesModelsHouseholds(state),
}),

dispatch => ({

  //@ts-expect-error
  handleSetModelsHousehold: model => dispatch(setModelsHousehold(model)),

  //@ts-expect-error
  handleSetSearchHousehold: search => dispatch(setSearchHousehold(search)),

  //@ts-expect-error
  handleSetStatusesHousehold: status => dispatch(setStatusesHousehold(status)),
}))(Households)

const HouseholdsWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const SpinnerStyled = styled(Spinner)`
  position: absolute;
  right: 30px;
  top: 11px;
`
