import { helpers } from '@agdt/agrotronic-react-components'
import { Tooltip } from 'antd'
import moment from 'moment/moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import { ColorType } from 'ui/RangePicker/Day'
import { CALENDAR_COLORS, getCalendarPaintRegs, getCalendarPaintWithData, RegisterType } from '../../queries/calendar'
import { SoloPicker } from '../RangePicker/SoloPicker'

const { debounce } = helpers

type WrapSoloPickerProps = {
  startDay: moment.Moment
  imei: string | number
  history?: number
  applyDate: (value: moment.Moment) => void
}

export const WrapSoloPicker: FC<WrapSoloPickerProps> = ({ startDay, imei, history, applyDate }) => {
  const isMounted = useRef(true)
  const [colors, setColors] = useState<ColorType[]>([])
  const [visibleMonth, setVisibleMonth] = useState<moment.Moment>(startDay)
  const [date, setDate] = useState<moment.Moment>(startDay)
  const [activeType, setActiveType] = useState<number | string>('')
  const [regs, setRegs] = useState<RegisterType[] | null>(null)
  const gmt = useSelector((state: AppStateType) => state.user.info.gmt) || 0
  const translate = useTranslate()

  const activeReg = regs?.find(item => item.type === activeType)

  const switchMonth = debounce((newValue: moment.Moment) => {
    setVisibleMonth(newValue)

    if(moment(newValue, 'DD.MM.YY') <= moment() && regs && activeType) {
      getCalendarPaintWithData(
        imei,
        {
          GMT     : gmt,
          levels  : regs.find(item => item.type === activeType)?.levels || [],
          month   : moment(newValue, 'DD.MM.YY').format('M'),
          register: activeType,
          year    : moment(newValue, 'DD.MM.YY').format('YYYY'),
        },
        (_: unknown, value: ColorType[]) => {
          if(isMounted.current) {
            setColors(value)
          }
        },
      )
    }
  }, 1000)

  const switchDays = (newDate: moment.Moment) => {
    setDate(newDate)
  }

  const onApplyDate = () => {
    applyDate(date)
  }

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    if(value !== activeType) {
      setActiveType(value)

      if(visibleMonth && moment(visibleMonth, 'DD.MM.YY') <= moment() && regs && value) {
        getCalendarPaintWithData(
          imei,
          {
            GMT     : gmt,
            levels  : regs.find(item => item.type === value)?.levels || [],
            month   : moment(visibleMonth, 'DD.MM.YY').format('M'),
            register: value,
            year    : moment(visibleMonth, 'DD.MM.YY').format('YYYY'),
          },
          (_: unknown, info: ColorType[]) => {
            if(isMounted.current) {
              setColors(info)
            }
          },
        )
      }
    }
  }

  useEffect(()=>{
    getCalendarPaintRegs(imei, (info: RegisterType[]) => {
      if(info.length && isMounted.current) {
        const newRegs = info
        const newActiveType = info[0].type
        setRegs(newRegs)
        setActiveType(newActiveType)

        getCalendarPaintWithData(
          imei,
          {
            GMT     : gmt,
            levels  : newRegs.find(item => item.type === newActiveType)?.levels || [],
            month   : date.format('M'),
            register: newActiveType,
            year    : date.format('YYYY'),
          },
          (_:unknown, value: ColorType[]) => {
            if(isMounted.current) {
              setColors(value)
            }
          },
        )
      }
    })

    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <div>
      <SoloPicker
        colors={colors}
        switchMonth={switchMonth}
        switchDays={switchDays}
        history={history}
        initDate={startDay}
      />
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={100}
      >
        {activeReg &&
          <div className="machine-detail__regs">
            {activeReg.levels.map((item, index) =>
              <Tooltip key={item} placement="top" title={item}>
                <div className="machine-detail__regs-item">
                  <div
                    className="machine-detail__regs-color"
                    style={{ background: CALENDAR_COLORS[index] }}
                  />
                </div>
              </Tooltip>,
            )}
            <Tooltip key="&infin;" placement="top" title="&infin;">
              <div className="machine-detail__regs-item">
                <div
                  className="machine-detail__regs-color"
                  style={{ background: CALENDAR_COLORS[4] }}
                />
              </div>
            </Tooltip>
          </div>
        }
      </ReactCSSTransitionGroup>
      <div className="machine-detail__top-wrap-picker">
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={100}
        >
          {regs &&
            <select
              name="regs"
              className="Date-Month__select"
              value={activeType}
              onChange={handleChange}
            >
              {regs.map(item =>
                <option key={item.type} value={item.type}>
                  {item.name}
                </option>,
              )}
            </select>
          }
        </ReactCSSTransitionGroup>
        <button
          className="machine-detail__top-button"
          onClick={onApplyDate}
        >
          {translate('accept')}
        </button>
      </div>
    </div>
  )
}
