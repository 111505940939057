import { hooks } from '@agdt/agrotronic-react-components'
import { makeObservable, override } from 'mobx'
import { demoRequest } from 'services/apiService/demoQueries'
import { getCurrentUser, getInfo } from 'services/apiService/modules'
import DictionaryStore from 'stores/dictionaries/DictionaryStore'
import { TUser } from 'types'
import { parseAccountFromApi } from '../../services/models/account'
import { parseFromApi } from '../../services/models/user'
const { useDemoMode } = hooks

export class UserStore extends DictionaryStore<TUser, TUser> {
  accountType: 'user' | 'account' | undefined

  constructor() {
    super(useDemoMode() ? demoRequest.bind(undefined, 'user') : async () => {
      //на время переходного процесса, пока существуют два типа учётных записей (account и user)
      //нужно пытаться запрашивать данные из двух источников
      try {
        this.accountType = 'user'
        return await getCurrentUser()
      } catch{
        this.accountType = 'account'
        return await getInfo()
      }
    })

    makeObservable(this)

    this.getValue()
  }

  setCurValue(value: TUser): void {
    if(this.accountType === 'user') {
      this.setValue(parseFromApi(value))
    } else {
      this.setValue(parseAccountFromApi(value))
    }    
  }

  @override
  async loadData() {
    const data: any = await this.fetchApi()

    this.setCurValue(data)
  }
}
