import { RefObject,useEffect } from 'react'

export default function useClickOutside(ref: RefObject<HTMLElement>, handler: (event: MouseEvent) => void) {
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if(!ref.current || ref.current.contains(event.target as HTMLElement)) {
          return
        }

        handler(event)
      }

      document.addEventListener('click', listener)

      return () => {
        document.removeEventListener('click', listener)
      }
    },

    [ref, handler],
  )
}
