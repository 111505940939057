import Donut from 'components/Donut'
import { WatchButton } from 'components/WatchButton'
import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { useHistory } from 'react-router-dom'
import { getStore } from 'stores/storesRegistry'
import { modesSchema } from 'utils'
import { link } from '../../../router'
import { prepareDuration } from '../../Map/duration'
import { MachineCard } from '../card'

type Measure = {
  value?: number
  measure?: string
  max?: number
  valueF?: '-' | number
}

export type ResultItemType = {
  id: number
  imei: number
  name: string
  typeName: string
  model: string
  DBC: boolean
  currentSpeed: boolean
  fuelConsumption: boolean
  speedWork: boolean
  performance: boolean
  data: {
    AGRICULTURE?: Measure
    AREA_PER_TIME?: Measure
    AVER_SPEED_COMBINE?: Measure
    FIRST_MOTOR?: Measure
    FUEL?: Measure
    INC_SUMM_AREA?: Measure
    INC_SUMM_FUEL?: Measure
    SPEED?: Measure
    TIME_HARVEST?: Measure
    TIME_MOVE_NO_HARVEST?: Measure
    TIME_NO_LINK?: Measure
    TIME_OFF?: Measure
    TIME_STOP_DON?: Measure
    TIME_STOP_HARVEST?: Measure
    TIME_STOP_HARVEST_FULL?: Measure
    STATUS?: Measure & {
      color?: string
    }
    NUM_MESSAGES_CRIT?: Measure
    NUM_MESSAGES_INFO?: Measure
    NUM_MESSAGES_NOCRIT?: Measure
    NUM_MESSAGES_SERVICE?: Measure
    ACTIVE?: Measure
    LATITUDE?: Measure
    LONGITUDE?: Measure
  }
}

type ResultItemProps = ResultItemType

export const ResultItem: FC<ResultItemProps> = ({
  id,
  imei,
  name,
  data,
  typeName,
  model,
  DBC,
  currentSpeed,
  speedWork,
  fuelConsumption,
  performance,
}) => {
  const translate = useTranslate()
  const householdUnit = getStore('householdUnits').getUnit(imei)
  const history = useHistory()

  const handleWatch = () => {
    householdUnit.setWatched()
    history.push(`${link.households}?machine=${name}`)
  }

  const parameters = {
    AGRICULTURE           : 0,
    AREA_PER_TIME         : 0,
    AVER_SPEED_COMBINE    : 0,
    FIRST_MOTOR           : 0,
    FUEL                  : 0,
    INC_SUMM_AREA         : 0,
    INC_SUMM_FUEL         : 0,
    SPEED                 : 0,
    TIME_HARVEST          : 0,
    TIME_MOVE_NO_HARVEST  : 0,
    TIME_NO_LINK          : 0,
    TIME_OFF              : 0,
    TIME_STOP_DON         : 0,
    TIME_STOP_HARVEST     : 0,
    TIME_STOP_HARVEST_FULL: 0,
  }

  Object.keys(parameters).forEach(element => {
    const key = element as keyof typeof parameters

    if(key === 'FUEL') {
      const obj = data?.[key]

      if(obj?.value) {
        const max = obj.max || 0

        if(obj?.measure !== '%') {
          if(obj?.value < max) {
            parameters[key] = obj.value / max * 100
          } else {
            parameters[key] = 100
          }
        } else {
          if(obj.value < max) {
            parameters[key] = obj.value
          } else {
            parameters[key] = max
          }
        }
      } else {
        parameters[key] = 0
      }
    } else if(key === 'FIRST_MOTOR') {
      const obj = data?.[key]

      if(obj?.value) {
        obj.valueF !== '-'
          ? parameters[key] = obj.valueF || 0
          : parameters[key] = 0
      }
    } else if(key === 'SPEED') {
      const obj = data?.[key]

      if(obj?.value && obj.value >= 1) {
        parameters[key] = obj.value
      }
    } else {
      parameters[key] = data?.[key]?.value || 0
    }
  })

  const getDataForDonut = () => {
    const params = [
      'TIME_NO_LINK',
      'TIME_MOVE_NO_HARVEST',
      'TIME_OFF',
      'TIME_STOP_DON',
      'TIME_HARVEST',
      'TIME_STOP_HARVEST',
      'TIME_STOP_HARVEST_FULL',
    ]

    const sumParameters = params.reduce((acc, entry) => acc + parameters[entry as keyof typeof parameters], 0)

    if(sumParameters !== 0) {
      return (
        params.map(entry => ({
          color  : modesSchema[entry as keyof typeof modesSchema].color,
          tooltip: `${translate(modesSchema[entry as keyof typeof modesSchema].name)}
                  ${prepareDuration(parameters[entry as keyof typeof parameters])}
                  ${(parameters[entry as keyof typeof parameters] / sumParameters * 100).toFixed(0)}%`,
          value: parameters[entry as keyof typeof parameters] || 0,
        }))
      )
    } else {
      return [{
        color  : modesSchema.TIME_NO_LINK.color,
        param  : '100%',
        tooltip: null,
        value  : 100,
      }]
    }
  }

  return (
    <tr>
      <td>
        <MachineCard
          id={id}
          imei={imei}
          type={typeName}
          name={name}
          statusF={String(data.STATUS?.valueF || '-')}
          fuel={parameters.FUEL}
          model={model}
          messages={[
            ...data?.NUM_MESSAGES_CRIT?.value ? [{
              count : data.NUM_MESSAGES_CRIT.value,
              status: 1,
            }] : [],
            ...data?.NUM_MESSAGES_INFO?.value ? [{
              count : data.NUM_MESSAGES_INFO.value,
              status: 4,
            }] : [],
            ...data?.NUM_MESSAGES_NOCRIT?.value ? [{
              count : data.NUM_MESSAGES_NOCRIT.value,
              status: 2,
            }] : [],
            ...data?.NUM_MESSAGES_SERVICE?.value ? [{
              count : data.NUM_MESSAGES_SERVICE.value,
              status: 8,
            }] : []]}
          color={data.STATUS?.color || ''}
          translate={translate}
        />
      </td>
      <td>
        <p style={{ ...!DBC && { paddingRight: '5px', textAlign: 'center' } }}>
          {parameters?.FIRST_MOTOR || '—'}
        </p>
      </td>
      <td>
        <p
          className="r-date"
          style={{ ...data.ACTIVE && !data.ACTIVE.valueF && { textAlign: 'center' } }}
        >
          {data?.ACTIVE?.valueF && data.ACTIVE.valueF !== '-'
            ? data.ACTIVE.valueF
            : '—'}
        </p>
      </td>
      <td>
        <Donut
          width={60} height={60}
          data={getDataForDonut()}
          tooltip
        />
      </td>
      <td>
        <p style={{ ...!currentSpeed && { textAlign: 'center' } }}>
          {parameters.SPEED ? `${parameters.SPEED.toFixed()} ${translate('km/h')}` : '—'}
        </p>
      </td>
      <td>
        <p style={{ ...!speedWork && { textAlign: 'center' } }}>
          {parameters.AVER_SPEED_COMBINE
            ? `${parameters.AVER_SPEED_COMBINE.toFixed(1)} ${translate('km/h')}`
            : '—'}
        </p>
      </td>
      <td>
        <p style={{ ...!fuelConsumption && { textAlign: 'center' } }}>
          {parameters.INC_SUMM_FUEL
            ? `${parameters.INC_SUMM_FUEL.toFixed(1)} ${translate('l')}`
            : '—'}
        </p>
      </td>
      <td>
        <p style={{ ...!speedWork && { textAlign: 'center' } }}>
          {parameters.INC_SUMM_AREA
            ? `${parameters.INC_SUMM_AREA.toFixed(1)} ${translate('ha')}`
            : '—'}
        </p>
      </td>
      <td>
        <p style={{ ...!performance && { textAlign: 'center' } }}>
          {parameters.AREA_PER_TIME
            ? `${parameters.AREA_PER_TIME.toFixed(1)} ${translate('ha/h')}`
            : '—'}
        </p>
      </td>
      <td>
        <p>
          {parameters?.AGRICULTURE || '—'}
        </p>
      </td>

      <td>
        <WatchButton
          disabled={!data?.LATITUDE?.value || !data?.LONGITUDE?.value}
          errorText={translate('View location is not available, the machine has not yet sent the coordinates')}
          handleWatch={handleWatch}
          isActive={householdUnit.isWatched}
        />
      </td>
    </tr>
  )
}
