import React from 'react'

const IconMap = {
  'ZUK': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 50 28">
      <defs/>
      <g id="Canvas" transform="translate(-1781 -1040)">
        <g id="Combined Shape">
          <path transform="translate(1781.4 1040)" style={{ fill: color }} id="path0_fill" fillRule="evenodd"
                d="M 26.6021 22C 26.6021 17.582 30.184 14 34.6021 14L 38.058 14L 40.6021 0L 28.6021 0L 28.238 2L 2.23804 2L 0 14.3101L 15.478 17.75L 18.6021 24L 26.886 24C 26.718 23.358 26.6021 22.696 26.6021 22ZM 14.6021 24C 14.6021 26.208 12.8101 28 10.6021 28C 8.39404 28 6.60205 26.208 6.60205 24C 6.60205 21.792 8.39404 20 10.6021 20C 12.8101 20 14.6021 21.792 14.6021 24ZM 42.6021 22.0006L 42.6021 21.5146L 49.088 28.0006L 39.844 28.0006C 41.52 26.5326 42.6021 24.4026 42.6021 22.0006ZM 34.6021 16C 31.2881 16 28.6021 18.686 28.6021 22C 28.6021 25.314 31.2881 28 34.6021 28C 37.916 28 40.6021 25.314 40.6021 22C 40.6021 18.686 37.916 16 34.6021 16Z"/>

        </g>
      </g>
    </svg>
  ,
  'KUK': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 24 14">
      <defs/>
      <g id="Шаблон" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Group-4" transform="translate(-1.000000, -3.000000)">
          <polygon id="Fill-17" points="0 20 26 20 26 0 0 0"/>
          <path style={{ fill: color }}
                d="M17,11 C18.657,11 20,12.343 20,14 C20,15.657 18.657,17 17,17 C15.343,17 14,15.657 14,14 C14,12.343 15.343,11 17,11 Z M6,11 C7.657,11 9,12.343 9,14 C9,15.657 7.657,17 6,17 C4.343,17 3,15.657 3,14 C3,12.343 4.343,11 6,11 Z M25,17 L21,17 L21,13 C23.209,13 25,14.791 25,17 Z M17,3 L23,3 L22,10 L17,10 C14.791,10 13,11.791 13,14 L13,16 L10,16 L10,14 C10,11.791 8.209,10 6,10 C3.791,10 2,11.791 2,14 L1,14 L1,10 L2,8 L10,7 L14,7 L14.429,4 L12,4 L12.143,3 L14.571,3 L16,3 L16,4 L16,7 L17,7 L17,3 Z M6,15 C6.552,15 7,14.552 7,14 C7,13.448 6.552,13 6,13 C5.448,13 5,13.448 5,14 C5,14.552 5.448,15 6,15 Z M17,15 C17.552,15 18,14.552 18,14 C18,13.448 17.552,13 17,13 C16.448,13 16,13.448 16,14 C16,14.552 16.448,15 17,15 Z"
                id="Combined-Shape" fill="#202020"/>
        </g>
      </g>
    </svg>
  ,
  'KS': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 25 14">
      <defs/>
      <g id="Шаблон" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-3" transform="translate(-1.000000, -3.000000)">
          <polygon id="Fill-13" points="0 20 26 20 26 0 0 0"/>
          <path style={{ fill: color }}
                d="M5,15.5 C5,16.329 4.329,17 3.5,17 C2.671,17 2,16.329 2,15.5 C2,14.671 2.671,14 3.5,14 C4.329,14 5,14.671 5,15.5 Z M18,14.5 C18,15.881 16.881,17 15.5,17 C14.119,17 13,15.881 13,14.5 C13,13.119 14.119,12 15.5,12 C16.881,12 18,13.119 18,14.5 Z M25,15 L18.95,15 C18.973,14.835 19,14.671 19,14.5 C19,12.567 17.433,11 15.5,11 C14.012,11 12.749,11.933 12.243,13.243 L11,12 L9.429,12 L9,15 L8,15 L8.429,12 L1,12 L1.578,7.952 L12,7 L12,3 L18,3 L17,10 L18,10 L25,15 Z M26,9.6284 L24.09,12.2574 L21,11.2534 L21,8.0034 L24.09,7.0004 L26,9.6284 Z"
                id="Combined-Shape" fill="#202020"/>
        </g>
      </g>
    </svg>
  ,
  'TRACTOR': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 25 14">
      <defs/>
      <g id="Шаблон" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-2" transform="translate(-1.000000, -3.000000)">
          <polygon id="Fill-11" points="0 20 26 20 26 0 0 0"/>
          <path style={{ fill: color }}
                d="M4.5,10 C6.433,10 8,11.567 8,13.5 C8,15.433 6.433,17 4.5,17 C2.567,17 1,15.433 1,13.5 C1,11.567 2.567,10 4.5,10 Z M17.5,10 C19.433,10 21,11.567 21,13.5 C21,15.433 19.433,17 17.5,17 C15.567,17 14,15.433 14,13.5 C14,11.567 15.567,10 17.5,10 Z M3.1084,9.2422 L3.2854,8.0002 L8.0004,8.0002 L8.0004,10.7032 C7.1754,9.6732 5.9224,9.0002 4.5004,9.0002 C4.0114,9.0002 3.5484,9.0972 3.1084,9.2422 Z M25,13 L26,13 L26,14 L24,14.286 L24,13 L21.95,13 C21.698,10.753 19.814,9 17.5,9 C15.015,9 13,11.015 13,13.5 C13,13.671 13.032,13.833 13.05,14 L11,14 L8.95,14 C8.968,13.833 9,13.671 9,13.5 C9,13.329 8.968,13.167 8.95,13 L11,13 L11,10 L9,9.714 L9,8 L10,8 L10.571,4 L9,4 L9,3 L10.714,3 L11,3 L12,3 L17,3 L17.582,7.073 L24.854,7.982 L25,9 L25,13 Z M17.5,15 C18.329,15 19,14.329 19,13.5 C19,12.671 18.329,12 17.5,12 C16.671,12 16,12.671 16,13.5 C16,14.329 16.671,15 17.5,15 Z M4.5,15 C5.329,15 6,14.329 6,13.5 C6,12.671 5.329,12 4.5,12 C3.671,12 3,12.671 3,13.5 C3,14.329 3.671,15 4.5,15 Z"
                id="Combined-Shape"
                fill="#202020"/>
        </g>
      </g>
    </svg>
  ,
  'SEEDER': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="-288.9408264 408.6289368 72.2789612 22.677166">
      <polygon fill={color} points="-247.4000854,422.8634338 -247.4000854,424.702179 -258.6394348,422.7203674
	-258.6394348,420.8816223 "/>
      <path fill={color} d="M-225.1882172,431.3060913c1.4581604,0,2.6405487-1.203186,2.6405487-2.6868286
	c0-1.4836731-1.1823883-2.6868286-2.6405487-2.6868286c-1.4581757,0-2.640564,1.2031555-2.640564,2.6868286
	C-227.8287811,430.1029053-226.6463928,431.3060913-225.1882172,431.3060913"/>
      <path fill={color} d="M-247.4000092,431.3060913c1.5620422,0,2.828476-1.266449,2.828476-2.8284912
	s-1.2664337-2.8275452-2.828476-2.8275452c-1.5620575,0-2.8284912,1.2655029-2.8284912,2.8275452
	S-248.9620667,431.3060913-247.4000092,431.3060913"/>
      <path fill={color} d="M-276.0667114,425.5863037c0-3.1590271-2.5602722-5.7192993-5.7192993-5.7192993
	s-5.7202454,2.5602722-5.7202454,5.7192993s2.5612183,5.7192993,5.7202454,5.7192993
	S-276.0667114,428.7453308-276.0667114,425.5863037"/>
      <g>
        <path fill={color} d="M-278.6241455,423.0141296h0.2664795c-0.0841064-0.1116943-0.1721802-0.2199097-0.2664795-0.3228455
		V423.0141296z"/>
        <path fill={color} d="M-263.4117432,420.2819824v-11.6530457h-25.5290833v12.0128479h2.0202942
		c1.2990723-1.3482971,3.1187134-2.1917725,5.134491-2.1917725c3.0272827,0,5.613678,1.8980103,6.6488037,4.5641174h15.5607605
		v-2.7321472H-263.4117432z"/>
      </g>
      <path fill={color} d="M-259.4094238,427.7828979c0-1.9454651-1.5771484-3.522644-3.5226135-3.522644
	c-1.9426575,0-3.5169678,1.5705566-3.5235901,3.5112915v0.0226746c0.0066223,1.9407654,1.5809326,3.5112915,3.5235901,3.5112915
	C-260.9865723,431.3055115-259.4094238,429.728363-259.4094238,427.7828979"/>
      <rect x="-224.3455353" y="424.2654724" fill={color} width="7.6836658" height="0.8622402"/>
      <path fill={color} d="M-230.9123688,424.2664185l0.062088-0.0739746l-2.6199493-2.1985779l0.844574-0.3074036
	l-0.2946625-0.8093262l-9.3112793,3.3892822h-4.0240936v0.6357117c1.0440674,0.3352051,1.8973999,1.0975037,2.3252716,2.0964355
	h0.0185089l0.5857544,1.0327148c0.1275024,0.2266541,0.2843475,0.5939941,0.3482666,0.815979l0.422287,1.4864502
	c0.0629425,0.2219238,0.2400208,0.5439758,0.3923035,0.7139587l0.12883,0.1455078
	c0.152298,0.1699829,0.2506866,0.1454163,0.2167969-0.0557556l-0.5821838-3.574585
	c-0.023056-0.1448669-0.0989227-0.3664856-0.1871643-0.56427h2.5213623l0.2417603,0.6478271
	c0.0676727,0.1843567,0.1276093,0.4815063,0.133728,0.6630554c-0.7345886,0.1014099-1.3023682,0.7259521-1.3023682,1.4883728
	c0,0.8329773,0.6752777,1.5082703,1.5082092,1.5082703c0.8329773,0,1.508255-0.675293,1.508255-1.5082703
	c0-0.6307068-0.3878174-1.1700745-0.9375-1.3955078l0.1388702-1.1383667
	c0.0088654-0.0703125,0.0018463-0.1679077-0.0091705-0.2653809h1.6035309l0.5858154,1.0327148
	c0.1274567,0.2266541,0.2843475,0.5939941,0.3482819,0.815979l0.422226,1.4864502
	c0.0630035,0.2219238,0.2400665,0.5439758,0.3923645,0.7139587l0.12883,0.1455078
	c0.1522827,0.1699829,0.2506256,0.1454163,0.2167358-0.0557556l-0.5821838-3.574585
	c-0.023056-0.1448669-0.0989227-0.3664856-0.1871643-0.56427h2.5213013l0.2417603,0.6478271
	c0.0676727,0.1843567,0.1276093,0.4815063,0.133728,0.6630554c-0.7345886,0.1014099-1.3023682,0.7259521-1.3023682,1.4883728
	c0,0.8329773,0.6752777,1.5082703,1.5082092,1.5082703c0.8329773,0,1.508255-0.675293,1.508255-1.5082703
	c0-0.6307068-0.3878784-1.1700745-0.9375458-1.3955078l0.138916-1.1383667
	c0.0088806-0.0703125,0.0018463-0.1679077-0.0091705-0.2653809h1.6450348l0.5085754,0.8965149
	c0.1274567,0.2266846,0.2843475,0.5940552,0.3482819,0.815979l0.4225159,1.4874268
	c0.0627136,0.2210083,0.2397766,0.5430603,0.3920746,0.7130432l0.12883,0.1454163
	c0.1522827,0.1699829,0.2506256,0.1454468,0.2167358-0.0557251l-0.5821838-3.5745544
	c-0.017868-0.1122131-0.0681915-0.2703857-0.1305084-0.4281006h0.723465c0.5902557-1.1864319,1.791687-2.0100708,3.1906433-2.010498
	v-0.7216492H-230.9123688z M-234.4048309,422.3340759l2.3034821,1.9323425h-7.6121216L-234.4048309,422.3340759z"/>
    </svg>
  ,
  'SPRAYER': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5092 7.10669H27.4853L27.4615 7.10954L1.85707 10.1821C1.63773 10.2084 1.48125 10.4075 1.50757 10.6269C1.53389 10.8462 1.73304 11.0027 1.95238 10.9764L27.0092 7.96956V8.17347L16.9832 11.6034H10.0498C8.94523 11.6034 8.0498 12.4988 8.0498 13.6034L8.0498 14.6596L8.00198 14.6759H7.53771L6.00144 11.6034H3.95308V15.7001L7.02562 15.7001L8.0498 15.7001L9.07398 15.7001L9.58645 15.7001C11.5932 15.7001 13.3002 16.9825 13.9327 18.7727H19.3641C20.4687 18.7727 21.3641 17.8772 21.3641 16.7727V13.6034C21.3641 12.6765 20.7335 11.8968 19.8779 11.67L27.671 9.00395L28.0092 8.88827V8.53087V7.90669H28.0213V7.10669H27.5092ZM13.1175 20.277C13.1175 18.2796 11.4987 16.6608 9.50126 16.6608C7.50385 16.6608 5.88442 18.2796 5.88442 20.277C5.88442 22.2745 7.50385 23.8933 9.50126 23.8933C11.4987 23.8933 13.1175 22.2745 13.1175 20.277ZM29.4953 18.5555V19.7181L22.3888 18.465V17.3024L29.4953 18.5555Z" fill={color}/>
    </svg>
  ,
  'NINJA': (color = '#b1b1b1', width = 24) =>
    <svg width={width} viewBox="0 0 50 28">
      <defs/>
      <g id="Canvas" transform="translate(-1781 -1040)">
        <g id="Combined Shape">
          <path transform="translate(1781.4 1040)" style={{ fill: color }} fillRule="evenodd"
                d="M 26.6021 22C 26.6021 17.582 30.184 14 34.6021 14L 38.058 14L 40.6021 0L 28.6021 0L 28.238 2L 2.23804 2L 0 14.3101L 15.478 17.75L 18.6021 24L 26.886 24C 26.718 23.358 26.6021 22.696 26.6021 22ZM 14.6021 24C 14.6021 26.208 12.8101 28 10.6021 28C 8.39404 28 6.60205 26.208 6.60205 24C 6.60205 21.792 8.39404 20 10.6021 20C 12.8101 20 14.6021 21.792 14.6021 24ZM 42.6021 22.0006L 42.6021 21.5146L 49.088 28.0006L 39.844 28.0006C 41.52 26.5326 42.6021 24.4026 42.6021 22.0006ZM 34.6021 16C 31.2881 16 28.6021 18.686 28.6021 22C 28.6021 25.314 31.2881 28 34.6021 28C 37.916 28 40.6021 25.314 40.6021 22C 40.6021 18.686 37.916 16 34.6021 16Z"/>

        </g>
      </g>
    </svg>
  ,
} as const

// keyof typeof IconMap - чтобы работали подсказки в IDE, если потребуется явное указание иконки
export const Icons: Record<keyof typeof IconMap | string, (color?:string, width?: number) => React.ReactElement> =
  IconMap
