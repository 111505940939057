import React from 'react'

export const SpeedometerIcon = ({ width = 28 }) =>
  <svg viewBox="0 0 428.948 428.948" width={width} height={width}>
    <g fill="#030104">
      <path d="M221.988 280.48c9.664 0 17.516-7.889 17.516-17.563 0-9.653-7.852-17.487-17.516-17.487l-79.361-58.371 61.844 76.985c.574 9.164 8.209 16.436 17.517 16.436zM127.008 332.197h174.933v41.513H127.008z" />
      <path d="M366.13 118.057c-40.51-40.509-94.369-62.818-151.656-62.818-57.288 0-111.148 22.309-151.657 62.818S0 212.425 0 269.713c0 36.99 9.319 72.539 26.816 103.997h40.086l29.319-16.929-12.948-22.428-37.7 21.768c-13.319-25.932-20.843-55.307-20.843-86.408 0-5.504.25-10.951.711-16.338l44.952 7.926 4.497-25.504-45.481-8.019c8.127-35.883 26.451-67.937 51.755-92.948l30.815 36.725 19.839-16.646-31.049-37.002c28.471-21.379 63.128-34.938 100.757-37.485v49.117h25.896V80.422c37.629 2.549 72.286 16.107 100.758 37.486l-31.05 37.001 19.838 16.646 30.816-36.726c25.303 25.012 43.627 57.066 51.754 92.948l-45.48 8.021 4.498 25.502 44.949-7.927c.461 5.388.711 10.834.711 16.338 0 31.103-7.521 60.479-20.842 86.409l-37.701-21.766-12.947 22.427 29.318 16.927h40.088c17.498-31.458 26.816-67.007 26.816-103.997.001-57.287-22.31-111.147-62.818-151.654z" />
    </g>
  </svg>


export const EquipmentIcon = ({ width = 28 }) =>
  <svg width={width} height={width} viewBox="0 0 31 25">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.7772 25C10.9708 25 10.2949 24.4412 10.1155 23.6897H17.6134C18.6852 23.6897 19.6756 23.1179 20.2115 22.1897L25.7493 12.598C26.2852 11.6698 26.2852 10.5262 25.7493 9.59798L23.672 5.99999H28.4043C29.737 5.99999 30.6969 7.27888 30.3247 8.55856L25.9619 23.5586C25.7135 24.4126 24.9309 25 24.0414 25H11.7772ZM18.4794 1.00623C18.3008 0.696824 17.9707 0.506226 17.6134 0.506226H6.5378C6.18054 0.506226 5.85041 0.696825 5.67178 1.00623L0.133975 10.598C-0.0446585 10.9074 -0.0446581 11.2886 0.133975 11.598L5.67178 21.1897C5.85041 21.4991 6.18054 21.6897 6.5378 21.6897L17.6134 21.6897C17.9707 21.6897 18.3008 21.4991 18.4794 21.1897L24.0172 11.598C24.1959 11.2886 24.1959 10.9074 24.0172 10.598L18.4794 1.00623ZM12.0688 15C14.278 15 16.0688 13.2091 16.0688 11C16.0688 8.79086 14.278 7 12.0688 7C9.85971 7 8.06885 8.79086 8.06885 11C8.06885 13.2091 9.85971 15 12.0688 15Z" fill="#202020"/>
  </svg>

export const CalendarIcon = ({ height = 30, width = 28 }) =>
  <svg width={width} height={height} viewBox="0 0 485.213 485.212">
    <path d="M60.652,75.816V15.163C60.652,6.781,67.433,0,75.817,0c8.38,0,15.161,6.781,15.161,15.163v60.653
		c0,8.38-6.781,15.161-15.161,15.161C67.433,90.978,60.652,84.196,60.652,75.816z M318.424,90.978
		c8.378,0,15.163-6.781,15.163-15.161V15.163C333.587,6.781,326.802,0,318.424,0c-8.382,0-15.168,6.781-15.168,15.163v60.653
		C303.256,84.196,310.042,90.978,318.424,90.978z M485.212,363.906c0,66.996-54.312,121.307-121.303,121.307
		c-66.986,0-121.302-54.311-121.302-121.307c0-66.986,54.315-121.3,121.302-121.3C430.9,242.606,485.212,296.919,485.212,363.906z
		 M454.89,363.906c0-50.161-40.81-90.976-90.98-90.976c-50.166,0-90.976,40.814-90.976,90.976c0,50.171,40.81,90.98,90.976,90.98
		C414.08,454.886,454.89,414.077,454.89,363.906z M121.305,181.955H60.652v60.651h60.653V181.955z M60.652,333.584h60.653V272.93
		H60.652V333.584z M151.629,242.606h60.654v-60.651h-60.654V242.606z M151.629,333.584h60.654V272.93h-60.654V333.584z
		 M30.328,360.891V151.628h333.582v60.653h30.327V94c0-18.421-14.692-33.349-32.843-33.349h-12.647v15.166
		c0,16.701-13.596,30.325-30.322,30.325c-16.731,0-30.326-13.624-30.326-30.325V60.651H106.14v15.166
		c0,16.701-13.593,30.325-30.322,30.325c-16.733,0-30.327-13.624-30.327-30.325V60.651H32.859C14.707,60.651,0.001,75.579,0.001,94
		v266.892c0,18.36,14.706,33.346,32.858,33.346h179.424v-30.331H32.859C31.485,363.906,30.328,362.487,30.328,360.891z
		 M303.256,242.606v-60.651h-60.648v60.651H303.256z M409.399,363.906h-45.49v-45.49c0-8.377-6.781-15.158-15.163-15.158
		s-15.159,6.781-15.159,15.158v60.658c0,8.378,6.777,15.163,15.159,15.163h60.653c8.382,0,15.163-6.785,15.163-15.163
		C424.562,370.692,417.781,363.906,409.399,363.906z"/>
  </svg>