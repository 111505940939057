import { UserActionsType,UserActionTypeEnum } from './actions'
import * as State from './state'

export default function user(state = State.init(), action: UserActionsType): State.User {
  switch(action.type) {
    case UserActionTypeEnum.PRELOADER_STOP:
      return State.stopPreload(state)

    case UserActionTypeEnum.USER_INFO:
      return State.setInfo(state, action.payload )

    case UserActionTypeEnum.USER_CONFIG:
      return State.setConfig(state, action.payload )

    case UserActionTypeEnum.USE_DEMO_MODE:
      return State.setDemoMode(state)

    case UserActionTypeEnum.SET_ITERATORS:
      return State.setIterators(state, action.payload )

    default:
      return state
  }
}
