import { Search } from '@agdt/agrotronic-react-components'
import { SingleCheckbox } from 'components/Filter/SingleCheckbox'
import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import { TTranslate } from 'types'
import TooltipD from 'ui/Tooltip'
import { scrollStyleMixin } from 'utils/styles'

const styles = {
  tooltip: { right: '0', bottom: '0' },
}

export type TProps = {
  colors?: string[]
  clearResult: () => void
  items: string[]
  name: string
  notTranslating?: boolean
  result: string[]
  search?: unknown
  setValue: (value: string) => void
  translate: TTranslate
}

const FilterCheckbox = ({
  colors,
  clearResult,
  items,
  name,
  notTranslating,
  result,
  search,
  setValue,
  translate,
}: TProps) => {
  const [searchText, setSearchText] = useState('')
  const [searchList, setSearchList] = useState(items)

  useEffect(() => {
    setSearchList(items)
  }, [items])

  const onSearch = (text: string) => {
    let list = items.filter(item =>
      item.toLowerCase().includes(text.toLowerCase()),
    )

    setSearchText(text)
    setSearchList(list)
  }

  return (
    <FilterItemContainer>
      <FilterItemHead>
        <FilterItemName>{translate(name)}</FilterItemName>
        <FilterItemClear onClick={clearResult}>{translate('clear')}</FilterItemClear>
        <span>{result.length ? result.length : ''}</span>
      </FilterItemHead>
      <FilterItemBody>
        {search && <SearchStyled value={searchText} onChange={onSearch} />}
        <FilterItemsContainer search={search}>
          {searchList.length ?
            searchList.map((item, index) =>
              <SingleCheckbox
                value={item}
                color={colors ? colors[index] : undefined}
                name={notTranslating ? item : translate(item)}
                key={index}
                checked={result.includes(item)}
                setChecked={setValue}
              />,
            )
            :
            <p>{translate('not_of_data')}</p>
          }
        </FilterItemsContainer>
      </FilterItemBody>
      <TooltipD
        text={translate('Select one or more values')}
        style={styles.tooltip}
      />
    </FilterItemContainer>
  )
}

const FilterItemContainer = styled.div`
  width: 200px;
  padding: 0 16px;
  box-sizing: content-box;
`

const FilterItemHead = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const FilterItemName = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
  text-transform: uppercase;
`

const FilterItemClear = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #D10029;
  position: relative;
  top: -15px;
  left: 15px;
  cursor: pointer;
`

const FilterItemBody = styled.div``

const SearchStyled = styled(Search)`
  height: 40px;
  margin-bottom: 10px;
`

const FilterItemsContainer = styled.div<{ search?: unknown }>`
  height: ${({ search }) => search ? '356px' : '406px'};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${scrollStyleMixin}
`

export default FilterCheckbox
