import { CodeConfirm, formats } from '@agdt/agrotronic-react-components'
import React, { FC,useCallback, useMemo, useState } from 'react'
import { updateCurrentUserContacts } from 'services/apiService/modules'
import { errorMessage, successMessage } from 'services/notification'
import styled from 'styled-components'
import { TTranslate } from 'types'

const { phone } = formats

type Props = {
  showConfirmation: boolean
  fieldName: string
  t: TTranslate
  value?: string
  closeHandler: () => void
  setShowConfirmation: (value: boolean) => void
  handleUpdateUserInfo: (fieldName: string, value: unknown) => void
}

export const CodeConfirmWrapper: FC<Props> = ({
  closeHandler,
  fieldName,
  handleUpdateUserInfo,
  setShowConfirmation,
  showConfirmation,
  t,
  value,
}) => {
  const [requestId, setRequestId] = useState<string>()
  const [confirmError, setConfirmError] = useState<string>()
  const [isLoadingRequestId, setIsLoadingRequestId] = useState(false)
  const [smsConfirmIsLoading, setSmsConfirmIsLoading] = useState(false)

  const texts = useMemo(() => ({
    common: {
      approve         : t('Approve'),
      confirmationCode: t('Confirmation code'),
      sendCode        : t('Send code'),
      sendNewCode     : t('Send new code'),
    },

    operatorEmail: {
      confirm: {
        text : <>{t('Enter the code sent to the address')} <Bold>{ value }</Bold></>,
        title: t('Enter code'),
      },

      request: {
        text : <>{t('To the address')} <Bold>{value}</Bold> {t('confirmation code will be sent')}</>,
        title: t('Email Confirmation'),
      },
    },

    operatorTelNumber: {
      confirm: {
        text : <>{t('Enter the code sent to the number')} <Bold>{ phone.phoneMask(value) }</Bold></>,
        title: t('Enter code'),
      },

      request: {
        text: <>
          {t('To the phone')} <Bold>{phone.phoneMask(value)}</Bold> {t('SMS with a confirmation code will be sent')}
        </>,
        title: t('Phone Confirmation'),
      },
    },
  }), [t, value])

  const windowType = requestId ? 'confirm' : 'request'

  const reset = useCallback(() => {
    setShowConfirmation(false)
    setRequestId(undefined)
    setConfirmError(undefined)
  }, [])

  const handleCloseModal = useCallback(() => {
    reset()
    closeHandler()
  }, [closeHandler, reset])

  const getRequestId = async () => {
    setIsLoadingRequestId(true)

    try {
      const result = await updateCurrentUserContacts({
        [fieldName]: value,
      })

      //@ts-expect-error
      setRequestId(result.requestId)
    } catch(error) {
      handleCloseModal()
      errorMessage(t('Error requesting code'))
    } finally {
      setIsLoadingRequestId(false)
    }
  }

  const confirmSMS = async (confirmCode: number) => {
    setSmsConfirmIsLoading(true)

    try {
      const result = await updateCurrentUserContacts({
        confirmCode,
        [fieldName]: value,
        requestId,
      })

      //@ts-expect-error
      handleUpdateUserInfo(fieldName, result[fieldName])
      successMessage(t('Changes successfully saved'))
      reset()
    } catch(error) {
      //@ts-expect-error
      const message = error?.message

      if(error && message) {
        setConfirmError(t(JSON.parse(message).errors))
      } else {
        errorMessage(t('Error confirming code'))
      }
    } finally {
      setSmsConfirmIsLoading(false)
    }
  }

  return <CodeConfirm
    closeHandler={handleCloseModal}
    commonTranslates={texts['common']}
    getConfirm={confirmSMS}
    getRequestId={getRequestId}
    isCloseByClickOutside={false}
    loadRequestId={isLoadingRequestId}
    requestId={requestId}
    requestRetryTimeout={120}
    showModal={showConfirmation}
    smsConfirmError={confirmError}
    smsConfirmIsLoading={smsConfirmIsLoading}

    //@ts-expect-error
    texts={texts[fieldName][windowType]}
  />
}

const Bold = styled.span`
  font-size: 16px;
  font-weight: 700;
`
