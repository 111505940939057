import React, { FC } from 'react'
import styled from 'styled-components'

type Props = {
  Icon?: FC<{color: string}>
  serial?: string
}

export const UnitCol: FC<Props> =({ serial, children, Icon }) => {
  return (
    <Col>
      {Icon && <Icon color="#A1A1A1" />}

      <Right>
        <Name hasSerial={Boolean(serial)}>{children}</Name>
        {serial && <Serial>{serial}</Serial>}
      </Right>
    </Col>
  )
}

const Right = styled.div`
  margin-left: 14px;
  width: calc(100% - 31px);
`

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-top: 8px;
`

const Name = styled.div<{hasSerial: boolean}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  height: ${({ hasSerial }) => hasSerial ? '' : '100%'};
  align-items: center;
  display: flex;
`

const Serial = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #A1A1A1;
`
