type SortingType = Readonly<{
  colId: string
  order: 'asc' | 'desc'
}>

export type FilterType = Readonly<{
  category?: string[]
  machine?: object
  status?: string[]
}>

export type Notification = Readonly<{
  currentRegister: unknown
  dateRange: unknown[]
  typeRange: unknown
  filter: FilterType
  isLoadingCalendarPainting: boolean
  isLoadingNotifications: boolean
  notifications: unknown
  queryParams: unknown
  page: number
  pageSize: number
  register: unknown
  registerData: unknown
  registerValue: unknown
  selectedMachine: unknown
  sorting: SortingType
}>


export const init = (): Notification => ({
  currentRegister: null,

  // For start state when date range is today date we can use registers as data source
  dateRange                : [],
  filter                   : {},
  isLoadingCalendarPainting: false,
  isLoadingNotifications   : false,
  notifications            : {},
  page                     : 0,
  pageSize                 : 10,
  queryParams              : undefined,
  register                 : null,
  registerData             : null,
  registerValue            : null,
  selectedMachine          : null,
  sorting                  : { colId: 'name', order: 'asc' },
  typeRange                : null,
})

export const setLoadingNotifications = (state: Notification, isLoadingNotifications: boolean): Notification => ({
  ...state,
  isLoadingNotifications,
})

export const setLoadingCalendarPainting = (state: Notification, isLoadingCalendarPainting: boolean): Notification => ({
  ...state,
  isLoadingCalendarPainting,
})

export const setSelectedMachine = (state: Notification, selectedMachine: unknown): Notification => ({
  ...state,
  page    : 0,
  pageSize: 10,
  selectedMachine,
  sorting : { colId: 'name', order: 'asc' },
})

export const setDateRange = (state: Notification, payload: unknown): Notification => ({
  ...state,

  /*@ts-expect-error*/
  dateRange      : payload.range || payload,
  page           : 0,
  pageSize       : 10,
  selectedMachine: null,
  sorting        : { colId: 'name', order: 'asc' },

  /*@ts-expect-error*/
  typeRange: payload.typeRange,
})

export const setFilter = (state: Notification, filter: FilterType): Notification => ({
  ...state,
  filter,
})

export const setRegisterValue = (state: Notification, registerValue: unknown): Notification => ({
  ...state,
  registerValue,
})

export const setRegister = (state: Notification, register: unknown): Notification => ({
  ...state,
  register,
})

export const clearRegister = (state: Notification): Notification => ({
  ...state,
  currentRegister: null,
  register       : null,
  registerData   : null,
  registerValue  : null,
})

export const setRegisterData = (state: Notification, registerData: unknown): Notification => ({
  ...state,
  registerData,
  registerValue: null,
})

export const setCurrentRegister = (state: Notification, currentRegister: unknown): Notification => ({
  ...state,
  currentRegister,
  registerValue: null,
})

export const setPage = (state: Notification, page: number): Notification => ({
  ...state,
  page,
})

export const setPageSize = (state: Notification, pageSize: number): Notification => ({
  ...state,
  page: 0,
  pageSize,
})
