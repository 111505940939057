import { formats } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
const { phone, string } = formats

type TValidator = {
  errorText: string
  validator: (arg: string) => boolean
}

export const notEmptyValidator = [
  {
    errorText: 'Can not be empty',
    validator: isEmpty,
  },
]

export const loginValidators = [
  {
    errorText: 'Login cannot be less than 6 characters',
    validator: (login: string) => login.length < 6,
  },
  {
    errorText: 'Login can contain only numbers and Latin letters',
    validator: (login: string) => !/^[a-zA-Z\d]+$/.test(login),
  },
  {
    errorText: 'Login cannot consist only of numbers',
    validator: (login: string) => /^\d+$/.test(login),
  },
]

export const passwordValidators = [
  {
    errorText: 'The password must contain at least 8 characters',
    validator: (value: string) => !string.passwordRegexp.test(value),
  },
]

export const emailValidators = [
  ...notEmptyValidator,

  {
    errorText: 'Invalid address format',
    validator: (email: string) => !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email),
  },
]

export const phoneValidator = [
  ...notEmptyValidator,

  {
    errorText: 'The phone number is specified in the wrong format',
    validator: (value: string) => !(phone.validate(value) && /\d+$/.test(value) && /79.+/.test(value)),
  },
]

export const validate = (validator: TValidator[]) => (value: string) => {
  const error = validator.find(V => V.validator(value))
  return error && error.errorText
}
