import DateBlock from 'components/Filter/DateBlock'
import { observer } from 'mobx-react'
import { demoMultinotices } from 'queries/authorization'
import React, { FC,useCallback } from 'react'
import { useDemoMode } from 'services/hooks'
import { getStore } from '../../../stores/storesRegistry'

const DateBlockFilter: FC = () => {
  const isDemo = useDemoMode()
  const store = getStore('notices')
  const { lang } = getStore('context')

  const switchMethod = useCallback(
    range => {
      if(isDemo) {
        /*@ts-expect-error*/
        demoMultinotices(data => { store.setData(data) }, lang)
      } else {
        if(range.typeRange === 'range') {
          store.period.setValue(range.range)
        } else {
          store.period.setPeriod(range.typeRange)
        }
      }
    },
    [isDemo, lang],
  )

  return (

    /*@ts-expect-error*/
    <DateBlock dateRange={store.period.value} setDateRange={switchMethod} />
  )
}

export default observer(DateBlockFilter)
