import {
  Button,
  hooks,
  icons,
  Input,
  InputPhoneEmail,
  ModalDialog,
  ModalFooterActions,
  ModalHeader,
  ModalOverlay,
} from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import { F, pipe } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled, { css } from 'styled-components'
import ResetPasswordStore from '../../stores/ResetPasswordStore'

type Entity = 'phoneOrEmail' | 'password' | 'confirmPassword' | 'code'

export const ResetPassword = observer(() => {
  const close = useCallback(() => {
    const backUrl = new URLSearchParams(window.location.search).get('backUrl')

    const defaultBackUrl = pipe(
      L => new URL(L),
      U => U.search = new URLSearchParams({ service: window.location.origin }).toString(),
    )(window.location)

    window.location.replace(backUrl || defaultBackUrl)
  }, [])

  const t = useTranslate()
  const { lang } = hooks.useLang()
  const translateIfPassed = useCallback(S => S && t(S), [t])

  const store = useMemo(() => new ResetPasswordStore(), [])

  const onChange = (entity: Entity) => (value: string) => {
    switch(entity) {
      case 'phoneOrEmail':
        store.phoneOrEmail.setValue(value)
        break

      case 'password':
        store.password.setValue(value)
        store.debouncedValidate()
        break

      case 'confirmPassword':
        store.confirmPassword.setValue(value)
        store.debouncedValidate()
        break

      case 'code':
        store.code.setValue(value)
        break

      default:
        return
    }
  }

  const onFocus = (entity: Entity) => () => {
    store.onFocus(entity)
  }

  const onInputCode = () => {
    store.debouncedValidate()
  }

  const onClickNextButton = () => {
    store.nextStep()
  }

  const onClickSendNewCode = () => {
    store.nextStep('code')
  }

  return <ModalOverlay
    visible={true}
    onClose={F}
  >
    <ModalDialogStyled>
      {/*@ts-expect-error*/}
      <ModalHeader>
        <StyledHeader>
          <icons.AgrotronicLogo size={316} lang={lang} />
        </StyledHeader>
      </ModalHeader>

      <Content>
        {store.step === 'code' && <Title key="code-reset-pwd">{t('Reset password')}</Title>}
        {store.step === 'success' && <>
          <Title key="code-reset-done">{t('Password reset')}</Title>

          <Caption2Text>
            {t('Password success changed')}
          </Caption2Text>
        </>}

        {store.step === 'start' && <>
          <Row key="phone email">
            <InputPhoneEmail
              onChange={onChange('phoneOrEmail')}
              handleOnBlur={store.setTypeAndValidateForm}
              error={Boolean(store.validationErrors.phoneOrEmail)}
              helperText={translateIfPassed(store.validationErrors.phoneOrEmail)}
              label={t('Phone / Email')}
              onFocus={onFocus('phoneOrEmail')}
              value={store.phoneOrEmail.exposedValue}
              required
              autoComplete="new-password"
            />
          </Row>

          <Row key="passwd">
            <Input
              onBlur={store.validate}
              error={Boolean(store.validationErrors.password)}
              helperText={translateIfPassed(store.validationErrors.password)}
              label={t('New password')}
              value={store.password.value}
              onChange={onChange('password')}
              onFocus={onFocus('password')}
              required
              type="password"
              autoComplete="new-password"
            />
          </Row>

          <Row key="conf psdw">
            <Input
              onBlur={store.validate}
              error={Boolean(store.validationErrors.confirmPassword)}
              helperText={translateIfPassed(store.validationErrors.confirmPassword)}
              label={t('Repeat password')}
              value={store.confirmPassword.value}
              onChange={onChange('confirmPassword')}
              onFocus={onFocus('confirmPassword')}
              required
              type="password"
              autoComplete="new-password"
            />
          </Row>
        </>}

        {store.step === 'code' && <>
          <Row key="second text">
            <SecondaryText>
              {t('Confirmation code sent to the email/phone number')}
            </SecondaryText>
          </Row>

          <Row key="code">
            <Input
              onBlur={store.validate}
              error={Boolean(store.validationErrors.code)}
              helperText={translateIfPassed(store.validationErrors.code)}
              label={t('Code')}
              value={store.code.value}
              onChange={onChange('code')}
              onInput={onInputCode}
              onFocus={onFocus('code')}
              required
            />
          </Row>
        </>}
      </Content>

      <ModalFooterActionsStyled>
        {store.step !== 'success' &&
          <ButtonStyled
            disabled={!store.isValid}
            onClick={onClickNextButton}
          >
            {t('Next')}
          </ButtonStyled>
        }

        {store.step === 'code' &&
          <ButtonStyled
            disabled={store.counter > 0}
            variant="text"
            onClick={onClickSendNewCode}
          >
            {t('Send new code')} {store.counter > 0
              ? [Math.floor(store.counter / 60), String(store.counter % 60).padStart(2, '0')].join(':')
              : ''}
          </ButtonStyled>
        }

        <ButtonStyled variant="text" onClick={close}>{t('To signin')}</ButtonStyled>
      </ModalFooterActionsStyled>
    </ModalDialogStyled>
  </ModalOverlay>
})



const ButtonStyled = styled(Button)`
  min-width: 316px;
  margin-top: 0.25rem; /* 8px */
  margin-bottom: 0.25rem; /* 8px */
`

const ModalFooterActionsStyled = styled(ModalFooterActions)`
  display: block;
`

const Content = styled.div<{loading?: boolean}>`
  width: 100%;
  min-width: 316px;
  display: flex;
  justify-content: ${({ loading }) => loading ? 'center' : 'normal'};
  flex-direction: column;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
`

const ModalDialogStyled = styled(ModalDialog)`
  width: 400px;
  padding: 0 42px 42px 42px;
`

const my4 = css`
  margin-top: 4px;
  margin-bottom: 4px;
`

const my8 = css`
  margin-top: 8px;
  margin-bottom: 8px;
`

const Title = styled.div`
  //styleName: Title 2/Medium 20;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  color: #111111;
  ${my8}
`

const Row = styled.div`
  ${my4}
`

const SecondaryText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-top: 4px;
  margin-bottom: 8px;
`

const Caption2Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 4px;
  margin-bottom: 8px;
`
