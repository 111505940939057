import { isNil } from 'ramda'
import React, { memo } from 'react'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { C_SKRP_STATUS_NAME, C_SKRP_STATUSES_MAP, C_TIME_COUNTER_REGISTERS } from '../../../constants'

const paramFormatter = (key: string, measure: string, value: number | string, t: TTranslate): string => {
  if(isNil(value) || value === 'NA') {
    return '-'
  }

  if(key.startsWith('FIRST_') || key.startsWith('LAST_')) {
    return value ? new Date(value).user.format('HH:mm:ss') : '-'
  } else if(C_TIME_COUNTER_REGISTERS.includes(key)) {
    // параметры, которые содержат единицу измерения часы (ч) нужно не показывать "ч" и переформатировать в чч:мм:сс
    //@ts-expect-error
    return prepareDuration(value * 3600000)
  } else if(key === C_SKRP_STATUS_NAME) {
    // параметр SKRP_STATUS (0, 1, 2, 3) нужно переводить в текст
    //@ts-expect-error
    return t(C_SKRP_STATUSES_MAP[value])
  } else if(key.startsWith('TIME_')) {
    //@ts-expect-error
    return value > 0 ? prepareDuration(value) : '-'
  } else {
    //@ts-expect-error
    return `${isNaN(parseFloat(value)) && isFinite(value) ? Math.round(value * 100) / 100 : value} ${measure}`
  }
}

type TParam = {
  key: string
  measure: string
  name: string
  value: number
}

type TProps = {
  item: {
    params: TParam[]
    title: string
  }
    t: TTranslate
}

export const Details = memo(({ item, t }: TProps) => <div className="detail-block">
  <h5 className="detail-block__title">{item.title}</h5>

  <ul className="detail-block__params">
    {item.params.map(param => <li className="detail-block__param" key={param.key}>
      <p className="detail-block__key">{param.name}</p>

      {param.key.startsWith('ON_') || param.key === 'FJD_ELSTEER_STATUS'
        ? <span className={`detail-block__indicator${param.value === 1 ? ' active' : ''}`} />
        : <p className="detail-block__value">{paramFormatter(param.key, param.measure, param.value, t)}</p>
      }
    </li>)}
  </ul>
</div>)

