import { AppDispatch } from 'reducers/store'
import { TaskType } from './state'

export enum NotificationTasksActionTypeEnum {
  GET_NOTIFICATION_TASKS = 'App/User/GET_NOTIFICATION_TASKS',
  EDIT_NOTIFICATION_TASK = 'App/User/EDIT_NOTIFICATION_TASK',
}

export type NotificationTasksActionsType = {
  type: NotificationTasksActionTypeEnum.GET_NOTIFICATION_TASKS
  payload: TaskType[]
} | {
  type: NotificationTasksActionTypeEnum.EDIT_NOTIFICATION_TASK

  // @todo странная ошибка типа, если сделать обязательным
  payload?: TaskType
}


export const getNotificationTasks = (tasks: TaskType[]) => (dispatch: AppDispatch) =>
  dispatch({ payload: tasks, type: NotificationTasksActionTypeEnum.GET_NOTIFICATION_TASKS })

export const editTask = (task: TaskType) => (dispatch: AppDispatch) =>
  dispatch({ payload: task, type: NotificationTasksActionTypeEnum.EDIT_NOTIFICATION_TASK })
