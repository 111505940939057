import moment from 'moment'
import { isEmpty, map, pipe, prop } from 'ramda'
import { AppDispatch, AppStateType } from 'reducers/store'
import { ThunkAction } from 'redux-thunk'
import {
  getDetailsByImei,
  getFill,
  getFuel,
  getGroupeMachines,
  getReportToEmail,
  getSensorsData,
  getTimeUsage,
} from 'services/apiService/modules'
import { errorMessage, successMessage } from 'services/notification'
import { TTranslate, TUnit } from 'types'
import { LANG_RU } from '../../constants/lang'
import { getSensorsDataPrepareJson,SensorsData } from '../../queries/request'
import { getAgroOperations } from '../../services/apiService/modules'
import { ReportType } from './state'

export enum ReportsActionTypeEnum {
  PUSH_REPORT = 'App/Reports/PUSH_REPORT',
  START_LOAD_REPORT = 'App/Reports/START_LOAD_REPORT',
  FINISH_LOAD_REPORT = 'App/Report/FINISH_LOAD_REPORT',
  CLEAR_RESULT = 'App/Report/CLEAR_RESULT',
  PUSH_TRACK_REPORT = 'App/Report/PUSH_TRACK_REPORT',
  FINISH_EXPORT_LOAD_REPORT = 'App/Report/FINISH_EXPORT_LOAD_REPORT',
  STOP_LOAD_REPORT = 'App/Report/STOP_LOAD_REPORT',
  FIXED_DATA_FILTER = 'App/Report/FIXED_DATA_FILTER',
  CLEAR_FIXED_DATA_FILTER = 'App/Report/CLEAR_FIXED_DATA_FILTER',
  REPORT_ERROR = 'App/Report/REPORT_ERROR',
  CLEAR_ERROR_REPORT = 'App/Report/CLEAR_ERROR_REPORT',
  PUSH_INDICATORS_REPORT = 'App/Report/PUSH_INDICATORS_REPORT',
  TOOGLE_CLEANING_REPORT = 'app/Report/TOOGLE_CLEANING_REPORT',
  PUSH_CLEANING_REPORT = 'app/Report/PUSH_CLEANING_REPORT',
  CHANGE_DENSITY = 'App/Report/CHANGE_DENSITY',
  TOGGLE_UNLOADING_REPORT = 'app/Report/TOGGLE_UNLOADING_REPORT',
  PUSH_UNLOADING_REPORT = 'app/Report/PUSH_UNLOADING_REPORT',
  PUSH_UNLOADING_CARRIER_REPORT = 'app/Report/PUSH_UNLOADING_CARRIER_REPORT',
  PUSH_REPORT_BY_PATH = 'app/Report/PUSH_REPORT_BY_PATH',
}

export type ReportsActionsType = {
  type: ReportsActionTypeEnum.PUSH_REPORT
  payload: {
    type: ReportType
    resultLoad: unknown[]
    data: unknown[]
  }
} | {
  type: ReportsActionTypeEnum.REPORT_ERROR
  payload: ReportType
} | {
  type: ReportsActionTypeEnum.CLEAR_ERROR_REPORT
  payload: ReportType
} | {
  type: ReportsActionTypeEnum.START_LOAD_REPORT
  payload: {
    type: ReportType
    from: unknown
    to: unknown
    ids: unknown
    detail: unknown[]
  }
} | {
  type: ReportsActionTypeEnum.FINISH_LOAD_REPORT
  payload: ReportType
} | {
  type: ReportsActionTypeEnum.FINISH_EXPORT_LOAD_REPORT
  payload: ReportType
} | {
  type: ReportsActionTypeEnum.CLEAR_RESULT
  payload: ReportType | 'cleaning'
} | {
  type: ReportsActionTypeEnum.PUSH_TRACK_REPORT
  payload: {
    type: Exclude<ReportType, ReportType.Time>
    imei: number | string
    data: unknown
  }
} | {
  type: ReportsActionTypeEnum.PUSH_INDICATORS_REPORT
  payload: {
    type: Exclude<ReportType, ReportType.Time>
    imei: number | string
    data: unknown
  }
} | {
  type: ReportsActionTypeEnum.STOP_LOAD_REPORT
  payload: ReportType
} | {
  type: ReportsActionTypeEnum.CLEAR_FIXED_DATA_FILTER
} | {
  type: ReportsActionTypeEnum.FIXED_DATA_FILTER
  payload?: string
} | {
  type: ReportsActionTypeEnum.TOOGLE_CLEANING_REPORT
} | {
  type: ReportsActionTypeEnum.PUSH_CLEANING_REPORT
  payload: unknown
} | {
  type: ReportsActionTypeEnum.TOGGLE_UNLOADING_REPORT
} | {
  type: ReportsActionTypeEnum.PUSH_UNLOADING_REPORT
  payload: unknown
} | {
  type: ReportsActionTypeEnum.PUSH_UNLOADING_CARRIER_REPORT
  payload: unknown
} | {
  type: ReportsActionTypeEnum.PUSH_REPORT_BY_PATH
  payload: {
    data: unknown
    reportPath: string[]
  }
} | {
  type: ReportsActionTypeEnum.CHANGE_DENSITY
  payload: {
    parent: string
    index: number
    value: number
  }
}

export const changeDensity = (data: {
  parent: string
  index: number
  value: number
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.CHANGE_DENSITY })

export const toogleCleaningReport = () => (dispatch: AppDispatch) =>
  dispatch({ type: ReportsActionTypeEnum.TOOGLE_CLEANING_REPORT })

export const pushCleaningReport = (data: object) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_CLEANING_REPORT })

export const toggleUnloadingReport = () => (dispatch: AppDispatch) =>
  dispatch({ type: ReportsActionTypeEnum.TOGGLE_UNLOADING_REPORT })

export const pushUnloadingReport = (data: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_UNLOADING_REPORT })

export const pushUnloadingCarrierReport = (data: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_UNLOADING_CARRIER_REPORT })

export const pushReportByPath = (data: {
  data: unknown
  reportPath: string[]
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_REPORT_BY_PATH })

export const pushReport = <T>(data: {
  type: ReportType
  resultLoad: unknown[]
  data: T
}) => (dispatch: AppDispatch) => {
    dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_REPORT })
  }

export const stopLoadReport = (type: ReportType): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) =>
  dispatch({ payload: type, type: ReportsActionTypeEnum.STOP_LOAD_REPORT })

export const finishLoadReport = (type: ReportType): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) =>
  dispatch({ payload: type, type: ReportsActionTypeEnum.FINISH_LOAD_REPORT })

export const clearReport = (type: ReportType): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) =>
  dispatch({ payload: type, type: ReportsActionTypeEnum.CLEAR_RESULT })

export const fixedDataFilter = (data?: string): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.FIXED_DATA_FILTER })

export const clearFixedDataFilter = (): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) =>
  dispatch({ type: ReportsActionTypeEnum.CLEAR_FIXED_DATA_FILTER })

/*@ts-expect-error*/
export const pushAgroOperationReport = (dispatch: AppDispatch, data, type: ReportType) => {
  const getDateWithUserTimeZone = (date: Date) => new Date().user.new(date)
  const formatDateTime = pipe(Date.parseDateTimeFromCalendarApi, getDateWithUserTimeZone)

  const query = {
    events: isEmpty(data.operations)
      ? ['ON_100', 'ON_UPLOAD', 'M0', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6']
      : data.operations,

    from                 : formatDateTime(data.time[0].from),
    imeis                : data.machine.map((item: TUnit) => Number(item.imei)),
    timezoneOffsetMinutes: data.gmt * 60,
    to                   : formatDateTime(data.time[0].to),
  }

  dispatch({
    payload: {
      detail: [query],
      from  : Date.parseDateTimeFromCalendarApi(data.time[0].from).user.getTime(),
      to    : Date.parseDateTimeFromCalendarApi(data.time[0].to).user.getTime(),
      type,
    },

    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getAgroOperations(query)
    .then(result => data.callbackOk(result))
    .catch(() => data.callbackError())
}

/*@ts-expect-error*/
export const pushTime = async (dispatch: AppDispatch, data, type: ReportType) => {
  const gmt = new Date().user.gmt
  let startDate = new Date().user
  startDate.setHours(0,0,0,0)
  let endDate = new Date().user
  endDate.setHours(23,59,59,999)

  const query = {

    /*@ts-expect-error*/
    imeis: data.machine.map(item => Number(item.imei)),

    ...data.time[0].today
      ? {
        from: startDate,
        to  : endDate,
      }
      : {
        from: new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,
        to  : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
      },

    timezoneOffsetMinutes: gmt * 60,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : data.time[0].from,
      to    : data.time[0].to,
      type,
    },
    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getTimeUsage({ ...query })
    .then(response => data.callbackOk(response))
    .catch(() => data.callbackError())
}

/*@ts-expect-error*/
export const pushFuelReport = (dispatch: AppDispatch, data, type: ReportType) => {
  const query = {
    from: new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,

    /*@ts-expect-error*/
    imeis                : data.machine.map(item => Number(item.imei)),
    timezoneOffsetMinutes: data.gmt * 60,
    to                   : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : Date.parseDateTimeFromCalendarApi(data.time[0].from).user.getTime(),
      to    : Date.parseDateTimeFromCalendarApi(data.time[0].to).user.getTime(),
      type,
    },

    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getFuel(query).then(result => data.callbackOk(result)).catch(error => data.callbackError(error))
}

/*@ts-expect-error*/
export const pushGroupMachineryReport = (dispatch: AppDispatch, data, type: ReportType) => {
  const query = {
    from : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,
    imeis: map(prop('imei'), data.machine),
    to   : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : +moment(data.time[0].from, 'YYMMDDHHmmss000'),
      to    : +moment(data.time[0].to, 'YYMMDDHHmmss000'),
      type,
    },
    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getGroupeMachines({ ...query }).then(result => data.callbackOk(result)).catch(error => data.callbackError(error))
}

/*@ts-expect-error*/
export const pushMachineReport = (dispatch: AppDispatch, data, type: ReportType) => {
  const query = {
    from                 : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,
    imei                 : data.machine[0].imei,
    timezoneOffsetMinutes: data.gmt * 60,
    to                   : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : Date.parseDateTimeFromCalendarApi(data.time[0].from).user.getTime(),
      ids   : [data.machine[0].id],
      to    : Date.parseDateTimeFromCalendarApi(data.time[0].to).user.getTime(),
      type,
    },

    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getDetailsByImei({
    ...query,
    lang: (data.lang || LANG_RU).toUpperCase(),
  })
    .then(result => data.callbackOk(result))
    .catch(error => data.callbackError(error))
}

/*@ts-expect-error*/
export const pushFueling = (dispatch: AppDispatch, data, type: ReportType) => {
  const query = {
    from: new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,

    /*@ts-expect-error*/
    imeis                : data.machine.map(item => Number(item.imei)),
    timezoneOffsetMinutes: data.gmt * 60,
    to                   : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc.getTime(),
      to    : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc.getTime(),
      type,
    },
    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  getFill(query).then(result => data.callbackOk(result)).catch(error => data.callbackError(error))
}

/*@ts-expect-error*/
export const pushIndicators = async (dispatch: AppDispatch, data, type: ReportType): Promise<SensorsData> => {
  const timezoneOffsetMinutes = new Date().user.gmt * 60

  const query = {
    from: new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc,
    to  : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc,
  }

  dispatch({
    payload: {
      detail: [query],
      from  : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].from)).utc.getTime(),
      to    : new Date().user.new(Date.parseDateTimeFromCalendarApi(data.time[0].to)).utc.getTime(),
      type,
    },
    type: ReportsActionTypeEnum.START_LOAD_REPORT,
  })

  return getSensorsDataPrepareJson(
    await getSensorsData({ imei: data.machine[0].imei, timezoneOffsetMinutes, ...query }) as SensorsData,
  )
}

export const pushTrackReport = (data: {
  type: Exclude<ReportType, ReportType.Time>
  imei: number | string
  data: unknown
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_TRACK_REPORT })

export const pushIndicatorsReport = (data: {
  type: Exclude<ReportType, ReportType.Time>
  imei: number | string
  data: unknown
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: ReportsActionTypeEnum.PUSH_INDICATORS_REPORT })

export const startLoadExportedReport = ({
  data,
  translate,
}: {
  data: {
    daily: boolean
    to: Date
    from: Date
    imeis: number[]
    lang: string | null
    timezoneOffsetMinutes: number
    type: string
  }
  translate: TTranslate
}) => async (dispatch: AppDispatch) => {
  try {
    const response = await getReportToEmail(data)

    if(response === null) {
      /*@ts-expect-error*/
      dispatch(finishExportedLoadExport(response))
    }

    /*@ts-expect-error*/
  } catch({ message: messageFromError }) {
    if(messageFromError) {
      const message = translate(JSON.parse(messageFromError).message)
      errorMessage(message ? translate(JSON.parse(messageFromError).message) : 'Ошибка при создании отчёта')
    }
  }
}

export const finishExportedLoadExport = (data: ReportType) => (dispatch: AppDispatch) => {
  successMessage('По мере готовности, отчет будет выслан на Ваш e-mail!')
  dispatch({ payload: data, type: ReportsActionTypeEnum.FINISH_EXPORT_LOAD_REPORT })
}

export const errorReport = (type: ReportType) => (dispatch: AppDispatch) => {
  dispatch({ payload: type , type: ReportsActionTypeEnum.REPORT_ERROR })
  setTimeout(() => dispatch({ payload: type, type: ReportsActionTypeEnum.CLEAR_ERROR_REPORT }), 4000)
}
