import { Loader } from 'components/Loader'
import { useUserTimezone } from 'hooks'
import { InteratorUnits } from 'queries/iteratorUnits'
import { find, isEmpty, isNil, map, prop, propEq } from 'ramda'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { AppStateType } from 'reducers/store'
import { enableDemoMode, preLoaderStop, setIterator } from 'reducers/user/actions'
import { UserInfoType } from 'reducers/user/state'
import { filterTypeUsers, userLogin } from 'reducers/users/actions'
import { apiServiceInit } from 'services/apiService'
import { useDemoMode } from 'services/hooks'
import notifications, { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'
import { TLang, TUser } from 'types'
import { C_SELECTED_FARM_LOCAL_STORAGE_KEY } from '../../constants'

type ConfigContextType = {
  farm: {
    accounts: TUser[]
    isLoading: boolean
    parent2Childrens: {}
    selectedFarmId: undefined
    setParent2Childrens: React.Dispatch<React.SetStateAction<{}>>
    setSelectedFarmId: (id: any) => Promise<void>
    units: unknown
  }
  gmt: number
  isDemo: string | boolean
  lang: TLang
  timezoneOffsetMinutes: number
  user: {
    VEHICLE_COUNT?: number
    NOTICES_PERIOD?: number
    HISTORY?: number
    REGISTERS_PERIOD?: number
  } & UserInfoType
}

export const ConfigContext = React.createContext<ConfigContextType | null>(null)

export const ValidateUser: FC = ({ children }) => {
  const isDemo = useDemoMode()
  const { setTimeZone } = useUserTimezone()
  const [accounts, setAccounts] = useState<TUser[]>([])
  const [farmUnits, setFarmUnits] = useState(null)
  const [selectedFarmId, setSelectedFarmId] = useState()
  const [parent2Childrens, setParent2Childrens] = useState({})
  const [isLoading, setIsLoading] = useState(true && !isDemo)
  const user = useSelector((state: AppStateType) => state.user.info)
  const lang = useSelector<AppStateType, TLang>((state: AppStateType) => state.Intl.locale as TLang)
  const userConfig = useSelector((state: AppStateType) => state.user.config)
  const stateDispatch = useDispatch()

  const setSelectedFarmAndLoadUnits = useCallback(async id => {
    if(id && id !== selectedFarmId) {
      const targets2 = getStore('dictionaries').targets2
      await targets2.waitValue()
      const units = targets2.filterDataForFarm(id)
      setFarmUnits(units)

      /*@ts-expect-error*/
      stateDispatch(filterTypeUsers(map(prop('id'), units)))
      setSelectedFarmId(id)

      // Обновляем данные регистерс ТОЛЬКО при переключении на другое хозяйство
      if(selectedFarmId !== undefined) {
        await targets2.refresh()
        const targets2Data = await targets2.cookUsersUnits(id)
        await stateDispatch(userLogin(targets2Data))
      }
    }

    setIsLoading(false)
  }, [selectedFarmId])

  const config = useMemo(() => {
    const gmt = user?.gmt
    const handledGmt = isNil(gmt) || isNaN(Number(gmt)) ? new Date().getTimezoneOffset() / -60 : Number(gmt)

    const conf = {
      farm: {
        accounts,
        isLoading,
        parent2Childrens,
        selectedFarmId,
        setParent2Childrens,
        setSelectedFarmId: setSelectedFarmAndLoadUnits,
        units            : farmUnits,
      },

      gmt                  : handledGmt,
      isDemo,
      lang,
      timezoneOffsetMinutes: handledGmt * 60,
      user                 : { ...user, config: userConfig },
    }

    //конфиг устанавливается до того как произошёл рендер
    getStore('context').updateByContext(conf)

    return conf
  }, [user, lang, accounts, parent2Childrens, selectedFarmId, setSelectedFarmAndLoadUnits, farmUnits, isLoading])

  useEffect(() => {
    const getAccounts = async () => {
      try {
        setAccounts(await getStore('dictionaries').usersTree.getValue())
      } catch(e) {
        errorMessage(e)
      }
    }

    getAccounts()
  }, [])

  useEffect(() => {
    const gmt = user?.gmt
    setTimeZone(gmt)
  }, [user?.gmt])

  // Инициализация при загрузке
  useEffect(() => {
    if(!user || isEmpty(user) || isEmpty(accounts) || selectedFarmId !== undefined) {
      return
    }

    const savedFarms = JSON.parse(localStorage.getItem(C_SELECTED_FARM_LOCAL_STORAGE_KEY) || '{}')

    /*@ts-expect-error*/
    const farmIdFromStorage = Number(prop(user?.id, savedFarms))
    const farmId = user.accountType === 'user' ? user.accountId : user.id

    const selectedFarm = find(propEq('id', farmIdFromStorage), accounts)
      || find(propEq('id', farmId), accounts)

    if(selectedFarm && selectedFarm.id !== selectedFarmId) {
      setSelectedFarmAndLoadUnits(selectedFarm.id)
    }
  }, [accounts, user])

  useEffect(() => {
    if(isDemo) {
      stateDispatch(enableDemoMode(lang))
      stateDispatch(preLoaderStop())
    } else {
      const iterator = new InteratorUnits(stateDispatch)
      iterator.iterateRequests()
      apiServiceInit()

      // TODO: user type account will be depricated
      notifications.init(prop('accountType', user) !== 'user')

      stateDispatch(setIterator(iterator))
    }
  }, [isDemo])

  return (
    <ConfigContext.Provider value={config}>
      <Loader key='Loader' />
      <div className="app" key={selectedFarmId}>{children}</div>
    </ConfigContext.Provider>
  )
}
