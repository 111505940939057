import React, { FC } from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { PolygonType } from 'ui/Map'
import { iconMarker, iconMarkerDelete, iconMarkerError } from '../../markersType'

type TProps = {
  deletePoint: (e: {latlng: {lat: number, lng: number}}, idx: number) => void
  isDeleteModeActive: boolean
  newGeoZone: {
    points: [number, number][]
    id: number
    validate?: boolean
  }[]
  newPointPosition: (e: Event, idx: number) => void
  setCurrentPolygon: (param: PolygonType) => void
}

export const EditGeoZone: FC<TProps> = ({
  deletePoint,
  isDeleteModeActive,
  newGeoZone,
  newPointPosition,
  setCurrentPolygon,
}) => {
  const onDeleteClick = (item: number) => (event: {latlng: {lat: number, lng: number}}) => {
    deletePoint(event, item)
  }

  const onDrag = (index: number) => (event: Event) => {
    newPointPosition(event, index)
  }

  return <>
    {newGeoZone.map(element => {
      return (
        <div key={element.id}>
          {isDeleteModeActive
            ? element.points.map((point, idx) =>
              <Marker
                icon={iconMarkerDelete}
                key={`${element.id}${idx}`}
                onClick={onDeleteClick(element.id)}
                position={point}
              />,
            )
            : element.points.map((point, idx) =>
              <Marker
                draggable
                icon={
                  element.validate !== false
                    ? iconMarker
                    : iconMarkerError
                }
                key={`${element.id}${idx}`}
                onDrag={onDrag(idx)}
                position={point}
              />,
            )}

          <Polygon
            color={element.validate !== false ? 'red' : '#bcbcbc'}
            interactive={true}
            key={'polygon' + element.id}
            positions={element.points}

            //@ts-expect-error
            ref={setCurrentPolygon}
          />
        </div>
      )
    })}
  </>
}
