import { Spinner } from '@agdt/agrotronic-react-components'
import { hooks } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import moment from 'moment/moment'
import { requestIndicators, requestTrack,RequestTrackData } from 'queries/request'
import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { ReportType } from 'reducers/reports/state'
import styled, { CSSProperties } from 'styled-components'
import { GraphicDigraph, IndicatorsType } from 'ui/Graphic/digraph'
import { MapDetailMachine, MarkerType } from 'ui/Map/mapUnit'
import { TrackType } from 'ui/Map/Track/TrackItem'
import TooltipD from 'ui/Tooltip'
import { exportSheet } from '../../result/common/exportSheet'
import { columns } from '../constants'
import { DataFueling, ReportInfo, Row } from './Row'

const styles = {
  tooltip: { right: 0, top: 0 },
  legend : {
    position: 'absolute',
    right   : 10,
    bottom  : 0,
    width   : 290,
    height  : 70,
  },
}

type FuelingResultProps = {
  reportWithFuelingAndDrain?: ReportInfo[]
  indicators?: Record<number, IndicatorsType>
  track?: Record<number, {
    TRACK_HARVEST?: TrackType[]
  }>
  showSpin: boolean
  request: {
    from: number
    to: number
  }
  report: unknown[]
}

export const FuelingResult: FC<FuelingResultProps> = ({
  reportWithFuelingAndDrain,
  track,
  showSpin,
  indicators,
  request,
  report,
}) => {
  const translate = useTranslate()
  const [marker, setMarker] = useState<MarkerType | undefined>(undefined)
  const [imei, setImei] = useState<number| null>(null)
  const [dataFueling, setDataFueling] = useState<DataFueling | null>(null)
  const [loading, setLoading] = useState(false)
  const locale = hooks.getLang()
  const stateDispatch = useDispatch()

  const endLoading = () => {
    setLoading(false)
  }

  const getTrack = (data: RequestTrackData) => {
    requestTrack.getTrack(data, stateDispatch, 'report')
  }

  const onRequestIndicators = (data: Required<RequestTrackData>) => {
    requestIndicators(data, endLoading, stateDispatch)
  }

  const exportToExcel = () => {
    exportSheet(
      [columns.map(column => translate(column.title))].concat(
        reportWithFuelingAndDrain?.map(row => columns.map(({ id }) => row?.[id])) || [],
      ),
      'fueling_report',
    )
  }

  const pushDataFueling = (data: DataFueling) => {
    if(loading) {
      return
    }

    setDataFueling(data)
    setMarker(data.marker)
    setImei(data.imei)

    if(!track?.[data.imei]) {
      getTrack({
        imei      : data.imei,
        from      : data.from,
        to        : data.to,
        reportType: ReportType.Fueling,
      })
    }

    if(!indicators?.[data.imei]) {
      setLoading(true)

      onRequestIndicators({
        imei      : data.imei,
        from      : moment(data.from).toDate(),
        to        : moment(Number(data.to)).toDate(),
        reportType: ReportType.Fueling,
        static    : false,
      })
    }
  }

  return (
    <div>
      <div className="watch__col-wrap" style={{ position: 'relative' }}>
        <div className="watch__col">
          <div className="map-wrap">
            <MapDetailMachine
              marker={reportWithFuelingAndDrain?.length ? marker : undefined}
              static={false}
              track={
                imei && track?.[imei]?.TRACK_HARVEST && reportWithFuelingAndDrain?.length
                  ? track?.[imei].TRACK_HARVEST
                  : []
              }
              legendStyle={styles.legend as CSSProperties}
              locale={locale}
            />
          </div>
        </div>

        <div className="watch__col">
          {(showSpin || loading) && <StyledSpinner />}

          {!showSpin && Boolean(reportWithFuelingAndDrain?.length) &&
            <Button
              type="primary"
              icon="download"
              style={{
                marginBottom: '10px',
                background  : '#78C800',
                border      : '1px solid #78C800',
              }}
              onClick={exportToExcel}
            >
              {translate('download excel')}
            </Button>
          }

          <TooltipD text={translate('Table filling/drain')} style={styles.tooltip} />

          <table>
            <thead>
              <tr>
                {columns.map(column => <th key={column.id}><p>{translate(column.title)}</p></th>)}
              </tr>
            </thead>

            <tbody>
              {Boolean(reportWithFuelingAndDrain?.length)
                && reportWithFuelingAndDrain?.map(
                  report =>
                    <Row
                      active={
                        imei === report.imei &&
                        report.from === marker?.startTime
                      }

                      isLoading={loading}
                      key={`${report.imei}_${report.from}`}
                      pushDataFueling={pushDataFueling}
                      report={report}
                      requestFrom={request.from}
                      requestTo={request.to}
                    />,

                )
              }

              {!showSpin && Boolean(!reportWithFuelingAndDrain?.length && report.length) &&
                <tr>
                  <td
                    colSpan={10}
                    style={{
                      textAlign      : 'center',
                      backgroundColor: '#f1cfcc',
                    }}
                  >
                    {translate('not_of_data')}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {imei && dataFueling && indicators?.[imei]?.time?.length && marker && Boolean(reportWithFuelingAndDrain?.length) &&
        <GraphicDigraph
          indicators={indicators[imei]}
          data={dataFueling}
          imei={imei}
          listEvent={
            reportWithFuelingAndDrain?.length
              ? reportWithFuelingAndDrain
              : undefined
          }
          translate={translate}
        />
      }
    </div>
  )
}

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 60%;
  top: 50%;
`
