import { Accordion } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { CategoryWrapper, Title } from '../../common/Styled'
import { Info } from './Info'
import Row from './Row'

type TProps = {
  handleSetSelectedUnit: (id: number) => void
  selectedUnitsIds: string[]
  translate: TTranslate
}

const UnitsSelector = ({
  handleSetSelectedUnit,
  selectedUnitsIds,
  translate,
}: TProps) => {
  const geoZonesStore = getStore('geoZones')
  const mapDisplaySettings = geoZonesStore.mapDisplaySettings
  const mapBuildSettings = geoZonesStore.mapBuildSettings

  return <CategoryWrapper>
    {/*@ts-expect-error*/}
    <Accordion
      isExpanded={!isEmpty(mapDisplaySettings.selectedUnits)}
      collapsedArrowDirection="bottom"
      expandedArrowDirection="top"
      title={<Title>{translate('Display machine data')}</Title>}
    >
      <UnitsListWrapper>
        {mapDisplaySettings.selectedUnits.map(unit => <Row
          key={unit.id}
          handleSetSelectedUnit={handleSetSelectedUnit}
          id={unit.id}
          number={unit.name}
          selected={selectedUnitsIds.includes(String(unit.id))}
          title={unit.typeName}
        />)}
      </UnitsListWrapper>

      {mapDisplaySettings.errorStatus && mapBuildSettings.selectedMapType === 'CURR_PRODUCTIVITY_ZUK' && <Info
        getGeoZonProductivity={geoZonesStore.getGeoZonProductivity}
        status={mapDisplaySettings.errorStatus}
        t={translate}
      />}
    </Accordion>
  </CategoryWrapper>
}

export default observer(UnitsSelector)

const UnitsListWrapper = styled.div`
  margin-top: 10px;
  max-height: 185px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
    width: 5px;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #e8e8e8;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
`
