import { useCallback,useEffect, useState } from 'react'

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = useCallback(() => ({
    height: hasWindow ? window.innerHeight : null,
    width : hasWindow ? window.innerWidth : null,
  }), [hasWindow])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    if(hasWindow) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if(hasWindow) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [hasWindow, getWindowDimensions])

  return windowDimensions
}
