import { Tooltip } from 'antd'
import React, { FC, ReactElement } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom'
import { TTranslate } from 'types'
import { link } from '../../../router'
import { prepareDuration } from '../../../ui/Map/duration'
import { modesSchema } from '../../../utils'
import { TimeDonut } from './TimeDonut'

const breakParams = [
  'AREA_PER_TIME',
  'TIME_NO_LINK',
  'TIME_MOVE_NO_HARVEST',
  'TIME_OFF',
  'TIME_STOP_DON',
  'TIME_HARVEST',
  'TIME_STOP_HARVEST',
  'TIME_STOP_HARVEST_FULL',
]

const getSumParams = (
  section: string,
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
  numRow: 1 | 2 | 3,
) => Object.keys(modelSections).map(item =>
  modelSections[item].types.size
    ? Object.keys(modelSections[item].types.toJS()).map(model => {
      const value = Math.round(

        // @ts-expect-error
        modelSections[item].types.toJS()[model].reduce<number>((acc, id) => acc + normilizeReport.data.get(id), 0)
        * 100,
      ) / 100 || '-'

      return <td
        key={model}
        rowSpan={modelSections[item].types.size <= 1
          ? (item === 'KUK' || item === 'ZUK') && numRow > 1
            ? numRow - 1
            : numRow
          : 1}
      >
        {section === 'INC_SUMM_MOTOR' && value !== '-' ? prepareDuration(value * 3600000) : value}
      </td>
    })

    : null,
)

const getSumParamsAverage = (
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
  numRow: 1 | 2 | 3,
) => Object.keys(modelSections).map(item =>
  modelSections[item].types.size ? Object.keys(modelSections[item].types.toJS()).map(model =>
    <td
      key={model}
      rowSpan={modelSections[item].types.size <= 1
        ? (item === 'KUK' || item === 'ZUK') && numRow > 1
          ? numRow - 1
          : numRow
        : 1}>
      {Math.round(modelSections[item].types.toJS()[model].reduce<number>((acc, id) =>

      // @ts-expect-error
        acc + normilizeReport.data.get(id), 0) / modelSections[item].types.toJS()[model].length * 100) / 100 || '-'}
    </td>)
    : null,
)

const getSumCombaineZukKuk = (
  section: string,
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
) => Object.keys(modelSections).map(item => {
  if(modelSections[item].types.size > 1 && (item === 'ZUK' || item === 'KUK')) {
    const value = Math.round(Object.keys(modelSections[item].types.toJS()).reduce((accum, model) =>
      accum +
      modelSections[item].types.toJS()[model].reduce<number>((acc, id) =>

      // @ts-expect-error
        acc + normilizeReport.data.get(id), 0), 0) * 100) / 100 || '-'

    return <td key={item} colSpan={modelSections[item].types.size}>
      { section === 'INC_SUMM_MOTOR' && value !== '-' ? prepareDuration(value * 3600000) : value }
    </td>
  }

  return null
})

const getSumCombaineZukKukAverage = (
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
) => Object.keys(modelSections).map(item =>
  modelSections[item].types.size > 1 && (item === 'ZUK' || item === 'KUK') ?
    <td
      key={item}
      colSpan={modelSections[item].types.size}
    >{Math.round(Object.keys(modelSections[item].types.toJS()).reduce((accum, model) =>
        accum +
        modelSections[item].types.toJS()[model].reduce<number>((acc, id) =>

        // @ts-expect-error
          acc + normilizeReport.data.get(id),
        0,
        ), 0,

        // @ts-expect-error
      ) / modelSections[item].types.reduce<number>((count, type) => count + type.size, 0) * 100) / 100 || '-'}</td>
    : null,
)

const getSumCombaine = (
  section: string,
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
) => {
  const value = Math.round(Object.keys(modelSections).reduce(
    (accumulator, item) => accumulator + (
      modelSections[item].types.size && (item === 'ZUK' || item === 'KUK')
        ? Object.keys(modelSections[item].types.toJS()).reduce((accum, model) =>
          accum + modelSections[item].types.toJS()[model].reduce<number>((acc, id) =>

          // @ts-expect-error
            acc + normilizeReport.data.get(id), 0), 0)

        : 0), 0) * 100,
  ) / 100 || '-'

  return <td colSpan={modelSections.ZUK.types.size + modelSections.KUK.types.size}>
    { section === 'INC_SUMM_MOTOR' && value !== '-' ? prepareDuration(value * 3600000) : value }
  </td>
}

const getSumCombaineAverage = (
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: {
    name: string
    measure: string
  },
) => <td
  colSpan={modelSections.ZUK.types.size + modelSections.KUK.types.size}>
  {Math.round(Object.keys(modelSections).reduce((accumulator, item) =>
    accumulator + (modelSections[item].types.size && (item === 'ZUK' || item === 'KUK')
      ? Object.keys(modelSections[item].types.toJS()).reduce((accum, model) =>
        accum + modelSections[item].types.toJS()[model].reduce<number>(

          // @ts-expect-error
          (acc, id) => acc + normilizeReport.data.get(id), 0) /
          modelSections[item].types.toJS()[model].length, 0)
    / modelSections[item].types.size : 0), 0)
    / Object.keys(modelSections).reduce((accumulator, item) =>
      accumulator +

      // @ts-expect-error
      modelSections[item].types.reduce<number>((count, type) => count + type.size, 0), 0) * 100) / 100 || '-'
  }
</td>

const getTimeCombaineZukKuk = (
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: FilterResultProps['normilizeReport'],
  date: string,
  translate: TTranslate,
) => {
  return Object.keys(modelSections).map(item => {
    if(modelSections[item].types.size > 1 && (item === 'ZUK' || item === 'KUK')) {
      const accum = {
        TIME_NO_LINK          : 0,
        TIME_MOVE_NO_HARVEST  : 0,
        TIME_OFF              : 0,
        TIME_STOP_DON         : 0,
        TIME_HARVEST          : 0,
        TIME_STOP_HARVEST     : 0,
        TIME_STOP_HARVEST_FULL: 0,
        sumParameters         : 0,
      }

      // @ts-expect-error
      const ids = Object.values(modelSections[item].types.toJS()).reduce((result, model) => {
        // @ts-expect-error
        return model.reduce((acc, id) => [...acc, id], result)
      }, [])

      // @ts-expect-error
      accum.TIME_NO_LINK += Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_NO_LINK.data.get(id), 0))

      accum.TIME_MOVE_NO_HARVEST +=

        // @ts-expect-error
        Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_MOVE_NO_HARVEST.data.get(id), 0))

      // @ts-expect-error
      accum.TIME_OFF += Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_OFF.data.get(id), 0))

      // @ts-expect-error
      accum.TIME_STOP_DON += Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_DON.data.get(id), 0))

      // @ts-expect-error
      accum.TIME_HARVEST += Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_HARVEST.data.get(id), 0))

      accum.TIME_STOP_HARVEST +=

        // @ts-expect-error
        Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_HARVEST.data.get(id), 0))

      accum.TIME_STOP_HARVEST_FULL +=

        // @ts-expect-error
        Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_HARVEST_FULL.data.get(id), 0))

      accum.sumParameters +=
        accum.TIME_NO_LINK + accum.TIME_MOVE_NO_HARVEST + accum.TIME_OFF + accum.TIME_STOP_DON + accum.TIME_HARVEST

      const timeNoLink = (accum.TIME_NO_LINK / accum.sumParameters * 100).toFixed(0)
      const timeMoveNoHarvest = (accum.TIME_MOVE_NO_HARVEST / accum.sumParameters * 100).toFixed(0)
      const timeOff = (accum.TIME_OFF / accum.sumParameters * 100).toFixed(0)
      const timeStopDon = (accum.TIME_STOP_DON / accum.sumParameters * 100).toFixed(0)
      const timeHarvest = (accum.TIME_HARVEST / accum.sumParameters * 100).toFixed(0)
      const timeStopHarvest = (accum.TIME_STOP_HARVEST / accum.sumParameters * 100).toFixed(0)
      const timeStopHarvestFull = (accum.TIME_STOP_HARVEST_FULL / accum.sumParameters * 100).toFixed(0)

      return <td colSpan={modelSections[item].types.size} key={item}>
        <Link
          to={`${link.watchTime}?machine=${ids.join()}&time=${date}&startFilter=true`}>
          <div style={{ width: '100%', backgroundColor: '#e8e8e8', height: '5px', display: 'flex' }}>
            <Tooltip
              title={`${translate('M0')} ${prepareDuration(accum.TIME_NO_LINK)}, ${timeNoLink}%`}
            >
              <span
                style={{
                  width          : `${timeNoLink}%`,
                  backgroundColor: modesSchema.TIME_NO_LINK.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M1')} ${prepareDuration(accum.TIME_MOVE_NO_HARVEST)}, ${timeMoveNoHarvest}%`}
            >
              <span
                style={{
                  width          : `${timeMoveNoHarvest}%`,
                  backgroundColor: modesSchema.TIME_MOVE_NO_HARVEST.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M2')} ${prepareDuration(accum.TIME_OFF)}, ${timeOff}%`}
            >
              <span
                style={{
                  width          : `${timeOff}%`,
                  backgroundColor: modesSchema.TIME_OFF.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M3')} ${prepareDuration(accum.TIME_STOP_DON)}, ${timeStopDon}%`}
            >
              <span
                style={{
                  width          : `${timeStopDon}%`,
                  backgroundColor: modesSchema.TIME_STOP_DON.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M4')} ${prepareDuration(accum.TIME_HARVEST)}, ${timeHarvest}%`}
            >
              <span
                style={{
                  width          : `${timeHarvest}%`,
                  backgroundColor: modesSchema.TIME_HARVEST.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M5')} ${prepareDuration(accum.TIME_STOP_HARVEST)}, ${timeStopHarvest}%`}
            >
              <span
                style={{
                  width          : `${timeStopHarvest}%`,
                  backgroundColor: modesSchema.TIME_STOP_HARVEST.color,
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${translate('M6')} ${prepareDuration(accum.TIME_STOP_HARVEST_FULL)}, ${timeStopHarvestFull}%`}
            >
              <span
                style={{
                  width          : `${timeStopHarvestFull}%`,
                  backgroundColor: modesSchema.TIME_STOP_HARVEST_FULL.color,
                }}
              />
            </Tooltip>
          </div>
        </Link>
      </td>
    }

    return null
  })
}


const getTimeCombaine = (
  modelSections: FilterResultProps['modelSections'],
  normilizeReport: FilterResultProps['normilizeReport'],
  date: string,
  translate: TTranslate,
) => {
  const accum = {
    TIME_NO_LINK          : 0,
    TIME_MOVE_NO_HARVEST  : 0,
    TIME_OFF              : 0,
    TIME_STOP_DON         : 0,
    TIME_HARVEST          : 0,
    TIME_STOP_HARVEST     : 0,
    TIME_STOP_HARVEST_FULL: 0,
    sumParameters         : 0,
  }

  const ids = [
    ...Object.values(modelSections.ZUK.types.toJS()).reduce((acc, item) => [
      ...acc,

      // @ts-expect-error
      ...item.reduce((acc, id) => [...acc, id], []),
    ], []),
    ...Object.values(modelSections.KUK.types.toJS()).reduce((acc, item) => [
      ...acc,

      // @ts-expect-error
      ...item.reduce((acc, id) => [...acc, id], []),
    ], []),
  ]

  // @ts-expect-error
  accum.TIME_NO_LINK = Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_NO_LINK.data.get(id), 0))

  accum.TIME_MOVE_NO_HARVEST =

    // @ts-expect-error
    Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_MOVE_NO_HARVEST.data.get(id), 0))

  // @ts-expect-error
  accum.TIME_OFF = Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_OFF.data.get(id), 0))

  // @ts-expect-error
  accum.TIME_STOP_DON = Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_DON.data.get(id), 0))

  // @ts-expect-error
  accum.TIME_HARVEST = Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_HARVEST.data.get(id), 0))

  // @ts-expect-error
  accum.TIME_STOP_HARVEST = Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_HARVEST.data.get(id), 0))

  accum.TIME_STOP_HARVEST_FULL =

    // @ts-expect-error
    Math.round(ids.reduce((acc, id) => acc + normilizeReport.TIME_STOP_HARVEST_FULL.data.get(id), 0))

  accum.sumParameters =
    accum.TIME_NO_LINK + accum.TIME_MOVE_NO_HARVEST + accum.TIME_OFF + accum.TIME_STOP_DON + accum.TIME_HARVEST

  const timeNoLink = (accum.TIME_NO_LINK / accum.sumParameters * 100).toFixed(0)
  const timeMoveNoHarvest = (accum.TIME_MOVE_NO_HARVEST / accum.sumParameters * 100).toFixed(0)
  const timeOff = (accum.TIME_OFF / accum.sumParameters * 100).toFixed(0)
  const timeStopDon = (accum.TIME_STOP_DON / accum.sumParameters * 100).toFixed(0)
  const timeHarvest = (accum.TIME_HARVEST / accum.sumParameters * 100).toFixed(0)
  const timeStopHarvest = (accum.TIME_STOP_HARVEST / accum.sumParameters * 100).toFixed(0)
  const timeStopHarvestFull = (accum.TIME_STOP_HARVEST_FULL / accum.sumParameters * 100).toFixed(0)

  return <td colSpan={modelSections.ZUK.types.size + modelSections.KUK.types.size}>
    <Link
      to={`${link.watchTime}?machine=${ids.join()}&time=${date}&startFilter=true`}>
      <div style={{ width: '100%', backgroundColor: '#e8e8e8', height: '5px', display: 'flex' }}>
        <Tooltip
          title={`${translate('M0')} ${prepareDuration(accum.TIME_NO_LINK)}, ${timeNoLink}%`}
        >
          <span
            style={{
              width          : `${timeNoLink}%`,
              backgroundColor: modesSchema.TIME_NO_LINK.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M1')} ${prepareDuration(accum.TIME_MOVE_NO_HARVEST)}, ${timeMoveNoHarvest}%`}
        >
          <span
            style={{
              width          : `${timeMoveNoHarvest}%`,
              backgroundColor: modesSchema.TIME_MOVE_NO_HARVEST.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M2')} ${prepareDuration(accum.TIME_OFF)}, ${timeOff}%`}
        >
          <span
            style={{
              width          : `${timeOff}%`,
              backgroundColor: modesSchema.TIME_OFF.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M3')} ${prepareDuration(accum.TIME_STOP_DON)}, ${timeStopDon}%`}
        >
          <span
            style={{
              width          : `${timeStopDon}%`,
              backgroundColor: modesSchema.TIME_STOP_DON.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M4')} ${prepareDuration(accum.TIME_HARVEST)}, ${timeHarvest}%`}
        >
          <span
            style={{
              width          : `${timeHarvest}%`,
              backgroundColor: modesSchema.TIME_HARVEST.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M5')} ${prepareDuration(accum.TIME_STOP_HARVEST)}, ${timeStopHarvest}%`}
        >
          <span
            style={{
              width          : `${timeStopHarvest}%`,
              backgroundColor: modesSchema.TIME_STOP_HARVEST.color,
            }}
          />
        </Tooltip>
        <Tooltip
          title={`${translate('M6')} ${prepareDuration(accum.TIME_STOP_HARVEST_FULL)}, ${timeStopHarvestFull}%`}
        >
          <span
            style={{
              width          : `${timeStopHarvestFull}%`,
              backgroundColor: modesSchema.TIME_STOP_HARVEST_FULL.color,
            }}
          />
        </Tooltip>
      </div>
    </Link>
  </td>
}

type TableReportProps = {
  translate: TTranslate
  date: string
  numRow: 1 | 2 | 3
  normilizeReport: FilterResultProps['normilizeReport']
  modelSections: FilterResultProps['modelSections']
}


const TableReport: FC<TableReportProps> = ({ normilizeReport, numRow, modelSections, date, translate }) =>
  <tbody>
    {Object.keys(normilizeReport).map(item => {
      if(breakParams.includes(item)) {
        return null
      }

      return <React.Fragment key={item}>
        {normilizeReport[item].name &&
          <tr>
            <td
              rowSpan={numRow}>{translate(normilizeReport[item].name)} ({translate(normilizeReport[item].measure)})
            </td>
            {getSumParams(item, modelSections, normilizeReport[item], numRow)}
          </tr>}

        {normilizeReport[item].name && numRow === 3 &&
          <tr>
            {getSumCombaineZukKuk(item, modelSections, normilizeReport[item])}
          </tr>
        }

        {normilizeReport[item].name && (numRow === 3 || numRow === 2) &&
          <tr>
            {getSumCombaine(item, modelSections, normilizeReport[item])}
          </tr>
        }
      </React.Fragment>
    })}

    {normilizeReport.AREA_PER_TIME.name &&
      <tr>
        <td rowSpan={numRow}>
          {translate(normilizeReport.AREA_PER_TIME.name)} ({translate(normilizeReport.AREA_PER_TIME.measure)})
        </td>
        {getSumParamsAverage(modelSections, normilizeReport.AREA_PER_TIME, numRow)}
      </tr>
    }

    {normilizeReport.AREA_PER_TIME.name && numRow === 3 &&
      <tr>
        {getSumCombaineZukKukAverage(modelSections, normilizeReport.AREA_PER_TIME)}
      </tr>
    }

    {normilizeReport.AREA_PER_TIME.name && (numRow === 3 || numRow === 2) &&
      <tr>
        {getSumCombaineAverage(modelSections, normilizeReport.AREA_PER_TIME)}
      </tr>
    }

    <tr>
      <td rowSpan={numRow}>{translate('use_of_time')}</td>

      {Object.keys(modelSections).map(item => Boolean(modelSections[item].types.size) && <TimeDonut
        date={date}
        item={item}
        key={item}
        normilizeReport={normilizeReport}
        numRow={numRow}
        t={translate}
        types={modelSections[item].types}
      />)}
    </tr>

    {numRow === 3 &&
      <tr>
        {getTimeCombaineZukKuk(modelSections, normilizeReport, date, translate)}
      </tr>
    }

    {numRow === 3 || numRow === 2 &&
      <tr>
        {getTimeCombaine(modelSections, normilizeReport, date, translate)}
      </tr>
    }
  </tbody>


type FilterResultProps = {
  from: string
  to: string
  numRow: 1 | 2 | 3
  normilizeReport: Record<string, {
    name: string
    measure: string
  }>
  modelSections: Record<string, {
    types: {
      size: number
      toJS: () => Record<string, unknown[]>
    }
    name: string
    icon: ReactElement | null
  }>
}

export const FilterResult: FC<FilterResultProps> = ({ modelSections, normilizeReport, numRow, from, to }) => {
  const translate = useTranslate()

  return (
    <div className="watch__full" style={{ overflow: 'auto' }}>
      <table className="testTable">
        <thead>
          <tr>
            <th rowSpan={2}/>

            {Object.values(modelSections).map(item => Boolean(item.types.size) &&
              <th
                key={item.name}
                colSpan={item.types.size > 1 ? item.types.size : undefined}
              >
                <Tooltip
                  title={translate(item.name)}
                  style={{ width: '50px' }}
                >
                  {item.icon ? item.icon : translate('Other')}
                </Tooltip>
              </th>)}
          </tr>

          <tr>
            {Object.values(modelSections).map(item => Object.keys(item.types.toJS()).map(key =>
              <th key={key}>
                {key} ({item.types.toJS()[key].length})
              </th>))}
          </tr>
        </thead>

        <TableReport
          modelSections={modelSections}
          normilizeReport={normilizeReport}
          numRow={numRow}
          date={`${from},${to}`}
          translate={translate}
        />
      </table>
    </div>
  )
}
