import { observer } from 'mobx-react'
import React, { FC, useCallback, useState } from 'react'
import { getStore } from 'stores/storesRegistry'
import { TTranslate } from 'types'
import MarkerButtons from './MarkerButtons'
import { NotificationOptions } from './NotificationOptions'
import { StopOptions } from './StopOptions'

type MarkersPanelProps = {
  imei: number
  centerTrack: () => void
  translate: TTranslate
}

const MarkersPanel: FC<MarkersPanelProps> = ({ centerTrack, imei, translate }) => {
  const unitTrack = getStore('householdUnits').getUnit(imei)
  const toggleMarkers = unitTrack.track.markers.toggleSelected
  const selectedMarkers = unitTrack.track.markers.selected

  const [showStopsNotificationPanel, setShowStopsNotificationPanel] = useState(false)
  const [showStopsIntervalsPanel, setShowStopsIntervalsPanel] = useState(false)

  const toggleNotificationOptions = useCallback(() => {
    setShowStopsNotificationPanel(!showStopsNotificationPanel)

    if(showStopsNotificationPanel) {
      toggleMarkers('notification', null)
    }
  }, [showStopsNotificationPanel])

  const toggleStopsIntervalsOptions = useCallback(() => {
    setShowStopsIntervalsPanel(!showStopsIntervalsPanel)
    toggleMarkers('stopIntervals', null)
  }, [showStopsIntervalsPanel])

  return <>
    <MarkerButtons
      imei={imei}
      centerTrack={centerTrack}
      toogleStopsIntevalsPanel={toggleStopsIntervalsOptions}
      showStopsIntervalsPanel={showStopsIntervalsPanel}
      showStopsNotificationPanel={showStopsNotificationPanel}
      toogleNotificationPanel={toggleNotificationOptions}
    />

    {showStopsIntervalsPanel &&
      <StopOptions
        toggleMarkers={toggleMarkers}
        shownMarkers={selectedMarkers['stopIntervals']}
        translate={translate}
      />
    }

    {showStopsNotificationPanel &&
      <NotificationOptions
        shownMarkers={selectedMarkers['notification']}
        toggleMarkers={toggleMarkers}
        translate={translate}
      />
    }
  </>
}

export default observer(MarkersPanel)
