import { differenceWith, prop } from 'ramda'
import { useMemo } from 'react'
import {TLayoutsConfig} from "../../layouts.config";

function getFromLS(key: string) {
  let ls: {[key: string]: string} = {}

  if(global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-7') ?? '{}')
    } catch(e) {
      /*Ignore*/
    }
  }

  return ls[key]
}

function saveToLS(key: string, value: string) {
  if(global.localStorage) {
    global.localStorage.setItem(
      'rgl-7',
      JSON.stringify({
        [key]: value,
      }),
    )
  }
}

/*@ts-expect-error*/
export default function useLayout(layoutsConfig: TLayoutsConfig, breakpoints) {
  // Check what layout from config loaded
  const [initialMode, layouts] = useMemo(() => {
    let layouts

    {// Load layout from local storage or use default from config
      const getVersion = prop('version')
      const storedLayout = getFromLS('layout')

      /*@ts-expect-error*/
      layouts = storedLayout && getVersion(storedLayout) === getVersion(layoutsConfig[0])
        ? storedLayout
        : layoutsConfig[0]
    }

    // Compare layout row by important keys
    /*@ts-expect-error*/
    const compareRow = (R1, R2) => {
      return ['i', 'x', 'y', 'w', 'h'].reduce((res, K) => res && R1[K] === R2[K], true)
    }

    // Sort rows by coordinates
    /*@ts-expect-error*/
    const sortRows = (A, B) => A.x - B.x || A.y - B.y

    // Compare layouts by breakpoints
    /*@ts-expect-error*/
    const layoutCompare = (L1, L2) => {
      let res = true

      Object.keys(breakpoints).forEach(K => {
        res = res && typeof L1[K] === typeof L2[K]

        if(res && L1[K]) {
          res = !differenceWith(compareRow, L1[K].sort(sortRows), L2[K].sort(sortRows)).length
        }
      })

      return res
    }

    // Compare each layout from config with current for detect what is actual
    for(let i = 0; i < layoutsConfig.length; i++) {
      if(layoutCompare(layouts, layoutsConfig[i]))
      {return [i, layouts]}
    }

    return [null, layouts]
  }, [])

  return { layouts, saveToLS, initialMode }
}
