import { getFill, getModeIntervals, getMultiNotices, getTrackEventsByUnit } from '../services/apiService/modules'

const time = new Date().user
const defaultFrom = new Date().startOf('day').user
const defaultTo = +time

export const requestGetEvents = async (
  imei: number,
  from = defaultFrom,
  to = defaultTo,
) => {
  return await getTrackEventsByUnit({
    from,
    imei: imei,
    to,
  })
}

export const requestGetStopIntervals = async ({
  eventTypes = ['M2', 'M3', 'M5'],
  from = defaultFrom,
  gmt,
  imei,
  to = defaultTo,
}: {
  eventTypes?: string[]
  from?: Date
  gmt: number
  imei: number
  to?: number
}) => {
  return await getModeIntervals({
    events               : eventTypes,
    from,
    imei,
    timezoneOffsetMinutes: gmt * 60,
    to,
  })
}

export const requestGetFillingDrains = async ({
  from = defaultFrom,
  gmt,
  imei,
  to = defaultTo,
}: {
  from?: Date
  gmt: number
  imei: number
  to?: number
}) => {
  return await getFill({
    from,
    imeis                : [imei],
    timezoneOffsetMinutes: gmt * 60,
    to,
  })
}

export const requestGetNotice = async ({
  imei,
  from = defaultFrom,
  gmt,
  to = defaultTo,
  lang,
}: {
  from?: Date
  gmt: number
  imei: number
  to?: number
  lang: string
}) => {
  const notices = await getMultiNotices({
    from,
    imeis                : [imei],
    lang                 : lang.toUpperCase(),
    timezoneOffsetMinutes: gmt * 60,
    to,
  }) as Record<number, unknown>

  return notices[imei] || []
}

export type LoadingMarkersInfo = {
  lat?: number
  lon?: number
  time: string
  sign?: string
}

export type FilingAndDrainMarkersInfo = {
  sign: string
  lat?: number
  lon?: number
  volume: number
  from: string
  to: string
}

export type StopIntervalMarkerInfo = {
  beg: string
  end: string
  lat?: number
  lon?: number
}

export type NotificationMarkersInfo = {
  status: number
  name: string
  timeSet?: string
  timeReset?: string
  lat?: number
  lon?: number
  statusName: string
  categoryName: string
}

export type PredictUnloadingMarkersInfo = {
  lat: number
  lon: number
  level: number
}

export type MarketResultItem = {
  type: 'stopsIntervals'
  data: Record<string, StopIntervalMarkerInfo[]>
} | {
  type: 'predictUnloading'
  data: PredictUnloadingMarkersInfo
} | {
  type: 'events'
  data: {
    on_100?: LoadingMarkersInfo[]
    on_upload?: LoadingMarkersInfo[]
  }
} | {
  type: 'notifications'
  data: NotificationMarkersInfo[]
} | {
  type: 'fueling'
  data?: {
    fillings: FilingAndDrainMarkersInfo[]
  }[]
}

type NormalizeResult = {
  filingAndDrain?: Required<FilingAndDrainMarkersInfo>[]
  loading?: Required<LoadingMarkersInfo>[]
  unloading?: Required<LoadingMarkersInfo>[]
  stopsIntervals?: Record<string, Required<StopIntervalMarkerInfo>[]>
  notification?: Required<NotificationMarkersInfo>[]
  predictUnloading?: PredictUnloadingMarkersInfo
}

export const normalizeEventMarkersResult = (result: MarketResultItem[]) =>
  result.reduce((acc: NormalizeResult, item) => {
    if(item.type === 'events') {
      if(item.data['on_100']) {
        acc['loading'] = item.data['on_100']
          .filter(element => element.lat && element.lon)
          .map(obj => ({ sign: 'LOADING', ...obj })) as Required<LoadingMarkersInfo>[]
      }

      if(item.data['on_upload']) {
        acc['unloading'] = item.data['on_upload']
          .filter(element => element.lat && element.lon)
          .map(obj => ({ sign: 'UPLOAD', ...obj })) as Required<LoadingMarkersInfo>[]
      }
    } else if(item.type === 'stopsIntervals') {
      Object.keys(item.data).forEach(mode => {
        acc['stopsIntervals'] = {
          ...acc['stopsIntervals'],
          [mode]: item.data[mode].filter(modeItems => modeItems.lat && modeItems.lon),
        } as Record<string, Required<StopIntervalMarkerInfo>[]>
      },
      )
    } else if(item.type === 'notifications') {
      acc['notification'] =
        item.data.filter(element => element.lat && element.lon) as Required<NotificationMarkersInfo>[] || []
    } else if(item.type === 'fueling') {
      acc['filingAndDrain'] = item.data?.[0]?.['fillings']?.filter(
        element => element.lat && element.lon,
      ) as Required<FilingAndDrainMarkersInfo>[]
        || []
    } else if(item.type === 'predictUnloading') {
      acc['predictUnloading'] = item.data
    }

    return acc
  }, {})
