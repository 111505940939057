import { formats } from '@agdt/agrotronic-react-components'
import moment from 'moment'
import React, { FC } from 'react'
import { Bar, BarChart, CartesianGrid, TickFormatterFunction, Tooltip, XAxis, YAxis } from 'recharts'
import { TTranslate } from 'types'
import { prepareDuration } from '../../../ui/Map/duration'

type ItemGraphicProps = {
  timeDistance?: boolean
  title: string
  name: string
  data: object[]
}

const ItemGraphic: FC<ItemGraphicProps> = ({ data, name, title, timeDistance }) => {
  const tickFormatter: TickFormatterFunction = (tik: string) => {
    return timeDistance
      ? `${moment.duration(tik).asHours().toFixed()} ч`
      : tik
  }

  const toolTipFormatter = (value: number | string | (string | number)[]) => {
    return timeDistance && !Number.isNaN(Number(value)) ? prepareDuration(Number(value)) : value
  }

  return (
    <div>
      <p style={{ textAlign: 'center', marginBottom: '10px' }}>{title}</p>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          bottom: 5,
          left  : 20,
          right : 30,
          top   : 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={tickFormatter}
        />
        <Tooltip formatter={toolTipFormatter}/>
        <Bar dataKey="value" fill="#8884d8" name={name} />
      </BarChart>
    </div>
  )
}

export type DataGraphicsBarReport = {
  from: string
  to: string
  timeHarvest: number
  harvest: unknown
  volume: unknown
  agriculture: unknown
  area: unknown
  density: unknown
  period_number: number
  fuel: unknown
}

export type GraphicsBarReport = {
  data: DataGraphicsBarReport[]
}

type GraphicsBarProps = {
  t: TTranslate
  titles: {
    time: string
    harvest: string
  }
  report: GraphicsBarReport
}

export const GraphicsBar: FC<GraphicsBarProps> = ({ report, t, titles }) => {
  return (
    <div className="graphics-full">
      <ItemGraphic
        data={report.data.map(item => {
          return {
            name : `${moment(formats.api.parseDateTimeFromApi(item.from)).format('DD.MM.YY')}`,
            value: moment.duration(item.timeHarvest).asMilliseconds(),
          }
        })}

        name={t('Harvesting time')}
        timeDistance={true}
        title={titles.time}
      />
      <ItemGraphic
        data={report.data.map(item => {
          return {
            name : `${moment(formats.api.parseDateTimeFromApi(item.from)).format('DD.MM.YY')}`,
            value: item.harvest,
          }
        })}

        name={t('Harvested crop')}
        title={titles.harvest}
      />
    </div>
  )
}
