import '@agdt/agrotronic-notifications'

/*@ts-expect-error*/
let widgetApi = null

export const init = async (useDefaultSettings = false) => {
  /*@ts-expect-error*/
  if(!window.createNotificationWidget) {
    return
  }

  /*@ts-expect-error*/
  widgetApi = await window.createNotificationWidget({
    maxDisplayedCount: 3,
    sdk              : window.sdk,
  })

  widgetApi.callOnce(useDefaultSettings)
}

export const successMessage = (text: unknown): void =>

  /*@ts-expect-error*/
  widgetApi.pushNewNotification([{
    color    : '#43a047',
    text,
    textColor: '#fff',
  }])


export const infoMessage = (text: unknown): void =>

  /*@ts-expect-error*/
  widgetApi.pushNewNotification([{
    color    : '#2979ff',
    text,
    textColor: '#fff',
  }])


export const warningMessage = (text: unknown): void =>

  /*@ts-expect-error*/
  widgetApi.pushNewNotification([{
    color    : '#ffa000',
    text,
    textColor: '#fff',
  }])


export const errorMessage = (text: unknown): void =>

  /*@ts-expect-error*/
  widgetApi.pushNewNotification([{
    color    : '#d32f2f',
    text,
    textColor: '#fff',
  }])


export default {
  errorMessage,
  infoMessage,
  init,
  successMessage,
  warningMessage,
}
