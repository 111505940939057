import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { isEmpty } from 'ramda'
import { TLang, TTranslate } from '../../../../../types'
import { TOperator } from '../../ResultTable/OperatorContent'

let cursor = 6

type TIterateReportProps = {
  sheet: ExcelJS.Worksheet
  rowNumber: number
  operator: TOperator
  level: number
  t: TTranslate
  lang: TLang
}

const iterateReport: (props: TIterateReportProps) => void = ({ sheet, rowNumber, operator, level, t, lang }) => {
  sheet.insertRow(
    rowNumber,
    [`${operator.name}, ${operator.telNumber ? formats.phone.phoneMask(operator.telNumber.toString()) : ''}`],
  )

  sheet.mergeCells(`A${rowNumber}:E${rowNumber}`)
  ++cursor

  if(operator.farms && !isEmpty(operator.farms)) {
    const headerRow = sheet.insertRow(
      cursor,
      [...new Array(level), `${t('farmings')}`, `${t('title')}`, '', `${t('date added')}`],
    )

    headerRow.outlineLevel = level
    ++cursor

    operator.farms.forEach(farm => {
      const dataRow = sheet.insertRow(cursor, [
        ...new Array(level + 1),
        `${farm.name}`,
        '',
        `${formats.date.dateViewFormatter(formats.api.parseDateTimeFromCalendarApi(farm.timestamp), lang)}`,
      ])

      dataRow.outlineLevel = level + 1
      ++cursor
    })

    if(operator.auditLog && !isEmpty(operator.auditLog)) {
      sheet.insertRow(cursor, undefined)
      sheet.getRow(cursor).outlineLevel = level
      ++cursor
    }
  }

  if(operator.auditLog && !isEmpty(operator.auditLog)) {
    // eslint-disable-next-line max-len
    sheet.insertRow(cursor, [...new Array(level), `${t('change history')}`, `${t('changed to')}`, `${t('changed')}`, `${t('date of change')}`])
    sheet.getRow(cursor).outlineLevel = level
    ++cursor

    operator.auditLog.forEach(log => {
      sheet.insertRow(cursor, [
        ...new Array(level + 1),
        // eslint-disable-next-line max-len
        `${log.field === 'photo' ? `${t('biometrics changed')}` : log.value}`, `${log.login || `${t('driver')}`}`, `${formats.date.dateViewFormatter(new Date(log.timestampCreate), lang)}`,
        ...new Array(2 - level),
      ])

      sheet.getRow(cursor).outlineLevel = level + 1
      ++cursor
    })
  }

  sheet.insertRow(cursor, undefined)
  ++cursor
}

type TCreateExcelFileProps = {
  t: TTranslate
  lang: TLang
  report: Record<string, TOperator>
  userLogin: unknown
  withoutFilters: unknown
}

export const createExcelFile: (props: TCreateExcelFileProps) => Promise<void> =
  async ({ report, t, userLogin, withoutFilters, lang }) => {
    cursor = 6
    const createDateTime = new Date()
    const workbook = new ExcelJS.Workbook()

    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/operators.xlsx`)

      // @ts-expect-error
      await workbook.xlsx.load(response.blob())
    } catch(err) {
      console.error(err)
    }

    const sheet = workbook.worksheets[0]

    // Generate report body
    Object.values(report).forEach(operator => {
      iterateReport({ lang, level: 1, operator, rowNumber: cursor, sheet, t })
    })

    //create header
    // eslint-disable-next-line max-len
    sheet.getCell('D1').value = `${t('formed')}: ${ userLogin }, ${formats.date.dateViewFormatter(createDateTime, lang)}, ${formats.date.timeFormatter(createDateTime, lang)}`
    sheet.getCell('A2').value = `${t('driver report')}`

    sheet.getCell('A3').value = withoutFilters
      ? `${t('the report was generated without filters')}`

      // eslint-disable-next-line max-len
      :`${t('the report is generated with filters')}: ${t('operators')}: ${Object.values(report).map(({ name }) => name).join(', ')}`

    sheet.getCell('A4').value = `${t('operator')}`
    sheet.getCell('B5').value = `${t('information')}`

    // Force download report
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = 'operators_report.xlsx'
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }
