import React from 'react'
import { Map } from 'react-leaflet'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { Link, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { link } from 'router'
import { getStore } from 'stores/storesRegistry'
import { MapComponent } from 'ui/Map'
import TooltipD from 'ui/Tooltip'
import { TGeoZone, TTranslate } from '../../types'
import { ConfigContext } from '../ValidateUser'
import GeoZones from './GeoZones'
import Households from './households'

type TProps = {
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
  translate: TTranslate
}

type TState = {
  mapRef: Map | null
  newGeoZone?: TGeoZone
}

class Tracking extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      mapRef    : null,
      newGeoZone: undefined,
    }
  }

  static contextType = ConfigContext

  componentWillUnmount() {
    //@ts-expect-error
    Object.values(getStore('households').map).forEach(unit => unit.track.removeIterator())
  }

  setMapRef = (mapRef: Map) => this.setState({ mapRef })

  setGeoZone = (zone: TGeoZone) => {
    this.setState({ newGeoZone: zone })
  }

  render() {
    return (
      <div className="tracking-page">
        <div className="tracking-page__list" id='detailsOuter'>
          <div className="tracking-page__nav">
            <Link
              to={link.households}
              className={
                this.props.location.pathname === link.tracking || this.props.location.pathname === link.households
                  ? 'active'
                  : ''}
            >
              {this.props.translate('farmings')}
            </Link>

            <Link
              to={link.geoZone}
              className={this.props.location.pathname === link.geoZone ? 'active' : ''}
            >
              {this.props.translate('geozones')}
            </Link>

            <TooltipD text={this.props.translate('Toggle viewing of objects on the map')} style={{}}/>
          </div>

          <Switch>
            <Redirect exact from={link.tracking} to={link.households} />

            <Route exact path={link.households}
              render={({ location, history }) =>
                <Households
                  history={history}
                  location={location}
                  mapRef={this.state.mapRef}
                />
              }
            />

            <Route
              path={link.geoZone}

              render={() =>
                <GeoZones
                  selectedFarmId={this.context?.farm?.selectedFarmId}
                  setGeoZone={this.setGeoZone}
                />
              }
            />
          </Switch>
        </div>

        <div className="tracking-page__map">
          <MapComponent
            setMapRef={this.setMapRef}
            editingGeoZone={this.state.newGeoZone}
          />
        </div>
      </div>
    )
  }
}

export default connect(state => ({

  //@ts-expect-error
  geoZone: state.geoZone,

  //@ts-expect-error
  map: state.map,
}))(withTranslate(Tracking))
