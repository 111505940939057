import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { Header } from '../../components/Widget/Header'
import { getStore } from '../../stores/storesRegistry'
import { FarmList } from './FarmList'
import FiltersList from './FiltersList'
import { Search } from './Search'

type TProps = {
  onClose: () => void
}

const Activity = ({ onClose }: TProps) => {
  const t = useTranslate()
  const selectedFarmId = getStore('context').selectedFarmId
  const activityStore = getStore('activityWidget')

  const onChangeSearch = useCallback((value: string) => {
    activityStore.setSearchText(value.toLowerCase())
  }, [])

  useEffect(() => {
    if(selectedFarmId) {
      activityStore.init()
    }

    return () => {
      activityStore.dispose()
    }
  }, [selectedFarmId])

  return (
    <>
      <Header
        counter={`${activityStore.totalUnitsCount} ${t('pcs')}`}
        onClose={onClose}
        title={t('All units')}
      />

      <FiltersList t={t} />

      <Search
        clear={activityStore.clear}
        clearLabel={t('clear')}

        isClearDisabled={!activityStore.statusesSelector.selectedStatuses.size
          && !activityStore.statusesSelector.selectedStatusesByHouseholds.size
          && isEmpty(activityStore.searchText)
        }

        onChange={onChangeSearch}
        placeholder={t('search')}
        searchText={activityStore.searchText}
      />

      <FarmList t={t} />
    </>
  )
}

export default observer(Activity)
