import moment from 'moment'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'

type ColumnSettings = {
  id: string
  index: number
  name: string
  sort: boolean
  tooltip: string
  width: number
  formatter?: Function
}

export const headerTableSummary: Record<string, ColumnSettings> = {
  count: {
    id     : 'count',
    index  : 4,
    name   : 'count',
    sort   : true,
    tooltip: 'Quantity for the period',
    width  : 1900,
  },
  duration: {
    formatter: prepareDuration,
    id       : 'duration',
    index    : 5,
    name     : 'duration',
    sort     : true,
    tooltip  : 'Duration for the period',
    width    : 3300,
  },
  event: {
    formatter: (item: string, t: TTranslate) => t(item),
    id       : 'event',
    index    : 3,
    name     : 'events',
    sort     : true,
    tooltip  : 'Event name',
    width    : 4150,
  },
  name: {
    id     : 'name',
    index  : 2,
    name   : 'machine',
    sort   : true,
    tooltip: 'Serial number machine',
    width  : 2600,
  },
  type: {
    id     : 'type',
    index  : 1,
    name   : 'type',
    sort   : true,
    tooltip: 'Type technics',
    width  : 2600,
  },
}

export const headerTableDetail: Record<string, ColumnSettings> = {
  beg: {
    formatter: (datetime: moment.MomentInput) => moment(datetime).format('HH:mm:ss'),
    id       : 'beg',
    index    : 5,
    name     : 'time_of_start',
    sort     : true,
    tooltip  : 'Event start time',
    width    : 1300,
  },
  begDay: {
    formatter: (datetime: moment.MomentInput) => moment(datetime).format('DD.MM.YYYY'),
    id       : 'begDay',
    index    : 4,
    name     : 'date_of_start',
    sort     : true,
    tooltip  : 'Event start date',
    width    : 1200,
  },
  coords: {
    id     : 'coords',
    index  : 8,
    name   : 'map',
    sort   : false,
    tooltip: 'Event location',
    width  : 700,
  },
  duration: {
    formatter: prepareDuration,
    id       : 'duration',
    index    : 7,
    name     : 'duration',
    sort     : true,
    tooltip  : 'Duration for the period',
    width    : 2000,
  },
  end: {
    formatter: (datetime: moment.MomentInput) => moment(datetime).format('HH:mm:ss'),
    id       : 'end',
    index    : 6,
    name     : 'time_of_end',
    sort     : true,
    tooltip  : 'Event end time',
    width    : 1300,
  },
  event: {
    formatter: (item: string, t: TTranslate) => t(item),
    id       : 'event',
    index    : 3,
    name     : 'events',
    sort     : true,
    tooltip  : 'Event name',
    width    : 2500,
  },
  name: {
    id     : 'name',
    index  : 2,
    name   : 'machine',
    sort   : true,
    tooltip: 'Serial number machine',
    width  : 1650,
  },
  type: {
    id     : 'type',
    index  : 1,
    name   : 'type',
    sort   : true,
    tooltip: 'Type technics',
    width  : 1650,
  },
}
