import { formats, helpers } from '@agdt/agrotronic-react-components'
import { urlApi } from 'constants/env'
import { action, configure, makeObservable, observable } from 'mobx'
import { isEmpty, isNil, keys, omit, pick } from 'ramda'
import PhoneEmailInputStore from 'stores/formFieldsStores/PhoneEmailInputStore'
import TextInputStore from 'stores/formFieldsStores/TextInputStore'

const { debounce } = helpers

configure({ enforceActions: 'observed' })

type TStep = 'start' | 'code' | 'success'
const C_TIMER = 120

export default class ResetPasswordStore {
  @observable step: TStep = 'start'
  @observable isLoading = false
  @observable _isValid = false
  phoneOrEmail = new PhoneEmailInputStore()
  password = new TextInputStore()
  confirmPassword = new TextInputStore()
  code = new TextInputStore()
  @observable validationErrors: { [key: string]: string } = {}
  touched = new Set()

  // Code step props
  //@ts-expect-error
  #requestId

  //@ts-expect-error
  #timer
  @observable counter = C_TIMER

  debouncedValidate: Function

  @action.bound
  async nextStep(forceState?: TStep) {
    const nextStep = forceState || (this.step === 'start' ? 'code' : 'success')

    if(['code', 'success'].includes(nextStep)) {
      try {
        const res = await this.changePasswordRequest(nextStep === 'success'
          ? { confirmCode: this.code.value, requestId: this.#requestId }
          : {
            ...this.phoneOrEmail.type === 'email'
              ? { 'email': this.phoneOrEmail.value }
              : { 'telNumber': formats.phone.onlyDigits(this.phoneOrEmail.value) },

            password: this.password.value,
          },
        )

        this.#requestId = res.requestId
      } catch(e) {
        if(nextStep === 'code') {
          this.validationErrors.phoneOrEmail = 'Error requesting code'
        } else {
          //@ts-expect-error
          this.validationErrors.code = e.cause?.status === 400 ? 'Wrong code' : 'Error during validation'

          // Clear code if wrong
          this.code.setValue('')
        }

        return
      }
    }

    if(nextStep === 'code') {
      this.startTimer()
    }

    this.step = nextStep
    this.validate()
  }

  startTimer() {
    if(this.#timer) {
      this.stopTimer()
    }

    this.counter = C_TIMER
    this.#timer = setInterval(this.iterateTime, 1000)
  }

  stopTimer() {
    clearInterval(this.#timer)
    this.#timer = null
  }

  @action.bound
  iterateTime() {
    if(!this.counter) {
      this.stopTimer()
    } else {
      this.counter--
    }
  }

  //@ts-expect-error
  onFocus(field) {
    this.touched.add(field)

    //@ts-expect-error
    if(this[field].onFocus) {
      //@ts-expect-error
      this[field].onFocus()
    }
  }

  set isValid(value){
    this._isValid = value
  }

  get isValid(){
    return this._isValid
  }

  @action.bound
  validate() {
    this.validationErrors = {}

    if(this.step === 'start') {
      this.validateStart()
    } else if(this.step === 'code') {
      this.validateCode()
    }

    // Нет ошибок - форма валидна
    this.isValid = isEmpty(this.validationErrors)

    // Выводим ошибки только на поля, которые были активированы ранее
    this.validationErrors = pick<any, any>([...this.touched.keys()], this.validationErrors)
  }

  validateCode() {
    this.validationErrors = {}

    // Все поля обязательные
    this.validateRequiredFields(['code'])

    if(!this.validationErrors['code'] && this.code.value.length !== 5) {
      this.validationErrors.code = 'Wrong code format'
    }
  }

  //@ts-expect-error
  validateRequiredFields(fields) {
    for(const F of fields) {
      //@ts-expect-error
      if(isEmpty(this[F as any].value) && this.touched.has(F)) {
        this.validationErrors[F as any] = 'This is a required field'
      }
    }
  }

  @action.bound
  validateStart() {
    // Все поля обязательные
    this.validateRequiredFields(['phoneOrEmail', 'password', 'confirmPassword'])

    // Валидация полей
    this.validationErrors = {

      // поля на которых нет еще ошибок
      ...omit<any, any>(keys(this.validationErrors),
        {
          ...this.password.value && this.password.value !== this.confirmPassword.value
            ? { confirmPassword: 'Confirm password mismatch' }
            : {},

          ...!formats.string.passwordRegexp.test(this.password.value)
            ? { password: 'Password cannot be less than 8 characters' }
            : {},

          ...isNil(this.phoneOrEmail.type) ? { phoneOrEmail: 'Wrong email or phone number' } : {},
        },
      ),

      ...this.validationErrors,
    }
  }

  setTypeAndValidateForm = (type: typeof PhoneEmailInputStore.prototype.type) => {
    this.phoneOrEmail.setType(type)
    this.validate()
  }

  async changePasswordRequest(payload = {}) {
    return await this.postData(`${urlApi}/password/restore`, {
      ...payload,
    })
  }

  async postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method     : 'POST', // *GET, POST, PUT, DELETE, etc.
      mode       : 'cors', // no-cors, *cors, same-origin
      cache      : 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit

      headers: {
        'Content-Type': 'application/json',
      },

      redirect      : 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body          : JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if(!response.ok) {
      // @ts-ignore
      throw new Error(response.statusText, { cause: response })
    }

    return await response.json() // parses JSON response into native JavaScript objects
  }

  constructor() {
    makeObservable(this)

    this.debouncedValidate = debounce(this.validate, 400).bind(this)
  }
}
