import React, { FC } from 'react'
import styled from 'styled-components'

type Props = {
  className: string
  title?: string
  counter?: string
  subtitle?: string | JSX.Element
}

export const Title: FC<Props> = ({ title, counter, subtitle, className }) => {
  return (
    <TitleContainer className={className}>
      {title && <TitleAndCounter>
        <TitleCaption>{ title }</TitleCaption>
        {counter && <Counter>{ counter }</Counter>}

      </TitleAndCounter>}

      {subtitle && <Subtitle>{ subtitle }</Subtitle>}
    </TitleContainer>
  )
}

export const TitleAndCounter = styled.div`
  display:flex;
`

export const TitleContainer = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  margin-left: 14px;
  margin-top: 9px;
`

export const TitleCaption = styled.h3`
  /* Bold 14 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  /* Color 3 */
  color: #202020;
`

export const Counter = styled.h3`
  /* Bold 14 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  /* Color 10 */
  color: #A1A1A1;

  margin-left: 6px;
`

export const Subtitle = styled.h4`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  /* Color 10 */
  color: #A1A1A1;
`
