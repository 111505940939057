import React from 'react'

export const type2file = {
  'ACROS 550'         : 'ACROS 550.jpg',
  'ACROS 550 (Itelma)': 'ACROS 550 (Itelma).jpg',
  'ACROS 580 E'       : 'ACROS 580 E.jpg',
  'ACROS 585'         : 'ACROS 585.jpg',
  'ACROS 585 PLUS'    : 'ACROS 585 PLUS.jpg',
  'ACROS 585d'        : 'ACROS 585d.jpg',
  'ACROS 590 E PLUS'  : 'ACROS 590 E PLUS.jpg',
  'ACROS 595'         : 'ACROS 595.jpg',
  'ACROS 595 PLUS'    : 'ACROS 595 PLUS.jpg',
  'ACROS_585'         : 'ACROS_585.jpg',
  'ACROS_595 PLUS'    : 'ACROS_595 PLUS.jpg',
  'CLAAS TUCANO 580'  : 'CLAAS_TUCANO_580.jpg',
  'F1300'             : 'F1300.jpg',
  'F1300it'           : 'F1300it.jpg',
  'F1500'             : 'F1500.jpg',
  'F2450'             : 'F2450.jpg',
  'JD 7830'           : 'JD_7830.jpg',
  'KSU 1'             : 'KSU 1.jpg',
  'KSU 2'             : 'KSU 2.jpg',
  'New Holland cx8080': 'New Holland cx8080.jpg',
  'NOVA'              : 'NOVA.jpg',
  'PANTERA 4502'      : 'PANTERA_4502.jpg',
  'RSM 5-30'          : 'RSM 5-30.jpg',
  'RSM 161'           : 'RSM 161.jpg',
  'RSM 1370'          : 'RSM 1370.jpg',
  'RSM 1401'          : 'RSM 1401.jpg',
  'RSM 1403'          : 'RSM 1403.jpg',
  'RSM 2375'          : 'RSM 2375.jpg',
  'RSM 2375it'        : 'RSM 2375it.jpg',
  'RSM 2400'          : 'RSM 2400.jpg',
  'RSM 2400it'        : 'RSM 2400it.jpg',
  'RSM 2405'          : 'RSM 2375.jpg',
  'RSM 3435'          : 'RSM 3435.jpg',
  'RSM 3485'          : 'RSM 3485.jpg',
  'RSM 3535'          : 'RSM 3535.jpg',
  'RSM 3575'          : 'RSM 3575.jpg',
  'RSM F 2550'        : 'RSM F 2550.jpg',
  'RSM F 2650'        : 'RSM F 2650.jpg',
  'RSM SPS-3800/36'   : 'SPS 3800.jpg',
  'RSM TS-3200/18'    : 'TS 3200.jpg',
  'RSM TS-3200/24'    : 'TS 3200.jpg',
  'RSM TS-3200/27'    : 'TS 3200.jpg',
  'RSM TS-4500/24'    : 'TS 4500.jpg',
  'RSM TS-4500/27'    : 'TS 4500.jpg',
  'RSM TS-6200/27'    : 'TS 6200.jpg',
  'RSM TS-6200/36'    : 'TS 6200.jpg',
  'S 300'             : 'S 300.jpg',
  'SC-8200'           : 'SC-12200_10200_8200_14800_18300.jpg',
  'SC-10200'          : 'SC-12200_10200_8200_14800_18300.jpg',
  'SC-12200'          : 'SC-12200_10200_8200_14800_18300.jpg',
  'SC-14800'          : 'SC-12200_10200_8200_14800_18300.jpg',
  'SC-18300'          : 'SC-12200_10200_8200_14800_18300.jpg',
  'SH-8200'           : 'SH-12200.jpg',
  'SH-10200'          : 'SH-12200.jpg',
  'SH-12200'          : 'SH-12200.jpg',
  'T 500'             : 'T 500.jpg',
  'TORUM 750'         : 'TORUM 750.jpg',
  'TORUM 755'         : 'TORUM 755.jpg',
  'TORUM 760'         : 'TORUM 760.jpg',
  'TORUM 770'         : 'TORUM 770.jpg',
  'TORUM 780'         : 'TORUM 780.jpg',
  'TORUM 785'         : 'TORUM 785.jpg',
  'TORUM 790'         : 'TORUM 790.jpg',
  'TS 3200'           : 'TS 3200.jpg',
  'VECTOR 410'        : 'VECTOR 410.jpg',
  'VECTOR 410g'       : 'VECTOR 410g.jpg',
  'VECTOR 420 E'      : 'VECTOR 420 E.jpg',
  'VECTOR 425'        : 'VECTOR 425.jpg',
  'VECTOR 430 E'      : 'VECTOR 430 E.jpg',
  'VECTOR 450 TRACK'  : 'VECTOR 450 TRACK.jpg',
  'VERSATILE 370'     : 'VERSATILE 370.jpg',
  'АР-3000/13'        : 'АР-3000.jpg',
  'АР-3000/21'        : 'АР-3000_21.jpg',
  'АР-4000/13'        : 'АР-3000.jpg',
  'АР-4000/21'        : 'АР-3000_21.jpg',
  'АР-4000/32'        : 'АР-4000.jpg',
}

type TProps = {
  typeName: keyof typeof type2file
}

export const UnitImage = ({ typeName }: TProps) => {
  return <img src={`${process.env.PUBLIC_URL}/img/jpg/${type2file[typeName]}`} alt="" />
}

export type TTypeName = keyof typeof type2file
