import { Button,icons, Spinner } from '@agdt/agrotronic-react-components'
import moment from 'moment'
import { requestTrack } from 'queries/request'
import { includes, keys, not, path } from 'ramda'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushMachineReport,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import { MapDetailMachine } from 'ui/Map/mapUnit'
import TooltipD from 'ui/Tooltip'
import { LANG_RU } from '../../../constants/lang'
import { formatter } from './helpers'
import { createExcelFile, CreateExcelParam } from './ToExcellHandler'

const { XlsIcon } = icons

const needParams = [
  'ACTIVE',
  'AGRICULTURE',
  'AREA_',
  'AVER_',
  'INC_',
  'NUM_',
  'SUMM_',
  'TIME_',
  'TO_',
  'ON_LIGHT',
  'ON_EMPTY',
  'ON_BLOCK',
  'TMP_INTAKE_MANIFOLD',
  'PR_INTAKE_MANIFOLD',
  'FAN_PERFORM',
  'FAN_CONTR_STATUS',
  'PR_STEERING',
  'ON_TPO',
  'ON_TPO_FRONT',
  'FR_TPO_FRONT',
  'REAR_ATTDEV_STATUS',
  'FRONT_ATTDEV_STATUS',
  'PR_BRAKE_LEFT',
  'PR_BRAKE_RIGHT',
  'TMP_REAR_ATTDEV_1',
  'TMP_REAR_ATTDEV_2',
  'TMP_REAR_ATTDEV_3',
  'TMP_REAR_ATTDEV_4',
  'TMP_REAR_ATTDEV_5',
  'TMP_REAR_ATTDEV_6',
  'TMP_FRONT_ATTDEV_1',
  'TMP_FRONT_ATTDEV_2',
  'CONS_SECT_1',
  'CONS_SECT_2',
  'CONS_SECT_3',
  'CONS_SECT_4',
  'CONS_SECT_5',
  'CONS_SECT_6',
  'CONS_FRONT_SECT_1',
  'CONS_FRONT_SECT_2',
  'WORK_SECT_1',
  'WORK_SECT_2',
  'WORK_SECT_3',
  'WORK_SECT_4',
  'WORK_SECT_5',
  'WORK_SECT_6',
  'WORK_FRONT_SECT_1',
  'WORK_FRONT_SECT_2',
  'ON_WAY',
  'ON_FRONTAXLE',
  'ON_TURNLANE',
  'ADAPTER',
  'PR_PNEVMO',
  'EQUIPMENT',
]

type Machine = {
  imei: number
  model: string
  name: string
  registers: Record<string, CreateExcelParam>
}

const MachineReports: FC<FilterControlConfig> = props => {
  const report = useSelector((state: AppStateType) => state.reports.get('machine'))

  //@ts-expect-error
  const machineSize = report.get('machine').size

  //@ts-expect-error
  const detailLoadSize = report.get('detailLoad').size

  //@ts-expect-error
  const resultLoadSize = report.get('resultLoad').size

  //@ts-expect-error
  const onStopReport = report.get('onStopReport')

  //@ts-expect-error
  const machineReport = path(['0', '0'], report.get('machine').toJS()) as Machine

  //@ts-expect-error
  const track = report.get('track')

  const imei = machineReport?.imei
  const login = useSelector((state: AppStateType) => state.user.info.operatorLogin || state.user.info.login) || ''
  const lang = useSelector((state: AppStateType) => state.user.info.lang) || LANG_RU
  const t = useTranslate()
  const [reportIsLoading, setReportIsLoading] = useState(false)
  const stateDispatch = useDispatch()

  const finishLoadReportAction = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  useEffect(() => {
    if(resultLoadSize >= detailLoadSize) {
      finishLoadReportAction(ReportType.Machine)
    }
  }, [detailLoadSize, finishLoadReportAction, resultLoadSize])

  useEffect(() => {
    if(onStopReport) {
      finishLoadReportAction(ReportType.Machine)
    }
  }, [finishLoadReportAction, onStopReport])

  const exportToExcelHandler = () => {
    if(reportIsLoading) {
      return
    }

    setReportIsLoading(true)

    createExcelFile({

      //@ts-expect-error
      from : report.get('request').from,
      login,
      model: machineReport.model,
      name : machineReport.name,
      parameters,
      t,

      //@ts-expect-error
      to: report.get('request').to,
    })
      .catch(() => errorMessage(t('Error creating file')))
      .finally(() => { setReportIsLoading(false) })
  }

  const parameters = useMemo(() => {
    if(!machineReport){
      return []
    }

    const registers = machineReport?.registers

    return keys(registers)
      .sort()

      .filter(R => not(includes(R, [
        'AVER_SPEED_COMBINE', 'INC_SUMM_AREA', 'MAX_TMP_GST_ROTOR', 'TO_10', 'TO_50', 'TO_250', 'TO_500', 'TO_1000',
        'TO_1500', 'INC_SUMM_FUEL', 'INC_SUMM_FUEL_STOP', 'INC_SUMM_FUEL_COMB', 'INC_SUMM_FUEL_MOVE',
      ])))

      .reduce((acc: CreateExcelParam[], key) => {
        if(needParams.find(item => key.indexOf(item) === 0) && registers[key] && registers[key].value) {
          acc.push({
            key,
            measure: registers?.[key]?.measure,
            name   : registers?.[key]?.name,
            value  : registers?.[key]?.value,
          })
        }

        return acc
      }, [])
  }, [machineReport])

  return (
    <div className="watch__wrap">
      <FilterControl
        button={true}
        disableButton={detailLoadSize !== resultLoadSize}
        translate={t}
        {...props}
      />

      <div className="watch__col-wrap2">
        <div className="watch__col" style={{ position: 'relative' }}>
          {detailLoadSize !== resultLoadSize && <StyledSpinner />}

          {Boolean(machineSize) && <Button
            disabled={reportIsLoading}
            height="24"
            onClick={exportToExcelHandler}
            variant='border'
            width='200px'
          >
            {reportIsLoading ? <Spinner color="#D5D2D2" size={24} /> : <XlsIcon />}
            <DownloadTextStyled>{t('Download')}</DownloadTextStyled>
          </Button>}

          {parameters.length > 0 &&
            <TooltipD
              text={t('Machine condition for the selected period')}
              style={{ left: '-15px' }}
            />
          }

          <ReportTable>
            {!onStopReport && parameters.map(P => <p key={P.key}>
              {P.name}

              <span>{formatter(P.key, P.value, P.measure)}</span>
            </p>)}
          </ReportTable>
        </div>

        <div className="watch__col" style={{ position: 'relative' }}>
          {detailLoadSize !== resultLoadSize && !track && <StyledSpinner />}

          <MapDetailMachine
            static={false}
            track={
              !onStopReport && imei && track && track[imei]
                ? track[imei].TRACK_HARVEST
                : []
            }
            locale={lang}
            legendStyle={{
              bottom  : '00px',
              height  : '70px',
              position: 'absolute',
              right   : '10px',
              width   : '290px',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      type    : 'radio-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
    },
    {
      id      : 2,
      type    : 'date',
      section : 'time',
      name    : 'select_a_date',
      validate: true,
      result  : [],
    },
  ],

  callback: data => dispatch => {
    data.callbackOk = (json: unknown[]) => {
      dispatch(pushReport({ data: json, type: ReportType.Machine, resultLoad: [] }))
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.Machine))
      dispatch(errorReport(ReportType.Machine))
    }

    dispatch(clearReport(ReportType.Machine))
    pushMachineReport(dispatch, data, ReportType.Machine)

    requestTrack.getTrack(
      {
        imei      : data.machine[0].imei,
        from      : moment(data.time[0].from, 'YYMMDDHHmmss000').unix() * 1000,
        to        : moment(data.time[0].to, 'YYMMDDHHmmss000').unix() * 1000,
        reportType: ReportType.Machine,
      },
      dispatch,
      'report',
    )
  },
  clear: () => dispatch => {
    dispatch(clearReport(ReportType.Machine))
  },

  //@ts-expect-error
})(MachineReports)

const StyledSpinner = styled(Spinner)`
  left: 50%;
  position: absolute;
  top: 50%;
`

const ReportTable = styled.div`
  padding: 30px 20px 30px 0;
  width: 100%;

  && p {
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }

  && span {
    font-size: 12px;
    font-weight: bold;
  }
`

const DownloadTextStyled = styled.span`
  font-size: 14px;
  margin-left: 6px;
`
