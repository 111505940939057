import { Button, ModalDialog, ModalFooterActions, ModalHeader, ModalOverlay } from '@agdt/agrotronic-react-components'
import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'

type Props = {
  onClose: () => void
}

export const TooLongPeriodError: FC<Props> = ({
  onClose,
}) => {
  const t = useTranslate()
  
  return <ModalOverlay visible onClose={onClose}>
    <ModalDialog>
      <ModalHeader onClose={onClose}>
        <TitleContainer>{t('The interval selected is too large')}</TitleContainer>
      </ModalHeader>

      <ModalBody>
        {t('The range between the selected dates must not exceed 30 days')}
      </ModalBody>

      <ModalFooterActions>
        <Button onClick={onClose} width="auto">
          {t('Close')}
        </Button>
      </ModalFooterActions>
    </ModalDialog>
  </ModalOverlay>
}


const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
`

const ModalBody = styled.div`
  margin: 24px 0;
  font-size: 16px;
`
