import React, { useCallback, useEffect, useMemo,useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export type TProps = {
  children: React.ReactNode
  className?: string
  height?: number
}

const DimensionsMeasurer = ({ children, className, height }: TProps) => {
  const containerRef = useRef<HTMLDivElement>()
  const [size, setSize] = useState<{ height?: number, width?: number}>({ height: Number(height), width: undefined })

  const updateSize = useCallback(() => setSize({
    height: height || containerRef.current?.clientHeight,
    width : containerRef.current?.clientWidth,
  }), [containerRef, height])

  const resizeObserver = useMemo(() => new ResizeObserver(updateSize), [updateSize])

  useEffect(() => {
    if(containerRef.current) {updateSize()}
  }, [containerRef, updateSize])

  useEffect(() => {
    // load
    if(containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      // unload
      resizeObserver.disconnect()
    }
  }, [containerRef, resizeObserver])

  return (

    /*@ts-expect-error*/
    <div ref={containerRef} className={className}>
      {/*@ts-expect-error*/}
      {Boolean(size.height) && children(size, containerRef.current)}
    </div>
  )
}

export default DimensionsMeasurer
