import { hooks } from '@agdt/agrotronic-react-components'
import * as mobx from 'mobx'
import { cookReqisters } from 'queries/iteratorUnits'
import { filter, flatten, values, without } from 'ramda'
import { demoRequest } from 'services/apiService/demoQueries'
import { getRegistersWithUnitsGrouping } from 'services/apiService/modules'
import { getStore } from 'stores/storesRegistry'
import { TStatus, TUnit } from 'types'
import { TFarmUnitsIdsCountByStatuses } from '../HomeWidgets/ActivityWidgetStore/types'

const { useDemoMode } = hooks

const isDemo = useDemoMode()

mobx.configure({ enforceActions: 'observed' })

const C_REGISTERS = [
  'ACTIVE',
  'ADAPTER',
  'AGRICULTURE',
  'AREA_PER_TIME',
  'AVER_SPEED_COMBINE',
  'CURR_PERF', // ???
  'DRIVER',
  'EQUIPMENT',
  'FIRST_MOTOR',
  'FUEL',
  'INC_SUMM_AREA',
  'INC_SUMM_FUEL',
  'INC_SUMM_FUEL_COMB',
  'INC_SUMM_FUEL_MOVE',
  'INC_SUMM_FUEL_STOP',
  'INC_SUMM_MOTOR',
  'INC_SUMM_PATH',
  'LATITUDE',
  'LOAD',
  'LONGITUDE',
  'MESSAGES_ALL', // ???
  'SPEED',
  'STATUS',
  'STATUS_SH',
  'TIME_HARVEST',
  'TIME_MOVE_NO_HARVEST',
  'TIME_NO_LINK',
  'TIME_OFF',
  'TIME_STOP_DON',
  'TIME_STOP_HARVEST',
  'TIME_STOP_HARVEST_FULL',
  'TYP_HARVESTER_NUMBER',
  'NUM_MESSAGES_ALL',
  'NUM_MESSAGES_CRIT',
  'NUM_MESSAGES_INFO',
  'NUM_MESSAGES_NOCRIT',
  'NUM_MESSAGES_SERVICE',
  'TO_10',
  'TO_50',
  'TO_250',
  'TO_500',
  'TO_1000',
  'TO_1500',
]

export type TUnitsIdsByStatus = {
  status: TStatus
  unitIds: number[]
}

export type TUnitsIdsCountByStatuses = {
  id: number
  statuses: TUnitsIdsByStatus[]
}

type TUnitWithType = {
  typeName: string
} & TUnit

export default class UnitsStore {
  // Для остановки авторана
  private disposeLoadAutorun?: mobx.IReactionDisposer

  @mobx.observable unitsIdsCountByStatuses: TFarmUnitsIdsCountByStatuses[] = []
  @mobx.observable _value: {[key: string]: TUnit} | undefined
  fetchApi: () => Promise<any>

  //TODO: later twice get value must not load dict twice
  loadingPromise: Promise<any> | undefined

  @mobx.computed get value() {
    const accountsTree = getStore('accountsTree')

    if(this._value === undefined) {
      return undefined
    }

    const filteredAccountsId = {}

    for(const K of Object.keys(this._value)) {
      const originalUnit = this._value[K]

      const parentsAccounts = flatten(originalUnit.accountIds.map(

        //@ts-expect-error
        A => Array.from(accountsTree.parentsListMap[A] || {}),
      ))

      //@ts-expect-error
      filteredAccountsId[K] = {
        ...originalUnit,

        // Убираем из списка аккаунтов все родительские, чтобы остались одни листья
        ownerIds: without(parentsAccounts, originalUnit.accountIds),
      }
    }

    return filteredAccountsId
  }

  @mobx.computed get valueDict() {
    if(this.value === undefined) {
      throw '.valueDict can not be used before dict is loaded. Use waitValue or useVars for avoid it'
    }

    return mobx.toJS(values(this.value) as any)
  }

  @mobx.computed get valueDictForCurrentFarm() {
    const farmId = getStore('context').selectedFarmId

    //@ts-expect-error
    return toJS(this.valueDict.filter(R => R.accountIds.includes(farmId)))
  }

  @mobx.computed get valueDictForAllAccessibleData() {
    const farmId = getStore('context').selectedFarmId

    //@ts-expect-error
    return toJS(this.valueDict.filter(R => R.accountIds.includes(farmId)))
  }

  constructor() {
    mobx.makeObservable(this)

    this.fetchApi = async () => {
      if(isDemo){
        return await demoRequest('grouping')
      }

      try {
        return await getRegistersWithUnitsGrouping({ registers: C_REGISTERS })
      } catch(err) {
        console.error(err)
      }

      return []
    }
  }

  async waitValue(): Promise<void> {
    if(this.value === undefined) {
      await this.loadData()
    }

    return Promise.resolve()
  }

  //@ts-expect-error
  // Все юниты принадлежащие хозяйству
  filterDataForFarm(farmId = undefined) {
    if(!farmId){ return this.filterDataForCurrentFarm }

    //@ts-expect-error
    return filter((V: TUnit) => V.accountIds.includes(farmId), values(this.value))
      .map(U => mobx.toJS(U))
  }

  //@ts-expect-error
  // Все юниты принадлежащие хозяйству
  @mobx.computed get filterDataForCurrentFarm() {
    const selectedFarmId = getStore('context').selectedFarmId

    if(!selectedFarmId){throw 'selectedFarmId must be calculated first'}

    return this.filterDataForFarm(selectedFarmId)
  }

  //@ts-expect-error
  // Все юниты доступные для хозяйства
  filterAllAccessibleDataForFarm(farmId = undefined) {
    if(!farmId){ return this.filterAllAccessibleDataForCurrentFarm }

    //@ts-expect-error
    return filter((V: TUnit) => V.accountIds.includes(farmId), values(this.value))
      .map(U => mobx.toJS(U))
  }

  //@ts-expect-error
  // Все юниты принадлежащие хозяйству
  @mobx.computed get filterAllAccessibleDataForCurrentFarm() {
    const selectedFarmId = getStore('context').selectedFarmId

    if(!selectedFarmId){throw 'selectedFarmId must be calculated first'}

    return this.filterAllAccessibleDataForFarm(selectedFarmId)
  }

  @mobx.computed get unitsWithRegistersDataMap() {
    let units = []

    try {
      units = this.filterDataForCurrentFarm
    } catch{}

    //@ts-expect-error
    return units.reduce((acc, U) => {
      acc[U.imei] = {
        ...mobx.toJS(U),
        data    : cookReqisters(U.registers, getStore('context').t),
        model   : U.subtype,
        typeName: U.model,
      }

      return acc
    }, {})
  }

  @mobx.computed get unitsWithRegistersData(): any[] {
    return Object.values(this.unitsWithRegistersDataMap)
  }

  @mobx.computed get sortedUnitsWithRegistersData(): TUnitWithType[] {
    return Object.values(this.unitsWithRegistersDataMap as TUnitWithType[]).sort((a, b) =>
      b.registers.ACTIVE ? b.registers.ACTIVE - a.registers.ACTIVE : -1,
    )
  }

  cookUsersUnits(farmId = undefined) {
    const farms = farmId === undefined
      ? getStore('accountsTree').childrenTree
      : getStore('accountsTree').childrenTreeForId(farmId)

    const units = {}
    const allUnits = mobx.toJS(this.filterAllAccessibleDataForFarm(farmId))

    for(let U of allUnits) {
      for(let parent of U.accountIds) {
        //@ts-expect-error
        if(units[parent] === undefined) { units[parent] = [U] }
        //@ts-expect-error
        else { units[parent].push(U) }
      }
    }

    const data = []

    for(let F of farms) {
      const farmUnits = []
      const ownedUnits = []

      //@ts-expect-error
      for(let U of units[F.id] || []){
        const unit = {
          data    : cookReqisters(U.registers, getStore('context').t),
          farm    : F.displayName,
          farmId  : F.id,
          id      : U.id,
          imei    : U.imei,
          model   : U.subtype,
          name    : U.name,
          owned   : U.ownerIds.includes(F.id),
          typeName: U.model,
        }

        farmUnits.push(
          unit,
        )

        if(unit.owned) {
          ownedUnits.push(unit)
        }
      }

      data.push({
        name : F.displayName,
        id   : F.id,
        units: {
          units: farmUnits,
          ownedUnits,
        },
      })
    }

    return data
  }

  async getValue(): Promise<any> {
    if(this.value === undefined) {
      if(this.loadingPromise === undefined) {
        this.loadingPromise = new Promise<any>(
          async resolve => {
            await this.loadData()
            resolve(mobx.toJS(this.value!))
            this.loadingPromise = undefined
          },
        )
      }

      return this.loadingPromise
    }

    return Promise.resolve(mobx.toJS(this.value!))
  }

  @mobx.action.bound
  loadData = mobx.flow(function* (this: UnitsStore) {
    const data = yield this.fetchApi()
    this._value = data.data
    this.unitsIdsCountByStatuses = data.grouping

    if(this.disposeLoadAutorun) { this.disposeLoadAutorun() }

    this.disposeLoadAutorun = mobx.autorun(() => {
      return this.value
    })
  })

  async refresh() {
    await this.loadData()
  }
}
