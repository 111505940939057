import { observer } from 'mobx-react'
import { keys } from 'ramda'
import React from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { C_STATUES } from '../../../constants'
import TooltipD from '../../Tooltip'
import TotalFilter from './TotalFilter'

const styles = {
  tooltips: {
    farmings   : { bottom: 12, left: 180 },
    numbOfUnits: { bottom: 75, right: 5 },
  },
}

export type TProps = {
  t: TTranslate
}

const FiltersList = ({ t }: TProps) => {
  const activityWidgetStore = getStore('activityWidget')
  const statusFiltersList = activityWidgetStore.unitsByStatus

  return <>
    <div>
      <TooltipD text={t('farmings list')} style={styles.tooltips.farmings}/>
    </div>

    <TotalFilterContainer>
      {keys(statusFiltersList).map(id => {
        const statusNumber = Number(id)
        const count = statusFiltersList[id]?.size

        return count && <TotalFilter
          color={C_STATUES[id].color}
          count={count}
          isActive={activityWidgetStore.statusesSelector.selectedStatuses.has(statusNumber)}
          key={C_STATUES[id].color}
          onClick={() => activityWidgetStore.statusesSelector.setSelectedStatus(statusNumber)}
          title={t(C_STATUES[id].title)}
        />
      },
      )}
    </TotalFilterContainer>

    <TooltipD
      style={styles.tooltips.numbOfUnits}
      text={t('The number of units in different modes in the household')}
    />
  </>
}

export default observer(FiltersList)

const TotalFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 99px;
  padding: 5px 10px;
`
