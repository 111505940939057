export const layersSchema = [
  {
    code: 'not',
    name: 'Do not display',
  },
  {
    code  : 'PAC0',
    legend: [
      {
        color: '#ACAAF7',
        value: 1,
      },
      {
        color: '#8D8AF3',
        value: 10,
      },
      {
        color: '#706EC2',
        value: 20,
      },
      {
        color: '#5658FF',
        value: 40,
      },
      {
        color: '#5B5DB1',
        value: 100,
      },
      {
        color: '#3E3F85',
        value: 200,
      },
    ],
    name : 'Convective precipitation',
    units: 'mm',
  },
  {
    code  : 'PR0',
    legend: [
      {
        color: '#FEF9CA',
        value: 0.018,
      },
      {
        color: '#B9F7A8',
        value: 0.03,
      },
      {
        color: '#93F57D',
        value: 0.05,
      },
      {
        color: '#78F554',
        value: 0.08,
      },
      {
        color: '#50B033',
        value: 0.16,
      },
      {
        color: '#387F22',
        value: 0.3,
      },
      {
        color: '#204E11',
        value: 0.8,
      },
      {
        color: '#F2A33A',
        value: 1.7,
      },
      {
        color: '#93F57D',
        value: 2.5,
      },
      {
        color: '#EB4726',
        value: 3.3,
      },
      {
        color: '#B02318',
        value: 5,
      },
      {
        color: '#971D13',
        value: 8.3,
      },
    ],
    name : 'Precipitation intensity',
    units: 'mm/h',
  },
  {
    code  : 'PA0',
    legend: [
      {
        color: '#00000000',
        value: 0,
      },
      {
        color: '#C8969600',
        value: 0.1,
      },
      {
        color: '#9696AA00',
        value: 0.2,
      },
      {
        color: '#7878BE19',
        value: 0.5,
      },
      {
        color: '#6E6ECD33',
        value: 1,
      },
      {
        color: '#5050E1B2',
        value: 10,
      },
      {
        color: '#1414FFE5',
        value: 140,
      },
    ],
    name : 'Accumulated precipitation',
    units: 'mm',
  },
  {
    code  : 'PAR0',
    legend: [
      {
        color: '#E1C86400',
        value: 0,
      },
      {
        color: '#C8963200',
        value: 0.1,
      },
      {
        color: '#9696AA00',
        value: 0.2,
      },
      {
        color: '#7878BE00',
        value: 0.5,
      },
      {
        color: '#6E6ECD4C',
        value: 1,
      },
      {
        color: '#5050E1B2',
        value: 10,
      },
      {
        color: '#1414FFE5',
        value: 140,
      },
    ],
    name : 'Accumulated precipitation - rain',
    units: 'mm',
  },
  {
    code  : 'PAS0',
    legend: [
      {
        color: '#00000000',
        value: 0,
      },
      {
        color: '#00D8FFFF',
        value: 5,
      },
      {
        color: '#00B6FFFF',
        value: 10,
      },
      {
        color: '#9549FF',
        value: 25,
      },
    ],
    name : 'Accumulated precipitation - snow',
    units: 'mm',
  },
  {
    code  : 'SD0',
    legend: [
      {
        color: '#EDEDED',
        value: 0.05,
      },
      {
        color: '#D9F0F4',
        value: 0.1,
      },
      {
        color: '#A5E5EF',
        value: 0.2,
      },
      {
        color: '#7DDEED',
        value: 0.3,
      },
      {
        color: '#35D2EA',
        value: 0.4,
      },
      {
        color: '#00CCE8',
        value: 0.5,
      },
      {
        color: '#706DCE',
        value: 0.6,
      },
      {
        color: '#514FCC',
        value: 0.7,
      },
      {
        color: '#3333CC',
        value: 0.8,
      },
      {
        color: '#1818CC',
        value: 0.9,
      },
      {
        color: '#C454B7',
        value: 1.2,
      },
      {
        color: '#C12CB0',
        value: 1.5,
      },
      {
        color: '#BF00A8',
        value: 1.8,
      },
      {
        color: '#85408C',
        value: 2.5,
      },
      {
        color: '#7F2389',
        value: 3,
      },
      {
        color: '#790087',
        value: 4,
      },
      {
        color: '#E80068',
        value: 15,
      },
    ],
    name : 'Depth of snow',
    units: 'm',
  },
  {
    code  : 'WS10',
    legend: [
      {
        color: '#FFFFFF00',
        value: 1,
      },
      {
        color: '#EECECC66',
        value: 5,
      },
      {
        color: '#B364BCB3',
        value: 15,
      },
      {
        color: '#3F213BCC',
        value: 25,
      },
      {
        color: '#744CACE6',
        value: 50,
      },
      {
        color: '#4600AFFF',
        value: 100,
      },
      {
        color: '#0D1126FF',
        value: 200,
      },
    ],
    name : 'Wind speed at an altitude of 10 meters',
    units: 'm/s',
  },
  {
    code  : 'WND',
    legend: [
      {
        color: '#FFFFFF00',
        value: 1,
      },
      {
        color: '#EECECC66',
        value: 5,
      },
      {
        color: '#B364BCB3',
        value: 15,
      },
      {
        color: '#3F213BCC',
        value: 25,
      },
      {
        color: 'rgba(116,76,172,0.9)',
        value: 50,
      },
      {
        color: '#4600AFFF',
        value: 100,
      },
      {
        color: '#0D1126FF',
        value: 200,
      },
    ],
    name : 'Joint display of speed wind and wind direction',
    units: 'm/s',
  },
  {
    code  : 'APM',
    legend: [
      {
        color: '#0073FF',
        value: 940,
      },
      {
        color: '#00AAFF',
        value: 960,
      },
      {
        color: '#4BD0D6',
        value: 980,
      },
      {
        color: '#8DE7C7',
        value: 1000,
      },
      {
        color: '#B0F720',
        value: 1010,
      },
      {
        color: '#F0B800',
        value: 1020,
      },
      {
        color: '#FB5515',
        value: 1040,
      },
      {
        color: '#F3363B',
        value: 1060,
      },
      {
        color: '#C60000',
        value: 1080,
      },
    ],
    name : 'Atmospheric pressure on mean sea level',
    units: 'hPa',
  },
  {
    code  : 'TA2',
    legend: [
      {
        color: '#821692',
        value: -65,
      },
      {
        color: '#821692',
        value: -55,
      },
      {
        color: '#821692',
        value: -45,
      },
      {
        color: '#821692',
        value: -40,
      },
      {
        color: '#8257DB',
        value: -30,
      },
      {
        color: '#208CEC',
        value: -20,
      },
      {
        color: '#20C4E8',
        value: -10,
      },
      {
        color: '#23DDDD',
        value: 0,
      },
      {
        color: '#C2FF28',
        value: 10,
      },
      {
        color: '#FFF028',
        value: 20,
      },
      {
        color: '#FFC228',
        value: 25,
      },
      {
        color: '#FC8014',
        value: 30,
      },
    ],
    name : 'Air temperature at a height of 2 meters',
    units: 'C°',
  },
  {
    code  : 'TD2',
    legend: [
      {
        color: '#821692',
        value: -65,
      },
      {
        color: '#821692',
        value: -55,
      },
      {
        color: '#821692',
        value: -45,
      },
      {
        color: '#821692',
        value: -40,
      },
      {
        color: '#8257DB',
        value: -30,
      },
      {
        color: '#208CEC',
        value: -20,
      },
      {
        color: '#20C4E8',
        value: -10,
      },
      {
        color: '#23DDDD',
        value: 0,
      },
      {
        color: '#C2FF28',
        value: 10,
      },
      {
        color: '#FFF028',
        value: 20,
      },
      {
        color: '#FFC228',
        value: 25,
      },
      {
        color: '#FC8014',
        value: 30,
      },
    ],
    name : 'Temperature of a dew point',
    units: 'C°',
  },
  {
    code  : 'TS0',
    legend: [
      {
        color: '#491763',
        value: -70,
      },
      {
        color: '#4E1378',
        value: -45,
      },
  
      {
        color: '#514F9B',
        value: -38,
      },{
        color: '#446DA9',
        value: -34,
      },{
        color: '#5C85B7',
        value: -30,
      },{
        color: '#739FC5',
        value: -26,
      },{
        color: '#88A7C9',
        value: -22,
      },{
        color: '#6CBCD4',
        value: -18,
      },{
        color: '#87CADC',
        value: -14,
      },{
        color: '#A7D8E5',
        value: -10,
      },{
        color: '#A7D5AD',
        value: -6,
      },{
        color: '#D2E9C8',
        value: -2,
      },{
        color: '#FEFEBB',
        value: 2,
      },{
        color: '#F5CEBB',
        value: 6,
      },{
        color: '#F2B68A',
        value: 10,
      },{
        color: '#EE934F',
        value: 14,
      },{
        color: '#EB702D',
        value: 18,
      },{
        color: '#E8706E',
        value: 22,
      },{
        color: '#CC2C44',
        value: 30,
      },{
        color: '#CC0000',
        value: 40,
      },{
        color: '#990000',
        value: 50,
      },
    ],
    name : 'Soil temperature 0-10 сm',
    units: 'C°',
  },
  {
    code  : 'TS10',
    legend: [
      {
        color: '#491763',
        value: -70,
      },
      {
        color: '#4E1378',
        value: -45,
      },
  
      {
        color: '#514F9B',
        value: -38,
      },{
        color: '#446DA9',
        value: -34,
      },{
        color: '#5C85B7',
        value: -30,
      },{
        color: '#739FC5',
        value: -26,
      },{
        color: '#88A7C9',
        value: -22,
      },{
        color: '#6CBCD4',
        value: -18,
      },{
        color: '#87CADC',
        value: -14,
      },{
        color: '#A7D8E5',
        value: -10,
      },{
        color: '#A7D5AD',
        value: -6,
      },{
        color: '#D2E9C8',
        value: -2,
      },{
        color: '#FEFEBB',
        value: 2,
      },{
        color: '#F5CEBB',
        value: 6,
      },{
        color: '#F2B68A',
        value: 10,
      },{
        color: '#EE934F',
        value: 14,
      },{
        color: '#EB702D',
        value: 18,
      },{
        color: '#E8706E',
        value: 22,
      },{
        color: '#CC2C44',
        value: 30,
      },{
        color: '#CC0000',
        value: 40,
      },{
        color: '#990000',
        value: 50,
      },
    ],
    name : 'Soil temperature >10 сm',
    units: 'C°',
  },
  {
    code  : 'HRD0',
    legend: [
      {
        color: '#db1200',
        value: 0,
      },
      {
        color: '#965700',
        value: 20,
      },
      {
        color: '#ede100',
        value: 40,
      },
      {
        color: '#8bd600',
        value: 60,
      },
      {
        color: '#00a808',
        value: 80,
      },
      {
        color: '#000099',
        value: 100,
      },
    ],
    name : 'Relative humidity',
    units: '%',
  },
  {
    code  : 'CL',
    legend: [
      {
        color: '#FFFFFF00',
        value: 0,
      },
      {
        color: '#FDFDFF19',
        value: 10,
      },
      {
        color: '#FCFBFF26',
        value: 20,
      },
      {
        color: '#FAFAFF33',
        value: 30,
      },
      {
        color: '#F9F8FF4C',
        value: 40,
      },
      {
        color: '#F7F7FF66',
        value: 50,
      },
      {
        color: '#F6F5FF8C',
        value: 60,
      },
      {
        color: '#F4F4FFBF',
        value: 70,
      },
      {
        color: '#E9E9DFCC',
        value: 80,
      },
      {
        color: '#DEDEDED8',
        value: 90,
      },
      {
        color: '#D2D2D2FF',
        value: 100,
      },
    ],
    name : 'Cloudiness',
    units: '%',
  },
]
