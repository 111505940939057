import { LatLngTuple } from 'leaflet'

export const MAP_CENTER: LatLngTuple = [47.2, 39.7]

export const MAP_MAX_BOUNDS: [number, number][] = [[-90, -180], [90, 180]]

export const MAP_TYPES = {
  GOOGLE  : 'google',
  GOOGLE_1: 'google1',
  GOOGLE_2: 'google2',
  OSM     : 'osm',
  SPUTNIC : 'sputnic',
} as const
