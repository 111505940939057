import { Button, constants, icons, Spinner } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { useCallback, useContext } from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { ConfigContext } from '../../../../../ValidateUser'
import { CategoryWrapper, Title } from '../../common/Styled'
import { Row } from './Row'
import { createExcelFile } from './ToExcell'

const formatter = (value?: number) => value ? value.toFixed(2) : '-'

export const params = [
  {
    id   : 'agriculture',
    title: 'agriculture',
  },
  {
    formatter,
    helpText: 'The area of the geofence without taking into account the relief of the earth',
    id      : 'totalArea',
    measure : 'ha',
    title   : 'area',
  },
  {
    formatter,
    helpText: 'The area of the geofence for which there is data on yield',
    id      : 'mapped',
    measure : '%',
    title   : 'Mapped',
  },
  {
    helpText: 'Average yield of the mapped part of the geofence',
    id      : 'avgYield',
    measure : 'cwt/ha',
    title   : 'Avg yield',
  },
  {
    helpText: 'Indicative (estimated) data obtained on the yield of the field',
    id      : 'totalYield',
    measure : 'T',
    title   : 'Total yield',
  },
] as const

export type TParam = {
  formatter?: Function
  helpText?: string
  id: typeof params[number]['id']
  measure?: string
  title: string
}

export const Productivity = observer(({ t }: { t: TTranslate }) => {
  const geoZonesStore = getStore('geoZones')
  const mapBuildSettings = getStore('geoZones').mapBuildSettings
  const mapDisplaySettings = getStore('geoZones').mapDisplaySettings

  const user = useContext(ConfigContext)?.user

  const getExcelReport = useCallback(() => {
    createExcelFile({
      farmUnits  : mapDisplaySettings.selectedUnits.map(U => U.name),
      from       : mapBuildSettings.period.from,
      geoZoneName: geoZonesStore.selectedGeoZone.name,
      login      : user?.name || '',
      report     : geoZonesStore.productivity,
      t,
      to         : mapBuildSettings.period.to,
    })
  }, [])

  return <CategoryWrapper>
    <ProductivityWrapper>
      <Title>{t('Productivity')}</Title>

      {geoZonesStore.isLoadingProductivity
        ? <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>

        : <ParamsListWrapper>
          {params.map((param: TParam) => <Row
            helpText={param.helpText && t(param.helpText)}
            key={param.id}
            measure={param?.measure && t(param.measure)}
            title={t(param.title)}

            value={
              param.formatter ?
                param.formatter(geoZonesStore.productivity[param.id])
                : geoZonesStore.productivity[param.id]
            }
          />)}
        </ParamsListWrapper>
      }

      <ButtonStyled
        onClick={getExcelReport}
        variant="border"
        width="286px"
      >
        <icons.XlsIcon color={constants.colors.icons.PRIMARY} size={24}/>
        <RightIconText>{t('Download the field yield report')}</RightIconText>
      </ButtonStyled>
    </ProductivityWrapper>
  </CategoryWrapper>
})

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 190px;
`

const ParamsListWrapper = styled.div`
  margin-top: 10px;
`

const RightIconText = styled.span`
  padding-left: 8px;
  font-size: 14px;
`

const ButtonStyled = styled(Button)`
  margin: 16px 0;
  padding: 5px;
  min-height: 32px;
`

const ProductivityWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 13px 0 17px 0;
`
