import 'leaflet.fullscreen/Control.FullScreen'
import 'ui/Map/leaflet-fullscreen.css'
import L from 'leaflet'
import { path } from 'ramda'
import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react'
import { Map, Marker, Popup } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { TMapTypes, TStatus, TUnit } from 'types'
import { Control } from 'utils/LeafletCustomControl'
import TooltipD from '../Tooltip'
import { MapLayer } from './MapLayer'
import { MarkerStateWrapper } from './MarkerStateWrapper'
import { markerOil0, markerOil1 } from './markersType'
import { StyleMap } from './styleMap'
import { Panel, TrackItem } from './Track'
import { PathType, TrackType } from './Track/TrackItem'
import { TransportPopupUnit } from './Track/TrackItem/PopupWrap/TransportPopup'

export type MarkerType = {
  lat?: number
  lon?: number
  eventType: 1 | 0
  eventName: string
  start: string
  end: string
  value: number
  startTime?: number
}

type MapDetailMachineProps = {
  detailPosition?: {
    lat: number
    lon: number
  }

  activeTargets2?: number
  legendStyle: CSSProperties
  locale?: string
  marker?: MarkerType
  static: boolean
  status?: TStatus | null
  track?: TrackType[]
  trackUpdated?: boolean
  transport?: TransportPopupUnit
  unit: TUnit
}

// @ts-expect-error не совпадает тип данных возвращаемый функцией
const getDataValue = (name: string, target: unknown): number => path(['data', name, 'value'], target)

export const MapDetailMachine: FC<MapDetailMachineProps> = ({
  activeTargets2,
  detailPosition,
  legendStyle,
  locale,
  marker,
  static: staticProp,
  status,
  track = [],
  trackUpdated,
  transport,
  unit,
}) => {
  const translate = useTranslate()
  const mapRef = useRef<Map>(null)
  const position: [number, number] = [47.2, 39.7]
  const zoom = 3
  const [viewport, setViewport] = useState<{center: [number, number], zoom: number}>({ center: position, zoom })
  const [type, setType] = useState<TMapTypes>('google1')
  const controlTypeMapRef = useRef<HTMLDivElement>(null)
  const [openControlTypeMap, setOpenControlTypeMap] = useState(false)

  const prevProps = useRef({
    trackLength: track.length,
  })

  const onCenter = () => {
    const LATITUDE = unit?.data.LATITUDE.value
    const LONGITUDE = unit?.data.LONGITUDE.value
    const coordMachine = LATITUDE && LONGITUDE ? [LATITUDE, LONGITUDE] : []

    if(track?.length) {
      const bounds = track.reduce((tracks: number[][], item: TrackType) => {
        const coords = item.path.map(point => [point.lat, point.lng])
        return [...tracks, ...coords]
      }, [])

      if(bounds.length) {
        if(coordMachine.length) {
          bounds.push(coordMachine)
        }

        if(mapRef.current) {
          mapRef.current.leafletElement.fitBounds(bounds as [number, number][])
        }
      }
    } else if(coordMachine.length) {
      setViewport({
        center: coordMachine as [number, number],
        zoom  : 15,
      })
    } else {
      setViewport({
        center: position,
        zoom  : 15,
      })
    }
  }

  const togglePopupType = () => {
    setOpenControlTypeMap(prevState => !prevState)
  }

  const switchType = (newType: TMapTypes) => {
    setType(newType)
    setOpenControlTypeMap(false)

    if(!mapRef.current) {
      return
    }

    const center = mapRef.current.leafletElement.getCenter()
    const mapZoom = mapRef.current.leafletElement.getZoom()

    mapRef.current.leafletElement.options.crs = L.CRS.EPSG3857

    //@ts-expect-error
    mapRef.current.leafletElement._resetView(center, mapZoom, true)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(!controlTypeMapRef.current || !controlTypeMapRef.current.contains(event.target as Node)) {
      setOpenControlTypeMap(false)
    }
  }, [setOpenControlTypeMap])

  useEffect(() => {
    if(mapRef.current) {
    // Add zoom buttons on map
      L.control
        .zoom({
          zoomInTitle : translate('zoom in'),
          zoomOutTitle: translate('zoom out'),
        })
        .addTo(mapRef.current.leafletElement)


      // Add fullscreen button on map
      L.control
        .fullscreen({
          title      : translate('fullscreen'),
          titleCancel: translate('exit fullscreen'),
        })
        .addTo(mapRef.current.leafletElement)

      mapRef.current.leafletElement.on('enterFullscreen', () => {
        if(mapRef.current) {
          mapRef.current.leafletElement.invalidateSize()
        }
      })

      mapRef.current.leafletElement.on('exitFullscreen', () => {
        if(mapRef.current) {
          mapRef.current.leafletElement.invalidateSize()
        }
      })
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if(!prevProps.current.trackLength && track.length) {
      const full = track.reduce((result: PathType[], item) => {
        item.path.forEach(itm => {
          result.push(itm)
        })

        return result
      }, [])

      if(mapRef.current) {
        mapRef.current.leafletElement.flyToBounds(L.latLngBounds(full), {
          maxZoom: 18,
        })
      }
    }
  }, [])

  return (
    <Map
      ref={mapRef}
      fadeAnimation={false}
      zoomAnimation={false}
      center={position}
      zoom={zoom}
      viewport={viewport}
      minZoom={2}
      maxZoom={20}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      zoomControl={false}
    >
      <TooltipD
        text={translate('Change zoom of map Can use scroll of mouse or multitouch')}
        style={{ top: '56px', left: '75px' }}
      />

      <TooltipD
        text={translate('Switch on/ switch off fullscreen mode')}
        style={{ top: '155px', left: '75px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
      />


      <MapLayer lang={locale} mapType={type} />

      <TooltipD
        text={translate('Show center of a map')}
        style={{ top: '220px', left: '75px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
      />
      <Control position="topleft">
        <div className="map__center">
          <button onClick={onCenter} />
        </div>
      </Control>
      <TooltipD
        text={translate('The selection type a map')}
        style={{ top: '95px', right: '8px', zIndex: 1000 }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
      />
      <Control position="topright">
        <div className="map__topright">
          <div className="map__topright-controll">
            <div className="map-type" ref={controlTypeMapRef}>
              <button
                className="map-type__button"
                onClick={togglePopupType}
              />

              {openControlTypeMap &&
                <StyleMap
                  type={type}
                  switchType={switchType}
                  translate={translate}
                />
              }
            </div>
          </div>
        </div>
      </Control>

      {track?.length && mapRef.current &&
        <TrackItem
          activeMarker={
            unit?.data?.LATITUDE?.value && unit?.data?.LONGITUDE?.value
              ? [unit.data.LATITUDE.value, unit.data.LONGITUDE.value]
              : []
          }

          activeTargets2={activeTargets2}
          activeTime={unit?.data?.ACTIVE?.time || 0}
          different={unit?.track?.different}
          map={mapRef.current}

          //@ts-expect-error
          model={unit?.model}
          name={unit?.name}
          static={staticProp}
          status={status}
          track={track}
          trackUpdated={trackUpdated}
          transport={transport}
          type={unit?.track?.type}
        />
      }

      <MarkerStateWrapper
        detailPosition={detailPosition}
        status={status}
        getDataValue={getDataValue}
        hasTrack={track && Boolean(track.length)}
        unit={unit}
      />

      {marker?.lat && marker?.lon &&
        <Marker
          position={[marker.lat, marker.lon]}
          icon={marker.eventType ? markerOil1 : markerOil0}
        >
          <Popup>
            <div className="marker-popup">
              <p className="marker-popup__type">
                {translate('event')}: {marker.eventName}
              </p>
              <p className="marker-popup__name">
                {translate('Start')}: {marker.start}
              </p>
              <p className="marker-popup__name">
                {translate('Finish')}: {marker.end}
              </p>
              <p className="marker-popup__name">
                {translate('Volume')}: {marker.value} л
              </p>
            </div>
          </Popup>
        </Marker>
      }

      <Panel
        type={'TRACK_HARVEST'}
        translate={translate}
        style={legendStyle}
      />
    </Map>
  )
}
