import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { isEmpty } from 'ramda'
import { prepareDuration } from 'ui/Map/duration'
import { LANG_RU } from '../../../../constants/lang'

const columns = ['type', 'Serial number', 'holding', 'farming', 'status', 'notification',
  'category', 'Appearance', 'Elimination', 'Duration, hh:mm:ss']

/*@ts-expect-error*/
export const createExcelFile = async ({ report, t, userLogin, period }) => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  let cursor = 4

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/notices.xlsx`)

    /*@ts-expect-error*/
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  // Generate report body
  /*@ts-expect-error*/
  report.forEach(data => {
    if(!isEmpty(data.notifications)) {
      /*@ts-expect-error*/
      data.notifications.forEach(N => {
        sheet.insertRow(cursor, [
          `${cursor - 3}`,
          data.typeName,
          data.serialNumber,
          data.holding,
          data.farm,
          N.statusName,
          N.name,
          N.categoryName,
          N.timeSet ? Date.parseDateTimeFromApi(N.timeSet).user.format('DD.MM.YY HH:mm:ss') : '',
          N.timeReset ? Date.parseDateTimeFromApi(N.timeReset).user.format('DD.MM.YY HH:mm:ss') : '',
          N.duration ? prepareDuration(N.duration) : '',
        ])

        ++cursor
      })
    }
  })

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('E1').value = `${t('formed')}: ${ userLogin }, ${formats.date.dateViewFormatter(createDateTime, LANG_RU)}, ${formats.date.timeFormatter(createDateTime, LANG_RU)}`
  // eslint-disable-next-line max-len
  sheet.getCell('A2').value = `${t('Report')} ${t('Notifications')} ${t('during the period')} ${period[0].format('DD.MM.YY HH:mm:ss')} - ${period[1].format('DD.MM.YY HH:mm:ss')}`

  const row = sheet.getRow(3)
  columns.forEach((name, index) => row.getCell(index + 2).value = t(name))

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'notifications_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
