import { Spin } from 'antd'
import moment from 'moment'
import React, { FC, useState } from 'react'
import {
  AutoSizer,
  Column,
  SortDirection,
  SortDirectionType,
  SortIndicator,
  Table,
  TableCellProps,
  TableHeaderProps,
} from 'react-virtualized'
import { CSSProperties } from 'styled-components'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { Popup } from 'ui/Notification/Popup'
import TooltipD from 'ui/Tooltip'
import { headerTableDetail, headerTableSummary } from '../constants'

const styles = {
  spinner: {
    position: 'fixed',
    left    : '50%',
    top     : '50%',
  },
  noRows: {
    position      : 'absolute',
    top           : 0,
    bottom        : 0,
    left          : 0,
    right         : 0,
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    fontSize      : '1em',
    color         : '#bdbdbd',
  },
  tooltip: { bottom: '0px', right: '0px' },
}

type FilterResultProps = {
  translate: TTranslate
  report: Record<string, string>[]
  showDetailInfo: boolean
  showSpin: boolean
}

export const FilterResult: FC<FilterResultProps> = ({ translate, report, showDetailInfo, showSpin }) => {
  const sortList = ({ sortBy, sortDirection }: {sortBy: string, sortDirection: SortDirectionType}) => {
    let sortedList = report

    if(sortBy === 'event') {
      sortedList.sort((a, b) => {
        if(translate(a[sortBy]) > translate(b[sortBy])) {
          return 1
        }

        if(translate(a[sortBy]) < translate(b[sortBy])) {
          return -1
        }

        return 0
      })
    } else {
      sortedList.sort((a, b) => {
        if(a[sortBy] > b[sortBy]) {
          return 1
        }

        if(a[sortBy] < b[sortBy]) {
          return -1
        }

        return 0
      })
    }

    if(sortDirection === SortDirection.DESC) {
      sortedList.reverse()
    }

    return sortedList
  }

  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [dataMap, setDataMap] = useState<unknown[]>([])
  const [sortBy, setSortBy] = useState('index')
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.ASC)
  const [, setSortedList] = useState<Record<string, string>[]>(sortList({ sortBy, sortDirection }))
  const typeReport = showDetailInfo ? headerTableDetail : headerTableSummary

  const noRowsRenderer = () => {
    return <div>{translate('not_of_data')}</div>
  }

  const rowGetter = ({ index }: {index: number}) => {
    return report[index]
  }

  const rowClassName = ({ index }: {index: number}) => {
    if(index < 0) {
      return 'headerRow'
    } else {
      return index % 2 === 0 ? 'evenRow' : 'oddRow'
    }
  }

  const sort = ({ sortBy, sortDirection }: {sortBy: string, sortDirection: SortDirectionType}) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    setSortedList(sortList({ sortBy, sortDirection }))
  }

  const cellIndexRenderer = ({ rowIndex }: {rowIndex: number}) => {
    return <p>{rowIndex + 1}</p>
  }

  const headerIndexRenderer = () => {
    return null
  }

  const headerRenderer = ({ dataKey, sortBy, sortDirection }: TableHeaderProps) => {
    return (
      <div>
        <p>
          {translate(typeReport?.[dataKey]?.name)}
          {sortBy === dataKey && typeReport[dataKey].sort && dataKey !== 'index' &&
            <SortIndicator sortDirection={sortDirection} />
          }
        </p>
        <p><TooltipD text={translate(typeReport[dataKey].tooltip)} style={styles.tooltip}/></p>
      </div>
    )
  }

  const showPopup = (data: unknown[]) => {
    setIsOpenPopup(true)
    setDataMap(data)
  }

  const closePopup = () => {
    setIsOpenPopup(false)
  }

  const cellRenderer = ({ cellData, dataKey, rowData }: TableCellProps) => {
    const handleOnClick = () => {
      showPopup(rowData.coords)
    }

    switch(dataKey) {
      case 'event':
        return <p>{translate(cellData)}</p>
      case 'begDay':
        return <p>{moment(cellData).format('DD.MM.YYYY')}</p>
      case 'beg':
      case 'end':
        return <p>{moment(cellData).format('HH:mm:ss')}</p>
      case 'duration':
        return <p>{prepareDuration(cellData)}</p>
      case 'coords':
        return <p>{cellData.lat && cellData.lon && <span className="image-map" onClick={handleOnClick}/> }</p>
      default:
        return <p>{cellData}</p>
    }
  }

  return (
    <div className="watch__full">
      <AutoSizer disableHeight>
        {({ width }) =>
          <Table
            ref="Table"
            headerHeight={30}
            height={document.documentElement.clientHeight - 360}
            rowHeight={40}
            noRowsRenderer={noRowsRenderer}
            overscanRowCount={10}
            rowGetter={rowGetter}
            rowCount={report.length}
            width={width}
            rowClassName={rowClassName}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          >
            <Column
              cellDataGetter={({ rowData }) => rowData.index}
              dataKey="index"
              cellRenderer={cellIndexRenderer}
              headerRenderer={headerIndexRenderer}
              width={200}
              disableSort={false}
            />
            {Object.keys(typeReport).map(column =>
              <Column
                key={column}
                dataKey={column}
                headerRenderer={headerRenderer}
                cellRenderer={cellRenderer}
                width={typeReport[column].width}
                disableSort={column === 'coords'}
              />,
            )}
          </Table>
        }
      </AutoSizer>
      {showSpin && <Spin style={styles.spinner as CSSProperties}/>}
      {isOpenPopup && <Popup closePopup={closePopup} {...dataMap}/>}
    </div>
  )
}
