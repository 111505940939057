import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { filter, identity, is, sum, times, values } from 'ramda'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { LANG_RU } from '../../../../constants/lang'
import { C_PARAMS } from '../../result/TimeDonut'

type CreateExcelArgs = {
  t: TTranslate
  from: string
  to: string
  login: string
  numRow: number
  modelSections: Record<string, {
    types: {
      size: number
      toJS: () => Record<string, unknown[]>
    }
    name: string
  }>
  report: {
    INC_SUMM_PATH: {
      data: {
        get: (value: unknown) => number
      }
    }
    INC_SUMM_MOTOR: {
      data: {
        get: (value: unknown) => number
      }
    }
  } & Record<string, {
    data: {
      get: (value: unknown) => number
    }
  }>
}

type Report = {
  data: {
    get: (value: unknown) => number
  }
}

type MergeOption = [number, number, number, number]

// @todo в идеальном мире этот тип не должен содержать вариации
type PreparedReport = {
  techTypes: string[]
  techTypesMerges: MergeOption[]
  endColumnNumber: number
  models: string[]
  sumPath: Array<number | '-'>
  areaPerTime: Array<number | '-'>
  sumMotor: string[]
  timeUsage: Record<string, string[]>
  sectionsSize: number[]
  sumPathCombineZukKuk: Array<number | '-'| ''>
  sumMotorCombineZukKuk: Array<number | '-'| ''>
  areaPerTimeZukKuk: Array<number | '-'| ''>
  timeUsageSumZukKuk: Record<string, Array<number | string>>
  timeUsageSum: Record<string, number>
  merges: unknown[]
}

export const createExcelFile = async ({
  from,
  login,
  modelSections,
  numRow,
  report,
  t,
  to,
}: CreateExcelArgs) => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  let cursor = 4

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/groupMachinery.xlsx`)

    /*@ts-expect-error*/
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const getCommonSumZukKuk = (types: Record<string, unknown[]>, currentReport: Report) =>
    Math.round(Object.keys(types).reduce((accum, model) =>
      accum + types[model].reduce((acc: number, id) => acc + currentReport.data.get(id), 0), 0) * 100,
    ) / 100 || '-'

  const preparedReport = Object.values(modelSections).reduce<PreparedReport>((acc, element) => {
    if(!element.types.size) { return acc }

    acc.techTypes.push(t(element.name))

    acc.techTypesMerges.push([3, acc.endColumnNumber + 2, 3, acc.endColumnNumber + element.types.size + 1])

    acc.endColumnNumber = acc.endColumnNumber + element.types.size

    const types = element.types.toJS()

    acc.models.push(...Object.keys(types).map(key => `${key} (${types[key].length})`))

    Object.values(types).forEach(model => {
      acc.sumPath.push(
        Math.round(model.reduce<number>((acc, id) => acc + report.INC_SUMM_PATH.data.get(id), 0) * 100) / 100 || '-',
      )

      const sumMotorValue = Math.round(
        model.reduce<number>((acc, id) => acc + report.INC_SUMM_MOTOR.data.get(id), 0) * 100,
      ) / 100

      acc.sumMotor.push(sumMotorValue ? prepareDuration(sumMotorValue * 3600000) : '-')

      const timeUsageSumParams = C_PARAMS.reduce<Record<string, number>>((accum, reportName) => {
        accum[reportName] = Math.round(model.reduce<number>((acc, id) => acc + report?.[reportName].data.get(id), 0))
        return accum
      }, {})

      const timeUsageSumAllParams = sum(values(timeUsageSumParams))

      C_PARAMS.forEach(reportName => {
        const reportItem = `${prepareDuration(timeUsageSumParams[reportName])}, ${
          timeUsageSumParams[reportName]
            ? `≈${(timeUsageSumParams[reportName] / timeUsageSumAllParams * 100).toFixed(2)}%`
            : 0
        }`

        if(acc.timeUsage[reportName]) {
          acc.timeUsage[reportName].push(reportItem)
        } else {
          acc.timeUsage[reportName] = [reportItem]
        }
      })
    })

    acc.areaPerTime.push(...Object.values(types).map(model => Math.round(
      model.reduce<number>((acc, id) => acc + report.AREA_PER_TIME.data.get(id), 0) / model.length * 100,
    ) / 100 || '-'))

    if(numRow === 3 || numRow === 2) {
      const sectionSize = element.types.size - 1

      acc.sectionsSize.push(sectionSize + 1)

      //Средний пройденный путь для группы
      acc.sumPathCombineZukKuk = acc.sumPathCombineZukKuk.concat([
        getCommonSumZukKuk(types, report.INC_SUMM_PATH),
      ], times(() => '', sectionSize))

      //Средняя наработка двигателя для группы
      acc.sumMotorCombineZukKuk = acc.sumMotorCombineZukKuk.concat([
        getCommonSumZukKuk(types, report.INC_SUMM_MOTOR),
      ], times(() => '', sectionSize))

      //Средняя производительность по времени для группы
      acc.areaPerTimeZukKuk = acc.areaPerTimeZukKuk.concat([
        Math.round(Object.values(types).reduce((accum, model) =>
          accum + model.reduce<number>((acc, id) =>
            acc + report.AREA_PER_TIME.data.get(id), 0), 0)

        /*@ts-expect-error*/
          / element.types.reduce<number>((count: number, type) => count + type.size, 0) * 100) / 100 || '-',
      ], times(() => '',sectionSize))


      //Среднее использование времени для группы
      const timeUsageSumParamsZukKuk = C_PARAMS.reduce<Record<string, number>>((accum, reportName) => {
        accum[reportName] = Math.round(Object.values(types).reduce((accum, model) =>
          accum + model.reduce<number>((acc, id) => acc + report[reportName].data.get(id), 0), 0))

        return accum
      }, {})

      const timeUsageSumAllParamsZukKuk = sum(values(timeUsageSumParamsZukKuk))

      C_PARAMS.forEach(reportName => {
        const reportItem =
          `${prepareDuration(timeUsageSumParamsZukKuk[reportName])}, ≈${(timeUsageSumParamsZukKuk[reportName]
            / timeUsageSumAllParamsZukKuk * 100).toFixed(2)}%`

        if(acc.timeUsageSumZukKuk[reportName]) {
          acc.timeUsageSumZukKuk[reportName] = acc.timeUsageSumZukKuk[reportName].concat(
            [reportItem], times(() => '', sectionSize),
          )

          acc.timeUsageSum[reportName] = acc.timeUsageSum[reportName] + timeUsageSumParamsZukKuk[reportName]
        } else {
          acc.timeUsageSumZukKuk[reportName] = [reportItem, ...times(() => '', sectionSize)]
          acc.timeUsageSum[reportName] = timeUsageSumParamsZukKuk[reportName]
        }
      })
    }

    return acc
  }, {
    areaPerTime          : [],
    areaPerTimeZukKuk    : [],
    endColumnNumber      : 1,
    merges               : [],
    models               : [],
    sectionsSize         : [],
    sumMotor             : [],
    sumMotorCombineZukKuk: [],
    sumPath              : [],
    sumPathCombineZukKuk : [],
    techTypes            : [],
    techTypesMerges      : [],
    timeUsage            : {},
    timeUsageSum         : {},
    timeUsageSumZukKuk   : {},
  })

  const sumAreaPerTime = Math.round(Object.keys(modelSections).reduce((accumulator, item) => {
    const types = modelSections[item].types.toJS()

    return accumulator + (modelSections[item].types.size && (item === 'ZUK' || item === 'KUK')
      ? Object.values(types).reduce((accum, model) =>
        accum + model.reduce<number>((acc, id) => acc + report.AREA_PER_TIME.data.get(id), 0) / model.length, 0)
          / modelSections[item].types.size
      : 0
    )}, 0) / Object.keys(modelSections).reduce((accumulator, item) =>

    /*@ts-expect-error*/
    accumulator + modelSections[item].types.reduce<number>((count: number, type) => count + type.size, 0), 0)
    * 100) / 100 || '-'

  const timeUsageSumAllParams = sum(values(preparedReport.timeUsageSum))

  let startDataRowNum = cursor
  const addedRowNum = numRow - 1

  //мердж ячеек внутри строки
  const getCellMergesForRow = (startRow: number) => {
    let merges: MergeOption[] = []

    if(numRow === 3) {
      const commonReportData = preparedReport.sectionsSize.reduce((acc, sectionSize) => {
        if(sectionSize > 1) {
          //пройденный путь, наработка двигателя, средняя производительность, режимы 0-6
          const value = times(identity, 10).map((num: number) => [
            startRow + addedRowNum - 1 + numRow * num, acc.startColumn + 1,
            startRow + addedRowNum - 1 + numRow * num, acc.startColumn + sectionSize,
          ])


          /*@ts-expect-error*/
          acc.merges.push(...value)

          acc.startColumn = acc.startColumn + sectionSize
        } else {
          acc.startColumn = acc.startColumn + 1
        }

        return acc
      }, { startColumn: 2, merges: [] })

      merges = merges.concat(commonReportData.merges)
    }

    if(numRow === 3 || numRow === 2) {
      //Общий пройденный путь, общая наработка двигателя, общая средняя производительность, общие значения режимов 0-6
      merges = merges.concat(times(identity, 10).map(num => [
        startRow + numRow * num + addedRowNum, 3,
        startRow + numRow * num + addedRowNum, preparedReport.endColumnNumber + 1,
      ]))
    }

    return merges
  }

  const getMergeCellsOneToTwo = (startRow: number): MergeOption => [startRow, 1, startRow, 2]

  const reportData = [
    {
      data         : [[t('type_of_machine'), '', ...preparedReport.models]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data: [
        [`${t('Distance traveled')}, ${t('km')}`, '', ...preparedReport.sumPath],
        ...numRow === 3 ? [['', '', ...preparedReport.sumPathCombineZukKuk]] : [],
        ...numRow === 3 || numRow === 2 ? [['', '', sum(filter(is(Number), preparedReport.sumPathCombineZukKuk))]] : [],
      ],

      getCellMerges: (startRow: number): MergeOption => [startRow, 1, startRow + addedRowNum, 2],
    },
    {
      data: [
        [`${t('Engine running time')}, ${t('hh:mm:ss')}`, '', ...preparedReport.sumMotor],
        ...numRow === 3
          ? [[
            '',
            '',
            ...preparedReport.sumMotorCombineZukKuk.map(V => V !== '-' && V ? prepareDuration(V * 3600000) : V),
          ]]
          : [],
        ...numRow === 3 || numRow === 2
          ? [['', '', prepareDuration(sum(filter(is(Number), preparedReport.sumMotorCombineZukKuk)) * 3600000)]]
          : [],
      ],

      getCellMerges: (startRow: number): MergeOption => [startRow, 1, startRow + addedRowNum, 2],
    },
    {
      data: [
        [`${t('Average time performance')}, ${t('ha/h')}`, '', ...preparedReport.areaPerTime],
        ...numRow === 3 ? [['', '', ...preparedReport.areaPerTimeZukKuk]] : [],
        ...numRow === 3 || numRow === 2 ? [['', '', sumAreaPerTime]] : [],
      ],

      getCellMerges: (startRow: number): MergeOption => [startRow, 1, startRow + addedRowNum, 2],
    },

    {
      data: [
        [t('use_of_time'), `${t('mode')}0`, ...preparedReport.timeUsage.TIME_NO_LINK],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_NO_LINK]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_NO_LINK)}, ≈` +
            `${(preparedReport.timeUsageSum.TIME_NO_LINK / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}1`, ...preparedReport.timeUsage.TIME_MOVE_NO_HARVEST],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_MOVE_NO_HARVEST]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_MOVE_NO_HARVEST)}, ≈` +
            `${(preparedReport.timeUsageSum.TIME_MOVE_NO_HARVEST / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}2`, ...preparedReport.timeUsage.TIME_OFF],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_OFF]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_OFF)}, ≈` +
            `${(preparedReport.timeUsageSum.TIME_OFF / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}3`, ...preparedReport.timeUsage.TIME_STOP_DON],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_STOP_DON]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_STOP_DON)}, ≈`+
            `${(preparedReport.timeUsageSum.TIME_STOP_DON / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}4`, ...preparedReport.timeUsage.TIME_HARVEST],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_HARVEST]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_HARVEST)}, ≈` +
            `${(preparedReport.timeUsageSum.TIME_HARVEST / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}5`, ...preparedReport.timeUsage.TIME_STOP_HARVEST],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_STOP_HARVEST]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_STOP_HARVEST)}, ≈`+
          `${(preparedReport.timeUsageSum.TIME_STOP_HARVEST / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],
    },
    {
      data: [
        [t('use_of_time'), `${t('mode')}6`, ...preparedReport.timeUsage.TIME_STOP_HARVEST_FULL],
        ...numRow === 3 ? [['', '', ...preparedReport.timeUsageSumZukKuk.TIME_STOP_HARVEST_FULL]] : [],
        ...numRow === 3 || numRow === 2
          ? [[
            '',
            '',
            `${prepareDuration(preparedReport.timeUsageSum.TIME_STOP_HARVEST_FULL)}, ≈`+
          `${(preparedReport.timeUsageSum.TIME_STOP_HARVEST_FULL / timeUsageSumAllParams * 100).toFixed(2)}%`,
          ]]
          : [],
      ],

      getCellMerges: (startRow: number): MergeOption => [startRow - numRow * 6 , 1, startRow + numRow - 1 , 1],
    },
    {
      data         : [[`${t('mode')}0 - ${t('M0')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}1 - ${t('M1')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}2 - ${t('M2')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}3 - ${t('M3')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}4 - ${t('M4')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}5 - ${t('M5')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
    {
      data         : [[`${t('mode')}6 - ${t('M6')}`]],
      getCellMerges: getMergeCellsOneToTwo,
    },
  ]

  const sheet = workbook.worksheets[0]

  //create header
  sheet.mergeCells(1, 2, 1, preparedReport.endColumnNumber + 1)
  sheet.mergeCells(2, 1, 2, preparedReport.endColumnNumber + 1)

  sheet.getCell(1, 2).value = `${t('formed')}: ${ login }, `
  + `${formats.date.dateViewFormatter(createDateTime, LANG_RU)}, ${formats.date.timeFormatter(createDateTime, LANG_RU)}`

  sheet.getCell(2, 1).value = `${t('consolidated_statement_about_machines')} ${t('during the period')} `+
  `${new Date().user.new(Date.parseDateTimeFromCalendarApi(from)).format('DD.MM.YY HH:mm:ss')} - `+
  `${new Date().user.new(Date.parseDateTimeFromCalendarApi(to)).format('DD.MM.YY HH:mm:ss')}`

  //типы техники
  sheet.getCell(3, 1).value = t('Type technics')
  sheet.mergeCells(3, 1, 3, 2)
  sheet.getRow(3).font = { size: 8 }
  sheet.getRow(3).alignment = { horizontal: 'left', vertical: 'middle' }

  preparedReport.techTypesMerges.forEach((M, index) => {
    sheet.mergeCells(M)
    const cell = sheet.getCell(preparedReport.techTypesMerges[index][0], preparedReport.techTypesMerges[index][1])

    cell.value = preparedReport.techTypes[index]

    cell.fill = {
      fgColor: { argb: 'D9D9D9' },
      pattern: 'solid',
      type   : 'pattern',
    }

    cell.border = {
      bottom: { style: 'thin' },
      left  : { style: 'thin' },
      right : { style: 'thin' },
      top   : { style: 'thin' },
    }

    cell.alignment = { horizontal: 'center', vertical: 'middle' }
  })

  reportData.forEach(P => {
    P.data.forEach(D => {
      sheet.insertRow(cursor, D)
      sheet.getRow(cursor).alignment = { horizontal: 'center', vertical: 'middle' }
      sheet.getCell(cursor, 1).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
      ++cursor
    })

    P.getCellMerges && sheet.mergeCells(P.getCellMerges(startDataRowNum))

    startDataRowNum = cursor
  })

  getCellMergesForRow(5).forEach(M => sheet.mergeCells(...M))

  sheet.mergeCells(cursor, 1, cursor, preparedReport.endColumnNumber + 1)

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'group_machinery_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
