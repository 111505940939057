import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { hooks } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import { F } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { withTranslate } from 'react-redux-multilingual'
import { CSSTransition } from 'react-transition-group'
import { getStore } from 'stores/storesRegistry'
import styled, { css } from 'styled-components'
import {layoutsConfig, TLayoutsConfig} from './layouts.config'
import WidgetsGallery from './WidgetsGallery'
import { useLayout } from './WidgetsGallery/hooks'
import { DropHereAcceptIcon, DropHereIcon } from './WidgetsGallery/icons'
import widgetsConfig from './WidgetsGallery/widgets.config'

const { usePopup } = hooks
const breakpoints = { lg: 1530, md: 1150, sm: 768, xs: 0 } as const
const cols = { lg: 4, md: 3, sm: 2, xs: 1 }

export type TBreakpoints = typeof breakpoints

const generateWidgetName = (layout: string[], id: string) => {
  // Detect free index
  let i = 1

  /*@ts-expect-error*/
  for(; layout.find(L => L.i === `${id}/${i}`);) {++i}
  return `${id}/${i}`
}

/*@ts-expect-error*/
function getWidgetsList(layoutForCurScreen) {

  /*@ts-expect-error*/
  return layoutForCurScreen.map(W => {
    const config = widgetsConfig.find(C => C.id === W.i.split('/').shift())

    if(!config){return console.error('Can not find widget in config', layoutForCurScreen)}

    return {
      ...W,
      isResizable: config.isResizable,
      widget     : config.id,

      // Get component for widget by id
      Component: config.Component,
    }
  })
}

export function Home({ width }: { width: number }) {
  const { layouts, initialMode, saveToLS } = useLayout(layoutsConfig, breakpoints)

  /*@ts-expect-error*/
  const currentSizeMode = useMemo(() => Object

    // Split breakpoints map to [name, size] pairs
    .entries(breakpoints)

    // Calculate distance between breakpoints size and width
    .map(([key, size]) => [key, size - width])

    // Negative or zero distance match to breakpoints bigger or equal to current width
    /*@ts-expect-error*/
    .filter(([name, dst]) => dst <= 0)

    // Get smaller distance
    /*@ts-expect-error*/
    .sort((A, B) => B[1] - A[1])

    // Get pair
    .shift()

    // First value in pair - name
    .shift(),

  [width],
  )

  const widgetsGallery = usePopup(false)

  /*@ts-expect-error*/
  const [layoutState, setLayoutState] = useState<TLayoutsConfig>(layouts)

  const changeConfig = useCallback((id, payload) => {

    /*@ts-expect-error*/
    const layout = layoutState[currentSizeMode]

    /*@ts-expect-error*/
    const index = layout.findIndex(L => L.i === id)

    // Avoid mutate original array
    const newLayout = [...layout]

    newLayout.splice(index, 1, {
      ...layout[index],
      ...payload,
    })

    setLayoutState({
      ...layoutState,

      /*@ts-expect-error*/
      [currentSizeMode]: newLayout,
    })
  }, [
    currentSizeMode, layoutState, setLayoutState,
  ])

  const onDropWidget = useCallback(event => {
    dragging.close()
    dragAccept.close()
    const data = JSON.parse(event.dataTransfer.getData('text'))

    /*@ts-expect-error*/
    const layout = layoutState[currentSizeMode]

    /*@ts-expect-error*/
    const layoutMap = layout.reduce((acc, W) => {
      for(let i = W.x; i < W.x + W.w; i++)
      {for(let j = W.y; j < W.y + W.h; j++) {
        if(acc[i] === undefined) {acc[i] = {}}

        acc[i][j] = true
      }}



      return acc
    }, {})

    // Search free space
    let x = 0

    let
        y = Infinity

    startSearchFreeSpace:
    for(let Y = 0; true; Y++)

    /*@ts-expect-error*/
    {for(let X = 0; X < cols[currentSizeMode]; X++){
      if(!(layoutMap[X] && layoutMap[X][Y])) {
        let isComponentFit = true

        for(let fitX = X; isComponentFit && fitX < X + data.w; fitX++)
        {for(let fitY = Y; isComponentFit && fitY < Y + data.h; fitY++)
        {isComponentFit = !(layoutMap[fitX] && layoutMap[fitX][fitY])}}

        if(isComponentFit){
          x = X; y = Y
          break startSearchFreeSpace
        }
      }
    }}

    setLayoutState({
      ...layoutState,

      /*@ts-expect-error*/
      [currentSizeMode]: layout.concat({
        h          : data.h,
        i          : data.isMultiple ? generateWidgetName(layout, data.id) : data.id,
        isResizable: false,
        w          : data.w,
        widget     : data.id,
        x,
        y,
      }),
    })
  }, [setLayoutState, layoutState, currentSizeMode])

  /*@ts-expect-error*/
  const onLayoutChanged = useCallback((layoutForCurScreen, layout) => {
    saveToLS('layout', layout)
    setLayoutState(layout)
  }, [saveToLS])

  const dragOver = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const removeWidget = useCallback(id => {
    setLayoutState({
      ...layoutState,

      /*@ts-expect-error*/
      [currentSizeMode]: layoutState[currentSizeMode].filter(W => W.i !== id),
    })
  }, [currentSizeMode, setLayoutState, layoutState])

  const [layoutMode, setLayoutMode] = useState(initialMode)
  const resetLayoutMode = useCallback(() => { setLayoutMode(null) }, [setLayoutMode])

  const resetLayout = useCallback(layout => {

    /*@ts-expect-error*/
    setLayoutState(layoutsConfig[layout])
    setLayoutMode(layout)
  }, [])

  const dragging = usePopup(false)
  const dragAccept = usePopup(false)
  const [dragCancel, setDragCancel] = useState(false)
  const toggleDrag = useCallback(state => state ? dragging.open() : dragging.close(), [dragging])
  const dropAcceptRef = useRef()

  const dragLeaveDropArea = useCallback(event => {
    // If user dragged out drop area - use other drop animation, not as for drop into area
    /*@ts-expect-error*/
    if(dropAcceptRef.current === event.target && event.pageY >= dropAcceptRef.current.getBoundingClientRect().height - 320) {
      setDragCancel(true)
    }
  }, [setDragCancel, dropAcceptRef])

  const dragEnterDropArea = useCallback(() => {
    dragAccept.open()
    setDragCancel(false)
  }, [dragAccept, setDragCancel])

  useEffect(() => {
    getStore('summary').init()
    return () => getStore('summary').dispose()
  }, [])

  return (
    <>
      {widgetsGallery.isShown && <DropAccept

        /*@ts-expect-error*/
        ref={dropAcceptRef}
        onDrop={onDropWidget}
        onDragOver={dragOver}
        onClick={widgetsGallery.close}
        onDragEnter={dragEnterDropArea}
        onDragLeave={dragLeaveDropArea}
      >
        <CSSTransition
          in={dragging.isShown}
          classNames="show"
          timeout={3000}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <DropHereIconStyled dropped={dragging.isShown} />
        </CSSTransition>

        <CSSTransition
          in={dragAccept.isShown && !dragCancel}
          classNames="show"
          timeout={300}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          {/*@ts-expect-error*/}
          {state => <DropHereAcceptIconStyled
            isShown={!dragAccept.isShown}
            dragCancel={dragCancel}
            checked={false}
          />}
        </CSSTransition>
      </DropAccept>}

      <Responsive
        compactType={'vertical'}
        autoSize={true}
        rowHeight={22}
        className="layout"

        /*@ts-expect-error*/
        layouts={layoutState}
        breakpoints={breakpoints}
        cols={cols}
        onResizeStop={F}
        onResizeStart={F}
        onResize={F}
        containerPadding={[9, 9]}
        margin={[16, 16]}
        draggableHandle=".draggable"
        onLayoutChange={onLayoutChanged}
        onDragStart={resetLayoutMode}

        // WidthProvider option
        measureBeforeMount={true}

        isDroppable={false}
        isDraggable={true}
        width={width}
        style={{ minHeight: 'calc(100% - 40px)' }}
      >

        {/*@ts-expect-error*/}
        {getWidgetsList(layoutState[currentSizeMode]).map(W => <W.Component
          key={W.i}

          /*@ts-expect-error*/
          changeConfig={payload => changeConfig(W.i, payload)}
          config={W}
          onClose={() => removeWidget(W.i)}
        />)}
      </Responsive>

      <WidgetsGallery

        /*@ts-expect-error*/
        layout={getWidgetsList(layoutState[currentSizeMode])}
        toggle={widgetsGallery.toggle}
        toggleDrag={toggleDrag}
        isShown={widgetsGallery.isShown}
        resetLayout={resetLayout}
        layoutMode={layoutMode}
      />
    </>
  )
}

export default withTranslate(observer(WidthProvider(Home)))

const DropAccept = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`

const acceptDropAnimation = css`
  width: 200%;
  height: 200%;
  top: -50%;
  opacity: 0;
  transition: all 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
`

const cancelDropAnimation = css`
  width: 0;
  transition: width 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
`

const DropHereAcceptIconStyled = styled(DropHereAcceptIcon)<{ isShown: boolean, dragCancel: boolean }>`
  position: fixed;
  top: calc(50% - 227px);
  z-index: 10;
  display: ${ ({ isShown }) => isShown ? 'inherit' : 'none' };

  &.show-enter { width: 0; }

  &.show-enter-active {
    width: 226px;
    transition: width 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
  }

  &.show-exit { width: 226px; opacity: 1;}

  &.show-exit-active {
    ${({ dragCancel }) => dragCancel ? cancelDropAnimation : acceptDropAnimation};
  }
`

const DropHereIconStyled = styled(DropHereIcon)`
  position: fixed;
  top: calc(50% - 227px);

  &.show-enter { width: 0; }
  &.show-enter-active {
    width: 226px;
    transition: width 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
  }

  &.show-exit {}
  &.show-exit-active {
    display: none;
  }
`
