import { action, makeObservable, observable, observe, override } from 'mobx'
import { allPass, append, identity } from 'ramda'
import TextInputStore from 'stores/formFieldsStores/TextInputStore'
import { SortableListStore } from './SortableListStore'

type TSearchDelegate<TItem> = (value: string, row: TItem) => boolean

class FilterableListStore<TItem>extends SortableListStore<TItem> {
  searchValue = new TextInputStore()
  @observable search: TSearchDelegate<TItem>[] = [() => true]

  // TODO: научиться переопределять свойства
  _items: TItem[] = []

  get unfilteredItems() {
    return this._items
  }

  @override setItems(items: TItem[]) {
    this._items = items
    this.filter()
  }

  @action.bound filter() {
    // Для попадания в результаты все предикаты поиска должны вернуть true
    const doSearch = allPass(
      this.search.filter(identity).map(S => (item: TItem) => { return S(this.searchValue.value, item)}),
    )

    super.setItems(this._items.filter(doSearch))
  }

  @action.bound
  setSearchDelegate(delegate: TSearchDelegate<TItem>) {
    this.search = append(delegate, this.search)
    return this.search.length - 1
  }

  @action.bound
  removeSearchDelegateByIndex(index: number) {
    delete this.search[index]
  }

  constructor() {
    super()
    makeObservable(this)

    observe(this.searchValue, 'value', () => {
      this.filter()
    })

    observe(this, 'search', () => {
      this.filter()
    })
  }
}

export { FilterableListStore }
