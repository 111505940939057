import { action, computed, makeObservable, observable } from 'mobx'
import { isEmpty } from 'ramda'
import HouseholdUnitStore from 'stores/Households/HouseholdUnitStore'

class HouseholdUnitsStore {
  @observable isLoading = false
  @observable map: {[key in string]: HouseholdUnitStore} = {}
  @observable showTrackArrow = false

  constructor() {
    makeObservable(this)
  }

  @action.bound
  clearHouseholdUnitsTrack() {
    Object.values(this.map).forEach(U => U.deleteTrack())
  }

  @action.bound

  //@ts-expect-error
  isOpenPane(imei, pane) {
    if(this.map[imei]) {
      return this.map[imei].openedTrackPanels.has(pane)
    }

    return false
  }

  @action.bound

  //@ts-expect-error
  getUnit(imei, forceCreate = true) {
    if(forceCreate && !this.map[imei]) {
      this.map[imei] = new HouseholdUnitStore(imei)
    }

    return this.map[imei]
  }

  @action.bound
  deleteTrackArrow() {
    this.showTrackArrow = false
  }

  @action.bound
  toggleTrackArrow() {
    this.showTrackArrow = !this.showTrackArrow
  }

  @computed
  get unitsWithTrack() {
    return Object.values(this.map).filter(U => !isEmpty(U.track.activeTrack))
  }

  @computed
  get watchedUnits() {
    return Object.values(this.map).filter(U => U.isWatched)
  }

  @computed
  get countWatchedUnitsByHouseholds() {
    return this.watchedUnits.reduce((acc, U) => {
      U.unit.ownerIds.forEach(id => {
        if(acc[id]) {
          acc[id] = acc[id] + 1
        } else {
          acc[id] = 1
        }
      })

      return acc
    }, {} as {[key: number]: number})
  }
}

export default () => {
  const newObj = new HouseholdUnitsStore()

  return new Proxy(newObj, {
    get(target, prop) {
      //@ts-expect-error
      if(target.hasOwnProperty(prop) || target[prop] !== undefined) {
        //@ts-expect-error
        return target[prop]
      }

      if(String(Number(prop)) === prop) {
        return target.getUnit(prop)
      }
    },
  })
}
