import React from 'react'
import { TSVGIconProps } from 'types'

export const LayoutOne = ({ color = 'white', size = 22, className }: TSVGIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect x="2.19995" y="7.33331" width="3.64514" height="3.45715" fill={color} />
      <rect x="2.19971" y="11.9428" width="8.33174" height="6.9143" fill={color} />
      <rect x="11.573" y="11.9428" width="8.33174" height="6.9143" fill={color} />
      <rect x="6.88647" y="7.33331" width="3.64514" height="3.45715" fill={color} />
      <rect x="11.573" y="7.33331" width="3.64514" height="3.45715" fill={color} />
      <rect x="16.2595" y="7.33331" width="3.64514" height="3.45715" fill={color} />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4V4.5V20C0 21.1046 0.89543 22 2 22H20C21.1046 22 22 21.1046 22 20V4.5V4V3.5V2C22 0.895431 21.1046 0 20 0H2C0.895431 0 0 0.895431 0 2V3.5V4ZM21 20V4.5H1V20C1 20.5523 1.44772 21 2 21H20C20.5523 21 21 20.5523 21 20Z"
        fill={color}
      />
    </svg>
  )
}
