import moment, { DurationInputArg1 } from 'moment'
import React, { FC, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { colorsStatus as colorsStatusMap } from '../../utils'
import { prepareDuration } from '../Map/duration'

type LegendContentProps = {
  showStatus: string[]
  action: (value: string) => void
  colorsStatus: Record<string, string>
}

const LegendContent: FC<LegendContentProps> = ({ showStatus, action, colorsStatus }) => {
  const onClick = (section: string) => () => {
    action(section)
  }

  return (
    <ul className="graphic-legend-custom">
      {Object.keys(colorsStatus).map(section =>
        <li
          key={section}
          className={`graphic-legend-custom__item${!showStatus.includes(section) ? ' shadow' : ''}`}
          onClick={onClick(section)}>
          <span style={{ background: colorsStatus[section] }}/>
          {section}
        </li>,
      )}
    </ul>
  )
}

type ValueObject = {
  value: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

type ChartAProps = {
  sumData: boolean
  report?: object[]
  colorsStatus: Record<string, string>
}

export const ChartA: FC<ChartAProps> = ({ report, sumData, colorsStatus }) => {
  const translate = useTranslate()
  const [showStatus, setShowStatus] = useState<string[]>(Object.keys(colorsStatus))

  const tickFormatter = (tik: DurationInputArg1) => {
    return `${moment.duration(tik).asHours().toFixed()} ${translate('h')}`
  }

  const tooltipFormatter = (value?: string | number | Array<string | number>): string => {
    const valueNumber = Number(value)

    return prepareDuration(!isNaN(valueNumber) ? valueNumber : undefined)
  }

  const toggleSection = (value: string) => {
    if(showStatus.includes(value)) {
      setShowStatus(showStatus.filter(item => item !== value))
    } else {
      setShowStatus([...showStatus, value])
    }
  }

  return (
    <div className="graph">
      {report &&
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={report}
            margin={{
              bottom: 0,
              left  : 0,
              right : 0,
              top   : 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name" xAxisId={0}/>
            <XAxis dataKey="name" xAxisId={1} hide />
            <XAxis dataKey="name" xAxisId={2} hide />
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip formatter={tooltipFormatter}/>
            {!sumData &&
              <Legend
                verticalAlign="top"
                content={<LegendContent
                  showStatus={showStatus}
                  action={toggleSection}
                  colorsStatus={colorsStatus}
                />
                }
              />
            }
            <Bar
              dataKey="M3"
              xAxisId={0}
              stackId="a"
              name={translate('M3')}
              fill={colorsStatusMap['M3']}
            />
            <Bar
              dataKey="M5"
              xAxisId={1}
              barSize={150}
              name={translate('M5')}
              fill={colorsStatusMap['M5']}
            />
            <Bar
              dataKey="M6"
              xAxisId={2}
              barSize={100}
              name={translate('M6')}
              fill={colorsStatusMap['M6']}
            />

            {sumData ?
              <Bar
                dataKey="duration"
                fill="#000000"
                stackId="a"
                name={translate('duration')}
              >
                {report.map((entry, index) => {
                  const { value } = entry as ValueObject
                  return <Cell key={`cell-${index}`} fill={colorsStatusMap[`M${value}`]}/>
                })}
              </Bar>
              : showStatus.map(status => <Bar key={status} dataKey={status} stackId="a" fill={colorsStatus[status]}/>)}
          </BarChart>
        </ResponsiveContainer>
      }
    </div>
  )
}
