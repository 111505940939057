import { TWorkMode } from 'types'

export { ConfigContext } from '../containers/ValidateUser'

export const fuelSchema = {
  INC_SUMM_FUEL_COMB: {
    color            : '#78C800',
    titleForTranslate: 'harvest',
  },
  INC_SUMM_FUEL_MOVE: {
    color            : '#06a9f5',
    titleForTranslate: 'driving_mode',
  },
  INC_SUMM_FUEL_STOP: {
    color            : '#d10a40',
    titleForTranslate: 'work_engine_in_no_movement',
  },
}

export const modesSchema = {
  TIME_NO_LINK          : { name: 'M0', color: '#BCBCBC' },
  TIME_MOVE_NO_HARVEST  : { name: 'M1', color: '#06a9f5' },
  TIME_IDLE             : { name: 'downtime', color: '#d10a40' }, // Спец. режим для виджета времени, обьединяет М3, М5, М6
  TIME_OFF              : { name: 'M2', color: '#202020' },
  TIME_STOP_DON         : { name: 'M3', color: '#d10a40' },
  TIME_HARVEST          : { name: 'M4', color: '#78C800' },
  TIME_STOP_HARVEST     : { name: 'M5', color: '#d61fd6' },
  TIME_STOP_HARVEST_FULL: { name: 'M6', color: '#F37203' },
}

export const colorsStatus: {
  [Key in TWorkMode]: string
} = {
  'M0': '#BCBCBC',
  'M1': '#06a9f5',
  'M2': '#202020',
  'M3': '#d10a40',
  'M4': '#78C800',
  'M5': '#d61fd6',
  'M6': '#F37203',
}

export const GMT = ['-12', '-11', '-10', '-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0', '+1', '+2', '+3', '+4', '+5', '+6', '+7', '+8', '+9', '+10', '+11', '+12']

export const validateEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)

export const getPassword = () => Math.random().toString(36).slice(-8)

export const validateTelNumber = (telNumber: string) =>
  telNumber && telNumber.length > 10 && telNumber.length < 17


export const hexToRGBA = (hex: string, alpha = 20) => {
  const bigint = parseInt(hex.replace('#',''), 16)
  alpha = alpha / 100
  const r = bigint >> 16 & 255
  const g = bigint >> 8 & 255
  const b = bigint & 255

  return `rgba(${r},${g},${b},${alpha})`
}

export const colorsRegister = [
  '#d10a40',
  '#F37203',
  '#78C800',
  '#06a9f5',
  '#d61fd6',
]

export const notificationNumName = {
  NUM_MESSAGES_CRIT: {
    value        : 1,
    name         : 'Критичное',
    color        : '#D10B41',
    typeTranslate: 'critical',
  },
  NUM_MESSAGES_NOCRIT: {
    value        : 2,
    name         : 'Не критичное',
    color        : '#F6C547',
    typeTranslate: 'not_critical',
  },
  NUM_MESSAGES_INFO: {
    value        : 4,
    name         : 'Информационное',
    color        : '#15CE70',
    typeTranslate: 'informational',
  },
  NUM_MESSAGES_SERVICE: {
    value        : 8,
    name         : 'Техническое обслуживание',
    color        : '#0072CF',
    typeTranslate: 'service',
  },
}

export const notificationStatus = {
  1: {
    value         : 1,
    color         : '#D10B41',
    name          : 'Критичное',
    type          : 'CRITICAL',
    typeTranslate : 'critical',
    registersField: 'NUM_MESSAGES_CRIT',
  },
  2: {
    value         : 2,
    color         : '#F6C547',
    name          : 'Не критичное',
    type          : 'NOT_CRITICAL',
    typeTranslate : 'not_critical',
    registersField: 'NUM_MESSAGES_NOCRIT',
  },
  4: {
    value         : 4,
    color         : '#15CE70',
    name          : 'Информационное',
    type          : 'INFORMATION',
    typeTranslate : 'informational',
    registersField: 'NUM_MESSAGES_INFO',
  },
  8: {
    value         : 8,
    color         : '#0072CF',
    name          : 'Техническое обслуживание',
    type          : 'SERVICE',
    typeTranslate : 'service',
    registersField: 'NUM_MESSAGES_SERVICE',
  },
}

export const notificationCategory = {
  Двигатель: {
    value        : 1,
    type         : 'ENGINE',
    typeTranslate: 'engine',
  },
  'Топливная система': {
    value        : 2,
    type         : 'FUEL_SYSTEM',
    typeTranslate: 'fuel_system',
  },
  'Адаптер и жатка': {
    value        : 4,
    type         : 'ADAPTER',
    typeTranslate: 'adapter_reaper',
  },
  'Наклонная камера и питатель': {
    value        : 8,
    type         : 'INCLINED',
    typeTranslate: 'inclined_camera_feeder',
  },
  Кабина: {
    value        : 16,
    type         : 'CABIN',
    typeTranslate: 'cabin',
  },
  'Молотилка ЗУК и измельчитель КУК': {
    value        : 32,
    type         : 'THRESHER',
    typeTranslate: 'thresher',
  },
  Очистка: {
    value        : 64,
    type         : 'CLEANING',
    typeTranslate: 'cleaning',
  },
  'Ходовая часть': {
    value        : 128,
    type         : 'CHASSIS',
    typeTranslate: 'chassis',
  },
  Бункер: {
    value        : 256,
    type         : 'BUNKER',
    typeTranslate: 'hopper',
  },
  'ИРС и копнитель': {
    value        : 512,
    type         : 'STRAW_CHOPPER',
    typeTranslate: 'stacker',
  },
  Гидравлика: {
    value        : 1024,
    type         : 'HYDRAULICS',
    typeTranslate: 'hydraulics',
  },
  'Электрика и электроника': {
    value        : 2048,
    type         : 'ELECTRICS',
    typeTranslate: 'electrics',
  },
  Уведомление: {
    value        : 4096,
    type         : 'NOTIFICATION',
    typeTranslate: 'notification',
  },
}
