import L from 'leaflet'

export const iconShadow = L.icon({
  iconUrl  : `${process.env.PUBLIC_URL}/img/svg/icon-shadow.svg`,
  iconSize : [46, 67],
  className: 'icon-std',
})

export const location = L.icon({
  iconUrl  : `${process.env.PUBLIC_URL}/img/svg/location.svg`,
  iconSize : [30, 30],
  className: 'icon-location',
})

export const iconMarker = L.icon({
  iconUrl : `${process.env.PUBLIC_URL}/img/svg/edit-marker.svg`,
  iconSize: [30, 30],
})

export const iconMarkerError = L.icon({
  iconUrl : `${process.env.PUBLIC_URL}/img/svg/edit-marker-error.svg`,
  iconSize: [30, 30],
})

export const iconMarkerDelete = L.icon({
  iconUrl : `${process.env.PUBLIC_URL}/img/svg/edit-marker-delete.svg`,
  iconSize: [30, 30],
})

export const iconStart = L.divIcon({
  html: `<div class="icon-start-finish">
        <img src="/img/svg/map/start.svg"/>
    </div>`,
  iconSize: [40, 40],
})

export const iconFinish = L.divIcon({
  html    : '<div class="icon-start-finish" style=\'background-image: url("/img/svg/map/finish.svg")\'>',
  iconSize: [40, 40],
})

export const notice = (statusNumber: number) => L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <div class='${statusNumber ? `status-${statusNumber}` : ''}'>
              <img src="/img/svg/map/notice.svg"/>
            </div>
          </div>`,

  iconSize: [40, 40],
})

export const marker3 = L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <img src="/img/svg/map/onloading.svg"/>
          </div>`,

  iconSize: [40, 40],
})

export const marker2 = L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <img src="/img/svg/map/bunker-full.svg"/>
        </div>`,

  iconSize: [40, 40],
})

export const marker1 = L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <img src="/img/svg/map/stopintervals.svg" />
        </div>`,

  iconSize: [40, 40],
})

export const markerOil0 = L.icon({
  iconUrl : `${process.env.PUBLIC_URL}/img/svg/oil0.svg`,
  iconSize: [25, 25],
})

export const filling = L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <img src="/img/svg/map/filling.svg"/>
        </div>`,

  iconSize: [40, 40],
})

export const drain = L.divIcon({
  html: `<div class='tracking-machine__marker'>
            <img src="/img/svg/map/drain.svg"/>
        </div>`,

  iconSize: [40, 40],
})

export const markerOil1 = L.icon({
  iconUrl : `${process.env.PUBLIC_URL}/img/svg/oil1.svg`,
  iconSize: [25, 25],
})

export const predictUnloadingYellow = L.divIcon({
  html: `<div class='tracking-machine__marker status-2'>
    <img src="/img/svg/map/predictUnloading.svg"/>
  </div>`,

  iconSize: [40, 40],
})

export const predictUnloadingRed = L.divIcon({
  html: `<div class='tracking-machine__marker status-1'>
    <img src="/img/svg/map/predictUnloading.svg"/>
  </div>`,

  iconSize: [40, 40],
})

export const bugIcon = (type: string, status: number, rotate?: number, transport?: unknown) => L.divIcon({
  html: `
    <div
      style="
        background: url('${process.env.PUBLIC_URL}/img/svg/tech_${type}_${status}.svg') no-repeat;
        transform: rotate(${rotate ? rotate : 0}deg)
      "
    >
    ${transport ? `
      <div style="
        background: url('${process.env.PUBLIC_URL}/img/svg/transport.svg') no-repeat;
        position: relative;
        left: 30px;"></div>`
    : ''}
    </div>
  `,
  iconSize   : [35, 35],
  popupAnchor: [0, 0],
  className  : `icon_tech tech_${type} tech_${type}_${status}`,
})
