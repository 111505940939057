
export const demoRequest = async (name: string) => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/demo/${name}.json`, {
      credentials: 'same-origin',
      headers    : {
        Accept        : 'application/json',
        'Content-Type': 'application/json',
      },

    })

    return await response.json()
  } catch(err) {
    console.error(err)
  }
}
