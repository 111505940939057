import { Button, icons, Spacer } from '@agdt/agrotronic-react-components'
import moment from 'moment/moment'
import React, { FC,useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { Calendar } from './Calendar'

type ElementType = {
  to: string
  from: string
  today?: boolean
}

type MachineResultType = {
  id: number
  imei: number
  name: string
}

type Props = {
  machinesResult: MachineResultType[]
  permissions: number | null
  section: string
  result: ElementType[]
  showSelected: boolean
  setFilter: (section: string, item: ElementType | string) => void
}

export const FilterDateButtons: FC<Props> = ({
  machinesResult,
  permissions,
  result,
  section,
  setFilter,
  showSelected,
}) => {
  const t = useTranslate()
  const [showCalendar, setShowCalendar] = useState(false)
  const selectWeek = !permissions || permissions >= 7 // Отображение кнопки недели, под вопросом
  const onSwitchShowDate = useCallback(() => setShowCalendar(!showCalendar), [showCalendar])

  const applyDay = useCallback(() => {
    const from = moment().startOf('day').format('YYMMDDHHmmssSSS')
    const to = moment().format('YYMMDDHHmmssSSS')
    setFilter(section, { from, to, today: true })
    setShowCalendar(false)
  }, [section])

  const applyWeek = useCallback(() => {
    const from = moment().subtract(6, 'day').startOf('day').format('YYMMDDHHmmssSSS')
    const to = moment().format('YYMMDDHHmmssSSS')
    setFilter(section, { from, to })
    setShowCalendar(false)
  }, [section])

  return (
    <Wrapper>
      <Button variant='border' width='100px' onClick={applyDay}>{t('today')}</Button>
      <Spacer size="10px" />
      {selectWeek && <Button variant='border' width='100px' onClick={applyWeek}>{t('week')}</Button>}
      <Spacer size="40px" />
      <Button width='150px' variant='border' onClick={onSwitchShowDate}>
        <StyledCalendar><icons.CalendarIcon color='#666666' size='24px' /></StyledCalendar>

        {showSelected && result.length
          ? `${moment(result[0].from, 'YYMMDDHHmmssSSS').format('DD.MM.YYYY')}
             - ${moment(result[0].to, 'YYMMDDHHmmssSSS').format('DD.MM.YYYY')}`

          : t('select_a_date')
        }</Button>

      <Calendar
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        result={result}
        machinesResult={machinesResult}
        setFilter={setFilter}
        section={section}
        t={t}
      />
    </Wrapper>
  )
}

const StyledCalendar = styled.div`
  margin-right: 10px;
`

const Wrapper = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 500px;

  & > button {
    height: 60px;
    width: auto;
  }
`
