import LegendItemsList from 'components/LegendItemsList'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { setGeoZoneColoringLegendValues } from 'reducers/geoZone/actions'
import styled from 'styled-components'

type TProps = {
  id: string
  legendValues: number[]
  mapType: {
    colors: string[]
    measure: string
    measureName: string
    values: number[]
  }

  setLegendValues: (id: string, data: unknown) => void
}

const Legend = ({ id, legendValues, mapType, setLegendValues }: TProps) => {
  const t = useTranslate()

  return <div>
    <div>{`${t(mapType.measureName)}, ${t(mapType.measure)}`}</div>
    <LegendContainer>
      <LegendItemsList
        colors={mapType.colors}
        id={id}
        legendValues={legendValues}
        setLegendValues={setLegendValues}
        t={t}
        values={mapType.values}
      >
        {color => <LegendItem color={color} />}
      </LegendItemsList>
    </LegendContainer>
  </div>
}

const LegendContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
`

const LegendItem = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 3px;
  display: inline-block;
  height: 15px;
  margin: 0 5px;
  width: 15px;
`

export default connect(

  //@ts-expect-error
  (state, { id }) => ({ legendValues: state.geoZone.legendValues[id] }),

  //@ts-expect-error
  dispatch => ({ setLegendValues: (id, data) => dispatch(setGeoZoneColoringLegendValues(id, data)) }),
)(Legend)
