import { action, autorun, makeObservable, observable } from 'mobx'
import { isEmpty } from 'ramda'
import { getStore } from 'stores/storesRegistry'
import { TUnit } from 'types'
import TrackStore from './TrackStore'

type TTracksPaneName = 'openDate' | 'openSettingsTrack'

export default class HouseholdUnitStore {
  imei: number

  //@ts-expect-error
  @observable unit: TUnit
  @observable isLoading = false
  @observable openedTrackPanels = new Set()
  @observable track
  @observable isWatched = false

  //@ts-expect-error
  constructor(imei) {
    makeObservable(this)
    this.imei = imei
    this.track = new TrackStore(this)

    // при обновлении registers - обновляем данные у юнита
    autorun(() => {
      const unitData = getStore('dictionaries').targets2.unitsWithRegistersDataMap[this.imei]
      this.setUnit(unitData)
    })
  }

  @action.bound

  //@ts-expect-error
  setUnit(unit) {
    this.unit = unit
  }

  @action.bound
  togglePanel(pane: TTracksPaneName, state = undefined) {
    const newState = state === undefined ? !this.openedTrackPanels.has(pane) : state
    this.openedTrackPanels[newState ? 'add' : 'delete'](pane)
  }

  @action.bound
  deleteTrack() {
    if(!isEmpty(this.track.activeTrack)){
      this.track.removeIterator()
      this.track = new TrackStore(this)
    }
  }

  @action.bound
  toggleWatch() {
    this.isWatched ? this.unwatch() : this.setWatched()
  }

  @action.bound
  setWatched() {
    this.isWatched = true

    // если у юнита есть трек за сегодня - нужно запросить новый трек, потому что он может отличаться от того, который
    // запрошен ранее и иконка техники может появиться в месте до которого трек ещё не отрисован.
    if(!isEmpty(this.track.activeTrack) && this.track.endDateIsToday) {
      this.track.setTimeRange(this.track.timeRangeFrom, new Date().getTime())
      this.track.getInitTrackData()
    }
  }

  @action.bound
  unwatch() {
    //@ts-expect-error
    if(this.track.iteratorTimerId) {
      this.track.removeIterator()
    }

    this.isWatched = false
  }
}
