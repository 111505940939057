export const columns = [
  { id: 'model', tooltipTitle: 'Type technics', title: 'type' },
  { id: 'name', tooltipTitle: 'Serial number machine', title: 'machine' },
  { id: 'date', tooltipTitle: 'date', title: 'date' },
  { id: 'startFuel', tooltipTitle: 'Fuel in the tank at the beginning of the day', title: 'Start of fuel' },
  { id: 'endFuel', tooltipTitle: 'Fuel in the tank at the end of the day', title: 'End of fuel' },
  { id: 'filling', tooltipTitle: 'Refills per day', title: 'filling' },
  { id: 'drain', tooltipTitle: 'Refills per day', title: 'drain' },
  { id: 'summFuel', tooltipTitle: 'Total consumption', title: 'summFuel' },
  { id: 'summFuelMove', tooltipTitle: 'Consumption in transport mode', title: 'summFuelMove' },
  { id: 'summFuelComb', tooltipTitle: 'Consumption in cleaning / work', title: 'summFuelComb' },
  { id: 'summFuelStand', tooltipTitle: 'Idle flow', title: 'summFuelStand' },
  { id: 'fuelHour', tooltipTitle: 'Average consumption', title: 'fuelHour' },
  { id: 'engineTime', tooltipTitle: 'ICE working time', title: 'engineTime' },
  { id: 'engineTimeMove', tooltipTitle: 'Time in transport mode', title: 'engineTimeMove' },
  { id: 'engineTimeComb', tooltipTitle: 'Time in cleaning / work', title: 'engineTimeComb' },
  { id: 'engineTimeStop', tooltipTitle: 'Idle time', title: 'engineTimeStop' },
]