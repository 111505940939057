import L from 'leaflet'
import { RefObject,useEffect } from 'react'
import { Map } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'

export const useMapControls = (mapRef: RefObject<Map>) => {
  const t = useTranslate()

  useEffect(() => {
    if(!mapRef.current) {
      return
    }

    const onInvalidateSize = () => {
      if(mapRef.current) {
        mapRef.current.leafletElement.invalidateSize()
      }
    }

    L.control
      .zoom({
        zoomInTitle : t('zoom in'),
        zoomOutTitle: t('zoom out'),
      })
      .addTo(mapRef.current.leafletElement)

    L.control
      .fullscreen({
        title      : t('fullscreen'),
        titleCancel: t('exit fullscreen'),
      })
      .addTo(mapRef.current.leafletElement)

    mapRef.current.leafletElement.on('enterFullscreen', onInvalidateSize)
    mapRef.current.leafletElement.on('exitFullscreen', onInvalidateSize)
  }, [mapRef, t])
}
