import React, { FC } from 'react'

export const IconClose: FC = () =>
  <svg viewBox={'0 0 24 18'} width={24} height={18}>
    <path
      id="Combined Shape"
      style={{ fill: '#78c800' }}
      d="M18.54,5.46v0c-0.39,-0.39 -1.02,-0.39 -1.41,0l-5.66,5.66c-0.39,0.39 -0.39,1.02 0,1.41v0c0.39,0.39 1.02,0.39 1.41,0l5.66,-5.66c0.39,-0.39 0.39,-1.02 0,-1.41zM12.54,12.54v0c0.39,-0.39 0.39,-1.02 0,-1.41l-5.66,-5.66c-0.39,-0.39 -1.02,-0.39 -1.41,0v0c-0.39,0.39 -0.39,1.02 0,1.41l5.66,5.66c0.39,0.39 1.02,0.39 1.41,0z"
    />
  </svg>
