import React from 'react'
import { CSSTransition } from 'react-transition-group'

const timeout = {
  appear: 500,
  enter : 300,
  exit  : 100,
}

export type TProps = {
  children: React.ReactNode
  isShow: boolean
}

const CSSTransitionWrapper = ({ children, isShow }: TProps) =>
  <CSSTransition
    classNames="example"
    in={isShow}
    mountOnEnter
    timeout={timeout}
    unmountOnExit
  >
    {children}
  </CSSTransition>

export default CSSTransitionWrapper
