import React, { FC, useEffect, useRef } from 'react'
import { columns } from '../constants'
import { Header } from './Header'

type ReportElement = {
  date: string
  name: string
} & Record<string, string>

type FilterResultProps = {
  report: ReportElement[][][]
}

export const FilterResult: FC<FilterResultProps> = ({ report }) => {
  const originalTable = useRef<HTMLTableElement>(null)
  const fixTable = useRef<HTMLTableElement>(null)

  const handleScroll = () => {
    if(!fixTable.current || !originalTable.current) {
      return
    }

    if(fixTable.current.style) {
      fixTable.current.style.width = `${originalTable.current.clientWidth}px`
    }

    if(originalTable.current.tHead && fixTable.current.tHead) {
      const elemsOrigTable = Array.from(originalTable.current.tHead.rows[0].cells)
      const elemsFixTable = Array.from(fixTable.current.tHead.rows[0].cells)

      elemsOrigTable.forEach((elem, index) => {
        elemsFixTable[index].style.width = `${elem.clientWidth}px`
      })
    }



    const { top } = originalTable.current.getBoundingClientRect()
    const height = originalTable.current.offsetHeight

    if(top < 0 && -top < height - 80) {
      fixTable.current.style.display = 'block'
    }
    else {
      fixTable.current.style.display = 'none'
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className="watch__full">
      <table ref={originalTable}>
        <Header />

        <tbody>
          {report[0].map(I => I.map(rowData => <tr key={`${rowData.date}_${rowData.name}`}>
            { columns.map(({ id }) => <td key={id}>
              <p>{rowData[id]}</p>
            </td>) }
          </tr>))}
        </tbody>
      </table>

      <table className={'fixed-table'} ref={fixTable}>
        <Header />
      </table>
    </div>
  )
}
