import { Badge } from '@agdt/agrotronic-react-components'
import Tippy from '@tippyjs/react'
import React, { FC } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { notificationStatus } from 'utils'
import { SumNotification } from '../../../stores/Notices/NoticesUnitsListStore'

type Props = {
  notifications: SumNotification[]
  translate: TTranslate
}

const NotificationBadges: FC<Props> = ({ notifications, translate }) => {
  return (
    <MachineNotificationContainerStyled>
      {notifications.map(notification => {
        if(!notification.value) {
          return null
        }

        const status = notificationStatus[notification.status]
        return <div key={`${notification.value}_${status.color}`}>
          {status && <Tippy
            content={translate(status.typeTranslate)}
          >
            <div>
              <BadgeStyle
                size={16}
                textSize={10}
                count={notification.value}
                color={status.color}
              />
            </div>
          </Tippy>
          }</div>
      })}
    </MachineNotificationContainerStyled>
  )
}

const MachineNotificationContainerStyled = styled.div`
  display: flex;
  align-items: center;
`

const BadgeStyle = styled(Badge)`
  position: relative;
  margin-left: 4px;
`

export default withTranslate(NotificationBadges)
