import React from 'react'

const CheckedIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="13" cy="13" r="13" fill="#0072CF"/>
    </svg>
  )
}

export default CheckedIcon
