import { LatLngTuple } from 'leaflet'
import { action, computed, makeObservable, observable } from 'mobx'
import { path } from 'ramda'
import { TMapTypes, TUnit } from 'types'
import { getStore } from '../storesRegistry'

export type TMarker = Record<TUnit['imei'], TUnit & {position: LatLngTuple}>

export class MapWidgetStore {
  @observable selectedMapType: TMapTypes = 'google1'

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setSelectedMapType(type: TMapTypes) {
    this.selectedMapType = type
  }

  @computed
  get markers() {
    const units: TUnit[] = getStore('dictionaries').targets2.unitsWithRegistersData
    const activityWidgetStore = getStore('activityWidget')

    const filterIsActive = activityWidgetStore.statusesSelector.selectedStatusesByHouseholds.size !== 0
    || activityWidgetStore.statusesSelector.selectedStatuses.size !== 0

    //если юнит относится к предприятию для которого выбран фильтр по статусам и находится в выбранном статусе - нужно добавить его к маркерам
    const farmUnitHasSelectedStatus = (unit: TUnit): boolean => {
      let hasSelectedStatus = false

      activityWidgetStore.statusesSelector.selectedStatusesByHouseholds.forEach((value, key) => {
        if(unit.ownerIds.includes(key) && value.has(unit.data.STATUS.value)) {
          hasSelectedStatus = true
        }
      })

      return hasSelectedStatus
    }

    const unitHasSelectedStatus = (unit: TUnit): boolean => {
      return getStore('activityWidget').statusesSelector.selectedStatuses.has(unit.data.STATUS.value)
    }

    const markers: TMarker = units.reduce((result, unit) => {
      const filter = getStore('activityWidget').statusesSelector.selectedStatusesByHouseholds.size !== 0
        ? farmUnitHasSelectedStatus
        : unitHasSelectedStatus

      // если у предприятия выбраны фильтры по статусам, нужно проверить удовлетворяет ли юнит условиям и если да - добавить его к маркерам
      if(filterIsActive && !filter(unit)) {
        return result
      }

      //не у всех юнитов есть координаты, эти юниты не отображаются
      if(path(['LATITUDE', 'value'], unit.data) && path(['LONGITUDE', 'value'], unit.data)){
        result[unit.imei] = {
          ...unit,
          position: [
            unit.data.LATITUDE.value,
            unit.data.LONGITUDE.value,
          ],
        }
      }

      return result
    }, {} as TMarker)

    return Object.values(markers)
  }

  @computed
  get clusterKey(){
    let hash = 0

    this.markers.forEach(M => {
      hash = hash + M.id
    })

    return hash + this.markers.length
  }
}
