import React from 'react'
import { TSVGIconProps } from 'types'

type TProps = {
  checked?: boolean
} & TSVGIconProps

const DropHereAcceptIcon = ({ className, size=226, checked = true }: TProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 226 226" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="113" cy="113" r="113" fill="#D10B41"/>
      {checked && <path fillRule="evenodd" clipRule="evenodd" d="M86.9999 145.757L165.879 66.8785L170.121 71.1212L89.1213 152.121L86.9999 154.243L84.8786 152.121L49.8787 117.121L54.1214 112.879L86.9999 145.757Z" fill="white"/>}
    </svg>
  )
}

export default DropHereAcceptIcon
