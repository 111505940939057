import { observer } from 'mobx-react'
import React, { FC, ReactElement, useContext } from 'react'
import { TTranslate } from 'types'
import { ConfigContext } from '../../containers/ValidateUser'
import { getStore } from '../../stores/storesRegistry'
import TooltipD from '../Tooltip'
import { Col, WrapActiveParameters } from './activeParameters'
import { FilterCheckbox } from './checkbox'
import { FilterDateButtons } from './dateButtons'
import { FilterDateSolo } from './dateSolo'
import { FilterItemMultyWrapper } from './FilterItemMultyWrapper'
import { FilterText } from './filterText'
import { FilterItemMultiRadio } from './myltiRadio'
import { FilterRadio } from './radio'

const styles = {
  tooltip: { bottom: '26px', right: '0' },
}

export const Plus = () =>
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path
      id="plusButton"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
      fill="#dedede"
    />
  </svg>

type FilterWrapControlProps = {
  children?: Array<JSX.Element | null | string>
  button?: boolean
  disableButton?: boolean
  shadowButton: boolean
  filter: unknown
  fixedDataFilter?: () => void
  onFilter: (value: unknown) => void
  translate: TTranslate
}


const FilterWrapControl: FC<FilterWrapControlProps> = ({
  filter,
  children,
  button,
  disableButton,
  shadowButton,
  translate,
  fixedDataFilter,
  onFilter,
}) => {
  const childrenWithProps = React.Children.map(children, child => {
    if(child) {
      const element = child as ReactElement
      return element.props.hideFilter ? null : React.cloneElement(element, { item: element.props.item })
    }

    return null
  })

  const onClick = () => {
    onFilter(filter)

    if(fixedDataFilter) {
      fixedDataFilter()
    }
  }

  return (
    <div className="filter-control__parameters">
      <div className="filter-control__items">
        {childrenWithProps}
      </div>
      <div className="filter-control__wrap-buttons">
        {button && (!disableButton && !shadowButton ?
          <div>
            <div
              onClick={onClick}
              className="filter-control__succes">
              {translate('accept')}
            </div>
            <TooltipD
              text={translate('Apply selected parameters in the filter')}
              style={styles.tooltip}
            />
          </div>
          :
          <div>
            <div className="filter-control__succes shadow">
              {translate('accept')}
            </div>
            <TooltipD text={translate('Apply selected parameters in the filter')}
              style={styles.tooltip} />
          </div>
        )}
      </div>
    </div>
  )
}

type ResultType = {
  id: number
  imei: number
  name: string
  to: string
  from: string
}

type FilterItemType = {
  labels: string[]
  hideFilter: boolean
  filter: string
  search: boolean
  name: string
  notTranslating: boolean
  section: string
  result: ResultType[]
  items: string[]
  type: 'checkbox' | 'date' | 'date-solo' | 'checkbox-machine' | 'radio-machine' | 'radio' | 'text'
  validate?: boolean
}

export type FilterControlConfig = {
  type: string
  filter: FilterItemType[]
  shadowButton: boolean
  info?: {
    operatorLogin: string
    login: string
  }
  config?: {
    NOTICES_PERIOD?: number
    REGISTERS_PERIOD?: number
  }
  stringFixedDataFilter: boolean
  onFilter: (value: unknown) => void
  fixedDataFilter?: () => void
  onStartFilter: (
    section: string,
    value: string | {
      to: string
      from: string
      today?: boolean
    } | {
      id: number
      name: string
      imei: number
    } | {
      id: number
      name: string
      to?: string
      from?: string
    },
    solo?: boolean,
    clear?: boolean
  ) => void
  onStartFilterArrayMachine: (value: unknown[]) => void
  clearFilter: () => void
  clearFixedDataFilter: () => void
}

type FilterControlProps = {
  button?: boolean
  disableButton?: boolean
  translate: TTranslate
} & FilterControlConfig

type ElementType = {
  registers: {
    FUEL_MAX: unknown
  }
  subtype: string
}

const FilterControl: FC<FilterControlProps> = ({
  config,
  info,
  type,
  filter,
  button,
  disableButton,
  shadowButton,
  translate,
  onFilter,
  fixedDataFilter,
  onStartFilter,
  onStartFilterArrayMachine,
  clearFilter,
  stringFixedDataFilter,
  clearFixedDataFilter,
}) => {
  const activeFilter = filter.some(item => item.result.length)
  const farm = useContext(ConfigContext)?.farm

  const getPermission = (): number | null => {
    const key = type === 'notice' ? 'NOTICES_PERIOD' : 'REGISTERS_PERIOD'

    if(info && config?.[key]) {
      return config[key] as number
    }

    return null
  }

  const factoryFilter = (item: FilterItemType): JSX.Element | null | string => {
    switch(item.type) {
      case 'checkbox':
        if(item.items.length >= 1) {
          return <FilterCheckbox
            {...item}
            key={item.section}
            onStartFilter={onStartFilter}
          />
        } else {
          return <div key={item.section} />
        }

      case 'date':
        return <FilterDateButtons
          {...item}
          machinesResult={filter.find(section => section.section === 'machine')?.result || []}
          key={item.section}
          permissions={getPermission()}
          setFilter={onStartFilter}
          showSelected
        />

      case 'date-solo':
        return <FilterDateSolo
          key={item.section}
          {...item}
          onStartFilter={onStartFilter}
        />

      case 'checkbox-machine':
        const getFilterDataForCurrentFarm = () => {
          if(item.filter === 'FUEL.l') {
            return getStore('dictionaries').targets2.filterDataForCurrentFarm.filter(
              (element: ElementType) => element.registers.FUEL_MAX,
            )
          }

          if(item.filter === 'ZUK') {
            return getStore('dictionaries').targets2.filterDataForCurrentFarm.filter(
              (element: ElementType) => element.subtype === 'ZUK',
            )
          }

          return getStore('dictionaries').targets2.filterDataForCurrentFarm
        }

        return <FilterItemMultyWrapper
          {...item}
          items={getFilterDataForCurrentFarm()}
          key={item.section + farm?.selectedFarmId}
          onStartFilterArrayMachine={onStartFilterArrayMachine}
        />
      case 'radio-machine':
        /*@ts-expect-error*/
        return <FilterItemMultiRadio
          {...item}
          key={item.section}
          onStartFilter={onStartFilter}
        />
      case 'radio':
        return <FilterRadio
          {...item}
          key={item.section}
          onStartFilter={onStartFilter}
        />
      case 'text':
        return <FilterText
          {...item}
          translate={translate}
          key={item.section}
          onStartFilter={onStartFilter}
        />
      default:
        return null
    }
  }

  const onClick = () => {
    if(stringFixedDataFilter) {
      clearFixedDataFilter()
    } else {
      if(fixedDataFilter) {
        fixedDataFilter()
      }
    }
  }

  return (
    <div className="filter-control">
      <FilterWrapControl
        button={button}
        onFilter={onFilter}
        filter={filter}
        shadowButton={shadowButton}
        disableButton={disableButton}
        translate={translate}
        fixedDataFilter={fixedDataFilter}
      >
        {filter.map(item => factoryFilter(item))}
      </FilterWrapControl>

      <WrapActiveParameters
        translate={translate}
        disableButtonClear={Boolean(filter.find(item => item.result.length))}
        clearFilter={clearFilter}
      >
        {filter.map(item => item.hideFilter ? null : <Col
          key={`Result-${item.section}`}
          {...item}
          translate={translate}
          notTranslating={item.notTranslating}
          onStartFilter={onStartFilter}
        />)
        }
      </WrapActiveParameters>

      {fixedDataFilter && activeFilter && type !== 'notice' &&
        <button
          className={`filter-control__clip${stringFixedDataFilter ? ' active' : ''}`}
          onClick={onClick}
        >
          {translate('fixed options of filter')}
        </button>}
    </div>
  )
}

export default observer(FilterControl)
