import { colorsStatus } from '../utils'

export const C_SELECTED_FARM_LOCAL_STORAGE_KEY = 'selectedFarmId'

// параметры - счётчики времени
export const C_TIME_COUNTER_REGISTERS = [
  'DAY_WORK_TIME',
  'DAY_WORK_TIME_DEVICE',
  'DISP_COUNT_TIME_SHARPENING',
  'INC_SUMM_CHASSIS',
  'INC_SUMM_IRS',
  'INC_SUMM_MOLOT',
  'INC_SUMM_MOTOR',
  'INC_SUMM_WORK_TIME',
  'SUMM_CHASSIS',
  'SUMM_IRS',
  'SUMM_MOLOT',
  'SUMM_MOTOR',
  'SUMM_WORK_TIME',
  'SUMM_WORK_TIME_DEVICE',
  'TO_10',
  'TO_1000',
  'TO_1500',
  'TO_250',
  'TO_50',
  'TO_500',
]

export const C_SKRP_STATUS_NAME = 'SKRP_STATUS'

export const C_SKRP_STATUSES_MAP: Record<number, string> = {
  0: 'Deactivated',
  1: 'Activated in the automatic NC hold mode',
  2: 'Activated in the mode of automatic maintenance of the cut height',
  3: 'Activated in the automatic pressure maintenance mode',
}

export const C_STATUES = {
  0: {
    color: colorsStatus['M0'],
    title: 'M0',
  },
  1: {
    color: colorsStatus['M1'],
    title: 'M1',
  },
  2: {
    color: colorsStatus['M2'],
    title: 'M2',
  },
  3: {
    color: colorsStatus['M3'],
    title: 'M3',
  },
  4: {
    color: colorsStatus['M4'],
    title: 'M4',
  },
  5: {
    color: colorsStatus['M5'],
    title: 'M5',
  },
  6: {
    color: colorsStatus['M6'],
    title: 'M6',
  },
} as const

export const enum MeasureType {
  Volume = 'л'
}
