import { icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'

const { WarningIcon } = icons

type TProps = {
  className?: string
  iconColor?: string
  message?: string
}

export default function NoteMessage({
  className,
  iconColor = '#FF9900',
  message,
}: TProps) {
  const t = useTranslate()

  if(!message) {
    message = t('data for current day')
  }

  return <NoteMessageRoot className={className}>
    <WarningIconStyled color={iconColor} />
    {message}
  </NoteMessageRoot>
}

const NoteMessageRoot = styled.div`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  align-items: center;
  color: #FF9900;
  margin-left: 14px;
  display: flex;
  margin-top: 4px;
`

const WarningIconStyled = styled(WarningIcon)`
  margin-right: 6px;
`
