import { Badge, Button, icons } from '@agdt/agrotronic-react-components'
import { flatten, length, pipe, prop, uniqBy, values } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { setFilter as setFilterAction } from 'reducers/notification/actions'
import { getStore } from 'stores/storesRegistry'
import styled, { css } from 'styled-components'

const { FilterListIcon } = icons

type TFilterCount = {
  filterCount: number
}

type TProps = {
  isDemo: boolean
  onClick: () => void
  setFilter: (filter: {}) => void
} & TFilterCount

const FilterButton = ({ onClick, filterCount, setFilter, isDemo }: TProps) => {
  const t = useTranslate()
  const noticeStore = getStore('notices')

  const clearFilter = () => {
    setFilter({})
    noticeStore.setFilter({})
  }

  return (
    <FilterButtonWrapper>
      <ButtonStyled
        variant={filterCount ? 'primary' : 'border'}
        width="auto"
        onClick={onClick}
        filterCount={filterCount}
        disabled={isDemo}
      >
        {filterCount
          ? <Badge count={filterCount} size={26} />
          : <FilterListIcon color="#A1A1A1" size={24} />
        }

        <RightIconText filterCount={filterCount}>
          {t('Filter')}
        </RightIconText>
      </ButtonStyled>

      {Boolean(filterCount) && <FilterClear onClick={clearFilter}>{t('clear')}</FilterClear>}
    </FilterButtonWrapper>
  )
}

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ButtonStyled = styled(Button)<TFilterCount>`
  ${({ filterCount }) =>
    filterCount &&
    css`
      background-color: rgba(209, 0, 41, 0.12);
      &:hover span {
        color: #ffffff;
      }
    `}
`

const FilterClear = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #D10029;
  cursor: pointer;
  margin-left: 15px;
`

const RightIconText = styled.span<TFilterCount>`
  padding-left: 10px;
  color: ${({ filterCount }) => filterCount ? '#D10029' : '#202020'};
  font-size: 14px;
  line-height: 18px;
`

//@ts-expect-error
const mapStateToProps = state => ({
  filterCount: Object.entries(state.notification.filter).reduce(
    (acc, [key, value]) => {
      if(key === 'machine') {
        //@ts-expect-error
        return acc + pipe(values, flatten, uniqBy(prop('id')), length)(value)
      }

      //@ts-expect-error
      return acc + value.length
    }, 0),

  isDemo: state.user.demoMode,
})

const mapDispatchToProps = {
  setFilter: setFilterAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterButton)
