import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { isEmpty } from 'ramda'
import { TErrorStatus } from '../../containers/Tracking/GeoZones/constants'
import { getStore } from '../storesRegistry'

export default class MapDisplaySettings {
  @observable currentZoneUnitsIds: string[] = []
  @observable selectedUnitsIds: string[] = []
  @observable rawData = null
  @observable errorStatus: TErrorStatus | null = null

  constructor() {
    makeObservable(this)

    reaction(
      () => this.selectedUnitsIds,
      (selectedUnitsIds, prevSelectedUnitsIds) => {
        if(isEmpty(selectedUnitsIds)) {
          this.setErrorStatus('IS_EMPTY')
        } else if(!isEmpty(prevSelectedUnitsIds) || this.errorStatus !== null) {
          this.setErrorStatus('CHANGED')
        }
      },
    )
  }

  @computed
  get selectedUnitsIdsNums() {
    return this.selectedUnitsIds.map(ID => Number(ID))
  }

  @computed
  get selectedUnits() {
    const units = getStore('dictionaries').targets2.unitsWithRegistersData
    return units.filter(unit => this.currentZoneUnitsIds.includes(String(unit.id)))
  }

  @action.bound
  setErrorStatus(status: TErrorStatus) {
    this.errorStatus = status
  }

  @action.bound
  setCurrentZoneUnitsIds(ids: string[]) {
    this.currentZoneUnitsIds = ids
  }

  @action.bound
  setSelectedUnitsIds(ids: string[]) {
    this.selectedUnitsIds = ids
  }

  @action.bound

  //@ts-expect-error
  setRawData(data) {
    this.rawData = data
  }
}
