import { Tooltip } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { SortParam } from 'reducers/settings/state'
import { AppStateType } from 'reducers/store'
import { TTranslate } from 'types'
import { sortMachine } from '../../reducers/settings/actions'
import TooltipD from '../Tooltip'
import { ResultItem, ResultItemType } from './Item'

const styles = {
  tooltips: {
    agriculture: {
      left: '-2px',
    },
    thCell: {
      left: '-8px',
    },
    watching: {
      left: '-20px',
    },
  },
}

const colorsStatus = [
  { color: '#BCBCBC', title: 'M0' },
  { color: '#06a9f5', title: 'driving_mode' },
  { color: '#202020', title: 'M2' },
  { color: '#d10a40', title: 'work_engine_in_no_movement' },
  { color: '#78C800', title: 'harvest' },
  { color: '#d61fd6', title: 'M5' },
  { color: '#F37203', title: 'M6' },
]

type StatusHelperProps = {
  color: string
  title: string
  translate: TTranslate
}

export const StatusHelper: FC<StatusHelperProps> = ({ color, title, translate }) =>
  <div className="machine-list__status-legend-item" key={color}>
    <div
      className="machine-list__status-legend-color"
      style={{ backgroundColor: color }}
    />
    <div className="machine-list__status-legend-title">{translate(title)}</div>
  </div>

type HeaderTableProps = {
  param: SortParam | null
  type?: boolean
  translate: TTranslate
  onSortMachine: (value: SortParam) => void
}

const HeaderTable: FC<HeaderTableProps> = ({ onSortMachine, param, type, translate }) =>
  <thead>
    <tr>
      <th>
        <span>{translate('machine')}</span>
        <TooltipD text={translate('Vechicle info')}
          style={styles.tooltips.thCell}/>
      </th>
      <th
        onClick={() => onSortMachine('FIRST_MOTOR')}
        className={`sort ${param === 'FIRST_MOTOR' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <span>{translate('inclusion_DVS')}</span>
        <TooltipD text={translate('The time of the first start of the engine for the current day')}
          style={styles.tooltips.thCell}/>
      </th>
      <th
        onClick={() => onSortMachine('ACTIVE')}
        className={`sort ${param === 'ACTIVE' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <span>{translate('last_data')}</span>
        <TooltipD text={translate('Date and time of the last communication session')}
          style={styles.tooltips.thCell}/>
      </th>
      <th
        onClick={() => onSortMachine('TIME')}
        className={`sort ${param === 'TIME' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <Tooltip placement="top" title={translate('For the current day')}>
          <span>{translate('activity')} <font style={{ color: '#ff0000', fontSize: '10px' }}>*</font></span>
        </Tooltip>
        <TooltipD
          text={translate('Pie chart modes of operation for the current day')}
          style={styles.tooltips.thCell}
        />
      </th>
      <th
        onClick={() => onSortMachine('SPEED')}
        className={`sort ${param === 'SPEED' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <span>{translate('speed_at_now')}</span>
        <TooltipD
          text={translate('speed_at_now')}
          style={styles.tooltips.thCell}
        />
      </th>
      <th
        onClick={() => onSortMachine('AVER_SPEED_COMBINE')}
        className={`sort ${param === 'AVER_SPEED_COMBINE' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <span>{translate('average_speed_move_in_harvest')}</span>
        <TooltipD
          text={
            translate('Average speed of equipment in the mode of combining / working for the current day')
          }
          style={styles.tooltips.thCell}
        />
      </th>
      <th
        onClick={() => onSortMachine('INC_SUMM_FUEL')}
        className={`sort ${param === 'INC_SUMM_FUEL' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <Tooltip placement="top" title={translate('For the current day')}>
          <span>{translate('expense_of_fuel')} <font style={{ color: '#ff0000', fontSize: '10px' }}>*</font></span>
        </Tooltip>
        <TooltipD
          text={translate('Fuel consumption for the current day')}
          style={styles.tooltips.thCell}
        />
      </th>
      <th
        onClick={() => onSortMachine('INC_SUMM_AREA')}
        className={`sort ${param === 'INC_SUMM_AREA' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <Tooltip placement="top" title={translate('For the current day')}>
          <span>{translate('area_cleaned')} <font style={{ color: '#ff0000', fontSize: '10px' }}>*</font></span>
        </Tooltip>
        <TooltipD
          text={translate('Cleaned / treated area for the current day')}
          style={styles.tooltips.thCell}
        />
      </th>
      <th
        onClick={() => onSortMachine('AREA_PER_TIME')}
        className={`sort ${param === 'AREA_PER_TIME' ? 'active' : ''} ${
          type ? 'max' : 'min'
        }`}
      >
        <Tooltip placement="top" title={translate('For the current day')}>
          <span>{translate('performance')} <font style={{ color: '#ff0000', fontSize: '10px' }}>*</font></span>
        </Tooltip>
        <TooltipD
          text={translate('Productivity of technology for the current day')}
          style={styles.tooltips.thCell}
        />
      </th>
      <th>
        <span style={{ marginRight: '5px' }}>{translate('agriculture')}</span>
        <TooltipD
          text={translate('agriculture')}
          style={styles.tooltips.agriculture}
        />
      </th>
      <th className="col-text-full" />
    </tr>
  </thead>

type FilterResultProps = {
  number: number
  filtration: boolean
  result: ResultItemType[]
  units: ResultItemType[]
  scrollComponent: HTMLElement | null
  scroll?: number | null
  heightFilter: number
}

export const FilterResult: FC<FilterResultProps> = ({
  number,
  filtration,
  result,
  units,
  scrollComponent,
  scroll,
  heightFilter,
}) => {
  const translate = useTranslate()
  const tableRef = useRef<HTMLTableElement>(null)
  const tableFix = useRef<HTMLTableElement>(null)
  const stateDispatch = useDispatch()
  const [more, setMore] = useState(true)
  const [load, setLoad] = useState(false)
  const [fixed, setFixed] = useState(false)
  const [machineList, setMachineList] = useState<ResultItemType[]>([])
  const [numberLoad, setNumberLoad] = useState(0)
  const settings = useSelector((state: AppStateType) => state.settings.machine)

  const onSortMachine = (value: SortParam) => {
    stateDispatch(sortMachine(value))
  }

  const sortActive = (list: ResultItemType[]) => {
    return list.sort((prev, next) => {
      if(!next.data?.ACTIVE?.value) {
        return -10000000
      }

      if(settings.sort.min) {
        if(prev.data?.ACTIVE?.value) {
          return next.data.ACTIVE.value - prev.data.ACTIVE.value
        }

        return 0
      } else {
        if(prev.data?.ACTIVE?.value) {
          return -(next.data.ACTIVE.value - prev.data.ACTIVE.value)
        }

        return 0
      }
    })
  }

  const sortFirstMotor = (list: ResultItemType[]) => {
    return list.sort((prev, next) => {
      if(!next.data?.FIRST_MOTOR?.value) {
        return -1000000000
      }

      if(settings.sort.min) {
        if(prev.data?.FIRST_MOTOR?.value) {
          return next.data.FIRST_MOTOR.value - prev.data.FIRST_MOTOR.value
        }

        return 1000
      } else {
        if(prev.data?.FIRST_MOTOR?.value) {
          return -(next.data.FIRST_MOTOR.value - prev.data.FIRST_MOTOR.value)
        }

        return 1
      }
    })
  }

  const sortTime = (list: ResultItemType[]) => {
    return list.sort((prev, next) => {
      const prevActive =
        (prev.data?.TIME_MOVE_NO_HARVEST?.value || 0) * 1000000 +
        (prev.data?.TIME_OFF?.value || 0) / 10000 +
        (prev.data?.TIME_STOP_DON?.value || 0) * 10 +
        (prev.data?.TIME_HARVEST?.value || 0) * 100000000000

      const nextActive =
        (next.data?.TIME_MOVE_NO_HARVEST?.value || 0) * 1000000 +
        (next.data?.TIME_OFF?.value || 0) / 10000 +
        (next.data?.TIME_STOP_DON?.value || 0) * 10 +
        (next.data?.TIME_HARVEST?.value || 0) * 100000000000

      if(settings.sort.min) {
        return -(prevActive - nextActive)
      }

      return prevActive - nextActive
    })
  }

  const defaultSort = (
    list: ResultItemType[],
    param: 'SPEED' | 'AVER_SPEED_COMBINE' | 'INC_SUMM_FUEL' | 'INC_SUMM_AREA' | 'AREA_PER_TIME',
  ) => {
    return list.sort((prev, next) => {
      const prevValue = prev.data?.[param]?.value || 0
      const nextValue = next.data?.[param]?.value || 0

      if(settings.sort.min) {
        return -(prevValue - nextValue)
      } else {
        return prevValue - nextValue
      }
    })
  }

  const sortList = (list: ResultItemType[]) => {
    const param = settings.sort.param

    if(param === 'ACTIVE') {
      return sortActive(list)
    } else if(param === 'FIRST_MOTOR') {
      return sortFirstMotor(list)
    } else if(param === 'TIME') {
      return sortTime(list)
    } else if(
      param === 'SPEED' ||
      param === 'AVER_SPEED_COMBINE' ||
      param === 'INC_SUMM_FUEL' ||
      param === 'INC_SUMM_AREA' ||
      param === 'AREA_PER_TIME'
    ) {
      return defaultSort(list, param)
    }

    return list
  }

  const nextNumberLoad = () => {
    const list = filtration
      ? sortList(result)
      : sortList(units)

    if(!load && machineList.length <= numberLoad && list.length) {
      setMachineList(list.filter((_item, index) => index < numberLoad + 30))
      setNumberLoad(numberLoad + 30)
      setLoad(true)


      if(numberLoad + 30 >= list.length) {
        setMore(false)
      }
    }
  }

  const getScrollComponent = () => {
    return scrollComponent
  }

  useEffect(()=> {
    setLoad(false)
  }, [machineList])

  useEffect(() => {
    setNumberLoad(0)
    setMachineList([])
    setMore(true)
    setLoad(false)

    nextNumberLoad()
  }, [result, units, settings.sort])

  useEffect(()=>{
    if(scroll && scroll < 0) {
      if(tableFix.current) {
        tableFix.current.style.top = `${heightFilter}px`
      }

      if(tableRef.current?.tHead && tableFix.current?.tHead) {
        const cellsResult = Array.from(tableRef.current.tHead.rows[0].cells)
        const cellsFix = Array.from(tableFix.current.tHead.rows[0].cells)


        cellsResult.forEach((elem, index) => {
          cellsFix[index].style.width = `${elem.clientWidth}px`
        })
      }

      setFixed(true)
    } else {
      setFixed(false)
    }
  }, [scroll])

  return (
    <div className="machine-list result-filter">
      <div className="result-filter__size machines-filter">
        <span className="result-filter__size-text">
          {translate('found')}:{' '}
          <span className="result-filter__size-number">
            {number}
          </span>
        </span>
        <span className="machine-list__status-legend">
          {colorsStatus.map(item =>
            <StatusHelper
              key={item.title}
              title={item.title}
              color={item.color}
              translate={translate}
            />,
          )}
        </span>
      </div>
      <div style={{ paddingLeft: '10px' }}>
        <span style={{ color: '#ff0000', fontSize: '10px' }}>*</span> - {translate('data for current day')}
      </div>
      <div className="result-filter__list">
        <table
          className="result-table"
          ref={tableRef}
        >
          <HeaderTable
            onSortMachine={onSortMachine}
            param={settings.sort.param}
            type={settings.sort.min}
            translate={translate}
          />
          <InfiniteScroll
            element="tbody"
            pageStart={0}
            hasMore={more}
            threshold={500}
            loadMore={nextNumberLoad}
            useWindow={false}
            getScrollParent={getScrollComponent}
          >
            {machineList.map(item =>
              <ResultItem key={item.id} {...item} />,
            )}
          </InfiniteScroll>
        </table>
        <table
          className={`fixed-table${fixed ? ' active' : ''}`}
          ref={tableFix}
        >
          <HeaderTable
            onSortMachine={onSortMachine}
            param={settings.sort.param}
            type={settings.sort.min}
            translate={translate}
          />
        </table>
      </div>
    </div>
  )
}
