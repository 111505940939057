import React, { useCallback, useRef } from 'react'
import { Map } from 'react-leaflet'
import { connect } from 'react-redux'
import { VariableSizeList as List } from 'react-window'
import { getFilteredStatusesModelsHouseholds } from 'reducers/users/selectors'
import styled from 'styled-components'
import { TAccount, TUserUnit } from 'types'
import DimensionsMeasurer from '../../../components/DimensionsMeasurer'
import { HouseHoldItem } from './houseHoldItem'

const C_ROW_HEIGHT = 50

type Household = TAccount & {
  units: TUserUnit[]
}

type TProps = {
  filteredHouseholds: Household[]
  mapRef: Map
}

const HouseHoldsListFC = ({ filteredHouseholds, mapRef }: TProps) => {
  const listRef = useRef<List>(null)
  const heightMap = React.useRef<number[]>([])

  const handleSetRowHeights = useCallback((index: number, size: number) => {
    if(!listRef.current) {
      return
    }

    const totalRowSize = size + C_ROW_HEIGHT

    if(totalRowSize > C_ROW_HEIGHT && totalRowSize !== heightMap.current[index]) {
      heightMap.current[index] = totalRowSize
      listRef.current.resetAfterIndex(index)
    } else if(totalRowSize === C_ROW_HEIGHT){
      heightMap.current[index] = C_ROW_HEIGHT
      listRef.current.resetAfterIndex(index)
    }
  }, [])

  const getHeight = useCallback(index => heightMap.current[index] || C_ROW_HEIGHT, [])

  return <Container>
    {(dimension: { height: number, width: number }) => <List
      height={dimension.height}
      itemCount={filteredHouseholds.length}

      itemData={{
        filteredHouseholds,
        handleSetRowHeights,
        mapRef,
      }}

      itemSize={getHeight}
      ref={listRef}
    >
      {HouseHoldItem}
    </List>}
  </Container>
}

export const HouseHoldsList = connect(state => ({
  filteredHouseholds: getFilteredStatusesModelsHouseholds(state),
}))(HouseHoldsListFC)

const Container = styled(DimensionsMeasurer)`
  height: 100%;
`

