import { hooks } from '@agdt/agrotronic-react-components'
import { put, select, take } from 'redux-saga/effects'
import { getCalendarProperties, getCalendarValue } from '../../services/apiService/modules'
import { errorMessage } from '../../services/notification'
import { NotificationActionTypeEnum } from '../notification/actions'
import * as notificationActions from '../notification/actions'

const getLangFromLocalStorage = () => {
  return hooks.getLang()
}


/*@ts-expect-error*/
const getStartOfMonth = (year, month) => new Date().user.new(new Date(year, month - 1)).utc

/*@ts-expect-error*/
const getEndOfMonth = (year, month) => new Date().user.new(new Date(year, month, 0, 23, 59, 59, 999 )).utc

export function* notificationDataSaga() {
  while(true) {
    try {
      yield take([
        NotificationActionTypeEnum.SET_FILTER,
        NotificationActionTypeEnum.SET_DATE_RANGE,
        NotificationActionTypeEnum.SET_SELECTED_MACHINE,
      ])

      // TODO delete at all when be sure in mobx solution
      continue
    } catch(error) {
      errorMessage(error)
      console.log(error)
    } finally {
      /*@ts-expect-error*/
      yield put(notificationActions.changeLoadingNotifications(false))
    }
  }
}

export function* notificationCalendarSaga() {
  while(true) {
    try {
      yield take([NotificationActionTypeEnum.SET_REGISTER_DATA, NotificationActionTypeEnum.SET_CURRENT_REGISTER])
      const { notification, user } = yield select()

      /*@ts-expect-error*/
      yield put(notificationActions.changeLoadingCalendarPainting(true))

      const GMT = user.info.gmt
      let register = notification.register
      let currentRegister = notification.currentRegister
      const { year, month } = notification.registerData
      let selectedMachine = notification.filter.machine

      if(!selectedMachine || selectedMachine.length !== 1) {
        yield put(notificationActions.clearRegister())
      } else {
        if(register == null) {
          /*@ts-expect-error*/
          const propertiesResponse = yield getCalendarProperties({
            imei: Number(selectedMachine[0].imei),
            lang: getLangFromLocalStorage(),
          })

          const normalizePropertiesResponse = Object.entries(
            propertiesResponse,
          ).map(([key, value]) => {
            /*@ts-expect-error*/
            value.type = key
            return value
          })

          yield put(notificationActions.setRegister(normalizePropertiesResponse))

          if(currentRegister == null) {
            currentRegister = normalizePropertiesResponse[0]

            yield put(
              notificationActions.setCurrentRegister(
                normalizePropertiesResponse[0],
              ),
            )
          }
        }

        /*@ts-expect-error*/
        const json = yield getCalendarValue({
          from                 : getStartOfMonth(year, month),
          imei                 : Number(selectedMachine[0].imei),
          register             : currentRegister.type,
          timezoneOffsetMinutes: GMT * 60,
          to                   : getEndOfMonth(year, month),
        })

        if(json && json[currentRegister.type]) {
          yield put(notificationActions.setRegisterValue(json[currentRegister.type]))
        }
      }
    } catch(err) {
      console.log(err)
    } finally {
      /*@ts-expect-error*/
      yield put(notificationActions.changeLoadingCalendarPainting(false))
    }
  }
}
