import { Button, icons, Spinner } from '@agdt/agrotronic-react-components'
import { LANG_RU } from 'constants/lang'
import { GMT_MOSCOW } from 'constants/time'
import { useUserTimezone } from 'hooks'
import { compose, isEmpty, pipe, propOr } from 'ramda'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { clearReport, pushCleaningReport, toogleCleaningReport } from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppDispatch, AppStateType } from 'reducers/store'
import { ThunkAction } from 'redux-thunk'
import { getHarvesting } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { TTranslate } from 'types'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter, { TFilterCallbackData } from 'ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { CleaningResult } from '../result/cleaning'
import { createExcelFile, TReport } from './ToExcellHandler'

type TProps = FilterControlConfig & {
  report: Map<string, TReport>
  deleteParameters: () => void
  dispatch: () => void
  translate: TTranslate
}

const ReportCleaning = (props: TProps) => {
  const [reportIsLoading, setReportIsLoading] = useState(false)
  const filterIsEmpty = useMemo(() => isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items), [])
  const report = props.report.get('report')

  const exportToExcelHandler = () => {
    const { from, to } = props.filter[1].result[0]

    setReportIsLoading(true)

    createExcelFile({
      from,
      login: props.info?.operatorLogin || props.info?.login || '',

      // @ts-expect-error
      report: report?.targets || {},
      t     : props.translate,
      to,
    })
      .catch(() => errorMessage(props.translate('Error creating file')))
      .finally(() => { setReportIsLoading(false) })
  }

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={props.translate('Select a different report or change the filtering conditions')}
        title={props.translate('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          disableButton={Boolean(props.report.get('load'))}
          {...props}
        />
        {props.report.get('load') && <CenteringWrapper><Spinner /></CenteringWrapper>}

        {report && <CenteringWrapper>
          <ExportButton
            variant="border"
            disabled={reportIsLoading}
            height="24"
            onClick={reportIsLoading ? undefined : exportToExcelHandler}
          >
            <>
              {reportIsLoading ? <Spinner color="#D5D2D2" size={24} /> : <icons.XlsIcon size="24" color="#32BE6E"/>}
              <TitleButton>{props.translate('Download')}</TitleButton>
            </>
          </ExportButton>
        </CenteringWrapper>}
        {/* @ts-expect-error */}
        {report && <CleaningResult report={report} />}
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default compose(
  connect(
    (state: AppStateType) => ({
      report: state.reports.get('cleaning'),
    })),

  InitFilter({
    default: [
      {
        filter  : 'ZUK',
        id      : 0,
        items   : [],
        name    : 'machine',
        result  : [],
        section : 'machine',
        type    : 'checkbox-machine',
        validate: true,
      },
      {
        id      : 2,
        name    : 'select_a_date',
        result  : [],
        section : 'time',
        type    : 'date',
        validate: true,
      },
    ],
    callback: (data: TFilterCallbackData) : ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) => {
      const { getDateWithUserTimeZone } = useUserTimezone()
      dispatch(toogleCleaningReport())

      const gmt = data.gmt ?? GMT_MOSCOW
      const formatDateTime = pipe(Date.parseDateTimeFromCalendarApi, getDateWithUserTimeZone)

      const query = {
        from                 : formatDateTime(data.time[0].from),
        imeis                : data.machine.map(item => Number(item.imei)),
        lang                 : (data.lang || LANG_RU).toUpperCase(),
        timezoneOffsetMinutes: gmt * 60,
        to                   : formatDateTime(data.time[0].to),
      }

      getHarvesting<unknown, {}>(query)
        .then(json => {
          dispatch(toogleCleaningReport())

          if(json) {
            dispatch(pushCleaningReport(json))
          }
        })
        .catch(error => {
          dispatch(toogleCleaningReport())

          if(error.message) {
            const errorText = propOr('error', 'message', JSON.parse(error.message))
            errorMessage(errorText)
          }
        })
    },

    clear: (): ThunkAction<void, AppStateType, void> => (dispatch: AppDispatch) => {
      dispatch(clearReport(ReportType.Cleaning))
    },
  }),

  //@ts-expect-error
)(ReportCleaning)

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ExportButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
  margin-top: 10px;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding-left: 4px;
`
