import Tippy from '@tippyjs/react'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export type TProps = {
  disabled: boolean
  errorText: string
  handleWatch: () => void
  isActive: boolean
}

export const WatchButton = ({
  disabled,
  errorText,
  handleWatch,
  isActive,
}: TProps) => {
  const handleClick = useCallback(() => {
    if(!disabled) {
      handleWatch()
    }
  }, [])

  return <Tippy
    disabled={!disabled}
    content={errorText}
    maxWidth={255}
    placement='bottom-end'
  >
    <StyledLink
      isActive={isActive}
      isDisabled={disabled}
      onClick={handleClick}
    />
  </Tippy>
}

const StyledLink = styled.div<{isActive: boolean, isDisabled: boolean}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: url("/img/svg/item-watch.svg") no-repeat center;
  background-color: ${
  ({ isActive, isDisabled }) => isDisabled ? 'rgba(32, 32, 32, 0.1)' : isActive ? '#78C800' : '#BDBDBD'
};
  
  cursor: ${({ isDisabled }) => isDisabled ? 'unset' : 'pointer'};
  border: 0;
  outline: 0;
  transition: 0.2s all ease-in-out;
  
  &:hover {
    background-color: ${({ isDisabled }) => isDisabled ? 'rgba(32, 32, 32, 0.1)' : '#78C800'};
  }
`
