import { action, makeObservable, observable } from 'mobx'
import { requestPredictUnloading } from 'queries/request'
import {
  FilingAndDrainMarkersInfo,
  LoadingMarkersInfo,
  MarketResultItem,
  normalizeEventMarkersResult,
  NotificationMarkersInfo,
  PredictUnloadingMarkersInfo,
  requestGetEvents,
  requestGetFillingDrains,
  requestGetNotice,
  requestGetStopIntervals,
  StopIntervalMarkerInfo,
} from 'queries/trackEvents'
import { isNil } from 'ramda'
import { demoRequest } from 'services/apiService/demoQueries'
import { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'

// @ts-ignore
const todayFrom = new Date().startOf('day').valueOf()

type Selected = {
  filingAndDrain : boolean
  loading : boolean
  notification : Set<number>
  predictUnloading: boolean
  stopIntervals : Set<string>
  unloading : boolean
}

type ToggleSelected = {
  (markerName: 'notification', params?: number): void
  (markerName: 'stopIntervals', params?: string): void
  (markerName: keyof Selected, params?: number | string | null): void
}

type Marker = {
  loading?: Required<LoadingMarkersInfo>[]
  unloading?: Required<LoadingMarkersInfo>[]
  notification?: Required<NotificationMarkersInfo>[]
  stopIntervals?: Record<string, Required<StopIntervalMarkerInfo>[]>
  filingAndDrain?: Required<FilingAndDrainMarkersInfo>[]
  predictUnloading?: PredictUnloadingMarkersInfo
}

export default class MarkersStore {
  @observable value?: Marker
  @observable selected: Selected = {
    filingAndDrain  : false,
    loading         : false,
    notification    : new Set<number>(),
    predictUnloading: false,
    stopIntervals   : new Set<string>(),
    unloading       : false,
  }

  constructor() {
    makeObservable(this)
  }

  @action.bound

  setMarkers(markers: Marker){
    this.value = markers
  }

  @action.bound
  toggleSelected: ToggleSelected = (markerName: keyof typeof this.selected, param?: number | string | null) => {
    if(markerName === 'notification' || markerName === 'stopIntervals') {
      if(isNil(param)) {
        const set = this.selected[markerName]

        set.clear()

        // не мутируем данные, чтобы перерисовывались компоненты, которые зависят от selected
        // т.к. selected это объект, то чтобы observable сработал корректно надо чтобы поменялась ссылка на объект
        this.selected = {
          ...this.selected,
          [markerName]: set,
        }
      } else {
        const set: Set<string | number> = this.selected[markerName]

        set[set.has(param) ? 'delete' : 'add'](param)

        // не мутируем данные, чтобы перерисовывались компоненты, которые зависят от selected
        // т.к. selected это объект, то чтобы observable сработал корректно надо чтобы поменялась ссылка на объект
        this.selected = {
          ...this.selected,
          [markerName]: set,
        }
      }
    } else {
      // не мутируем данные, чтобы перерисовывались компоненты, которые зависят от selected
      // т.к. selected это объект, то чтобы observable сработал корректно надо чтобы поменялась ссылка на объект
      this.selected = {
        ...this.selected,
        [markerName]: !this.selected[markerName],
      }
    }
  }

  //@ts-expect-error
  private checkPermission = (from, to) => {
    const config = getStore('context').user.config

    if(!config || !config.NOTICES_PERIOD) {
      return true
    }

    const periodNoticesMS = (config.NOTICES_PERIOD + 1) * (1000 * 60 * 60 * 24)
    return periodNoticesMS > to - from
  }

  //@ts-expect-error
  requestMarkersData = async (from, to, imei) => {
    if(getStore('context').isDemo) {
      this.setMarkers(await demoRequest('trackMarkers'))
    } else {
      let result = []
      const gmt = getStore('context').gmt
      const lang = getStore('context').lang
      const translate = getStore('context').t
      const targets2 = getStore('dictionaries').targets2.value

      //@ts-expect-error
      const model = targets2[imei].subtype

      try {
        result.push({
          type: 'fueling', data: await requestGetFillingDrains({
            from,
            gmt,
            imei: Number(imei),
            to,
          }),
        })
      } catch(err) {
        errorMessage(translate('service is not available'))
        console.error(err)
      }

      if(this.checkPermission(from, to)) {
        try {
          result.push({
            data: await requestGetNotice({
              from,
              gmt,
              imei,
              lang,
              to,
            }),

            type: 'notifications',
          })
        } catch(err) {
          errorMessage(translate('service is not available'))
          console.error(err)
        }

        try {
          result.push({ type: 'events', data: await requestGetEvents(imei, from, to) })
        } catch(err) {
          errorMessage(translate('service is not available'))
          console.error(err)
        }
      }

      try {
        result.push({
          type: 'stopsIntervals',
          data: await requestGetStopIntervals({
            from,
            gmt,
            imei,
            to,
          }),
        })
      } catch(err) {
        errorMessage(translate('service is not available'))
        console.error(err)
      }

      if(from === todayFrom && model === 'ZUK') {
        try {
          result.push({ data: await requestPredictUnloading(imei), type: 'predictUnloading' })
        } catch(err) {
          errorMessage(translate('service is not available'))
          console.error(err)
        }
      }

      const normalizeResult = normalizeEventMarkersResult(result as MarketResultItem[])

      this.setMarkers({
        filingAndDrain  : normalizeResult.filingAndDrain,
        loading         : normalizeResult.loading,
        notification    : normalizeResult.notification,
        predictUnloading: normalizeResult.predictUnloading,
        stopIntervals   : normalizeResult.stopsIntervals,
        unloading       : normalizeResult.unloading,
      })
    }
  }
}
