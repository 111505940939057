import { formats } from '@agdt/agrotronic-react-components'
import { action, configure, makeObservable, observable } from 'mobx'
import TextInputStore from 'stores/formFieldsStores/TextInputStore'

configure({ enforceActions: 'observed' })

type TType = 'email' | 'phone' | undefined

export default class PhoneEmailInputStore extends TextInputStore {
  // Режим вывода значения, телефон может выводиться отформатированным
  @observable showFormatted = false

  // Тип значения
  @observable type: TType

  // Отформатированное значение или нет в зависимости от режима и типа значения
  get exposedValue() {
    return !this.showFormatted && this.type === 'phone'
      ? formats.phone.phoneMask(this.value)
      : this.value
  }

  @action.bound
  onFocus() {
    this.showFormatted = true
  }

  @action.bound
  setType(type: TType) {
    this.type = type
    this.showFormatted = false
  }

  constructor(value = '') {
    super(value)
    makeObservable(this)
  }
}
