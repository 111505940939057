import React from 'react'
import styled from 'styled-components'

export type TProps = {
  children: React.ReactNode
  Icon: ({ color }: {color: string}) => React.ReactElement
  onClick: () => void
}

export default function Item({ children, Icon, onClick }: TProps) {
  return (
    <ItemElement onClick={onClick}>{Icon && <Icon color={'#A1A1A1'} />}<Caption>{children}</Caption></ItemElement>
  )
}

const Caption = styled.span`
  margin-left: 11px;
  color: #202020;
`

const ItemElement = styled.li`
  display: flex;
  align-items: center;
    
  height: 32px;
  min-width: 228px;
  padding: 4px 19px 4px 19px;
  cursor: pointer;
  
  :hover {
    background: rgba(0, 0, 0, 0.08);
  }
`
