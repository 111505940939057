import {
  HeaderRow,
  SortableHeaderCell,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled, { css } from 'styled-components'
import { scrollStyleMixin } from 'utils/styles'
import { getStore } from '../../../stores/storesRegistry'
import MapPopup from '../MapPopup'
import formatItem from './formatNotification'
import NotificationPaginator from './NotificationPaginator'

const columnsConfig = [
  { display: true, id: 'name', label: 'notifications' },
  { display: true, id: 'status', label: 'status' },
  { display: true, id: 'categoryName', label: 'category' },
  { display: true, id: 'timeSet', label: 'Appearance' },
  { display: true, id: 'timeReset', label: 'Elimination' },
  { display: true, id: 'duration', label: 'duration' },
  { display: true, id: 'position' },
]

const NotificationTable: FC = () => {
  const t = useTranslate()
  const store = getStore('notices')
  const [isOpenPopup, setOpenPopup] = useState(false)
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null)

  const showPopup = (coord: [number, number]) => {
    setOpenPopup(true)
    setCoordinates(coord)
  }

  const closePopup = () => setOpenPopup(false)

  return store.notices.isLoading ?
    <SpinnerContainer>
      <Spinner color="#D10B41" />
    </SpinnerContainer>
    : <TableWrapper>
      <Table>
        <TableHeaderStyled>
          <HeaderRow>
            {columnsConfig.map(({ id, label }, index) =>
              <SortableHeaderCellStyled
                key={id}
                id={id}
                index={index}
                sort={store.notices.list.sortField === id ? store.notices.list.sortDirectionString : null}

                /*@ts-expect-error*/
                onChangeSort={store.notices.list.setSortField}
              >
                {label != null ? t(label) : null}
              </SortableHeaderCellStyled>,
            )}
          </HeaderRow>
        </TableHeaderStyled>
        <TableBody>
          {store.notices.list.sortedItems.map(item => {
            /*@ts-expect-error*/
            const formattedItem = formatItem(item, t)

            const onClick = () => {
              if(formattedItem.position) {
                /*@ts-expect-error*/
                showPopup([formattedItem.lat, formattedItem.lon])
              }
            }

            return (
              <TableRow
                key={item.id}
                onClick={onClick}
              >
                {columnsConfig.map(({ id }, index) => {
                  /*@ts-expect-error*/
                  const item = formattedItem[id]
                  return (
                    <TableCell
                      key={id}
                      width={index === 0 ? '200px' : undefined}
                    >
                      {item}
                    </TableCell>
                  )
                },
                )}
              </TableRow>
            )
          })}
        </TableBody>
        <NotificationPaginator />
      </Table>
      {isOpenPopup && coordinates &&
        <MapPopup onCancel={closePopup} coordinates={coordinates} />
      }
    </TableWrapper>
}

const TableWrapper = styled.div`
  height: calc(100vh - 100px);
  overflow-y: auto;
  ${scrollStyleMixin}
`

const TableHeaderStyled = styled(TableHeader)`
  &&& {
    background: #ffffff;
  }
`

const SortableHeaderCellStyled = styled(SortableHeaderCell)<{index: number}>`
  ${({ index }) =>
    index === 0
      ? css`
          min-width: 200px;
        `
      : null};

  &&& {
    font-weight: bold;
    color: #202020;
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  margin: 20px;
  justify-content: center;
`

export default observer(NotificationTable)
