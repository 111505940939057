export const COLOR_MAP_ACTIVE = {
  1: '#F5F5F5',
  2: '#FCE4FF',
  3: '#FFE9E2',
  4: '#ECF0FF',
  5: '#D6FBDD',
}

export const COLOR_MAP = {
  1: '#E0E0E0',
  2: '#F7C1FE',
  3: '#FDD8CD',
  4: '#D5DDFD',
  5: '#D6FBDD',
}

export const MARGIN_LEFT_MAP = {
  1: 0,
  2: 42,
  3: 84,
  4: 126,
  5: 168,
}
