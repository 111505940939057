import DownloadBlock from 'components/Filter/DownloadBlock'
import FilterButton from 'components/Filter/FilterButton'
import DateBlockFilter from 'pages/Notification/Filter/DateBlockFilter'
import FilterModal from 'pages/Notification/Filter/FilterModal'
import React, { FC,useState } from 'react'
import styled from 'styled-components'

const Filter: FC = () => {
  const [visibleFilter, setVisibleFilter] = useState(false)

  const showFilter = () => {
    setVisibleFilter(true)
  }

  return (
    <>
      <FilterContainerStyled>
        <FilterButton onClick={showFilter} />
        <DateBlockFilter />
        <DownloadBlock />
      </FilterContainerStyled>

      <FilterModal
        setVisibleFilter={setVisibleFilter}
        visibleFilter={visibleFilter}
      />
    </>
  )
}

const FilterContainerStyled = styled.div`
  display: flex;
  min-height: 86px;
  max-height: 86px;
  border-bottom: 1px solid #E2E5EC;
  padding: 0 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`

export default Filter
