import { hooks } from '@agdt/agrotronic-react-components'
import { errorMessage } from 'services/notification'
import { getCalendarProperties, getCalendarValue } from '../services/apiService/modules'

export const CALENDAR_COLORS = [
  '#cc7777',
  '#dddd00',
  '#77cc77',
  '#77cccc',
  '#9999cc',
  '#cc77cc',
]

export type RegisterType = {
  type: number | string
  levels: number[]
  name: string
  measure?: string
}

const getLangFromLocalStorage = () => {
  return hooks.getLang().toUpperCase()
}

const getStartOfMonth = (year: number, month: number) => new Date().user.new(new Date(year, month - 1)).utc

const getEndOfMonth = (year: number, month: number) =>
  new Date().user.new(new Date(year, month, 0, 23, 59, 59, 999 )).utc

export const getCalendarPaintRegs = async (imei: string | number, callback?: Function): Promise<RegisterType[]> => {
  let regs: RegisterType[] = []

  try {
    const calendarProperties = await getCalendarProperties({
      imei: Number(imei),
      lang: getLangFromLocalStorage(),
    }) as Record<string, RegisterType>

    regs = Object.keys(calendarProperties).reduce((acc: RegisterType[], key: string) => {
      acc.push({ ...calendarProperties[key], type: key })
      return acc
    }, [])

    if(callback) {
      callback(regs)
    }

    return regs
  } catch(error) {
    console.log(error)
  }

  return regs
}

type DataArg = {
  year: string
  month: string
  register: number | string
  GMT: number
  levels: number[]
}

type CalendarData = {
  color: string
  day: string
  imei: string | number
  month: string
  options: {
      imei: string | number
      param: string | number
  }
  param: string | number
  value: number
  year: string
}

export const getCalendarPaintWithData = async (
  imei: string | number,
  data: DataArg,
  cb?: Function,
): Promise<CalendarData[]> => {
  let calendarData: CalendarData[] = []

  try {
    const calendarValue = await getCalendarValue({
      from                 : getStartOfMonth(Number(data.year), Number(data.month)),
      imei                 : Number(imei),
      register             : data.register,
      timezoneOffsetMinutes: data.GMT * 60,
      to                   : getEndOfMonth(Number(data.year), Number(data.month)),
    }) as Record<number | string, number[]>

    if(calendarValue[data.register]) {
      calendarData = calendarValue[data.register].map((value, index) => ({
        color: value
          ? data.levels.reduce(
            (acc, level, levelIndex) => value >= level ? CALENDAR_COLORS[levelIndex + 1] : acc,
            CALENDAR_COLORS[0],
          )
          : '#cccccc',

        day    : `${index + 1}`,
        imei,
        month  : data.month,
        options: { imei: imei, param: data.register },
        param  : data.register,
        value,
        year   : data.year,
      }))

      if(cb) {
        cb(data.register, calendarData)
      }

      return calendarData
    }
  } catch(error) {
    errorMessage(error)
  }

  return calendarData
}

type DateObj = {
  query: {
    year: number
    month: number
    register: number | string
    GMT: number
  }

  callback: (value: Record<string | number, unknown>) => void
}

export async function getCalendarPaintValue(imei: string | number, data: DateObj) {
  try {
    const calendarValue = await getCalendarValue({
      from                 : getStartOfMonth(data.query.year, data.query.month),
      imei                 : Number(imei),
      register             : data.query.register,
      timezoneOffsetMinutes: data.query.GMT * 60,
      to                   : getEndOfMonth(data.query.year, data.query.month),
    }) as Record<string | number, unknown>

    data.callback(calendarValue)
  } catch(error) {
    errorMessage(error)
  }
}
