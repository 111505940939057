import { Accordion } from '@agdt/agrotronic-react-components'
import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'

type TProps = {
  children: ReactElement
  title: ReactElement | string
}

export const ReportRowTitle: FC<TProps> = ({ children, title }) => <Accordion
  arrowLeft={false}
  collapsedArrowDirection="bottom"
  expandedArrowDirection="top"
  title={<Header>{title}</Header>}
  onToggle={() => {}}
  className=''
>
  {children}
</Accordion>

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  background: #F2F2F2;
  border-radius: 4px;
  font-size: 16px;
`
