import { Children, isValidElement } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { MapControl, MapControlProps, withLeaflet } from 'react-leaflet'
import { DivControl } from './DivControl'


class ControlClass<P extends MapControlProps>extends MapControl {
  createLeafletElement({ position, className }: P & { className: string }) {
    // @ts-expect-error
    this.leafletElement = new DivControl({ className, position })
    return this.leafletElement
  }

  updateLeafletElement(fromProps: P, toProps:P): void {
    super.updateLeafletElement(fromProps, toProps)
    this.renderContent()
  }

  componentDidMount() {
    if(super.componentDidMount) {
      super.componentDidMount()
      this.renderContent()
    }
  }

  componentWillUnmount() {
    const container = this.leafletElement.getContainer()

    if(container) {
      unmountComponentAtNode(container)
    }

    if(super.componentWillUnmount) {
      super.componentWillUnmount()
    }
  }

  renderContent() {
    const container = this.leafletElement.getContainer()
    const children = this.props.children

    if(children && isValidElement(children) && container) {
      render(Children.only(children), container)
    }
  }
}

const Control = withLeaflet(ControlClass)

export { Control }
