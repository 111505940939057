import { AppDispatch } from 'reducers/store'
import { TTrack } from 'types'
import { urlApi } from '../../constants/env'
import { apiServiceGet } from '../../services/apiService'

export enum UsersActionTypeEnum {
  SHOW_TRACK = 'App/Users/SHOW_TRACK',
  DELETE_TRACK = 'App/Users/DELETE_TRACK',
  PUSH_TRACK = 'App/Users/PUSH_TRACK',
  UPDATE_DATA_MACHINE = 'App/Users/UPDATE_DATA_MACHINE',
  START_LOAD_TRACK_DETAIL = 'App/Users/START_LOAD_TRACK_DETAIL',
  FLY_TRACK = 'App/Users/FLY_TRACK',
  FILTER_TYPE_USERS = 'App/Users/FILTER_TYPE_USERS',
  SET_SEARCH_HOUSEHOLD = 'App/Users/SET_SEARCH_HOUSEHOLD',
  SET_STATUSES_HOUSEHOLD = 'App/Users/SET_STATUSES_HOUSEHOLD',
  SET_MODELS_HOUSEHOLD = 'App/Users/SET_MODELS_HOUSEHOLD',
  SET_ALLOWEDS_SENSORS = 'App/Users/SET_ALLOWEDS_SENSORS',

  SUCCES_REQUEST_USERS = 'App/Machines/Request/SUCCES_REQUEST_USERS',
}

export type UsersActionsType = {
  type: UsersActionTypeEnum.SET_MODELS_HOUSEHOLD
  payload: unknown
} | {
  type: UsersActionTypeEnum.SET_STATUSES_HOUSEHOLD
  payload: unknown
} | {
  type: UsersActionTypeEnum.SET_SEARCH_HOUSEHOLD
  payload?: string
} | {
  type: UsersActionTypeEnum.SUCCES_REQUEST_USERS
  payload: unknown
} | {
  type: UsersActionTypeEnum.PUSH_TRACK
  payload: {
    imei: unknown
    data: Record<string, TTrack[]>
    demo: unknown
    today: unknown
    stat: unknown
  }
} | {
  type: UsersActionTypeEnum.FLY_TRACK
  payload: {
    imei: unknown
    data: unknown[]
  }
} | {
  type: UsersActionTypeEnum.START_LOAD_TRACK_DETAIL
  payload: {
    id: unknown
    data: unknown[]
    from: string
    to: string
  }
} | {
  type: UsersActionTypeEnum.DELETE_TRACK
  payload: {
    id: unknown
  }
} | {
  type: UsersActionTypeEnum.UPDATE_DATA_MACHINE
  payload: {
    id: unknown
    data: object
  }
} | {
  type: UsersActionTypeEnum.SET_ALLOWEDS_SENSORS
  payload: object
} | {
  type: UsersActionTypeEnum.FILTER_TYPE_USERS
  payload: {
    filteredTypeModels: unknown[]
    id: unknown
  }
}

export const pushUsers = (list: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: list, type: UsersActionTypeEnum.SUCCES_REQUEST_USERS })

export const deleteTrack = (data: {
  id: unknown
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: UsersActionTypeEnum.DELETE_TRACK })

export const startLoadTrack = (data: {
  id: unknown
  data: unknown[]
  from: string
  to: string
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: UsersActionTypeEnum.START_LOAD_TRACK_DETAIL })

export const pushTrack = (
  data: object,
  imei: number,
  stat: unknown,
  demo: unknown,
  today?: unknown,
  userId?: unknown,
) => (dispatch: AppDispatch) =>
  dispatch({ payload: { data, demo, imei, stat, today, userId }, type: UsersActionTypeEnum.PUSH_TRACK })

export const updateDataMachine = (data: {
  id: unknown
  data: object
}) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: UsersActionTypeEnum.UPDATE_DATA_MACHINE })

export const flyTrack = (data: unknown[], imei: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: { data, imei }, type: UsersActionTypeEnum.FLY_TRACK })

export const filterTypeUsers = (filteredTypeModels: unknown[], id: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: { filteredTypeModels, id }, type: UsersActionTypeEnum.FILTER_TYPE_USERS })

export const setSearchHousehold = (search: string) => (dispatch: AppDispatch) =>
  dispatch({ payload: search, type: UsersActionTypeEnum.SET_SEARCH_HOUSEHOLD })

export const setModelsHousehold = (models: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: models, type: UsersActionTypeEnum.SET_MODELS_HOUSEHOLD })

export const setStatusesHousehold = (statuses: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: statuses, type: UsersActionTypeEnum.SET_STATUSES_HOUSEHOLD })

export const setAllowedsSensors = (sensors: object) => (dispatch: AppDispatch) =>
  dispatch({ payload: sensors, type: UsersActionTypeEnum.SET_ALLOWEDS_SENSORS })

export const userLogin = (data: unknown, isDemo = false) => (dispatch: AppDispatch) => {
  if(!isDemo) {
    localStorage.removeItem('rsm-agrotronic-demo-mode')
  }

  dispatch(pushUsers(data))
}

export const userLogout = async () => {
  const isDemo = localStorage.getItem('rsm-agrotronic-demo-mode')
  localStorage.removeItem('ST')
  localStorage.removeItem('rsm-agrotronic-demo-mode')

  if(isDemo) {
    const loginUrl = new URL(`${urlApi}/cas/login`)
    loginUrl.searchParams.append('service', window.origin)
    window.location.href = loginUrl.toString()
  } else {
    apiServiceGet().redirectToLogout()
  }
}
