import { hooks } from '@agdt/agrotronic-react-components'
import React, { ReactNode } from 'react'
import { IntlProvider } from 'react-redux-multilingual'
import translations from 'translations'

export interface Props {
    children: ReactNode
}

export const LangProvider: React.FC<Props> = ({ children }): JSX.Element => {
  return <IntlProvider translations={translations} locale={hooks.getLang()}>
    {children}
  </IntlProvider>
}

export default LangProvider
