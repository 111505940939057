import { action, makeObservable, observable } from 'mobx'
import { type2sensor, type2type } from '../../containers/Tracking/GeoZones/Details/helpers'
import { loadBlobFile } from '../../queries/request'
import { getGeoZoneDetails, getSHP } from '../../services/apiService/modules'
import { errorMessage } from '../../services/notification'
import { getStore } from '../storesRegistry'
import MapBuildSettings from './MapBuildSettings'
import MapDisplaySettings from './MapDisplaySettings'

export type TDetails = {
  agriculture: string
  totalArea: number
  mapped: number
  avgYield: number
  totalYield: number
}

export default class GeoZones {
  mapBuildSettings = new MapBuildSettings()
  mapDisplaySettings = new MapDisplaySettings()

  //@ts-expect-error
  @observable selectedGeoZone
  @observable isShapeLoading: boolean = false
  @observable haveNoData: boolean = false
  @observable haveColoringData: boolean = false
  @observable productivity: Partial<TDetails> = {}
  @observable isLoadingProductivity: boolean = true

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setProductivity(geoZoneDetails: any) {
    this.productivity = geoZoneDetails
  }

  @action.bound
  setSHPisLoading(isLoading: boolean) {
    this.isShapeLoading = isLoading
  }

  @action.bound
  setIsLoadingProductivity(geoZoneDetails: any) {
    this.isLoadingProductivity = geoZoneDetails
  }

  @action.bound
  getGeoZonProductivity = async () => {
    //@ts-expect-error
    this.mapDisplaySettings.setErrorStatus(null)
    this.setIsLoadingProductivity(true)

    try {
      const geoZoneDetails = await getGeoZoneDetails({

        //@ts-ignore
        endDate  : new Date().user.new(this.mapBuildSettings.period.to).utc,
        geoZoneId: this.selectedGeoZone.id,

        // См. таску AT-3601, пояснения в комментариях
        onBoard: true,

        //@ts-ignore
        startDate: new Date().user.new(this.mapBuildSettings.period.from).utc,
        unitIds  : this.mapDisplaySettings.selectedUnitsIdsNums,
      })

      this.setProductivity(geoZoneDetails)
    } catch(e) {
      errorMessage(getStore('context').t('service is not available'))
    } finally {
      this.setIsLoadingProductivity(false)
    }
  }

  @action.bound
  setSelectedGeoZone(geoZone: any) {
    this.selectedGeoZone = geoZone
  }

  @action.bound
  setHaveColoringData(value: boolean) {
    this.haveColoringData = value
  }

  @action.bound

  //@ts-expect-error
  setHaveNoData(value) {
    this.haveNoData = value
  }

  @action.bound
  clear() {
    this.setHaveColoringData(false)
    this.mapBuildSettings = new MapBuildSettings()
    this.mapDisplaySettings = new MapDisplaySettings()
  }

  @action.bound
  clearDisplaySettings() {
    this.mapDisplaySettings = new MapDisplaySettings()
  }

  getSHPFile = async () => {
    this.setSHPisLoading(true)

    const shpData = {

      // См. таску AT-3601, пояснения в комментариях
      onBoard: true,

      //@ts-ignore
      from          : new Date().user.new(this.mapBuildSettings.period.from).utc,
      objectId      : this.selectedGeoZone.id,
      sensorTypeName: type2sensor(this.mapBuildSettings.selectedMapType),

      //@ts-ignore
      to        : new Date().user.new(this.mapBuildSettings.period.to).utc,
      type      : type2type(this.mapBuildSettings.selectedMapType),
      unitIdList: this.mapDisplaySettings.selectedUnitsIdsNums,
      width     : Number(this.mapBuildSettings.widthPart.value),
    }


    try {
      const xhr = await getSHP(shpData)

      /*@ts-expect-error*/
      loadBlobFile(xhr.response, 'shp', 'zip')
    } catch(e) {
      errorMessage(getStore('context').t('No data for the this period'))
    } finally {
      this.setSHPisLoading(false)
    }
  }
}
