import { observer } from 'mobx-react'
import { all, isEmpty, last, path } from 'ramda'
import React, { FC,useEffect, useMemo } from 'react'
import { Map } from 'react-leaflet'
import HouseholdUnitStore from 'stores/Households/HouseholdUnitStore'
import { getStore } from 'stores/storesRegistry'
import MarkerState from '../../markerState'

type MarkersProps = {
  currentMap: Map
  setNoZoomTrack: (value: boolean) => void
}

const MarkersComponent: FC<MarkersProps> = ({ currentMap, setNoZoomTrack }) => {
  const watchedUnits = getStore('householdUnits').watchedUnits
  const unitsWithTrack = getStore('householdUnits').unitsWithTrack

  const zoomMarker = () => {
    const getCoordinatesFromRegisters = ({ unit }: HouseholdUnitStore) => [
      unit.data.LATITUDE.value,
      unit.data.LONGITUDE.value,
    ] as [number, number]

    const getCoordinatesFromTrack = (unit: HouseholdUnitStore) => {
      //@ts-expect-error
      const { lat, lng } = last(last(path(['track', 'activeTrack', `${unit.track.type}`], unit)).path)
      return [lat, lng] as [number, number]
    }

    //если окончание трека не сегодня, то нужно получать данные для иконки техники из registers
    const coordinates = watchedUnits
      .map(U => isEmpty(U.track.activeTrack) || !U.track.endDateIsToday
        ? getCoordinatesFromRegisters(U)
        : getCoordinatesFromTrack(U))
      .filter(all(Boolean))

    //@ts-expect-error
    !isEmpty(coordinates) && currentMap.leafletElement.flyToBounds(coordinates, 14)
  }

  const units = useMemo(() => watchedUnits.filter(U =>
    isEmpty(U.track.activeTrack)
      || !isEmpty(U.track.activeTrack) && !U.track.endDateIsToday,
  ),
  [watchedUnits, unitsWithTrack])

  useEffect(() => {
    //Маркеры отслеживаемой техники
    if(watchedUnits.length) {
      setNoZoomTrack(true)
      setTimeout(() => setNoZoomTrack(false), 2000)
    }

    if(!isEmpty(watchedUnits)) {
      zoomMarker()
    }
  }, [watchedUnits.length])

  return (
    <>
      {units.map(U => <MarkerState
        key={`${U.imei}`}
        position={[U.unit.data.LATITUDE.value, U.unit.data.LONGITUDE.value]}
        unit={U.unit}
      />,
      )}
    </>
  )
}

export const Markers = observer(MarkersComponent)
