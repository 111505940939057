import { constants, Spinner } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import { getStore } from 'stores/storesRegistry'
import TooltipD from 'ui/Tooltip'
import { Plus } from '../../../Filter/filterControl'
import { DateOption, DayPickerRangeColorsWrapper } from '../../../Pickers/rangeDateColors'

type ControlTimeProps = {
  imei: number
}

const ControlTime:FC<ControlTimeProps> = ({ imei }) => {
  const translate = useTranslate()
  const householdUnitsStore = getStore('householdUnits')
  const unitStore = householdUnitsStore.getUnit(imei)
  const config = useSelector((state: AppStateType) => state.user.config)
  const demoMode = useSelector((state: AppStateType) => state.user.demoMode)
  const info = useSelector((state: AppStateType) => state.user.info)

  const onRequestDate = (date: {from: unknown, to: unknown}) => () => {
    const unitTrackStore = getStore('householdUnits').getUnit(imei)

    unitTrackStore.track.setTimeRange(date.from, date.to)
    unitTrackStore.track.getInitTrackData()
  }

  const checkPermission = (from: number, to: number) => {
    if(!info || !config?.NOTICES_PERIOD) {
      return true
    }

    const periodNoticesMS = (config.NOTICES_PERIOD + 1) * (1000 * 60 * 60 * 24)
    return periodNoticesMS > to - from
  }

  const handleApplyDate = (value: DateOption[]) => {
    const dateFrom = value[0].date as moment.Moment
    const timeFrom = value[0].time
    const dateTo = value[1].date as moment.Moment
    const timeTo = value[1].time
    const from = moment(`${dateFrom.format('YYMMDD')}${timeFrom.hours}${timeFrom.minute}00000`, 'YYMMDDHHmmss000')
    const to = moment(`${moment(dateTo).format('YYMMDD')}${timeTo.hours}${timeTo.minute}59000`,'YYMMDDHHmmss000')
    const firstPart = translate('Attention! Request for notifications is limited to')

    if(!checkPermission(+from, +to)) {
      alert(`${firstPart} ${config.NOTICES_PERIOD} ${translate('days Please change the request parameters')}.`)
    }

    onRequestDate({ from: from.unix() * 1000, to: to.unix() * 1000 })()
    getStore('householdUnits').getUnit(imei).togglePanel('openDate')
  }

  const togglePanel = () => {
    unitStore.togglePanel('openDate')
  }

  return (
    <div className="tracking-machine__panel">
      <div className="tracking-machine__date">
        <TooltipD
          text={translate('Select the period of the show track')}
          style={{ top: '-8px', left: '-20px' }}
        />

        <p>{translate('track')}:</p>

        {!demoMode && <button
          disabled={unitStore.track.isLoading}
          onClick={
            onRequestDate({
              from: +moment().startOf('day'),
              to  : +moment(),
            })
          }
        >
          {translate('today')}
        </button>
        }

        <button
          disabled={unitStore.track.isLoading}
          onClick={
            onRequestDate({
              from: +moment().subtract(1, 'week'),
              to  : +moment(),
            })
          }
        >
          {translate('week')}
        </button>

        {!demoMode &&
          <button
            disabled={unitStore.track.isLoading}
            onClick={togglePanel}
          >
            {translate('select_a_date')} <Plus/>
          </button>
        }

        {unitStore.track.isLoading &&
          <div style={{ marginLeft: 10 }}>
            <Spinner color={constants.colors.buttonRedColor} />
          </div>
        }
      </div>

      {unitStore.openedTrackPanels.has('openDate') &&
        <div className="tracking-machine__date-open">
          <DayPickerRangeColorsWrapper
            applyDate={handleApplyDate}
            imei={imei}
            permissions={config.REGISTERS_PERIOD}
            history={config.HISTORY}
          />
        </div>
      }

      {unitStore.track.noData &&
        <p className="tracking-machine__err">
          {translate('No data for the this period')}
        </p>
      }
    </div>
  )
}

export default observer(ControlTime)
