import '@agdt/agrotronic-jssdk'
import { apiAuthMethod,urlApi } from 'constants/env'

const { sdk } = window

export const apiServiceInit = () => {
  sdk.init({
    authMethod : apiAuthMethod,
    baseUrl    : urlApi,
    useStForGET: true,
  })
}

export const apiServiceGet = () => sdk

const getSdkModule = async <P = unknown, R = unknown>(moduleName: string): Promise<Record<string, (params?: P)=>R>> => {
  try {
    return sdk.module(moduleName)
  } catch(err) {
    await sdk.enableModule(moduleName)
    return sdk.module(moduleName)
  }
}

export const apiServiceBindModule =
<P = unknown, R = unknown>(moduleName: string) => (methodName: string) => async (params?: P): Promise<R | null> => {
    try {
      const sdkModule = await getSdkModule<P, R>(moduleName)
      return sdkModule[methodName](params)
    } catch(error) {
      console.error(error)
    }

    return null
  }
