import { hooks } from '@agdt/agrotronic-react-components'
import { action, autorun, makeObservable, observable } from 'mobx'
import { demoRequest } from 'services/apiService/demoQueries'
import { getSummary } from 'services/apiService/modules'
import { getStore } from 'stores/storesRegistry'
import { TSummaryDataRegisters } from 'types'
import { LANG_RU } from '../../constants/lang'

const { useDemoMode } = hooks

type TSummaryData = {
  registers: TSummaryDataRegisters
}

export default class SummaryStore {
  @observable value: Partial<TSummaryDataRegisters> = {}
  @observable dataLoadingError = false

  //@ts-expect-error
  private disposer

  //@ts-expect-error
  private timerLoadSummary
  constructor() {
    makeObservable(this)
  }

  @action
  setValue(value: TSummaryDataRegisters | {}) {
    this.value = value
  }

  @action
  setDataLoadingError(value: boolean) {
    this.dataLoadingError = value
  }

  @action
  async init() {
    //при изменении selectedFarmId запрашивать новые данные
    //disposer чтобы остановить autorun
    this.disposer = autorun(() => {
      this.setValue({})
      this.loadData(getStore('context').selectedFarmId)
    })

    if(!getStore('context').isDemo) {
      this.timerLoadSummary = setInterval(async () => {
        await this.loadData(getStore('context').selectedFarmId)
      }, 60000)
    }
  }

  @action
  loadData = async (selectedFarmId: number) => {
    try {
      const data: TSummaryData = useDemoMode()
        ? await demoRequest('summary')
        : await getSummary({ farmId: selectedFarmId, lang: (getStore('context').lang || LANG_RU).toUpperCase() })

      this.setValue(data.registers)
      this.setDataLoadingError(false)
    } catch(e) {
      this.setValue({})
      this.setDataLoadingError(true)
    }
  }

  @action
  dispose() {
    this.disposer()
    clearInterval(this.timerLoadSummary)
  }
}
