import { FooterRow, Pagination, TableCell,TableFooter as TableFooterComponent } from '@agdt/agrotronic-react-components'
import React, { FC, useMemo } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'

type Props = {
  itemsCount: number
  pageSize: number
  page: number
  onPageChange: (value: number) => void
  onPageSizeChange: (value: number) => void
  translate: (value: string) => string
}

const Paginator: FC<Props> = ({ itemsCount, pageSize, page, onPageChange, onPageSizeChange, translate }) => {
  const rowsPerPageSelectorList = useMemo(() => [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: translate('All'), value: Number.MAX_SAFE_INTEGER },
  ], [translate])

  const onChangePage = (newPage: number): void => {
    onPageChange(newPage - 1)
  }

  const onChangeRowsPerPage = (newCount: number): void => {
    onPageSizeChange(newCount)
  }

  if(!itemsCount) {
    return null
  }

  return (
    <TableFooter>
      <FooterRow hAlign="center">
        <TableCell maxWidth="100%">
          <PaginationContainer>
            <Pagination
              currentPage={page + 1}
              totalPages={Math.ceil(itemsCount / pageSize) || 1}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              ofLabel={translate('of')}
              rowsPerPageLabel={translate('Rows per page')}
              rowsPerPageSelectorList={rowsPerPageSelectorList}
            />
          </PaginationContainer>
        </TableCell>
      </FooterRow>
    </TableFooter>
  )
}

const component = withTranslate(Paginator)
component.displayName = 'Paginator'

export default component

const PaginationContainer = styled.div`
  display: block;
  height: 44px;
  width: 100%;
  margin-top: 10px;
  color: black;
  font-weight: 400;
`

const TableFooter = styled(TableFooterComponent)`
  flex: 0 0 80px;
  max-height: 80px;
  background: #fff;
`
