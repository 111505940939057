import React from 'react'
import { Sector } from 'recharts'

export type TProps = {
  endAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
}

export const ActiveShape = (props: TProps) =>
  <>
    <Sector
      {...props}
      innerRadius={props.innerRadius + 3}
      outerRadius={props.outerRadius - 3}
      startAngle={props.startAngle + 4}
      endAngle={props.endAngle - 4}
      cornerRadius={10}
      className='blur-sector'
    />
    <filter id="dropshadow" x="-2" y="-2" width="200" height="200">
      <feGaussianBlur stdDeviation="6"/>
    </filter>
    <Sector
      {...props}
      innerRadius={props.innerRadius - 1}
      outerRadius={props.outerRadius + 1}
      startAngle={props.startAngle + 5}
      endAngle={props.endAngle - 5}
      cornerRadius={10}
      stroke={undefined}
    />
  </>
