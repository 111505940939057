import { Button, constants, Spinner } from '@agdt/agrotronic-react-components'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import TooltipD from '../../../../ui/Tooltip'
import EyeIcon from '../assets/EyeIcon'
import {TTranslate} from "types";

const { buttonRedColor } = constants.colors

type TProps = {
  id: number
  isWatching: boolean
  loading: boolean
  name: string
  select: (id: number) => void
  onClickTrack: (id: number) => void
  translate: TTranslate
}

export const Row = ({
  id,
  isWatching,
  loading,
  name,
  select,
  onClickTrack,
  translate,
}: TProps) => {
  const handleOnClickTrack = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    onClickTrack(id)
  }, [])

  const handleSelect = useCallback(() => {
    select(id)
  }, [])

  return <GeoZoneRow
    className="geoZone-item__item"
    onClick={handleSelect}
    role="button"
    tabIndex={0}
  >
    <GeoZoneItemTextWrapper>
      <p className="geoZone-item__name">{name}</p>
      <TooltipD text={translate('Name geozone')} style={{ top: '-8px' }}/>
    </GeoZoneItemTextWrapper>

    <ItemHeader key="item-header">
      <ButtonStyled variant="icon" onClick={handleOnClickTrack} key="edit-button">
        {({ pressed }: { pressed: boolean }) => !loading
          ? <EyeIcon color={pressed ? buttonRedColor : isWatching && '#78C800' || '#999999'}/>
          : <Spinner className={'no-back'} />
        }
      </ButtonStyled>

      <TooltipD
        text={translate('Show / hide geofzonee on map')}
        style={{ top: '-8px', left: '-20px' }}
        key="tooltip-geofzonee3"
      />

      <TooltipD
        text={translate('Show / hide geozone detail information')}
        style={{ top: '-8px' }}
        key="tooltip-open-close"
      />
    </ItemHeader>
  </GeoZoneRow>
}

const GeoZoneRow = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  cursor: pointer;
  padding: 16px 30px 8px 30px;
  align-items: center;

  :hover {
    background-color: #EEEEEE;
  }
`

const ItemHeader = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
`

const ButtonStyled = styled(Button)`
  margin-right: 16px;
`

const GeoZoneItemTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
