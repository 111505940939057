import { TabContent, TabItem } from '@agdt/agrotronic-react-components'
import { Spin } from 'antd'
import { observer } from 'mobx-react'
import {dropLast, groupBy, is, isEmpty, last, path, pathOr, pipe, prop, propOr, reduce, toPairs, values} from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { getAllEmployee, getPerformanceReport } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import styled, { css } from 'styled-components'
import { useUserTimezone } from '../../../hooks'
import { getStore } from '../../../stores/storesRegistry'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { objectFields, TAB_IDS } from './constants'
import { EmployeType, Filters } from './Filters'
import PerformanceResultTable from './ResultTable'
import { ToExcel } from './ResultTable/ToExcell'

const getNameByIdFromList = (id: string, property: string, list: {id: number}[], noDataMessage: string) =>
  propOr(noDataMessage, property, list.find(I => I.id === Number(id)))

const haveId = (id: string) => id !== 'null' && id !== '-1'

const ReportPerformance = () => {
  const { getDateWithUserTimeZone } = useUserTimezone()
  const t = useTranslate()
  const [reportIsLoading, setReportIsLoading] = useState(false)

  const tabs = useMemo(() => [
    { id: TAB_IDS.SHT_OPERATOR, label: `${t('sht_abbr')} - ${t('operator')}` },
    { id: TAB_IDS.OPERATOR_SHT, label: `${t('operator')} - ${t('sht_abbr')}` },
  ], [t])

  const [currentTab, setCurrentTab ] = useState(tabs[0])
  const [report, setReport ] = useState<unknown>(null)
  const [ready, setReady] = useState(false)
  const [employees, setEmployees ] = useState<EmployeType[]>([])
  const [units, setUnits ] = useState<{id: number, name: string}[]>([])

  const [objects ] = useState([])
  const [checkedFiltersIds, setCheckedFiltersIds] = useState({})
  const [selectedDate, setSelectedDate] = useState<{from: string, to: string} | undefined>()

  const setCheckedIds = useCallback((filterName, filterValue) => {
    setCheckedFiltersIds({ ...checkedFiltersIds, [filterName]: filterValue })
  }, [checkedFiltersIds])

  const datesFilterResult = useCallback((_, date) => {
    setSelectedDate(date)
  }, [])

  const titleCreator = useMemo(() => ({
    employeePerformanceByField: {
      getSubtitle: (id: string) => haveId(id) ? t('operator') : '',
      getTitle   : (id: string) => {
        const employee = employees.find(I => I.id === Number(id))

        if(employee) {
          const getProp = (propName: string) => propOr('', propName, employee)

          const fullName = `${
            getProp('operatorSurname')
          } ${
            getProp('operatorFirstName')
          } ${
            getProp('operatorSecondName')
          }`

          return isEmpty(fullName) ? getProp('name') : fullName
        }

        return `${t('n/d')} ${t('about operator')}`
      },
    },
    fieldPerformance: {
      getSubtitle: (id: string) => haveId(id) ? t('field') : '',
      getTitle   : (id: string) => getNameByIdFromList(id, 'name', objects, `${t('n/d')} ${t('about field')}`),
    },
    operationPerformance: {
      getSubtitle: (id: string) => haveId(id) ? t('operation') : '',
      getTitle   : (id: string) => id !== 'null' ? id : `${t('n/d')} ${t('about operation')}`,
    },
    taskPerformance: {
      getSubtitle: (id: string) => haveId(id) ? t('task') : '',
      getTitle   : (id: string) => id !== 'null' ? id : `${t('n/d')} ${t('about task')}`,
    },
    unitPerformanceId: {
      getSubtitle: (id: string) => {
        const unit = units.find(I => I.id === Number(id))
        return pathOr(unit?.name, ['registers', 'TYP_HARVESTER_NUMBER'], unit)
      },

      getTitle: (id: string) => getNameByIdFromList(id, 'model', units, `${t('n/d')} ${t('about technique')}`),
    },
  }), [employees, objects, t, units])


  useEffect(() => {
    async function fetchData() {
      setUnits(getStore('dictionaries').targets2.unitsWithRegistersData)

      //@ts-expect-error
      setEmployees(await getAllEmployee())

      setReady(true)
    }

    fetchData()
  }, [])

  const getReport = useCallback(() => {
    if(!selectedDate) {
      return
    }

    setReportIsLoading(true)
    setReport(null)

    const checkedOperatorsIds = path(['employees', '1'], checkedFiltersIds)
    const formatDateTime = pipe(Date.parseDateTimeFromCalendarApi, getDateWithUserTimeZone)

    getPerformanceReport({
      from     : formatDateTime(selectedDate.from),
      operators: isEmpty(checkedOperatorsIds) ? undefined : checkedOperatorsIds,
      to       : formatDateTime(selectedDate.to),
      unitId   : pathOr(undefined, ['machines', '1'], checkedFiltersIds),
    }).then(result => {
      setReport(result)
      setReportIsLoading(false)
    }).catch(() => {
      errorMessage(t('reporting service is not available'))
      setReportIsLoading(false)
    })
  }, [checkedFiltersIds, selectedDate, t])

  const preparedReport = useMemo(() => {
    const isObject = is(Object)
    const isNumber = is(Number)
    const pReport = {}

    //@ts-expect-error
    const mutableAssocPath = ([first, ...rest], V, target) => {
      if(!target[first]) {
        target[first] = {}
      }

      //@ts-expect-error
      target[first] = rest.length > 0 ? mutableAssocPath(rest, V, target[first]) : V
      return target
    }

    const fillEmployeeReport = ([
      unitPerformanceId,
      ,
      employeePerformanceByField,
      ,
      fieldPerformance,
      ,
      operationPerformance,
      ,
      taskPerformanceId,
    ]: string[], V: unknown) => {
      const employee = {
        name : 'employeePerformanceByField',
        param: employeePerformanceByField,
      }

      const field = {
        name : 'fieldPerformance',
        param: fieldPerformance,
      }

      const unit = {
        name : 'unitPerformanceId',
        param: unitPerformanceId,
      }

      const params = {
        [TAB_IDS.FIELD_OPERATOR]: { level1: field, level2: employee, level3: unit },
        [TAB_IDS.FIELD_SHT]     : { level1: field, level2: unit, level3: employee },
        [TAB_IDS.OPERATOR_FIELD]: { level1: employee, level2: field, level3: unit },
        [TAB_IDS.OPERATOR_SHT]  : { level1: employee, level2: unit, level3: field },
        [TAB_IDS.SHT_FIELD]     : { level1: unit, level2: field, level3: employee },
        [TAB_IDS.SHT_OPERATOR]  : { level1: unit, level2: employee, level3: field },
      }

      mutableAssocPath([
        params[currentTab.id].level1.name,
        params[currentTab.id].level1.param,
        params[currentTab.id].level2.name,
        params[currentTab.id].level2.param,

        'operationPerformance',
        operationPerformance,
        'taskPerformance',
        taskPerformanceId],

      V,
      pReport,
      )
    }

    //@ts-expect-error
    const rebuildReport = (path, [K, V]) => {
      if(last(path) === 'taskPerformance') {
        fillEmployeeReport([...path, K], V)
      } else if(isObject(V)) {
        reduce(rebuildReport, [...path, K], toPairs(V))
      }

      return path
    }

    //@ts-expect-error
    reduce(rebuildReport, [], toPairs(report))

    //@ts-expect-error
    const rsum = (path, [K, V]) => {
      if(path.length > 1 && isNumber(V)) {
        //@ts-expect-error
        dropLast(1, path).forEach(node => node[K] = node[K] === undefined ? V : node[K] + V)
      } else if(isObject(V)) {
        reduce(rsum, [...path, V], toPairs(V))
      }

      return path
    }

    reduce(rsum, [pReport], toPairs(pReport))

    //@ts-expect-error
    const clearReport = r => Object.keys(r).reduce<Record<string, unknown>[]>((acc, K) => {
      if(objectFields.includes(K)) {
        Object.keys(r[K]).forEach(key => {
          if(isObject(r[K][key])) {
            //@ts-expect-error
            const { getSubtitle, getTitle } = titleCreator[K]

            acc.push({
              allWorkModesPerformance: r[K][key].allWorkModesPerformance,
              area                   : r[K][key].area,
              children               : clearReport(r[K][key]),
              harvestPerformance     : r[K][key].harvestPerformance,
              id                     : key,
              subtitle               : getSubtitle(key),
              timeAllWorkModes       : r[K][key].timeAllWorkModes,
              timeHarvest            : r[K][key].timeHarvest,
              title                  : getTitle(key),
            })
          }
        })
      }

      return acc
    }, [])

    return currentTab.id === TAB_IDS.OPERATOR_SHT

    //@ts-expect-error
      ? values(groupBy(prop('title'), clearReport(pReport))).map(I => {
        return I.length > 1
          ? I.reduce((acc, item) => ({
            ...item,

            //@ts-expect-error
            allWorkModesPerformance: acc.allWorkModesPerformance + item.allWorkModesPerformance || 0,

            //@ts-expect-error
            area: acc.area + item.area || 0,

            //@ts-expect-error
            children: acc.children.concat(item.children),

            //@ts-expect-error
            harvestPerformance: acc.harvestPerformance + item.harvestPerformance || 0,

            //@ts-expect-error
            timeAllWorkModes: acc.timeAllWorkModes + item.timeAllWorkModes || 0,

            //@ts-expect-error
            timeHarvest: acc.timeHarvest + item.timeHarvest || 0,
          }), {
            allWorkModesPerformance: 0,
            area                   : 0,
            children               : [],
            harvestPerformance     : 0,
            timeAllWorkModes       : 0,
            timeHarvest            : 0,
          })

          : I[0]
      })
      : clearReport(pReport)
  }, [currentTab.id, report, titleCreator])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={ready && isEmpty(units)}
        text={t('Select a different report or change the filtering conditions')}
        title={t('There is no data on which to build this report')}
      >
        {ready && <Filters
          checkedFiltersIds={checkedFiltersIds}
          datesFilterResult={datesFilterResult}
          employees={employees}
          getReport={getReport}
          reportIsLoading={reportIsLoading}
          selectedDate={selectedDate}
          setCheckedIds={setCheckedIds}
          units={units}
        />}

        {(!ready || reportIsLoading) &&
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>}

        {selectedDate && !isEmpty(preparedReport) && <>
          <ReportWrapper>
            <TabsContainer>
              {tabs.map(tab =>
                <StyledTabItem
                  active={tab.id === currentTab.id}
                  key={tab.id}
                  onSelect={setCurrentTab}
                  tab={tab}
                  noBottomBorder={true}
                />,
              )}
            </TabsContainer>

            <ToExcel
              checkedFiltersIds={checkedFiltersIds}
              employees={employees}

              //@ts-expect-error
              preparedReport={preparedReport}
              selectedDate={selectedDate}
              units={units}
              currentTab={currentTab.id as 'SHT_OPERATOR' | 'OPERATOR_SHT'}
            />
          </ReportWrapper>

          <StyledTabContent>
            <PerformanceResultTable

              //@ts-expect-error
              report={preparedReport}
            />
          </StyledTabContent>
        </>}

        {isEmpty(report) && isEmpty(preparedReport) && <NoDataWrapper>{t('no data')}</NoDataWrapper>}
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default observer(ReportPerformance)

const ReportWrapper = styled.div`
  display: flex;
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const NoDataWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888886;
`

const StyledTabContent = styled(TabContent)`
  border: none;
  border-right: 1px solid #e6e6e6;
  height: auto;
  overflow-y: unset;
  padding: 0;
  margin-top: 8px;
  background: none;
`

const StyledTabItem = styled(TabItem)`
  border: none;
  font-size: 16px;
  font-weight: 700;
  position: inherit;
  padding: 0;
  margin-right: 32px;
  cursor: pointer;
  background: none;

  ${({ active }) => active
    ? css`
      border-bottom: 2px solid #D10029;
      margin-top: 2px;
  ` : css`
      background-color: inherit;
      color: rgba(0, 0, 0, .36);
  `}

  > div{
    display: none;
  };
`

const TabsContainer = styled.ul`
  display: flex;
  flex: 1 0 50px;
  height: 50px;
  width: 100%;
  padding: 0 0 0 24px;
  margin-top: 7px;
  list-style: none;
  box-sizing: border-box;
`
