import React, { useCallback } from 'react'
import {
  Cell,
  Label,
  Pie,
  PieChart,
  Tooltip,
} from 'recharts'
import styled from 'styled-components'
import { ActiveShape } from './ActiveShape'
import { Labels } from './Labels'

/*@ts-expect-error*/
const CustomTooltip = props =>
  props.active ? props.payload[0].payload.tooltip : null

type TData = {
  color: string
}

export type TProps = {
  animation?: boolean
  half?: boolean
  height: number
  labels?: boolean
  title?: string
  tooltip?: boolean
  width?: number
  data: TData[]
}

export default function Donut({
  data,
  half,
  height,
  labels,
  title,
  tooltip,
  width,
}: TProps) {
  const [activeCell, setActiveCell] = React.useState<number | undefined>(undefined)
  const handleMouseEnter = useCallback((_: unknown, index: number) => setActiveCell(index), [])
  const handleMouseLeave = useCallback(() => setActiveCell(undefined), [])

  return (
    <Wrapper>
      <PieChart width={width} height={height} >
        <Pie
          animationEasing="ease-in"
          animationDuration={1000}
          animationBegin={50}
          data={data}
          dataKey="value"
          startAngle={half ? 180 : 90}
          endAngle={half ? 0 : -360}
          innerRadius={height * 0.3}
          outerRadius={height * 0.35}
          isAnimationActive={false} //TODO: fix animation with labels
          cornerRadius={1}
          activeIndex={activeCell}
          activeShape={ActiveShape}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}

          /*@ts-expect-error*/
          label={labels && Labels}
          labelLine={false}
        >
          {half || <Label value={title} position='center' />}
          {data?.map(entry => <Cell key={entry.color} fill={entry.color} stroke={undefined} />)}
        </Pie>
        {tooltip && <Tooltip wrapperStyle={{ maxWidth: '200px' }} content={<CustomTooltip />} />}
      </PieChart>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .recharts-wrapper {
    margin: 0 auto;
  }
  .recharts-label > tspan {
    font-size: 20px;
  }
  .recharts-tooltip-wrapper {
      background: #323232;
      color: #fff;
      width: max-content;
      font-size: 10px;
      padding: 4px 8px;
      border-radius: 3px;
      z-index: 1;
  }
  .blur-sector {
    filter: url('#dropshadow');
  }
`
