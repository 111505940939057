import './picker.scss'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { ColorType } from './Day'
import { MonthPiker } from './MonthPicker'

type SoloPickerProps = {
  initDate: moment.Moment
  history?: number
  format?: string
  colors?: ColorType[]
  switchMonth: (value: string) => void
  switchDays: (value: moment.Moment) => void
}

export const SoloPicker: FC<SoloPickerProps> = ({
  initDate,
  history,
  format = 'DD.MM.YY',
  colors = [],
  switchMonth,
  switchDays,
}) => {
  const [oneActiveDay, setOneActiveDay] = useState(initDate)

  const setActiveMonth = (_type: string, month: string) => {
    switchMonth(month)
  }

  const setRange = (newDate: moment.Moment) => {
    setOneActiveDay(newDate)
    switchDays(newDate)
  }

  return (
    <div className="Date-RangePicker">
      <MonthPiker
        type="left"
        date={initDate}
        history={history}
        isSoloCalendar={true}
        format={format}
        colors={colors}
        oneActiveDay={oneActiveDay}
        setActiveMonth={setActiveMonth}
        setRange={setRange}
      />
    </div>
  )
}
