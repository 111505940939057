import { propOr } from 'ramda'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { FilterDateButtons } from 'ui/Filter/dateButtons'
import { MultiselectWithSearch } from 'ui/Filter/MultiselectFilter'

const emptyList = { '1': [] }

export type EmployeType = {
  operatorSurname: string
  surname: string
  operatorFirstName: string
  firstName: string
  id: number
}

type FiltersProps = {
  checkedFiltersIds: Record<string, {
    id: number
    imei: number
    name: string
  }[]>
  reportIsLoading: boolean
  selectedDate?: {
    to: string
    from: string
    today?: boolean
  }
  employees: EmployeType[]
  units: {
    name: string
    id: unknown
  }[]
  datesFilterResult: (section: string, item: {
    to: string
    from: string
    today?: boolean
  } | string) => void
  getReport: () => void
  setCheckedIds: (name: string, record: Record<string, number[]>) => void
}

export const Filters: FC<FiltersProps> = ({
  checkedFiltersIds,
  datesFilterResult,
  employees,
  getReport,
  reportIsLoading,
  selectedDate,
  setCheckedIds,
  units,
}) => {
  const t = useTranslate()

  const machinesPrepared = useMemo(() => {
    return [{
      categoryId : 1,
      items      : units,
      name       : t('sht'),
      selectTitle: t('all sht'),
    }]
  }, [t, units])

  const employeesPrepared = useMemo(() => {
    return [{
      categoryId: 1,

      items: employees.map(employee => {
        const getProp = (propName: string) => propOr('', propName, employee)

        return {
          ...employee,
          name: `${
            getProp('operatorSurname') || getProp('surname')
          } ${
            getProp('operatorFirstName') || getProp('firstName')
          }`.trim() || 'No name',
        }
      }),

      name: t('operators'),
    }]
  }, [employees, t])

  const onClickButton = () => {
    if(selectedDate) {
      getReport()
    }
  }

  return <div className='filter-control'>
    <div className='filter-control__parameters'>
      <div className='filter-control__items'>
        <MultiselectWithSearch
          checkedOptionsIds={checkedFiltersIds['machines'] || emptyList}
          filterName='machines'
          label={t('sht')}
          options={machinesPrepared}
          searchInputLabel={t('find technique')}
          setCheckedIds={setCheckedIds}
        />

        <MultiselectWithSearch
          popupFitContent
          checkedOptionsIds={checkedFiltersIds['employees'] || emptyList}
          filterName='employees'
          label={t('operators')}
          noGroups
          options={employeesPrepared}
          searchInputLabel={t('find operator')}
          setCheckedIds={setCheckedIds}
          width='200px'
        />

        <FilterDateButtons
          machinesResult={checkedFiltersIds.machines || []}
          permissions={null}
          result={selectedDate ? [selectedDate] : []}
          section='time'
          setFilter={datesFilterResult}
          showSelected
        />
      </div>

      <div className="filter-control__wrap-buttons">
        <AcceptButton
          active={Boolean(selectedDate) && !reportIsLoading}
          onClick={onClickButton}
        >
          {t('accept')}
        </AcceptButton>
      </div>
    </div>

  </div>
}

const AcceptButton = styled.div<{active: boolean}>`
  max-width: 170px;
  min-width: 170px;
  background: ${({ active }) => active ? '#78C800' : '#cccccc'};
  text-align: center;
  height: 50px;
  line-height: 50px;
  box-shadow: inset 0px -2px 0px rgb(0 0 0 / 10%);
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`
