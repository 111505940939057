import L from 'leaflet'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment/moment'
import { findLast, forEachObjIndexed, fromPairs, isEmpty, last, max, pathOr, prop, reduce, toPairs } from 'ramda'
import { getCourse } from 'reducers/users/selectors'
import { demoRequest } from 'services/apiService/demoQueries'
import { getTrack } from 'services/apiService/modules'
import { getStore } from 'stores/storesRegistry'
import normalizeTrack, { adaptationPoints, addCourse } from 'ui/Map/normalizeTrack'
import { errorMessage } from '../../services/notification'
import HouseholdUnitStore from './HouseholdUnitStore'
import MarkersStore from './MarkersStore'

//@ts-expect-error
const valueOrNull = (name, value, defaultValue = null) => pathOr(defaultValue, [name, 'value'], value)

//@ts-expect-error
const clearNoMovePoints = json => json.filter(item => item.mode === 1 || item.mode === 4)

//@ts-expect-error
const cookActiveTrack = (trackData, unitData, demo, today) => {
  let track = fromPairs(
    toPairs(trackData)
      .map(

        //@ts-expect-error
        // eslint-disable-next-line max-len
        ([I, T]) => [I, T.map(P => ({ ...P, path: P.path.filter(J => Number(J.utc) <= Number(unitData.ACTIVE.value)) }))],
      ),
  )

  // В демо режиме не добавляет в трек последнюю точку из targets2 потому что в targets2 точки очень далеко от трека
  if(!demo && today && valueOrNull('LATITUDE', unitData) && valueOrNull('LONGITUDE', unitData))
  {forEachObjIndexed(M => {
    if(M.length > 0

      // @ts-ignore
      && (valueOrNull('LATITUDE', unitData) !== last(M).lat || valueOrNull('LONGITUDE', unitData) !== last(M).lng)
    )

    // @ts-ignore
    {last(M).path.push({
      CURR_PERF_TON: null,
      ID           : Number(unitData.ACTIVE.value),
      LOAD         : valueOrNull('LOAD', unitData),
      SPEED        : valueOrNull('SPEED', unitData),

      // @ts-ignore
      course: getCourse(unitData.LONGITUDE.value, last(M).lng, unitData.LATITUDE.value, last(M).lat),
      lat   : unitData.LATITUDE.value,
      lng   : unitData.LONGITUDE.value,

      //@ts-expect-error
      status: valueOrNull('STATUS', unitData, 0),

      t: unitData.ACTIVE.value
        ? moment.utc(unitData.ACTIVE.value).format('YYMMDDHHmmssSSS')
        : null,

      utc: unitData.ACTIVE.value,
    })}

    return M
  }, track)}

  const splittedTrack = {}

  // В этом блоке обеспечивается прерывистость трека, если временной интервал между точками велик https://trello.com/c/j7xQtPci
  // Время из предыдущего шага, для первой итерации MAX_SAFE_INTEGER
  Object.entries(track).forEach(([trackType, trackArr]) =>

    //@ts-expect-error
    trackArr.forEach(item => {
      let listPath

      {
        let lastPoint = { lat: 0, lng: 0, utc: Number.MAX_SAFE_INTEGER }

        //@ts-expect-error
        listPath = item.path.reduce((result, pathPount) => {
          // eslint-disable-next-line max-len
          if(pathPount.utc - lastPoint.utc > 45000 && L.latLng([lastPoint.lat, lastPoint.lng]).distanceTo([pathPount.lat, pathPount.lng]) > 500) {
            // Если разрыв между точками больше 7.5 мин и удаленность более 500 метров добавляем новый сегмент c пометкой о телепортации

            //@ts-expect-error
            if(!splittedTrack[trackType]) {splittedTrack[trackType] = []}

            if(result.tmp.length > 1)

            //@ts-expect-error
            {splittedTrack[trackType].push({ ...item, path: result.tmp })}

            //@ts-expect-error
            splittedTrack[trackType].push({
              ...item,
              path: [lastPoint, pathPount],

              // Непредвиденное перемещение, транспортировка
              isTeleported: true,
            })

            result.tmp = []
            lastPoint = pathPount
            return result
          }

          lastPoint = pathPount

          if(pathPount.lat && pathPount.lng) {
            result.tmp.push(pathPount)
          }

          return result
        }, { res: [], tmp: [] })

        if(listPath.tmp.length > 1) {
          //@ts-expect-error
          if(!splittedTrack[trackType]) {splittedTrack[trackType] = []}

          //@ts-expect-error
          splittedTrack[trackType].push({ ...item, path: listPath.tmp })
        }
      }
    }),
  )

  return splittedTrack
}

export type ActiveTrackPathType = {
  lat: number
  lng: number
  SPEED: number
  ID: number
  course: number
  status: number
  t: string
}

type ActiveTrack = {
  TRACK_HARVEST?: unknown[]
  TRACK_LOAD?: unknown
} & Record<string, {
  path: ActiveTrackPathType[]
  isTeleported?: boolean
  color: string
  id?: number
}[]>

export default class TrackStore {
  private rootStore

  //@ts-expect-error
  @observable private iteratorTimerId

  //@ts-expect-error
  @observable timeRangeFrom

  //@ts-expect-error
  @observable timeRangeTo
  @observable markers = new MarkersStore()
  @observable isLoading = false
  @observable activeTrack: ActiveTrack = {}
  @observable static = false
  @observable type = 'TRACK_HARVEST'
  @observable different = []
  @observable noData = false

  //@ts-expect-error
  @observable trackUpdated
  @observable animateTrack = false

  constructor(rootStore: HouseholdUnitStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  get timeRange() {
    if(!this.timeRangeTo || !this.timeRangeFrom) {
      return undefined
    }

    return { from: this.timeRangeFrom, to: this.timeRangeTo }
  }

  @computed
  get endDateIsToday() {
    const now = new Date()
    const date = new Date(this.timeRangeTo)

    return date.getDate() === now.getDate()
      && date.getMonth() === now.getMonth()
      && date.getFullYear() === now.getFullYear()
  }

  @computed
  get userTimeRange() {
    return {
      from: new Date().user.new(this.timeRangeFrom),
      to  : new Date().user.new(this.timeRangeTo),
    }
  }

  @action.bound
  setIsLoading(state: boolean) {
    this.isLoading = state
  }

  @action.bound

  //@ts-expect-error
  setTimeRange(from, to) {
    this.timeRangeFrom = from
    this.timeRangeTo = to
  }

  @action.bound
  clearTimeRange() {
    this.timeRangeFrom = undefined
    this.timeRangeTo = undefined
  }

  //@ts-expect-error
  @action.bound setType(type) {
    this.type = type
  }

  @action.bound

  //@ts-expect-error
  setNoData(state) {
    this.noData = state
  }

  @action.bound
  showNotHaveData() {
    this.setNoData(true)
    setTimeout(() => this.setNoData(false), 5000)
  }

  @action.bound
  async getTrackData() {
    try {
      const json = await getTrack({
        from: this.userTimeRange.from,
        imei: this.rootStore.imei,
        to  : this.userTimeRange.to,
      })

      if(isEmpty(json)) {
        this.showNotHaveData()
        this.setIsLoading(false)
      }

      return json
    } catch(error) {
      errorMessage(getStore('context').t('track service is not available'))
    } finally {
      this.setIsLoading(false)
    }

    return []
  }

  @action.bound
  async getInitTrackData() {
    this.setIsLoading(true)
    const todayDate = new Date()
    const inputDate = new Date(this.timeRangeTo)
    const isDemo = getStore('context').isDemo

    const trackData = isDemo
      ? await demoRequest('track')
      : normalizeTrack(adaptationPoints(addCourse(clearNoMovePoints(await this.getTrackData()))))

    this.pushTrack(trackData, true, isDemo, inputDate.setHours(0,0,0,0) === todayDate.setHours(0,0,0,0))
    this.setIsLoading(false)

    this.markers.requestMarkersData(this.userTimeRange.from, this.userTimeRange.to, this.rootStore.imei)

    if(this.endDateIsToday && this.rootStore.isWatched) {
      this.createIterator()
    }
  }

  @action.bound

  //@ts-expect-error
  pushTrack(trackData, stat, demo, today) {
    const unit = getStore('dictionaries').targets2.unitsWithRegistersDataMap[this.rootStore.imei]
    this.activeTrack = cookActiveTrack(trackData, unit.data, demo, today)
  }

  @action.bound

  //@ts-expect-error
  flyTrack(json) {
    if(!isEmpty(this.activeTrack)) {
      const trackData = adaptationPoints(addCourse(clearNoMovePoints(json)))

      const trackHarvest = this.activeTrack['TRACK_HARVEST']

      if(trackHarvest && trackHarvest.length > 0) {
        //@ts-expect-error
        let lastPoint

        {
          let index = trackHarvest.length

          while(!lastPoint && index >= 0) {
            //@ts-expect-error
            lastPoint = findLast(I => true, trackHarvest[--index].path)
          }
        }

        const unit = getStore('dictionaries').targets2.unitsWithRegistersDataMap[this.rootStore.imei]
        const unitData = unit.data

        const different = trackData.filter(

          //@ts-expect-error
          P => Number(P.utc) > Number(lastPoint.utc) && Number(P.utc) < Number(unitData.ACTIVE.value),
        )

        if(
          //@ts-expect-error
          Number(lastPoint.utc) < Number(unitData.ACTIVE.value)
          && unitData.LATITUDE.value
          && unitData.LONGITUDE.value

          //@ts-expect-error
          && lastPoint.lat !== unitData.LATITUDE.value

          //@ts-expect-error
          && lastPoint.lng !== unitData.LONGITUDE.value
        ) {
          const dataFromTargets2 = {
            CURR_PERF_TON: null,
            ID           : unitData.ACTIVE.value,
            LOAD         : valueOrNull('LOAD', unitData) || 0,
            SPEED        : valueOrNull('SPEED', unitData) || 0,

            course: getCourse(
              unitData.LONGITUDE.value,

              //@ts-expect-error
              (last(different) || lastPoint).lng,
              unitData.LATITUDE.value,

              //@ts-expect-error
              (last(different) || lastPoint).lat,
            ),

            lat: unitData.LATITUDE.value,
            lng: unitData.LONGITUDE.value,

            //@ts-expect-error
            status: valueOrNull('STATUS', unitData, 0) || 0,
            utc   : unitData.ACTIVE.value,

            t: unitData.ACTIVE.value
              ? moment.utc(unitData.ACTIVE.value).format('YYMMDDHHmmssSSS')
              : null,

            fromTargets2: true,
          }

          different.push(dataFromTargets2)
        }

        const getUTC = prop('utc')

        if(different) {
          const maxTime = Math.max(reduce((A,B) => max(A, getUTC(B)), 0, this.different || []), unit.updated || 0)
          const filteredDifferent = different.filter(P => P.utc > maxTime)

          if(filteredDifferent) {
            //@ts-expect-error
            this.different = different
            this.trackUpdated = maxTime
          }
        }
      }
    }
  }

  @action.bound
  async iterator(first = false, timeout = 60000) {
    if(!first) {
      this.setTimeRange(this.timeRangeFrom, new Date().getTime())
      const json = await this.getTrackData()

      if(json) {
        this.flyTrack(json)
      }

      this.markers.requestMarkersData(this.userTimeRange.from, this.userTimeRange.to, this.rootStore.imei)
    }

    this.iteratorTimerId = setTimeout(this.iterator, timeout)
  }

  @action.bound
  createIterator() {
    this.animateTrack = true
    const currentOffset = Number(new Date()) % 60000
    const startTimeOffset = 6953

    // startTimeOffset (мс) запуска приложения, например для 12:30:41 = 41ххх
    // делаем запросы трека синхронно (+5 секунд задержки) запросам targets2 которые делаются раз в минуту после запуска приложения
    this.iterator(true, (60000 - currentOffset + startTimeOffset) % 60000 + 5000)
  }

  @action.bound
  removeIterator() {
    this.animateTrack = false
    clearTimeout(this.iteratorTimerId)
  }
}
