import { AppDispatch } from 'reducers/store'
import { SortParam } from './state'

export enum SettingsActionTypeEnum {
  SORT_MACHINE = 'App/Machine/SORT_MACHINE',
  SWITCH_HELP = 'App/Settings/SWITCH_HELP',
}

export type SettingsActionsType = {
  type: SettingsActionTypeEnum.SORT_MACHINE
  payload: SortParam
} | {
  type: SettingsActionTypeEnum.SWITCH_HELP
}

export const sortMachine = (data: SortParam) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: SettingsActionTypeEnum.SORT_MACHINE })

export const switchHelp = () => (dispatch: AppDispatch) =>
  dispatch({ type: SettingsActionTypeEnum.SWITCH_HELP })
