import { GeoJsonObject, GeoJsonTypes } from 'geojson'

export type GeoColoringData = {
  features: unknown[]
  type: GeoJsonTypes
}

export type StaticType = {
  id: string | number
  isOpened?: boolean
  watch?: boolean
  location: GeoJsonObject | GeoJsonObject[]
  show?: boolean
  coloringData?: GeoColoringData
  coloringType?: string
}

export type GeoZone = Readonly<{
  isColoringLoad: boolean
  legendValues: Record<string|number, number[]>
  newGeoZone: unknown
  searchText: string
  static: ReadonlyArray<StaticType>
}>

export const init = (): GeoZone => ({
  isColoringLoad: false,
  legendValues  : {},
  newGeoZone    : null,
  searchText    : '',
  static        : [],
})

export const setOpen = (
  state: GeoZone,
  { id, isOpened }: {id: unknown, isOpened: boolean},
): GeoZone => ({
  ...state,
  static: state.static.map(item => {
    if(item.id === id) {
      return {
        ...item,
        isOpened: isOpened,
      }
    }

    return item
  }),
})

export const setSearchText = ( state: GeoZone, searchText: string): GeoZone => ({
  ...state,
  searchText,
})

export const pushZones = (state: GeoZone, zones: StaticType[] ): GeoZone => ({
  ...state,
  static: zones.map(item => {
    const prevItem = state.static.find(element => element.id === item.id)

    if(prevItem) {
      return prevItem
    }

    return {
      ...item,
      isOpened: false,
      watch   : false,
    }
  }),
})

export const pushGeoJson = ( state: GeoZone, zone: StaticType): GeoZone => ({
  ...state,
  static: state.static.map(item => {
    if(item.id === zone.id) {
      return {
        ...item,
        location: zone.location,
      }
    }

    return item
  }),
})

export const watch = (
  state: GeoZone,
  { id, show }: {id: unknown, show: boolean},
): GeoZone => ({
  ...state,
  static: state.static.map(item => {
    if(item.id === id) {
      return {
        ...item,
        watch: show,
      }
    }

    return item
  }),
})

export const unwatchAll = (state: GeoZone): GeoZone => ({
  ...state,
  static: state.static.map(item => ({
    ...item,
    isOpened: false,
    watch   : false,
  })),
})

export const deleteZone = (state: GeoZone, id: unknown ): GeoZone => ({
  ...state,
  static: state.static.filter(item => item.id !== id),
})

export const updateZone = (state: GeoZone, zone?: StaticType): GeoZone => ({
  ...state,
  ...zone && { static: state.static.map(item => {
    if(item.id === zone.id) {
      return {
        ...item,
        ...zone,
      }
    }

    return item
  }) },
})

export const addZone = (state: GeoZone, zone: StaticType): GeoZone => ({
  ...state,
  static: [
    ...state.static,
    {
      ...zone,
      isOpened: false,
      watch   : false,
    },
  ],
})

export const setZoneColoring = (
  state: GeoZone,
  { id, data, type }: {
    id: unknown
    data: GeoColoringData
    type: string
  },
): GeoZone => ({
  ...state,
  static: state.static.map(item => {
    if(item.id === id) {
      return {
        ...item,
        coloringData: data,
        coloringType: type,
      }
    }

    return item
  }),
})

export const setZoneColoringLegends = (
  state: GeoZone,
  { id, data }: {id: string | number, data: number[]},
): GeoZone => ({
  ...state,
  legendValues: {
    ...state.legendValues,
    [id]: data,
  },
})

export const deleteZoneColoring = (
  state: GeoZone,
  { id }: {id: unknown},
): GeoZone => ({
  ...state,
  static: state.static.map(item => {
    if(item.id === id) {
      return {
        ...item,
        coloringData: undefined,
        coloringType: undefined,
      }
    }

    return item
  }),
})

export const setZoneColoringLoad = (state: GeoZone, isColoringLoad: boolean): GeoZone => ({
  ...state,
  isColoringLoad,
})

