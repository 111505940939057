export const productivityValues: Record<string, Array<[number, number]>> = {
  'KUK': [[7, 0], [8, 20], [9, 40], [10, 60], [11, 80], [12, 100]],
  'ZUK': [[100, 50], [101, 60], [102, 70], [103, 80], [104, 100], [105, 200]],
}

export const harvestColors: string[] = [
  '#b1b1b1',
  '#06a9f5',
  '#202020',
  '#D10B41',
  '#78c800',
  '#d61fd6',
]
