import { hooks } from '@agdt/agrotronic-react-components'
import L from 'leaflet'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { Map } from 'react-leaflet'
import { getStore } from 'stores/storesRegistry'
import { TMapTypes, TTranslate } from 'types'
import { StyleMap } from 'ui/Map/styleMap'
import { Control } from 'utils/LeafletCustomControl'

type TMapTypeControl = {
  mapRef: React.RefObject<Map>
  t: TTranslate
}

export const MapTypeControl = observer(({ mapRef, t }: TMapTypeControl) => {
  const mapWidgetStore = getStore('mapWidget')
  const [isOpened, setIsOpened] = useState(false)
  const controlRef = useRef(null)

  const handleSwitchType = (type: TMapTypes) => {
    mapWidgetStore.setSelectedMapType(type)

    if(mapRef.current) {
      const center = mapRef.current.leafletElement.getCenter()
      const zoom = mapRef.current.leafletElement.getZoom()

      mapRef.current.leafletElement.options.crs = L.CRS.EPSG3857
      mapRef.current.leafletElement.setView(center, zoom)
    }
  }

  hooks.useClickOutside(controlRef, () => setIsOpened(false))

  const handleControl = () => setIsOpened(!isOpened)

  return <Control position="topright">
    <div className="map__topright">
      <div className="map__topright-controll">
        <div className="map-type" ref={controlRef}>
          <button
            className="map-type__button"
            onClick={handleControl}
          />

          {isOpened && <div>
            <StyleMap
              switchType={handleSwitchType}
              translate={t}
              type={mapWidgetStore.selectedMapType}
            />
          </div>}
        </div>
      </div>
    </div>
  </Control>
})
