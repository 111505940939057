import { SettingsActionsType, SettingsActionTypeEnum } from './actions'
import * as State from './state'

export default function settings(state = State.init(), action: SettingsActionsType): State.Settings {
  switch(action.type){
    case SettingsActionTypeEnum.SORT_MACHINE:
      return State.sort(state, action.payload )

    case SettingsActionTypeEnum.SWITCH_HELP:
      return State.toggleHelp(state)

    default:
      return state
  }
}
