import { MAP_TYPES } from 'constants/map'
import { mapBoxToken } from 'constants/tokens'
import React from 'react'
import { TileLayer } from 'react-leaflet'
import { TMapTypes } from 'types'

type TMapLayer = {
  lang: string
  mapType?: TMapTypes
  zIndex?: number
}

type TMapList = Record<TMapTypes, string>

export const MapLayer = ({ lang, mapType = 'google1', zIndex }: TMapLayer) => {
  const mapsList: TMapList = {
    [MAP_TYPES.GOOGLE]  : `https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=${lang}`,
    [MAP_TYPES.GOOGLE_1]: `https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=${lang}`,
    [MAP_TYPES.GOOGLE_2]: `https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=${lang}`,
    [MAP_TYPES.OSM]     : 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    [MAP_TYPES.SPUTNIC] : `https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${mapBoxToken}`,
  }

  return (
    <TileLayer
      attribution={
        mapType === 'osm'
          ? '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          : undefined
      }

      url={mapsList[mapType]}
      zIndex={zIndex}
      zoomAnimated={false}
    />
  )
}
