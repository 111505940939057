import 'tippy.js/dist/tippy.css'
import 'antd/lib/form/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/input-number/style/index.css'
import 'antd/lib/pagination/style/index.css'
import 'antd/lib/spin/style/index.css'
import 'antd/lib/table/style/index.css'
import 'antd/lib/tooltip/style/index.css'
import 'antd/lib/progress/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/switch/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/slider/style/index.css'
import 'antd/lib/radio/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/menu/style/index.css'
import 'antd/lib/popover/style/index.css'
import './style/index.scss'
import './style/leaflet.css'
import 'react-virtualized/styles.css'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import { ConfigContext, ValidateUser } from './containers/ValidateUser'
import { ResetPassword } from './pages/ResetPassword'
import { link, RouterBlock } from './router'
import { InitStore } from './stores/storesRegistry'

const App = () =>
  <Router>
    <Switch>
      <Route exact path={link.resetpassword} component={ResetPassword} />

      <Route>
        <ValidateUser>
          <Header />

          <ConfigContext.Consumer>
            {context => !context?.farm?.isLoading && context?.farm?.selectedFarmId && <><RouterBlock /></>}
          </ConfigContext.Consumer>

          <InitStore />
        </ValidateUser>
      </Route>
    </Switch>
  </Router>

export default App
