import { observer } from 'mobx-react'
import React, { CSSProperties, useCallback } from 'react'
import { Map } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { getStore } from 'stores/storesRegistry'
import { TAccount, TUserUnit } from 'types'
import { IconClose, IconOpen } from 'ui/Icons'
import TooltipD from 'ui/Tooltip'
import { ListMachine } from 'ui/Tracking/listMachine'

const styles = {
  tooltip: { left: '36px', top: '-32px' },
}

type Household = TAccount & {
  units: TUserUnit[]
}

type TProps = {
  data: {
    filteredHouseholds: Household[]
    handleSetRowHeights: (index: number, size: number) => void
    mapRef: Map
  }

  index: number
  style: CSSProperties
}

const HouseHoldItem = observer(({ data, index, style }: TProps) => {
  const {
    filteredHouseholds,
    handleSetRowHeights,
    mapRef,
  } = data

  const item = filteredHouseholds[index]
  const t = useTranslate()
  const householdsStore = getStore('households')
  const householdUnits = getStore('householdUnits')
  const isOpen = householdsStore.isOpen(item.id)

  const handleOpenedToggle = useCallback(() => {
    householdsStore.toggle(item.id)
  }, [isOpen, item.id])

  return (
    <div style={style}>
      <div
        className="tracking-item__main"
        data-id={item.id}
        onClick={handleOpenedToggle}
        role="button"
      >
        <div className="tracking-item__name">
          <p>{item.name}</p>

          {householdUnits.countWatchedUnitsByHouseholds[item.id] &&
            <span>{householdUnits.countWatchedUnitsByHouseholds[item.id]}</span>
          }
        </div>

        <div className="tracking-item__open">
          {isOpen ? <IconOpen /> : <IconClose />}

          <TooltipD
            text={t('List of farm equipment (indicating the number of monitored equipment)')}
            style={styles.tooltip}
          />
        </div>
      </div>

      {isOpen && <ListMachine
        handleSetRowHeights={handleSetRowHeights}
        units={item.units.filter(U => U.owned)}
        mapRef={mapRef}
        rowId={index}
      />}
    </div>
  )
})

export { HouseHoldItem }
