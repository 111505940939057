import { Popover, Spin } from 'antd'
import { isEmpty, isNil } from 'ramda'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import { clearReport, startLoadExportedReport } from '../../reducers/reports/actions'
import { link } from '../../router'
import FilterControl, { FilterControlConfig } from '../../ui/Filter/filterControl'
import InitFilter from '../../ui/Filter/initFilter'
import TooltipD from '../../ui/Tooltip'

type ButtonExportProps = {
  name: string
  shadowButton: boolean
  from: string
  to: string
  typeReport: string
  flag: boolean
  imeis: number[]
}

const ButtonExport: FC<ButtonExportProps> = ({
  name,
  shadowButton,
  from,
  to,
  typeReport,
  flag,
  imeis,
}) => {
  const translate = useTranslate()
  const stateDispatch = useDispatch()
  const config = useSelector((state: AppStateType) => state.user.config)
  const info = useSelector((state: AppStateType) => state.user.info)

  const haveEmail = !(isNil(info.email) || isEmpty(info.email))
  const GMT = info.gmt || 0

  const checkPermission = () => {
    if(!info || !config?.NOTICES_PERIOD) {
      return true
    }

    const periodNoticesMS = (config.NOTICES_PERIOD + 1) * (1000 * 60 * 60 * 24)
    const diff = Date.parseDateTimeFromCalendarApi(to).getTime() - Date.parseDateTimeFromCalendarApi(from).getTime()

    return periodNoticesMS > diff
  }

  const startLoadExport = () => {
    stateDispatch(startLoadExportedReport({
      data: {
        daily                : flag,
        from                 : new Date().user.new(Date.parseDateTimeFromCalendarApi(from)).utc,
        imeis,
        lang                 : info?.lang?.toUpperCase() || null,
        timezoneOffsetMinutes: GMT * 60,
        to                   : new Date().user.new(Date.parseDateTimeFromCalendarApi(to)).utc,
        type                 : typeReport,
      },

      translate,
    }))
  }

  const handleLoadExport = () => {
    if(typeReport === 'messages' && !checkPermission()) {
      alert(`${translate('Attention! Request for notifications is limited to')} ${config?.NOTICES_PERIOD} `+
      `${translate('days. Please change the request parameters')}.`,
      )
    } else {
      startLoadExport()
    }
  }

  return (
    <div className="watch__export-item">
      <p>{name}</p>

      {haveEmail && !shadowButton
        ? <button
          onClick={handleLoadExport}
        >
          {translate('export_of_report')}
        </button>

        : <Popover
          placement="topLeft"

          content={!haveEmail
            ? <div>
              {translate('To generate the report, you must specify the email address')}
              &nbsp;
              <Link to={link.profile}>{translate('set email')}</Link>
            </div>
            : <div>{translate('Please select unit(s) and time period')}</div>
          }
        >
          <ShadowButton>{translate('export_of_report')}</ShadowButton>
        </Popover>
      }
    </div>
  )
}

const Export: FC<FilterControlConfig> = props => {
  const translate = useTranslate()

  const report = useSelector((state: AppStateType) => state.reports.get('exportReport'))

  const imeis = props.filter[0] && props.filter[1].result[0] ? props.filter[0].result.map(item => item.imei) : []
  const from = props.filter[0] && props.filter[1].result[0] ? props.filter[1].result[0].from : ''
  const to = props.filter[0] && props.filter[1].result[0] ? props.filter[1].result[0].to : ''

  const buttons = [
    {
      name      : translate('A final report about harvesting'),
      typeReport: 'harvest',
      flag      : false,
    },
    {
      name      : translate('A final report about harvesting (compact)'),
      typeReport: 'harvestc',
      flag      : false,
    },
    {
      name      : translate('A daily report about harvesting'),
      typeReport: 'harvest',
      flag      : true,
    },
    {
      name      : translate('A daily report about harvesting (compact)'),
      typeReport: 'harvestc',
      flag      : true,
    },
    {
      name      : translate('A final report about machine'),
      typeReport: 'vehicle',
      flag      : false,
    },
    {
      name      : translate('A daily report about machine'),
      typeReport: 'vehicle',
      flag      : true,
    },
    {
      name      : translate('A final report about used time'),
      typeReport: 'timeusage',
      flag      : false,
    },
    {
      name      : translate('A daily report about used time'),
      typeReport: 'timeusage',
      flag      : true,
    },
    {
      name      : translate('notifications'),
      typeReport: 'messages',
      flag      : true,
    },
    {
      name      : translate('An overall report about operation time'),
      typeReport: 'vehiclei',
      flag      : false,
    },
    {
      name      : translate('Daily Cleaning/Work Report (KYK)'),
      typeReport: 'harvestk',
      flag      : true,
    },
  ]

  return (
    <div className="watch__wrap">
      <FilterControl translate={translate} {...props} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* @ts-expect-error */}
        {report.get('load') && <Spin /> }
      </div>

      <div className="watch__export">
        <TooltipD
          text={
            translate('Types de rapports disponibles pour exportation dans un fichier Export disponible au format Excel')
          }
          style={{ left: '-15px' }}
        />

        {buttons.map((item, key) =>
          <ButtonExport
            name={item.name}
            from={from}
            to={to}
            imeis={imeis}
            typeReport={item.typeReport}
            flag={item.flag}
            shadowButton={props.shadowButton}
            key={key}
          />,
        )}
      </div>
    </div>
  )
}

const ShadowButton = styled.button`
  && { border: 2px solid lightgrey; }

  &&:hover {
    border: 2px solid lightgrey;
    background: inherit;
    color: inherit;
  }
`

export default InitFilter({
  default: [
    {
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
    },
    {
      type    : 'date',
      name    : 'time',
      section : 'time',
      id      : 1,
      result  : [],
      validate: true,
    },
  ],
  clear: () => dispatch => {
    dispatch(clearReport(ReportType.Export))
  },
  noStartCallbackFiltration: true,

  //@ts-expect-error
})(Export)
