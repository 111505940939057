import React, { FC } from 'react'
import styled from 'styled-components'
import { OperatorCard } from './OperatorCard'
import { OperatorContent, TOperator } from './OperatorContent'
import { ReportRowTitle } from './ReportRowTitle'

type TProps = {
  report: Record<string, TOperator>
}

export const OperatorsResultTable: FC<TProps> = ({ report }) => <ReportWrapper>
  {Object.values(report).map(operator => <ReportRowWrapper key={operator.id}>
    <ReportRowTitle
      title={
        <OperatorCard
          fullName={operator.name}
          photo={operator.photoSmall}
          tel={operator.telNumber}
        />}
    >
      <OperatorContent operator={operator} />
    </ReportRowTitle>
  </ReportRowWrapper>)}

</ReportWrapper>

const ReportWrapper = styled.div`
  padding: 16px;
  background-color: #ffffff;
`

const ReportRowWrapper = styled.div`
  border: 1px solid #F2F2F2;
  margin: 16px 0;
  border-radius: 4px;
  
  & > div:first-child {
    background-color: #F2F2F2;
  }
`

export default OperatorsResultTable
