import { icons } from '@agdt/agrotronic-react-components'
import React, { FC,useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useDebounce from '../../../utils/useDebounce'

type Props = {
  isClearDisabled: boolean
  clearLabel: string
  placeholder: string
  searchText: string
  clear: () => void
  onChange: (value: string) => void
}

export const Search: FC<Props> = ({
  clear,
  clearLabel,
  isClearDisabled,
  onChange,
  placeholder,
  searchText,
}) => {
  const [searchValue, setSearchValue] = useState(searchText)
  const onValueChanged = useCallback(e => setSearchValue(e.target.value), [])
  const debouncedSearchValue = useDebounce(searchValue, 500)
  useEffect(() => onChange(debouncedSearchValue), [debouncedSearchValue, onChange])

  const handleClear = useCallback(() => {
    if(!isClearDisabled) {
      setSearchValue('')
      clear()
    }
  }, [isClearDisabled])

  return (
    <SearchContainer>
      <SearchIcon color="#A1A1A1" size="18" />

      <SearchInput
        placeholder={placeholder}
        value={searchValue}
        onChange={onValueChanged}
      />

      <FilterListIcon color="#A1A1A1" />

      <ClearButton
        disabled={isClearDisabled}
        onClick={handleClear}
      >
        {clearLabel}
      </ClearButton>

    </SearchContainer>
  )
}

const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  display:flex;
  align-items: center;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 10px 20px 10px 5px;

  flex-grow: 2;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #A1A1A1;
`

const ClearButton = styled.a<{disabled: boolean}>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-right: 14px;

  /* identical to box height, or 129% */
  text-decoration-line: underline;

  /* Color 10 */
  color: #A1A1A1;

  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`

const FilterListIcon = styled(icons.FilterListIcon)`
  margin-right: 10px;
`

const SearchIcon = styled(icons.SearchIcon)`
  margin-left: 15px;
`
