import moment from 'moment'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { closestItems, dateRangeGenerator, isStartOfDay } from '../utils'

SwiperCore.use([Navigation])

const currentDateTime = moment().valueOf()
const todayDate = moment(currentDateTime).startOf('day').valueOf()
const dateFrom = moment(todayDate).subtract(5,'d').valueOf()

const dateForLayersWithoutForecast = moment().isBetween(todayDate, moment(todayDate).add(12,'h'))
  ? moment(todayDate).add(12,'h')
  : moment(todayDate).add(1,'d')

const navigation = {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
}

type TProps = {
  currentWeatherLayer: string
  setWeatherMapDateTime: (arg: number) => void
}

const WeatherMapTimelineComponent = ({ currentWeatherLayer, setWeatherMapDateTime }: TProps) => {
  const setWeatherMapDateTimeHander = useCallback(
    activeSlide => setWeatherMapDateTime(moment(timelineItems[activeSlide.realIndex]).unix()),
    [setWeatherMapDateTime],
  )

  //слои 'TS0' и 'TS10' не предоставляют прогноз. Поэтому для них отображать даты на 5 дней вперёд не нужно
  const dateTo = ['TS0', 'TS10'].includes(currentWeatherLayer)
    ? dateForLayersWithoutForecast.valueOf()
    : moment(todayDate).add(5,'d').valueOf()

  const timelineItems = dateRangeGenerator(dateFrom, dateTo, 3, 'h')
  const closestDateTime = closestItems(timelineItems as number[], currentDateTime)
  const currentDateTimeIndex = timelineItems.indexOf(closestDateTime)

  return (
    <TimelineWrapper>
      <TimelineContainer>
        <TimelineCurrent />
        <div className='swiper-button-next'><div className='swiper-button-next-arrow'/></div>

        <Swiper
          centeredSlides
          createElements={false}
          grabCursor
          initialSlide={currentDateTimeIndex}
          navigation={navigation}
          onSlideChange={setWeatherMapDateTimeHander}
          slideToClickedSlide
          slidesPerView={'auto'}
        >
          {timelineItems.map(item =>
            <SwiperSlide
              className={`timeline-item
               ${moment(item).isBefore(closestDateTime) ? 'past-time-item' : ''}
               ${isStartOfDay(item) ? 'timeline-item-separated' : ''}
              `}
              key={Number(item)}
            >
              <div className='timeline-item-date'>
                {isStartOfDay(item) && moment(item).format('dd, D')}
              </div>

              <div className='timeline-item-time'>
                {moment(item).format('HH:mm')}
              </div>
            </SwiperSlide>)}
        </Swiper>

        <div className='swiper-button-prev'>
          <div className='swiper-button-prev-arrow'/>
        </div>
      </TimelineContainer>
    </TimelineWrapper>
  )
}

const TimelineWrapper = styled.div`
  bottom: 23px;
  left: 0;
  padding: 0 50px;
  position: absolute;
  right: 0;
  z-index: 400;
`

const TimelineContainer = styled.div`
  background-color: #ffffff;
  border-radius: 2px;
  height: 100%;
`

const TimelineCurrent = styled.div`
  background: #fff;
  border-radius: 2px;
  border: 1px solid #C9CCD4;
  bottom: -5px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 6px 0 rgb(0 0 0 / 5%);
  left: 50%;
  margin-left: -35px;
  position: absolute;
  top: -5px;
  width: 70px;
`

export default WeatherMapTimelineComponent
