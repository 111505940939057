import { isEmpty, keys, sum } from 'ramda'
import { TMapTypes } from '../../constants'

export const type2sensor = (type: TMapTypes | null) => {
  if(type === null){
    return type
  }

  if(['CURR_PRODUCTIVITY_ZUK', 'CURR_PRODUCTIVITY_KUK'].includes(type)) {
    return 'CURR_PRODUCTIVITY'
  }

  if(['CURR_HYMIDITY_KUK', 'CURR_HYMIDITY_ZUK'].includes(type)) {
    return 'CURR_HYMIDITY'
  }

  if(['DRY_PRODUCTIVITY_KUK'].includes(type)) {
    return 'DRY_PRODUCTIVITY'
  }

  return type
}


export const type2type = (type: TMapTypes | null) => {
  if(type === null){
    return type
  }

  if(['CURR_PRODUCTIVITY_ZUK', 'CURR_HYMIDITY_ZUK'].includes(type)) {
    return 'ZUK'
  }

  if(['CURR_HYMIDITY_KUK', 'CURR_PRODUCTIVITY_KUK'].includes(type)) {
    return 'KUK'
  }

  if(['DRY_PRODUCTIVITY_KUK'].includes(type)) {
    return 'KUK'
  }

  return type
}

export const getCurrentZoneUnitsIds = (coloringData: unknown): string[] | undefined => {
  if(!coloringData) { return }

  //@ts-expect-error
  return [...coloringData.features.reduce((acc, item) => {
    if(item.properties) {
      acc.add(...keys(Object.assign({}, ...item.properties.val)))
    }

    return acc
  }, new Set())]
}

export const getColoringDataWithFilteredValues = (coloringData: unknown, unitsIds: string[]) => {
  //@ts-expect-error
  return coloringData.features.reduce((acc, item) => {
    const selectedValues = item.properties.val.filter((v: number) => unitsIds.includes(Object.keys(v)[0]))
    const values = Object.values(Object.assign({}, ...selectedValues))

    if(!isEmpty(values)) {
      //@ts-expect-error
      acc.push({ ...item, properties: { val: sum(values) / values.length } })
    }

    return acc
  }, [])
}
