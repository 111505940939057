import { helpers } from '@agdt/agrotronic-react-components'
import L, { MarkerClusterGroupOptions } from 'leaflet'
import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet'
require('leaflet.markercluster')

const { debounce } = helpers

type TMarkerClusterGroup = L.MarkerClusterGroup & {
  _flushLayerBuffer: () => void
  _layerBuffer: MapLayer[]
}

L.MarkerClusterGroup.include({
  _flushLayerBuffer() {
    // if in queue for delete still stay some layers delete it before add new
    this.deleteLayersFromQueue()

    this.addLayers(this._layerBuffer)
    this._layerBuffer = []
  },

  addLayerDelayed(layer: MapLayer) {
    if(this._layerBuffer.length === 0) {
      if(!this.delayedFlushLayerBuffer){
        this.delayedFlushLayerBuffer = debounce(this._flushLayerBuffer.bind(this), 0)
      }

      this.delayedFlushLayerBuffer()
    }

    this._layerBuffer.push(layer)
  },

  delayedDeleteLayersFromQueue() {
    if(!this.delayedDeleteProcess){
      this.delayedDeleteProcess = debounce(this.deleteLayersFromQueue.bind(this), 0)
    }

    this.delayedDeleteProcess()
  },

  deleteLayersFromQueue() {
    if(this.deleteQueue?.length) {
      this.removeLayers(this.deleteQueue)
    }

    this.deleteQueue = []
  },

  removeLayerDelayed(element: MapLayer) {
    if(!this.deleteQueue){this.deleteQueue = []}

    this.deleteQueue.push(element)
    this.delayedDeleteLayersFromQueue()
  },
})


L.MarkerClusterGroup.addInitHook(function (this: TMarkerClusterGroup) {
  this._layerBuffer = []
})

class Cluster extends MapLayer {
  constructor(props: MapLayerProps & MarkerClusterGroupOptions) {
    super(props)
  }

  createLeafletElement({ leaflet, ...props }: MapLayerProps) {
    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = L.markerClusterGroup(props)
    this.contextValue = { layerContainer: markerClusterGroup, map: leaflet?.map }

    return markerClusterGroup
  }
}

export default withLeaflet(Cluster)
