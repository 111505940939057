import { icons, Spinner } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { useDemoMode } from 'services/hooks'
import { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { createExcelFile } from './ToExcellHandler'

const { XlsIcon } = icons

const DownloadBlock = () =>{
  const t = useTranslate()
  const isDemo = useDemoMode()
  const userLogin = getStore('context').user.name
  const store = getStore('notices')
  const noticesList = store.notices.list.sortedItems
  const period = store.period.value
  const units = store.units.list.sortedItems
  const [reportIsLoading, setReportIsLoading] = useState(false)

  const exportToExcelHandler = useCallback(
    () => {
      setReportIsLoading(true)
      const machine = units.find(I => I.imei === store.selectedUnitImei)

      const report = [{

        //@ts-expect-error
        farm: machine.farm,

        //@ts-expect-error
        holding      : machine.holding,
        notifications: noticesList,

        //@ts-expect-error
        serialNumber: machine.name,

        //@ts-expect-error
        typeName: machine.subtype,
      }]

      createExcelFile({ period, report, t, userLogin })
        .catch(() => errorMessage(t('Error creating file')))
        .finally(() => setReportIsLoading(false))
    },
    [period, units, noticesList, userLogin],
  )

  // WARN: вернуть кнопку в demo режим когда решится стратегия https://trello.com/c/MQuoUFhf
  return isDemo || store.notices.isLoading ? <EmptyBlock /> : <DownloadBlockStyled
    disabled={reportIsLoading}
    onClick={reportIsLoading ? undefined : exportToExcelHandler}
  >
    {reportIsLoading ? <Spinner color="#D5D2D2" size={24} /> : <XlsIcon />}
    <DownloadTextStyled>{t('Download')}</DownloadTextStyled>
  </DownloadBlockStyled>
}

const EmptyBlock = styled.div`
  width: 75px;
`

const DownloadBlockStyled = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const DownloadTextStyled = styled.span`
  font-size: 14px;
  margin-left: 6px;
`

export default observer(DownloadBlock)
