import React from 'react'
import styled from 'styled-components'

export type TProps = {
  cx: number
  cy: number
  fill: string
  midAngle: number
  outerRadius: number
  textAnchor: string
  title: string
  tooltip: React.ReactNode
}

export const Labels = ({
  cx,
  cy,
  fill,
  midAngle,
  outerRadius,
  textAnchor,
  title,
  tooltip,
}: TProps) => {
  const RADIAN = Math.PI / 180
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius - 10) * cos
  const sy = cy + (outerRadius - 10) * sin
  const mx = cx + (outerRadius + 10) * cos
  const my = cy + (outerRadius + 10) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 12
  const tx = ex + (cos >= 0 ? 1 : -1) * 3

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${my}`} stroke={fill} fill="none"/>
      {title && title.split('.').reverse().map((line, index) =>
        <LabelText key={tx} x={tx} y={my - index * 12} textAnchor={textAnchor} fill="#333">
          {line}
        </LabelText>,
      )}
      <LabelText key={tx} x={tx} y={my + 12} textAnchor={textAnchor} fill="#A1A1A1">
        {tooltip}
      </LabelText>
    </g>
  )
}

const LabelText = styled.text`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 14px;
`
