import { ModalDialog,ModalOverlay } from '@agdt/agrotronic-react-components'
import React, { FC } from 'react'
import styled from 'styled-components'
import { SoloMachineMap } from 'ui/Map/soloMachineMap'

type Props = {
  coordinates: [number, number]
  onCancel: () => void
}

const MapPopup: FC<Props> = ({ coordinates, onCancel }) => {
  return (
    <ModalOverlay visible={true} onClose={onCancel}>
      <StyledDialog>
        <MapContainer>
          <SoloMachineMap marker={coordinates} />
        </MapContainer>
      </StyledDialog>
    </ModalOverlay>
  )
}

const StyledDialog = styled(ModalDialog)`
  padding: 5px;
`

const MapContainer = styled.div`
  width: 600px;
  height: 400px;
`

export default MapPopup
