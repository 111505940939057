import React from 'react'

type TProps = {
  className?: string
  color?: string
  size?: number
}

const ZukIcon = ({ color='#202020', className, size = 12 }: TProps) => {
  return (
    <svg
      className={className}
      height={size}
      fill="none"
      viewBox="0 0 20 12"
      width={size * 5 / 3}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.8 5.71429C4.3464 5.71429 5.6 6.99347 5.6 8.57143C5.6 10.1494 4.3464 11.4286 2.8 11.4286C1.2536 11.4286 0 10.1494 0 8.57143C0 6.99347 1.2536 5.71429 2.8 5.71429ZM13.2 5.71429C14.7464 5.71429 16 6.99347 16 8.57143C16 10.1494 14.7464 11.4286 13.2 11.4286C11.6536 11.4286 10.4 10.1494 10.4 8.57143C10.4 6.99347 11.6536 5.71429 13.2 5.71429ZM1.68672 5.09567L1.82832 4.0818H5.60032V6.28833C4.94032 5.44751 3.93792 4.89812 2.80032 4.89812C2.40912 4.89812 2.03872 4.97731 1.68672 5.09567ZM19.2 8.16327H20V8.97959L18.4 9.21306V8.16327H16.76C16.5584 6.32898 15.0512 4.89796 13.2 4.89796C11.212 4.89796 9.6 6.54286 9.6 8.57143C9.6 8.71102 9.6256 8.84327 9.64 8.97959H8H6.36C6.3744 8.84327 6.4 8.71102 6.4 8.57143C6.4 8.43184 6.3744 8.29959 6.36 8.16327H8V5.71429L6.4 5.48082V4.08163H7.2L7.6568 0.816327H6.4V0H7.7712H8H8.8H12.8L13.2656 3.3249L19.0832 4.06694L19.2 4.89796V8.16327ZM13.2 9.79592C13.8632 9.79592 14.4 9.24816 14.4 8.57143C14.4 7.89469 13.8632 7.34694 13.2 7.34694C12.5368 7.34694 12 7.89469 12 8.57143C12 9.24816 12.5368 9.79592 13.2 9.79592ZM2.8 9.79592C3.4632 9.79592 4 9.24816 4 8.57143C4 7.89469 3.4632 7.34694 2.8 7.34694C2.1368 7.34694 1.6 7.89469 1.6 8.57143C1.6 9.24816 2.1368 9.79592 2.8 9.79592Z" fill={color}/>
    </svg>
  )
}

export default ZukIcon
