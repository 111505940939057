import { Spinner } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import { List, Map } from 'immutable'
import { observer } from 'mobx-react'
import { isEmpty, path, pathOr, prop } from 'ramda'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushGroupMachineryReport,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import { Icons } from 'ui/Machines/icons'
import { getStore } from '../../../stores/storesRegistry'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { FilterResult } from '../result/groupmachinery'
import { createExcelFile } from './ToExcellHandler'

const colorIcon = '#78c800'

const GroupMachineryReports:FC<FilterControlConfig> = props => {
  //@ts-expect-error
  const detailLoad = useSelector((state: AppStateType) => state.reports.get('groupmachinery').get('detailLoad'))

  //@ts-expect-error
  const resultLoad = useSelector((state: AppStateType) => state.reports.get('groupmachinery').get('resultLoad'))

  const groupMachineryReport = useSelector((state: AppStateType) =>

  //@ts-expect-error
    state.reports.get('groupmachinery').get('groupmachinery'),
  )

  const endLoadReport = detailLoad.size === resultLoad.size

  const login = useSelector((state: AppStateType) => state.user.info.operatorLogin || state.user.info.login) || ''
  const stateDispatch = useDispatch()
  const translate = useTranslate()

  const onFinishLoadReport = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  useEffect(() => {
    if(resultLoad.size >= detailLoad.size){
      onFinishLoadReport(ReportType.Groupmachinery)
    }
  }, [resultLoad.size, detailLoad.size, onFinishLoadReport])

  const unitsWithRegistersData = getStore('dictionaries').targets2.unitsWithRegistersData

  const { from, to } = pathOr(
    {
      from: new Date().startOf('day').format('YYMMDDHHmmss000'),
      to  : new Date().endOf('day').format('YYMMDDHHmmss000'),
    },
    ['filter', 1, 'result', 0],
    props,
  )

  const { modelSections, report } = useMemo(() => {
    const modelSectionsTemplate = {
      ZUK    : { name: 'Combine Harvesters', icon: Icons.ZUK(colorIcon), types: Map() },
      KUK    : { name: 'Forage Harvesters', icon: Icons.KUK(colorIcon), types: Map() },
      KS     : { name: 'Power facilities', icon: Icons.KS(colorIcon), types: Map() },
      TRACTOR: { name: 'tractor', icon: Icons.TRACTOR(colorIcon), types: Map() },
      others : { name: 'Other', icon: null, types: Map() },
    }

    let normalizeReport = {
      'INC_SUMM_PATH'         : { name: 'Distance traveled', measure: 'km', data: Map() },
      'INC_SUMM_MOTOR'        : { name: 'Engine running time', measure: 'hh:mm:ss', data: Map() },
      'AREA_PER_TIME'         : { name: 'Average time performance', measure: 'ha/h', data: Map() },
      'TIME_MOVE_NO_HARVEST'  : { name: '', measure: '', data: Map() },
      'TIME_NO_LINK'          : { name: '', measure: '', data: Map() },
      'TIME_OFF'              : { name: '', measure: '', data: Map() },
      'TIME_STOP_DON'         : { name: '', measure: '', data: Map() },
      'TIME_HARVEST'          : { name: '', measure: '', data: Map() },
      'TIME_STOP_HARVEST'     : { name: '', measure: '', data: Map() },
      'TIME_STOP_HARVEST_FULL': { name: '', measure: '', data: Map() },
    }

    const withGroupMachineryReport = Boolean(groupMachineryReport.size)

    const typeCaption = withGroupMachineryReport ? 'unitTypeName' : 'typeName'
    const dataCaption = withGroupMachineryReport ? 'registers' : 'data'
    const data = withGroupMachineryReport ? groupMachineryReport.toJS()[0] : unitsWithRegistersData

    const models = Object.keys(modelSectionsTemplate)

    //@ts-expect-error
    data.forEach(unit => {
      const unitModel = prop('model', unit)
      const unitType = prop(typeCaption, unit)

      const model = models.includes(unitModel) ? unitModel : 'others'

      //@ts-expect-error
      if(!modelSectionsTemplate[model].types.has(unitType)) {
        //@ts-expect-error
        modelSectionsTemplate[model].types = modelSectionsTemplate[model].types.set(unitType, List([]))
      }

      //@ts-expect-error
      modelSectionsTemplate[model].types = modelSectionsTemplate[model].types.setIn(
        [unitType],

        //@ts-expect-error
        List([...modelSectionsTemplate[model].types.getIn([unitType]).toJS(), unit.id]),
      )

      Object.keys(normalizeReport).forEach(key => {
        const name = path([dataCaption, key, 'name'], unit)
        const measure = path([dataCaption, key, 'measure'], unit)
        const value = path([dataCaption, key, 'value'], unit)

        //@ts-expect-error
        if(!normalizeReport[key].name && name) {
          //@ts-expect-error
          normalizeReport[key].name = name
        }

        //@ts-expect-error
        if(!normalizeReport[key].measure && measure) {
          //@ts-expect-error
          normalizeReport[key].measure = measure
        }

        //@ts-expect-error
        normalizeReport[key].data = normalizeReport[key].data.set(unit.id, value || 0)
      })
    })

    return { modelSections: modelSectionsTemplate, report: normalizeReport }
  }, [groupMachineryReport, unitsWithRegistersData])

  const numRow = useMemo(() => {
    if(modelSections.ZUK.types.size > 1 && modelSections.KUK.types.size > 1
      || modelSections.ZUK.types.size === 1 && modelSections.KUK.types.size > 1
      || modelSections.ZUK.types.size > 1 && modelSections.KUK.types.size === 1) {
      return 3
    }
    else if(modelSections.ZUK.types.size < 1 && modelSections.KUK.types.size > 1
      || modelSections.ZUK.types.size > 1 && modelSections.KUK.types.size < 1
      || modelSections.ZUK.types.size === 1 && modelSections.KUK.types.size === 1) {
      return 2
    }

    return 1
  }, [modelSections.KUK.types.size, modelSections.ZUK.types.size])

  const handleExportReportToExcel = useCallback(() => {
    createExcelFile({
      from,
      login,
      modelSections,
      numRow,

      //@ts-expect-error
      report,
      t: translate,
      to,
    })
  }, [from, modelSections, numRow, login, translate, report, to])

  const filterIsEmpty = useMemo(() =>
    isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items),
  [props.filter])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={translate('Select a different report or change the filtering conditions')}
        title={translate('There is no data on which to build this report')}
      >

        <FilterControl
          button={true}
          disableButton={!endLoadReport}
          translate={translate}
          {...props}
        />

        {endLoadReport &&
            <Button
              type="primary"
              icon="download"
              style={{
                marginBottom: '10px',
                background  : '#78C800',
                border      : '1px solid #78C800',
              }}
              onClick={handleExportReportToExcel}
            >
              {translate('download excel')}
            </Button>
        }

        {!endLoadReport
          ? <StyledSpinner />
          : <FilterResult
            normilizeReport={report}
            to={to}
            from={from}
            numRow={numRow}
            modelSections={modelSections}
          />
        }

      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
    },
    {
      id      : 2,
      type    : 'date',
      name    : 'select_a_date',
      section : 'time',
      result  : [],
      validate: true,
    },
  ],

  callback: data => dispatch => {
    data.callbackOk = (json: unknown[]) => {
      dispatch(
        pushReport({ data: json, type: ReportType.Groupmachinery, resultLoad: [] }),
      )
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.Groupmachinery))
      dispatch(errorReport(ReportType.Groupmachinery))
    }

    dispatch(clearReport(ReportType.Groupmachinery))
    pushGroupMachineryReport(dispatch, data, ReportType.Groupmachinery)
  },
  clear: () => dispatch => {
    dispatch(clearReport(ReportType.Groupmachinery))
  },

  //@ts-expect-error
})(observer(GroupMachineryReports))

const StyledSpinner = styled(Spinner)`
  position: fixed;
  left: 50%;
  top: 50%;
`
