import { action, makeObservable, observable } from 'mobx'
import { isEmpty, isNil, propOr } from 'ramda'
import { FilterableListStore } from 'stores/generic/FilterableListStore'
import { TUnit } from 'types'
import { notificationStatus } from 'utils'

export type NotificationStatus = keyof typeof notificationStatus

export type SumNotification = {
  status: NotificationStatus
  value: number
}

export type UnitWithNotifications = TUnit & {
  sumNotifications: SumNotification[]
}

export class NoticesUnitsListStore {
  @observable isLoading = false
  @observable sortByStatus: NotificationStatus = 1

  list = new FilterableListStore<UnitWithNotifications>()

  constructor() {
    makeObservable(this)

    this.list.setSearchDelegate((needed: string, row) => isEmpty(needed) || isNil(needed)
      || propOr<'', typeof row, string>('', 'name', row).toLowerCase().indexOf(needed.toLowerCase()) >= 0,
    )

    this.list.setSortField(
      (item: UnitWithNotifications) => item.sumNotifications.find(S => S.status === this.sortByStatus)?.value || 0, -1)
  }

  @action
  setSortByStatus(status: NotificationStatus) {
    this.sortByStatus = status

    // Пересортируем список при изменении значения сортировки
    this.list.sort()
  }

  @action
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }
}
