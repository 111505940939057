import { Search } from '@agdt/agrotronic-react-components'
import { flatten, isEmpty, length, pipe, values } from 'ramda'
import React, { useEffect, useMemo, useReducer } from 'react'
import { AutoSizer } from 'react-virtualized'
import styled from 'styled-components'
import TooltipD from 'ui/Tooltip'
import { assertNever } from 'utils/assertNever'
import { Selector } from './selector'
import Tree from './Tree'

const styles = {
  tooltip: { right: '0', bottom: '0' },
}

type TState = {
  searchText: string
  all: boolean
  models: string[]
  statuses: string[]
}

type TAction =
  | { type: 'SET_SEARCH', payload: TState['searchText'] }
  | { type: 'SET_ALL', payload: TState['all'] }
  | { type: 'SET_MODELS', payload: TState['models'] }
  | { type: 'SET_STATUSES', payload: TState['statuses'] }
  | { type: 'RESET' }

const init = (): TState => ({
  all       : false,
  models    : [],
  searchText: '',
  statuses  : [],
})

const reducer = (state: TState, action: TAction): TState => {
  switch(action.type) {
    case 'SET_SEARCH':
      return { ...state, searchText: action.payload }
    case 'SET_ALL':
      return { ...state, all: action.payload }
    case 'SET_MODELS':
      return { ...state, models: action.payload }
    case 'SET_STATUSES':
      return { ...state, statuses: action.payload }
    case 'RESET':
      return init()
    default:
      return assertNever(action, state)
  }
}

//@ts-expect-error
export default function (props) {
  const {
    clearAllMachines,
    farmsWithFilteredUnites,
    machines,
    models,
    name,
    openedItems,
    result,
    setAllMachines,
    setFilteredList,
    setMachine,
    setMachines,
    statuses,
    translate,
  } = props

  const [state, dispatch] = useReducer(reducer, farmsWithFilteredUnites, init)

  const onSearch = (searchText: string) => {
    dispatch({ type: 'SET_SEARCH', payload: searchText })
  }

  const setAll = () => {
    dispatch({ type: 'SET_ALL', payload: true })
    setAllMachines()
  }

  const clearAll = () => {
    dispatch({ type: 'SET_ALL', payload: false })
    clearAllMachines()
  }

  useEffect(() => {
    if(state.all && isEmpty(result)) {
      dispatch({ type: 'SET_ALL', payload: false })
    }
  }, [result])

  useEffect(() => {
    const { models, statuses, searchText } = state

    //@ts-expect-error
    const farmsWithFilteredUnits = machines.reduce((acc, farm) => {
      //@ts-expect-error
      const filteredUnits = farm.units.filter(unit => {
        let isSuit = true

        if(searchText) {
          isSuit = unit.name.toLowerCase().includes(searchText.toLowerCase())
        }

        if(models.length && isSuit) {
          isSuit = models.includes(unit.typeName)
        }

        if(statuses.length && isSuit) {
          isSuit = statuses.includes(unit.status.valueF)
        }

        return isSuit
      })

      if(!isEmpty(filteredUnits)) {
        acc.push({ ...farm, units: filteredUnits })
      }

      return acc
    }, [])

    setFilteredList(farmsWithFilteredUnits)
  }, [state.models, state.statuses, state.searchText, machines])

  const handlerModels = (param: string) => {
    let models = [...state.models]

    if(param) {
      if(models.includes(param)) {
        models = models.filter(item => item !== param)
      } else {
        models.push(param)
      }
    } else {
      models = []
    }

    dispatch({ type: 'SET_MODELS', payload: models })
  }

  const handlerStatuses = (param: string) => {
    let statuses = [...state.statuses]

    if(param) {
      if(statuses.includes(param)) {
        statuses = statuses.filter(item => item !== param)
      } else {
        statuses.push(param)
      }
    } else {
      statuses = []
    }

    dispatch({ type: 'SET_STATUSES', payload: statuses })
  }

  const isFiltered = state.searchText || state.models.length || state.statuses.length
  const machinesCount = useMemo(() => pipe(values, flatten, length)(result) || '', [result])

  return (
    <FilterItemContainer>
      <FilterItemHead>
        <FilterItemName>{translate(name)}</FilterItemName>
        <FilterItemClear onClick={clearAll}>{translate('clear')}</FilterItemClear>
        <span>{machinesCount}</span>
      </FilterItemHead>

      <>
        <SearchStyled value={state.searchText} onChange={onSearch} />

        <FilterItemsContainer>
          {isFiltered && isEmpty(farmsWithFilteredUnites)
            ? <p>{translate('not_of_data')}</p>
            : <ContainerStyled>
              <AutoSizer disableWidth>
                {({ height }) => {
                  return height ? <Tree

                    //@ts-expect-error
                    height={height}
                    items={farmsWithFilteredUnites}
                    openedFarm={openedItems}
                    result={result}
                    setMachines={setMachines}
                    setMachine={setMachine}
                  /> : null
                }}
              </AutoSizer>
            </ContainerStyled>
          }
        </FilterItemsContainer>

        <div>
          <SelectorContainer>
            <SelectorStyled
              renderList={models}
              textAfterSelect={translate('by_model')}
              result={state.models}
              select={handlerModels}
              first={true}
            />

            <SelectorStyled
              renderList={statuses}
              textAfterSelect={translate('by_active')}
              result={state.statuses}
              select={handlerStatuses}
              last={true}
            />
          </SelectorContainer>

          <AllSelector onClick={state.all ? clearAll : setAll} selected={state.all}>
            {translate(`${state.all ? 'deselecting' : 'selecting'}`)}
          </AllSelector>
        </div>
      </>

      <TooltipD
        text={translate('Choice of equipment / group of equipment')}
        style={styles.tooltip}
      />
    </FilterItemContainer>
  )
}

const ContainerStyled = styled.div`
  height: 300px;
`

const FilterItemContainer = styled.div`
  width: 200px;
  padding: 0 16px;
  box-sizing: content-box;
`

const FilterItemHead = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const FilterItemName = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
  text-transform: uppercase;
`

const FilterItemClear = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #D10029;
  position: relative;
  top: -15px;
  left: 15px;
  cursor: pointer;
`

const SearchStyled = styled(Search)`
  height: 40px;
  margin-bottom: 10px;
`

const FilterItemsContainer = styled.div`
  height: 300px;
`

const SelectorContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

const AllSelector = styled.button<{ selected: boolean }>`
  color: ${({ selected }) => selected ? '#ffffff' : '#202020'};
  background-color: ${({ selected }) =>
    selected ? 'rgba(234,14,14,0.8)' : '#ffffff'};
  border: ${({ selected }) =>
    selected ? '1px solid #D10029' : '1px solid #C9CCD4'};
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    color: ${({ selected }) => selected ? '#ffffff' : '#202020'};
    background-color: ${({ selected }) => selected ? '#D10029' : '#ffffff'};
    border: ${({ selected }) =>
    selected ? '1px solid #D10029' : '1px solid #202020'};
  }
`

const SelectorStyled = styled(Selector)`
  width: 50%;
  height: 25px;
`
