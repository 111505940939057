import './picker.scss'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { ColorType } from './Day'
import { MonthPiker } from './MonthPicker'

type RangePickerProps = {
  permissions?: number
  history?: number
  format?: string
  colors?: ColorType[]
  initPicker?: {
    from: moment.MomentInput
    to: moment.MomentInput
  }
  range?: {
    from: moment.MomentInput
    to: moment.MomentInput
  }
  switchDays: (value: {
    from: moment.MomentInput
    to: moment.MomentInput
  }) => void
  switchMonth?: (type: 'left' | 'right', date: string) => void
}

export const RangePicker:FC<RangePickerProps> = ({
  permissions,
  history,
  format = 'DD.MM.YY',
  colors,
  initPicker = {
    from: moment().subtract(1, 'months').format('DD.MM.YY'),
    to  : moment().format('DD.MM.YY'),
  },
  range,
  switchDays,
  switchMonth,
}) => {
  const [activeMonths, setActiveMonths] = useState({
    left : initPicker.from,
    right: initPicker.to,
  })

  const [activeRange, setActiveRange] = useState({
    from: range ? range.from : null,
    to  : range ? range.to: null,
  })

  const setRange = (value: moment.Moment) => {
    let { from, to } = activeRange

    if(activeRange.from && activeRange.to) {
      from = value
      to = null
    } else {
      if(activeRange.from) {
        if(activeRange.from > value) {
          to = from
          from = value
        } else if(activeRange.from === value) {
          from = null
          to = null
        } else {
          to = value
        }
      } else {
        from = value
      }
    }

    setActiveRange({
      from,
      to,
    })

    switchDays({
      from,
      to,
    })
  }

  const updateActiveMonth = (type: 'left' | 'right', date: string) => {
    setActiveMonths(prevValue => ({
      ...prevValue,
      [type]: date,
    }))

    if(switchMonth){
      switchMonth(type, date)
    }
  }

  return (
    <div className="Date-RangePicker">
      <MonthPiker
        type="left"
        date={initPicker.from}
        skipMonth={activeMonths.right}
        permissions={permissions}
        history={history}
        format={format}
        colors={colors}
        activeRange={activeRange}
        setRange={setRange}
        setActiveMonth={updateActiveMonth}
      />
      <MonthPiker
        type="right"
        date={initPicker.to}
        skipMonth={activeMonths.left}
        permissions={permissions}
        history={history}
        format={format}
        colors={colors}
        activeRange={activeRange}
        setRange={setRange}
        setActiveMonth={updateActiveMonth}
      />
    </div>
  )
}
