import { TDetailRegisters } from '../../../types'

export const getFuelValue = (fuelRegister: TDetailRegisters['FUEL'] | null): null | number => {
  if(!fuelRegister || fuelRegister.value === null) {
    return null
  }

  if(fuelRegister.measure === '%') {
    return fuelRegister.value
  }

  const value = fuelRegister.valueMax ? Math.min(fuelRegister.value, fuelRegister.valueMax) : fuelRegister.value
  return Math.round(value)
}

export const getFuelProgress = (fuel: number | null, measure?: string, fuelMax?: number ): number => {
  if(fuel === null) {
    return 0
  }

  return measure !== '%' && fuelMax
    ? fuel / fuelMax * 100

    // От некоторых машин приходит значение топлива в баке > 100%. Нужно ограничивать.
    : Math.min(fuel, 100)
}

export const cookMainInfoData = (registers: TDetailRegisters) => {
  const fuelValue = getFuelValue(registers.FUEL)

  return {
    active: {
      formattedValue: registers.ACTIVE.value
        ? new Date(registers.ACTIVE.value).user.format('DD.MM.YY HH:mm:ss')
        : null,
    },

    fuel: {
      measure : registers.FUEL?.measure,
      name    : registers.FUEL?.name,
      progress: getFuelProgress(
        fuelValue,
        registers.FUEL?.measure,

        registers.FUEL?.measure ==='л'
          ? registers.FUEL?.valueMax
          : undefined,
      ),

      value: fuelValue,
    },

    speed: {
      measure: registers.SPEED.measure,
      name   : registers.SPEED.name,
      value  : registers.SPEED.value,
    },

    status: {
      value: registers.STATUS?.value,
    },
  }
}

export type TMainInfoData = ReturnType<typeof cookMainInfoData>
