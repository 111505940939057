export type Map = Readonly<{
  arrow: boolean
  centerPolygon: unknown
}>

export const init = (): Map => ({
  arrow        : false,
  centerPolygon: null,
})

export const setPolygon = (state: Map, centerPolygon: unknown): Map => ({
  ...state,
  centerPolygon,
})
