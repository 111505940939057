import TablePaginator from 'components/Paginator'
import { connect } from 'react-redux'
import * as notificationsActions from 'reducers/notification/actions'
import { getRecords } from 'reducers/notification/selectors'
import { AppStateType } from 'reducers/store'

const mapStateToProps = (state: AppStateType) => {
  const items = getRecords(state.notification)
  const page = state.notification.page
  const pageSize = state.notification.pageSize

  return {
    itemsCount: items.length,
    page      : page,
    pageSize  : pageSize,
  }
}

const mapDispatchToProps = {
  onPageChange    : notificationsActions.setPage,
  onPageSizeChange: notificationsActions.setPageSize,
}

export default connect(mapStateToProps, mapDispatchToProps)(TablePaginator)
