import React from 'react'
import styled from 'styled-components'
import EyeIcon from '../../../../assets/EyeIcon'

type TProps = {
  handleSetSelectedUnit: (id: number) => void
  id: number
  number: number
  selected: boolean
  title: string
}

const Row = ({ handleSetSelectedUnit, id, number, selected, title }: TProps) => {
  return <RowWrapper onClick={() => handleSetSelectedUnit(id)}>
    <UnitWrapper>
      <Title>{title}</Title>
      <Number>{number}</Number>
    </UnitWrapper>

    <EyeWrapper>
      <EyeIcon color={selected ? '#78C800' : '#999999'} />
    </EyeWrapper>
  </RowWrapper>
}

export default Row

const RowWrapper = styled.div`
  display: flex;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
`

const EyeWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  
  ${RowWrapper}:hover & {
    background-color: #D6D6D6;
  }
`

const UnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #202020;
`

const Number = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #A1A1A1;
`
