import { formats } from '@agdt/agrotronic-react-components'
import { last } from 'ramda'
import { productivityValues } from './constants'
import { harvestColors } from './constants'

export const loadColors = ['#06a9f5', '#78c800', '#f1c340', '#d10a40']
export const speedColors = ['#202020', '#06a9f5', '#78c800', '#fb8b14', '#d10a40']
export const productivityColors = ['#D00000', '#FF6600', '#FFCC00', '#FFFF00', '#CCFFCC', '#00FF00']

export const colorLoadItem = ({ LOAD }: {LOAD: number | null}) =>
  !LOAD || LOAD < 25
    ? loadColors[0]
    : LOAD < 50
      ? loadColors[1]
      : LOAD < 75
        ? loadColors[2]
        : loadColors[3]

export const colorSpeedItem = (item: {SPEED: number}) =>
  item.SPEED < 3
    ? speedColors[1]
    : item.SPEED < 6
      ? speedColors[2]
      : item.SPEED < 9
        ? speedColors[3]
        : speedColors[4]

const colorProductivityItem = (param: number, model: 'KUK' | 'ZUK') => {
  const colorIndex = productivityValues[model].findIndex(V => param < V[1])
  return colorIndex > -1 ? productivityColors[colorIndex] : last(productivityColors)
}

export type PointInfo = {
  course: number
  lon: number
  lat: number
  load: number
  speed: number
  mode: number
  utc?: string
  perf: number
}

export const addCourse = (track: PointInfo[]) => {
  const calculateCourse = (item: PointInfo, prevItem: PointInfo) => {
    let course = prevItem.course
    const dx = (item.lon - prevItem.lon) * Math.PI / 180

    const dy =
      Math.log(Math.tan(Math.PI / 4 + item.lat * Math.PI / 360)) -
      Math.log(Math.tan(Math.PI / 4 + prevItem.lat * Math.PI / 360))

    if(dx || dy) {
      course = 90 - Math.atan2(dy, dx) * 180 / Math.PI
      while(course < 0) {course += 360}
      while(course >= 360) {course -= 360}
      course = Math.round(course)
    }

    return course
  }

  return track.reduce((result: PointInfo[], item: PointInfo) => {
    const prevItem = result[result.length - 1]
    result.push(prevItem ? { ...item, course: calculateCourse(item, prevItem) } : { ...item, course: 0 })
    return result
  }, [])
}

export const adaptationPoints = (track: PointInfo[]) => track.map((point, index) => ({
  course       : point.course,
  CURR_PERF_TON: point.perf || null,
  ID           : index,
  lat          : point.lat,
  lng          : point.lon,
  LOAD         : point.load || 0,
  SPEED        : point.speed || 0,
  status       : point.mode || 0,
  t            : point?.utc || null,
  utc          : new Date(formats.api.parseDateTimeFromApi(String(point.utc))).getTime(),
})).sort((a, b) => Number(a.t) - Number(b.t))

type HarvestInfo = {
  TRACK_HARVEST: {
    path: {
      status: number
    }[]
    color: string
  }[]
}

const stepHarvest = (result: HarvestInfo, item: {status: number}) => {
  const lastLine = last(result.TRACK_HARVEST) || null
  const color = harvestColors[item.status]

  if(lastLine) {
    lastLine.path.push(item)

    if(color !== lastLine.color) {
      result.TRACK_HARVEST.push({ color, path: [item] })
    }
  } else {
    result.TRACK_HARVEST.push({ color, path: [item] })
  }
}

type LoadInfo = {
  TRACK_LOAD: {
    path: {
      LOAD: number | null
    }[]
    color: string
  }[]
}

const stepLoad = (result: LoadInfo, item: {LOAD: number | null}) => {
  const lastLineLoad = result.TRACK_LOAD[result.TRACK_LOAD.length - 1]
  const color = colorLoadItem(item)

  if(lastLineLoad) {
    lastLineLoad.path.push(item)

    if(color !== lastLineLoad.color) {
      result.TRACK_LOAD.push({ color, path: [item] })
    }
  } else {
    result.TRACK_LOAD.push({ color, path: [item] })
  }
}

type SpeedInfo = {
  TRACK_SPEED: {
    path: {
      SPEED: number
    }[]
    color: string
  }[]
}

const stepSpeed = (result: SpeedInfo, item: {SPEED: number}) => {
  const lastLineSpeed = result.TRACK_SPEED[result.TRACK_SPEED.length - 1]
  const color = colorSpeedItem(item)

  if(lastLineSpeed) {
    lastLineSpeed.path.push(item)

    if(color !== lastLineSpeed.color) {
      result.TRACK_SPEED.push({ color, path: [item] })
    }
  } else {
    result.TRACK_SPEED.push({ color, path: [item] })
  }
}

type ProductivityInfo = {
  TRACK_PRODUCTIVITY_KUK: {
    path: {
      CURR_PERF_TON: number | null
    }[]
    color: string
  }[]
  TRACK_PRODUCTIVITY_ZUK: {
    path: {
      CURR_PERF_TON: number | null
    }[]
    color: string
  }[]
}

const stepProductivity = (result: ProductivityInfo, item: {CURR_PERF_TON: number | null}, model: 'KUK' | 'ZUK') => {
  if(!item.CURR_PERF_TON) {
    return
  }

  const lastLineProductivity = result[`TRACK_PRODUCTIVITY_${model}`][result[`TRACK_PRODUCTIVITY_${model}`].length - 1]
  const color = colorProductivityItem(item.CURR_PERF_TON, model)

  if(lastLineProductivity) {
    lastLineProductivity.path.push(item)

    if(color !== lastLineProductivity.color) {
      result[`TRACK_PRODUCTIVITY_${model}`].push({ color: String(color), path: [item, item] })
    }
  } else {
    result[`TRACK_PRODUCTIVITY_${model}`].push({ color: String(color), path: [item, item] })
  }
}

type TrackInfo = {
  CURR_PERF_TON: number | null
  LOAD: number | null
  status: number
  SPEED: number
}

export default (track: TrackInfo[]) => {
  const needTrackLoad = track.some(item => item.LOAD !== 0)
  const needTrackProductivity = track.some(item => item.CURR_PERF_TON)

  return track.reduce((result, item) => {
    stepHarvest(result, item)

    if(needTrackLoad) {
      stepLoad(result, item)
    }

    if(needTrackProductivity) {
      stepProductivity(result, item, 'KUK')
      stepProductivity(result, item, 'ZUK')
    }

    stepSpeed(result, item)
    return result
  },
  { TRACK_HARVEST: [], TRACK_LOAD: [], TRACK_PRODUCTIVITY_KUK: [], TRACK_PRODUCTIVITY_ZUK: [], TRACK_SPEED: [] },
  )
}
