import Tippy from '@tippyjs/react'
import { icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { notificationStatus } from 'utils'

type NotificationType = {
  duration?: number
  lon?: unknown
  lat?: unknown
  timeReset?: string
  timeSet?: string
  status: keyof typeof notificationStatus
}

const { MapIcon } = icons
const formatDate = (date: string) => Date.parseDateTimeFromApi(date).user.format('DD.MM.YY HH:mm:ss')

export default (notification: NotificationType, translate: TTranslate) => ({
  ...notification,
  duration: notification.duration ? prepareDuration(notification.duration) : '',
  position: !!(notification.lon && notification.lat) && <MapIcon />,
  status  : !!notification.status &&
    <Tippy
      content={translate(notificationStatus[notification.status].typeTranslate)}
    >
      <StatusColor color={notificationStatus[notification.status].color} />
    </Tippy>,

  timeReset: notification.timeReset ? formatDate(notification.timeReset) : '',
  timeSet  : notification.timeSet ? formatDate(notification.timeSet) : '',
})

const StatusColor = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ color }) => color};
`
