import L from 'leaflet'
import { eqBy, prop, unionWith } from 'ramda'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Map, Marker } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { TrackTypes, TStatus } from '../../../../types'
import { iconStart } from '../../markersType'
import { Point, PolylineWrap } from './PolylineWrap'
import { PopupWrap } from './PopupWrap'
import { TransportPopupUnit } from './PopupWrap/TransportPopup'
import { getTrackWithPoint } from './utils'
import { getFormattedTime } from './utils/getFormattedTime'

export type PathType = {
  ID: number
  course: number
  utc: number
  status: number
  SPEED: number
  LOAD: number
} & Point

export type TrackType = {
  path: PathType[]
  isTeleported?: boolean
  color: string
  id?: number
}

type TrackItemProps = {
  activeTargets2?: number
  animateTrack: boolean
  arrow: boolean
  corridor: number
  deleteTrack: (value: {imei: number}) => void
  different?: PathType[]
  endDateIsToday: boolean
  imei: number
  isWatched: boolean
  manipulation: boolean
  map: Map
  model: string
  name: string
  noZoom: boolean
  polylineKey: number
  productivity: boolean
  static: boolean
  status?: TStatus | null
  track: TrackType[]
  trackUpdated?: boolean
  transport?: TransportPopupUnit
  type?: TrackTypes
}

export const TrackItem: FC<TrackItemProps> = ({
  activeTargets2,
  animateTrack,
  arrow,
  corridor,
  deleteTrack,
  different,
  endDateIsToday,
  imei,
  isWatched,
  manipulation,
  map,
  model,
  name,
  noZoom,
  polylineKey,
  productivity,
  static: staticProp,
  status,
  track: trackProps,
  trackUpdated,
  transport,
  type,
}) => {
  const getLastTrackPoint = () => {
    const lastTrackPath = trackProps[trackProps.length-1].path

    return lastTrackPath[lastTrackPath.length-1]
  }

  const [start, setStart] = useState(trackProps[0].path[0])
  const [track, setTrack] = useState(trackProps)
  const [finish, setFinish] = useState(getLastTrackPoint())
  const [animateDuration, setAnimateDuration] = useState(60000)
  const [animatePoint, setAnimatePoint] = useState<PathType[]>([])
  const [lastAnimatedPointTime, setLastAnimatedPointTime] = useState(0)
  const [weight, setWeight] = useState(2)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const animateRef = useRef<boolean>(false)

  const prevProps = useRef({
    animateTrack,
    trackLength: trackProps.length,
    trackUpdated,
    type,
  })

  const translate = useTranslate()

  const zoomTrack = () => {
    const arrayPoint = track.reduce((list: [number, number][], item) => {
      item.path.forEach(it => {
        if(it.lat && it.lng) {
          list.push([it.lat, it.lng])
        }
      })

      return list
    }, [])

    const compare = (a1: [number, number], a2: [number, number]) =>
      a1.length === a2.length && a1.every((v, i) => v === a2[i])

    const uniqeArrayPoint = arrayPoint.filter(
      (v, i, a) => a.findIndex(v1 => compare(v, v1)) === i,
    )

    if(!uniqeArrayPoint.length || noZoom){
      return
    }

    if(uniqeArrayPoint.length === 1) {
      map.leafletElement.flyTo(arrayPoint[0])
    } else {
      if(L.latLngBounds(arrayPoint)) {
        map.leafletElement.flyToBounds(L.latLngBounds(arrayPoint))
      }
    }
  }

  const getMetersPerPixel = (): number => {
    const centerLatLng = map.leafletElement.getCenter()
    const pointC = map.leafletElement.latLngToContainerPoint(centerLatLng)
    const pointX = L.point(pointC.x + 10, pointC.y)

    const latLngX = map.leafletElement.containerPointToLatLng(pointX)
    return centerLatLng.distanceTo(latLngX) / 10
  }

  const getWeight = (): number => {
    return corridor / getMetersPerPixel()
  }

  const updateWeight = () => {
    const newWeight = getWeight()

    map.leafletElement.eachLayer(layer => {
      //@ts-expect-error
      if(layer?.options?.color && layer.options.positions && layer.options.positions.length > 1) {
        //@ts-expect-error
        layer.setStyle({ weight: newWeight })
      }
    })

    setWeight(newWeight)
  }

  const addPointToTrack = (point: PathType) => {
    setFinish(point)
    setLastAnimatedPointTime(point.utc)

    const animate = animateRef.current

    setTrack(prevTrack => getTrackWithPoint({
      animate,
      point,
      track    : prevTrack,
      trackType: type,
    }))
  }

  const iterationAnimate = (animatePoints: PathType[]) => {
    let numberPoint = 0
    const newAnimateDuration = animateDuration / animatePoints.length

    setAnimateDuration(newAnimateDuration)

    if(animatePoints[numberPoint]) {
      addPointToTrack(animatePoints[numberPoint])
      numberPoint += 1
    }

    animateRef.current = true

    const animateIntervalId = setInterval(() => {
      if(animatePoints[numberPoint]) {
        addPointToTrack(animatePoints[numberPoint])
        numberPoint += 1
      } else {
        // Если проиграны все точки сбрасываем состояние
        numberPoint = 0
        animateRef.current = false
        setAnimatePoint([])
        clearInterval(animateIntervalId)
      }
    }, newAnimateDuration)

    timerRef.current = animateIntervalId
  }

  const setAnimateMarker = () => {
    // объединяем вновь полученные точки с точками прогирываемыми в данный момент по времени
    //@ts-expect-error
    const newAnimatePoint = unionWith(eqBy(prop('utc')), animatePoint, different)

    // Избегаем проигрывания точек которые уже проиграны
      .filter(P => P.utc > lastAnimatedPointTime)

    setAnimatePoint(newAnimatePoint)

    if(newAnimatePoint.length === 1 || !animateRef.current) {
      iterationAnimate(newAnimatePoint)
    }
  }

  useEffect(()=> {
    if(track.length) {
      zoomTrack()
    }

    if(productivity) {
      map.leafletElement.on('zoomend', updateWeight)
    }

    return () => {
      if(productivity) {
        map.leafletElement.off('zoomend', updateWeight)
      }

      if(timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  useEffect(()=> {
    if(prevProps.current.trackUpdated !== trackUpdated && different?.length) {
      setAnimateMarker()
    }

    if(type && type !== prevProps.current.type || prevProps.current.trackLength !== trackProps.length) {
      setFinish(getLastTrackPoint())
      setTrack(trackProps)
      setStart(trackProps[0].path[0])
    }

    if(prevProps.current.trackLength !== trackProps.length) {
      zoomTrack()
    }

    // остановить анимацию, если наблюдение выключено
    if(animateTrack !== prevProps.current.animateTrack && !animateTrack) {
      animateRef.current = false

      if(timerRef.current) {
        clearInterval(timerRef.current)
      }
    }

    prevProps.current = {
      animateTrack,
      trackLength: trackProps.length,
      trackUpdated,
      type,
    }
  })

  if(!start) {
    return null
  }

  return (
    <div>
      {start.lat && start.lng &&
      <Marker
        position={[start.lat, start.lng]}
        key={'track-start-icon'}
        icon={iconStart}
        zIndexOffset={1000}
      >
        <PopupWrap
          {...start}
          time={getFormattedTime(start.t)}
          imei={imei}
          name={name}
          manipulation={manipulation}
          deleteTrack={deleteTrack}
          translate={translate}
          measure={model === 'ZUK' ? 'cwt/ha' : 't/ha'}
        />
      </Marker>
      }

      {track.map((item, index) =>
        <PolylineWrap
          dashed={item.isTeleported}
          map={map}
          key={`${item.color}-${item.path[0].ID}-${type}`}
          rotate={
            index === track.length - 1
              ? finish.course
              : undefined
          }
          model={
            index === track.length - 1
              ? model
              : undefined
          }
          measureModel={model}
          static={
            index === track.length - 1
              ? staticProp
              : undefined
          }
          finish={
            index === track.length - 1
              ? finish
              : undefined
          }
          arrow={arrow}
          polylineKey={`${polylineKey}-${item.id}`}
          name={name}
          last={index === track.length - 1}
          animateDuration={
            index === track.length - 1
              ? animateDuration
              : undefined
          }

          animate={
            index === track.length - 1
              ? animateRef.current
              : undefined
          }

          deleteTrack={deleteTrack}
          manipulation={manipulation}
          {...item}
          translate={translate}
          transport={transport}
          activeTargets2={activeTargets2}
          status={status}
          productivity={productivity}
          weight={weight}
          imei={imei}
          isWatched={isWatched}
          endDateIsToday={endDateIsToday}
        />,
      )}
    </div>
  )
}
