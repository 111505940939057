import { HeaderCell, HeaderRow, Table, TableBody, TableCell, TableHeader, TableRow } from '@agdt/agrotronic-react-components'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { TasksArrayType, TaskType } from 'reducers/notificationTasks/state'
import { editNotificationTask, updateNotificationSettingsByTypeId } from 'services/apiService/modules'
import styled from 'styled-components'
import { CellWithCheckbox } from './CellWithCheckbox'

type ChanelName = 'active' | 'online' | 'email' | 'sms'

type Props = {
  notifications: TasksArrayType
  smsChanelIsDisabled: boolean
  isUser: boolean
  editTask: (response: TaskType) => void
}

export const Notifications: FC<Props> = ({ editTask, isUser, notifications, smsChanelIsDisabled }) => {
  const t = useTranslate()

  const columns = useMemo(() => [
    { caption: t('On'), id: 'active' },
    { caption: t('Event'), id: 'typeName' },
    { caption: t('Web'), id: 'online' },
    { caption: 'Email', id: 'email' },
    { caption: t('SMS'), id: 'CMC' },
  ], [])

  const handleSetNotifyChanel = useMemo(
    () => isUser
      ? (notification: TaskType, chanelName: ChanelName) => updateNotificationSettingsByTypeId({
        [chanelName]: !notification[chanelName],
        typeId      : notification.typeId,
      }) as Promise<TaskType>

      : (notification: TaskType, chanelName: ChanelName) => editNotificationTask({
        [chanelName]: !notification[chanelName],
        id          : notification.id,
      }) as Promise<TaskType>,
    [isUser])

  return (
    <Block>
      <Title>{t('Notifications')}</Title>

      <TableWrapper>
        <Table>
          <TableHeader>
            <HeaderRow>
              {columns.map(cell => <HeaderCell key={cell.id} maxWidth={cell.id === 'typeName' ? '645px' : '84px'}>
                {cell.caption}
              </HeaderCell>)}
            </HeaderRow>
          </TableHeader>

          <TableBody>
            {notifications.map(notification =>
              <TableRow key={notification.id}>
                <CellWithCheckbox
                  editTask={editTask}
                  fieldName='active'
                  handleSetNotifyChanel={handleSetNotifyChanel}
                  notification={notification}
                  translate={t}
                />

                <TableCell
                  key={notification.typeId}
                  maxWidth="645px"
                >
                  {t(`notificationTasks.${notification.typeName}`)}
                </TableCell>

                <CellWithCheckbox
                  editTask={editTask}
                  fieldName='online'
                  handleSetNotifyChanel={handleSetNotifyChanel}
                  notification={notification}
                  translate={t}
                />

                <CellWithCheckbox
                  editTask={editTask}
                  fieldName='email'
                  handleSetNotifyChanel={handleSetNotifyChanel}
                  notification={notification}
                  translate={t}
                />

                <CellWithCheckbox
                  disabled={smsChanelIsDisabled}
                  editTask={editTask}
                  fieldName='sms'
                  handleSetNotifyChanel={handleSetNotifyChanel}
                  notification={notification}
                  translate={t}
                />
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </Block>
  )}

export default Notifications

const Block = styled.div`
  background-color: #fff;
  padding: 24px;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 17px;
`

const TableWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.12);
  box-sizing: border-box;
  max-width: 1000px;
  table > tbody {
    height: 356px;
    overflow-y: overlay;
  }
`
