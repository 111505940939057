import { TrackTypes } from '../../../../../types'
import { harvestColors } from '../../../constants'
import { colorLoadItem, colorSpeedItem } from '../../../normalizeTrack'
import { PathType, TrackType } from '../index'

/**
 * Возвращает цвет точки в зависимости от статуса
 *
 * @param point - Новая точка для добавления к треку.
 * @param trackType - Тип трека.
 * @returns Цвет точки.
 */
export const getColor = (point: PathType, trackType?: TrackTypes) => {
  switch(trackType) {
    case 'TRACK_LOAD':
      return colorLoadItem(point)
    case 'TRACK_SPEED':
      return colorSpeedItem(point)
    default:
      return harvestColors[point.status]
  }
}

export type GetTrackWithPoint = {
  track: TrackType[]
  point: PathType
  animate: boolean
  trackType?: TrackTypes
}

/**
 * Добавляет в трек новую точку для анимации, предварительно создавая новый сегмент линии.
 *
 * @param track - Массив точек трека для обновления.
 * @param point - Новая точка для добавления к треку.
 * @param animate - Признак того что трек уже проигрывается. Если да то точка добавляется в существующий сегмент. Нет - предварительно добавляется сегмент.
 * @param trackType - Тип трека.
 * @returns Обновленный массив трека с добавленной новой точкой.
 */
export const getTrackWithPoint = ({
  animate,
  point,
  track,
  trackType,
}: GetTrackWithPoint) => {
  let newTrack = [ ...track ]
  const lastLine = track[track.length -1]
  const color = getColor(point, trackType)

  if(lastLine.color === color && animate) {
    newTrack.splice(track.length - 1, 1, {
      color: lastLine.color,
      path : [ ...lastLine.path, point ],
    })

    //на первой итерации добавляется новая линия, для того чтобы не смешивать статичные данные из трека и
    //тем что будет анимироваться
  } else {
    newTrack.push({
      color,
      path: [lastLine.path[lastLine.path.length -1], point],
    })
  }

  return newTrack
}
