import React from 'react'
import styled from 'styled-components'
import { LayoutOne, LayoutThree, LayoutTwo } from './icons'

type TProps = {
  onChange: (value: number) => void
  layout: number | null
}

export default function Switcher({ onChange, layout }: TProps) {
  return (
    <SwitcherElement>
      {[LayoutOne, LayoutTwo, LayoutThree].map(
        (LayoutIcon, index) => <LayoutButton
          key={index}
          onClick={() => onChange(index)}
          isActive={layout === index}
        >
          <LayoutIcon size={22} color={layout === index ? 'white' : '#A1A1A1'} />
        </LayoutButton>,
      )}
    </SwitcherElement>
  )
}

const SwitcherElement = styled.div`
  width: 132px;
  height: 46px;
  
  /* Color 7 */
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 2px;
  
  display: flex;
  float: right;
  
  margin-top: 7px;
  margin-right: 20px;
`

const LayoutButton = styled.div<{ isActive: boolean }>`
  width: 44px;
  height: 44px;

  background: ${({ isActive }) => isActive ? '#D10029' : 'white'};
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
`
