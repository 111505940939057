import { NotificationMarkersInfo } from 'queries/trackEvents'
import React, { FC, useMemo } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { TTranslate } from 'types'
import { notice } from '../../markersType'

type NotificationMarkerProps = {
  t: TTranslate
  markers: Required<NotificationMarkersInfo>[]
  selectedOptions: Set<number>
}

export const NotificationMarkers: FC<NotificationMarkerProps> = ({ markers, selectedOptions, t }) => {
  const notificationMarkers = useMemo(() => markers.filter(marker => selectedOptions.has(marker.status)),
    [selectedOptions.size, markers],
  )

  return <>{notificationMarkers.map(marker => <Marker
    icon={notice(marker.status)}
    key={`${marker.name}-${marker.timeReset}-${marker.lat}-${marker.lon}`}
    position={[marker.lat, marker.lon]}
    zIndexOffset={10000}
  >
    <Popup position={[marker.lat, marker.lon]}>
      <div className="marker-popup">
        {marker.timeSet && <p className="marker-popup__name">
          {t('Start')}: {Date.parseDateTimeFromApi(marker.timeSet).format('DD.MM.YY HH:mm:ss')}
        </p>}

        {marker.timeReset && <p className="marker-popup__name">
          {t('Finish')}: {Date.parseDateTimeFromApi(marker.timeReset).format('DD.MM.YY HH:mm:ss')}
        </p>}

        <p className="marker-popup__name">{t('status')}: {marker.statusName}</p>
        <p className="marker-popup__name">{t('category')}: {marker.categoryName}</p>
        <p className="marker-popup__name">{t('Message')}: {marker.name}</p>
      </div>
    </Popup>
  </Marker>)}</>
}
