import { icons } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLOR_MAP, COLOR_MAP_ACTIVE, MARGIN_LEFT_MAP } from '../constants'

export type NumberSet = 1 | 2 | 3 | 4 | 5

type RowProps = {
  element: {
    children?: Record<string, string>[]
  } & Record<string, string>
  level?: NumberSet
  selectedLevel: number | null
  settings: {
    id: string
    additionalFields?: string[]
    formatter?: Function
  }[]
  setSelectedLevel: (value: number | null) => void
}

export const Row: FC<RowProps> = ({ element, level = 1, selectedLevel, settings, setSelectedLevel }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const parent = !isEmpty(element.children)

  useEffect(() => {
    if(selectedLevel) {
      setIsExpanded(selectedLevel > level)
    }
  }, [selectedLevel])

  const handleRowClick = useCallback(() => {
    if(!parent) { return }

    setIsExpanded(!isExpanded)
    setSelectedLevel(null)
  }, [isExpanded, parent, setSelectedLevel])

  return <>
    <StyledTableRowWrapper onClick={handleRowClick}>
      <Lines level={level}>
        <TableRow
          color={isExpanded && parent ? COLOR_MAP_ACTIVE[level] : COLOR_MAP[level]}
          level={level}
          parent={parent}
        >
          {settings.map((item, index) =>
            <StyledTableCell key={item.id} level={level} index={index}>
              {index === 0 && <ArrowContainer key={`${item.id}_icon`}>
                {parent
                  ? <icons.ArrowDropDownIcon direction={isExpanded ? 'bottom' : 'right'}/>
                  : <Dot />
                }
              </ArrowContainer>}

              <ItemTitleWrapper>
                <Title>{item.formatter ? item.formatter(element[item.id], element.type) : element[item.id]}</Title>

                {item.additionalFields && item.additionalFields.map(field =>
                  <Subtitle key={field}>{element[field]}</Subtitle>,
                )}
              </ItemTitleWrapper>
            </StyledTableCell>,
          )}
        </TableRow>
      </Lines>
    </StyledTableRowWrapper>

    {parent && isExpanded && <RowListWrapper level={level}>
      <StyledTd><table><tbody>
        {element.children?.map((E, idx) => <Row
          key={idx}
          element={E}
          level={level + 1 as NumberSet}
          selectedLevel={selectedLevel}
          settings={settings}
          setSelectedLevel={setSelectedLevel}
        />)}
      </tbody></table></StyledTd>
    </RowListWrapper>
    }
  </>
}

const StyledTd = styled.td`
  width: 100%;
`

const RowListWrapper = styled.tr<{level: NumberSet}>`
  position: relative;
  display: flex;
  border-bottom: none;

  &:last-child:before {
    border-right: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: -42px;
    left: ${({ level }) => `${MARGIN_LEFT_MAP[level] - 30 }px`};
    border-right: ${({ level }) => level > 1
    ? `2px solid ${COLOR_MAP_ACTIVE[Math.max(1, level - 1) as NumberSet]}`
    : 'none'};
    border-radius: 0;
    height: 100%;
    z-index: 1;
  }
`

const ArrowContainer = styled.div`
  margin: 6px -5px 0 0;
`

const Lines = styled.td<{level: NumberSet}>`
  width: 100%;
  margin-right: ${({ level }) => `${MARGIN_LEFT_MAP[level]}px`};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -41px;
    left: ${({ level }) => `${MARGIN_LEFT_MAP[level] - 30 }px`};
    border-right: ${({ level }) => `2px solid ${COLOR_MAP_ACTIVE[Math.max(1, level - 1) as NumberSet]}`};
    border-radius: 0;
    height: 100%;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: ${({ level }) => `${MARGIN_LEFT_MAP[level] - 30}px`};
    border-top: ${({ level }) => `2px solid ${COLOR_MAP_ACTIVE[Math.max(1, level - 1) as NumberSet]}`};
    border-radius: 0;
    width: 30px;
    height: 100%;
  }
`

const Dot = styled.div`
  background-color: #626262;
  border-radius: 50%;
  height: 6px;
  margin: 4px 10px 10px 10px;
  width: 6px;
`

const TableRow = styled.div<{level: NumberSet, parent: boolean}>`
  background-color: ${({ color }) => color};
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-start;
  cursor: ${({ parent }) => parent ? 'pointer' : 'auto'};
  color: #202020;
  border-bottom: 1px solid #e0e0e0;
  min-height: 76px;
  border-radius: 5px;
  margin: ${({ level }) => `5px 10px 5px ${MARGIN_LEFT_MAP[level]}px`};
`

const StyledTableRowWrapper = styled.tr`
  display: flex;
  border-bottom: none;
`

const StyledTableCell = styled.div<{level: NumberSet, index: number}>`
  font-size: 14px;
  max-width: 46em;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ index, level }) => index === 0 ? `${70 - MARGIN_LEFT_MAP[level]}px` : 0};
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  flex: 1 1 6em;
  min-width: 6em;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
`

const ItemTitleWrapper = styled.div`
  flex-direction: column;
  align-items: start;
  margin-left: 1em;
`

const Title = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: 600;
`

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
`
