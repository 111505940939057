import { constants } from '@agdt/agrotronic-react-components'
import CSSTransitionWrapper from 'components/CSSTransitionWrapper'
import { isEmpty } from 'ramda'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { validateGeozone } from '../../../utils/helpers'

type DataPolygonProps = {
  areaPolygon: number | null
  isDeleteModeActive: boolean
  lastPoint: [number, number] | null
  nameNewGeoZone: unknown
  newGeoZone: {
    points: unknown[]
  }[]
  saveElement: () => void
  toggleDeleteMode: () => void
}

export const DataPolygon: FC<DataPolygonProps> = ({
  areaPolygon,
  isDeleteModeActive,
  lastPoint,
  nameNewGeoZone,
  newGeoZone,
  saveElement,
  toggleDeleteMode,
}) => {
  const t = useTranslate()

  const isValid = useMemo(
    () => validateGeozone(newGeoZone[0].points),
    [newGeoZone?.[0].points],
  )

  return <>
    {lastPoint &&
    <div className="geoZone-controll__data">
      <p className="point">{t('Active point')}
        <span>{lastPoint[0]}</span>
        <span>{lastPoint[1]}</span>
      </p>
    </div>
    }
    <div className="geoZone-controll__item">
      <p>{t('area')}
        <span>{areaPolygon ? areaPolygon.toFixed(2) : 0} {t('ha')}</span>
      </p>
    </div>

    <CSSTransitionWrapper isShow={newGeoZone[0].points.length > 0}>
      <div className={isDeleteModeActive ? 'geoZone-controll__button-wr' : 'geoZone-controll__button-wr active'}>
        <button onClick={toggleDeleteMode}>{isDeleteModeActive ? t('Continue') : t('Delete point')}</button>
      </div>
    </CSSTransitionWrapper>

    <CSSTransitionWrapper isShow={newGeoZone[0].points.length > 2 && !isEmpty(nameNewGeoZone)}>
      <ButtonOrError className="geoZone-controll__button-wr" error={!isValid}>
        {isValid
          ? <button onClick={saveElement}>{t('Save geozone')}</button>
          : t('The geozone should not self-crossing')
        }
      </ButtonOrError>
    </CSSTransitionWrapper>
  </>
}

const ButtonOrError = styled.div<{error: boolean}>`
  color: ${({ error }) => !error ? 'inherit' : constants.colors.PRIMARY_ERROR}
`
