import { TProps as TWidgetProps, Widget } from 'components/Widget'
import React from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { HeaderElement } from '../../../components/Widget/Header'
import { TitleAndCounter, TitleCaption, TitleContainer } from '../../../components/Widget/Header/Title'
import SystemContextMenu from '../../../components/Widget/SystemContextMenu'
import TooltipD from '../../../ui/Tooltip'
import { MapComponent } from './MapComponent'

const styles = {
  tooltips: {
    map: { left: '65px', top: '5px' },
  },
}

type TProps = {
  className: string
  onClose: () => void
} & TWidgetProps

function MapWidget({ className, onClose, ...restProps }: TProps) {
  const translate = useTranslate()

  return (
    <Widget
      className={className}
      {...restProps}
      isStratchable={true}
    >
      <HeaderStyled isSubtitle className="draggable">
        <TitleContainer>
          <TitleAndCounter>
            <TitleCaptionStyled>{translate('map')}</TitleCaptionStyled>
          </TitleAndCounter>
        </TitleContainer>

        <SystemContextMenu onClose={onClose} iconColor="#FFFFFF" />
      </HeaderStyled>

      <TooltipD
        text={translate('The map show all machines Click on machine, to open information about this machine')}
        style={styles.tooltips.map}
      />

      <MapComponent />
    </Widget>
  )
}

export default MapWidget

const HeaderStyled = styled(HeaderElement)`
  position: absolute;
  z-index: 999;
  width: 100%;
  background: rgba(50, 50, 50, 0.8);
`

const TitleCaptionStyled = styled(TitleCaption)`
  /* Bold 14 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  
  display: flex;
  align-items: center;
  
  /* Color 9 */
  color: #FFFFFF;
`
