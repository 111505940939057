import { Loader as GenericLoader } from '@agdt/agrotronic-react-components'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { AppStateType } from 'reducers/store'
import { userLogout } from 'reducers/users/actions'
import { LANG_RU } from '../constants/lang'
import { ConfigContext } from '../containers/ValidateUser'

export const Loader: FC = () => {
  const lang = useContext(ConfigContext)?.lang
  const preLoaded = useSelector((state: AppStateType) => state.user.preLoaded)

  if(!preLoaded) {
    return null
  }

  return <GenericLoader

    /*@ts-expect-error*/
    lang={(lang ?? LANG_RU).toUpperCase()}
    logoutHandler={userLogout}
  />
}
