import React, { FC } from 'react'
import { TTranslate } from 'types'
import { notificationStatus } from 'utils'

type NotificationOptionsProps = {
  translate: TTranslate
  shownMarkers: Set<number>
  toggleMarkers: (type: 'notification', value: number) => void
}

export const NotificationOptions: FC<NotificationOptionsProps> = ({
  shownMarkers,
  toggleMarkers,
  translate,
}) => {
  const onChange = (value: number) => () => {
    toggleMarkers('notification', value)
  }

  return (
    <div className={'tracking-machine__settings'}>
      <p>{translate('notifications')}</p>

      {Object.values(notificationStatus).map(item =>
        <div className="tracking-machine__item" key={item.value}>
          <input
            checked={shownMarkers.has(item.value)}
            id={`n${item.value}`}
            onChange={onChange(item.value)}
            type="checkbox"
          />

          <label htmlFor={`n${item.value}`}>
            {translate(item.typeTranslate)}
          </label>
        </div>,
      )}
    </div>
  )
}
