import { Button, DateSelector, helpers, hooks, Spacer, Spinner } from '@agdt/agrotronic-react-components'
import moment from 'moment/moment'
import { getCalendarPaintRegs, getCalendarPaintValue, RegisterType } from 'queries/calendar'
import React, { FC,useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { colorsRegister } from 'utils'
import { errorMessage } from '../../../services/notification'

type ElementType = {
  to: string
  from: string
}

type MachineResultType = {
  id: number
  imei: number
  name: string
}

type CalendarDateType = {
  month: number
  year: number
}

type Props = {
  machinesResult: MachineResultType[]
  result: ElementType[]
  section: string
  showCalendar: boolean
  setShowCalendar: (value: boolean) => void
  setFilter: (section: string, item: ElementType) => void
  t: TTranslate
}

export const Calendar: FC<Props> = ({
  machinesResult,
  result,
  setFilter,
  section,
  showCalendar,
  setShowCalendar,
  t,
}) => {
  const gmt = useSelector((state: AppStateType) => state.user.info.gmt) || 0
  const withData = machinesResult.length === 1 // Условие раскраски календаря
  const ref = useRef<HTMLDivElement>(null)
  const [registerColors, setRegisterColors] = useState<unknown>(null)
  const [date, setDate] = useState<Date[] | null>(null)
  const [loadingCalendarData, setLoadingCalendarData] = useState(false)
  const [registers, setRegisters] = useState<Record<number, unknown>>({})
  const [register, setRegister] = useState<RegisterType|null>(null)

  const [calendarDate, setCalendarDate] = useState<CalendarDateType>({ // Дата отображаемая в календаре
    month: new Date().getMonth() + 1,
    year : new Date().getFullYear(),
  })

  const onResetDate = useCallback(() => {
    if(result.length) {
      setDate([
        moment(result[0].from, 'YYMMDDHHmmssSSS').toDate(),
        moment(result[0].to, 'YYMMDDHHmmssSSS').toDate(),
      ])
    } else {
      setDate(null)
    }

    setRegisterColors(null)
  }, [result])

  const handleCloseSelector = useCallback(() => {
    if(showCalendar){
      onResetDate()
      setShowCalendar(false)
    }
  }, [showCalendar])

  hooks.useClickOutside(ref, handleCloseSelector)

  const debouncedSetCalendarDate = helpers.debounce(
    (newDate: CalendarDateType) => setCalendarDate({ month: newDate.month + 1, year: newDate.year }),
    700)

  const changeMonthHandler = useCallback(newDate => {
    setRegisterColors(null)
    debouncedSetCalendarDate(newDate)
  }, [])

  const applyRange = useCallback(() => {
    if(date) {
      const from = moment(date[0]).format('YYMMDDHHmmssSSS')
      const to = moment(date[1]).format('YYMMDDHHmmssSSS')
      setFilter(section, { from, to })
      setShowCalendar(false)
    }
  }, [date, section])

  useEffect(onResetDate, [result]) // Сбрасывать дату при изменении даты кнопками

  useEffect(() => {
    const currentImei = machinesResult?.[0]?.imei

    if(withData && currentImei && !registers[currentImei] && showCalendar) {
      setLoadingCalendarData(true)

      getCalendarPaintRegs(currentImei)
        .then(regs => {
          if(regs?.length) {
            setRegisters({ ...registers, [currentImei]: regs })
            setRegister(regs[0])
          }
        })

        .catch(() => {errorMessage(t('Error loading calendar coloring pages'))})
        .finally(() => { setLoadingCalendarData(false) })
    }
  }, [showCalendar])

  useEffect(() => {
    const registerType = register?.type

    if(registerType && calendarDate && machinesResult.length) {
      setLoadingCalendarData(true)

      getCalendarPaintValue(
        machinesResult[0].imei,
        {
          callback: (calendarValue: Record<string | number, unknown>) => {
            setRegisterColors(calendarValue[registerType])
            setLoadingCalendarData(false)
          },

          query: {
            GMT     : gmt,
            month   : calendarDate.month,
            register: registerType,
            year    : calendarDate.year,
          },
        },
      )
    }
  }, [calendarDate, register])

  return <>
    {showCalendar &&
      <DateSelectorWrapper ref={ref}>
        {loadingCalendarData && <>
          <SpinnerBackground/>
          <StyledSpinner color="#D10B41" size={31}/>
        </>}

        <DateSelector
          onChange={setDate}
          maxCurrentDate
          value={date}
          withTime

          // Передаем параметры раскраски календаря если выполняется условие "withData"
          {...withData && {
            cbChangeMonth: changeMonthHandler,
            colors       : colorsRegister,
            colorValues  : registerColors,
            data         : registers[machinesResult[0].imei],
            dataSelected : register,
            onChangeData : setRegister,
            withData,
          }}
        />
        <ButtonWrapper>
          <Button disabled={!date} onClick={applyRange}>{t('accept')}</Button>
          <Spacer size="20px"/>
          <Button variant="border" onClick={handleCloseSelector}>{t('cancel')}</Button>
        </ButtonWrapper>
      </DateSelectorWrapper>
    }
  </>
}

const DateSelectorWrapper = styled.div`
  background-color: white;
  padding: 10px 10px;
  border-radius: 2px;
  position: absolute;
  top: 18vh;
  z-index: 1004;
  box-shadow: 0 5px 6px rgba(0,0,0,0.2), 0 3px 16px rgba(0,0,0,0.12), 0 9px 12px rgba(0,0,0,0.12);
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10px;
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 2;
`

const SpinnerBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .7;
  z-index: 1;
`
