import { Button, icons } from '@agdt/agrotronic-react-components'
import moment from 'moment'
import React, { FC, useCallback } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { exportSheet } from '../../../result/common/exportSheet'
import { areaFormatter, timeFormatter } from '../'

type TProps = {
  items: {
    model: string
    number: string
    details: {
      model: string
      number: number
      area: number
      modelName: string
      work: number
    }[]
  }[]
  period?: {
    from: string
    to: string
  }
  reportName: unknown
}

type TReport = {
  merges: string[]
  position: number
  rows: [string, string, string, string][]
  totalArea: number
}

const dateFormatter = (date: string): string => moment(date, 'YYMMDDHHmmss000').format('DD.MM.YY')

export const ExportReportToExcel: FC<TProps> =({ items, period, reportName }) => {
  const t = useTranslate()

  const exportToExcelVehicleReport = useCallback(() => {
    if(!items) { return }

    const report = items.reduce<TReport>((acc, item) => {
      item.details.map(D => {
        acc.totalArea =  acc.totalArea + D.area

        acc.rows.push([
          `${item.model} (${item.number})`,
          `${D.model} (${D.number}, ${D.modelName})`,
          timeFormatter(D.work), areaFormatter(D.area),
        ])
      })

      const nextPosition = acc.position + item.details.length - 1
      acc.merges.push(`A${acc.position}:A${nextPosition}`)
      acc.position = nextPosition + 1
      return acc
    }, { merges: [], position: 3, rows: [], totalArea: 0 })

    const data = [
      [t('period'), period ? `${dateFormatter(period.from)} - ${dateFormatter(period.to)}` : '-'] ,
      [t('osht'), t('aggregate'), `${t('operating time')}, ${t('eh')}`, `${t('operating time')}, ${t('ha')}`],
      ...report.rows,
      ['', '', `${t('total')}:`, areaFormatter(report.totalArea)],
    ]

    exportSheet(data, 'report', report.merges)
  }, [items, period?.from, period?.to, t])

  const exportToExcelAggregatesReport = useCallback(() => {
    if(!items) { return }

    const report = items.reduce<[string, string, string, string][]>((acc, item) => {
      item.details.map(D => acc.push([
        item.model,
        `${D.model} (${D.number})`,
        timeFormatter(D.work),
        areaFormatter(D.area),
      ]))

      return acc
    }, [])

    const data = [
      [t('period'), period ? `${dateFormatter(period.from)} - ${dateFormatter(period.to)}` : '-'],
      [ t('aggregate'), t('osht'), `${t('operating time')}, ${t('eh')}`, `${t('operating time')}, ${t('ha')}`],
      ...report,
    ]

    exportSheet(data, 'report')
  }, [items, period?.from, period?.to, t])

  return <StyledButton
    onClick={reportName === 'vehicleReport' ? exportToExcelAggregatesReport : exportToExcelVehicleReport}
    variant="border"
  >
    <>
      <icons.XlsIcon size='24' color='#32BE6E' />
      <TitleButton>{t('Download')}</TitleButton>
    </>
  </StyledButton>
}

const StyledButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding-left: 4px;
`
