import { icons } from '@agdt/agrotronic-react-components'
import { Feature, isFeatureEnabled } from '@agdt/utils'
import React from 'react'
import { link } from '../../../router'

export interface MenuItemConfiguration {

  /**
   * @deprecated Пока используется, но скоро будет заменено на isSysAdmin, isOrgAdmin, canViewOrganization и canEditOrganization
   */
  isAdmin: boolean

  /**
   * @deprecated Пока используется, но скоро будет заменено на isSysAdmin, isOrgAdmin, canViewOrganization и canEditOrganization
   */
  isAdminLogin: boolean

  isInDebug: boolean
  isInDemoMode?: boolean

  // Экран PAL (720x576) или меньше?
  isPAL: boolean
  link?: keyof typeof link

  /**
   * Пользователь является администратором Агротроник
   */
  isSysAdmin: boolean

  /**
   * Пользователь является администратором организации
   */
  isOrgAdmin: boolean

  /**
   * У пользователя есть привилегия на редактирование организации
   */
  canEditOrganization: boolean
}

export type MenuItemDefinition = {

  // Идентификатор для тестов
  testId: string

  // Условие показа пункта меню
  condition?: (config: MenuItemConfiguration) => boolean
  external?: boolean
  icon: JSX.Element
  label: string
  link?: keyof typeof link
  linkTo?: string
  matchPath?: { exact?: boolean, path: string | string[] }
  items?: Array<{

    // Идентификатор для тестов
    testId: string
    condition?: (config: MenuItemConfiguration) => boolean
    external: boolean
    label: string
    separated?: boolean
    matchPaths?: { exact?: boolean, path: string | string[] }
    icon?: JSX.Element
    link: keyof typeof link
  }>
}

export const menuItems: MenuItemDefinition[] = [
  {
    testId   : 'desktop',
    external : false,
    icon     : <icons.HomeIcon color='#fff'/>,
    label    : 'desktop',
    link     : 'home',
    matchPath: { exact: true, path: '/' },
  },

  {
    testId   : 'myMachines',
    external : false,
    icon     : <icons.MachineIcon color='#fff'/>,
    label    : 'myMachines',
    link     : 'machines',
    matchPath: { path: '/machines' },
  },

  {
    condition: ({ isInDemoMode }) => !isInDemoMode,
    external : true,
    icon     : <icons.NewMapIcon color='#fff'/>,
    label    : 'map',
    link     : 'map',
    testId   : 'map',
  },

  {
    testId   : 'tracking',
    external : false,
    icon     : <icons.MapIcon color='#fff'/>,
    label    : 'map',
    link     : 'tracking',
    matchPath: { path: ['/tracking', '/tracking/households', '/tracking/GeoZones'] },
  },

  {
    testId   : 'notifications',
    external : false,
    icon     : <icons.TechControlIcon color='#fff'/>,
    label    : 'notifications',
    link     : 'notifications',
    matchPath: { path: '/notifications' },
  },

  {
    testId   : 'analysis',
    external : false,
    icon     : <icons.ChartIcon color='#fff'/>,
    label    : 'analysis',
    link     : 'watchMultiple',
    matchPath: { path: '/analysis' },
  },

  {
    testId   : 'planning',
    condition: ({ isInDemoMode }) => !isInDemoMode,
    external : true,
    icon     : <icons.AssignmentTurnedIcon color='#fff'/>,
    label    : 'Planning',
    link     : 'planning',
  },

  {
    testId   : 'catalogs',
    condition: ({ isInDemoMode }) => !isInDemoMode,
    external : true,
    icon     : <icons.BookSearchIcon color='#fff' size={24} />,
    label    : 'Catalogs',
    link     : 'catalogs',
  },

  {
    testId   : 'adminPanel',
    condition: ({
      isAdminLogin,
      isAdmin,
      isSysAdmin,
      isOrgAdmin,
      canEditOrganization,
    }) =>
      isFeatureEnabled(Feature.AppendMachine)
        ? isSysAdmin ||
        isOrgAdmin ||
        canEditOrganization
        : isAdmin || isAdminLogin,
    icon: <icons.CrownIcon color="#fff" />,

    items: [
      {
        testId  : 'farms',
        external: true,
        icon    : <icons.FarmsIcon color="#ccc" />,
        label   : 'Farms',
        link    : 'farms',
      },
      {
        testId   : 'users',
        condition: ({ isSysAdmin, isOrgAdmin }) =>
          isFeatureEnabled(Feature.AppendMachine)
            ? isSysAdmin || isOrgAdmin
            : true,
        external: true,
        icon    : <icons.SocialGroupIcon color="#ccc" />,
        label   : 'Users',
        link    : 'users',
      },
      {
        testId   : 'roles',
        condition: ({ isAdminLogin, isSysAdmin, isOrgAdmin }) =>
          isFeatureEnabled(Feature.AppendMachine)
            ? isSysAdmin || isOrgAdmin
            : isAdminLogin,
        external: true,
        icon    : <icons.ContentHowToRegIcon color="#ccc" />,
        label   : 'Roles',
        link    : 'roles',
      },
      {
        testId   : 'organizations',
        condition: ({ isAdminLogin, isSysAdmin }) =>
          isFeatureEnabled(Feature.AppendMachine) ? isSysAdmin : isAdminLogin,
        external : true,
        label    : 'Organizations types',
        link     : 'adminpanel',
        separated: true,
      },
    ],

    label: 'AdminPanel',
  },
]
