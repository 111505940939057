import { helpers } from '@agdt/agrotronic-react-components'
import moment from 'moment/moment'
import { F, indexBy, map, path, pipe, prop, tryCatch } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { withRouter } from 'react-router'
import { AppDispatch, AppStateType } from 'reducers/store'
import { ThunkAction } from 'redux-thunk'
import { warningMessage } from 'services/notification'
import { TLang, TTranslate } from 'types'
import { notificationCategory, notificationStatus } from 'utils'
import { LANG_RU } from '../../constants/lang'
import { GMT_MOSCOW } from '../../constants/time'
import { TMachine } from '../../containers/MachineDetail/models'
import { ConfigContext } from '../../containers/ValidateUser'
import { clearFixedDataFilter, fixedDataFilter } from '../../reducers/reports/actions'
import { filteredUsersOfType } from '../../reducers/users/selectors'

const { debounce } = helpers

type UnitInfo = {
  id: string | number
  name: string
  imei: number
}

export type TimeInfo = {
  today?: boolean
  from: string
  to: string
 }

type UnitsItem = {
  units: UnitInfo[]
}

type DefaultItem = {
  id: number
  name: string
  section: string
  type: string
  filter?: string
  items?: UnitsItem[]
  result: {
    id: string | number
  }[]
  validate: boolean
}

export type TFilterCallbackData = {
  machine: TMachine[]
  lang?: TLang
  time: TimeInfo[]
  gmt?: number
  callbackOk?: Function
  callbackError?: Function
}

type DataArg = {
  noStartCallbackFiltration?: boolean
  default: DefaultItem[]
  callback?: (value: TFilterCallbackData) => ThunkAction<void, AppStateType, void>
  clear: () => ThunkAction<void, AppStateType, void>
}

type FilterUnit = {
  data: {
    FUEL?: {
      measure: string
    }
    STATUS: unknown
  }
  model: string
  typeName: string
} & UnitInfo

type UserType = {
  units: {
    ownedUnits: FilterUnit[]
  }
  id: string | number
  name: string
}

type HOCProps = {
  maxRequestVehicle: number
  users: UserType[]
  location: {
    search: string
    pathname: string
  }
  history: string[]
  translate: TTranslate
  dispatch: AppDispatch
  stringFixedDataFilter: string
}

type HOCState = {
  filter: DefaultItem[]
  filtration: boolean
  shadowButton: boolean
  stringBrowser: string
}

type ComposedComponentProps = {
  translate: TTranslate
  shadowButton: boolean
  filtration: boolean
  filter: DefaultItem[]
  onFilter: (state: {section: string, result: unknown}[]) => void
  onStartFilter: (
    section: string,
    value: {id: string | number} | TimeInfo | string | UnitInfo,
    solo?: boolean,
    clear?: boolean,
    pushToStringBrowser?: boolean,
  ) => void
  clearFilter: (callback?: () => void, route?: boolean) => void
  deleteParameters: (section: string, value: unknown) => void
  fixedDataFilter: () => void
  clearFixedDataFilter: () => void
  onStartFilterArrayMachine: (value: Array<string | number>, pushToStringBrowser?: boolean) => void
} & HOCProps

type QueryStartFilter = {
  machine: TMachine[]
  time: TimeInfo[]
  status: unknown[]
}

export default (data: DataArg) => (ComposedComponent: React.FC<ComposedComponentProps>) => {
  class HOCFilter extends React.Component<HOCProps, HOCState> {
    state = {
      filter       : data.default,
      filtration   : false,
      shadowButton : true,
      stringBrowser: '',
    }

    static contextType = ConfigContext

    static context: React.ContextType<typeof ConfigContext>

    maxVehicleCountMessage = debounce(() => warningMessage(
      `${this.props.translate('maxVehicleCountRequest')} ${this.props.maxRequestVehicle}`,
    ), 200);

    componentWillMount() {
      const { translate } = this.props
      const copyData = JSON.parse(JSON.stringify(data.default))

      this.setState(prevState => ({
        ...prevState,
        filter: copyData.map((item: DefaultItem) => ({
          ...item,
          name: translate(item.name),
        })),
      }))

      this.clearFilter(undefined, false)
      this.getMultiCheckbox(this.props)
    }

    componentDidMount() {
      if(this.props.users.length) {
        this.getMultiCheckbox(this.props, true)
      }
    }

    componentWillReceiveProps(nextProps: HOCProps) {
      if(nextProps.users.length && !this.props.users.length) {
        this.getMultiCheckbox(nextProps, true)
      } else if(nextProps.users.length) {
        this.getMultiCheckbox(nextProps)
      }
    }

    getSection = (section: string) => this.state.filter.find(item => item.section === section);

    getMultiCheckbox = ({ users }: HOCProps, stringBrowser?: boolean) => {
      const findMulti = data.default.find(item => item.type === 'checkbox-machine' || item.type === 'radio-machine')

      if(findMulti) {
        if(findMulti.filter === 'FUEL.l') {
          return this.pushItems(
            this.getMachine(users, unit => Boolean(unit.data.FUEL && unit.data.FUEL.measure !== '%')),
            stringBrowser,
          )
        }

        if(findMulti.filter === 'ZUK') {
          return this.pushItems(this.getMachine(users, unit => unit.model === 'ZUK'), stringBrowser)
        }

        return this.pushItems(this.getMachine(users, unit => Boolean(unit)), stringBrowser)
      }
    };

    pushItems = (items: UnitsItem[], stringBrowser?: boolean) => {
      this.setState(prevState => ({
        ...prevState,
        filter: prevState.filter.map((item: DefaultItem) => {
          if(item.type === 'checkbox-machine' || item.type === 'radio-machine') {
            return {
              ...item,
              items,
            }
          }

          return item
        }),
      }), () => {
        if(stringBrowser){this.takeStringBrowser()}
      })
    };

    getMachine = (users: UserType[], filter: (value: FilterUnit) => boolean) => {
      const userUnits = tryCatch(pipe(
        path(['farm', 'units']),

        //@ts-expect-error
        indexBy(prop('id')),
      ), F)(this.context) as false | Record<number | string, unknown>

      return users.reduce((list: UnitsItem[], user: UserType) => {
        const userTemp = user.units.ownedUnits
          .filter(U => userUnits === false || userUnits[U.id])
          .filter(filter)
          .map(({ id, name, typeName, model, data: unitData, imei }) => ({
            id,
            imei,
            model  : typeName,
            name,
            status : unitData.STATUS,
            subtype: model,
            typeName,
          }))

        if(userTemp.length) {
          return [
            ...list,
            {
              id   : user.id,
              name : user.name,
              units: userTemp,
            },
          ]
        }

        return list
      }, [])
    };

    takeStringBrowser = () => {
      if(this.props.location) {
        const query = new URLSearchParams(this.props.location.search)

        this.setState({ stringBrowser: this.props.location.search }, () => {
          const queryStartFilter = this.state.filter.reduce((result, item) => ({
            ...result,
            [item.section]: [],
          }), {}) as QueryStartFilter

          if(query.has('machine')){this.takeStringMachine(String(query.get('machine')), queryStartFilter)}

          if(query.has('time')){this.takeStringTime(String(query.get('time')), queryStartFilter)}

          if(query.has('status')){this.takeStringStatus(String(query.get('status')), queryStartFilter)}

          if(query.has('category')){this.takeStringCategory(String(query.get('category')), queryStartFilter)}

          if(query.has('startFilter')){this.startFilterBecauseLocation(queryStartFilter)}
        })
      }
    };

    takeStringMachine = (machine: string, queryStartFilter: {machine: unknown[]}) => {
      const machineSection = this.getSection('machine')

      const validateArrayMachine = machine
        .split(',')
        .map(item => this.validateOnlyNumber(item))
        .filter(item => item)

      if(machineSection?.type === 'radio-machine') {
        queryStartFilter.machine.push(validateArrayMachine[validateArrayMachine.length - 1])
        this.selectMachine(validateArrayMachine[validateArrayMachine.length - 1], false)
      } else {
        validateArrayMachine.map(item => queryStartFilter.machine.push(item))

        if(validateArrayMachine.length > 1) {
          //@ts-expect-error
          this.onStartFilterArrayMachine(map(prop('id'), validateArrayMachine), false)
        } else {
          this.selectMachine(validateArrayMachine[0], false)
        }
      }
    };

    takeStringTime = (time: string, queryStartFilter: {time: unknown[]}) => {
      const isToday = ['today'].includes(time)

      const arrayTime = isToday
        ? [
          new Date().startOf('day').format('YYMMDDHHmmss000'),
          new Date().format('YYMMDDHHmmss000'),
        ]
        : time.split(',')

      if(arrayTime.length === 2) {
        const validTime = this.validateTime(arrayTime, isToday)

        if(validTime){
          queryStartFilter.time.push(validTime)
          this.onStartFilter('time', validTime, undefined, undefined, false)
        }
      }
    };

    takeStringStatus = (status: string, queryStartFilter: {status: unknown[]}) => {
      const arrayStatuses = status
        .split(',')
        .map(item => +item)
        .filter(item => item)

      arrayStatuses.forEach(item => {
        Object.values(notificationStatus).forEach(element => {
          if(element.value === item) {
            if(queryStartFilter.status) {
              queryStartFilter.status.push(element.typeTranslate)
            } else {
              queryStartFilter.status = [element.typeTranslate]
            }

            this.onStartFilter('status', element.typeTranslate, undefined, undefined, false)
          }
        })
      })
    };

    takeStringCategory = (category: string, queryStartFilter: {status: unknown[]}) => {
      const arrayCategories = category
        .split(',')
        .map(item => +item)
        .filter(item => item)

      arrayCategories.forEach(item => {
        Object.values(notificationCategory).forEach(element => {
          if(element.value === item) {
            if(queryStartFilter.status) {
              queryStartFilter.status.push(element.typeTranslate)
            } else {
              queryStartFilter.status = [element.typeTranslate]
            }

            this.onStartFilter('category', element.typeTranslate, undefined, undefined, false)
          }
        })
      })
    };

    validateOnlyNumber = (id: string) => {
      const onlyNumber = id.replace(/\D/g, '')

      if(onlyNumber) {
        const findUnit = this.props.users.reduce((result: FilterUnit | null, user) => {
          const findU = user.units.ownedUnits.find(unit => unit.id === Number(id))

          if(findU) {
            return findU
          }

          return result
        }, null)

        if(findUnit){
          return {
            id  : findUnit.id,
            imei: findUnit.imei,
            name: findUnit.name,
          }
        }
      }

      return false
    };

    validateTime = (timeRange: string[], isToday = false) => {
      const from = timeRange[0].replace(/\D/g, '')
      const to = timeRange[1].replace(/\D/g, '')

      if(from.length === 15 && to.length === 15 && moment(from, 'YYMMDDHHmmss000') < moment(to, 'YYMMDDHHmmss000')) {
        return {
          from,
          to,
          ...isToday && { today: true },
        }
      }

      return false
    };

    startFilterBecauseLocation = (queryStartFilter: {
      machine: TMachine[]
      time: TimeInfo[]
    }) => {
      if(queryStartFilter.machine.length && queryStartFilter.time.length && data.callback) {
        this.props.dispatch(data.callback({
          ...queryStartFilter,
          gmt : this.context?.gmt ?? GMT_MOSCOW,
          lang: this.context?.lang || LANG_RU,
        }))
      }
    };

    selectMachine = (machine: {id: string | number} | false, pushToStringBrowser = true) => {
      const selectedParamItems = this.state.filter.find(item => item.section === 'machine')?.items || []

      if(!machine) {
        return
      }

      const machinesList: Record<string | number, UnitInfo> =
        selectedParamItems.reduce<Record<string|number, UnitInfo>>((acc, user) => {
          // не будем использовать здесь spread оператор, т.к. он под капотом копирует данные,
          // а здесь около 20к записей и получается около 200к копирований, что пипец как тормозит
          for(let unit of user.units) {
            acc[unit.id] = unit
          }

          return acc
        }, {})

      const selectedMachine = machinesList[machine.id]

      if(selectedMachine) {
        this.onStartFilter('machine', {
          id  : selectedMachine.id,
          imei: selectedMachine.imei,
          name: selectedMachine.name,
        }, undefined, undefined, pushToStringBrowser)
      }
    }


    onStartFilterArrayMachine = (value: Array<string | number>, pushToStringBrowser = true) => {
      this.setState(prevState => ({
        ...prevState,
        filter: prevState.filter.map(item => {
          if(item.section === 'machine') {
            return {
              ...item,
              result: value.reduce((acc: UnitInfo[], id) => {
                const machInfo = item?.items?.reduce((result: UnitInfo | undefined, user: UnitsItem) => {
                  const findUnit = user.units.find(unit => unit.id === id)

                  if(findUnit) {
                    return {
                      id  : findUnit.id,
                      imei: findUnit.imei,
                      name: findUnit.name,
                    }
                  }

                  return result
                }, undefined)

                if(machInfo) {
                  return [
                    ...acc,
                    machInfo,
                  ]
                }

                return acc
              }, []),
            }
          }

          return item
        }),
      }), () => this.validateFilter(pushToStringBrowser))
    }

    onStartFilter = (
      section: string,
      value: {id: string | number} | TimeInfo | string | UnitInfo,
      solo?: boolean,
      clear?: boolean,
      pushToStringBrowser = true,
    ) => {
      //@ts-expect-error
      this.setState(prevState => {
        const getResult = (source: DefaultItem) => {
          const valueObject = typeof value === 'object' ? value as {id?: string | number} : null
          const findItem = source.result.find(item => item.id === valueObject?.id) !== undefined

          switch(section) {
            case 'machine':
              if(findItem) {
                return solo ? [] : source.result.filter(item => item.id !== valueObject?.id)
              }

              if(solo) {
                return [value]
              }

              if(source.result.length < this.props.maxRequestVehicle) {
                return [...source.result, value]
              }

              this.maxVehicleCountMessage()
              return [...source.result]

            case 'time':
              return findItem && clear ? [] : [value]

            default:
              if(solo) {
                return [value]
              }

              if(source.result.find(item => item === value)) {
                return source.result.filter(item => item !== value)
              }

              return [...source.result, value]
          }
        }

        return {
          ...prevState,
          filter: prevState.filter.map(element => {
            if(element.section === section) {
              return {
                ...element,
                result: getResult(element),
              }
            }

            return element
          }),
        }
      }, () => this.validateFilter(pushToStringBrowser))
    };

    validateFilter = (pushToStringBrowser: boolean) => {
      const findNoValidate = this.state.filter.find(item => {
        return item.validate && !item.result.length
      })

      if(!findNoValidate) {
        this.setState({ shadowButton: false })
      } else {
        this.setState({ shadowButton: true })
      }

      if(pushToStringBrowser) {
        this.state.filter.forEach(item => {
          if(item.result.length){
            this.factoryForBrowserString(item.section, item.result)
          } else {
            //@ts-expect-error
            this.buildString('', item.section)
          }
        })
      }
    };

    deleteParameters = (section: string, value: unknown) => {
      this.setState(prevState => ({
        ...prevState,
        filter: prevState.filter.map(element => {
          if(element.section === section) {
            return {
              ...element,
              result: element.result.filter(item => item !== value),
            }
          }

          return element
        }),
      }), () => {
        if(!this.state.filter.find(item => item.result.length)){
          this.setState({ filtration: false })
        }
      })
    };

    factoryForBrowserString = (type: string, options: unknown[]) => {
      switch(type) {
        case 'machine': {
          this.machListInBrowserString(type, options as {id: string | number}[])
          break
        }

        case 'time': {
          this.timeInBrowserString(type, options as TimeInfo[])
          break
        }

        case 'status': {
          this.statusInBrowserString(type, options)
          break
        }

        case 'category': {
          this.categoryInBrowserString(type, options)
          break
        }

        default: {
          break
        }
      }
    };

    machListInBrowserString = (type: 'machine', list: {id: string | number}[]) => {
      const machinesString = list.reduce((result, item, index) => {
        if(index <= 500) {
          result = `${result}${item.id}${index !== list.length - 1 && index !== 500 ? ',' : ''}`
        }

        return result
      }, '')

      this.buildString({ [type]: machinesString }, type)
    };

    timeInBrowserString = (type: 'time', value: TimeInfo[]) => this.buildString({
      [type]: value[0].today ? 'today' : `${value[0].from},${value[0].to}`,
    }, type);

    statusInBrowserString = (type: 'status', list: unknown[]) => {
      const statusString = list.reduce<string>((result, item, index) => {
        const findStatus = Object.values(notificationStatus).find(element => element.typeTranslate === item)

        if(findStatus) {
          result = `${result}${findStatus.value}${index !== list.length - 1 ? ',' : ''}`
        }

        return result
      }, '')

      this.buildString({ [type]: statusString }, type)
    };

    categoryInBrowserString = (type: 'category', list: unknown[]) => {
      const categoryString = list.reduce<string>((result, item, index) => {
        const findCategory = Object.values(notificationCategory).find(category => category.typeTranslate === item)

        if(findCategory) {
          result = `${result}${findCategory.value}${index !== list.length - 1 ? ',' : ''}`
        }

        return result
      }, '')

      this.buildString({ [type]: categoryString }, type)
    };

    buildString = <T extends string,>(value: Record<T, string>, type: T) => this.setState(prevState => {
      const query = new URLSearchParams(prevState.stringBrowser)

      if(!value) {
        query.delete(type)
      }
      else {
        query.set(type, value[type])
      }

      const string = query.toString()

      return { stringBrowser: string ? `?${string}` : '' }
    }, () => {
      this.props.history.push(`${this.props.location.pathname}${this.state.stringBrowser}`)
    });

    onFilter = (state: {section: string, result: unknown}[]) => {
      const parameters = state.reduce((acc, item) => ({
        ...acc,
        [item.section]: item.result,
      }), {})

      // @ts-expect-error
      this.props.dispatch(data.callback({
        ...parameters,
        gmt : this.context?.gmt ?? GMT_MOSCOW,
        lang: this.context?.lang || LANG_RU,
      }))
    };

    clearFilter = (callback?: () => void, route = true) => {
      if(route){this.props.dispatch(data.clear())}

      this.setState(prevState => ({
        ...prevState,
        filter: prevState.filter.map(item => ({
          ...item,
          result: [],
        })),
        shadowButton: true,
      }), () => {
        if(route) {
          this.props.history.push(this.props.location.pathname)

          if(callback) {callback()}
        }
      })
    };

    fixedDataFilter = () => {this.props.dispatch(fixedDataFilter(this.state.stringBrowser))};
    clearFixedDataFilter = () => this.props.dispatch(clearFixedDataFilter());

    render() {
      if(this.props.users.length) {
        return (
          <ComposedComponent
            {...this.props}
            translate={this.props.translate}
            shadowButton={this.state.shadowButton}
            filtration={this.state.filtration}
            filter={this.state.filter}
            onFilter={this.onFilter}
            onStartFilter={this.onStartFilter}
            clearFilter={this.clearFilter}
            deleteParameters={this.deleteParameters}
            fixedDataFilter={this.fixedDataFilter}
            clearFixedDataFilter={this.clearFixedDataFilter}
            stringFixedDataFilter={this.props.stringFixedDataFilter}
            onStartFilterArrayMachine={this.onStartFilterArrayMachine}
          />
        )
      }

      return null
    }
  }

  HOCFilter.contextType = ConfigContext

  return connect(
    (state: AppStateType) => ({
      info                 : state.user.info,
      maxRequestVehicle    : state.user.config.VEHICLE_COUNT,
      stringFixedDataFilter: state.reports.get('fixedDataFilter'),
      users                : filteredUsersOfType(state),
    }),

    //@ts-expect-error
  )(withTranslate(withRouter(HOCFilter)))
}
