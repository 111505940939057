import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { TTranslate } from 'types'
import { formatter } from '../helpers'
import { LANG_RU } from '../../../../constants/lang'

export type CreateExcelParam = {
  key: string
  name: string
  value: number
  measure: string
}

type CreateExcelArgs = {
  t: TTranslate
  from: string | number
  to: string | number
  parameters: CreateExcelParam[]
  name: string
  model: string
  login: string
}

export const createExcelFile = async ({ from, login, model, name, parameters, t, to }: CreateExcelArgs) => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  let cursor = 4

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/commonReport.xlsx`)

    /*@ts-expect-error*/
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  // Generate report body
  parameters.forEach(P => {
    sheet.insertRow(cursor, [P.name, formatter(P.key, P.value, P.measure)])

    ++cursor
  })

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('B1').value = `${t('formed')}: ${ login }, ${formats.date.dateViewFormatter(createDateTime, LANG_RU)}, ${formats.date.timeFormatter(createDateTime, LANG_RU)}`
  // eslint-disable-next-line max-len
  sheet.getCell('A2').value = `${t('A report about machine')} ${model} ${name} ${t('during the period')} ${new Date(from).format('DD.MM.YY HH:mm:ss')} - ${new Date(to).format('DD.MM.YY HH:mm:ss')}`;

  ['Parameter', 'Meaning'].forEach((paramName, index) => sheet.getRow(3).getCell(index + 1).value = t(paramName))

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'machine_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
