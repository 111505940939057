import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { TTranslate } from 'types'
import { F1300Icon, KsIcon,Rsm3535Icon, ServiceIndicatorIcon, ZukIcon } from '../icons'

const colors = {
  warning: '#D10029',
}

type TProps = {
  model: string
  name: string
  serviceType: string
  serviceValue: number
  style?: CSSProperties
  t: TTranslate
  typeName: string
}

export default function Row({
  model,
  name,
  serviceType,
  serviceValue,
  style,
  t,
  typeName,
}: TProps) {
  const serviceTypeValue = serviceType && serviceType.replace('TO_', '')
  const isWarning = serviceValue < 10
  const warningIconColor = { ...isWarning ? { color: colors.warning } : {} }

  return (
    <Vehicle style={style}>
      <VehicleIcon title={model}>
        {model === 'TRACTOR' && <ZukIcon {...warningIconColor} />}
        {model === 'KUK' && <F1300Icon {...warningIconColor} />}
        {model === 'ZUK' && <Rsm3535Icon {...warningIconColor} />}
        {model === 'KS' && <KsIcon {...warningIconColor} />}
      </VehicleIcon>

      <VehicleTitle isWarning={isWarning}>{typeName}</VehicleTitle>
      <VehicleSerial isWarning={isWarning}>{name}</VehicleSerial>

      <VehicleServiceHours>
        {serviceValue !== Infinity && <ServiceIndicatorIcon value={serviceTypeValue} {...warningIconColor} />}
      </VehicleServiceHours>

      <VehicleValue isWarning={isWarning}>{serviceValue === Infinity ? '—' : `${serviceValue.toFixed(serviceValue < 9.9 ? 1 : 0)} ${t('h')}`}</VehicleValue>
    </Vehicle>
  )
}

const warningTextColor = css<{ isWarning: boolean }>`
  color: ${({ isWarning }) => isWarning ? colors.warning : '#202020'};
`

const Vehicle = styled.div`
  display: flex;
  width: 100%;
  height: 19px;

  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);

  margin-left: 15px;
  margin-right: 10px;
  margin-top: 4px;
`

const VehicleTitle = styled.div`
  flex-grow: 1;

  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* Color 3 */
  ${warningTextColor};

  margin-left: 9px;
`

const VehicleValue = styled.div`
  /* Bold 10 */
  font-style: normal;
  font-weight: bold;
  font-size: 10px;

  /* identical to box height, or 160% */
  display: flex;
  align-items: center;
  ${warningTextColor};

  opacity: unset;
  margin-left: 10px;
  width: 60px;
`

const VehicleIcon = styled.div`
  margin-top: -3px;
  width: 20px;
`

const VehicleSerial = styled.div`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  min-width: 100px;

  /* Color 3 */
  ${warningTextColor};

  margin-left: 17px;
`

const VehicleServiceHours = styled.div`
  width: 20px;
`
