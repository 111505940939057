import { Avatar, formats } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { LANG_RU } from '../../../../../constants/lang'
import { AppStateType } from '../../../../../reducers/store'
import { ReportRowTitle } from '../ReportRowTitle'

type TFarm = {
  name: string
  timestamp: string
  ownerName?: string
}

type TAuditRecord = {
  field: string
  login: string
  timestampCreate: number
  value: string
}

export type TOperator = {
  id: number
  name: string
  telNumber: number
  photoSmall: string
  farms: TFarm[]
  auditLog: TAuditRecord[]
}

type TProps = {
  operator: TOperator
}

export const OperatorContent: FC<TProps> = ({ operator }) => {
  const t = useTranslate()
  const lang = useSelector((state: AppStateType) => state.user.info.lang || LANG_RU)

  return <Content>
    <ContentTitle>
      {t('driver information')}
    </ContentTitle>

    {!isEmpty(operator.farms) && <ReportRowWrapper>
      <ReportRowTitle title={t('farmings')}>
        <Table>
          {operator.farms.map(({ name, timestamp }) => <Row key={`${timestamp}_${name}`}>
            <RowTitle>{name}</RowTitle>
            <Param>{formats.date.dateViewFormatter(formats.api.parseDateTimeFromCalendarApi(timestamp), lang)}</Param>
          </Row>)}
        </Table>
      </ReportRowTitle>
    </ReportRowWrapper>}

    {!isEmpty(operator.auditLog) && <ReportRowWrapper>
      <ReportRowTitle title={t('change history')}>
        <Table>
          {operator.auditLog.map(
            ({ field, login, timestampCreate, value }) => <Row key={`${timestampCreate}_${value}`}>
              <RowTitle>{`${t('changed to')}:`} <RowTitleValue>
                {field === 'photo'
                  ? <Avatar size="24px" src={`data:image/png;base64, ${value}`}/>
                  : value
                }
              </RowTitleValue>
              </RowTitle>

              <FirstParam>Изменил: {login || `${t('driver')}`}</FirstParam>
              <SecondParam>{formats.date.dateViewFormatter(new Date(timestampCreate), lang)}</SecondParam>
            </Row>)}
        </Table>
      </ReportRowTitle>
    </ReportRowWrapper>}
  </Content>
}

const ReportRowWrapper = styled.div`
  border: 1px solid #F2F2F2;
  margin: 16px 0;
  border-radius: 4px;

  & > div:first-child {
    background-color: #F2F2F2;
  }
`

const FirstParam = styled.div`
  max-width: 30%;
  padding-right: 16px;
  text-align: left;
  flex: 1 0;
`

const Param = styled(FirstParam)`
  max-width: 40%;
  text-align: right;
`

const SecondParam = styled.div`
  min-width: 15%;
  text-align: right;
`

const Content = styled.div`
  margin: 16px;
`

const Table = styled.div`
  & :last-child {
    border: none;
  }
`

const Row = styled.div`
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #F2F2F2;
  flex: 1 0;
`

const RowTitle = styled.div`
  display: flex;
  width: 75%;
  align-items:center;
`

const RowTitleValue = styled.div`
  display: flex;
  margin-left: 16px;
`

const ContentTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`
