import { SimpleSelectTree } from '@agdt/agrotronic-react-components'
import { find, isEmpty, mergeLeft, propEq } from 'ramda'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { userLogout } from 'reducers/users/actions'
import { setOperatorDefaultAccount } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TAccount, TTranslate, TUser } from 'types'
import { C_SELECTED_FARM_LOCAL_STORAGE_KEY } from '../../../../constants'
import { ConfigContext } from '../../../../containers/ValidateUser'

const C_ITEM_SIZE = 48

export type TProps = {
  t: TTranslate
  user: TUser | TAccount
}

export type TSelectorListItem = TAccount & { authorizationRequired?: boolean }

const FarmSelector = ({ t, user }: TProps) => {
  const farm = useContext(ConfigContext)?.farm
  const [selectedFarmName, setSelectedFarmName] = useState('')
  const [anotherAccounts, setAnotherAccounts] = useState<TSelectorListItem[]>([])
  const ref = useRef(null)

  useEffect(() => {
    if(!farm?.selectedFarmId || isEmpty(farm?.accounts)) { return }

    /*@ts-expect-error*/
    setSelectedFarmName(find(propEq('id', farm.selectedFarmId), farm.accounts).name)
  }, [farm?.accounts, farm?.selectedFarmId])

  const handleSelect = useCallback(async node => {
    if(global.localStorage) {
      const savedFarms = JSON.parse(localStorage.getItem(C_SELECTED_FARM_LOCAL_STORAGE_KEY) ?? '{}')

      global.localStorage.setItem(
        C_SELECTED_FARM_LOCAL_STORAGE_KEY,
        JSON.stringify(mergeLeft({ [user.id]: node.id }, savedFarms)),
      )
    }

    if(node.authorizationRequired) {
      setOperatorDefaultAccount({ defaultUser: node.login })
        .then(userLogout)
        .catch(e => console.log('Не удалось изменить организацию по умолчанию', e))
    } else {
      if(farm?.setSelectedFarmId) {
        farm.setSelectedFarmId(node.id)
      }
    }
  }, [farm?.setSelectedFarmId, user.id])

  useEffect(() => {
    const getAnotherAccounts = () => {
      if(user.accountType === 'user') {
        getStore('dictionaries').operatorUsersAccounts.getValue().then(operatorAccounts => {
          if(ref.current !== null) {setAnotherAccounts(operatorAccounts.reduce<TSelectorListItem[]>((acc, account) => {
            if(account.id !== user.accountId) {
              acc.push({ ...account, authorizationRequired: true })
            }
  
            return acc
          }, []))}
        }).catch(e => {
          errorMessage(e)
        })
      }
    }

    if(user.accountType && !isEmpty(farm?.accounts)) {
      getAnotherAccounts()
    }
  }, [farm?.accounts, user.accountId, user.accountType])

  const showSelector = anotherAccounts.length + (farm?.accounts?.length || 0) > 1

  return <>
    {showSelector && farm && <SelectTreeWrapper ref={ref}
    >
      <SimpleSelectTree

        /*@ts-expect-error*/
        accounts={farm.accounts}
        anotherAccounts={anotherAccounts}
        disabled={isEmpty(farm.accounts)}
        fieldHeight={40}
        fieldWidth={256}
        itemSize={C_ITEM_SIZE}
        listHeight={350}
        noData={t('Not found')}
        placeholder={t('search')}
        onSelectOption={handleSelect}
        overflowY="hidden"
        title={selectedFarmName}
      />
    </SelectTreeWrapper>}
  </>
}

export default FarmSelector

const SelectTreeWrapper = styled.div`
  width: 256px;
  padding-right: 34px;
`
