import { Spinner } from '@agdt/agrotronic-react-components'
import { LineString, MultiLineString, MultiPoint, Polygon } from 'geojson'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { addGeoZone as addGeoZoneAction, watchGeoZone } from 'reducers/geoZone/actions'
import { createObject } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import { FeatureCollectionWithFilename } from 'shpjs'
import styled from 'styled-components'
import { validateGeozone } from 'ui/Map/utils/helpers'
import { convertFileToArrayBuffer, tryParseShape } from './helpers'

type TAllowedGeometry = MultiPoint | LineString | MultiLineString | Polygon
type TProps = {
  addGeoZone: (data: {
    id : number
    location: TAllowedGeometry
    name : string
    type : TAllowedGeometry['type']
  }) => void

  openGeoZone: (id: number) => void
}

const LoadShapeFile = ({ addGeoZone, openGeoZone }: TProps) => {
  const [loadShapeFile, setLoadShapeFile] = useState<boolean>(false)
  const inputElement = useRef<HTMLInputElement>(null)
  const t = useTranslate()

  //@ts-expect-error
  const clearInput = () => inputElement.current.value = null

  //@ts-expect-error
  const errorLoadShape = text => {
    clearInput()
    errorMessage(text)
    setLoadShapeFile(false)
  }

  // Ищем ту feature у которой есть геометрия
  //@ts-expect-error
  const getGeometry = (featureCollection: FeatureCollectionWithFilename | FeatureCollectionWithFilename[]) => {
    for(const item of Array.isArray(featureCollection) ? featureCollection : [featureCollection]) {
      for(const feature of item.features) {
        if(feature.geometry) {
          return feature.geometry
        }
      }
    }
  }

  const convertToLayer = async (geometry: TAllowedGeometry, fileName: string) => {
    try {
      //@ts-expect-error
      const { id } = await createObject({
        location: geometry,
        name    : fileName,
      })

      addGeoZone({
        id      : Number(id),
        location: geometry,
        name    : fileName,
        type    : geometry.type,
      })

      openGeoZone(id)

      clearInput()
      setLoadShapeFile(false)
    } catch(e){
      console.error(e)
      errorLoadShape(t('Shape file is not correct'))
    }
  }

  const handleLoadShapeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.files || e.target.files.length === 0) {
      console.error('Files should include at least one file')
      return
    }

    const file = e.target.files[0]
    setLoadShapeFile(true)

    if(!['application/zip', 'application/x-zip-compressed'].includes(file.type)) {
      errorLoadShape(t('Error load file: need type file zip'))
      console.error('Wrong type formal', file.type)
      errorLoadShape(t('operation:Error load file - need type file zip'))
      return
    }

    const arrayBuffer = await convertFileToArrayBuffer(file)

    if(!arrayBuffer) {
      errorLoadShape(t('File read error'))
      return
    }

    const featureCollection = await tryParseShape(arrayBuffer)

    if(!featureCollection) {
      errorLoadShape(t('Shape file have not supported type'))
      return
    }

    const geometry = getGeometry(featureCollection)

    // Проверяем геозону на взаимопересечения ее границ
    if(!validateGeozone(geometry)) {
      errorLoadShape(t('Shape file is not correct'))
      return
    }

    convertToLayer(geometry as TAllowedGeometry, file.name)
  }

  return <>
    <ShapeFileInput
      id="f1"
      onChange={handleLoadShapeFile}
      ref={inputElement}
      type="file"
    />

    <label htmlFor="f1">
      {loadShapeFile
        ? <SpinnerWrapper><Spinner color="rgba(0, 0, 0, .54)" size={20} /></SpinnerWrapper>
        : <div className="geoZone-add__button"/>
      }
    </label>
  </>
}

const SpinnerWrapper = styled.div`
  margin: 15px;
`

const ShapeFileInput = styled.input`
  display: none;
`

export default connect(null, dispatch => ({

  //@ts-expect-error
  addGeoZone: data => dispatch(addGeoZoneAction(data)),

  //@ts-expect-error
  openGeoZone: id => dispatch(watchGeoZone(id, true)),
}))(LoadShapeFile)
