import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ru'
import { hooks } from '@agdt/agrotronic-react-components'
import moment from 'moment'

export type IntlStateType = Readonly<{
  locale: string
}>

export type IntlActionTypesType = {
  type: 'SET_LOCALE'

  // @todo странная ошибка типа, если сделать обязательным
  locale?: string
}

const initialState: IntlStateType = {
  locale: hooks.getLang(),
}

// @todo есть ощущение, что надо вынести из редакса
export default function Intl(state = initialState, action: IntlActionTypesType): IntlStateType {
  switch(action.type) {
    case 'SET_LOCALE':
      const locale = action.locale

      if(!locale) {
        return state
      }

      // @todo сайд эффекты
      hooks.setLang(locale.toUpperCase())
      moment.locale(locale.toLowerCase())
      hooks.setLang(locale.toLowerCase())

      return {
        ...state,
        locale,
      }

    default:
      return state
  }
}
