import React from 'react'

type TProps = Partial<{
  className: string
  color?: string
  size: number
}>

const KsIcon = ({ className, size = 20, color='#202020' }: TProps) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0KsIcon)">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 21.5C7 22.329 6.329 23 5.5 23C4.671 23 4 22.329 4 21.5C4 20.671 4.671 20 5.5 20C6.329 20 7 20.671 7 21.5ZM20 20.5C20 21.881 18.881 23 17.5 23C16.119 23 15 21.881 15 20.5C15 19.119 16.119 18 17.5 18C18.881 18 20 19.119 20 20.5ZM27 21H20.95C20.973 20.835 21 20.671 21 20.5C21 18.567 19.433 17 17.5 17C16.012 17 14.749 17.933 14.243 19.243L13 18H11.429L11 21H10L10.429 18H3L3.578 13.952L14 13V9H20L19 16H20L27 21ZM28 15.6284L26.09 18.2574L23 17.2534V14.0034L26.09 13.0004L28 15.6284Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0KsIcon">
          <rect x="3" y="9" width="25" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default KsIcon
