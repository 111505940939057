import { action, makeObservable, observable } from 'mobx'
import { is } from 'ramda'

type TSort = 1 | -1 | 0
const isNumber = is(Number)

//@ts-expect-error
const number2tsort = N => ({
  asc : 1,
  desc: -1,
}[N] || 0)

//@ts-expect-error
const tsort2number = N => ({
  '-1': 'desc',
  '1' : 'asc',
}[N] || null)

class SortableListStore<TItem /*extends { [key: string]: string | number }*/> {
  items: TItem[] = []
  @observable sortField: (keyof TItem) | Function | null = null
  @observable sortDirection: TSort = 0
  @observable sortedItems: TItem[] = []

  get sortDirectionString() {
    return tsort2number(this.sortDirection)
  }

  @action.bound setItems(items: TItem[]) {
    this.items = items
    this.sort()
  }

  // Sort items
  @action.bound sort() {
    if(this.sortDirection === 0 || this.sortField === null) {
      this.sortedItems = this.items
    } else {
      this.sortedItems = [...this.items].sort(
        (A, B) => {
          if(this.sortField === null) { return 0 }

          const aSortField = this.sortField instanceof Function ? this.sortField(A) : A[this.sortField]
          const bSortField = this.sortField instanceof Function ? this.sortField(B) : B[this.sortField]
          return aSortField > bSortField ? this.sortDirection : this.sortDirection * -1
        },
      )
    }
  }

  @action.bound setSortField(sortField: keyof TItem | Function, sortDirection: TSort | number) {
    this.sortField = sortField
    this.sortDirection = isNumber(sortDirection) ? sortDirection : number2tsort(sortDirection)
    this.sort()
  }

  constructor() {
    makeObservable(this)
  }
}

export { SortableListStore }
