//@ts-expect-error
import LGeo from 'leaflet-geodesy'
//@ts-expect-error
import simplepolygon from 'simplepolygon'

export const calculatePolygonArea = (polygon: unknown) => LGeo.area(polygon) / 10000

export function validateGeozone(newPoints: unknown) {
  //@ts-expect-error
  if(newPoints.length <= 2) {
    return true
  }

  const poly = {

    //@ts-expect-error
    geometry: !newPoints.type ? {

      //@ts-expect-error
      coordinates: [[...newPoints]],
      type       : 'Polygon',
    } : newPoints,
    type: 'Feature',
  }

  try {
    const result = simplepolygon(poly)

    if(result.features.length > 1) {
      return false
    }
  } catch(e) {
    return false
  }

  return true
}
