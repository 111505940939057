export const columns = [
  { id: 'model', title: 'type' },
  { id: 'name', title: 'serial n' },
  { id: 'eventName', title: 'events' },
  { id: 'dateStart', title: 'date_of_start' },
  { id: 'timeStart', title: 'time_of_start' },
  { id: 'timeEnd', title: 'time_of_end' },
  { id: 'duration', title: 'duration' },
  { id: 'fuel', title: 'fuel' },
  { id: 'fuelVolumeStart', title: 'Start of fuel' },
  { id: 'fuelVolumeEnd', title: 'End of fuel' },
]
