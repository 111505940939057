import L from 'leaflet'
import React, { FC, useCallback,useEffect, useRef, useState } from 'react'
import { Map, Marker } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import { TMapTypes, TTranslate } from 'types'
import { Control } from 'utils/LeafletCustomControl'
import { LANG_RU } from '../../constants/lang'
import TooltipD from '../Tooltip'
import { MapLayer } from './MapLayer'
import { iconShadow } from './markersType'
import { StyleMap } from './styleMap'

type SoloMachineMapProps = {
  marker: [number, number]
}

export const SoloMachineMap: FC<SoloMachineMapProps> = ({ marker }) => {
  const translate = useTranslate()
  const locale = useSelector((state: AppStateType) => state.user.info.lang || LANG_RU)

  return <SimpleExample
    marker={marker}
    translate={translate}
    locale={locale}
  />
}

type SimpleExampleProps = {
  translate: TTranslate
  locale: string
  marker: [number, number]
}

const SimpleExample: FC<SimpleExampleProps> = ({ translate, locale, marker }) => {
  const map = useRef<Map>(null)
  const controlTypeMapRef = useRef<HTMLDivElement>(null)
  const [type, setType] = useState<TMapTypes>('google1')
  const [openControlTypeMap, setOpenControlTypeMap] = useState(false)

  const onCenter = () => {
    if(map.current) {
      map.current.leafletElement.flyTo(marker)
    }
  }

  const togglePopupType = () => {
    setOpenControlTypeMap(prevState => !prevState)
  }

  const switchType = (newType: TMapTypes) => {
    setType(newType)

    if(!map.current) {
      return
    }

    const center = map.current.leafletElement.getCenter()
    const mapZoom = map.current.leafletElement.getZoom()

    map.current.leafletElement.options.crs = L.CRS.EPSG3857

    //@ts-expect-error
    map.current.leafletElement._resetView(center, mapZoom, true)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(!controlTypeMapRef.current || !controlTypeMapRef.current.contains(event.target as Node)) {
      setOpenControlTypeMap(false)
    }
  }, [setOpenControlTypeMap])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <Map
      ref={map}
      fadeAnimation={false}
      zoomAnimation={false}
      center={marker}
      zoom={10}
      minZoom={2}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
    >
      <TooltipD
        text={translate('Change zoom of map Can use scroll of mouse or multitouch')}
        style={{ top: '56px', left: '75px' }}
      />

      <MapLayer lang={locale} mapType={type} />

      <TooltipD
        text={translate('Show center of a map')}
        style={{ top: '155px', left: '75px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
      />
      <Control position="topleft">
        <div className="map__center">
          <button onClick={onCenter}/>
        </div>
      </Control>
      <TooltipD
        text={translate('The selection type a map')}
        style={{ top: '95px', right: '8px' }} // отступы подобраны на глазок, но переделка на правильный механизм сейчас выглядит нецелесообразной, т.к. в будущем от тултипов скорее всего откажемся
      />
      <Control position="topright">
        <div className="map__topright">
          <div className="map__topright-controll">
            <div className="map-type" ref={controlTypeMapRef}>
              <button
                className="map-type__button"
                onClick={togglePopupType}
              />
              {openControlTypeMap &&
                <StyleMap
                  type={type}
                  switchType={switchType}
                  translate={translate}
                />
              }
            </div>
          </div>
        </div>
      </Control>
      {marker.length &&
        <Marker
          position={[marker[0], marker[1]]}
          icon={iconShadow}
        />
      }
    </Map>
  )
}
