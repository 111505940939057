import { C_COMPACT_HEIGHT, C_LARGE_HEIGHT,C_MIDDLE_HEIGHT } from './WidgetsGallery/widgets.config'

// Update version every time when layout changed included sizes of widgets,
// removed/added default widgets
const VERSION = 3

// виджет погоды отключается по задаче
// https://agdt.evateam.ru/desk/Task/AT-3066#vypilit-servis-pogody-iz-vseh-mest-ispolzovaniya-v-web-prilozhenii

export const layoutsConfig = [

  // Layout 1
  {
    version: VERSION,

    lg: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 0, w: 1, h: C_MIDDLE_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 3, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 7, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 2, y: 7, w: 2, h: C_LARGE_HEIGHT },
    ],

    md: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 0, w: 1, h: C_MIDDLE_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 2, y: 1, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'service', widget: 'service', x: 2, y: 2, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 1, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 2, w: 2, h: C_LARGE_HEIGHT },
    ],

    sm: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 0, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 0, y: 1, w: 1, h: C_MIDDLE_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 1, y: 1, w: 1, h: C_MIDDLE_HEIGHT },
      { i: 'map', widget: 'map', x: 2, y: 2, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 3, y: 3, w: 2, h: C_LARGE_HEIGHT },
    ],

    xs: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 8, h: C_MIDDLE_HEIGHT },
      { i: 'time', widget: 'time', x: 0, y: 1, w: 8, h: C_MIDDLE_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 0, y: 2, w: 8, h: C_MIDDLE_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 0, y: 3, w: 8, h: C_MIDDLE_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 4, w: 8, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 5, w: 8, h: C_LARGE_HEIGHT },
    ],
  },

  // Layout 2
  {
    version: VERSION,

    lg: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 0, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 3, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 3, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 2, y: 3, w: 2, h: C_LARGE_HEIGHT },
    ],

    md: [
      { i: 'square', widget: 'square', x: 2, y: 12, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 2, y: 8, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 0, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 2, y: 4, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 0, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 16, w: 2, h: C_LARGE_HEIGHT },
    ],

    sm: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 1, y: 4, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 0, y: 4, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 8, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 24, w: 2, h: C_LARGE_HEIGHT },
    ],
    xs: [
      { i: 'square', widget: 'square', x: 0, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 0, y: 4, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 0, y: 8, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 12, y: 0, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 16, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 32, w: 2, h: C_LARGE_HEIGHT },
    ],
  },

  // Layout 3
  {
    version: VERSION,

    lg: [
      { i: 'square', widget: 'square', x: 0, y: 16, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 16, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 16, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 3, y: 16, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 0, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 2, y: 0, w: 2, h: C_LARGE_HEIGHT },
    ],

    md: [
      { i: 'square', widget: 'square', x: 2, y: 12, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 2, y: 8, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 2, y: 0, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 2, y: 4, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 0, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 16, w: 2, h: C_LARGE_HEIGHT },
    ],

    sm: [
      { i: 'square', widget: 'square', x: 0, y: 32, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 1, y: 32, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 1, y: 36, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 0, y: 36, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 0, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 16, w: 2, h: C_LARGE_HEIGHT },
    ],
    xs: [
      { i: 'square', widget: 'square', x: 0, y: 32, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'time', widget: 'time', x: 0, y: 36, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'fuel', widget: 'fuel', x: 0, y: 40, w: 1, h: C_COMPACT_HEIGHT },

      //{ i: 'weather', widget: 'weather', x: 12, y: 44, w: 1, h: C_COMPACT_HEIGHT },
      { i: 'map', widget: 'map', x: 0, y: 0, w: 2, h: C_LARGE_HEIGHT },
      { i: 'activity', widget: 'activity', x: 0, y: 16, w: 2, h: C_LARGE_HEIGHT },
    ],
  },
] as const

export type TLayoutsConfig = typeof layoutsConfig
