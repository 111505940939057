import moment from 'moment'
import React, { FC, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { RangePicker } from '../RangePicker'
import { Time, WrapTimePicker } from './timePicker'

export type DateOption = {
  date: moment.MomentInput
  time: Time
}

type DayPickerRangeControllerWrapperProps = {
  permissions?: number
  history?: number
  applyDate: (args: DateOption[]) => void
}

export const DayPickerRangeControllerWrapper: FC<DayPickerRangeControllerWrapperProps> = ({
  permissions,
  history,
  applyDate,
}) => {
  const translate = useTranslate()
  const format = 'DD.MM.YYYY'

  const [fromState, setFromState] = useState<DateOption>({
    date: moment(),
    time: {
      hours : '00',
      minute: '00',
    },
  })

  const [toState, setToState] = useState<DateOption>({
    date: moment(),
    time: {
      hours : '23',
      minute: '59',
    },
  })

  const setTime = (time: { from: Time, to: Time }) => {
    setFromState(prevValue => ({
      ...prevValue,
      time: time.from,
    }))

    setToState(prevValue => ({
      ...prevValue,
      time: time.to,
    }))
  }

  const switchDays = (data: {from: moment.MomentInput, to: moment.MomentInput}) => {
    setFromState(prevValue => ({
      ...prevValue,
      date: data.from,
    }))

    setToState(prevValue => ({
      ...prevValue,
      date: data.to ? data.to : data.from,
    }))
  }

  const onApplyDate = () => {
    applyDate([
      fromState,
      toState,
    ])
  }

  return (
    <div>
      <div className="filter-parameter__inputs">
        <input type="text"
          value={fromState.date ? moment(fromState.date).format(format).toString() : ''}
          disabled
          placeholder={translate('to')}/>
        <input type="text"
          value={toState.date ? moment(toState.date).format(format).toString() : ''}
          disabled
          placeholder={translate('from')}/>
      </div>
      <WrapTimePicker
        from={fromState.time}
        to={toState.time}
        setTime={setTime}
      />
      <RangePicker
        switchDays={switchDays}
        permissions={permissions}
        history={history}
      />
      <div className="Date-Month__set">
        <div className="Date-Month__regs" />
        <div className="filter-parameter__buttons">
          { fromState.date ?
            <button onClick={onApplyDate}>{translate('accept')}</button>
            :
            <button style={{ backgroundColor: '#e3e3e3' }}>{translate('accept')}</button>
          }
        </div>
      </div>
    </div>
  )
}
