import { CheckBox } from '@agdt/agrotronic-react-components'
import { Popover, Slider } from 'antd'
import { SliderValue } from 'antd/es/slider'
import React, { FC, MouseEventHandler, useRef, useState } from 'react'
import { GithubPicker } from 'react-color'
import { Color } from 'react-color'
import styled, { css } from 'styled-components'
import { NormalGraph, SmoothGraph } from './icons'

type TProps = {
  colors: string[]
  index: number
  graphicColor: Color[]
  updateColor: (colors: Color[]) => void
  updateGraphic: (newColors: string[], index: number, showIndex: boolean | undefined, isRollAvg?: boolean, rollAvg?: number) => void
  visibilitySeries: number[]
  hideColorPicker: boolean
  name: string
  measure: string
  isAllowAvg: boolean
}

export const ItemParam: FC<TProps> = ({
  colors,
  index,
  graphicColor,
  updateColor,
  updateGraphic,
  visibilitySeries,
  hideColorPicker,
  name,
  measure,
  isAllowAvg,
}) => {
  const [rollAvg, setRollAvg] = useState(5)
  const [isRollAvg, setIsRollAvg] = useState(false)
  const [show, setShow] = useState(visibilitySeries.indexOf(index + 1) > -1)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(colors[index % colors.length])

  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  const handleClick: MouseEventHandler<HTMLDivElement> = (): void => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = (): void => {
    setDisplayColorPicker(false)
  }

  const handleChange = (newColor: {hex: string}): void => {
    setColor(newColor.hex)
    handleClose()

    let newColors = [...graphicColor]
    newColors[index] = newColor.hex

    updateColor(newColors)
  }

  const toggleRollAvg: MouseEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation()

    const newState = !isRollAvg

    setIsRollAvg(newState)

    setTimeout(() => {
      updateGraphic([], index, undefined, newState, rollAvg)
    })
  }

  const updateRollAvg = (value: SliderValue): void => {
    if(Array.isArray(value)) {
      return
    }

    setRollAvg(value)

    if(timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      updateGraphic([], index, undefined, isRollAvg, value)
    }, 400)

    // @ts-expect-error
    return false
  }

  const toggleGraph = (): void => {
    setShow(!show)

    setTimeout(() => {
      updateGraphic([], index, !show)
    })
  }


  return (
    <WatchControlItem className="watch__control-item">
      <Colored
        color={color}
        onClick={hideColorPicker ? undefined : handleClick}
      />

      {displayColorPicker &&
        <div className="watch__colorpicker-popover">
          <div
            className="watch__colorpicker-cover"
            onClick={handleClose}
          />
          <GithubPicker
            colors={colors}
            key={index}
            color={graphicColor[index]}
            onChange={handleChange}
          />
        </div>
      }

      <CheckBox checked={show} onChange={toggleGraph} />

      <Label onClick={toggleGraph}>
        {name} {measure && `(${measure})`}

        {show && isAllowAvg && <>
          <RollAvg onClick={toggleRollAvg}><NormalGraphStyled selected={!isRollAvg} /></RollAvg>
          <Popover
            content={<SliderContainer onClick={e => {e.stopPropagation()}}><SliderStyled
              min={5}
              max={100}
              step={1}

              /*
              // @ts-expect-error */
              trackStyle={sliderColors}
              handleStyle={sliderColors}
              onAfterChange={updateRollAvg}
              defaultValue={rollAvg}
            /><Block>Сглаживание</Block></SliderContainer>}
          >
            <RollAvg isLeast={true} onClick={toggleRollAvg}><SmoothGraphStyled selected={isRollAvg} /></RollAvg>
          </Popover>
        </>}
      </Label>
    </WatchControlItem>
  )
}

export default ItemParam

const sliderColors = {
  backgroundСolor: '#D10029',
  borderColor    : '#D10029',
  border         : 'solid 2px #D10029',
  color          : '#D10029',
}

const selectedCss = css`
  background: #C4C4C4;
  border-radius: 2px;
  padding: 2px;
`

const SmoothGraphStyled = styled(SmoothGraph)(({ selected }) => selected ? selectedCss : undefined)
const NormalGraphStyled = styled(NormalGraph)(({ selected }) => selected ? selectedCss : undefined)

const RollAvg = styled.span<{isLeast?: boolean}>`
  cursor: pointer;
  padding-left: 14px;

  :first-child {
    padding-left: 16px;
  }

  vertical-align: sub;
  padding-right: ${({ isLeast }) => isLeast ? '5px' : '10px'};
`

const Label = styled.span`
  /* Regular 16 */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`

const Colored = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
  margin-right: 9px;

  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 50%;
`

const WatchControlItem = styled.div`
  line-height: 24px;
`

const SliderContainer = styled.div`
  display: block;
  width: 150px;

`

const SliderStyled = styled(Slider)`
  vertical-align: top;
  color: #D10029;
  .ant-slider-track, .ant-slider:hover .ant-slider-track {
    background-color: #D10029;
  }
`


const Block = styled.div`
  display: inline-block;

`
