import { isNil, update } from 'ramda'
import React, { useEffect } from 'react'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { EditableLegendValue } from './EditableLegendValue'

export type TProps = {
  children: (color: string) => React.ReactNode
  colors: string[]
  id: string
  legendValues: number[]
  setLegendValues: (id: string, values: number[]) => void
  t: TTranslate
  values: number[]
}

export default function LegendItemsList({
  children,
  colors,
  id,
  legendValues,
  setLegendValues,
  t,
  values,
}: TProps) {
  const setValues = (index: number) => (value: string) => {
    const parsedValue = parseInt(value)

    if(isNaN(parsedValue)) { return }

    if(parsedValue < 0) {
      errorMessage(t('value cannot be less than 0'))
      return
    }

    if(index > 0 && legendValues[index - 1] >= parsedValue) {
      errorMessage(`${t('value must be')} ${t('greater than previous one')}`)
      return
    }

    if(index + 1 < legendValues.length && legendValues[index + 1] <= parsedValue) {
      errorMessage(`${t('value must be')} ${t('less than following')}`)
      return
    }

    setLegendValues(id, update(index, parseInt(value), legendValues))
  }

  useEffect(() => {
    setLegendValues(id, values)
  }, [])

  return <>
    {legendValues && colors.map((color, index) =>
      !isNil(legendValues[index]) && <EditableLegendValue
        key={color}
        setValue={setValues(index)}
        value={String(legendValues[index])}
      >
        {children(color)}
      </EditableLegendValue>,
    )}

    <Infin>&infin;</Infin>
  </>
}

const Infin = styled.div`
  font-size: 22px;
`
