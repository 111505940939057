import { combineReducers } from 'redux'
import geoZone from './geoZone'
import map from './map'
import notification from './notification'
import notificationTasks from './notificationTasks'
import reports from './reports'
import settings from './settings'
import Intl from './translate'
import user from './user'
import users from './users'

export const reducers = combineReducers({
  geoZone,
  Intl,
  map,
  notification,
  notificationTasks,
  reports,
  settings,
  user,
  users,
})
