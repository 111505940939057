import L from 'leaflet'

export const pointToLayerGenerator = (_feature: unknown, latlng: L.LatLngExpression) => {
  return L.circleMarker(latlng, {
    color      : '#000',
    fillColor  : '#3388ff',
    fillOpacity: 0.8,
    opacity    : 1,
    radius     : 8,
    weight     : 1,
  })
}
