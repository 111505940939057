import { AppDispatch } from 'reducers/store'

export enum MapActionTypeEnum {
  CENTER_POLYGON = 'App/Map/CENTER_POLYGON',
}

export type MapActionsType = {
  type: MapActionTypeEnum.CENTER_POLYGON
  payload: unknown
}

export const centerPolygon = (id: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: id, type: MapActionTypeEnum.CENTER_POLYGON })

