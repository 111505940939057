import { Notification } from 'reducers/notification/state'
import { createSelector } from 'reselect'

/*@ts-expect-error*/
const getItems = (items, imei) => {
  return items[imei] || []
}

const pageSelectedMachineSelector = (stagesState: Notification) => stagesState.selectedMachine
const pageItemsSelector = (stagesState: Notification) => stagesState.notifications

export const getRecords = createSelector(
  pageItemsSelector,
  pageSelectedMachineSelector,
  getItems,
)
