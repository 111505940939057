import moment from 'moment'
import React, { FC } from 'react'
import { TTranslate } from 'types'
import { ActiveFilterMachinesCol } from './ActiveFilterMachinesCol'

type ElementType = {
  id: number
  name: string
  to?: string
  from?: string
}

type ItemComponentProps = {
  name: string
  item: ElementType | string
  color?: string
  section: string
  onStartFilter: (section: string, item: ElementType | string, solo?: boolean, clear?: boolean) => void
}

type GetNameArgType = {
  item: ElementType | string
  items: unknown[]
  notTranslating: boolean
  labels: string[]
  type: string
  translate: TTranslate
}

type ColProps = {
  result: Array<ElementType | string>
  labels: string[]
  section: string
  notTranslating: boolean
  type: string
  colors?: string[]
  items: unknown[]
  translate: TTranslate
  onStartFilter: (section: string, item: ElementType | string, solo?: boolean, clear?: boolean) => void
}

type WrapActiveParametersProps = {
  disableButtonClear: boolean
  clearFilter: () => void
  translate: TTranslate
}

const getName = ({
  item,
  items,
  notTranslating,
  labels,
  type,
  translate,
}: GetNameArgType): string => {
  const { from, to } = typeof item === 'object' ? item : { from: '', to: '' }

  if(type === 'date-solo') {
    return moment(to, 'YYMMDDHHmmss000').format('DD.MM.YY')
  } else if(type === 'date') {
    const first: string = moment(from, 'YYMMDDHHmmss000').format('DD.MM.YY')
    const second: string = moment(to, 'YYMMDDHHmmss000').format('DD.MM.YY')
    return `${first} - ${second}`
  } else if(labels) {
    const index = items.findIndex(it => it === item)
    return notTranslating ? labels[index] : translate(labels[index])
  }

  return notTranslating ? String(item) : translate(String(item))
}

const Item: FC<ItemComponentProps> = ({ item, name, section, color, onStartFilter }) => {
  const onClick = () => {
    onStartFilter(section, item, undefined, section === 'time')
  }

  return (
    <div className="filter-result">
      <p>{name}</p>
      {color && <i className="filter-result__color" style={{ background: color }} />}
      <span
        className="filter-result__delete"
        tabIndex={0}
        role="button"
        onClick={onClick}
      />
    </div>
  )
}

export const Col: FC<ColProps> = ({
  type,
  result,
  items,
  labels,
  section,
  colors,
  translate,
  notTranslating,
  onStartFilter,
}) => {
  if(['checkbox-machine', 'radio-machine'].includes(type)) {
    return <ActiveFilterMachinesCol
      result={result as ElementType[]}
      items={items}
      section={section}
      colors={colors}
      onStartFilter={onStartFilter}
    />
  }

  return <div className="filter-control__result-col">
    {result &&
      <div>
        {result.map(item =>
          <Item
            key={typeof item === 'object' && item.id ? 'result' + section + item.id : 'result' + section + item}
            item={item}
            color={colors?.[items.findIndex(it => it === item)]}
            section={section}
            name={getName({
              item,
              items,
              labels,
              notTranslating,
              translate,
              type,
            })}
            onStartFilter={onStartFilter}
          />,
        )}
      </div>
    }
  </div>
}

export const WrapActiveParameters: FC<WrapActiveParametersProps> = ({
  children,
  disableButtonClear,
  clearFilter,
  translate,
}) =>
  <div className="filter-control__result">
    <div className="filter-control__result-wrap">
      {children}
    </div>
    <div className="filter-control__result-clear">
      {disableButtonClear &&
        <p
          tabIndex={0}
          role="button"

          // чтобы не передавать SyntheticBaseEvent в clearFilter()
          // иначе ломается приложение из-за того, что clearFilter() ожидает функцию, а не объект
          onClick={() => clearFilter()}
        >
          {translate('clear filter')}
        </p>
      }
    </div>
  </div>
