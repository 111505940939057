import React, { FC } from 'react'
import { Popup } from 'react-leaflet'
import { useDispatch } from 'react-redux'
import { setSearchHousehold } from 'reducers/users/actions'
import { TTranslate } from 'types'
import { TransportPopup, TransportPopupUnit } from './TransportPopup'

type PopupWrapProps = {
  translate: TTranslate
  CURR_PERF_TON?: string
  SPEED?: number
  LOAD?: number
  lat: number
  lng: number
  name: string
  transport?: TransportPopupUnit
  imei: number
  manipulation: boolean
  measure: string
  status?: number
  time: string
  deleteTrack: (value: {imei: number}) => void
}

export const PopupWrap: FC<PopupWrapProps> = ({
  CURR_PERF_TON,
  deleteTrack,
  imei,
  lat,
  LOAD,
  lng,
  manipulation,
  measure,
  name,
  SPEED,
  status,
  time,
  translate,
  transport,
}) => {
  const stateDispatch = useDispatch()

  const onDeleteClick = () => {
    deleteTrack({ imei })
  }

  const handleSetSearchHousehold = (search: string) => () => {
    stateDispatch(setSearchHousehold(search))
  }

  return <Popup position={[lat, lng]}>
    <div className="marker-popup">
      <p className="marker-popup__type">{name}</p>

      <p className="marker-popup__name">
        {translate('speed')}: {SPEED ? SPEED : 0} {translate('km/h')}
      </p>

      <p className="marker-popup__name">
        {translate('Engine load')}: {LOAD ? LOAD : 0} %
      </p>

      {CURR_PERF_TON && <p className="marker-popup__name">
        {translate('Productivity')}: {CURR_PERF_TON} {translate(measure)}
      </p>}

      <p className="marker-popup__name">
        {translate('time')}: {time}
      </p>

      {status !== undefined
        ? <p className="marker-popup__name">{translate('status')}: {translate(`M${status}`)}</p>
        : ''
      }

      {manipulation && <div className="marker-popup__wrap">
        <p
          className="marker-popup__state"
          onClick={handleSetSearchHousehold(name)}
        >
          {translate('Show in list')}
        </p>

        <button
          onClick={onDeleteClick}
          className="marker-popup__delete-track"
        />
      </div>}
    </div>

    {transport && <TransportPopup unit={transport}/>}
  </Popup>
}
