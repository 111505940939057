/* eslint-disable */
export default {
  'to10': 'Maintenance-10 alert',
  'to50': 'Maintenance-50 alert',
  'to250': 'Maintenance-250 alert',
  'to500': 'Maintenance-500 alert',
  'to50Before10': 'Warning 10 operation hours before maintenance-50',
  'to50Before20': 'Warning 20 operation hours before maintenance-50',
  'to50Before30': 'Warning 30 operation hours before maintenance-50',
  'to250Before10': 'Warning 10 operation hours before maintenance-250',
  'to250Before20': 'Warning 20 operation hours before maintenance-250',
  'to250Before30': 'Warning 30 operation hours before maintenance-250',
  'to500Before10': 'Warning 10 operation hours before maintenance-500',
  'to500Before20': 'Warning 20 operation hours before maintenance-500',
  'to500Before30': 'Warning 30 operation hours before maintenance-500',
  'geoIn': 'Entrance to the geofence',
  'geoOut': 'Exit from the geofence',
  'operationStart': 'Start of operation',
  'operationEnd': 'End of operation',
  'interposition': 'Relative position of vehicles',
  'eventStart': 'Start of event',
  'eventEnd': 'End of event',
  'route': 'Warning up on a route',
  'addressIn': 'Warning entrance to it location',
  'addressOut': 'Warning exit of it location',
  'operator': 'Operator vehicle warning',
  'unit': 'Warning from vehicle',
};
