import { icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import { ContextMenu as ContextMenuComponent } from '../ContextMenu'
import Item from '../ContextMenu/Item'

const { DeleteIcon } = icons

type TProps = {
  ContextMenu?: React.ReactNode
  onClose: () => void
  iconColor?: string
}

export default function ({ ContextMenu, onClose, iconColor }: TProps) {
  return (
    <ContextMenuComponent iconColor={iconColor}>
      {ContextMenu}
      <Item Icon={DeleteIcon} onClick={onClose}>Удалить</Item>
    </ContextMenuComponent>
  )
}
