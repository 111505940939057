import styled from 'styled-components'

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #666666;
`

export const CategoryWrapper = styled.div`
  padding: 0 0 16px 0;
`
