/* eslint-disable */
export default {
  'to10': 'Оповещение о необходимости прохождения TO-10',
  'to50': 'Оповещение о необходимости прохождения TO-50',
  'to250': 'Оповещение о необходимости прохождения TO-250',
  'to500': 'Оповещение о необходимости прохождения TO-500',
  'to50Before10': 'Оповещение за 10 моточасов о необходимости прохождения TO-50',
  'to50Before20': 'Оповещение за 20 моточасов о необходимости прохождения TO-50',
  'to50Before30': 'Оповещение за 30 моточасов о необходимости прохождения TO-50',
  'to250Before10': 'Оповещение за 10 моточасов о необходимости прохождения TO-250',
  'to250Before20': 'Оповещение за 20 моточасов о необходимости прохождения TO-250',
  'to250Before30': 'Оповещение за 30 моточасов о необходимости прохождения TO-250',
  'to500Before10': 'Оповещение за 10 моточасов о необходимости прохождения TO-500',
  'to500Before20': 'Оповещение за 20 моточасов о необходимости прохождения TO-500',
  'to500Before30': 'Оповещение за 30 моточасов о необходимости прохождения TO-500',
  'geoIn': 'Вход в геозону',
  'geoOut': 'Выход из геозоны',
  'operationStart': 'Начало выполнения производственной операции',
  'operationEnd': 'Окончание выполнения производственной операции',
  'interposition': 'Взаиморасположение единиц',
  'eventStart': 'Начало события',
  'eventEnd': 'Окончание события',
  'route': 'Оповещение по маршруту',
  'addressIn': 'Оповещение о входе в локацию по адресу',
  'addressOut': 'Оповещение о выходе из локации по адресу',
  'operator': 'Оповещение оператору техники',
  'unit': 'Оповещение от единицы техники',
};
