import { AppHeader, Button, constants, icons } from '@agdt/agrotronic-react-components'
import { GeoJsonObject } from 'geojson'
import L from 'leaflet'
import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import {
  deleteGeoZoneColoring as deleteGeoZoneColoringAction,
  geoZoneColoringLoad as geoZoneColoringLoadAction,
  setGeoZoneColoring as setGeoZoneColoringAction,
} from 'reducers/geoZone/actions'
import { getBuild } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { mapRef } from 'ui/Map'
import TooltipD from 'ui/Tooltip'
import { mapParams } from '../constants'
import { getColoringDataWithFilteredValues, getCurrentZoneUnitsIds, type2sensor, type2type } from './helpers'
import { MapBuildSettings } from './MapBuildSettings'
import { MapDisplaySettings } from './MapDisplaySettings'
import SelectMapType from './SelectMapType'

const { DeleteIcon, EditIcon } = icons

type TProps = {
  close: () => void
  deleteGeoZoneColoring: (id: string) => void
  geoZoneColoringLoad: (load: boolean) => void
  id: string
  isColoringLoad: boolean
  location: GeoJsonObject
  name: string
  onClickDeleteGeozone: (id: string) => void
  onClickEditGeozone: (id: string) => void
  setGeoZoneColoring: (id: string, data: unknown, type: string | null) => void
  t: TTranslate
}

export const DetailsFC = ({
  close,
  deleteGeoZoneColoring,
  geoZoneColoringLoad,
  id,
  isColoringLoad,
  location,
  name,
  onClickDeleteGeozone,
  onClickEditGeozone,
  setGeoZoneColoring,
  t,
}: TProps) => {
  const geoZonesStore = getStore('geoZones')
  const mapBuildSettings = geoZonesStore.mapBuildSettings
  const mapDisplaySettings = geoZonesStore.mapDisplaySettings

  const handleClear = useCallback(() => {
    deleteGeoZoneColoring(id)
    geoZonesStore.clear()
  }, [id])

  const fitToGeoZone = () => {
    const geoJSONLayer = L.geoJson([])
    geoJSONLayer.addData(location)

    if(mapRef) {
      mapRef.fitBounds(geoJSONLayer.getBounds())
    }
  }

  const handlerRequestMap = () => {
    if(!mapBuildSettings.period.from || !mapBuildSettings.period.to) {
      return
    }

    geoZoneColoringLoad(true)

    const params = {

      // См. таску AT-3601, пояснения в комментариях
      onBoard       : true,
      from          : new Date().user.new(mapBuildSettings.period.from).utc,
      objectId      : id,
      sensorTypeName: type2sensor(mapBuildSettings.selectedMapType),
      to            : new Date().user.new(mapBuildSettings.period.to).utc,
      type          : type2type(mapBuildSettings.selectedMapType),
      width         : Number(mapBuildSettings.widthPart.value),
    }

    getBuild(params).then(result => {
      //@ts-expect-error
      if(result.features != null) {
        const ids = getCurrentZoneUnitsIds(result)

        if(ids) {
          mapDisplaySettings.setCurrentZoneUnitsIds(ids)
          mapDisplaySettings.setSelectedUnitsIds([...ids])


          const coloringDataWithFilteredValues = getColoringDataWithFilteredValues(result, ids)
          mapDisplaySettings.setRawData(result)

          setGeoZoneColoring(
            id,

            //@ts-expect-error
            { ...result, features: coloringDataWithFilteredValues },
            mapBuildSettings.selectedMapType,
          )

          fitToGeoZone()
        }


        //TODO после переноса стора геозон в mobx нужно убрать это и смотреть наличие данных о карте урожайности там
        geoZonesStore.setHaveColoringData(true)
        mapBuildSettings.selectedMapType === 'CURR_PRODUCTIVITY_ZUK' && geoZonesStore.getGeoZonProductivity()
      } else {
        geoZonesStore.setHaveNoData(true)
        setTimeout(() => geoZonesStore.setHaveNoData(false), 6000)
      }
    })
      .catch(() => errorMessage(t('service is not available')))
      .finally(() => geoZoneColoringLoad(false))
  }

  const goBackHandler = useCallback(() => {
    handleClear()
    close()
  }, [])

  const handleDeleteGeoZone = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    onClickDeleteGeozone(id)
  }, [id])

  const handleEditGeozone = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    onClickEditGeozone(id)
  }, [id])

  const handleSelect = useCallback(type => {
    mapBuildSettings.setSelectedMapType(type)
    geoZonesStore.clearDisplaySettings()
  }, [id])

  const mapTypeName = mapParams.find(item => item.type === mapBuildSettings.selectedMapType)?.name

 
  return <DetailsWrapper>
    <AppHeader
      goBackHandler={goBackHandler}
      title={name}
    >
      <ButtonsWrapper>
        <ButtonStyled variant="icon" onClick={handleEditGeozone} key="edit-button">
          <EditIcon color={constants.colors.icons.PRIMARY} />
        </ButtonStyled>

        <TooltipD
          text={t('Edit geozone')}
          style={{ top: '-8px', left: '-20px' }}
          key="tooltip-geofzonee2"
        />

        <ButtonStyled variant="icon" onClick={handleDeleteGeoZone} key="delete-button">
          <DeleteIcon color={constants.colors.icons.PRIMARY} />
        </ButtonStyled>

        <TooltipD
          text={t('Delete geozone')}
          style={{ top: '-8px', left: '-20px' }}
          key="tooltip-geofzonee1"
        />
      </ButtonsWrapper>
    </AppHeader>

    <Content>
      <SelectMapType
        data={mapParams}
        handleClear={handleClear}
        handleSelect={handleSelect}
        title={mapTypeName ? t(mapTypeName) : undefined}
      />

      <TooltipD
        text={t('Select type of map')}
        style={{ top: '-34px', left: '432px' }}
        key="tooltip-geofzonee4"
      />

      {mapBuildSettings.selectedMapType && <MapBuildSettings
        isColoringLoad={Boolean(isColoringLoad)}
        handlerRequestMap={handlerRequestMap}
        t={t}
      />}

      {mapBuildSettings.selectedMapType && !isEmpty(mapDisplaySettings.currentZoneUnitsIds) &&
        <MapDisplaySettings
          id={id}
          setGeoZoneColoring={setGeoZoneColoring}
          t={t}
        />}
    </Content>
  </DetailsWrapper>
}

/*@ts-expect-error*/
const mapStateToProps = state => {
  return {
    allowedSensors: state.users.get('allowedSensors'),
    geoZone       : state.geoZone.static,
    isColoringLoad: state.geoZone.isColoringLoad,
  }
}

/*@ts-expect-error*/
const mapDispatchToProps = dispatch => {
  return {

    /*@ts-expect-error*/
    deleteGeoZoneColoring: id => dispatch(deleteGeoZoneColoringAction(id)),

    /*@ts-expect-error*/
    geoZoneColoringLoad: flag => dispatch(geoZoneColoringLoadAction(flag)),

    /*@ts-expect-error*/
    setGeoZoneColoring: (id, data, type) => dispatch(setGeoZoneColoringAction(id, data, type)),
  }
}

export const Details = connect(mapStateToProps, mapDispatchToProps)(observer(DetailsFC))

const Content = styled.div`
  padding: 20px 21px 20px 20px;
  border-top: 1px solid rgba(32, 32, 32, 0.1);
`

const DetailsWrapper = styled.div`
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  width: 480px;
`

const ButtonsWrapper= styled.div`
  display: flex;
`

const ButtonStyled = styled(Button)`
  margin-right: 16px;
`
