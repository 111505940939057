import { DropdownList, icons } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { FC, useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { hexToRGBA, notificationStatus } from 'utils'
import { getStore } from '../../../stores/storesRegistry'

const { SortDownIcon } = icons

type Status = {
  id: string
  value?: number
  color?: string
  name?: string
  type?: string
  typeTranslate?: string
  registersField?: string
}

let statuses: Status[] = [
  { id: '0', typeTranslate: 'status_of_message' },
]

Object.keys(notificationStatus).forEach(item => {
  statuses.push({
    ...notificationStatus[Number(item) as keyof typeof notificationStatus],
    id: item,
  })
})

type DropdownChildProps = {
  text: string
  id: string
  color?: string
  t: TTranslate
  onSelectedStatus: (value: number) => void
}

const DropdownChild: FC<DropdownChildProps> = ({ text, t, color, onSelectedStatus, id }) => {
  const handlerSelectedStatus = () => {
    if(color) {
      onSelectedStatus(Number(id))
    }
  }

  return (
    <DropdownChildContainer>
      <ColorTextContainer
        colorForBackground={color}
        onClick={handlerSelectedStatus}
      >
        {t(text)}
      </ColorTextContainer>
    </DropdownChildContainer>
  )
}

const DropdownSortMachines: FC = () => {
  const [dropdownIsOpen, setDropDownOpen] = useState(false)
  const t = useTranslate()
  const unitsStore = getStore('notices').units

  const toggleDropdown = useCallback(() => {
    setDropDownOpen(!dropdownIsOpen)
  }, [dropdownIsOpen])

  const onSelectedStatus = (status: number) => {
    unitsStore.setSortByStatus(status as keyof typeof notificationStatus)
  }

  const getColor = () => {
    const finderStatus = statuses.find(item => +item.id === +unitsStore.sortByStatus)

    if(!finderStatus) {
      return undefined
    }

    return finderStatus.color
  }

  return (
    <DropdownList
      listChildren={statuses.map(elem =>
        <DropdownChild
          key={elem.id}
          t={t}
          text={String(elem.typeTranslate)}
          id={elem.id}
          color={elem.color}
          onSelectedStatus={onSelectedStatus}
        />,
      )}
      isPopupOpen={dropdownIsOpen}
      onHidePopup={toggleDropdown}
      border="none"
      width="auto"
    >
      <DropdownButtonStyled onClick={toggleDropdown}>
        <SortDownIcon size="24" color="#202020" />
        <DropdownButtonNameStyled colorForBackground={getColor()}>
          {t('sortByQuantity')}
        </DropdownButtonNameStyled>
      </DropdownButtonStyled>
    </DropdownList>
  )
}

const ColorTextContainer = styled.span<{colorForBackground?: string}>`
  font-size: 12px;
  line-height: 16px;
  color: #202020;
  border-radius: 30px;
  cursor: ${({ colorForBackground }) => colorForBackground ? 'pointer' : 'default'};
  padding: ${({ colorForBackground }) => colorForBackground ? '6px 12px' : '0'};
  background-color: ${({ colorForBackground }) => colorForBackground ? hexToRGBA(colorForBackground, 20) : 'none'};

  &:before {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ colorForBackground }) => colorForBackground ? colorForBackground : 'none'};
    content: "";
    display: ${({ colorForBackground }) => colorForBackground ? 'inline-block' : 'none'};
    margin-right: 12px;
  }
`

const DropdownChildContainer = styled.div`
  font-size: 20px;
  height: 30px;
  margin: 3px 24px;
`

const DropdownButtonStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 25px;
  cursor: pointer;
  border-bottom: 1px solid #E2E5EC;
`

const DropdownButtonNameStyled = styled.span<{colorForBackground?: string}>`
  margin-left: 5px;
  color: #202020;
  font-size: 12px;
  line-height: 16px;
  user-select: none;

  &:after {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ colorForBackground }) => colorForBackground ? colorForBackground : 'none'};
    content: "";
    display: ${({ colorForBackground }) => colorForBackground ? 'inline-block' : 'none'};
    margin-left: 12px;

  }
`

export default observer(DropdownSortMachines)
