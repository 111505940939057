import { AppDispatch } from 'reducers/store'
import { GeoColoringData, StaticType } from './state'

export enum GeoZoneActionTypeEnum {
  PUSH_GEOZONE = 'App/Map/PUSH_GEOZONE',
  PUSH_GEOZONE_GEOJSON = 'App/Map/PUSH_GEOZONE_GEOJSON',
  WATCH_GEOZONE = 'App/Map/WATCH_GEOZONE',
  UNWATCH_ALL_GEOZONES = 'App/Map/UNWATCH_ALL_GEOZONES',
  DELETE_GEOZONE = 'App/Map/DELETE_GEOZONE',
  UPDATE_GEOZONE = 'App/Map/UPDATE_GEOZONE',
  ADD_GEOZONE = 'App/Map/ADD_GEOZONE',
  GEOZONE_SEARCH_TEXT_CHANGED = 'App/Map/GEOZONE_SEARCH_TEXT_CHANGED',
  SET_GEOZONE_OPEN = 'App/Map/SET_GEOZONE_OPEN',
  SET_GEOZONE_COLORING = 'App/Map/SET_GEOZONE_COLORING',
  SET_GEOZONE_COLORING_LEGEND_VALUES = 'App/Map/SET_GEOZONE_COLORING_LEGEND_VALUES',
  DELETE_GEOZONE_COLORING = 'App/Map/DELETE_GEOZONE_COLORING',
  GEOZONE_COLORING_LOAD = 'App/Map/GEOZONE_COLORING_LOAD',
}

export type GeoZoneActionsType = {
  type: GeoZoneActionTypeEnum.SET_GEOZONE_OPEN
  payload: {
    id: unknown
    isOpened: boolean
  }
} | {
  type: GeoZoneActionTypeEnum.GEOZONE_SEARCH_TEXT_CHANGED
  payload: string
} | {
  type: GeoZoneActionTypeEnum.PUSH_GEOZONE
  payload: StaticType[]
} | {
  type: GeoZoneActionTypeEnum.PUSH_GEOZONE_GEOJSON
  payload: StaticType
} | {
  type: GeoZoneActionTypeEnum.WATCH_GEOZONE
  payload: {
    id: unknown
    show: boolean
  }
} | {
  type: GeoZoneActionTypeEnum.UNWATCH_ALL_GEOZONES
} | {
  type: GeoZoneActionTypeEnum.DELETE_GEOZONE
  payload: unknown
} | {
  type: GeoZoneActionTypeEnum.UPDATE_GEOZONE
  payload?: StaticType
} | {
  type: GeoZoneActionTypeEnum.ADD_GEOZONE
  payload: StaticType
} | {
  type: GeoZoneActionTypeEnum.SET_GEOZONE_COLORING
  payload: {
    id: unknown
    data: GeoColoringData
    type: string
  }
} | {
  type: GeoZoneActionTypeEnum.SET_GEOZONE_COLORING_LEGEND_VALUES
  payload: {
    id: string | number
    data: number[]
  }
} | {
  type: GeoZoneActionTypeEnum.DELETE_GEOZONE_COLORING
  payload: {
    id: unknown
  }
} | {
  type: GeoZoneActionTypeEnum.GEOZONE_COLORING_LOAD
  payload: boolean
}

export const watchGeoZone = (id: unknown, show: boolean) => (dispatch: AppDispatch) =>
  dispatch({ payload: { id, show }, type: GeoZoneActionTypeEnum.WATCH_GEOZONE })

export const unwatchAllGeoZones = () => (dispatch: AppDispatch) =>
  dispatch({ type: GeoZoneActionTypeEnum.UNWATCH_ALL_GEOZONES })

export const deleteGeoZone = (id: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: id, type: GeoZoneActionTypeEnum.DELETE_GEOZONE })

export const updateGeoZoneStore = (data?: StaticType) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: GeoZoneActionTypeEnum.UPDATE_GEOZONE })

export const addGeoZone = (data: StaticType) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: GeoZoneActionTypeEnum.ADD_GEOZONE })

export const geoZoneSearchTextChanged = (data: string) => (dispatch: AppDispatch) =>
  dispatch({ payload: data, type: GeoZoneActionTypeEnum.GEOZONE_SEARCH_TEXT_CHANGED })

export const setGeoZoneOpen = (id: unknown, isOpened: boolean) => (dispatch: AppDispatch) =>
  dispatch({ payload: { id, isOpened }, type: GeoZoneActionTypeEnum.SET_GEOZONE_OPEN })

export const setGeoZoneColoring = (id: unknown, data: GeoColoringData, type: string) => (dispatch: AppDispatch) =>
  dispatch({ payload: { data, id, type }, type: GeoZoneActionTypeEnum.SET_GEOZONE_COLORING })

export const setGeoZoneColoringLegendValues = (id: string | number, data: number[]) => (dispatch: AppDispatch) =>
  dispatch({ payload: { data, id }, type: GeoZoneActionTypeEnum.SET_GEOZONE_COLORING_LEGEND_VALUES })

export const deleteGeoZoneColoring = (id: unknown) => (dispatch: AppDispatch) =>
  dispatch({ payload: { id }, type: GeoZoneActionTypeEnum.DELETE_GEOZONE_COLORING })

export const geoZoneColoringLoad = (flag: boolean) => (dispatch: AppDispatch) =>
  dispatch({ payload: flag, type: GeoZoneActionTypeEnum.GEOZONE_COLORING_LOAD })

export const pushGeoZone = (list: StaticType) => (dispatch: AppDispatch) =>
  dispatch({ payload: list, type: GeoZoneActionTypeEnum.PUSH_GEOZONE })

export const pushGeoZoneGeoJson = (item: StaticType) => (dispatch: AppDispatch) =>
  dispatch({ payload: item, type: GeoZoneActionTypeEnum.PUSH_GEOZONE_GEOJSON })
