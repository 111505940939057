import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { RouteComponentProps } from 'react-router-dom'
import { TUnit, TUser } from 'types'
import { link } from '../../router'
import FilterControl from '../../ui/Filter/filterControl'
import { FilterResult } from '../../ui/Machines/result'
import { ConfigContext } from '../ValidateUser'
import { InitFilter } from './initFilterMachine'

type TProps = {
  clearFilter: () => void
  filter: TFilter[]
  filtration: boolean
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
  onFilteredType: unknown
  onStartFilter: (section: string, value: number) => void
  result: TUnit[]
  units: TUnit[]
  users: TUser[]
}

type TFilter = {
  id: number
  items: number[] | string[]
  labels?: string[]
  name: string
  notTranslating: boolean
  result: []
  section: string
  type: string
}

type TLocalState = {
  filter: TFilter[]
  height : number
  isOpenMenu: boolean
  scroll?: number | null
  scrollComponent: HTMLElement | null
  toFixed : boolean
  top?: string
}

@withTranslate
//@ts-expect-error
@InitFilter
class Machines extends React.Component<TProps, TLocalState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      filter         : props.filter,
      toFixed        : false,
      scroll         : null,
      height         : 0,
      scrollComponent: null,
      isOpenMenu     : false,
    }
  }

  filter: HTMLDivElement | null = null
  scrollElement: HTMLDivElement | null = null
  table: HTMLDivElement | null = null
  static contextType = ConfigContext

  componentWillUnmount() {
    this.scrollElement?.removeEventListener('scroll', this.handleScroll)
  }

  componentDidMount() {
    this.scrollElement?.addEventListener('scroll', this.handleScroll)

    this.setState({
      filter         : this.props.filter,
      scrollComponent: this.scrollElement,
    })

    //@ts-expect-error
    if(this.props.location?.query && this.props.filter.length) {
      this.getDefaultHouseholds(this.props)
      this.getDefaultActive(this.props)
      this.getDefaultNotification(this.props)
    }
  }

  //@ts-expect-error
  componentWillReceiveProps(nextProps) {
    if(nextProps.filter !== this.props.filter) {
      this.setState({
        filter: nextProps.filter,
      })

      this.getDefaultHouseholds(nextProps)
      this.getDefaultActive(nextProps)
      this.getDefaultNotification(nextProps)
    }

    if(nextProps.filtration && nextProps.isFiltered && nextProps.users !== this.props.users) {
      this.props.clearFilter()

      this.setState({
        filter: nextProps.filter,
      })

      this.getDefaultHouseholds(nextProps)
      this.getDefaultActive(nextProps)
      this.getDefaultNotification(nextProps)
    }
  }

  toggleMenu = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu })
  };

  getDefaultHouseholds = (props: TProps) => {
    const households = new URLSearchParams(props.location.search).get('households')

    if(households && props.filter[3] && props.filter[3].items.length > 1) {
      const searchHouseholds = props.users.find(user => user.id === Number(households))

      //@ts-expect-error
      if(searchHouseholds && !props.filter[3].result.includes(searchHouseholds.name)) {
        //@ts-expect-error
        this.props.onStartFilter('main', searchHouseholds.name)

        const account = this.context.farm.accounts.find((element: TUser) => element.name === searchHouseholds.name)

        if(account) {
          this.context.farm.setSelectedFarmId(account.id)
        }

        this.props.history.push(link.machines)
      }
    }
  };

  getDefaultNotification = (props: TProps) => {
    const notification = new URLSearchParams(props.location.search).get('notification')

    if(notification && props.filter[4] && props.filter[4].items.length >= 1) {
      //@ts-expect-error
      this.props.onStartFilter('notification', Number(props.location.query.notification))
    }
  };

  getDefaultActive(props: TProps) {
    const active = new URLSearchParams(props.location.search).get('active')

    if(active && props.filter[1] && props.filter[1].items.length > 1) {
      if(props.filter[1].items.find(item => item === Number(active))) {
        this.props.onStartFilter('status', Number(active))
      }
    }
  }

  handleScroll = () => {
    let top = this.table?.getBoundingClientRect().top
    let height = this.filter ? this.filter.offsetHeight : 0

    if(top && top < 0) {
      this.setState({
        toFixed: true,
        height : height,
        top    : `${-(top - height)}px`,
        scroll : top,
      })
    } else {
      this.setState({ toFixed: false, height: 0, top: 'inherit', scroll: top })
    }
  };

  getRef = (el: HTMLDivElement) => this.scrollElement = el;

  render() {
    const filterNumber = this.props.filtration ? this.props.result.length : this.props.units.length

    const showFilter = Boolean(this.props.filter.find(item => !isEmpty(item.items)))

    return (
      <div className="machines-wrap" ref={this.getRef}>
        <div className={`machine-filter ${this.state.toFixed ? ' active' : ''}`}>
          {showFilter &&
            <>
              <div className={`mobile-filter-icon${this.state.toFixed ? ' active' : ''}`} onClick={this.toggleMenu}/>

              <div
                className={`machine-filter__control${this.state.toFixed ? ' active' : ''} ${this.state.isOpenMenu ? 'mobile-open' : ''}`}
                ref={filter => this.filter = filter}
              >
                {/*@ts-expect-error*/}
                <FilterControl
                  {...this.props}
                />
              </div>
            </>}

          <div className="machine-filter__result" ref={table => {
            this.table = table
          }}>
            <FilterResult
              number={filterNumber}
              filtration={this.props.filtration}

              //@ts-expect-error
              result={this.props.result}

              //@ts-expect-error
              units={this.props.units}
              scroll={this.state.scroll}
              heightFilter={this.state.height}
              onFilteredType={this.props.onFilteredType}
              scrollComponent={this.state.scrollComponent}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default observer(Machines)
