import { Button, icons } from '@agdt/agrotronic-react-components'
import { prop } from 'ramda'
import React, { FC, useCallback } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { exportSheet } from '../common/exportSheet'

type DownloadExcelButtonProps = {
  resultFormat: {
    title: string
  }[]
  items: {
    model: string
    number: number
    uploads: string
  }[]
}

export const DownloadExcelButton: FC<DownloadExcelButtonProps> = ({ items, resultFormat }) => {
  const t = useTranslate()

  const exportToExcel = useCallback(() => {
    if(!items) { return }

    const columns = resultFormat.map(prop('title'))

    exportSheet(
      [columns].concat(items.map(item => {
        const { model, number, uploads } = item
        return [`${model} (${number})`, uploads]
      })),
      'report',
    )
  }, [items, resultFormat])

  return <StyledButton onClick={exportToExcel} variant="border">
    <>
      <icons.XlsIcon size='24' color='#32BE6E' />
      <TitleButton>{t('Download')}</TitleButton>
    </>
  </StyledButton>
}

const StyledButton = styled(Button)`
  height: 48px;
  padding: 10px 14px;
  width: 140px;
  flex-wrap: nowrap;
`

const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 700;
  padding-left: 4px;
`
