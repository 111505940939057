import React, { FC, useCallback, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { MultiselectWithSearch, Option } from 'ui/Filter/MultiselectFilter'
import { TOperator } from '../ResultTable/OperatorContent'

type TProps = {
  checkedOperatorsIds: unknown
  onHideList: () => void
  operators: TOperator[]
  setCheckedOperatorsIds: (record: Record<string, number[]>) => void
}

const Filters: FC<TProps> = ({ checkedOperatorsIds, onHideList, operators, setCheckedOperatorsIds }) => {
  const t = useTranslate()

  const setCheckedIds = useCallback<(name: string, record: Record<string, number[]>) => void>((_, operatorIds) => {
    setCheckedOperatorsIds(operatorIds)
  }, [])

  const operatorsPrepared = useMemo<Option[]>(() => {
    return [{
      categoryId: 1,
      items     : operators,
      name      : t('operators'),
    }]
  }, [operators, t])

  return <div className='filter-control'>
    <FiltersWrapper className='filter-control__parameters'>
      <div className='filter-control__items'>
        <MultiselectWithSearch
          checkedOptionsIds={checkedOperatorsIds}
          filterName='operators'
          label={t('operators')}
          options={operatorsPrepared}
          onHideList={onHideList}
          searchInputLabel={t('find operator')}
          setCheckedIds={setCheckedIds}
          width='350px'
          noGroups
        />
      </div>
    </FiltersWrapper>
  </div>
}

const FiltersWrapper = styled.div`
  padding-top: 32px;
  box-shadow: none !important;
  height: 60px !important;
`

export default Filters
