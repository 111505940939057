import {
  HeaderRow,
  SortableHeaderCell,
  Table as GenericTable,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@agdt/agrotronic-react-components'
import {ascend, descend, prop, sort, values} from 'ramda'
import React, {FC, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {ExportReportToExcel} from '../../Equipment/ResultTable/ExportReportToExcel'

export type ColumnSettings = {
  id: string
  title: string
  sort?: 'asc' | 'desc'
  formatter?: Function
  additionalFields?: string[]
}

export type Unit = {
  id: string
  model: string
  number: string
  details: {
    model: string
    number: number
    area: number
    modelName: string
    work: number
  }[]
} & Record<string, unknown>

type ReportTableProps = {
  isDetailsTable?: boolean
  columnSettings: ColumnSettings[]
  period?: {
    from: string
    to: string
  }
  reportName?: string
  setShownUnitDetails?: (value: Unit | null) => void
  units: object
}

export const ReportTable: FC<ReportTableProps> = ({
  columnSettings,
  isDetailsTable,
  period,
  reportName,
  setShownUnitDetails,
  units,
}) => {
  const [settings, setSettings] = useState(columnSettings)

  const toggleSort = useCallback((id, order) => {
    setSettings(settings.map(setting => ({ ...setting, sort: setting.id === id ? order : undefined })))
  }, [settings])

  const sortedUnits: Unit[] = useMemo(() => {
    const valuesUnits = values(units)
    const sortField = settings.find(element => element.sort)

    if(!sortField) {
      return valuesUnits
    }

    const { id: sortFieldId, sort: sortFieldDirection } = sortField
    const byProp = prop(sortFieldId)

    return sort(
      sortFieldDirection === 'asc' ? ascend(byProp) : descend(byProp),
      valuesUnits,
    )
  }, [settings, units])

  const clickHandler = (unit: Unit) => () => {
    if(!isDetailsTable && setShownUnitDetails) {
      setShownUnitDetails(unit)
    }
  }

  return (
    <StyledGenericTable>
      <StyledTableHeader>
        <StyledHeaderRow apperance={'large'} className={isDetailsTable ? 'details': ''}>
          {settings.map(column =>
            <StyledSortableHeaderCell
              id={column.id}
              key={column.id}
              onChangeSort={toggleSort}
              sort={column.sort}
            >
              {column.title}
            </StyledSortableHeaderCell>)}

          {!isDetailsTable && <ExcelButtonWrapper>
            <ExportReportToExcel items={sortedUnits} period={period} reportName={reportName} />
          </ExcelButtonWrapper>}
        </StyledHeaderRow>
      </StyledTableHeader>

      <TableBody>
        {sortedUnits.map(unit => <StyledTableRow className={isDetailsTable ? 'details' : ''}
          key={unit.id}
          onClick={clickHandler(unit)}
          apperance={'large'}
        >
          {settings.map(item =>
            <StyledTableCell key={item.id}>
              <UnitModel>{item.formatter ? item.formatter(unit[item.id]) : unit[item.id]}</UnitModel>

              <AdditionalTitle>
                {item.additionalFields && item.additionalFields.map(field =>
                  unit[field] && <UnitNumber key={field}>{unit[field]}</UnitNumber>,
                )}
              </AdditionalTitle>
            </StyledTableCell>)
          }
        </StyledTableRow>,
        )}
      </TableBody>
    </StyledGenericTable>
  )
}

const StyledTableRow = styled(TableRow)`
  &.details {
    min-height: 60px;
    border-left: 1px solid #e6e6e6;
  }
`

const ExcelButtonWrapper = styled(TableCell)`
  position: absolute;
  right: 24px;
`

const StyledGenericTable = styled(GenericTable)`
  grid: none;
`

const StyledTableHeader = styled(TableHeader)`
  background: #ffffff;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
`

const UnitModel = styled.div`
  position: relative;
  color: #000000;
  font-size: 13px;
  font-weight: 600;
`

const AdditionalTitle = styled.div`
  & span:first-child {
    color: #000000;
  }

  & span:only-child {
    color: #7d7d7d;
  }
`

const UnitNumber = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
  padding-right: 5px;
`

const StyledSortableHeaderCell = styled(SortableHeaderCell)`
  font-size: 14px;
  text-transform: uppercase;
  max-width: 46em;

  & span {
    color: #000000;
    font-size: 14px;
  }
`

const StyledTableCell = styled(TableCell)`
  max-width: 46em;
  flex-direction: column;
  align-items: start;
  padding-right: 10px;
`

const StyledHeaderRow = styled(HeaderRow)`
  width: 100%;
  position: relative;

  &.details {
    min-height: 60px;
    border-left: 1px solid #e6e6e6;
  }
`
