import React, { useCallback } from 'react'
import styled from 'styled-components'
import { TSVGIconProps } from 'types'
import PreviewWithState from './PreviewWithState'

type TProps = {
  className?: string
  count: number
  defaultSize: {}
  isChecked?: boolean
  isMultiple: boolean
  Preview: (arg: TSVGIconProps) => JSX.Element
  title: string
  toggleDrag: (arg: boolean) => void
  widgetId: string
}

export default ({
  className,
  count,
  defaultSize,
  isMultiple,
  Preview,
  title,
  toggleDrag,
  widgetId,
}: TProps) => {
  const dragStart = useCallback(e => {
    toggleDrag(true)
    e.dataTransfer.setData('text/plain', JSON.stringify({ id: widgetId, isMultiple, ...defaultSize }))
  }, [toggleDrag])

  const drop = useCallback(() => {
    toggleDrag(false)
  }, [toggleDrag])

  return (
    <PreviewElement

      className={className}
    >
      {isMultiple || count === 0 ? <Draggable
        className={'droppable-element'}
        draggable={true}
        unselectable="on"

        // Pass
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        onDragStart={dragStart}
        onDragEnd={drop}
      >
        <PreviewWithState Preview={Preview} count={count} isChecked={!isMultiple && Boolean(count)}/>
      </Draggable>

        : <PreviewWithState Preview={Preview} count={count} isChecked={!isMultiple && Boolean(count)}/>
      }

      <Caption>{title}</Caption>
    </PreviewElement>
  )
}

const PreviewElement = styled.div`
  margin: 0 15px;
`

const Caption = styled.div`
  max-width: fit-content;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  /* text Color #3 */
  color: #202020;
`

const Draggable = styled.div`
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
`
