const progressColor = {
  green : 'green',
  red   : 'red',
  shadow: 'shadow',
  yellow: 'yellow',
}

export const getFuelColor = (FUELProgress: number | undefined) => {
  if(FUELProgress === 0 || !FUELProgress) {
    return progressColor.shadow
  } else if(FUELProgress <= 20) {
    return progressColor.red
  } else if(FUELProgress <= 60 && FUELProgress > 20) {
    return progressColor.yellow
  } else {
    return progressColor.green
  }
}
