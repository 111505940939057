import React, { ChangeEvent, FC, useEffect, useState } from 'react'

type OptionProps = {
  item: string
}

export type Time = {
  hours: string
  minute: string
}

const Option: FC<OptionProps> = ({ item }) => {
  return (
    <option value={item}>
      {item}
    </option>
  )
}

type SelectProps = {
  value: string
  num: number
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

const Select: FC<SelectProps> = ({ value, num, onChange, disabled }) => {
  return (
    <select
      name="from"
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {Array.from({ length: num }, (_, key) => {
        const item = key < 10 ? `0${key}` : String(key)
        return <Option item={item} key={item} />
      })}
    </select>
  )
}

type TimePickerProps = {
  value?: Time
  range: 'from' | 'to'
  disabled?: boolean
  onChange: (range: 'from' | 'to', type: 'hours' | 'minute', value: string) => void
}

const TimePicker: FC<TimePickerProps> = ({ range, onChange, value, disabled }) => {
  const handleChange = (type: 'hours' | 'minute') =>
    ({ target: { value: handleValue } }: ChangeEvent<HTMLSelectElement>) => {
      onChange(range, type, handleValue)
    }

  return (
    <div className="filter-parameter__time-item">
      <Select
        num={24}
        onChange={handleChange('hours')}
        value={value?.hours || '00'}
        disabled={disabled}
      />
      <Select
        num={60}
        onChange={handleChange('minute')}
        value={value?.minute || '00'}
        disabled={disabled}
      />
    </div>
  )
}

type WrapTimePickerProps = {
  from: Time
  to: Time
  setTime: (arg: {
    from: Time
    to: Time
  }) => void
}

export const WrapTimePicker: FC<WrapTimePickerProps> = ({ from, to, setTime }) => {
  const [fromState, setFromState] = useState(from)
  const [toState, setToState] = useState(to)

  const changeDate = (range: 'from' | 'to', type: 'hours' | 'minute', data: string) => {
    if(range === 'from') {
      setFromState({
        ...fromState || {},
        [type]: data,
      })
    }

    if(range === 'to') {
      setToState({
        ...toState || {},
        [type]: data,
      })
    }
  }

  useEffect(()=>{
    setTime({
      from: fromState,
      to  : toState,
    })
  }, [toState, fromState])

  return (
    <div className="filter-parameter__time-range">
      <TimePicker
        range="from"
        onChange={changeDate}
        value={fromState}
      />
      <TimePicker
        range="to"
        onChange={changeDate}
        value={toState}
      />
    </div>
  )
}
