import { useCallback, useMemo, useState } from 'react'

function getFromLS(id: string) {
  let ls: {[key: string]: string} = {}

  if(global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('widgetsSettings') ?? '{}')
    } catch(e) {
      /*Ignore*/
    }
  }

  return ls[id]
}

function saveToLS(id: string, value: string) {
  if(global.localStorage) {
    const ls = JSON.parse(global.localStorage.getItem('widgetsSettings') ?? '{}')

    global.localStorage.setItem(
      'widgetsSettings',
      JSON.stringify({
        ...ls,
        [id]: value,
      }),
    )
  }
}

export default function useWidgetSettings(id: string) {
  const settingsFromLs = useMemo(() => getFromLS(id), [id])
  const [settings, setSettings] = useState(settingsFromLs)

  const save = useCallback(val => {
    saveToLS(id, val)
    setSettings(val)
  }, [id, setSettings])

  return { save, setSettings, settings }
}
