import { Avatar } from '@agdt/agrotronic-react-components'
import { isNil } from 'ramda'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { link } from 'router'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import TooltipD from 'ui/Tooltip'
import { TMainInfoData } from '../helpers'
import { CalendarIcon, EquipmentIcon, SpeedometerIcon } from '../icons'
import { TMachine } from '../models'
import { getFuelColor } from './helpers'
import Item from './Item'

const styles = {
  tooltips: {
    info: { bottom: 0, left: 318 },
  },
}

export type TDriver = {
  operatorFirstName?: string
  operatorPhotoSmall?: string
  operatorSurname?: string
  personNumber?: string
}

export type TEquipment = {
  modelName?: string
  width?: number
}

type TProps = {
  driver: TDriver | null
  equipment: TEquipment | null
  load: boolean
  machine: TMachine
  mainInfoData: TMainInfoData | null
  t: TTranslate
}

function valueOrDash<T>(value?: T) {
  return value || '-'
}

export const MainInfo = ({
  driver,
  equipment,
  load,
  machine,
  mainInfoData,
  t,
}: TProps) => {
  const color = useMemo(() => getFuelColor(mainInfoData?.fuel.progress), [mainInfoData?.fuel.progress])
  const householdUnit = getStore('householdUnits').getUnit(machine.imei)
  const status = mainInfoData?.status.value || 0 // пока статус неизвестен используется 0 (нет связи)

  return <DetailsWrapper>
    <TooltipD
      text={
        t('Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment')
      }
      style={styles.tooltips.info}
    />

    <DetailsDataWrapper>
      <Item
        title={t('fuel')}
        status={valueOrDash(mainInfoData?.fuel.value && `${mainInfoData?.fuel.value} ${mainInfoData?.fuel.measure}`)}
      >
        <div className="detail-main__data-fiel">
          <div
            className={`detail-main__data-fiel-progres ${color}`}
            style={{ height: `${mainInfoData?.fuel.progress.toFixed(1)}%` }}
          />
        </div>
      </Item>

      <Item
        title={t('status')}
        
        status={isNil(mainInfoData?.status.value)
          ? '-'
          : t(`M${mainInfoData?.status.value}`)
        }>
        <div className={`detail-main__data-work color-${status}`}/>
      </Item>

      <Item
        title={t('last_data')}
        status={valueOrDash(mainInfoData?.active.formattedValue)}
      >
        <CalendarIcon />
      </Item>

      <Item
        title={t('speed_at_now')}
        status={valueOrDash(mainInfoData?.speed.value)}
      >
        <SpeedometerIcon/>
      </Item>

      {machine.data.LATITUDE && machine.data.LONGITUDE && <Item
        load={load}
        title={t('watching')}
        status={householdUnit.isWatched ? t('is active') : t('not active')}
      >
        {!load
          ? <Link
            className={householdUnit.isWatched ? 'detail-main__data-watch active' : 'detail-main__data-watch'}
            onClick={() => householdUnit.setWatched()}
            to={`${link.households}?machine=${machine.name}`}
          />

          : <span className={householdUnit.isWatched ? 'detail-main__data-watch active' : 'detail-main__data-watch'}
          />
        }

        <TooltipD
          text={
            t('Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)')
          }

          style={{ top: '-8px' }}
        />
      </Item>}

      {equipment && <Item
        title={valueOrDash(equipment.modelName)}
        status={valueOrDash(equipment.width)}
      >
        <EquipmentIcon/>
      </Item>}

      {driver && <Item
        title={`${driver.operatorFirstName || ''} ${driver.operatorSurname || ''}`}
        status={valueOrDash(driver.personNumber)}
      >
        <Avatar
          size="28px"
          src={driver.operatorPhotoSmall && `data:image/png;base64, ${driver.operatorPhotoSmall}`}
        />
      </Item>}
    </DetailsDataWrapper>
  </DetailsWrapper>
}

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const DetailsDataWrapper = styled.div`
  flex: 1 1;
`
