import Donut from 'components/Donut'
import { keys, sum } from 'ramda'
import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { link } from 'router'
import { TTranslate } from 'types'
import { prepareDuration } from 'ui/Map/duration'
import { modesSchema } from 'utils'

export const C_PARAMS = [
  'TIME_NO_LINK',
  'TIME_MOVE_NO_HARVEST',
  'TIME_OFF',
  'TIME_STOP_DON',
  'TIME_HARVEST',
  'TIME_STOP_HARVEST',
  'TIME_STOP_HARVEST_FULL',
]

type TimeDonutProps = {
  t: TTranslate
  types: unknown
  date: string
  item: string
  normilizeReport: unknown
  numRow: number
}

const TimeDonutComponent: FC<TimeDonutProps> = ({ date, item, normilizeReport, numRow, t, types }) => {
  return (
    <>
      {

        // @ts-expect-error
        Object.keys(types.toJS()).map(model => {
          const params = C_PARAMS.reduce<Record<string, number>>((acc, param) => {
            acc[param] = Math.round(

              // @ts-expect-error
              sum(types.toJS()[model].map(id => normilizeReport[param].data.get(id))),
            )

            return acc
          }, {})

          const sumParameters = sum(C_PARAMS.map(param => params[param]))

          const data = keys(params).map(param => {
            const percents = params[param] / sumParameters * 100

            return {

              // @ts-expect-error
              color: modesSchema?.[param].color,

              // @ts-expect-error
              tooltip: `${t(modesSchema[param].name)},
                          ${prepareDuration(params[param])},
                          ≈${percents.toFixed(2)}%`,

              value: params[param],
            }
          })

          return <td
            key={model}

            // @ts-expect-error
            rowSpan={types.size <= 1 ? (item === 'KUK' || item === 'ZUK') && numRow > 1 ? numRow - 1 : numRow : 1}
          >
            <Link

              // @ts-expect-error
              to={`${link.watchTime}?machine=${types.toJS()[model].join()}&time=${date}&startFilter=true`}
            >
              <Donut
                width={80}
                height={80}
                tooltip
                data={data}
              />
            </Link>
          </td>
        })
      }
    </>
  )
}

export const TimeDonut: FC<TimeDonutProps> = memo(TimeDonutComponent)
