import { Input } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { TTranslate } from 'types'
import TooltipD from '../Tooltip'

const styles = {
  input: {
    border  : 'none',
    fontSize: '13px',
    outline : 'none',
    width   : '100%',
  },
  tooltip: {
    bottom: '0',
    right : '0',
  },
}

type Props = {
  result: unknown[]
  section: string
  translate: TTranslate
  onStartFilter: (section: string, item: string, solo?: boolean) => void
}

export const FilterText: FC<Props> = ({ section, result, onStartFilter, translate }) => {
  const [search, setSearch] = useState('')
  const [resultLength, setResultLength] = useState(result.length)

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value

    if(searchValue !== search) {
      setSearch(searchValue)
    }
  }

  useEffect(()=>{
    if(search) {
      onStartFilter(section, search, true)
    }
  }, [search])

  useEffect(()=>{
    if(!result.length && resultLength) {
      setSearch('')
    }

    setResultLength(result.length)
  }, [result.length])

  return (
    <div className="filter-parameter">
      <Input
        placeholder={translate('Serial number')}
        onChange={onSearch}
        value={search}
        style={styles.input}
      />
      <TooltipD text={translate('Search the machine by serial number')} style={styles.tooltip}/>
    </div>
  )
}
