import { action, computed, configure, makeObservable, observable } from 'mobx'
import { isNil, map, prop } from 'ramda'
import { TUser } from 'types'

configure({ enforceActions: 'observed' })

type TUserWithDisplayName = {
  displayName: string
} & TUser

const defaultOptionsBuilder = map((option: TUser) => ({
  ...option,
  displayName: `${option.name} (${option.id})`,
}))

class FormSelectStore {
  getOptionKey: (obj: {id: number}) => number
  onChangeValue: (value?: unknown) => void
  optionsBuilder: (option: TUser[]) => TUserWithDisplayName[]

  @observable initValue: unknown = null;
  @observable value: unknown = null;
  @observable options: TUserWithDisplayName[] = [];

  @computed get selectedOption() {
    const { options, value, getOptionKey } = this
    return options.find(option => getOptionKey(option) === value)
  }

  constructor({
    value = null,
    options = [],
    getOptionKey = prop('id'),
    onChangeValue = () => {},
    optionsBuilder = defaultOptionsBuilder,
  } = {}) {
    makeObservable(this)
    this.value = value
    this.optionsBuilder = optionsBuilder
    this.options = this.optionsBuilder(options)
    this.getOptionKey = getOptionKey
    this.onChangeValue = onChangeValue
  }

  @action.bound setValue(value: unknown) {
    this.value = value
    this.onChangeValue(value)
  }

  @action.bound setValueById(id: number) {
    if(isNil(id)){
      return this.setValue(null)
    }

    const option = this.options.find(O => id === this.getOptionKey(O))

    if(!option) {
      throw `setValueById can not found option with id = ${id}`
    }

    this.setValue(option)
  }

  @action.bound
  setOptions(options: TUser[]) {
    this.options = this.optionsBuilder(options)
  }

  @action.bound setOptionsRaw(options: TUserWithDisplayName[]) {
    this.options = options
  }

  @action.bound
  setInitValue(value: unknown) {
    this.initValue = value
    this.setValue(value)
  }

  @computed
  get newValue() {
    if(this.initValue === this.value){return undefined}

    return this.value
  }
}

export default FormSelectStore
