import { formats, Spinner } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import moment from 'moment'
import { isEmpty, keys } from 'ramda'
import React, { FC, useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushFuelReport,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import { TUnit } from 'types'
import FilterControl, { FilterControlConfig } from '../../../ui/Filter/filterControl'
import InitFilter from '../../../ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { exportSheet } from '../result/common/exportSheet'
import { columns } from './constants'
import { FilterResult as ResultTable } from './ResultTable'

const round2 = (value: number) => {
  return value && !Number.isNaN(value) && Number.isFinite(value) ? Math.round(value * 100) / 100 : '-'
}

const FuelReport: FC<FilterControlConfig> = props => {
  const report = useSelector((state: AppStateType) => state.reports.get('fuelReport'))

  //@ts-expect-error
  const detailLoad = report.get('detailLoad')

  //@ts-expect-error
  const resultLoad = report.get('resultLoad')

  const endLoadReport = detailLoad.size !== resultLoad.size

  //@ts-expect-error
  const onStopReport = report.get('onStopReport')

  //@ts-expect-error
  const fuelReport = report.get('fuelReport')?.toJS()

  //@ts-expect-error
  const error = report.get('error')

  const stateDispatch = useDispatch()
  const translate = useTranslate()

  const onFinishLoadReport = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  const exportToExcel = () => {
    if(!fuelReport) { return }

    exportSheet(
      [columns.map(column => translate(column.title))].concat(

        //@ts-expect-error
        ...fuelReport[0].map(I => I.map(rowData => columns.map(({ id }) => rowData[id]))),
      ),
      'fuel_report',
    )
  }

  const filterIsEmpty = isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items)

  useEffect(() => {
    if(resultLoad.size >= detailLoad.size){
      onFinishLoadReport(ReportType.FuelReport)
    }
  }, [resultLoad.size, detailLoad.size, onFinishLoadReport])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={translate('Select a different report or change the filtering conditions')}
        title={translate('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          translate={translate}
          disableButton={endLoadReport}
          {...props}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!onStopReport && endLoadReport &&
            <Spinner />
          }

          {!onStopReport && fuelReport?.[0] &&
              <Button
                type="primary"
                icon="download"
                style={{
                  marginBottom: '10px',
                  background  : '#78C800',
                  border      : '1px solid #78C800',
                }}
                onClick={exportToExcel}
              >
                {translate('download excel')}
              </Button>
          }
        </div>
      </WithNoItemsForReportPlaceholder>

      {error &&
        <p style={{ color: 'red', textAlign: 'center' }}>
          {error}
        </p>
      }

      {!onStopReport && fuelReport?.[0] &&
        <ResultTable report={fuelReport} />
      }
    </div>
  )
}

export default InitFilter({
  default: [
    {
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
      filter  : 'FUEL.l',
    },
    {
      id      : 1,
      type    : 'date',
      name    : 'select_a_date',
      section : 'time',
      result  : [],
      validate: true,
    },
  ],

  callback: data => dispatch => {
    data.callbackOk = (result: Record<string, TUnit[]>) => {
      const report = keys(result).sort().map(date => result[date].map(item => {
        const commonFields = {
          model: item.registers.model,
          name : item.registers.name,
          date : moment(formats.api.parseDateTimeFromApi(date)).format('DD.MM.YYYY'),
        }

        //@ts-expect-error
        if(!item.startFuel) {
          return {
            ...commonFields,
            ...columns.reduce<Record<string, string>>((acc, column) => {
              if(!['model', 'name', 'date'].includes(column.id)){
                acc[column.id] = '-'
              }

              return acc
            }, {}),
          }
        }

        const engineTimeMove = item.registers.registers.TIME_MOVE_NO_HARVEST.value
        const engineTimeComb = item.registers.registers.TIME_HARVEST.value
        const engineTimeStop = item.registers.registers.TIME_STOP_DON.value

        const engineTime = (engineTimeMove + engineTimeComb + engineTimeStop) / 3600000

        return {
          ...commonFields,

          //@ts-expect-error
          endFuel: round2(item.endFuel),

          //@ts-expect-error
          startFuel: round2(item.startFuel),

          //@ts-expect-error
          filling: round2(item.fillings.reduce((acc, F) => F.sign === 'FILING' ? acc + F.volume : acc, 0)),

          //@ts-expect-error
          drain         : item.fillings.filter(F => F.sign === 'DRAIN').length,
          summFuel      : round2(item.registers.registers.INC_SUMM_FUEL.value),
          summFuelMove  : round2(item.registers.registers.INC_SUMM_FUEL_MOVE.value),
          summFuelComb  : round2(item.registers.registers.INC_SUMM_FUEL_COMB.value),
          summFuelStand : round2(item.registers.registers.INC_SUMM_FUEL_STOP.value),
          fuelHour      : round2(item.registers.registers.INC_SUMM_FUEL.value / engineTime),
          engineTime    : round2(engineTime),
          engineTimeMove: round2(engineTimeMove / 3600000),
          engineTimeComb: round2(engineTimeComb / 3600000),
          engineTimeStop: round2(engineTimeStop / 3600000),
        }
      }))

      dispatch(pushReport({ data: report, type: ReportType.FuelReport, resultLoad: [] }))
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.FuelReport))
      dispatch(errorReport(ReportType.FuelReport))
    }

    dispatch(clearReport(ReportType.FuelReport))
    pushFuelReport(dispatch, data, ReportType.FuelReport)
  },

  clear: () => dispatch => dispatch(clearReport(ReportType.FuelReport)),

  //@ts-expect-error
})(FuelReport)
