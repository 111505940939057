export const colors = [
  '#F44336',
  '#9C28B0',
  '#673AB6',
  '#3F51B5',
  '#2196F3',
  '#04A9F4',
  '#00BCD4',
  '#049688',
  '#4CAF50',
  '#8BC24B',
  '#8BC24B',
  '#CEDC3A',
  '#FFEB3B',
  '#FFC00A',
  '#FF9800',
  '#FF5721',
  '#795448',
  '#795448',
  '#607D8A',
  '#606060',
  '#000000',
]
