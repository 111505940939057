export const TAB_IDS = {
  FIELD_OPERATOR: 'FIELD_OPERATOR',
  FIELD_SHT     : 'FIELD_SHT',
  OPERATOR_FIELD: 'OPERATOR_FIELD',
  OPERATOR_SHT  : 'OPERATOR_SHT',
  SHT_FIELD     : 'SHT_FIELD',
  SHT_OPERATOR  : 'SHT_OPERATOR',
}

export const LEVEL_TITLES = {
  [TAB_IDS.OPERATOR_SHT]: { 1: 'operator', 2: 'sht_abbr' },
  [TAB_IDS.SHT_OPERATOR]: { 1: 'sht_abbr', 2: 'operator' },
}

export const objectFields = [
  'employeePerformanceByField',
  'unitPerformanceId',
  'operationPerformance',
  'taskPerformance',
]
