import moment from 'moment'
import React, { FC, useState } from 'react'
import { ActiveRange, ColorType } from './Day'
import { ListDays } from './ListDays'

type MonthPikerProps = {
  format: string
  activeRange?: ActiveRange
  permissions?: number
  history?: number
  isSoloCalendar?: boolean
  date: moment.MomentInput
  oneActiveDay?: moment.MomentInput
  setRange: (value: moment.Moment) => void
  skipMonth?: moment.MomentInput
  type: 'left' | 'right'
  setActiveMonth: (type: 'left' | 'right', month: string) => void
  colors?: ColorType[]
}

export const MonthPiker: FC<MonthPikerProps> = ({
  isSoloCalendar = false,
  permissions,
  history,
  date,
  format,
  activeRange,
  oneActiveDay,
  setRange,
  skipMonth,
  type,
  setActiveMonth,
  colors,
}) => {
  const [month, setMonth] = useState<moment.MomentInput>(date)
  const [number, setNumber] = useState(0)

  const setNumberMonth = (direction: 'prev' | 'next') => () => {
    const monthDate = moment(month, format)

    const active = direction === 'next'
      ? monthDate.add(1, 'month').startOf('month').format(format)
      : monthDate.subtract(1, 'month').startOf('month').format(format)

    const diff = skipMonth && active === moment(skipMonth, format).startOf('month').format(format) ? 2 : 1
    const newNumber = direction === 'next' ? number + diff : number - diff
    const newMonth = moment(date, format).add(newNumber, 'month').format(format)

    setNumber(newNumber)
    setMonth(newMonth)
    setActiveMonth(type, newMonth)
  }

  return (
    <div className="Date-Month">
      <div className="Date-Month__control">
        <button
          className="Date-Month__button Date-Month__button--prev"
          onClick={setNumberMonth('prev')}
        >
          <i/>
        </button>
        {moment(month, format).format('MMMM-YYYY')}
        <button
          className="Date-Month__button Date-Month__button--next"
          onClick={setNumberMonth('next')}
        >
          <i/>
        </button>
      </div>
      <div>
        <div className="Date-Month__daysWeek">
          {[0,1,2,3,4,5,6].map(item =>
            <div key={item} className="Date-Month__dayWeek">{moment.weekdaysShort(true, item)}</div>,
          )}
        </div>
        <ListDays
          date={month}
          permissions={permissions}
          history={history}
          isSoloCalendar={isSoloCalendar}
          format={format}
          activeRange={activeRange}
          oneActiveDay={oneActiveDay}
          setRange={setRange}
          colors={colors}
        />
      </div>
    </div>
  )
}
