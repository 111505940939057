import { createSelector } from 'reselect'

export const geoZoneWithSearchTextSelector = createSelector(

  //@ts-expect-error
  state => state.geoZone.static,

  //@ts-expect-error
  state => state.geoZone.searchText,
  (geoZones, searchText) => {
    searchText = searchText && searchText.toLowerCase().trim()

    const filteredGeoZones = searchText && searchText.length > 0

      //@ts-expect-error
      ? geoZones.filter(geoZone =>
        geoZone.name.toLowerCase().includes(searchText),
      )
      : geoZones

    //@ts-expect-error
    const resultGeoZones = filteredGeoZones.map(item => {
      return {
        id            : item.id,
        name          : item.name,
        geoZoneType   : item.type,
        isDefault     : true,
        location      : item.location,
        isOpened      : item.isOpened,
        isWatching    : item.watch,
        coloringData  : item.coloringData,
        isMultiPolygon: item.type === 'MULTIPOLYGON' || item.type === 'MultiPolygon',
      }
    })

    return resultGeoZones
  },
)
