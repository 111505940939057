import { Spacer } from '@agdt/agrotronic-react-components'
import { Icon,Switch } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { switchHelp } from 'reducers/settings/actions'

/*@ts-expect-error*/
function HelpSwitch(props) {
  const t = useTranslate()

  return <>
    <Switch
      size="small"
      checkedChildren={<Icon type="check" />}
      unCheckedChildren={<Icon type="close" />}
      checked={props.help}
      onChange={props.switchHelp}
    />
    <Spacer size='8px' />
    {t('helpers')}
  </>
}

/*@ts-expect-error*/
const mapStateToProps = state => ({
  help: state.settings.help,
})

/*@ts-expect-error*/
const dispatchToProps = dispatch => ({
  switchHelp: () => dispatch(switchHelp()),
})

export default connect(mapStateToProps, dispatchToProps)(HelpSwitch)
