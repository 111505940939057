import { Button, formats, InputLegend, Spinner } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import DatePicker from './DatePicker'

const twoDigitMask = formats.numbers.onlyDigitsWithLength(2)

type TProps = {
  isColoringLoad: boolean
  handlerRequestMap: () => void
  t: TTranslate
}

export type TDate = {
  date: number
  time: {
    hours: number
    minute: number
  }
}

export const MapBuildSettings = observer(({ isColoringLoad, handlerRequestMap, t }: TProps) => {
  const geoZonesStore = getStore('geoZones')
  const mapBuildSettings = geoZonesStore.mapBuildSettings
 
  const applyDate = (date: TDate[]) => {
    const from = new Date(date[0].date).setHours(date[0].time.hours, date[0].time.minute)
    const to = new Date(date[1].date).setHours(date[1].time.hours, date[1].time.minute, 59, 999)

    mapBuildSettings.setPeriod({ from, to })
  }

  return <>
    <GeoZoneRow key="geozone-data-time">
      <DatePicker
        applyDate={applyDate}
        from={mapBuildSettings.period.from}
        t={t}
        to={mapBuildSettings.period.to}
      />

      <Col width="20%" key="geozone-data-spacer" />

      <Col width="140px" key="geozone-data-input">
        <InputLegend

          //@ts-expect-error
          disableClear
          label={t('Part width')}
          legend={t('m')}
          onChange={mapBuildSettings.widthPart.setValue}
          postProcessValue={twoDigitMask}
          value={mapBuildSettings.widthPart.value}
          width="140px"
        />
      </Col>
    </GeoZoneRow>

    <GeoZoneRow key="geozone-data-buttons">
      <Col width="45%" key="geozone-data-button-download">
        <Button
          disabled={
            !mapBuildSettings.period.from
            || !mapBuildSettings.period.to
            || !(mapBuildSettings.selectedMapType && geoZonesStore.haveColoringData)
          }

          //@ts-expect-error
          icon="download"
          variant="border"
          onClick={geoZonesStore.getSHPFile}
        >
          {geoZonesStore.isShapeLoading ? <Spinner /> : null}
          {t('Download map')}
        </Button>
      </Col>

      <Col width="10%" key="geozone-data-button-space" />

      <Col width="45%" key="geozone-data-button-create">
        <Button
          disabled={geoZonesStore.isShapeLoading || !mapBuildSettings.period.to || !mapBuildSettings.period.from}

          //@ts-expect-error
          icon="download"
          variant="primary"
          isLoading={isColoringLoad}

          onClick={mapBuildSettings.period.from && mapBuildSettings.period.to
            ? handlerRequestMap
            : undefined
          }
        >
          {t('Create map')}
        </Button>
      </Col>
    </GeoZoneRow>

    {geoZonesStore.haveNoData && <div className="tracking-machine__err">
      {t('No data for the this period')}
    </div>}
  </>
})

const Col = styled.div<{ width: string }>`
  width: ${({ width }) => width || '50%'};
`

const GeoZoneRow = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 8px;
  width: 100%;
`
