import { Button,Form, Input, Select } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { sendFeedback } from 'services/apiService/modules'
import { TTranslate, TUser } from 'types'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

function getNameBrowser() {
  var ua = navigator.userAgent; var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

  if(/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }

  if(M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)

    if(tem != null){return tem.slice(1).join(' ').replace('OPR', 'Opera')}
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  tem = ua.match(/version\/(\d+)/i)

  if(tem != null) {
    M.splice(1, 1, tem[1])
  }

  return M.join(' ')
}

const browser = getNameBrowser()

type TProps = {
  demoMode: boolean
  translate: TTranslate
  user: TUser
}

const ContactPage = ({
  demoMode,
  translate,
  user,
}: TProps) => <>
  <h1 className="contact-title">{translate('Contact form')}</h1>
  <div className="conatct-wrapper">
    {!demoMode

    /*@ts-expect-error*/
      ? <ContactForm translate={translate} user={user}/>
      : <p className="watch__demoText">Этот раздел не доступен в демо режиме</p>}
  </div>
</>

const ContactForm = Form.create({ name: 'contact' })((props: TProps & { form: WrappedFormUtils }) => {
  const [activeRequest, setActiveRequest] = useState(false)
  const [errorText, changeErrorText] = useState('')
  const { getFieldDecorator } = props.form

  const prefixSelector = getFieldDecorator('prefix', {
    initialValue: '+7',
  })(<>+7</>)

  /*@ts-expect-error*/
  const makeErrorText = errorList => errorList.reduce((result, item) => {
    return result + `${result ? ', ' + item.field + ': ' + item.message : item.field + ': ' + item.message}`
  }, '')


  const showErrorText = (text: string) => {
    changeErrorText(text)
    setTimeout(() => changeErrorText(''), 15000)
  }

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()

    props.form.validateFields((err, values) => {
      if(err === null) {
        setActiveRequest(true)

        sendFeedback({
          contactPhoneNumber: `${values.prefix}${values.phone}`,
          email             : values['e-mail'],
          message           : values.message,
          service           : values.service,
          subject           : values.reasons,

          /*@ts-expect-error*/
          timezoneOffsetMinutes: props.user.info.gmt * 60,

          /*@ts-expect-error*/
          userFarm: props.user.info.name ? props.user.info.name : '',

          /*@ts-expect-error*/
          userName           : props.user.info.login ? props.user.info.login : '',
          userOsVersion      : browser,
          vehicleSerialNumber: values.serial_number,
          vehicleType        : values.type_technics,
        }).then(() => {
          setActiveRequest(false)
          props.form.resetFields()
        }).catch(error => {
          setActiveRequest(false)

          if(error.violations && error.violations.length) {
            const text = makeErrorText(error.violations)
            showErrorText(text)
          }
        })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="contact-column">
        <Item label="E-mail" required={true}>
          {getFieldDecorator('e-mail', {
            rules: [
              {
                required: true,
                message : '',
              },
            ],
          })(<Input/>)}
        </Item>
        <Item label={props.translate('Contact phone')} required={true}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: '' }],
          })(<Input addonBefore={prefixSelector} type="number"/>)}
        </Item>
        <Item label={props.translate('Service')} required={true}>
          {getFieldDecorator('service', {
            rules: [{ required: true, message: '' }],
          })(
            <Select placeholder={props.translate('Service')}>
              <Option value={props.translate('Department of service vehicles')}>
                {props.translate('Department of service vehicles')}
              </Option>
              <Option value={props.translate('Spare Parts Sales Department')}>
                {props.translate('Spare Parts Sales Department')}
              </Option>
              <Option value={props.translate('Support Agrotronic')}>
                {props.translate('Support Agrotronic')}
              </Option>
            </Select>,
          )}
        </Item>
        <Item label={props.translate('Reasons contact')} required={true}>
          {getFieldDecorator('reasons', {
            rules: [{ required: true, message: '' }],
          })(<Input/>)}
        </Item>
        <Item label={props.translate('Serial number machine')}>
          {getFieldDecorator('serial_number')(<Input/>)}
        </Item>
        <Item label={props.translate('Type technics')}>
          {getFieldDecorator('type_technics')(
            <Select placeholder={props.translate('Type technics')}>
              <Option value={props.translate('Tractors')}>{props.translate('Tractors')}</Option>
              <Option value={props.translate('Combine Harvesters')}>
                {props.translate('Combine Harvesters')}
              </Option>
              <Option value={props.translate('Forage Harvesters')}>
                {props.translate('Forage Harvesters')}
              </Option>
              <Option value={props.translate('Sprinklers')}>{props.translate('Sprinklers')}</Option>
              <Option value={props.translate('Mower')}>{props.translate('Mower')}</Option>
            </Select>,
          )}
        </Item>
      </div>
      <Item label={props.translate('Message')} required={true}>
        {getFieldDecorator('message', {
          rules: [{ required: true, message: '' }],
        })(<TextArea/>)}
      </Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={activeRequest}>
          {props.translate('submit')}
        </Button>
        {errorText ? <p className="contact-error">{errorText}</p> : null}
      </Form.Item>
    </Form>
  )
})

export default connect(state => ({

  /*@ts-expect-error*/
  user: state.user,

  /*@ts-expect-error*/
  demoMode: state.user.demoMode,
}))(withTranslate(ContactPage))
