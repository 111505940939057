import { observer } from 'mobx-react'
import { append, without } from 'ramda'
import React from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { getColoringDataWithFilteredValues } from '../helpers'
import MapLegend from './MapLegend'
import { Productivity } from './Productivity'
import UnitsSelector from './UnitsSelector'

type TProps = {
  id: string
  setGeoZoneColoring: (id: string, data: unknown, type: string | null) => void
  t: TTranslate
}

const MapDisplaySettingsFC = ({
  id,
  setGeoZoneColoring,
  t,
}: TProps) => {
  const geoZonesStore = getStore('geoZones')
  const mapBuildSettings = geoZonesStore.mapBuildSettings
  const mapDisplaySettings = geoZonesStore.mapDisplaySettings

  const handleSetSelectedUnit = (unitId: number) => {
    const idString = String(unitId)

    const Ids = mapDisplaySettings.selectedUnitsIds.includes(idString)
      ? without([idString], mapDisplaySettings.selectedUnitsIds)
      : append(idString, mapDisplaySettings.selectedUnitsIds)

    mapDisplaySettings.setSelectedUnitsIds(Ids)

    const coloringDataWithFilteredValues = getColoringDataWithFilteredValues(mapDisplaySettings.rawData, Ids)

    setGeoZoneColoring(
      id,

      //@ts-expect-error
      { ...mapDisplaySettings.rawData, features: coloringDataWithFilteredValues },
      mapBuildSettings.selectedMapType,
    )
  }

  return <Wrapper>
    <UnitsSelector
      handleSetSelectedUnit={handleSetSelectedUnit}
      selectedUnitsIds={mapDisplaySettings.selectedUnitsIds}
      translate={t}
    />

    {mapBuildSettings.selectedMapType === 'CURR_PRODUCTIVITY_ZUK' && <Productivity
      t={t}
    />}

    <MapLegend
      id={id}
      translate={t}
      typeCreateMap={mapBuildSettings.selectedMapType}
    />
  </Wrapper>
}

export const MapDisplaySettings = observer(MapDisplaySettingsFC)

const Wrapper = styled.div`
  padding-top: 13px;
`
