import React from 'react'
import { TSVGIconProps } from 'types'

type TProps = {
  dropped?: boolean
} & TSVGIconProps

const DropHereIcon = ({ className, dropped = false }: TProps) => {
  return (
    <svg width="226" height="226" viewBox="0 0 226 226" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      {dropped && <circle key={'1'} opacity="0.3" cx="113" cy="113" r="110" fill="black" stroke="white" strokeWidth="6" strokeDasharray="15 15"/>}
      {dropped && <path key={'2'} opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M110 116V170H116V116H170V110H116V56H110V110H56V116H110Z" fill="white"/>}
      {!dropped && <circle key={'3'} cx="113" cy="113" r="113" fill="white" />}
      {!dropped && <path key={'4'} opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M86.9999 145.757L165.879 66.8785L170.121 71.1212L89.1212 152.121L86.9999 154.243L84.8786 152.121L49.8787 117.121L54.1213 112.879L86.9999 145.757Z" fill="#606060" />}
    </svg>
  )
}

export default DropHereIcon
