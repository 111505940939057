import { Button, hooks } from '@agdt/agrotronic-react-components'
import React, { useCallback, useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { DayPickerRangeControllerWrapper } from 'ui/Pickers/rangeDate'
import TooltipD from 'ui/Tooltip'
import { TDate } from '../'

type TProps = {
  applyDate: (date: TDate[]) => void
  from: number | null
  t: TTranslate
  to: number | null
}

export default function DatePicker({ applyDate, from, t, to }: TProps){
  const ref = useRef(null)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handleClose = useCallback(() => {
    setShowDatePicker(false)
  }, [])

  const handleOpen = useCallback(() => {
    setShowDatePicker(true)
  }, [])

  const handleApplyDate = useCallback(date => {
    applyDate(date)
    setShowDatePicker(false)
  }, [])

  hooks.useClickOutside(ref, handleClose)

  return (
    <Col ref={ref} key="geozone-data-time-picker">
      <TooltipD text={t('Time period selection')}
        style={{ right: '25px', top: '4px' }} />

      <Button variant="text" onClick={handleOpen}>
        {from && to
          ? `${new Date(from).format('DD.MM.YY')} - ${new Date(to).format('DD.MM.YY')}`
          : t('select_a_date')
        }
      </Button>

      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {showDatePicker && <div className="filter-parameter__open date">
          <DayPickerRangeControllerWrapper
            applyDate={handleApplyDate}
          />
        </div>}
      </ReactCSSTransitionGroup>
    </Col>
  )
}

const Col = styled.div`
  width: 50%;
`
