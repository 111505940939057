import { helpers } from '@agdt/agrotronic-react-components'
import moment from 'moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import { CALENDAR_COLORS, getCalendarPaintRegs, getCalendarPaintWithData, RegisterType } from '../../queries/calendar'
import { RangePicker } from '../RangePicker'
import { Time, WrapTimePicker } from './timePicker'

const { debounce } = helpers
const format = 'dddd, D MMMM YYYY'

export type DateOption = {
  date: moment.MomentInput
  time: Time
}

type DayPickerRangeColorsWrapperProps = {
  permissions?: number
  history?: number
  imei: string | number
  initDate?: {
    from: moment.MomentInput
    to: moment.MomentInput
  }
  applyDate: (args: DateOption[]) => void
}

type ColorType = {
  month: string
  year: string
  day: string
  color: string
  value: unknown
}

export const DayPickerRangeColorsWrapper: FC<DayPickerRangeColorsWrapperProps> = ({
  permissions,
  history,
  imei,
  initDate,
  applyDate,
}) => {
  const isMounted = useRef(true)
  const translate = useTranslate()
  const gmt = useSelector((state: AppStateType) => state.user.info.gmt) || 0
  const inputFrom = useRef<HTMLInputElement>(null)
  const inputTo = useRef<HTMLInputElement>(null)
  const [activeType, setActiveType] = useState<number | string>('')
  const [colors, setColors] = useState<Record<string|number, ColorType[]>>({})
  const [regs, setRegs] = useState<RegisterType[] | null>(null)
  const activeReg = regs?.find(item => item.type === activeType)

  const [switchMonth, setSwitchMonth] =useState({
    left: moment()
      .subtract(1, 'months')
      .format('DD.MM.YY'),
    right: moment()
      .format('DD.MM.YY'),
  })

  const [fromState, setFromState] = useState<DateOption>({
    date: moment(),
    time: {
      hours : '00',
      minute: '00',
    },
  })

  const [toState, setToState] = useState<DateOption>({
    date: null,
    time: {
      hours : '23',
      minute: '59',
    },
  })

  const setTime = (time: { from: Time, to: Time }) => {
    setFromState(prevValue => ({
      ...prevValue,
      time: time.from,
    }))

    setToState(prevValue => ({
      ...prevValue,
      time: time.to,
    }))
  }

  const updateColors = (param: string|number, color: ColorType[]) => {
    if(isMounted.current) {
      setColors(prevValue => {
        if(prevValue[param]) {
          return color.reduce((acc: Record<string|number, ColorType[]>, colorItem: ColorType) => {
            const findColor = acc[param].find(
              item =>
                item.day === colorItem.day &&
                item.year === colorItem.year &&
                item.month === colorItem.month,
            )

            if(findColor) {
              findColor.color = colorItem.color
              findColor.value = colorItem.value
            } else {
              acc[param].push(colorItem)
            }

            return acc
          }, { ...prevValue })
        }

        return {
          ...prevValue,
          [param]: color,
        }
      })
    }
  }

  const updateSwitchMonth = debounce((type: 'left' | 'right', date: string) => {
    setSwitchMonth(prevValue => ({
      ...prevValue,
      [type]: date,
    }))

    if(activeType &&
      +moment(date, 'DD.MM.YY') <= +moment() &&
      !colors?.[activeType]?.every(({ month, year }) =>
        month === moment(date, 'DD.MM.YY').format('M') &&
        year === moment(date, 'DD.MM.YY').format('YYYY'),
      )
    ) {
      getCalendarPaintWithData(
        imei,
        {
          GMT     : gmt,
          levels  : regs?.find(item => item.type === activeType)?.levels || [],
          month   : moment(date, 'DD.MM.YY').format('M'),
          register: activeType,
          year    : moment(date, 'DD.MM.YY').format('YYYY'),
        },
        (param: string|number, value: ColorType[]) => updateColors(param, value),
      )
    }
  }, 1000)

  const switchDays = (data: {from: moment.MomentInput, to: moment.MomentInput}) => {
    setFromState(prevValue => ({
      ...prevValue,
      date: data.from,
    }))

    const newDateTo = data.to ? data.to : data.from

    setToState(prevValue => ({
      ...prevValue,
      date: newDateTo,
    }))

    if(inputFrom.current) {
      inputFrom.current.value = data.from ? moment(data.from).format(format) : ''
    }

    if(inputTo.current) {
      inputTo.current.value = newDateTo ? moment(newDateTo).format(format) : ''
    }
  }

  const handleChange = ({ target: { value: newActiveType } }: React.ChangeEvent<HTMLSelectElement>) => {
    if(newActiveType !== activeType) {
      setActiveType(newActiveType)

      const { left, right } = switchMonth

      if(moment(left, 'DD.MM.YY') <= moment()) {
        getCalendarPaintWithData(
          imei,
          {
            GMT     : gmt,
            levels  : regs?.find(item => item.type === newActiveType)?.levels || [],
            month   : moment(left, 'DD.MM.YY').format('M'),
            register: newActiveType,
            year    : moment(left, 'DD.MM.YY').format('YYYY'),
          },
          (param: string|number, value: ColorType[]) => updateColors(param, value),
        )
      }

      if(moment(right, 'DD.MM.YY') <= moment()) {
        getCalendarPaintWithData(
          imei,
          {
            GMT     : gmt,
            levels  : regs?.find(item => item.type === newActiveType)?.levels || [],
            month   : moment(right, 'DD.MM.YY').format('M'),
            register: newActiveType,
            year    : moment(right, 'DD.MM.YY').format('YYYY'),
          },
          (param: string|number, value: ColorType[]) => updateColors(param, value),
        )
      }
    }
  }

  const onApplyDate = () => {
    applyDate([
      fromState,
      toState,
    ])
  }

  useEffect(()=>{
    if(imei) {
      getCalendarPaintRegs(imei, (info: RegisterType[]) => {
        if(info.length && isMounted.current) {
          const newRegs = info
          const newActiveType = info[0].type
          setRegs(newRegs)
          setActiveType(newActiveType)

          const fromDate = moment(fromState.date)

          Promise.allSettled([
            getCalendarPaintWithData(
              imei,
              {
                GMT     : gmt,
                levels  : newRegs?.find(item => item.type === newActiveType)?.levels || [],
                month   : fromDate.format('M'),
                register: newActiveType,
                year    : fromDate.format('YYYY'),
              },
            ),
            getCalendarPaintWithData(
              imei,
              {
                GMT     : gmt,
                levels  : newRegs?.find(item => item.type === newActiveType)?.levels || [],
                month   : fromDate.subtract(1, 'months').format('M'),
                register: newActiveType,
                year    : fromDate.subtract(1, 'months').format('YYYY'),
              },
            ),
          ]).then(
            results => {
              const result: ColorType[] = []

              results.forEach(item => {
                if(item.status === 'fulfilled') {
                  result.push(...item.value)
                }
              })

              updateColors(newActiveType, result)
            },
          )
        }
      })
    }

    if(initDate) {
      setFromState(prevValue => ({
        ...prevValue,
        date: moment(initDate.from, 'YYMMDDHHmmss000'),
      }))

      setToState(prevValue => ({
        ...prevValue,
        date: moment(initDate.to, 'YYMMDDHHmmss000'),
      }))
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <div>
      <div className="filter-parameter__inputs">
        <input
          type="text"
          defaultValue={moment(fromState.date).format(format)}
          ref={inputFrom}
          placeholder={translate('from')}
        />
        <input
          type="text"
          defaultValue={moment(toState.date || moment()).format(format)}
          ref={inputTo}
          placeholder={translate('to')}
        />
      </div>
      <WrapTimePicker
        from={fromState.time}
        to={toState.time}
        setTime={setTime}
      />
      <RangePicker
        colors={colors?.[activeType] || []}
        range={
          fromState.date && toState.date ? {
            from: fromState.date,
            to  : toState.date,
          } : undefined
        }
        switchMonth={updateSwitchMonth}
        switchDays={switchDays}
        permissions={permissions}
        history={history}
      />
      {activeReg &&
        <div className="Date-Month__info">
          <div className="Date-Month__item">
            <div
              className="Date-Month__color"
              style={{ background: '#cccccc' }}
            />
            <p className="Date-Month__name">{translate('not_of_data')}</p>
          </div>
          {activeReg.levels.map((item, index) =>
            <div className="Date-Month__item" key={index}>
              <div
                className="Date-Month__color"
                style={{ background: CALENDAR_COLORS[index] }}
              />
              <p className="Date-Month__name">{`<${item}`}</p>
            </div>,
          )}
          <div className="Date-Month__item">
            <div
              className="Date-Month__color"
              style={{ background: CALENDAR_COLORS[4] }}
            />
            <p className="Date-Month__name">{`>${activeReg.levels[3]}`}</p>
          </div>
        </div>
      }

      <div className="Date-Month__set">
        <div className="Date-Month__regs">
          {regs &&
            <StyledSelect
              name="regs"
              value={activeType}
              onChange={handleChange}
            >
              {regs.map(item =>
                <option key={item.type} value={item.type}>
                  {item.name}{item.measure ? `, ${item.measure}` : null}
                </option>,
              )}
            </StyledSelect>
          }
        </div>

        <div className="filter-parameter__buttons">
          {fromState.date && toState.date ?
            <button onClick={onApplyDate}>
              {translate('accept')}
            </button> :
            <button style={{ backgroundColor: '#e3e3e3' }}>{translate('accept')}</button>}
        </div>
      </div>
    </div>
  )
}

const StyledSelect = styled.select`
  display: block;
  height: 28px;
  width: 298px;
  border-radius: 3px;
  font-size: 12px;
  padding: 0 4px;
  border: 1px solid #d0cfcf;
`
