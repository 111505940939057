import { hooks } from '@agdt/agrotronic-react-components'
import CSSTransitionWrapper from 'components/CSSTransitionWrapper'
import { MAP_CENTER, MAP_MAX_BOUNDS, MAP_TYPES } from 'constants/map'
import L from 'leaflet'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Map } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import { useClickOutside } from 'services/hooks'
import { TGeoZone } from 'types'
import { getStore } from '../../stores/storesRegistry'
import TooltipD from '../Tooltip'
import { EquipmentTrack } from './EquipmentTrack'
import { CreateGeoZone } from './GeoZone/CreateGeoZone'
import { EditGeoZone } from './GeoZone/EditGeoZone'
import { GeoZoneControl } from './GeoZone/GeoZoneControl'
import { RenderGeoZone } from './GeoZone/RenderGeoZone'
import { useMapControls } from './hooks/useMapControls'
import LoadShapeFile from './LoadShapeFile'
import { MapLayer } from './MapLayer'
import { StyleMap } from './styleMap'
import { CONTROL_NAMES } from './utils/constants'
import { calculatePolygonArea } from './utils/helpers'
import { tooltips } from './utils/tooltipsShema'
import { weatherLayerIsShowed } from './WeaterMap/utils'

//https://agdt.evateam.ru/desk/Task/AT-3066#vypilit-servis-pogody-iz-vseh-mest-ispolzovaniya-v-web-prilozhenii
//import { WeatherMapControls } from './WeaterMap/WeatherMapControls'
import WeatherMapLayer from './WeaterMap/WeatherMapLayer'
import { WeatherMapLegend } from './WeaterMap/WeatherMapLegend'
import WeatherMapTimeline from './WeaterMap/WeatherMapTimeline'

export let mapRef: L.Map | null = null

type MapComponentProps = {
  editingGeoZone?: TGeoZone
  setMapRef: (value: Map) => void
}

type GeoZoneType = {
  points: [number, number][]
  id: number
  type: string
  validate?: boolean
}

export type PolygonType = {
  leafletElement: {
    setLatLngs: (value: [number, number][]) => void
  }
}

export const MapComponent: FC<MapComponentProps> = ({ editingGeoZone, setMapRef }) => {
  const t = useTranslate()
  const controlRef = useRef<HTMLDivElement>(null)
  const currentMapRef = useRef<Map>(null)
  const [activeWeatherLayerCode, setActiveWeatherLayerCode] = useState('not')
  const [activePopupControl, setActivePopupControl] = useState(null)
  const [areaPolygon, setAreaPolygon] = useState<number | null>(null)
  const [currentPolygon, setCurrentPolygon] = useState<PolygonType | null>(null)
  const [isDeleteModeActive, setIsDeleteModeActive] = useState(false)
  const [geoZoneType, setGeoZoneType] = useState<string | null>(null)
  const [idElement, setIdElement] = useState(0)
  const [idZone, setIdZone] = useState<number | null>(null)
  const [lastPoint, setLastPoint] = useState<[number, number] | null>(null)
  const [nameNewGeoZone, setNameNewGeoZone] = useState('')
  const [newGeoZone, setNewGeoZone] = useState<GeoZoneType[]>([])
  const centerMapPosition = MAP_CENTER
  const [mapType, setMapType] = useState(MAP_TYPES.GOOGLE_1)
  const [typeSave, setTypeSave] = useState<string | null>(null)
  const [weatherMapDateTime, setWeatherMapDateTime] = useState<number | null>(null)
  const locale = hooks.getLang()
  const hintIsOn = useSelector((state: AppStateType) => state.settings.help)
  const geoZones = useSelector((state: AppStateType) => state.geoZone.static)
  const toggleTrackArrowHandler = getStore('householdUnits').toggleTrackArrow

  useClickOutside(controlRef, () => setActivePopupControl(null))

  const newCoordinateGeoZone = useCallback(point => {
    const newPoints = [point, ...newGeoZone[0].points]

    setNewGeoZone([{
      ...newGeoZone[0],
      points: newPoints,
    }])

    setLastPoint(point)

    if(newPoints.length > 2) {
      setAreaPolygon(calculatePolygonArea(currentPolygon?.leafletElement))
    }
  }, [currentPolygon, newGeoZone])

  const clickMap = useCallback(e => {
    if(geoZoneType && !isDeleteModeActive) {
      return newCoordinateGeoZone([e.latlng.lat, e.latlng.lng])
    }
  }, [isDeleteModeActive, geoZoneType, newCoordinateGeoZone])

  const closeGeoZoneConstructor = useCallback(() => {
    setTypeSave(null)
    setNewGeoZone([])
    setActivePopupControl(null)
    setGeoZoneType(null)
    setIsDeleteModeActive(false)
    setAreaPolygon(null)
    setLastPoint(null)
    setNameNewGeoZone('')
  }, [])

  const closePopupControl = useCallback(() => setActivePopupControl(null), [])

  const createNewGeoZone = useCallback(() => {
    setNewGeoZone([{
      id    : idElement + 1,
      points: [],
      type  : 'Polygon',
    }])

    setIdElement(idElement + 1)
    setGeoZoneType('Polygon')
    setActivePopupControl(null)
  }, [idElement])

  const deletePoint = useCallback((point: {latlng: {lat: number, lng: number}}) => {
    const newPoints = newGeoZone[0].points.filter(
      item => item[0] !== point.latlng.lat && item[1] !== point.latlng.lng,
    )

    setNewGeoZone([{
      ...newGeoZone[0],
      points: newPoints,
    }])

    setAreaPolygon(newPoints.length > 2 ? calculatePolygonArea(currentPolygon?.leafletElement) : 0)

    if(!newPoints.length) {
      setIsDeleteModeActive(false)
    }
  }, [currentPolygon, newGeoZone])

  const newPointPosition = useCallback((e, idx) => {
    const newPoints = [...newGeoZone[0].points]

    newPoints.splice(idx, 1, [e.latlng.lat, e.latlng.lng])

    setNewGeoZone([{
      ...newGeoZone[0],
      points: newPoints,
    }])

    setLastPoint([e.latlng.lat, e.latlng.lng])
    setAreaPolygon(calculatePolygonArea(currentPolygon?.leafletElement))
    currentPolygon?.leafletElement.setLatLngs(newPoints)
  }, [currentPolygon, newGeoZone])

  const switchMapType = useCallback(type => {
    setMapType(type)

    if(currentMapRef.current) {
      currentMapRef.current.leafletElement.options.crs = L.CRS.EPSG3857

      //@ts-expect-error
      currentMapRef.current.leafletElement._resetView(
        currentMapRef.current.leafletElement.getCenter(),
        currentMapRef.current.leafletElement.getZoom(),
        true,
      )
    }
  }, [])

  const toggleDeleteMode = useCallback(() => {
    setIsDeleteModeActive(!isDeleteModeActive)
  }, [isDeleteModeActive])

  const togglePopupControl = useCallback(name =>
    setActivePopupControl(name === activePopupControl ? null : name), [activePopupControl],
  )

  useMapControls(currentMapRef)

  useEffect(() => {
    if(currentMapRef.current) {
      mapRef = currentMapRef.current.leafletElement
      setMapRef(currentMapRef.current)
    }
  }, [])

  // при добавлении новой геозоны обнуляем стейт создания геозон
  useEffect(() => {
    setNewGeoZone([])
    setGeoZoneType(null)
    setNameNewGeoZone('')
    setLastPoint(null)
    setAreaPolygon(null)
  }, [geoZones])

  //когда приходит геозона для редактирования - записываем её в локальный стейт
  useEffect(() => {
    if(!newGeoZone.length && editingGeoZone?.data) {
      const points = Array.isArray(editingGeoZone.data[0])
        ? editingGeoZone.data.map(point => [point[1], point[0]])
        : []

      setNewGeoZone([{
        id    : editingGeoZone.idZone,
        points: points as [number, number][],
        type  : 'Polygon',
      }])

      setGeoZoneType('Polygon')
      setNameNewGeoZone(editingGeoZone.name)
      setTypeSave(editingGeoZone.typeSave)
      setIdZone(editingGeoZone.idZone)

      if(currentMapRef.current) {
        currentMapRef.current.leafletElement.flyToBounds(L.latLngBounds(points as [number, number][]))
      }
    }
  }, [editingGeoZone])

  useEffect(() => {
    if(currentPolygon) {
      setAreaPolygon(calculatePolygonArea(currentPolygon.leafletElement))
    }
  }, [currentPolygon])

  return <div id="map" className="map-wrap">
    <Map
      center={centerMapPosition}
      fadeAnimation={false}
      minZoom={2}
      maxBounds={MAP_MAX_BOUNDS}

      //@ts-expect-error
      onClick={clickMap}
      ref={currentMapRef}
      zoom={10}
      zoomAnimation={false}
      zoomControl={false} // Hide default zoomControl buttons
    >
      <MapLayer
        mapType={mapType}
        zIndex={0}
        lang={locale}
      />

      {weatherLayerIsShowed(activeWeatherLayerCode) && <WeatherMapLayer

        //@ts-expect-error
        dateTime={weatherMapDateTime}
        layerCode={activeWeatherLayerCode}
      />}

      {hintIsOn && tooltips.map(({ style, text }) =>
        <TooltipD
          key={text}
          text={t(text)}
          style={style}
        />,
      )}

      <EditGeoZone
        deletePoint={deletePoint}
        isDeleteModeActive={isDeleteModeActive}
        newGeoZone={newGeoZone}
        newPointPosition={newPointPosition}
        setCurrentPolygon={setCurrentPolygon}
      />

      <RenderGeoZone />

      {currentMapRef.current && <EquipmentTrack currentMap={currentMapRef.current} />}
    </Map>

    <div className="map__topright" ref={controlRef}>
      <div className="geoZone-controll">
        <button
          className="geoZone-controll__button"
          onClick={() => togglePopupControl(CONTROL_NAMES.GEO_ZONE)}
        />
        <CSSTransitionWrapper isShow={activePopupControl === CONTROL_NAMES.GEO_ZONE}>
          <GeoZoneControl
            createNewGeoZone={createNewGeoZone}
            closePopupControl={closePopupControl}
          />
        </CSSTransitionWrapper>

        {geoZoneType && <CreateGeoZone
          areaPolygon={areaPolygon}
          closeGeoZoneConstructor={closeGeoZoneConstructor}
          isDeleteModeActive={isDeleteModeActive}
          idZone={idZone}
          lastPoint={lastPoint}
          nameNewGeoZone={nameNewGeoZone}
          newGeoZone={newGeoZone}
          setNameNewGeoZone={setNameNewGeoZone}
          toggleDeleteMode={toggleDeleteMode}
          typeSave={Boolean(typeSave)}
          currentMapRef={currentMapRef}
        />}
      </div>

      <LoadShapeFile />

      <div className="map-control-2">
        <button
          className="map-control-2__button"
          onClick={toggleTrackArrowHandler}
        />
      </div>

      <div className="map-type">
        <button
          className="map-type__button"
          onClick={() => togglePopupControl(CONTROL_NAMES.TYPE_MAP)}
        />

        <CSSTransitionWrapper isShow={activePopupControl === CONTROL_NAMES.TYPE_MAP}>
          <StyleMap
            type={mapType}
            switchType={switchMapType}
            translate={t}
          />
        </CSSTransitionWrapper>
      </div>

      {/*https://agdt.evateam.ru/desk/Task/AT-3066#vypilit-servis-pogody-iz-vseh-mest-ispolzovaniya-v-web-prilozhenii*/}
      {/*<WeatherMapControls*/}
      {/*  currentWeatherLayer={activeWeatherLayerCode}*/}
      {/*  setWeatherLayer={setActiveWeatherLayerCode}*/}
      {/*  togglePopupControl={togglePopupControl}*/}
      {/*  weatherControlsIsOpen={activePopupControl === CONTROL_NAMES.WEATHER}*/}
      {/*/>*/}
    </div>

    {weatherLayerIsShowed(activeWeatherLayerCode) && <>
      <WeatherMapLegend currentWeatherLayer={activeWeatherLayerCode}/>
      <WeatherMapTimeline currentWeatherLayer={activeWeatherLayerCode} setWeatherMapDateTime={setWeatherMapDateTime} />
    </>}
  </div>
}
