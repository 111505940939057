import { isEmpty, path } from 'ramda'
import React, { FC, useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { validateUserPersonalData } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import { ValidateInput } from '../common/ValidateInput'
import { loginValidators, validate } from '../common/validators'

type Props = {
  fieldName: string
  dataIsSave?: boolean
  disableClear?: boolean
  disabled: boolean
  required?: boolean
  testid: string
  label: string
  initValue?: string
  handleSave: (field: string, value: unknown) => void
}

export const LoginInput: FC<Props> = ({
  dataIsSave,
  disableClear,
  disabled,
  fieldName,
  handleSave,
  initValue,
  label,
  required,
  testid,
}) => {
  const [value, setValue] = useState<string | boolean | undefined>(initValue)
  const [validateError, setValidateError] = useState<string>('')
  const [validation, setValidation] = useState(false)
  const t = useTranslate()

  const handleOnBlur = useCallback(() => {
    if(!validateError && value !== initValue) {
      setValidation(true)

      validateUserPersonalData({ operatorLogin: value })
        .then(() => handleSave(fieldName, isEmpty(value) ? null : value))
        .catch(error => {
          if(path(['info', 'status'], error)){
            setValidateError(t('Username is already in use, use a different one'))
          } else {
            errorMessage(t('Error occurred during validation process'))
          }
        })
        .finally(() => setValidation(false))
    }
  },[fieldName, handleSave, initValue, t, validateError, value])

  return <ValidateInput
    dataIsSave={dataIsSave || validation}
    disableClear={disableClear}
    disabled={disabled}
    error={Boolean(validateError)}
    handleOnBlur={handleOnBlur}
    helperText={validateError}
    initValue={initValue}
    label={label}
    required={required}
    setValidateError={setValidateError}
    setValue={setValue}
    t={t}
    testid={testid}
    validator={validate(loginValidators)}
    value={String(value)}
  />
}
