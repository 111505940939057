import React from 'react'
import styled from 'styled-components'
import { TSVGIconProps } from 'types'
import { BlueCircleIcon, CheckedIcon } from './icons'

type TProps = {
  Preview: (arg: TSVGIconProps) => JSX.Element
  count: number
  isChecked: boolean
}

export default function PreviewWithState({ Preview, count, isChecked }: TProps) {
  return (
    <PreviewElement>
      {!isChecked && count > 0 && <BlueCircleIconWithCount>
        <BlueCircleIconStyled />
        <Count>{ count }</Count>
      </BlueCircleIconWithCount>}

      {isChecked && <CheckedIconStyled />}

      <Preview />
    </PreviewElement>
  )
}

const PreviewElement = styled.div`
  position: sticky;
  :hover {
    transition: box-shadow 300ms cubic-bezier(0.64, 0.04, 0.35, 1);;
    box-shadow: 0 0 30px rgba(0,0,0,0.31);
  }
`

const CheckedIconStyled = styled(CheckedIcon)`
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
`

const BlueCircleIconWithCount = styled.div`
  position: sticky;
`

const BlueCircleIconStyled = styled(BlueCircleIcon)`
  position: absolute;
`

const Count = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Color 9 */

  color: #FFFFFF;

  width: 26px;
  text-align: center;
`
