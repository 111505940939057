import React, { memo } from 'react'
import styled from 'styled-components'

type TProps = {
  children: React.ReactNode
  load?: boolean
  status: string | number
  title: string
}

const Item = memo(({
  children,
  load,
  status,
  title,
}: TProps) => <DataItemWrapper load={load}>
  <LeftContent>
    <DataItemIcon>
      {children}
    </DataItemIcon>

    <p>{title}</p>
  </LeftContent>

  <StatusWrapper>{status}</StatusWrapper>
</DataItemWrapper>,
)

export default Item

const DataItemWrapper = styled.div<{load?: boolean}>`
  &:first-child {
    border-top: 1px solid rgba(32, 32, 32, 0.1);
  }
  
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ load }) => load ? 0.2 : 'unset'};
`

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`

const DataItemIcon = styled.div`
  width: 54px;
  height: 80px;
  display: flex;
  align-items: center;
`

const StatusWrapper = styled.p`
  font-weight: 700;
  margin-left: 20px;
  text-align: end;
`
