import React from 'react'
import styled from 'styled-components'
import Hint from '../Hint'

const C_MAX_HEIGHT = 185

type TProps = {
  caption: string
  children?: React.ReactNode
  className: string
  color: string
  hint?: string
  max: number
  maxHeight?: number
  value?: string | number
}

export default function GraphBar({
  caption, className, color, hint, max, value, maxHeight = C_MAX_HEIGHT,
}: TProps) {
  return (
    <Container className={className}>
      <span>{ caption }</span>
      <Hint {...{ hint }}>
        <GraphBarDiv
          color={color}
          maxHeight={maxHeight}
          proportionHeight={Number(value) / max}
        />
      </Hint>
    </Container>
  )
}

const GraphBarDiv = styled.div<{ maxHeight: number, proportionHeight: number }>`
  background: ${({ color }) => color};
  border-radius: 2px 2px 0 0;
  width: 100%;
  height: ${({ proportionHeight, maxHeight }) => proportionHeight * maxHeight || 1}px;
`

const Container = styled.div`
  height: ${C_MAX_HEIGHT}px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
`
