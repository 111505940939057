import Tippy from '@tippyjs/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { TTranslate, TUserUnitData } from 'types'
import { link } from '../../router'
import { Progress } from '../Machines/card'
import { Icons } from '../Machines/icons'
import TooltipD from '../Tooltip'

const progresColor = {
  black : '#202020',
  blue  : '#06a9f5',
  green : '#78C800',
  red   : '#D10B41',
  shadow: '#bcbcbc',
  yellow: '#F1C340',
}

const getColor = (value: number) => {
  if(value === 0) {
    return progresColor.black
  }

  if(value < 20 && value > 0) {
    return progresColor.blue
  }

  if(value > 20 && value < 60) {
    return progresColor.yellow
  }

  if(value > 60) {
    return progresColor.red
  }

  return progresColor.shadow
}

type IconEngineLoadProps = {
  load: number
}

const IconEngineLoad: FC<IconEngineLoadProps> = ({ load }) => {
  return (
    <svg viewBox={'0 0 22 14'} width={22} height={14}>
      <path
        id="Page 1"
        style={{ fill: getColor(load) }}
        transform="translate(-6 -6)"
        // eslint-disable-next-line max-len
        d="M25,11v2h-2v-2h-2l-2,-2h-3v-2h3v-2h-8v2h3v2h-5v3h-2v-3c-1.1,0 -2,0.9 -2,2v4c0,1.1 0.9,2 2,2v-3h2v3h2l2,2h10v-2h2v2c1.1,0 2,-0.9 2,-2v-4c0,-1.1 -0.9,-2 -2,-2"
      />
    </svg>
  )
}

type IconSpeedProps = {
  speed: number
}

const IconSpeed: FC<IconSpeedProps> = ({ speed }) => {
  return (
    <svg viewBox={'0 0 18 19'} width={18} height={19}>
      <path
        id="Fill 3"
        style={{ fill: getColor(speed) }}
        transform="translate(-6 -3)"
        // eslint-disable-next-line max-len
        d="M13,14.95l0.01,-7.7l3.72,6.75l-0.01,0c0.09,0.16 0.17,0.32 0.22,0.51c0.28,1.07 -0.37,2.16 -1.44,2.43c-1.07,0.27 -2.16,-0.37 -2.43,-1.44c-0.05,-0.18 -0.06,-0.37 -0.05,-0.55zM15,6c-4.97,0 -9,4.03 -9,9c0,1.74 0.5,3.35 1.35,4.73c2.32,-1.1 4.91,-1.73 7.65,-1.73c2.74,0 5.32,0.63 7.65,1.73c0.85,-1.38 1.35,-2.99 1.35,-4.73c0,-4.97 -4.03,-9 -9,-9z"
      />
    </svg>
  )
}

type InformationPanelProps = {
  data: TUserUnitData
  id: number
  name: string
  typeName: string
  model: string
  translate: TTranslate
}

export const InformationPanel: FC<InformationPanelProps> = ({ data, model, typeName, name, id, translate }) => {
  const speedData = data?.SPEED?.value || 0
  const hasSpeedData = Boolean(speedData && Math.floor(speedData) >=1)
  const loadData = data?.LOAD?.value || 0
  const hasLoadData = Boolean(loadData && Math.floor(loadData) >= 1)

  let FUEL = 0

  if(data?.FUEL?.value) {
    if(data.FUEL.measure !== '%') {
      FUEL =
        data.FUEL.value < data.FUEL.max
          ? Math.floor(data.FUEL.value / data.FUEL.max * 100)
          : 100
    } else {
      FUEL =
        data.FUEL.value < data.FUEL.max ? data.FUEL.value : data.FUEL.max
    }
  }

  return <>
    <div className="tracking-machine__data-col col-machine">
      <div className="data-machine-t__main">
        <Tippy content={data.STATUS.valueF}>
          <div
            className="data-machine-t__icon"
            style={{ background: data.STATUS.color }}
          >
            {Icons[model]?.('#ffffff') || Icons.NINJA('#ffffff')}
          </div>
        </Tippy>

        <TooltipD
          text={translate('Current status of the vehicle')}
          style={{ top: '-8px', left: '-15px' }}
        />

        <div className="data-machine-t__text">
          <Tippy content={typeName}>
            <Link
              to={`${link.detail}?machine=${id}`}
              className="data-machine-t__type"
            >
              {typeName}
            </Link>
          </Tippy>

          <Link
            to={`${link.detail}?machine=${id}`}
            className="data-machine-t__name"
          >
            {name}
          </Link>
        </div>
        <TooltipD text={translate('Make and model of technology')} style={{ top: '-8px', right: '-7px' }} />
      </div>
    </div>

    <div className="tracking-machine__data-col icons">
      <div className="data-machine-icon">
        <Progress fuel={FUEL} size="small" translate={translate}/>
        <p className="tracking-machine__progress-text">
          {data && data.FUEL && data.FUEL.measure === 'translate(\'l\')'
            ? data.FUEL.value
              ? `${data.FUEL.value.toFixed()} ${translate('l')}`
              : `0 ${translate('l')}`
            : `${(+FUEL).toFixed(0)}%`}
        </p>
      </div>

      {hasSpeedData &&
        <div className="data-machine-icon">
          <Tippy content={translate('speed')}>
            <div className="speed">
              <IconSpeed speed={speedData} />
              <p>{speedData.toFixed()}</p>
            </div>
          </Tippy>
        </div>
      }

      {hasLoadData &&
        <div className="data-machine-icon">
          <Tippy content={translate('Engine load')}>
            <div className="engine-load">
              <IconEngineLoad load={Math.floor(loadData)} />
              <p>{loadData.toFixed()}%</p>
            </div>
          </Tippy>
        </div>
      }
    </div>
  </>
}
