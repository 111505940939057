import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { Link, Redirect,Route, Switch } from 'react-router-dom'
import { AppStateType } from 'reducers/store'
import { link } from '../../router'
import TooltipD from '../../ui/Tooltip'
import MachineReports from './MachineReports'
import PerformanceIndicators from './PerformanceIndicators'

const styles = {
  tooltip: { bottom: '0px', right: '0px', zIndex: '1000' },
}

type WatchSingleProps = {
  location: {
    pathname: string
  }
}

export const WatchSingle: FC<WatchSingleProps> = ({ location: { pathname } }) => {
  const translate = useTranslate()
  const fixedDataFilter = useSelector((state: AppStateType) => state.reports.get('fixedDataFilter'))

  return (
    <div>
      <TooltipD text={translate('Report Type Selection Menu')} style={styles.tooltip}/>

      <div className="watch__navigations">
        <Link
          to={`${link.watchPerformanceIndicators}${fixedDataFilter || ''}`}
          className={pathname === link.watchPerformanceIndicators || pathname === link.watchSingle ? 'active' : ''}
        >
          {translate('indications_of_work')}
        </Link>

        <Link
          to={`${link.watchMachineReports}${fixedDataFilter || ''}`}
          className={pathname === link.watchMachineReports ? 'active' : undefined}
        >
          {translate('report_about_machine')}
        </Link>
      </div>

      <Switch>
        <Redirect exact from={link.watchSingle} to={`${link.watchPerformanceIndicators}${fixedDataFilter || ''}`} />
        <Route path={link.watchPerformanceIndicators} component={PerformanceIndicators} />
        <Route path={link.watchMachineReports} component={MachineReports} />
      </Switch>
    </div>
  )
}
