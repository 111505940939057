import { formats } from '@agdt/agrotronic-react-components'
import { dygraphs } from 'dygraphs'
import ExcelJS from 'exceljs'
import { keys } from 'ramda'
import { C_SKRP_STATUS_NAME, C_SKRP_STATUSES_MAP } from '../../../../../../constants'
import { TLang, TTranslate } from '../../../../../../types'

type SeriesLegendData = dygraphs.SeriesLegendData

type TSensor = {
  type: string
}

export type TParams = {
  labels: string[]
  dataCoefs: Record<string, number>
  discretParams: string[]
  newColors: string[]
  series: Record<string, SeriesLegendData> | null | undefined
}

type TDuration = {
  beg: number
  end: number
}

export type TReportBackgroundItem = {
  duration: TDuration[]
  name: string
  value: number
}

export type TReportProps = {
  data: number[][]
  machineName: unknown
  params: TParams
  reportBackground: Record<string, TReportBackgroundItem>
  reportData: TSensor[]
  reportFrom: string
  reportTo: string
  showedParamsIds: number[]
  t: TTranslate
  userLogin: unknown
  lang: TLang
}

const C_REPORT_BODY_START_ROW = 6

export const createExcelFile = async ({
  data,
  machineName,
  params,
  reportBackground,
  reportData,
  reportFrom,
  reportTo,
  showedParamsIds,
  t,
  userLogin,
  lang,
}: TReportProps): Promise<void> => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()
  const SKRPStatusIndex = reportData.findIndex(sensor => sensor.type === C_SKRP_STATUS_NAME)

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/performanceindicators.xlsx`)

    // @ts-expect-error
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('B1').value = `${t('formed')}: ${ userLogin }, ${formats.date.dateViewFormatter(createDateTime, lang)}, ${formats.date.timeFormatter(createDateTime, lang)}`

  // eslint-disable-next-line max-len
  sheet.getCell('A2').value = `${t('Report')} "${t('indications_of_work')}" ${t('during the period')} ${formats.date.dateViewFormatter(formats.api.parseDateTimeFromApi(reportFrom), lang)} - ${formats.date.dateViewFormatter(formats.api.parseDateTimeFromApi(reportTo), lang)}`
  sheet.getCell('A3').value = `${t('the report is generated with filters')}: ${t('sht')}: ${machineName}`
  sheet.getCell('A4').value = `${t('Date and time')}`
  sheet.getCell('B4').value = `${t('Parameters')}`

  const lastColumn = showedParamsIds.length + 2

  //columns title
  const titleParams = showedParamsIds.map(id => params.labels[id])
  sheet.insertRow(5, ['', ...titleParams, t('mode')])

  // formatting header
  sheet.mergeCells(1, 2, 1, lastColumn)
  sheet.mergeCells(2, 1, 2, lastColumn)
  sheet.mergeCells(3, 1, 3, lastColumn)
  sheet.mergeCells(4, 2, 4, lastColumn)

  //create rows
  const rows = data.map(reportItem => {
    const modeName = keys(reportBackground).reduce((acc, key) => {
      if(reportBackground[key].duration.find(item => reportItem[0] >= item.beg && reportItem[0] < item.end)) {
        acc = reportBackground[key].name
      }

      return acc
    }, '-')

    const filteredData = showedParamsIds.map(
      paramId => {
        if(SKRPStatusIndex !== -1 && paramId === SKRPStatusIndex + 1) {
          return t(C_SKRP_STATUSES_MAP[Number((reportItem[paramId] * params.dataCoefs[params.labels[paramId]]).toFixed(0))])
        }

        return reportItem[paramId]
          ? (reportItem[paramId] * params.dataCoefs[params.labels[paramId]]).toFixed(1)
          : '-'
      },
    )

    return [
      new Date(reportItem[0]).user.format('DD.MM.YY HH:mm:ss'),
      ...filteredData,
      modeName,
    ]
  })

  sheet.insertRows(C_REPORT_BODY_START_ROW, rows)

  //formatting footer
  const lastRow = data.length + C_REPORT_BODY_START_ROW
  sheet.mergeCells(lastRow, 1, lastRow, lastColumn)

  // Download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'performanceIndicators_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
