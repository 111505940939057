import Tippy from '@tippyjs/react'
import { PopupChild, PopupContainer } from '@agdt/agrotronic-react-components'
import { keys } from 'ramda'
import React, { FC, useCallback } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { exportSheet } from '../common/exportSheet'

type Props = {
  items: Record<string, {
    model?: string
    number?: number
    uploads: string
  }[]>
  unit: string
}

const ActionsList: FC<Props> = ({ unit, items }) => {
  const t = useTranslate()

  const exportToExcel = useCallback(() => {
    if(!items) { return }

    const report = keys(items).reduce<{column: string}[]>((acc, key) => {
      acc.push({ column: t(key) })

      items[key].map(item => acc.push({
        column: `${item.model || t('Unknown transport')}${item?.number ? '' : ` (${item.number})`} - ${item.uploads}`,
      }))

      return acc
    }, [])

    exportSheet(
      [[unit]].concat(report.map(item => [item.column])),
      'report',
    )
  }, [items, unit])

  return <StyledPopupContainer>
    <StyledPopupChild onClick={exportToExcel}>
      {t('download excel')}
    </StyledPopupChild>
  </StyledPopupContainer>
}

export const DetailsImportToExcel: FC<Props> = ({ unit, items }) =>
  <ImportExportButton>
    <StyledTippy
      arrow={false}
      content={<ActionsList items={items} unit={unit} />}
      interactive
      placement="bottom"
    >
      <IconBox>...</IconBox>
    </StyledTippy>
  </ImportExportButton>

const StyledTippy = styled(Tippy)`
  .tippy-content {
    border-radius: 2px;
    padding: 0;
  }
`

// @todo поправить ошибку с defaultProps
const StyledPopupContainer = styled(PopupContainer as any)`
  display: flex;
  align-items: center;
`

// @todo поправить ошибку с defaultProps
const StyledPopupChild = styled(PopupChild as any)`
  display: flex;
  align-items: center;
  width: 140px;
`

const IconBox = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: .7;
  text-align: center;
`

const ImportExportButton = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  top: 30px;
  right: 55px;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    cursor: pointer;
  }
`
