import { Avatar, formats } from '@agdt/agrotronic-react-components'
import React, { FC } from 'react'
import styled from 'styled-components'

type TProps = {
  photo: string
  fullName: string
  tel: number
}

export const OperatorCard: FC<TProps> = ({ photo, fullName, tel }) =>
  <CardWrapper>
    <Avatar size='48px' src={photo && `data:image/png;base64, ${photo}`}/>

    <Info>
      <FullName>{fullName}</FullName>
      <Phone>{tel ? formats.phone.phoneMask(tel.toString()) : ''}</Phone>
    </Info>
  </CardWrapper>

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`

const CardWrapper = styled.div`
  display: flex;
  margin: 6px 0;
`

const FullName = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const Phone = styled.div`
  font-size: 12px;
  font-weight: 400;
  opacity: .6;
`
