import Tippy from '@tippyjs/react'
import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { getStore } from 'stores/storesRegistry'

type MarkerButtonsProps = {
  imei: number
  showStopsIntervalsPanel: boolean
  showStopsNotificationPanel: boolean
  toogleStopsIntevalsPanel: () => void
  toogleNotificationPanel: () => void
  centerTrack: () => void
}

const MarkerButtons: FC<MarkerButtonsProps> = ({
  imei,
  showStopsIntervalsPanel,
  showStopsNotificationPanel,
  toogleStopsIntevalsPanel,
  toogleNotificationPanel,
  centerTrack,
}) => {
  const translate = useTranslate()
  const unitTrack = getStore('householdUnits').getUnit(imei)
  const markers = unitTrack.track.markers.value

  const toggleMarkers = (value: 'filingAndDrain' | 'loading' | 'predictUnloading' | 'unloading') =>
    () => unitTrack.track.markers.toggleSelected(value)

  const selectedMarkers = unitTrack.track.markers.selected

  const showMarkerButton = (markerName: 'stopIntervals' | 'notification' | 'filingAndDrain' | 'loading') =>
    markers?.[markerName] && !isEmpty(markers?.[markerName])

  const showPredictUnloading = () => Boolean(markers?.['predictUnloading']?.level)

  return (
    <div className="tracking-machine__button-control">
      {markers && <>
        {showMarkerButton('stopIntervals') &&
          <Tippy content={translate('downtime')} key={1}>
            <button
              onClick={toogleStopsIntevalsPanel}
              className={`tracking-machine__bt ${showStopsIntervalsPanel ? ' active' : ''}`}
            >
              <img src='/img/svg/map/stopintervals.svg' />
            </button>
          </Tippy>
        }

        {showMarkerButton('notification') &&
          <Tippy content={translate('notifications')} key={2}>
            <button
              onClick={toogleNotificationPanel}
              className={`tracking-machine__bt ${showStopsNotificationPanel ? 'active' : ''}`}
            >
              <img src='/img/svg/map/notice.svg' />
            </button>
          </Tippy>
        }

        {showMarkerButton('filingAndDrain') &&
          <Tippy content={translate('Filling/Drain')} key={3}>
            <button
              onClick={toggleMarkers('filingAndDrain')}
              className={`tracking-machine__bt ${selectedMarkers['filingAndDrain'] ? ' active' : ''}`}
            >
              <img src='/img/svg/map/filling.svg' />
            </button>
          </Tippy>
        }

        {showMarkerButton('loading') &&
          <Tippy content={translate('BUNKER_FULL')} key={4}>
            <button
              onClick={toggleMarkers('loading')}
              className={`tracking-machine__bt ${selectedMarkers['loading'] ? ' active' : ''}`}
            >
              <img src='/img/svg/map/bunker-full.svg' />
            </button>
          </Tippy>
        }

        {showPredictUnloading() &&
          <Tippy content={translate('predictUnloading')} key={5}>
            <button
              onClick={toggleMarkers('predictUnloading')}
              className={`tracking-machine__bt ${selectedMarkers['predictUnloading'] ? ' active' : ''}`}
            >
              <img src='/img/svg/map/predictUnloading.svg' />
            </button>
          </Tippy>
        }

        {showMarkerButton('loading') &&
          <Tippy content={translate('UPLOAD')} key={6}>
            <button
              onClick={toggleMarkers('unloading')}
              className={`tracking-machine__bt ${selectedMarkers['unloading'] ? 'active' : ''}`}
            >
              <img src='/img/svg/map/onloading.svg' />
            </button>
          </Tippy>
        }
      </>
      }

      <Tippy content={translate('Scaling')} key={8}>
        <button
          className={'tracking-machine__bt bt6'}
          onClick={centerTrack}
        />
      </Tippy>
    </div>
  )
}

export default observer(MarkerButtons)
