export const mapParams = [
  {
    colors     : ['#791F2C', '#FE3402', '#FFAF02', '#FCED04', '#82CC01', '#026836'],
    measure    : 't/ha',
    measureName: 'Productivity',
    name       : 'Productivity map wet KUK',
    type       : 'CURR_PRODUCTIVITY_KUK',
    values     : [0, 20, 40, 60, 80, 100],
  },

  {
    colors     : ['#791F2C', '#FE3402', '#FFAF02', '#FCED04', '#82CC01', '#026836'],
    measure    : 't/ha',
    measureName: 'Productivity',
    name       : 'Productivity map dry KUK',
    type       : 'DRY_PRODUCTIVITY_KUK',
    values     : [0, 20, 40, 60, 80, 100],
  },

  {
    colors     : ['#D8DBD8', '#BBC1C5', '#95B3C5', '#609BC1', '#2B719F', '#0E5E9F'],
    measure    : '%',
    measureName: 'Moisture',
    name       : 'Moisture map KUK',
    type       : 'CURR_HYMIDITY_KUK',
    values     : [0, 20, 40, 55, 65, 75],
  },

  {
    colors     : ['#791F2C', '#FE3402', '#FFAF02', '#FCED04', '#82CC01', '#026836'],
    measure    : 'cwt/ha',
    measureName: 'Productivity',
    name       : 'Productivity map ZUK',
    type       : 'CURR_PRODUCTIVITY_ZUK',
    values     : [0, 50, 70, 80, 100, 200],
  },

  {
    colors     : ['#D8DBD8', '#95B3C5', '#97B6C7', '#609BC1', '#2B719F', '#0E5E9F'],
    measure    : '%',
    measureName: 'Moisture',
    name       : 'Moisture map ZUK',
    type       : 'CURR_HYMIDITY_ZUK',
    values     : [0, 10, 15, 20, 25, 30],
  },
] as const

export const C_ERROR_STATUSES = {
  CHANGED : 'CHANGED',
  IS_EMPTY: 'IS_EMPTY',
} as const

export type TErrorStatus = keyof typeof C_ERROR_STATUSES

export type TMapParams = typeof mapParams
export type TMapTypes = typeof mapParams[number]['type']
