import React from 'react'

const CheckedIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="13" cy="13" r="13" fill="#2EA44E"/>
      <path d="M9.79506 16.875L6.32506 13.405C6.13823 13.2178 5.88458 13.1125 5.62006 13.1125C5.35554 13.1125 5.10189 13.2178 4.91506 13.405C4.52506 13.795 4.52506 14.425 4.91506 14.815L9.09506 18.995C9.48506 19.385 10.1151 19.385 10.5051 18.995L21.0851 8.41501C21.4751 8.02501 21.4751 7.39501 21.0851 7.00501C20.8982 6.81776 20.6446 6.71252 20.3801 6.71252C20.1155 6.71252 19.8619 6.81776 19.6751 7.00501L9.79506 16.875Z" fill="white"/>
    </svg>
  )
}

export default CheckedIcon




