import React, { CSSProperties, FC } from 'react'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'

type ElementType = {
  id: number
  name: string
}

type ItemComponentProps = {
  item: ElementType
  color?: string
  style: CSSProperties
  section: string
  onStartFilter: (section: string, item: ElementType, solo?: boolean, clear?: boolean) => void
}

type ActiveFilterMachinesColProps = {
  result?: ElementType[]
  items: unknown[]
  section: string
  colors?: string[]
  onStartFilter: (section: string, item: ElementType, solo?: boolean, clear?: boolean) => void
}

const Item: FC<ItemComponentProps> = ({ item, section, color, style, onStartFilter }) => {
  const onClick = () => {
    onStartFilter(section, item)
  }

  return (
    <ItemStyled className="filter-result" style={style}>
      <p>{item.name}</p>
      {color && <i className="filter-result__color" style={{ background: color }} />}
      <span
        className="filter-result__delete"
        tabIndex={0}
        role="button"
        onClick={onClick}
      />
    </ItemStyled>
  )
}

export const ActiveFilterMachinesCol: FC<ActiveFilterMachinesColProps> = ({
  result, items, section, colors, onStartFilter,
}) =>
  <StyledListWrapper>
    {result &&
      <StyledList
        width="100%"
        height={84}
        itemCount={result.length}
        itemSize={28}
      >
        {({ index, style }) => {
          const item = result[index]

          return (
            <Item
              key={item.id}
              item={item}
              color={colors?.[items.findIndex(it => it === item)]}
              section={section}
              style={style}
              onStartFilter={onStartFilter}
            />
          )
        }}
      </StyledList>
    }
  </StyledListWrapper>

const StyledList = styled(List)`
  width: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
    width: 5px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #D10B41;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
`

const ItemStyled = styled.div`
  height: 27px;
  max-height: 27px;
  margin-bottom: 1px;
`

const StyledListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  min-width: 200px;
  max-height: 84px;
  overflow-y: auto;
  padding-right: 1px;
`
