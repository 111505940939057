import { Button, ButtonGroup, Spacer } from '@agdt/agrotronic-react-components'
import React, { useEffect,useState } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { TDateRange, TPeriod, TTranslate } from 'types'
import DatePeriod from './DatePeriod'

export type TProps = {
  dateRange: TDateRange
  setDateRange: ({ range, typeRange }: { range: TDateRange, typeRange: TPeriod }) => void
  translate: TTranslate
}

const DateBlock = ({ translate, dateRange, setDateRange }: TProps) => {
  const [localRange, setLocalRange] = useState<TDateRange>(dateRange)
  const [typeRange, setTypeRange] = useState<TPeriod>('today')

  useEffect(() => {
    if(typeRange !== 'range') {
      setDateRange({ range: localRange, typeRange })
    }
  }, [typeRange])

  const handleTodayRange = () => {
    const from = new Date().user.startOf('day')
    const to = new Date().user.endOf('day')

    setTypeRange('today')
    setLocalRange([from, to])
  }

  const handleWeekRange = () => {
    const from = new Date().user.subtract(1, 'week').startOf('day')
    const to = new Date().user.endOf('day')

    setTypeRange('week')
    setLocalRange([from, to])
  }

  return (
    <DateBlockContainer>
      {/*@ts-expect-error*/}
      <ButtonGroup>
        <Button
          onClick={handleTodayRange}
          variant={typeRange === 'today' ? 'primary' : 'border'}
        >
          {translate('today')}
        </Button>
        <Button
          variant={typeRange === 'week' ? 'primary' : 'border'}
          onClick={handleWeekRange}
        >
          {translate('week')}
        </Button>
      </ButtonGroup>

      <Spacer size="24px" />

      <DatePeriod
        dateRange={dateRange}
        handleTodayRange={handleTodayRange}
        handleWeekRange={handleWeekRange}
        localRange={localRange}
        setDateRange={setDateRange}
        setLocalRange={setLocalRange}
        setTypeRange={setTypeRange}
        typeRange={typeRange}
      />
    </DateBlockContainer>
  )
}

const DateBlockContainer = styled.div`
  display: flex;
`

export default withTranslate(DateBlock)
