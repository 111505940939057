import de from './de'
import en from './en'
import fr from './fr'
import ru from './ru'

export const translations = {
  de: {
    locale  : 'de',
    messages: de,
    name    : 'Deutsch',
  },
  en: {
    locale  : 'en',
    messages: en,
    name    : 'English',
  },

  fr: {
    locale  : 'fr',
    messages: fr,
    name    : 'Le français',
  },
  ru: {
    locale  : 'ru',
    messages: ru,
    name    : 'Русский',
  },
}

export default translations
