import { action, makeObservable, observable } from 'mobx'
import { TMapTypes } from '../../containers/Tracking/GeoZones/constants'
import TextInputStore from '../formFieldsStores/TextInputStore'

type TPeriod = {from: number | null, to: number | null}

export default class MapBuildSettings {
  @observable selectedMapType: TMapTypes | null = null
  @observable period: TPeriod = { from: null, to: null }
  widthPart = new TextInputStore('20')

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setSelectedMapType(mapType: TMapTypes | null) {
    this.selectedMapType = mapType
  }

  @action.bound
  setPeriod(period: TPeriod) {
    this.period = period
  }
}
