import { Button, ModalDialog, ModalFooterActions, ModalHeader, ModalOverlay } from '@agdt/agrotronic-react-components'
import FilterCheckbox from 'components/Filter/FilterCheckbox'
import FilterItemMulty from 'components/Filter/FilterItemMulty'
import React, { FC,useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { setFilter as setFilterAction } from 'reducers/notification/actions'
import { FilterType } from 'reducers/notification/state'
import { AppStateType } from 'reducers/store'
import {
  filteredUsersOfType,
  getListUnitsFromSimplifiedUsers,
  getSimpifiedUsers,
  getStatusesAndModelsMachinesList,
} from 'reducers/users/selectors'
import styled from 'styled-components'
import { getStore } from '../../../../stores/storesRegistry'
import { init, NotificationPageActionsEnum, reducer } from './reducer'

type Props = {
  visibleFilter: boolean
  setVisibleFilter: (value: boolean) => void
}

const FilterModal: FC<Props> = ({
  visibleFilter,
  setVisibleFilter,
}) => {
  const stateDispatch = useDispatch()
  const users = useSelector(filteredUsersOfType)
  const filter = useSelector((state: AppStateType) => state.notification.filter)
  const machines = getSimpifiedUsers(users)
  const machinesList = getListUnitsFromSimplifiedUsers(machines)
  const { statuses, models } = getStatusesAndModelsMachinesList(machinesList)
  const t = useTranslate()
  const noticesStore = getStore('notices')

  const setFilter = (value: FilterType) => {
    stateDispatch(setFilterAction(value))
  }

  const [state, dispatch] = useReducer(
    reducer,
    { filter, machines, machinesList, models, statuses },
    init,
  )

  useEffect(() => {
    dispatch({
      payload: { machinesList, models, statuses },
      type   : NotificationPageActionsEnum.INIT_MACHINES,
    })
  }, [machinesList, statuses, models])

  useEffect(() => {
    dispatch({
      payload: filter,
      type   : NotificationPageActionsEnum.INIT_RESULT,
    })
  }, [filter])

  const setFilteredList = (list: unknown[]) =>
    dispatch({ payload: list, type: NotificationPageActionsEnum.SET_FILTERED_LIST })

  const setStatuses = (value?: string) =>
    dispatch({ payload: value, type: NotificationPageActionsEnum.SET_STATUSES })

  const clearStatuses = () => dispatch({ type: NotificationPageActionsEnum.SET_STATUSES })

  const setCategories = (categories?: string) =>
    dispatch({ payload: categories, type: NotificationPageActionsEnum.SET_CATEGORIES })

  const clearCategories = () => dispatch({ type: NotificationPageActionsEnum.SET_CATEGORIES })

  const setMachine = (farmId: string | number, unit: unknown) =>
    dispatch({ payload: { farmId, unit }, type: NotificationPageActionsEnum.SET_MACHINE })

  const setMachines = (farmId: string | number) =>
    dispatch({ payload: farmId, type: NotificationPageActionsEnum.SET_MACHINES })

  const setAllMachines = () =>
    dispatch({ type: NotificationPageActionsEnum.SET_ALL_MACHINES })

  const clearAllMachines = () => dispatch({ type: NotificationPageActionsEnum.CLEAR_ALL_MACHINES })

  const setSelectItems = (item: unknown[] = []) =>
    dispatch({ payload: item, type: NotificationPageActionsEnum.SET_SELECT_ITEMS })

  const onCancel = () => {
    dispatch({
      payload: filter,
      type   : NotificationPageActionsEnum.INIT_RESULT,
    })

    setVisibleFilter(false)
  }

  const handlerSetFilter = () => {
    const normalizeFilter = Object.entries(state).reduce((acc, [_, value]) => {
      //@ts-expect-error
      acc[value.section] = value.result
      return acc
    }, {})

    setFilter(normalizeFilter)
    noticesStore.setFilter(normalizeFilter)
    setVisibleFilter(false)
  }

  return (
    <ModalOverlay visible={visibleFilter} onClose={onCancel}>
      <StyledDialog>
        <ModalHeader onClose={onCancel}>
          <TitleContainer>{t('Filter')}</TitleContainer>
        </ModalHeader>

        <Divider />

        <ModalBody>
          <FilterContainer>
            <FilterItemMulty
              {...state.machine}
              clearAllMachines={clearAllMachines}
              translate={t}
              setMachines={setMachines}
              setAllMachines={setAllMachines}
              setSelectItems={setSelectItems}
              setMachine={setMachine}
              setFilteredList={setFilteredList}
              machines={machines}
            />

            <FilterCheckbox
              {...state.category}
              translate={t}
              setValue={setCategories}
              clearResult={clearCategories}
            />

            <FilterCheckbox
              {...state.status}
              translate={t}
              setValue={setStatuses}
              clearResult={clearStatuses}
            />
          </FilterContainer>
        </ModalBody>

        <StyledFooter>
          <Button onClick={handlerSetFilter} width="auto">
            {t('Apply filter')}
          </Button>
        </StyledFooter>
      </StyledDialog>
    </ModalOverlay>
  )
}

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2e5ec;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #202020;
`

const ModalBody = styled.div`
  margin-top: 24px;
  //max-width: 300px;
`

const StyledDialog = styled(ModalDialog)``

const StyledFooter = styled(ModalFooterActions)`
  margin-top: 40px;

  &&& {
    align-items: center;
    justify-content: center;
  }
`

const FilterContainer = styled.div`
  display: flex;

  & > div:first-child {
    padding-left: 0;
  }
  & > div:last-child {
    padding-right: 0;
  }
`

export default FilterModal
