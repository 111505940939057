import Dygraph, { dygraphs } from 'dygraphs'
import { append, converge, divide, length, path, pipe, sum, takeLast } from 'ramda'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import TooltipD from 'ui/Tooltip'
import { C_SKRP_STATUS_NAME, C_SKRP_STATUSES_MAP } from '../../../../constants'
import { colors } from './constants'
import ItemParam from './ItemParam'
import { ToExcel } from './ToExcell'
import { TParams, TReportBackgroundItem } from './ToExcell/ToExcellHandler'

type SeriesLegendData = dygraphs.SeriesLegendData
type LegendData = dygraphs.LegendData

type TReportRecord = {
  value: number[]
  measure: string
  brName: string
}

type TReport = {
  values: Record<string, TReportRecord>
  time: number[]
  background: Record<string, TReportBackgroundItem>
}

type TReportParam = {
  type: string
  measure: string
  brName: string
  value: number[]
}

type SKPRBackgroundData = {
  color: string
  beg: number | null
  end: number | null
}

type SKPRBackground = {
  currBeg: number | null
  currColor: string | null
  currEnd: number | null
  data: SKPRBackgroundData[]
}

type TProps = {
  report: TReport[]
  reportTo: string
  reportFrom: string
  machineName: string
}

const getMax = (arr: number[]): number => {
  let len = arr.length
  let max = -Infinity

  while(len--) {
    max = arr[len] > max ? arr[len] : max
  }

  return max
}

const getSKPRBackgrounds = (report: TReport): SKPRBackground => {
  const bgMap: Record<number, string> = {
    0: 'rgba(51, 54, 62, 1)',
    1: 'rgba(243, 114, 3, 1)',
    2: 'rgba(120, 200, 0, 1)',
    3: 'rgba(0, 158, 227, 1)',
  }

  const SKRPData = report.values[C_SKRP_STATUS_NAME].value

  return SKRPData.reduce<SKPRBackground>((acc, value, index) => {
    if(acc.currColor === null) {
      acc.currColor = bgMap[value]
      acc.currBeg = report?.time[index]
      acc.currEnd = report?.time[index]

      return acc
    }

    if(acc.currColor !== bgMap[value]) {
      acc.data.push({
        beg  : acc.currBeg,
        color: acc.currColor,
        end  : acc.currEnd,
      })

      acc.currColor = bgMap[value]
      acc.currBeg = report.time[index]
      acc.currEnd = report.time[index]

      return acc
    }

    if(SKRPData.length === index + 1) {
      acc.data.push({
        beg  : acc.currBeg,
        color: bgMap[value],
        end  : report.time[index],
      })

      return acc
    }

    acc.currEnd = report.time[index]
    return acc
  }, { currBeg: null, currColor: null, currEnd: null, data: [] })
}

export const ResultFilter: FC<TProps> = ({ report, reportTo, reportFrom , machineName }) => {
  const [data, setData] = useState<number[][]>([])
  const [dygraph, setDygraph] = useState<Dygraph | null>(null)
  const [graphicColor, setGraphicColor] = useState<string[]>([])
  const [paramsData, setParamsData] = useState<TParams>({ labels: [], dataCoefs: {}, discretParams: [], newColors: [], series: undefined })
  const [rollAvg, setRollAvg] = useState<number[]>([])
  const [visibilitySeries, setVisibilitySeries] = useState(Object.keys(report[0].values).length > 1 ? [1, 2] : [1])

  const t = useTranslate()

  const graphicRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    loadGraphic()
  }, [rollAvg, visibilitySeries])

  useEffect(() => {
    createGraphic()
  }, [graphicColor, data, paramsData, graphicRef.current])

  const reportParams: TReportParam[] = Object.keys(report[0].values).map(item => ({
    ...report[0].values[item],
    type: item,
  }))

  const SKRPStatusIndex = Object.keys(report[0].values).findIndex(sensorName => sensorName === C_SKRP_STATUS_NAME)



  const updateColor = (newColors: string[]): void => {
    if(!newColors.length) {
      newColors = graphicColor
    } else {
      setGraphicColor(newColors)
    }

    dygraph?.updateOptions({ colors: newColors })
  }

  const uploadGraphic = (_newColors: string[], index: number, showIndex: boolean | undefined, isRollAvg?: boolean, rollAvgValue?: number): void => {
    if(rollAvgValue !== undefined) {
      const newRollAvg = [...rollAvg]
      newRollAvg[index] = isRollAvg ? rollAvgValue : 1

      setRollAvg(newRollAvg)
    }

    if(index !== undefined && showIndex !== undefined) {
      let tempVisibilitySeries = [...visibilitySeries]

      showIndex
        ? tempVisibilitySeries.push(index + 1)
        : tempVisibilitySeries.splice(
          tempVisibilitySeries.indexOf(index + 1),
          1,
        )

      setVisibilitySeries(tempVisibilitySeries)
    }
  }

  const createGraphic = () => {
    if(!dygraph && !graphicRef.current || !Boolean(data.length)) {
      // если графика еще нет, то ждём пока в DOM появится элемент, который будет использоваться в dygraph
      // а потом ждём пока для графика будут данные

      return
    }

    let { newColors, labels, series, dataCoefs, discretParams } = paramsData

    const visibility = Object.keys(report[0].values).reduce<boolean[]>(
      (acc, _, index) =>
      {
        acc.push(visibilitySeries.includes(index + 1))
        return acc
      },
      [],
    )

    if(!dygraph) {
      const dygraphConfig = {
        connectSeparatedPoints: false,
        showRangeSelector     : true,
        interactionModel      : Dygraph.defaultInteractionModel,
        visibility            : visibility,
        legendFormatter       : (data: LegendData): string => {
          let range: number | null = null

          
          for(let key in report[0].background) {
            let findRange = report[0].background[key].duration.find(
              item => data.x >= item.beg && data.x < item.end,
            )

            if(findRange) {
              range = report[0].background[key].value
            }
          }

          if(data.x == null) {
            return (
              '<br>' +
              data.series.map(function (series: SeriesLegendData): string {
                return series.dashHTML + ' ' + series.labelHTML
              })
                .join('<br>')
            )
          }

          let html = `<div class="wrap-label">
            <p>${t('date')}</p>
            <p>${new Date(data.x).user.format('DD.MM.YY')} ${new Date(data.x).user.format('HH:mm:ss')}</p>
           </div>

           <div class="wrap-label">
             <p>${t('mode')}</p>
             <p>${t(`M${range && range >= 1 && range <= 6 ? range : 0}`)}</p>
           </div>`

          
          data.series.forEach((series: SeriesLegendData, key) => {
            if(!series.isVisible){return}

            let preparedValue

            if(path([key, 'type'], reportParams) === 'TRANSMISSION') {
              preparedValue = {

                
                '0': t('Move forward'),

                
                '1': t('Neutral gear'),

                
                '2': t('Back'),

                
                '3': t('Parking'),
              }[String(Number(series.yHTML).toFixed(0))]
            }

            //Преобразование значения СКПР в текст
            if(reportParams[key].type === C_SKRP_STATUS_NAME) {
              preparedValue = t(C_SKRP_STATUSES_MAP[Number(series.yHTML)])
            }

            let labeledData = `<div class="wrap-label__item"><p>${series.labelHTML}</p>

            <p>${
              discretParams?.includes(series.labelHTML)
                ? !series.y ? t('No') : t('Yes')
                : preparedValue || series.yHTML
}</p></div>`

            if(series.isHighlighted) {
              labeledData = `<div class="wrap-label__children">${series.label}</div>`
            }

            html += labeledData
          })

          return html
        },

        colors: newColors,
        legend: 'follow',
        series: series,

        underlayCallback: (canvas: CanvasRenderingContext2D, area: {y: number, h: number}, g: Dygraph): void => {
          const highlight_period = (x_start: number, x_end: number, mod: number): void => {
            const mode = [
              'rgba(177, 177, 177, 0.2)',
              'rgba(6, 170, 245, 0.2)',
              'rgba(32, 32, 32, 0.2)',
              'rgba(209, 11, 65, 0.2)',
              'rgba(120, 200, 0, 0.2)',
              'rgba(214, 31, 214, 0.2)',
              'rgba(243,114,3,0.2)',
            ]

            canvas.fillStyle = mode[mod]
            let canvas_left_x = g.toDomXCoord(x_start)
            let canvas_right_x = g.toDomXCoord(x_end)
            let canvas_width = canvas_right_x - canvas_left_x
            canvas.fillRect(canvas_left_x, area.y, canvas_width, area.h)
          }

          //график для SKRP_STATUS отображается полосой в нижней части области поля.
          if(SKRPStatusIndex !== -1 && visibilitySeries.includes(SKRPStatusIndex + 1)) {
            const SKPRBackgrounds = getSKPRBackgrounds(report[0])

            
            SKPRBackgrounds.data.forEach(el => {
              canvas.fillStyle = el.color

              const canvas_left_x = g.toDomXCoord(el.beg) || 0
              const canvas_right_x = g.toDomXCoord(el.end) || 0
              const canvas_width = canvas_right_x - canvas_left_x

              canvas.fillRect(canvas_left_x, area.h - 24, canvas_width, 24)
            })
          }

          
          let MOD = report[0].background

          for(let i in MOD) {
            MOD[i].duration.map(item => {
              highlight_period(item.beg, item.end, MOD[i].value)
            })
          }
        },
        labels,
        xAxisHeight: 30,
        axes       : {
          x: {
            axisLabelFormatter: (text: number | Date): string => {
              return (
                '<div style=\'font-size:10px\'>' +
                new Date(text).user.format('DD.MM.YY') +
                '<br/>' +
                new Date(text).user.format('HH:mm:ss') +
                '</div>'
              )
            },
            
            valueFormatter: (text: number | Date): string => {
              return new Date(text).user.format('DD.MM.YY')
            },
          },
          y: {
            valueFormatter: (value: number | Date | null, _opts: unknown, series_name: string): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: new config: dygraphConfig.axes.y.valueFormatter(): wrong argument type: Date')
              }

              return value !== null ? (value * dataCoefs[series_name]).toFixed(1) : '-'
            },
            
            axisLabelFormatter: (value: number | Date): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: new config: dygraphConfig.axes.y.axisLabelFormatter(): wrong argument type: Date')
              }

              return value.toFixed(1)
            },
          },
          y2: {
            valueFormatter: (value: number | Date | null): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: new config: dygraphConfig.axes.y2.axisLabelFormatter(): wrong argument type: Date')
              }

              return value?.toFixed(1) ?? '-'
            },
            
            axisLabelFormatter: (value: number | Date): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: new config: dygraphConfig.axes.y2.axisLabelFormatter(): wrong argument type: Date')
              }

              return value.toFixed(1)
            },
          },
        },
        labelsShowZeroValues: true,

        axisLabelFormatter: (value: number | Date): string => '<div style=\'font-size:10px\'>' + value + '</div>',
      } as const // чтобы legend: 'follow' был типизирован как legend: 'follow', а не как legend: string

      const dygraph = new Dygraph(graphicRef.current || '', data, dygraphConfig)

      setDygraph(dygraph)
    } else {
      const dygraphConfig = {
        visibility,
        colors: newColors,
        series,
        labels,
        file  : data,
        axes  : {
          x: {
            axisLabelFormatter: (value: number | Date): string => {
              return (
                '<div style=\'font-size:10px\'>' +
                new Date(value).user.format('DD.MM.YY') +
                '<br/>' +
                new Date(value).user.format('HH:mm:ss') +
                '</div>'
              )
            },
            valueFormatter: (value: number | Date): string => {
              return new Date(value).user.format('DD.MM.YY')
            },
          },
          y: {
            valueFormatter: (value: number | Date | null, _opts: unknown, series_name: string): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: update config: dygraphConfig.axes.y.valueFormatter(): wrong argument type: Date')
              }

              return value !== null ? (value * dataCoefs[series_name]).toFixed(1) : '-'
            },

            
            axisLabelFormatter: (value: number | Date): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: update config: dygraphConfig.axes.y.axisLabelFormatter(): wrong argument type: Date')
              }

              return value.toFixed(1)
            },
          },
          y2: {
            valueFormatter: (value: number | Date): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: update config: dygraphConfig.axes.y2.valueFormatter(): wrong argument type: Date')
              }

              return value !== null ? value.toFixed(1) : '-'
            },

            
            axisLabelFormatter: (value: number | Date): string => {
              if(value instanceof Date) {
                throw Error('PerformanceIndicators: update config: dygraphConfig.axes.y2.axisLabelFormatter(): wrong argument type: Date')
              }

              return value.toFixed(1)
            },
          },
        },
      }

      
      dygraph.updateOptions(dygraphConfig)

      
      const area = dygraph.getArea()

      
      const oldVisibility = dygraph.visibility().reduce((acc, item) => item ? acc + 1 : acc, 0)

      if(oldVisibility === 2) {
        area.w += visibilitySeries.length > oldVisibility ? 117 - 57 : 117 + 1
        area.h += 74

        
        dygraph.resize(area.w, area.h)
      }
    }
  }

  const loadGraphic = (): void => {
    if(report[0] && report[0].time.length) {
      let data: number[][] = []
      let labelsAll = ['Date']

      let params: {
        newColors: string[]
        labels: string[]
        series: Record<string, SeriesLegendData>
        dataCoefs: Record<string, number>
        discretParams: string[]
      } = {
        newColors    : [],
        labels       : [],
        series       : {},
        dataCoefs    : {},
        discretParams: [],
      }

      let labels: typeof params.labels = ['Date']
      let series: typeof params.series = {}
      let dataCoefs: typeof params.dataCoefs = {}
      let newColors: typeof params.newColors = []
      let discretParams: typeof params.discretParams = []
      let mainData = 1
      let counterSeries = 0

      // @ts-expect-error
      const average = converge(divide, [sum, length])

      reportParams.forEach((it, itemIndex) => {
        let dataCoefIndex = 1
        let seriesName = `${it.brName}${it.measure ? `, ${it.measure}` : ''}`

        if(it.type.indexOf('ON_') === 0 || it.type === 'FJD_ELSTEER_STATUS') {
          discretParams.push(seriesName)
        }

        labels.push(seriesName)

        if(visibilitySeries.includes(itemIndex + 1)) {
          counterSeries++
        }

        if(counterSeries === 1) {
          mainData = getMax(it.value)
        }

        if(counterSeries > 2) {
          let maxData = getMax(it.value)

          if(maxData && mainData) {dataCoefIndex = maxData / mainData}
        }

        dataCoefs[seriesName] = dataCoefIndex || 1

        if(counterSeries === 2) {
          series[seriesName] = {

            // @ts-expect-error
            axis: 'y2',
          }
        } else {
          series[seriesName] = {

            // @ts-expect-error
            axis: 'y',
          }
        }

        labelsAll.push(seriesName)

        
        if(!graphicColor.length) {
          // График СКПР прозрачный
          newColors.push(it.type === C_SKRP_STATUS_NAME ? 'rgba(0, 0, 0, 0)' : colors[itemIndex % colors.length])
        }
      })

      
      let lastValues: (number|null)[] = []

      
      let lastMode: string | undefined

      
      const getMode = (time: number): string | undefined =>
        Object.entries(report[0].background).find(([, { duration }]) => Boolean(duration.find(D => time >= D.beg && time <= D.end)))?.[0]


      
      report[0].time.forEach((item, index) => {
        let itemParams: number[] = [item]

        // Reset last values when mode changed
        let mode: string | undefined

        try {
          mode = getMode(item)
        } catch(e) {
          // TODO: ошибка входных данных надо как-то уведомлять
        }

        
        if(lastMode !== mode) {
          lastValues = []
          lastMode = mode
        }

        reportParams.forEach((it, itemIndex) => {
          if(it.value[index] == null) {
            // @ts-expect-error
            itemParams.push(null)
          } else {
            // @ts-expect-error
            lastValues[itemIndex] = pipe(

              // @ts-expect-error
              append(it.value[index]),

              // in 1 case - no rolling average
              
              takeLast(rollAvg[itemIndex] || 1),

              
            )(lastValues[itemIndex] || [])

            // @ts-expect-error
            itemParams.push(average(lastValues[itemIndex]) / dataCoefs[labelsAll[itemIndex + 1]],
            )
          }
        })

        data.push(itemParams)
      })

      if(!newColors.length) {
        newColors = graphicColor
      }

      
      params.newColors = newColors

      
      params.labels = labels

      
      params.series = series

      
      params.dataCoefs = dataCoefs

      
      params.discretParams = discretParams


      setGraphicColor(newColors)
      setData(data)
      setParamsData(params)
    }
  }

  return (
    <>
      <ToExcel
        data={data}
        machineName={machineName}
        params={paramsData}
        reportData={reportParams}
        reportBackground={report[0].background}
        reportFrom={reportFrom}
        reportTo={reportTo}
        showedParamsIds={visibilitySeries}
        t={t}
      />

      {report[0].time.length
        ? <div>
          <TooltipD

              
            text={t('Work performance graph')}
            style={{ left: '-15px' }}
          />

          <DygraphComponent onlySKRP={SKRPStatusIndex !== -1 && visibilitySeries.length === 1}
            id="graphic-dygraph"
            ref={graph => graphicRef.current = graph}
          />

          <div className="watch__control">
            <div className="watch__control-top">
              <p>
                {t('Select graphics')} (
                {visibilitySeries.length}/{reportParams.length})
              </p>

              <TooltipD

                  
                text={t(
                  'The choice of parameters for display on the graph (with the ability to change color)',
                )}
                style={{ left: '-15px' }}
              />
            </div>

            <div className="watch__control-data">
              {reportParams.map((item, index) => {
                if(item.value.some(Boolean) || item.type === C_SKRP_STATUS_NAME && item.value.some(val => val !== null)) {
                  return <ItemParam
                    colors={colors}

                      
                    graphicColor={graphicColor}
                    hideColorPicker={item.type === C_SKRP_STATUS_NAME}
                    index={index}

                    isAllowAvg={
                      item.type.indexOf('ON_') !== 0
                        && !['CURR_TRANSMISSION', 'TRANSMISSION', C_SKRP_STATUS_NAME, 'FJD_ELSTEER_STATUS'].includes(item.type)
                    }

                    key={item.type}
                    measure={item.measure}
                    name={item.brName}

                    /*
                      //@ts-expect-error*/
                    updateColor={updateColor}
                    updateGraphic={uploadGraphic}
                    visibilitySeries={visibilitySeries}
                  />
                }

                return null
              })}
            </div>
          </div>
        </div>

        : <div
          style={{
            display       : 'flex',
            justifyContent: 'center',
            padding       : '20px',
          }}
        >
          <p>{t('not_of_data')}</p>
        </div>
      }
    </>
  )
}

const DygraphComponent = styled.div<{onlySKRP: boolean}>`
  //Если отмечен только СКРП - фиксируем легенду в нижней части поля
  .dygraph-legend {
    top: ${({ onlySKRP }) => onlySKRP ? '316px !important' : 'unset'};
  }
`
