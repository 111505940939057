import { action, makeObservable, observable } from 'mobx'
import HouseholdStore from 'stores/Households/HouseholdStore'

class HouseholdsStore {
  @observable isLoading = false
  @observable map: {[key in string]: HouseholdStore} = {}
  @observable openedHouseholdId?: number

  //@ts-expect-error
  isOpen(id) {
    return this.openedHouseholdId === id
  }

  constructor() {
    makeObservable(this)
  }

  //@ts-expect-error
  getHousehold(id) {
    if(!this.map[id]) {
      this.map[id] = new HouseholdStore()
    } else {
      this.map[id].toggle()
    }

    return this.map[id]
  }

  @action.bound

  //@ts-expect-error
  toggle(id) {
    this.openedHouseholdId = this.openedHouseholdId === id ? undefined : id
  }
}

export default () => {
  const newObj = new HouseholdsStore()

  return new Proxy(newObj, {

    //@ts-expect-error
    get(target, prop, receiver) {
      //@ts-expect-error
      if(target.hasOwnProperty(prop) || target[prop] !== undefined) {
        //@ts-expect-error
        return target[prop]
      }

      if(String(Number(prop)) === prop) {
        return target.getHousehold(prop)
      }
    },
  })
}