import {TProps as TWidgetProps, Widget} from 'components/Widget'
import { Header } from 'components/Widget/Header'
import { observer } from 'mobx-react'
import { propOr } from 'ramda'
import React from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { fuelSchema } from 'utils'
import { TUseWidgetResizeProps, useWidgetResize } from '../common/hooks/useWidgetResize'
import { NotAvailableMessage } from '../common/NotAvailableMessage'
import NoteMessage from '../common/NoteMessage'
import GraphBar from './GraphBar'
import RowGraphBar from './RowGraphBar'

const C_GRAPH_FIELDS = ['INC_SUMM_FUEL_COMB', 'INC_SUMM_FUEL_MOVE', 'INC_SUMM_FUEL_STOP'] as const

type TProps = {
  className: string
  onClose: () => void
  config: {
    isResizable: boolean
  }
} & TWidgetProps & TUseWidgetResizeProps

function FuelWidget({ className, changeConfig, config, onClose, ...restProps }: TProps) {
  const { isCompact, toggleSize } = useWidgetResize(config, changeConfig)
  const t = useTranslate()
  const summary = getStore('summary')
  const total: number = propOr(0, 'INC_SUMM_FUEL', summary.value)
  const parametersData = summary.value

  const graphsData = C_GRAPH_FIELDS.map(field => ({
    caption: t(fuelSchema[field].titleForTranslate),
    color  : fuelSchema[field].color,
    hint   : parametersData[field] ? `${parametersData[field]?.toLocaleString()} ${t('l')}` : undefined,
    max    : total,
    value  : parametersData[field],
  }))

  return (
    <Widget
      {...restProps}
      className={className}
      notAvailable={summary.dataLoadingError}
    >
      <Header
        counter={summary.dataLoadingError ? '' : `${total.toLocaleString()} ${t('l')}`}
        onClose={onClose}
        {...isCompact ? {} : { subtitle: t('Total fuel spent on all available units') }}
        title={t('fuel')}
        isCompact={isCompact}
        isResizable={config.isResizable}
        toggleSize={toggleSize}
      />

      {summary.dataLoadingError
        ? <NotAvailableMessage text={t('service is not available')}/>
        : <>
          {isCompact && <GraphBarContainerCompact>
            {graphsData.map(graphData => <RowGraphBar key={graphData.caption} {...graphData} />)}
          </GraphBarContainerCompact>}

          {!isCompact && <>
            <GraphBarContainer>
              {graphsData.map(graphData => <GraphBarStyled
                className={className}
                key={graphData.caption}
                {...graphData}
              />)}
            </GraphBarContainer>

            <NoteMessage />
          </>}
        </>}
    </Widget>
  )
}

export default observer(FuelWidget)

const GraphBarContainerCompact = styled.div`
  height: 80px;
  padding: 12px;

  display:flex;
  width: 100%;

  & :first-child {
    border-radius: 2px 0 0 2px;
  }

  & :last-child {
    border-radius: 0 2px 2px 0;
  }

`

const GraphBarContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 19px;
`

const GraphBarStyled = styled(GraphBar)`
  margin-left: 16px;
  width: 33%
`
