import { groupBy, prop, propOr } from 'ramda'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { WidgetsGalleryIcon } from './icons'
import Preview from './Preview'
import Switcher from './Switcher'
import widgetsConfig from './widgets.config'

type TProps = {
  layout: number
  resetLayout: (layout: number) => void
  layoutMode: number | null
  toggle: () => void
  isShown: boolean
  toggleDrag: (state: boolean) => void
}

export default function WidgetsGallery({ layout, resetLayout, layoutMode, toggle, isShown, toggleDrag }: TProps) {
  /*@ts-expect-error*/
  const counts = useMemo(() => groupBy(prop('widget'), layout), [layout])
  const t = useTranslate()

  return (
    <RootElement isShown={isShown}>
      <WidgetsGalleryIconStyled
        active={isShown}
        onClick={toggle}
      />

      <CSSTransition
        in={isShown}
        classNames="slideup"
        timeout={300}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <TopBar isShown={isShown}>
          <>
            <SidePart><Title>{t('Widgets')}</Title></SidePart>
            <CenterPart />
            <SidePart><Switcher onChange={resetLayout} layout={layoutMode} /></SidePart>
          </>
        </TopBar>
      </CSSTransition>

      <CSSTransition
        in={isShown}
        classNames="slideup"
        timeout={300}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <Scrollable>{widgetsConfig.map(W => <Preview
          key={W.id}
          widgetId={W.id}

          /*@ts-expect-error*/
          count={propOr([], W.id, counts).length}
          isMultiple={W.isMultiple}
          defaultSize={W.defaultSize}
          title={t(W.title)}
          Preview={W.Preview}
          toggleDrag={toggleDrag}
        />)}
        </Scrollable>
      </CSSTransition>
    </RootElement>
  )
}

const RootElement = styled.div<{ isShown: boolean }>`
  width: 100%;

  top: calc(100% - ${ ({ isShown }) => isShown ? '307px' : '29px' });
  height: ${ ({ isShown }) => isShown ? '307px' : '29px' };
  z-index: 2;
  position: fixed;
  transition: all 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
`

const WidgetsGalleryIconStyled = styled(WidgetsGalleryIcon)<{ active: boolean }>`
    position: fixed;
    top: ${({ active }) => active ? 'calc(100% - 324px)' : 'calc(100% - 90px)'};
    left: calc(50% - 48.5px);
    opacity: ${({ active }) => active ? '1' : '0.2'};
    transition: all 300ms cubic-bezier(0.64, 0.04, 0.35, 1);

    :hover {
      opacity: 1;
      margin-top: ${({ active }) => active ? 'inherit' : '-25px'};
    }

    z-index: 100;
`

const activeTopBar = css`
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.02), 0 1px 4px rgba(0, 0, 0, 0.01);
`

const TopBar = styled.div<{ isShown: boolean }>`
  width: 100%;
  ${ ({ isShown }) => isShown ? activeTopBar : undefined };
  height: 90px;
  display: flex;
  align-items: flex-end;

  &.slideup-exit {
  }

  &.slideup-exit-active {
  };

  &.slideup-enter {
  }

  &.slideup-enter-active {
  };
`

const Scrollable = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 20px 0 20px;
  background-color: #F5F6F8;
  height: 216px;
  padding-top: 10px;
  overflow-y: auto;
`

const CenterPart = styled.div`
  min-width: 94px;
  max-width: 94px;
  height: 91px;
  background: url("./img/svg/frame.svg") no-repeat;
  background-position: 0 32px;
`

const SidePart = styled.div`
  width: 100%;
  background-color: white;
  height: 59px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  /* Color 3 */
  color: #202020;

  margin-top: 17px;
  margin-left: 17px;
`
