import { helpers } from '@agdt/agrotronic-react-components'
import { Button, Icon, Table } from 'antd'
import React, { FC, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { changeDensity } from 'reducers/reports/actions'
import { prepareDuration } from 'ui/Map/duration'
import { DataGraphicsBarReport,GraphicsBar, GraphicsBarReport } from './GraphicsBar'

const { debounce } = helpers

type TargetType = {
  name: string
  model: string
  holding: unknown
  farm: unknown
  threshing_sensor: unknown
  data: DataGraphicsBarReport[]
}

type CleaningResultProps = {
  report: {
    title: {
      period_number: string
      time: string
      agriculture: string
      density: string
      harvest: string
      model: string
      name: string
      holding: string
      farm: string
      threshing_sensor: string
    }
    targets: Record<string, TargetType>
  }
}

type DateSourceType = {
  key: string
} & TargetType

export const CleaningResult: FC<CleaningResultProps> = ({ report }) => {
  const t = useTranslate()
  const graphic = useRef<HTMLDivElement>(null)
  const [activeGraphic, setActiveGraphic] = useState<GraphicsBarReport>()
  const stateDispatch = useDispatch()

  const showGraphic = (data: GraphicsBarReport) => {
    setActiveGraphic(data)

    if(graphic.current) {
      window.scrollTo(0, graphic.current.offsetTop - 50)
    }
  }

  const changeTextDensity = debounce(
    (parent: string, index: number, value: number) => stateDispatch(changeDensity({ parent, index, value })),
    1000,
  )

  const renderInput = (key: string) => (
    value: number | null | undefined,
    data: {volume: null | undefined
      period_number: number},
  ) => {
    return !Number.isNaN(Number(value)) ?
      <input
        type="number"
        style={{ width: 90, height: 20, textAlign: 'center' }}
        defaultValue={Number(value)}
        disabled={data.volume === null}
        onChange={e => {
          changeTextDensity(
            key,
            data.period_number,
            Number(e.target.value),
          )
        }}
      />
      :
      <p>{value}</p>
  }

  const expandedRowRender = (item: DateSourceType) => {
    const childrenColumn = [
      {
        title    : report.title.period_number,
        dataIndex: 'period_number',
        key      : 'period_number',
      },
      {
        title    : t('startHarvesting'),
        dataIndex: 'startHarvesting',
        key      : 'startHarvesting',
      },
      {
        title    : t('endHarvesting'),
        dataIndex: 'endHarvesting',
        key      : 'endHarvesting',
      },
      {
        title    : t('periodHarvesting'),
        dataIndex: 'periodHarvesting',
        key      : 'periodHarvesting',
      },
      {
        title    : report.title.time,
        dataIndex: 'time',
        key      : 'time',
      },
      {
        title    : report.title.agriculture,
        dataIndex: 'agriculture',
        key      : 'agriculture',
      },
      {
        title    : report.title.density,
        dataIndex: 'density',
        key      : 'density',
        render   : renderInput(item.key),
      },
      {
        title    : report.title.harvest,
        dataIndex: 'harvest',
        key      : 'harvest',
      },
    ]

    const childrenData = item.data.map((data, index) => {
      const dateFrom = Date.parseDateTimeFromApi(data.from)
      const dateTo = Date.parseDateTimeFromApi(data.to)

      return {
        agriculture     : data.agriculture,
        area            : data.area,
        density         : data.density,
        endHarvesting   : dateTo.format('DD.MM.YY HH:mm:ss'),
        fuel            : data.fuel,
        harvest         : data.harvest,
        key             : data.from,
        period_number   : index + 1,
        periodHarvesting: prepareDuration(dateTo.getTime() - dateFrom.getTime()),
        startHarvesting : dateFrom.format('DD.MM.YY HH:mm:ss'),
        time            : prepareDuration(data.timeHarvest),
        volume          : data.volume,
      }
    })

    return (
      <div
        style={{
          margin    : '-16px',
          borderTop : '1px solid #e8e8e8',
          borderLeft: '1px solid #e8e8e8',
        }}
      >
        <Table
          columns={childrenColumn}

          // @ts-expect-error
          dataSource={childrenData}
          pagination={false}
        />
      </div>
    )
  }

  const columns = [
    {
      title    : report.title.model,
      dataIndex: 'model',
      key      : 'model',
    },
    {
      title    : report.title.name,
      dataIndex: 'name',
      key      : 'name',
    },
    {
      title    : report.title.holding,
      dataIndex: 'holding',
      key      : 'holding',
    },
    {
      title    : report.title.farm,
      dataIndex: 'farm',
      key      : 'farm',
    },
    {
      title    : report.title.threshing_sensor,
      dataIndex: 'threshing_sensor',
      key      : 'threshing_sensor',
      render   : (threshing_sensor?: string) =>
        <div style={{ textIndent: 10 }}>
          {threshing_sensor ? <Icon type="check-circle" /> : <Icon type="close-circle" />}
        </div>,

    },
    {
      title    : '',
      dataIndex: 'actions',
      key      : 'actions',
      render   : (_: unknown, item: GraphicsBarReport) =>
        item.data.length ?
          <Button onClick={() => showGraphic(item)} style={{ float: 'right' }}>
            {t('make graphics')}
          </Button>
          : null,
    },
  ]

  const dataSource = Object.keys(report.targets).reduce<DateSourceType[]>((result, item) => {
    const { name, model, holding, farm, threshing_sensor, data } = report.targets[item]

    result.push({
      key: item,
      name,
      model,
      holding,
      farm,
      threshing_sensor,
      data,
    })

    return result
  }, [])

  return (
    <div className="watch__full">
      <div ref={graphic}>
        {activeGraphic ? <GraphicsBar report={activeGraphic} t={t} titles={report.title} /> : null}
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        expandedRowRender={expandedRowRender}
      />
    </div>
  )
}
