import { Spinner } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import { isEmpty } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushReport, pushTime,
  stopLoadReport } from '../../reducers/reports/actions'
import { AnalyticGraphicBar } from '../../ui/Analytics/graphicBar'
import FilterControl, { FilterControlConfig } from '../../ui/Filter/filterControl'
import InitFilter from '../../ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from './common/WithNoItemsForReportPlaceholder'

const ButtonGroup = Button.Group

const Time: FC<FilterControlConfig> = props => {
  const translate = useTranslate()
  const stateDispatch = useDispatch()
  const filterIsEmpty = isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items)
  const [showDetailInfo, setShowDetailInfo] = useState(false)

  const report = useSelector((state: AppStateType) => state.reports.get('time'))

  //@ts-expect-error
  const detailLoadSize = report.get('detailLoad').size

  //@ts-expect-error
  const resultLoadSize = report.get('resultLoad').size

  //@ts-expect-error
  const onStopReport = report.get('onStopReport')

  //@ts-expect-error
  const error = report.get('error')

  const finishLoadReportAction = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  const onHideDetailInfo = () => {
    setShowDetailInfo(false)
  }

  const onShowDetailInfo = () => {
    setShowDetailInfo(true)
  }

  useEffect(() => {
    if(resultLoadSize >= detailLoadSize) {
      finishLoadReportAction(ReportType.Time)
    }
  }, [detailLoadSize, finishLoadReportAction, resultLoadSize])

  useEffect(() => {
    if(onStopReport) {
      finishLoadReportAction(ReportType.Time)
    }
  }, [finishLoadReportAction, onStopReport])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={translate('Select a different report or change the filtering conditions')}
        title={translate('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          disableButton={detailLoadSize !== resultLoadSize}
          translate={translate}
          {...props}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!onStopReport && detailLoadSize !== resultLoadSize &&
            <Spinner />
          }
        </div>
        {error &&
          <p style={{ color: 'red', textAlign: 'center' }}>
            {error}
          </p>
        }
        {/* @ts-expect-error */}
        {!onStopReport && report.getIn(['time', 0])?.length &&
          <>
            <div className="buttonWrap-watch">
              {/* @ts-expect-error */}
              {report.get('time').size &&
                <ButtonGroup>
                  <Button
                    className={!showDetailInfo ? 'active' : ''}
                    onClick={onHideDetailInfo}
                  >
                    {translate('By mode')}
                  </Button>
                  <Button
                    className={showDetailInfo ? 'active' : ''}
                    onClick={onShowDetailInfo}
                  >
                    {translate('By days')}
                  </Button>
                </ButtonGroup>
              }
            </div>
            <AnalyticGraphicBar
              key={!showDetailInfo ? 'sum' : 'detail'}
              sumData={!showDetailInfo}
              title={
                !showDetailInfo
                  ? translate('Analysis of working time by mode')
                  : translate('Analysis of working time by day')
              }
              tooltip={
                !showDetailInfo
                  ? translate('Graph of the analysis of working time by group / unit of equipment for the selected period')
                  : translate('Graph of the analysis of working time for the group / unit of equipment for the selected period It is possible to turn on / off the display of modes')
              }

              /* @ts-expect-error */
              report={report.getIn(['time', 0])}
            />
          </>
        }
        {/* @ts-expect-error */}
        {report.getIn(['time', 0]) && !report.getIn(['time', 0]).length &&
          <p style={{ color: 'red', textAlign: 'center' }}>
            {translate('not_of_data')}
          </p>
        }
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      id      : 0,
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      result  : [],
      items   : [],
      validate: true,
    },
    {
      id      : 2,
      type    : 'date',
      section : 'time',
      name    : 'select_a_date',
      validate: true,
      result  : [],
    },
  ],
  callback: data => dispatch => {
    data.callbackOk = (json: unknown[]) => {
      dispatch(
        pushReport({ data: json, type: ReportType.Time, resultLoad: [] }),
      )
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.Time))
      dispatch(errorReport(ReportType.Time))
    }

    dispatch(clearReport(ReportType.Time))
    pushTime(dispatch, data, ReportType.Time)
  },
  clear: () => dispatch => dispatch(clearReport(ReportType.Time)),

  //@ts-expect-error
})(Time)

