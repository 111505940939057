import Tippy, { TippyProps } from '@tippyjs/react'
import { icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { TTooltipDStyle } from 'types'

type TState = {
  settings: {
    help: boolean
  }
}

export type TProps = {
  help?: boolean
  style: TTooltipDStyle
  text: string
} & Pick<TippyProps, 'appendTo'>


const TooltipD = ({ appendTo, help, style, text }: TProps) => <span className="tooltipD">
  {help && <span
    className="tooltipD__icon"
    style={style}
  >
    <StyledTippy
      appendTo={appendTo}
      content={text}
      placement='right'
    >
      <div>
        <icons.HelpIcon color='#f1c340' size='15px' />
      </div>
    </StyledTippy>
  </span>}
</span>

export default connect((state: TState) => ({
  help: state.settings.help,
}))(TooltipD)

const StyledTippy = styled(Tippy)`
  .tippy-content {
    min-width: ${({ appendTo }) => appendTo === 'parent' ? '250px' : '50px'};
    max-width: 250px;
    padding: 6px 8px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`
