import { formats } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import moment from 'moment'
import { prop, sortBy, values } from 'ramda'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushAgroOperationReport,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppDispatch, AppStateType } from 'reducers/store'
import FilterControl, { FilterControlConfig } from 'ui/Filter/filterControl'
import InitFilter from 'ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { exportSheet } from '../result/common/exportSheet'
import { headerTableDetail, headerTableSummary } from './constants'
import { FilterResult } from './Result'

const ButtonGroup = Button.Group

const styles = {
  button: {
    background: '#78C800',
    border    : '1px solid #78C800',
  },
  buttonWrap: {
    marginTop   : '20px',
    marginBottom: '0',
  },
}

type NormalizeReportType = {
  operations: Record<string, string>[]
  summary: Record<string, string>[]
}

type ItemType = {
  duration?: number
  count: number
  event: string
  beg: string
  end: string
  lat: number
  lon: number
}

const AgroOperations: FC<FilterControlConfig> = props => {
  const { filter } = props
  const translate = useTranslate()
  const [showDetailInfo, setShowDetailInfo] = useState(false)
  const stateDispatch = useDispatch()

  //@ts-expect-error
  const report = useSelector((state: AppStateType) => state.reports.get('agrooperations').get('agrooperations').toJS())

  //@ts-expect-error
  const request = useSelector((state: AppStateType) => state.reports.get('agrooperations').get('request'))

  //@ts-expect-error
  const detailLoad = useSelector((state: AppStateType) => state.reports.get('agrooperations').get('detailLoad').toJS())

  //@ts-expect-error
  const resultLoad = useSelector((state: AppStateType) => state.reports.get('agrooperations').get('resultLoad').toJS())

  const endLoad = detailLoad.length === resultLoad.length

  const prevResultLoadLength = useRef(resultLoad.length)

  const onFinishLoadReport = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  const getNormalizeReport = (): NormalizeReportType => {
    const source = report[0]

    if(!source) {
      return { operations: [], summary: [] }
    }

    return Object.keys(source).reduce((acc: NormalizeReportType, imei) => {
      if(source[imei] && source[imei].summary) {
        const tempSummary = source[imei].summary
          .filter((item: ItemType) => item?.duration)
          .map((item: ItemType) => ({
            type : source[imei].type,
            name : source[imei].name,
            event: item.event,

            //duration приходит в секундах, но требуется значение в миллисекундах
            duration: (item?.duration || 0) * 1000,
            count   : item.count,
          }))

        acc.summary = [...acc.summary, ...tempSummary]
      }

      if(source[imei] && source[imei].operations) {
        const tempOperation = source[imei].operations
          .filter((item: ItemType) => item)
          .map((item: ItemType) => {
            const end = formats.api.parseDateTimeFromApi(item.end)
            const beg = formats.api.parseDateTimeFromApi(item.beg)

            return {
              beg,
              begDay: beg,
              coords: {
                lat: item.lat,
                lon: item.lon,
              },

              duration: end.getTime() - beg.getTime(),
              end,
              event   : item.event,
              name    : source[imei].name,
              type    : source[imei].type,
            }})

        acc.operations = [...acc.operations, ...tempOperation]
      }

      return acc
    }, { operations: [], summary: [] })
  }

  const normalizeReport = getNormalizeReport()

  const handleSetSummary = () => {
    setShowDetailInfo(false)
  }

  const handleSetDetail = () => {
    setShowDetailInfo(true)
  }

  const exportToExcel = () => {
    const report = normalizeReport[showDetailInfo ? 'operations' : 'summary']

    const from = moment(request.from)
    const to = moment(request.to)

    const columns = sortBy(prop('index'), values(showDetailInfo ? headerTableDetail : headerTableSummary))

    exportSheet(
      [
        [translate('A final report agricultural operations')],
        [`${from.format('DD.MM.YY HH:mm:ss')} - ${to.format('DD.MM.YY HH:mm:ss')}`],
        [],
        columns
          .map(column => translate(column.name))]
        .concat(report.map((row: Record<string, string>) =>
          columns.map(({ id, formatter }) => formatter ? formatter(row[id], translate) : row[id]),
        )),
      'operations_report',
    )
  }

  useEffect(()=> {
    if(resultLoad.length !== prevResultLoadLength.current && resultLoad.length >= detailLoad.length) {
      onFinishLoadReport(ReportType.AgroOperations)
    }

    prevResultLoadLength.current = resultLoad.length
  }, [resultLoad, detailLoad, onFinishLoadReport])

  useEffect(() => {
    return () => {
      onFinishLoadReport(ReportType.AgroOperations)
    }
  }, [onFinishLoadReport])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={!Boolean(filter.find(item => item.type === 'checkbox-machine')?.items?.length)}
        text={translate('Select a different report or change the filtering conditions')}
        title={translate('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          disableButton={!endLoad}
          translate={translate}
          {...props}
        />

        {endLoad &&
          <div
            className="buttonWrap-watch"
            style={styles.buttonWrap}
          >
            {Boolean(report.length) &&
              <ButtonGroup>
                <Button
                  className={!showDetailInfo ? 'active' : ''}
                  onClick={handleSetSummary}
                >
                  {translate('summary')}
                </Button>

                <Button
                  className={showDetailInfo ? 'active' : ''}
                  onClick={handleSetDetail}
                >
                  {translate('detail')}
                </Button>

                <Button
                  type="primary"
                  icon="download"
                  style={styles.button}
                  onClick={exportToExcel}
                >
                  {translate('download excel')}
                </Button>
              </ButtonGroup>
            }
          </div>
        }
        <FilterResult
          report={normalizeReport[showDetailInfo ? 'operations' : 'summary']}
          translate={translate}
          showSpin={!endLoad}
          showDetailInfo={showDetailInfo}
        />

      </WithNoItemsForReportPlaceholder>
    </div>
  )
}

export default InitFilter({
  default: [
    {
      id      : 0,
      items   : [],
      name    : 'machine',
      result  : [],
      section : 'machine',
      type    : 'checkbox-machine',
      validate: true,
    },
    {
      id     : 3,
      name   : 'params_of_report',
      section: 'operations',
      type   : 'checkbox',

      //@ts-expect-error
      items : ['M0', 'M1', 'M2', 'M3', 'M4', 'M5', 'ON_100', 'ON_UPLOAD'],
      result: [],
    },
    {
      id      : 2,
      type    : 'date',
      name    : 'select_a_date',
      section : 'time',
      result  : [],
      validate: true,
    },
  ],

  callback: data => (dispatch: AppDispatch) => {
    data.callbackOk = (json: unknown[]) => {
      dispatch(
        pushReport({ data: json, type: ReportType.AgroOperations, resultLoad: [] }),
      )
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.AgroOperations))
      dispatch(errorReport(ReportType.AgroOperations))
    }

    dispatch(clearReport(ReportType.AgroOperations))
    pushAgroOperationReport(dispatch, data, ReportType.AgroOperations)
  },

  clear: () => (dispatch: AppDispatch) => {
    dispatch(clearReport(ReportType.AgroOperations))
  },

  //@ts-expect-error
})(AgroOperations)
