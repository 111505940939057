import Tippy from '@tippyjs/react'
import { WatchButton } from 'components/WatchButton'
import L from 'leaflet'
import { observer } from 'mobx-react'
import moment from 'moment'
import { isEmpty } from 'ramda'
import React, { FC, useCallback } from 'react'
import { Map } from 'react-leaflet'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate, TUserUnitData } from 'types'
import TooltipD from 'ui/Tooltip'
import { InformationPanel } from '../informationPanel'
import ControlTime from './ControlTime'
import { ControlTrack } from './ControlTrack'
import MarkersPanel from './MarkersPanel'

const styles = {
  tooltip1: { left: '-20px', top: '-8px' },
  tooltip2: { right: '-24px', top: '-25px' },
}

type ItemMachineProps = {
  imei: number
  mapRef: Map
  translate: TTranslate
  typeName: string
  name: string
  data: TUserUnitData
  id: number
  model: string
}

const ItemMachine: FC<ItemMachineProps> = ({
  imei,
  mapRef,
  typeName,
  name,
  data,
  id,
  model,
  translate,
}) => {
  const householdUnitsStore = getStore('householdUnits')
  const householdUnit = householdUnitsStore.getUnit(imei)

  const switchTypeTrack = useCallback((param: {type: string, id: number}) => {
    if(householdUnit.track.type !== param.type) {
      //@ts-expect-error
      for(let i in mapRef.leafletElement._layers) {
        //@ts-expect-error
        if(mapRef.leafletElement._layers[i].options.snakingSpeed) {
          //@ts-expect-error
          mapRef.leafletElement.removeLayer(mapRef.leafletElement._layers[i])
        }
      }

      householdUnit.track.setType(param.type)
    }
  }, [])

  const handleDeleteTrack = useCallback(() => {
    householdUnitsStore.deleteTrackArrow()
    householdUnit.deleteTrack()
  }, [])

  const centerTrack = useCallback(() => {
    const type: string = householdUnit.track.type
    const activeTrack = householdUnit.track.activeTrack

    if(activeTrack[type]) {
      const arrayPoint = activeTrack[type].reduce((list: [number, number][], item) => {
        item.path.forEach(it => {
          if(it.lat && it.lng) {
            list.push([it.lat, it.lng])
          }
        })

        return list
      }, [])

      if(L.latLngBounds(arrayPoint)) {
        mapRef.leafletElement.flyToBounds(L.latLngBounds(arrayPoint))
      }
    }
  }, [householdUnit.track])

  const onClickOpenTrack = useCallback(() => {
    getStore('householdUnits').getUnit(imei).togglePanel('openSettingsTrack')
  }, [])

  return householdUnitsStore && <div className="tracking-machine">
    <div className="tracking-machine__data">
      <InformationPanel
        data={data}
        id={id}
        model={model}
        name={name}
        translate={translate}
        typeName={typeName}
      />

      <ButtonsWrapper>
        <Tippy
          content={translate('Track building')}
          placement="top-end"
        >
          <div
            onClick={onClickOpenTrack}
            className={`tracking-machine__open-track ${
              householdUnit.openedTrackPanels.has('openSettingsTrack') ? 'active' : ''
            }`}
          />
        </Tippy>

        <Tippy
          disabled={!data?.LATITUDE?.value || !data?.LONGITUDE?.value}
          content={translate('Track')}
          placement='top-end'
        >
          <div>
            <WatchButton
              disabled={!data?.LATITUDE?.value || !data?.LONGITUDE?.value}
              errorText={translate('View location is not available, the machine has not yet sent the coordinates')}
              handleWatch={householdUnit.toggleWatch}
              isActive={householdUnit.isWatched}
            />
          </div>
        </Tippy>

        <TooltipD
          text={translate('Enable / Disable the display of technology on the map')}
          style={styles.tooltip2}
        />
      </ButtonsWrapper>
    </div>

    {householdUnit.openedTrackPanels.has('openSettingsTrack') &&
      <div>
        {(!householdUnit.track.timeRange
          || isEmpty(householdUnit.track.activeTrack)
          || householdUnit.track.isLoading
        ) && <ControlTime imei={imei} />
        }

        {!isEmpty(householdUnit.track.activeTrack) && <>
          <div className="tracking-machine__time">
            <p>
              {moment(householdUnit.track?.timeRange?.from).format('DD.MM.YY HH:mm')}{' '}
              -{' '}
              {moment(householdUnit.track?.timeRange?.to).format('DD.MM.YY HH:mm')}
            </p>
          </div>

          <div className="tracking-machine__button-control">
            <ControlTrack
              deleteTrack={handleDeleteTrack}
              id={id}
              model={model}
              showTypeHarvest={Boolean(householdUnit.track.activeTrack.TRACK_HARVEST)}
              showTypeLoad={Boolean(householdUnit.track.activeTrack.TRACK_LOAD)}
              showTypeProductivity={Boolean(householdUnit.track.activeTrack[`TRACK_PRODUCTIVITY_${model}`])}
              showTypeSpeed={Boolean(householdUnit.track.activeTrack.TRACK_SPEED)}
              switchTypeTrack={switchTypeTrack}
              translate={translate}
              type={householdUnit.track.type}
            />
          </div>

          <MarkersPanel
            centerTrack={centerTrack}
            imei={imei}
            translate={translate}
          />
        </>
        }
      </div>
    }
  </div>
}

export default observer(ItemMachine)

const ButtonsWrapper = styled.div`
  max-width: 85px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 3px;
`
