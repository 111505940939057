import { is } from 'ramda'
import XLSX from 'xlsx'

export const exportSheet = (data: unknown[][], fileName = 'farms', merges: unknown[] = []) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)
  ws['!merges'] = []

  merges.map(M => ws['!merges']?.push(is(Object, M) ? M : XLSX.utils.decode_range(String(M))))

  XLSX.utils.book_append_sheet(wb, ws, fileName)
  XLSX.writeFile(wb, `${fileName}.xls`)
}
