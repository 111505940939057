import { Button, constants, icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { C_ERROR_STATUSES, TErrorStatus } from '../../../../constants'

const C_STATUSES_MAP = {
  [C_ERROR_STATUSES.CHANGED]: {
    text: 'You have changed the display of machine data',
  },

  [C_ERROR_STATUSES.IS_EMPTY]: {
    text: 'Select at least one machine to build a field yield report',
  },
}

type TProps = {
  getGeoZonProductivity: () => void
  status: TErrorStatus
  t: TTranslate
}

export const Info = ({
  getGeoZonProductivity,
  status,
  t,
}: TProps) => {
  return <>
    <InfoWrapper>
      <IconWrapper>
        <icons.InfoIcon color={constants.colors.icons.PRIMARY} size={20}/>
      </IconWrapper>

      {t(C_STATUSES_MAP[status].text)}
    </InfoWrapper>

    {status === 'CHANGED' && <ButtonStyled onClick={getGeoZonProductivity} variant="border" width="119px">
      <icons.ReplayIcon color={constants.colors.icons.PRIMARY} size={24}/>
      <RightIconText>{t('Refresh')}</RightIconText>
    </ButtonStyled>}
  </>
}

const InfoWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  color: ${constants.colors.text.PRIMARY};
  display: flex;
  font-size: 14px;
  gap: 8px;
  margin-top: 20px
  padding: 8px 12px;;
`

const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`

const RightIconText = styled.span`
  padding-left: 8px;
`

const ButtonStyled = styled(Button)`
  margin: 16px 0;
  padding: 5px;
  min-height: 32px;
`
