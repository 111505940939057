import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { useTranslate } from 'react-redux-multilingual'
import { TTranslate } from 'types'
import { loginValidators } from '../../pages/Profile/Info/common/validators'
import TooltipD from '../Tooltip'
import { Plus } from './filterControl'

const styles = {
  tooltip: { bottom: '0', right: '0' },
}

type ControlCheckboxProps = {
  result: unknown[]
  section: string
  item: string
  label: string[]
  index: number
  color: boolean
  notTranslating: boolean
  translate: TTranslate
  onStartFilter: (section: string, item: string) => void
}

const ControlCheckbox: FC<ControlCheckboxProps> = ({
  result,
  section,
  label,
  index,
  color,
  notTranslating,
  item,
  translate,
  onStartFilter,
}) => {
  const [checked, setChecked] = useState(result.some(element => element === item))

  const changeChecked = () => {
    setChecked(!checked)
    onStartFilter(section, item)
  }

  return (
    <div className={`filter-parameter__item ${color ? 'color' : ''}`}>
      <input
        type='checkbox'
        id={`${index}`}
        checked={checked}
        onChange={changeChecked}
      />
      <label htmlFor={`${index}`}>{notTranslating ? label[index] : translate(label[index])}</label>
    </div>
  )
}


type FilterCheckboxProps = {
  notTranslating: boolean
  result: unknown[]
  labels?: string[]
  section: string
  colors?: string[]
  search: boolean
  name: string
  items: string[]
  onStartFilter: (section: string, item: string) => void
}

export const FilterCheckbox: FC<FilterCheckboxProps> = ({
  notTranslating,
  result,
  labels,
  section,
  colors,
  name,
  search,
  items,
  onStartFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [searchList, setSearchList] = useState<string[]>([])
  const translate = useTranslate()
  const rootRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if(open && (!rootRef.current || !rootRef.current.contains(event.target as Node))) {
      setOpen(false)
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(value)

    if(value) {
      setSearchList(items.filter(item => item.toLowerCase().includes(value.toLowerCase())))
    }
  }

  const rendeControlCheckboxs = (elements: string[]) => {
    return (
      elements.map((item, index) =>
        <ControlCheckbox
          color={Boolean(colors?.[index])}
          key={section + index}
          item={item}
          index={index}
          label={labels || elements}
          result={result}
          section={section}
          translate={translate}
          notTranslating={notTranslating}
          onStartFilter={onStartFilter}
        />,
      )
    )
  }

  const renderWrappedCheckboxs = () => {
    if(searchString && searchList.length) {
      return (
        <div style={{ marginTop: '10px' }}>
          {rendeControlCheckboxs(searchList)}
        </div>
      )
    }

    if(searchString && !searchList.length) {
      return <p style={{ marginBottom: '-10px' }}>{translate('not_of_data')}</p>
    }

    return rendeControlCheckboxs(items)
  }

  return (
    <div className="filter-parameter">
      <p
        tabIndex={0}
        role="button"
        onClick={toggleOpen}
      >
        <span>{name}</span>
        <Plus/>
      </p>
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {open &&
          <div
            className="filter-parameter__open multy"
            ref={rootRef}
          >
            {search &&
              <div className="filter-parameter__wrap-top">
                <input
                  className="filter-parameter__input"
                  type="text"
                  placeholder={translate('search')}
                  value={searchString}
                  onChange={onSearch}
                />
              </div>}
            {renderWrappedCheckboxs()}
          </div>
        }
      </ReactCSSTransitionGroup>
      <TooltipD text={translate('Select one or more values')} style={styles.tooltip}/>
    </div>
  )
}
