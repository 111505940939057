import { Spinner } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useRef } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps } from 'react-virtualized'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { Icons } from 'ui/Machines/icons'
import { scrollStyleMixin } from 'utils/styles'
import NotificationBadges from './NotificationBadges'

const Machines: FC = () => {
  const store = getStore('notices')
  const machines = store.units.list.sortedItems
  const listRef = useRef<List | null>(null)
  const t = useTranslate()

  const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight : 80,
  })

  useEffect(() => {
    if(listRef && listRef.current) {
      cellMeasurerCache.clearAll()
      listRef.current.recomputeRowHeights()
    }
  })

  useEffect(() => {
    if(!store.selectedUnitImei && machines.length > 0) {
      store.selectUnit(machines[0].imei)
    }
  }, [store.selectedUnitImei, machines])

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const machine = machines[index]
    const selected = store.selectedUnitImei === machine.imei

    const { imei, name, subtype, sumNotifications, typeName } = machine
    const icon = Icons[subtype]?.(undefined, 31) || Icons.NINJA(undefined, 31)

    const handleSelectMachine = () => {
      if(!selected) {
        store.selectUnit(imei)
      }
    }

    return (
      <CellMeasurer
        cache={cellMeasurerCache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {() =>
          <MachineContainerStyled
            style={style}
            key={imei}
            selected={selected}
            onClick={handleSelectMachine}
          >
            <MachineIconStyled>
              {icon}
            </MachineIconStyled>
            <MachineNameContainerStyled>
              <MachineModelStyled selected={selected}>
                {typeName}
              </MachineModelStyled>
              <MachineNameStyled selected={selected}>
                {name}
              </MachineNameStyled>
            </MachineNameContainerStyled>
            <NotificationBadges notifications={sumNotifications} translate={t}/>
          </MachineContainerStyled>
        }
      </CellMeasurer>
    )
  }

  return (
    <MachinesContainerStyled>
      {store.isLoading && !machines.length || store.units.isLoading ?
        <SpinnerContainer><Spinner color="#D10B41"/></SpinnerContainer>
        :
        <AutoSizer>
          {({ width, height }) =>
            <ListStyled
              deferredMeasurementCache={cellMeasurerCache}
              ref={listRef}
              rowCount={machines.length}
              rowHeight={cellMeasurerCache.rowHeight}
              rowRenderer={rowRenderer}
              width={width}
              height={height}
            />
          }
        </AutoSizer>
      }
    </MachinesContainerStyled>
  )
}

const ListStyled = styled(List)`
  ${scrollStyleMixin}
`

const MachinesContainerStyled = styled.div`
  height: calc(100vh - 197px);
`

const SpinnerContainer = styled.div`
  display: flex;
  margin: 20px;
  justify-content: center;
`

const MachineContainerStyled = styled.div<{selected: boolean}>`
  width: 100%;
  border-bottom: 1px solid #e2e5ec;
  display: flex;
  cursor: pointer;
  padding: 0 25px;
  align-items: center;
  min-height: 80px;
  background-color: ${({ selected }) =>
    selected ? 'rgba(209, 0, 41, 0.12)' : 'transparent'};

  :hover {
    background-color: ${({ selected }) =>
    selected ? 'rgba(209, 0, 41, 0.12)' : '#f5f5f5'};
  }
`

const MachineIconStyled = styled.div`
  width: 31px;
  height: 31px;
  display: flex;
  margin-right: 14px;
`

const MachineNameContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-right: 14px;
`

const MachineModelStyled = styled.span<{selected: boolean}>`
  color: ${({ selected }) => selected ? '#D10029' : '#202020'};
  font-size: 12px;
  line-height: 16px;
`

const MachineNameStyled = styled.span<{selected: boolean}>`
  color: ${({ selected }) => selected ? '#D10029' : '#a1a1a1'};
  font-size: 10px;
  line-height: 14px;
`

export default observer(Machines)
