import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { TDetails } from 'stores/GeoZones'
import { TTranslate } from 'types'
import {params, TParam} from '../index'
import { LANG_RU } from '../../../../../../../constants/lang'

type TProps = {
  farmUnits: string[]
  from: number | null
  geoZoneName: string
  login: string
  report: Partial<TDetails>
  t: TTranslate
  to: number | null
}

export const createExcelFile = async ({ farmUnits, from, geoZoneName, login, report, t, to }: TProps) => {
  const columns = [
    {
      id   : 'geoZoneName',
      title: 'Name geoZone',
    },

    ...params,
  ] as const

  type TColumn = {
    formatter?: Function
    helpText?: string
    id: typeof columns[number]['id']
    measure?: string
    title: string
  }

  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/commonReport.xlsx`)

    //@ts-expect-error
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('B1').value = `${t('formed')}: ${ login }, ${formats.date.dateViewFormatter(createDateTime, LANG_RU)}, ${formats.date.timeFormatter(createDateTime, LANG_RU)}`

  if(from && to) {
    // eslint-disable-next-line max-len
    sheet.getCell('A2').value = `${t('Field Yield Report')} ${new Date().user.new(from).format('DD.MM.YY HH:mm:ss')} - ${new Date().user.new(to).format('DD.MM.YY HH:mm:ss')}`
  }

  // Generate column titles
  sheet.insertRow(3, [
    ...columns.map(
      (C: TColumn) => `${t(C.title)}${C.measure ? `, ${t(C.measure)}` : ''}${C.helpText ? ` (${t(C.helpText)})` : ''}`,
    ),
    t('Equipment (Equipment that was in the geofence during the specified period)'),
  ])

  const preparedReport = [
    geoZoneName,
    ...params.map((P: TParam) => P.formatter ? P.formatter(report[P.id]) : report[P.id]),
    farmUnits.join(', '),
  ]

  //resize title row for full report length
  sheet.mergeCells(1, 2, 1, preparedReport.length)

  //unmerge merged cells
  sheet.unMergeCells(2, 1, 2, 2)

  //resize title row for full report length
  sheet.mergeCells(2, 1, 2, preparedReport.length)

  // Generate report
  sheet.insertRow(4, preparedReport)

  sheet.getRow(4).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }

  // remove unused row
  sheet.spliceRows(5, 1)

  //resize footer for full report length
  sheet.mergeCells(5, 2, 5, preparedReport.length)

  //fix row size
  sheet.getColumn(1).width = 25
  sheet.getColumn(3).width = 30
  sheet.getRow(3).height = 80
  sheet.getRow(4).height = 100

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'geo_zones_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
