import React, { FC, useMemo } from 'react'
import { TStatus, TUnit } from '../../types'
import MarkerState from './markerState'

type MarkerStateWrapperProps = {
  detailPosition?: {
    lat: number
    lon: number
  }

  getDataValue: (name: string, target: unknown) => number
  hasTrack: boolean
  status?: TStatus | null
  unit: TUnit
}

export const MarkerStateWrapper: FC<MarkerStateWrapperProps> = ({
  detailPosition,
  getDataValue,
  hasTrack,
  status = 0, // на карте для детальной информации, пока данные не получены - отображать иконку со статусом 0
  unit,
}) => {
  const lat = getDataValue('LATITUDE', unit)
  const lon = getDataValue('LONGITUDE', unit)

  const position = useMemo((): [number, number] | null => {
    if(detailPosition) {
      return [detailPosition.lat, detailPosition.lon]
    }

    if(lat && lon) {
      return [lat, lon]
    }

    return null
  }, [detailPosition, lat, lon])

  if(!hasTrack && position !== null) {
    return <MarkerState
      status={status}
      position={position}
      unit={unit}
    />
  }

  return null
}
