import { all,fork } from 'redux-saga/effects'
import { notificationCalendarSaga,notificationDataSaga } from './saga/notification'

function* rootSaga() {
  yield all([
    fork(notificationDataSaga),
    fork(notificationCalendarSaga),
  ])
}

export default rootSaga
