import { HeaderCell, HeaderRow, Table as GenericTable, TableBody, TableHeader } from '@agdt/agrotronic-react-components'
import { range } from 'ramda'
import React, { FC, useMemo, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { prepareDuration } from 'ui/Map/duration'
import { COLOR_MAP_ACTIVE } from './constants'
import { NumberSet, Row } from './Row'

type PerformanceResultTableProps = {
  report: {
    id: string
  }[]
}

const PerformanceResultTable: FC<PerformanceResultTableProps> = ({ report }) => {
  const t = useTranslate()

  const columnSettings = useMemo(() => {
    const performanceFormatter = (performance: unknown) => typeof performance === 'number'
      ? `${performance.toFixed(2)} ${t('ha/h')}`
      : '-'

    return [
      { additionalFields: ['subtitle'], id: 'title' },

      {
        formatter: (value: string) => `${parseFloat(value).toFixed(2)} ${t('ha')}`,
        id       : 'area',
        title    : t('Treated area'),
      },

      { formatter: prepareDuration, id: 'timeAllWorkModes', title: `${t('total running time')}, ${t('hh:mm:ss')}` },
      { formatter: prepareDuration, id: 'timeHarvest', title: `${t('harvest')}, ${t('hh:mm:ss')}` },
      { formatter: performanceFormatter, id: 'allWorkModesPerformance', title: t('Average performance sum') },
      { formatter: performanceFormatter, id: 'harvestPerformance', title: t('Average performance run mode') },
    ]
  }, [t])

  const [selectedLevel, setSelectedLevel] = useState<number | null>(null)

  const onClick = (number: number) => () => {
    setSelectedLevel(number)
  }

  const selectorsList = useMemo(() => range(1, 5).map(number =>
    <Selector
      key={number}
      color={number !== 1 && number === selectedLevel ? COLOR_MAP_ACTIVE[number as NumberSet] : 'transparent'}
      onClick={onClick(number)}
    >
      {number}
    </Selector>),[selectedLevel])

  return (
    <TableWrapper>
      <StyledGenericTable>
        <StyledTableHeader>
          <StyledHeaderRow apperance={'large'}>
            {columnSettings.map(column => column.id === 'title'
              ? <SelectorHeaderCell key={column.id} maxWidth="300px">
                {selectorsList}
              </SelectorHeaderCell>

              : <StyledHeaderCell
                key={column.id}
              >
                <HeaderCellTitleWrapper>
                  {column.title}
                </HeaderCellTitleWrapper>
              </StyledHeaderCell>,
            )}
          </StyledHeaderRow>
        </StyledTableHeader>

        <StyledTableBody>

          {report.map(element =>
            <Row
              key={element.id}
              element={element}
              selectedLevel={selectedLevel}
              setSelectedLevel={setSelectedLevel}
              settings={columnSettings}
            />,
          )}
        </StyledTableBody>
      </StyledGenericTable>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  position: relative;
`

const Selector = styled.div`
  padding: 5px 10px;
  margin-right: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background-color:  ${({ color }) => color};

  &:hover {
    border: 1px solid #AFAFAF;
  }
`

const SelectorHeaderCell = styled(HeaderCell)`
margin-right: 90px;
`

const HeaderCellTitleWrapper = styled.div`
  font-size: 14px;
  width: 150px;
`

const StyledTableBody = styled(TableBody)`
  padding: 0 10px;
`

const StyledGenericTable = styled(GenericTable)`
  grid: none;
`

const StyledTableHeader = styled(TableHeader)`
  background: #ffffff;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
`

const StyledHeaderCell = styled(HeaderCell)`
  font-size: 14px;
  max-width: 46em;

  & span {
    color: #000000;
    font-size: 14px;
  }
`

const StyledHeaderRow = styled(HeaderRow)`
  width: 100%;
  min-height: 72px;
`

export default PerformanceResultTable
