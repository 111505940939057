import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx'
import { compose, map } from 'ramda'
import hashBy from 'services/helpers/hashBy'

const hashById = hashBy((item: {id: number}) => item.id)

const buildDictionary = compose(

  // @ts-ignore
  hashById({}),
  map(({ name, ...other }) => ({ label: name, name, ...other })),
)

export default class DictionaryStore<P, T = P[]> {
  @observable value: T | undefined
  fetchApi: () => Promise<T>
  _valueDict: {[key in number | string]: P} | undefined

  //TODO: later twice get value must not load dict twice
  loadingPromise: Promise<T> | undefined

  @computed get valueDict() {
    if(this.value === undefined) {
      throw '.valueDict can not be used before dict is loaded. Use waitValue or useVars for avoid it'
    }

    if(this._valueDict === undefined) {
      this._valueDict = buildDictionary(this.value as any) as any
    }

    return toJS(this._valueDict)
  }

  constructor(fetchApi: () => Promise<T>) {
    makeObservable(this)
    this.fetchApi = fetchApi
  }

  async waitValue(): Promise<void> {
    if(this.value === undefined) {
      await this.loadData()
    }

    return Promise.resolve()
  }

  async getValue(): Promise<T> {
    if(this.value === undefined) {
      if(this.loadingPromise === undefined) {
        this.loadingPromise = new Promise<T>(
          async resolve => {
            await this.loadData()
            resolve(toJS(this.value!))
            this.loadingPromise = undefined
          },
        )
      }

      return this.loadingPromise
    }

    return Promise.resolve(toJS(this.value!))
  }

  @action.bound
  async loadData() {
    const data = await this.fetchApi()

    runInAction(() => {
      this.value = data
    })
  }

  @action
  setValue(value: T) {
    this.value = value
  }

  async refresh() {
    await this.loadData()
  }
}
