import { CheckBox, TableCell } from '@agdt/agrotronic-react-components'
import React, { FC, useState } from 'react'
import { TaskType } from 'reducers/notificationTasks/state'
import { TTranslate } from 'types'

type Props = {
  fieldName: 'active' | 'online' | 'email' | 'sms'
  disabled?: boolean
  translate: TTranslate
  notification: TaskType
  editTask: (response: TaskType) => void
  handleSetNotifyChanel: (notification: TaskType, fieldName: 'active' | 'online' | 'email' | 'sms') => Promise<TaskType>
}

export const CellWithCheckbox: FC<Props> = ({
  disabled,
  editTask,
  fieldName,
  handleSetNotifyChanel,
  notification,
  translate,
}) => {
  const [settingIsSaved, setSettingIsSaved] = useState(false)

  const handleOnClick = () => {
    setSettingIsSaved(true)

    handleSetNotifyChanel(notification, fieldName)
      .then((response: TaskType) => editTask(response))
      .catch(() => translate('saveProfileError'))
      .finally(() => setSettingIsSaved(false))
  }

  return <TableCell key={fieldName} maxWidth="84px">
    <CheckBox
      checked={notification[fieldName]}
      disabled={disabled || settingIsSaved}
      onChange={handleOnClick}
    />
  </TableCell>
}
