import { AppDispatch } from 'reducers/store'
import { useDemoUser as demoUser } from '../../queries/authorization'
import { ConfigType, IteratorsType,UserInfoType } from './state'

export enum UserActionTypeEnum {
  USER_INFO = 'App/User/USER_INFO',
  USER_CONFIG = 'App/User/USER_CONFIG',
  PRELOADER_STOP = 'App/User/PRELOADER_STOP',
  USE_DEMO_MODE = 'App/User/USE_DEMO_MODE',
  SET_ITERATORS = 'App/User/SET_ITERATORS',
}

export type UserActionsType = {
  type: UserActionTypeEnum.USER_INFO
  payload: UserInfoType
} | {
  type: UserActionTypeEnum.PRELOADER_STOP
} | {
  type: UserActionTypeEnum.USER_CONFIG
  payload: ConfigType
} | {
  type: UserActionTypeEnum.USE_DEMO_MODE
} | {
  type: UserActionTypeEnum.SET_ITERATORS
  payload: IteratorsType
}

export const userInfo = (user: UserInfoType) => (dispatch: AppDispatch) =>
  dispatch({ payload: user, type: UserActionTypeEnum.USER_INFO })

export const userConfig = (config: ConfigType) => (dispatch: AppDispatch) =>
  dispatch({ payload: config, type: UserActionTypeEnum.USER_CONFIG })

export const setIterator = (iterator: IteratorsType) => (dispatch: AppDispatch) => {
  dispatch({ payload: iterator, type: UserActionTypeEnum.SET_ITERATORS })
}

export const preLoaderStop = () => (dispatch: AppDispatch) => {
  dispatch({ type: UserActionTypeEnum.PRELOADER_STOP })
}

export const enableDemoMode = (lang: string) => (dispatch: AppDispatch) => {
  localStorage.setItem('rsm-agrotronic-demo-mode', 'true')
  demoUser(dispatch, lang)
  dispatch({ type: UserActionTypeEnum.USE_DEMO_MODE })
}
