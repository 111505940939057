import { action, autorun, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { isEmpty, map, prop, reduce, sum, values } from 'ramda'
import { TUser } from 'types'
import { getStore } from '../../storesRegistry'
import StatusesSelector from './StatusesSelector'
import { TCountByStatusId, TFarmListItem, TUnitsStatusesByFarmMap } from './types'

export class ActivityWidgetStore {
  private disposer?: IReactionDisposer
  private clearSelectedStatusesDisposer?: IReactionDisposer

  @observable unitsStatusesByFarmMap: TUnitsStatusesByFarmMap = {}
  @observable searchText:string = ''
  @observable unitsByStatus: TCountByStatusId = {}

  statusesSelector = new StatusesSelector()

  constructor() {
    makeObservable(this)
  }

  @computed
  get preparedWidgetData() {
    const filteredFarms: TUser[] = getStore('accountsTree').childrenTree.filter(
      F => F.name?.toLowerCase().includes(this.searchText.trim().toLowerCase()),
    )

    const farmsList: TFarmListItem[] = []

    if(!isEmpty(this.unitsStatusesByFarmMap) && !isEmpty(filteredFarms)) {
      filteredFarms.forEach(farm => {
        farmsList.push({
          id      : farm.id,
          name    : farm.name,
          statuses: this.unitsStatusesByFarmMap[farm.id]?.statuses,

          total: this.unitsStatusesByFarmMap[farm.id]
            ? sum(this.unitsStatusesByFarmMap[farm.id].statuses.map(S => S.unitIds.length))
            : 0,
        })
      })
    }

    return farmsList
  }

  @computed
  get totalUnitsCount() {
    return reduce(
      (acc, status) => status?.size ? acc + status.size : acc,
      0,
      values(this.unitsByStatus),
    )
  }

  @action.bound
  setUnitsByStatus(value: TCountByStatusId) {
    this.unitsByStatus = value
  }

  @action.bound
  setSearchText(value: string) {
    this.searchText = value
  }

  @action.bound
  setUnitsStatusesByFarmMap(unitsStatusesByFarmMap: TUnitsStatusesByFarmMap) {
    this.unitsStatusesByFarmMap = unitsStatusesByFarmMap
  }

  @action.bound
  init() {
    this.disposer = autorun(
      () => {
        const currentFarmsIds = map(prop('id'), getStore('accountsTree').childrenTree)
        const unitsStatusesByFarmMap: TUnitsStatusesByFarmMap = {}
        const unitsByStatus: TCountByStatusId = {}

        getStore('dictionaries').targets2.unitsIdsCountByStatuses.forEach(
          F => {
            if(currentFarmsIds.includes(F.id)) {
              unitsStatusesByFarmMap[F.id] = F

              F.statuses.forEach(S => {
                if(unitsByStatus[S.status]) {
                  S.unitIds.forEach((id: number) => unitsByStatus[S.status]?.add(id))
                } else {
                  unitsByStatus[S.status] = new Set(S.unitIds)
                }
              })
            }
          },
        )

        this.setUnitsByStatus(unitsByStatus)
        this.setUnitsStatusesByFarmMap(unitsStatusesByFarmMap)
      },
    )

    this.clearSelectedStatusesDisposer = reaction(
      () => getStore('context').selectedFarmId,
      () => this.statusesSelector.clear(),
    )
  }

  @action.bound
  dispose() {
    this.disposer?.()
    this.clearSelectedStatusesDisposer?.()
  }

  @action.bound
  clear() {
    this.setSearchText('')
    this.statusesSelector.clear()
  }
}
