import React from 'react'

type TProps = {
  className?: string
  onClick?: () => void
}

const WidgetsGalleryIcon = ({ className, onClick }: TProps) => {
  return (
    <svg
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g filter="url(#WidgetsGalleryIconfilter0_ddd)">
        <rect x="15" y="14" width="67.1584" height="67.1584" rx="33.5792" fill="#D10029"/>
      </g>
      <path d="M35 38H42.5058V57.6866H35V38Z" fill="white"/>
      <path d="M44.0069 48.6005H51.5127V57.6866H44.0069V48.6005Z" fill="white"/>
      <path d="M53.0139 48.6005H60.5196V57.6866H53.0139V48.6005Z" fill="white"/>
      <path d="M44.0069 38H60.5196V47.0861H44.0069V38Z" fill="white"/>
      <defs>
        <filter id="WidgetsGalleryIconfilter0_ddd" x="0.608912" y="0.808169" width="95.9406" height="95.9406" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1.19926"/>
          <feGaussianBlur stdDeviation="7.19554"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="4.79703"/>
          <feGaussianBlur stdDeviation="4.79703"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="3.59777"/>
          <feGaussianBlur stdDeviation="3.59777"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default WidgetsGalleryIcon
