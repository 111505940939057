import { GeoJsonObject } from 'geojson'
import { PathOptions, StyleFunction } from 'leaflet'
import { observer } from 'mobx-react'
import { flatten, isEmpty, path, pathOr, pipe, prop, sum } from 'ramda'
import React, { FC, useCallback, useMemo } from 'react'
import { GeoJSON } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { AppStateType } from 'reducers/store'
import { mapParams } from '../../../../containers/Tracking/GeoZones/constants'
import { getStore } from '../../../../stores/storesRegistry'
import { pointToLayerGenerator } from './helpers'

const coordinatesToHash = pipe(pathOr([], ['coordinates', '0']), flatten, sum)

const RenderGeoZoneComponent: FC = () => {
  const geoZone = useSelector((state: AppStateType) => state.geoZone)
  const t = useTranslate()
  const geoZonesStore = getStore('geoZones')

  const featureGenerator = useCallback((feature, layer, featureType) => {
    if(['CURR_HYMIDITY_KUK', 'CURR_HYMIDITY_ZUK'].includes(featureType)) {
      layer.bindPopup(`${t('Moisture')} ${
        feature
        && feature.properties
        && (
          feature.properties.val != null
          && !isNaN(parseFloat(feature.properties.val))
          && isFinite(feature.properties.val))
          ? feature.properties.val.toFixed(1)
          + t('%')
          : '-'}`)
    } else {
      layer.bindPopup(`${t('Productivity')} ${
        feature
        && feature.properties
        && (
          feature.properties.val != null
          && !isNaN(parseFloat(feature.properties.val))
          && isFinite(feature.properties.val))
          ? feature.properties.val.toFixed(1)
          + ', '
          + t(featureType === 'CURR_PRODUCTIVITY_ZUK' ? 'cwt/ha' : 't/ha')
          : '-'}`)
    }
  }, [t])

  const styleGenerator = useCallback((feature, legendValues: number[], featureType?: string): PathOptions => {
    if(feature.properties.val != null) {
      const findType = mapParams.find(type => type.type === featureType)
      const index = legendValues.findIndex(element => feature.properties.val < element)
      let color = findType?.colors[index > -1 ? index - 1 : findType.colors.length - 1]
      return { color: color, fillOpacity: 1, stroke: true, weight: 1, lineCap: 'square', lineJoin: 'round' }
    }

    return {}
  }, [])

  const selectedGeoZoneId = prop('id', geoZonesStore.selectedGeoZone)

  const renderedData = useMemo(() => geoZone.static.map(item => {
    const coloringData = item.coloringData
    const coloringType = item.coloringType

    if(item && (
      item.watch || item.show || coloringData || selectedGeoZoneId === item.id)
    ) {
      const render = []
      const legendValues = geoZone.legendValues[item.id]

      if(coloringData && legendValues) {
        const filteredFeatures = coloringData.features
          .filter(F => !isEmpty(path(['geometry', 'coordinates'], F)) && path(['geometry', 'type'], F) === 'Polygon')

        const newGeoData = {
          ...coloringData,
          features: filteredFeatures,
        } as unknown as GeoJsonObject

        const styleFunction: StyleFunction = feature => styleGenerator(feature, legendValues, coloringType)

        render.push(<GeoJSON
          data={newGeoData}
          key={`coloringData-${coloringType}-${coloringData.features.length}`}
          onEachFeature={(feature, layer) => featureGenerator(feature, layer, coloringType)}
          style={styleFunction}
        />)
      }

      if(item.watch || item.show || selectedGeoZoneId === item.id) {
        render.push(
          <GeoJSON
            data={item.location}
            key={coordinatesToHash(item.location)}
            pointToLayer={pointToLayerGenerator}
          />,
        )
      }

      return render
    }

    return null
  }), [geoZone.static, geoZone.legendValues, selectedGeoZoneId])

  return <>
    {renderedData}
  </>
}

export const RenderGeoZone = observer(RenderGeoZoneComponent)
