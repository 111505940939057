// @todo отказаться от immutable
import { List, Map } from 'immutable'
import L from 'leaflet'
import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'
import { findLast, forEachObjIndexed, fromPairs, last, max, pathOr, prop, reduce, toPairs } from 'ramda'
import { TPath, TTrack } from 'types'
import { requestTrack } from '../../queries/request'
import { machinesSelector } from './selectors'
import { getCourse } from './selectors'

type TUnitData = {
  ACTIVE:{
    value: number
  }
  LATITUDE: {
    value: number
  }
  LONGITUDE: {
    value: number
  }
}

export type UnitType = {
  id: unknown
  imei: number
  markers?: unknown[]
  timeTrack?: string
  track?: {
    static?: unknown
    activeTrack?: Record<string, {
      path: {
        lat: number
        lng: number
      }[]
    }[]>
    type: string
    different?: unknown[]
  }
  shownMarkers?: unknown[]
  trackTimeRange?: unknown[]
  detailData?: {
    DATA_GEO?: unknown[]
  }
  graphic?: {
    modesByUnits?: unknown
    summary?: unknown
  }
  data?: TUnitData
  markersFuel?: unknown
}

export type UserType = {
  id: unknown
  name: string
  parent: unknown
  type: unknown
  units: {
    units: UnitType[]
  }
}

export const init = () => Map({
  allowedSensors       : {},
  dataAllMachines      : Map({}),
  dummy                : Date(),
  filteredTypeModels   : [],
  filterTypeModelsValue: null,
  modelsHousehold      : [],
  observersMovement    : [],
  searchHousehold      : '',
  statusesHousehold    : [],
  users                : List<UserType>([]),
})

export type Users = ReturnType<typeof init>

const setUnit = (
  state: Users,
  searchFunc: (item: UnitType) => boolean,
  callback: (item: UnitType) => void,
  searchUserFunc?: (item: unknown) => unknown,
): Users => {
  return state.set(
    'users',

    /*@ts-expect-error*/
    state.get('users').map(user => {
      if(searchUserFunc && !searchUserFunc(user)) {
        return user
      }

      const findUnit = user.units.units.find(searchFunc)

      if(findUnit) {callback(findUnit)}

      return user
    }),
  )
}

const valueOrNull = (
  name: string,
  value?: TUnitData,
  defaultValue: number | null = null,
) => pathOr(defaultValue, [name, 'value'], value)

export const setModelsHousehold = (state: Users, value: unknown): Users => {
  /*@ts-expect-error*/
  let modelsHousehold = [...state.get('modelsHousehold')]

  if(value) {
    modelsHousehold = modelsHousehold.some(item => item === value)
      ? modelsHousehold.filter(item => item !== value)
      : [...modelsHousehold, value]
  } else {
    modelsHousehold = []
  }

  return state.set('modelsHousehold', modelsHousehold)
}

export const setStatusesHousehold = (state: Users, payload: unknown): Users => {
  /*@ts-expect-error*/
  let statusesHousehold: unknown[] = state.get('statusesHousehold')

  if(payload) {
    statusesHousehold = statusesHousehold.some(item => item === payload)
      ? statusesHousehold.filter(item => item !== payload)
      : [...statusesHousehold, payload]
  } else {
    statusesHousehold = []
  }

  return state.set('statusesHousehold', statusesHousehold)
}

export const setSearchHousehold = (state: Users, payload?: string): Users => {
  return payload !== undefined ? state.set('searchHousehold', payload) : state
}

export const setSuccesRequestUsers = (state: Users, payload: unknown): Users => {
  /*@ts-expect-error*/
  const newMachinesMap = machinesSelector(payload, state.get('users').toJS())
  return state.set('users', List(cloneDeep(newMachinesMap)))
}

export const pushTrack = (
  state: Users,
  payload: {
    imei: unknown
    data: {[key: string]: TTrack[]}
    demo: unknown
    today: unknown
    stat: unknown
  },
): Users => {
  return setUnit(
    state,
    unit => unit.imei === payload.imei,
    unit => {
      /*@ts-expect-error*/
      unit.track = unit.track ? unit.track : {}

      /*@ts-expect-error*/
      unit.track.type = unit.track.type ? unit.track.type : 'TRACK_HARVEST'

      //в случае, если в registers нет даты последней активности машины - не фильтруем точки трека по этому параметру
      //если есть - трек обрезается до даты последней активности
      let track = !unit.data?.ACTIVE.value
        ? payload.data

        : fromPairs(
          toPairs(payload.data)
            .map(([I, T]) =>
              [I, T.map(P => ({ ...P, path: P.path.filter(J => Number(J.utc) <= Number(unit.data?.ACTIVE.value)) }))],
            ),
        )

      const unitData = unit.data

      // В демо режиме не добавляет в трек последнюю точку из targets2 потому что в targets2 точки очень далеко от трека
      if(
        !payload.demo &&
        payload.today &&
        valueOrNull('LATITUDE', unitData) &&
        valueOrNull('LONGITUDE', unitData)
      )
      {forEachObjIndexed(M => {
        if(
          M.length > 0 &&

          /*@ts-expect-error*/
          (valueOrNull('LATITUDE', unitData) !== last(M).lat ||

          /*@ts-expect-error*/
          valueOrNull('LONGITUDE', unitData) !== last(M).lng)
        ) {
          /*@ts-expect-error*/
          last(M).path.push({

            /*@ts-expect-error*/
            course       : getCourse(unitData.LONGITUDE.value, last(M).lng, unitData.LATITUDE.value, last(M).lat),
            CURR_PERF_TON: null,
            fromTargets2 : true,

            /*@ts-expect-error*/
            ID: Number(unitData.ACTIVE.value),

            /*@ts-expect-error*/
            lat: unitData.LATITUDE.value,

            /*@ts-expect-error*/
            lng   : unitData.LONGITUDE.value,
            LOAD  : valueOrNull('LOAD', unitData),
            SPEED : valueOrNull('SPEED', unitData),
            status: valueOrNull('STATUS', unitData, 0),

            /*@ts-expect-error*/
            t: unitData.ACTIVE.value

            /*@ts-expect-error*/
              ? moment.utc(unitData.ACTIVE.value).format('YYMMDDHHmmssSSS')
              : null,

            /*@ts-expect-error*/
            utc: unitData.ACTIVE.value,
          })
        }

        return M
      }, track)}

      const splittedTrack = {}

      // В этом блоке обеспечивается прерывистость трека, если временной интервал между точками велик https://trello.com/c/j7xQtPci
      // Время из предыдущего шага, для первой итерации MAX_SAFE_INTEGER
      Object.entries(track)
        .forEach(([trackType, trackArr]) =>
          trackArr.forEach(item => {
            let listPath

            {
              let lastPoint = { lat: 0, lng: 0, utc: Number.MAX_SAFE_INTEGER }

              listPath = item.path.reduce((result, pathPount) => {
                if(
                  pathPount.utc - lastPoint.utc > 900_000 &&
                  L.latLng([lastPoint.lat, lastPoint.lng]).distanceTo([pathPount.lat, pathPount.lng]) > 500
                ) {
                  // Если разрыв между точками больше 15 мин и удаленность более 500 метров добавляем новый сегмент c пометкой о телепортации
                  /*@ts-expect-error*/
                  if(!splittedTrack[trackType]) {splittedTrack[trackType] = []}

                  if(result.tmp.length > 1) {
                    /*@ts-expect-error*/
                    splittedTrack[trackType].push({ ...item, path: result.tmp })
                  }

                  /*@ts-expect-error*/
                  splittedTrack[trackType].push({
                    ...item,

                    // Непредвиденное перемещение, транспортировка
                    isTeleported: true,
                    path        : [lastPoint, pathPount],
                  })

                  result.tmp = []
                  lastPoint = pathPount
                  return result
                }

                lastPoint = pathPount

                if(pathPount.lat && pathPount.lng) {
                  result.tmp.push(pathPount)
                }

                return result
              }, { res: [], tmp: [] as TPath[] })

              if(listPath.tmp.length > 1) {
                /*@ts-expect-error*/
                if(!splittedTrack[trackType]) {
                  /*@ts-expect-error*/
                  splittedTrack[trackType] = []
                }

                /*@ts-expect-error*/
                splittedTrack[trackType].push({ ...item, path: listPath.tmp })
              }
            }
          }),
        )

      /*@ts-expect-error*/
      unit.track.activeTrack = splittedTrack

      /*@ts-expect-error*/
      unit.track.static = !payload.stat
    },
  )
}

export const flyTrack = (
  state: Users,
  payload: {
    imei: unknown
    data: unknown[]
  },
): Users => {
  return state.set(
    'users',

    /*@ts-expect-error*/
    state.get('users').map(user => {
      const unit = user.units.units.find(
        (item: UnitType) => item.imei === payload.imei,
      )

      if(unit && unit.track && unit.track.activeTrack) {
        const { TRACK_HARVEST } = unit.track.activeTrack

        if(TRACK_HARVEST && TRACK_HARVEST.length > 0) {
          /*@ts-expect-error*/
          let lastPoint

          {
            let index = TRACK_HARVEST.length

            while(!lastPoint && index >= 0) {
              lastPoint = findLast(item => !!item, TRACK_HARVEST[--index].path)
            }
          }

          const different = payload.data.filter(

            /*@ts-expect-error*/
            P => Number(P.utc) > Number(lastPoint.utc) && Number(P.utc) < Number(unit.data.ACTIVE.value),
          )

          const unitData = unit.data

          if(
            /*@ts-expect-error*/
            Number(lastPoint.utc) < Number(unitData.ACTIVE.value) &&
            unitData.LATITUDE.value &&
            unitData.LONGITUDE.value &&

            /*@ts-expect-error*/
            lastPoint.lat !== unitData.LATITUDE.value &&

            /*@ts-expect-error*/
            lastPoint.lng !== unitData.LONGITUDE.value
          ) {
            const dataFromTargets2 = {
              course: getCourse(
                unitData.LONGITUDE.value,

                /*@ts-expect-error*/
                (last(different) || lastPoint).lng,
                unitData.LATITUDE.value,

                /*@ts-expect-error*/
                (last(different) || lastPoint).lat,
              ),
              CURR_PERF_TON: null,
              fromTargets2 : true,
              ID           : unitData.ACTIVE.value,
              lat          : unitData.LATITUDE.value,
              lng          : unitData.LONGITUDE.value,
              LOAD         : valueOrNull('LOAD', unitData),
              SPEED        : valueOrNull('SPEED', unitData),
              status       : valueOrNull('STATUS', unitData, 0),
              t            : unitData.ACTIVE.value
                ? moment.utc(unitData.ACTIVE.value).format('YYMMDDHHmmssSSS')
                : null,
              utc: unitData.ACTIVE.value,
            }

            different.push(dataFromTargets2)
          }

          const getUTC = prop('utc')

          if(different) {
            const maxTime = Math.max(reduce((A,B) =>

            /*@ts-expect-error*/
              max(A, getUTC(B)), 0, unit.track.different || []), unit.updated || 0,
            )


            /*@ts-expect-error*/
            const filteredDifferent = different.filter(P => P.utc > maxTime)

            if(filteredDifferent) {
              unit.track.different = different
              unit.trackUpdated = maxTime
            }
          }
        }
      }

      return user
    }),
  )
}

export const startLoadTrackDetail = (
  state: Users,
  payload: {
    id: unknown
    data: unknown[]
    from: string
    to: string
  },
): Users => {
  return setUnit(
    state,
    unit => unit.id === payload.id,
    unit => {
      unit.timeTrack = `${moment(
        payload.from,
        'YYMMDDHHmmss000',
      ).format('DD.MM.YY HH:mm')} - ${moment(
        payload.to,
        'YYMMDDHHmmss000',
      ).format('DD.MM.YY HH:mm')}`
    },
  )
}

export const deleteTrack = (
  state: Users,
  payload: {
    id: unknown
  },
): Users => {
  return setUnit(
    state,
    unit => unit.id === payload.id,
    unit => {
      requestTrack.stopObservableTrack(unit.imei)
      delete unit.track
      delete unit.timeTrack
      delete unit.markers
      delete unit.shownMarkers
      delete unit.trackTimeRange

      if(unit.detailData && unit.detailData.DATA_GEO) {
        unit.detailData.DATA_GEO = []
      }
    },
  )
}

export const updateMachine = (
  state: Users,
  payload: {
    id: unknown
    data: object
  },
): Users => {
  return setUnit(
    state,
    unit => unit.id === payload.id,
    unit => {
      Object.keys(payload.data).map(key => {
        /*@ts-expect-error*/
        if(unit.data[key]) {unit.data[key] = payload.data[key]}
      })
    },
  )
}

export const setAllowedSensors = (
  state: Users,
  payload: object,
): Users => {
  return state.set('allowedSensors', payload)
}

export const filterUsersType = (
  state: Users,
  payload: {
    filteredTypeModels: unknown[]
    id: unknown
  },
): Users => {
  /*@ts-expect-error*/
  return state.set('filterTypeModelsValue', payload.id).set('filteredTypeModels', payload.filteredTypeModels)
}
