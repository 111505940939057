import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import SystemContextMenu from '../../../components/Widget/SystemContextMenu'
import { ContextMenu } from '../ContextMenu'
import ResizeButton from './ResizeButton'
import { Title } from './Title'

type TProps = {
  isCompact?: boolean
  contextMenu?: React.ReactNode
  counter?: string
  subtitle?: string | JSX.Element
  title: string
  className?: string
  onClose: () => void
  iconColor?: string
  isResizable?: boolean
  toggleSize?: () => void
}

export const Header = ({
  className,
  contextMenu = ContextMenu,
  counter,
  iconColor = '#A1A1A1',
  isCompact,
  isResizable = false,
  onClose,
  subtitle,
  title,
  toggleSize,
}: TProps) => {
  return (
    <HeaderElement isSubtitle={Boolean(subtitle)} className={classNames(className)} isCompact={isCompact}>
      <Title
        title={title}
        subtitle={subtitle}
        counter={counter}
        className='draggable'
      />

      {isResizable && <ResizeButton toggle={toggleSize} isCompact={isCompact} iconColor={iconColor} />}
      <SystemContextMenu ContextMenu={contextMenu} onClose={onClose} iconColor='#A1A1A1' />
    </HeaderElement>
  )
}

export const HeaderElement = styled.header<{ isCompact?: boolean, isSubtitle: boolean }>`
  height: ${({ isSubtitle }) => isSubtitle ? '49px': '36px'};
  border-bottom: ${({ isCompact }) => isCompact ? 'none' : '1px dashed #202020'};
  display: flex;
`
