import { FilingAndDrainMarkersInfo } from 'queries/trackEvents'
import React, { FC } from 'react'
import { Marker, Popup } from 'react-leaflet'
import { TTranslate } from 'types'
import { drain, filling } from '../../markersType'

type FuelingAndDrainProps = {
  t: TTranslate
  marker: Required<FilingAndDrainMarkersInfo>
}

export const FuelingAndDrain: FC<FuelingAndDrainProps> = ({ marker, t }) => <Marker
  key={`${marker.sign}_${marker.lat}_${marker.lon}`}
  position={[marker.lat, marker.lon]}
  icon={marker.sign === 'FILING' ? filling : drain}
  zIndexOffset={10000}
>
  <Popup position={[marker.lat, marker.lon]}>
    <div className="marker-popup">
      <p className="marker-popup__name">
        {t('Start')}: {Date.parseDateTimeFromApi(marker.from).user.format('DD.MM.YY HH:mm:ss')}
      </p>
      <p className="marker-popup__name">
        {t('Finish')}: {Date.parseDateTimeFromApi(marker.to).user.format('DD.MM.YY HH:mm:ss')}
      </p>
      <p className="marker-popup__name">
        {marker.sign === 'FILING' ? t('FILING') : t('DRAIN')}:
        {marker.volume.toFixed(2)} {t('l')}
      </p>
    </div>
  </Popup>
</Marker>
