import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'

export type TransportPopupUnit = {
  name?: string
  stereotype?: string
  modelTransportTypeName?: string
  modelBrandName?: string
  modelName?: string
  regNum?: string
}

type TransportPopupProps = {
  unit: TransportPopupUnit
}

export const TransportPopup: FC<TransportPopupProps> = ({ unit }) => {
  const t = useTranslate()
  const isValid = Boolean(unit.name)

  return (
    <Container>
      <Title>{t(unit.stereotype || 'transport')}</Title>
      <Access isValid={isValid}>
        {t(isValid ? 'allowed' : 'forbidden').toUpperCase()}
      </Access>
      <Paramiter>
        <p>{t('type')}:</p><span>{unit.modelTransportTypeName || '-'}</span>
      </Paramiter>
      <Paramiter>
        <p>{t('brand')}:</p><span>{unit.modelBrandName || '-'}</span>
      </Paramiter>
      <Paramiter>
        <p>{t('model')}:</p><span>{unit.modelName || '-'}</span>
      </Paramiter>
      <Paramiter>
        <p>{t('license plate')}:</p><span>{unit.regNum || '-'}</span>
      </Paramiter>
    </Container>
  )
}

const Container = styled.div`
  border-top: 1px solid #A1A1A1;
  margin-top: 8px;
`

const Title = styled.p`
  font-weight: bold;
  margin: 14px 0px 10px 0px !IMPORTANT;
`

const Access = styled.div<{isValid: boolean}>`
  font-weight: bold;
  background-color: ${({ isValid }) => isValid ? '#15CE70' : '#EB1717'};
  border-radius: 2px;
  color: white;
  padding: 5px 18px;
  width: min-content;
  margin-bottom: 16px;
`

const Paramiter = styled.div`
  p {
    font-weight: bold;
    display: inline;
  }
  span {
    float: right;
  }
`
