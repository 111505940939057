/* eslint-disable */
import notificationTasks from './notificationTasks/de'

const de = {
  // Validation
  "Confirmation code": "Bestätigungscode",
  "Approve": "Bestätigen",
  "Send code": "Code senden",
  "Wrong code format": "Ungültiges Codeformat",
  "Send new code": "Neuen Code senden",
  "Enter a number or Email": "Geben Sie eine Nummer oder E-Mail ein",
  "Validation error": "Validierungsfehler",
  "Error during validation": "Fehler bei der Validierung",
  "Error requesting code": "Fehler beim Anfordern eines Bestätigungscodes",
  "This is a required field": "Dies ist ein erforderliches Feld",
  "Password cannot be less than 8 characters": "Das Passwort muss mindestens 8 Zeichen lang sein (Groß- und Kleinbuchstaben, Ziffern und andere Zeichen)",
  "At least 6 characters, only numbers and Latin letters": "Mindestens 6 Zeichen, nur Zahlen und lateinische Buchstaben",
  "Wrong email or phone number": "E-Mail oder Telefonnummer ist falsch eingegeben",
  "Confirm password mismatch": "Passwörter stimmen nicht überein",
  "Password restored": "Passwort wurde wiederhergestellt",
  "Password success changed":"Das Passwort wurde erfolgreich wiederhergestellt. Sie können Ihr neues Passwort verwenden, um sich anzumelden",

  // Notification Tasks
  notificationTasks,
  // Common
  "To generate the report, you must specify the email address": "Um den Bericht zu generieren, müssen Sie die E-Mail-Adresse angeben",
  "set email": "E-Mail festlegen",
  "Repeat password": "Passwort wiederholen",
  "New password": "Neues Passwort",
  "Confirmation code sent to the email/phone number": "Ein Bestätigungscode wurde an das angegebene Telefon/E-Mail gesendet",
  "Reset password": "Passwort zurücksetzen",
  "Next": "Weiter",
  "To signin": "Zur Autorisierung",
  "Phone / Email": "Telefon / Email",
  "OK": "ОК",
  "Open": "Dateien auswählen",
  "Import data from USB": "Daten von USB importieren",
  "Wrong file format": "Das Dateiformat wird nicht unterstützt",
  "import completed": "Daten wurden importiert",
  "The geozone should not self-crossing": "Die Geozone sollte sich nicht selbst überqueren",
  more: "Details",
  "sign_out": "Verlassen",
  "helpers": "Tipps",
  "desktop": "Arbeitsplatz",
  "map (new)": "Karte (neu)",
  "myMachines": "Maschinen",
  "tracking": "Verfolung",
  "watch": "Analyse",
  "notifications": "Notifications",
  "Notifications": "Notifications",
  "Not found": "Nicht gefunden",
  "settings": "Bedienung",
  sortByQuantity: "Nach Menge sortieren",
  "map": "Karte",
  "activity": "Aktivität",
  "removed": "Geerntet/Bearbeitet",
  "name_of_the_farm": "Bezeichnung des Landwirtschaftsbetriebs",
  "machines_total": "Maschinen gesamt",
  "total": "Gesamt",
  "active": "Aktiv",
  "warning": "Kritisch",
  amountOfHours: "Die Anzahl der Stunden",
  "farming_name": "Bezeichnung des Landwirtschaftsbetriebs",
  "machines_quantity": "Machines au total",
  "activities": "Aktivität",
  "fuel": "Kraftstoff",
  "conservants": "Konservierungsmittelapplikationssystem",
  "machine": "Maschine",
  "inclusion_DVS": "Motorstart",
  "last_data": "Letzte Angaben",
  "speed_at_now": "Aktuelle Geschwindigkeit",
  "speed": "Geschwindigkeit",
  "average_speed_move_in_harvest": "Durchschn. Geschw. Mähdrescher",
  "expense_of_fuel": "Kraftstoffverbrauch",
  "area_cleaned": "Geerntete/bearbeitete Fläche",
  "performance": "Leistung",
  "watching": "Verfolung",
  "status": "Status",
  "type_of_machine": "Typ der Maschine",
  "farming": "Landwirtschaftsbetrieb",
  "today": "Heute",
  day: "Tag",
  month: "Monat",
  year: "Jahr",
  "week": "Woche",
  "date": "Datum",
  "select_a_date": "Termine auswählen",
  "track": "Route",
  "accept": "Annehmen",
  "range_of_date": "Zeitraum",
  "drain": "Ablässe",
  "refuel": "Tankungen",
  "indications_of_work": "Arbeitskennziffern",
  "consolidated_statement_about_machines": "Gesamtbericht zu Maschinen",
  "events_a_o": "Ereignisse zu Landwirtschaftsoperationen",
  "export": "Export",
  "model": "Modell",
  "time": "Zeit",
  "status_of_message": "Status der Meldung",
  "category": "Kategorie",
  "found": "Gefunden",
  "selecting_date": "Datum auswählen",
  "analysis": "Analyse",
  "use_of_time": "Nutzung der Zeit",
  "report_about_machine": "Bericht zur Maschine",
  "fuel_report": "Kraftstoffbericht",
  "harvest": "Betriebsmodus",
  "driving_mode": "Straßenmodus",
  "work_engine_in_no_movement": "Standzeit mit laufendem Motor",
  "liters": "Liter",
  "machines": "Maschinen",
  "farmings": "Landwirtschaftsbetriebe",
  "geozones": "Geozonen",
  "search": "Suche",
  "search_for": "Suche nach Landwirtschaftsbetrieben und Maschinen",
  "search_for_geozone": "Suche nach Geozone",
  "params_of_report": "Berichtsparameter",
  "format_of_file": "Dateiformat",
  "type": "Typ",
  "events": "Ereignisse",
  "event": "Ereignis",
  "count": "Anzahl",
  "duration": "Dauer",
  "date_of_start": "Datum des Beginns",
  "time_of_start": "Beginnzeit",
  "time_of_end": "Ende-Zeit",
  cancelChanges: "Änderungen storniert",
  saveProfileWait: "Informationen werden gespeichert",
  saveProfileDone: "Information gespeichert",
  saveProfileError: "Beim Speichern ist ein Fehler aufgetreten",
  savePhotoWait: "Foto wird gespeichert",
  savePhotoDone: "Foto gespeichert",
  deleteProfileWait: "Information wird gelöscht",
  deleteProfileDone: "Information gelöscht",
  pleaseWait: "Bitte warten",
  deletePhoto: "Foto gelöscht",
  photoTooLarge: "Foto zu groß",
  maxPhotoSize: "Maximale Fotogröße: 2 MB",
  wrongPhotoFormat: "Nicht unterstütztes Fotoformat",
  supportedPhotoFormats: "Unterstützte Fotoformate: JPEG, JPG, PNG",
  "A final report about harvesting": "Gesamterntebericht/Gesamtarbeitsbericht",
  "A daily report about harvesting (compact)": "Tageserntebericht/Tagesarbeitsbericht (kompakt)",
  "A final report about used time": "Gesamtbericht zur Nutzung der Zeit",
  "An overall report about operation time": "Gesamtbericht zur Betriebsdauer",
  "Daily Cleaning/Work Report (KYK)": "Täglicher Reinigungs-/Arbeitsbericht (ALS)",
  "A final report about harvesting (compact)": "Gesamterntebericht/Gesamtarbeitsbericht (kompakt)",
  "A final report about machine": "Gesamtbericht zu Maschinen",
  "A daily report about used time": "Tagesbericht zur Nutzung der Zeit",
  "A daily report about harvesting": "Tagesernte-/Arbeitsbericht",
  "A daily report about machine": "Tagesbericht zu Maschinen",
  "A report about machine": "Ein Bericht über die Maschine",
  "A final report agricultural operations": "Ein Abschlussbericht landwirtschaftlicher Betriebe",
  "A detail report agricultural operations": "Ein detaillierter Bericht über landwirtschaftliche Operationen",
  "A report of fuel": "Ein Bericht von Kraftstoff",
  "Filling/Drain": "Befüllen / Entleeren",
  "export_of_report": "Export des Berichts",
  "export_of_reports": "Export der Berichte",
  "Please select unit(s) and time period": "Bitte Einheit (en) und Datum / Periode auswählen",
  "by_model": "Nach Mod.",
  "by_active": "Nach Aktiv.",
  "not_of_data": "Keine Angaben",
  "selecting": "Alles auswählen",
  "deselecting": "Alles abwählen",
  "Navigation menu by pages": "Menü der Seitennavigation",
  "profile settings": "Einstellungen des Profils",
  "farmings list": "Liste von Landwirtschaftsbetrieben",
  "Change zoom of map Can use scroll of mouse or multitouch": "Änderung des Kartenmassstabs. Dazu kann die Scroll-Funktion verwendet oder zwei Finger auf der Karte auseinander-/zusammengezogen werden",
  "Switch on/ switch off fullscreen mode": "Vollbildmodus ein-/ausschalten",
  "Show center of a map": "Zentrierung der Karte",
  "The selection type a map": "Typ der Karte auswählen",
  "The map show all machines Click on machine, to open information about this machine": "Karte mit der Anzeige aller verfügbaren Maschinen. Um sich die Informationen zu einer bestimmten Technikeinheit anzeigen zu lassen, ist das Symbol anzuklicken",
  "Cost fuel for regimes all machines": "Kraftstoffaufwendungen bei Betriebsmodi für alle Einheiten",
  "Percent of time, when machines had active regime": "Prozentuale Zeit, während der alle Einheiten in Betriebsmodi sind",
  "serial n": "Seriennummer",
  "Start of fuel": "Beginn Kraftstofftank, l.",
  "End of fuel": "Ende Kraftstofftank, l.",
  "filling": "Getankt Kraftstofftank, l",
  "drain_of_day": "Abgelassen Kraftstofftank, l",
  "summFuel": "Gesamtkraftstoffverbrauch, l",
  "summFuelMove": "Verbrauch beim Transport, l",
  "summFuelComb": "Verbrauch Ernte/Arbeit, l",
  "summFuelStand": "Verbrauch im Stand, l",
  "fuelHour": "Durchschnittlicher Kraftstoffverbr. l/h",
  "engineTime": "Betriebsdauer Motor, h",
  "engineTimeMove": "Zeit im Transportmodus, h",
  "engineTimeComb": "Zeit während der Ernte/Arbeit, h",
  "engineTimeStop": "Zeit im Stillstand",
  "phone": "Telefon",
  "clear": "Reinigen",
  "clear filter": "Filter reinigen",
  "fixed options of filter": "Filterparameter fixieren",
  "M0": "Keine Verbindung",
  "M2": "Ausgeschaltet",
  "M5": "Stillstand mit eingeschalteten Aggregaten",
  "M6": "Stillstand mit eingeschalteten Aggregaten und vollem Tank",
  "M1": "Straßenmodus",
  "M3": "Standzeit mit laufendem Motor",
  "M4": "Betriebsmodus",
  Idle: "Standzeit",
  "UPLOAD": "Entleerung",
  "BUNKER_FULL": "Korntank voll",
  "details": "Detailansicht",
  "FILING": "Tankung",
  "DRAIN": "Ablass",
  "All": "Alle",
  "All machines": "Die Filmteams",
  "harvesting machinery": "Futtererntetechnik",
  "sowing machinery": "Aussaattechnik",
  "tractor": "Schlepper",
  "clear_filter": "Filterung löschen",
  "critical": "Kritisch",
  "not_critical": "Nicht kritisch",
  "informational": "Informativ",
  "service": "Technische Wartung",
  "engine": "Motor",
  "fuel_system": "Kraftstoffsystem",
  "adapter_reaper": "Vorsatzgerät und Schneidwerk",
  "reaper": "Schneidwerk",
  "inclined_camera_feeder": "Schrägförderer und Einzug",
  "cabin": "Kabine",
  "thresher": "Drescher beim Mähdrescher und Häcksler  beim Feldhäcksler",
  "cleaning": "Reinigung",
  "chassis": "Fahrwerk",
  "hopper": "Korntank",
  "stacker": "Streuhäcksler und Strohsammler",
  "hydraulics": "9.8 Hydraulik",
  "electrics": "Elektrik und Elektronik",
  "notification": "Anzeige",
  "harvesting": "Ernte",
  "hopper_full": "Korntank voll",
  "unloading": "Entleerung",
  "Loading data": "Loading data",
  "downtime": "Stillstandzeiten",
  "idle_time_<_1": "Standzeit mit laufendem Motor bis zu 1 Min",
  "idle_time_>_1": "Standzeit mit laufendem Motor über 1 Min",
  "stop_<_1": "Anhalten weniger als 1 Minute",
  "stop_1_10": "Anhalten von 1 bis 10 Minuten",
  "stop_>_10": "Anhalten mehr als 10 Minuten",
  "summary": "Gesamt",
  "detail": "Detailliert",
  "agriculture": "Erntefrucht",
  "rating": "Nach Ranking sortieren",
  "ON_UPLOAD": "Entleerung",
  "ON_100": "Korntank voll",
  "group": "Zu einer Gruppe gehörend",
  "single": "Einzeln",
  "Set a date": "Datum vorgeben",
  "Indicators at moment": "Aktuelle Kennwerte",
  "Technique in testing": "Versuchstechnik",
  "Maintenance": "Technische Wartung",
  "Aggregates": "Aggregate",
  "Status at now": "Aktueller Status",
  "Move speed of combine": "Fahrgeschwindigkeit des Mähdreschers",
  "Level fuel in tank": "Kraftstoffstand im Tank",
  "Performance at now": "Aktuelle Leistung",
  "is active": "Aktiv",
  "not active": "Nicht aktiv",
  "download excel": "Herunterladen (excel)",
  "report cleaning": "Bericht zum Dreschertrag",
  "make graphics": "Diagramme erstellen",
  "Contact form": "Kontaktieren",
  "Reasons contact": "Grund der Anfrage",
  "Service": "Dienst",
  "Contact phone": "Telefonnummer",
  "Message": "Nachricht",
  "Serial number machine": "Seriennummer der Maschine",
  "Type technics": "Typ der Technik",
  "submit": "Absenden",
  "profile": "Profil",
  "Load photo": "Foto hochladen",
  "noname": "Name nicht gefunden",
  "noemail": "E-Mail nicht gefunden",
  "language": "Sprache",
  "ru": "Русский",
  "en": "English",
  "fr": "Le français",
  "de": "Deutsch",
  "Serial number search": "Suche der Maschine nach der Seriennummer",
  "For the current day": "für den laufenden Tag",
  "feedback": "Kontaktieren",
  "period": "Zeitraum",
  "sprayers": "Sprayers",
  "mode": "Mode",
  "level fuel, l": "Kraftstoffstand, l",
  "fuel graphics": "Kraftstoffdiagramm",
  "creating geozones": "Geozonen anlegen",
  "area": "Fläche",
  "Last point": "Letzter Punkt",
  "Long": "Länge",
  "km": "km",
  "Save geozone": "Geozone speichern",
  "Active point": "Aktiver Punkt",
  "Continue": "Fortsetzen",
  "Delete point": "Punkt löschen",
  "shape": "Shape-Datei laden",
  "Turning on the display of directions on the map": "Anzeige der Richtung auf der Karte aktivieren",
  "Select map tile": "Auswahl des Hintergrundbildes der Karte",
  "Geozone edit": "Geozone editieren",
  "New geozone": "Neue Geozone",
  "Name geozone": "Name der Geozone",
  "Do not display": "Werden nicht angezeigt",
  "Convective precipitation": "Konvektiver Niederschlag",
  "Precipitation intensity": "Precipitation intensity",
  "Accumulated precipitation": "Niederschlagsintensität",
  "Accumulated precipitation - rain": "Akkumulierter Niederschlag - Regen",
  "Accumulated precipitation - snow": "Akkumulierter Niederschlag - Schnee",
  "Depth of snow": "Schneehöhe",
  "Wind speed at an altitude of 10 meters": "Windgeschwindigkeit in 10 Metern Höhe",
  "Joint display of speed wind and wind direction": "Gemeinsame Anzeige von Geschwindigkeitswind (Farbe) und Windrichtung (Pfeile), die von U- und V-Komponenten empfangen werden",
  "Atmospheric pressure on mean sea level": "Atmosphärendruck auf mittlerem Meeresspiegel",
  "Air temperature at a height of 2 meters": "Lufttemperatur in einer Höhe von 2 Metern",
  "Temperature of a dew point": "Temperatur eines Taupunktes",
  "Soil temperature 0-10 сm": "Bodentemperatur 0-10 µm",
  "Température du sol> 10 сm": "Bodentemperatur >10 µm",
  "Relative humidity": "Relative Luftfeuchtigkeit",
  "Cloudiness": "Trübung",
  "Start": "Beginn",
  "Finish": "Ende",
  "sputnic": "Satellitenansicht",
  "hybrid": "hybrid",
  "terrain": "terrain",
  "Engine load": "Belastung des Motors",
  "Show in list": "In der Liste anzeigen",
  "No location data": "Keine Angaben zum Standort",
  "from": "Von",
  "to": "Bis",
  "good day": "Guten Tag",
  "User profile page": "Benutzerprofilseite",
  "Track in geozone": "Route in der Geozone",
  "Scaling": "Skalierung",
  "Select the period of the show track": "Auswahl der Anzeigeperiode der Route",
  "No data for the this period": "Keine Angaben für den ausgewählten Zeitr.",
  "Attention! Request for notifications is limited to": "Achtung! Die Anforderung von Benachrichtigungen ist auf",
  "days Please change the request parameters": "Tage begrenzt. Bitte ändern Sie die Anforderungsparameter",
  "Track by mode": "Route nach Betriebsmodi",
  "Track by speed": "Route nach Geschwindigkeit",
  "Track by load": "Route nach der Auslastung",
  "Delete track": "Route löschen",
  "Track control panel (select track type, delete track)": "Bedienpanel der Routen (Auswahl der Art der Route, Löschung der Route)",
  "Geozone name": "Name der Geozone",
  "Time of entry": "Zeit des Befahrens",
  "Departure time": "Zeit des Verlassens",
  "Time in geozone": "Zeit in der Geozone",
  "Current status of the vehicle": "Aktueller Status der Technik",
  "Make and model of technology": "Marke und Modell der Technologie",
  "Level fuel": "Kraftstoffstand",
  "Track building": "Erstellung der Route",
  "Track": "Verfolgen",
  "Enable / Disable the display of technology on the map": "Anzeige der Technik auf der Karte aktivieren/deaktivieren",
  "Total fuel spent on all available units": "Insgesamt verbrauchter Kraftstoff für alle verfügbaren Technikeinheiten",
  "Fuel consumption by mode for all units": "Kraftstoffaufwendungen bei Betriebsmodi für alle Einheiten",
  "main diagram": "Die gesamte abgeerntete / behandelte Fläche und das Diagramm der abgelaufenen Zeit für jeden Modus für alle Maschinen.",
  "data for current day": "Daten für den aktuellen Tag",
  "Show / hide geofzonee on map": "Geozone auf der Karte ein-/ausblenden",
  "Show / hide geozone detail information": "Detailinformationen zur Geozone ein-/ausblenden",
  "Select map tool": "Kartenwerkzeug auswählen",
  "Geozone type": "Typen der Geozone",
  "Delete": "Löschung",
  "Delete geozone": "Löschung der Geozone",
  "Edit geozone": "Editiermodus der Geozone",
  "Enable geozone editing mode": "Aufruf des Editiermodus der Geozone",
  "save": "Sparen",
  "cancel": "Stornieren",
  "The range between the selected dates must not exceed 30 days": "Der Bereich zwischen den ausgewählten Datumsangaben darf 30 Tage nicht überschreiten",
  "The interval selected is too large": "Das Intervall ist zu groß ausgewählt",
  "Close": "Schließen",
  "Name": "Name",
  "Surname": "Nachname",
  "middle name": "Zweiter Vorname",
  role: "Position",
  "By mode": "Nach Modus",
  "By days": "Von Tagen",
  "Analysis of working time by mode": "Analyse der Arbeitszeit nach Modus",
  "Analysis of working time by day": "Analyse der Arbeitszeit nach Tag",
  "Graph of the analysis of working time by group / unit of equipment for the selected period": "Grafik der Analyse der Arbeitszeit nach Gerätegruppe / -einheit für den ausgewählten Zeitraum",
  "Graph of the analysis of working time for the group / unit of equipment for the selected period It is possible to turn on / off the display of modes": "Diagramm der Analyse der Arbeitszeit für die Gruppe / Einheit der Ausrüstung für den ausgewählten Zeitraum. Es ist möglich, die Anzeige der Modi ein- oder auszuschalten",
  "Fuel in the tank at the beginning of the day": "Kraftstoff im Tank zum Tagesbeginn",
  "Fuel in the tank at the end of the day": "Kraftstoff im Tank zum Tagesende",
  "Refills per day": "Tankungen pro Tag",
  "Total consumption": "Gesamtkraftstoffverbrauch",
  "Consumption in transport mode": "Verbrauch beim Transport",
  "Consumption in cleaning / work": "Verbrauch Ernte/Arbeit",
  "Idle flow": "Verbrauch im Stand",
  "Average consumption": "Durchschnittlicher Kraftstoffverbr",
  "ICE working time": "Betriebsdauer Motor",
  "Time in transport mode": "Zeit im Transportmodus",
  "Time in cleaning / work": "Zeit während der Ernte/Arbeit",
  "Idle time": "Zeit im Stillstand",
  "Table filling/drain": "Tabelle der Ablässe/Tankungen",
  "Volume": "Lautstärke",
  "Other": "Andere",
  "Combine Harvesters": "Mähdrescher",
  "Forage Harvesters": "Feldhäcksler",
  "Power facilities": "Kraftanlagen",
  "Tractors": "Traktoren",
  "Treated area": "Behandelte Fläche",
  "Distance traveled": "Zurückgelegte Strecke",
  "Engine running time": "Motorlaufzeit",
  "Fuel consumption": "Kraftstoffverbrauch",
  "Average time performance": "Durchschnittliche Zeitleistung",
  "Yes": "Ja",
  "No": "Nein",
  "Work performance graph": "Diagramm der Arbeitskennzahlen",
  "The choice of parameters for display on the graph (with the ability to change color)": "Parameterauswahl zur Anzeige auf dem Diagramm (Farbe wechselbar)",
  "Select graphics": "Grafik auswählen",
  "Types de rapports disponibles pour exportation dans un fichier Export disponible au format Excel": "Arten der Berichte, die in eine Datei exportiert werden können. Export in eine Excel- möglich",
  "Download": "Herunterladen",
  "The number of units in different modes in the household": "Anzahl der Einheiten in verschiedenen Modi im Landwirtschaftsbetrieb",
  "Department of service vehicles": "Abteilung für Servicefahrzeuge",
  "Spare Parts Sales Department": "Abteilung Ersatzteilverkauf",
  "Support Agrotronic": "Support-Service Agrotronic",
  "Sprinklers": "Sprinkler",
  "Mower": "Mäher",
  "Appearance vehicles": "Aussehen von Fahrzeugen",
  "Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment": "Aktuelle Informationen zur Technik: Füllgrad des Kraftstofftanks, aktueller Status des Betriebs der Technik, Datum und Zeit der letzten Aktivität der Technik",
  "Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)": "Aktivitätsstatus der Verfolgungsfunktion der Technik (beim Anklicken mit der rechten Maustaste wird das Tracking dieser Technikeinheit aktiviert)",
  "Refresh": "Aktualisieren",
  "Detailed parameters of the equipment": "Ausführliche Betriebsparameter der Technik",
  "List of user geozones with the search function by name": "Liste von Geozonen des Benutzers mit der Suche nach Namen",
  "List of households and units of user equipment with the search function by name": "Liste von Landwirtschaftsbetrieben und Technikeinheiten mit der Funktion der Suche nach Namen",
  "Toggle viewing of objects on the map": "Umschaltung des Anzeigemodus der Objekte auf der Karte",
  "Event name": "Name des Ereignisses",
  "Quantity for the period": "Anzahl für einen Zeitraum",
  "Duration for the period": "Dauer für einen Zeitraum",
  "Event start date": "Datum des Beginns des Ereignisses",
  "Event start time": "Zeit des Beginns des Ereignisses",
  "Event end time": "Zeit von Ereignisende",
  "Event duration": "Dauer des Ereignisses",
  "Event location": "Standort des Ereignisses",
  "Machine condition for the selected period": "Maschinenzustand für den ausgewählten Zeitraum",
  "Report Type Selection Menu": "Menü der Auswahl der Berichtsart",
  "Select one or more values": "Auswahl von einem oder mehreren Werten",
  "Time period selection": "Auswahl des Zeitraums",
  "Apply selected parameters in the filter": "Übernahme der ausgewählten Parameter im Filter",
  "Search the machine by serial number": "Suche der Maschine nach der Seriennummer",
  "Select one unit of equipment": "Auswahl einer Technikeinheit",
  "Choice of equipment / group of equipment": "Auswahl der Technik/einer Gruppe der Technik",
  "Single parameter selection": "Auswahl eines Einzelparameters",
  "wrong login or password": "Ungültiger Benutzername oder Passwort",
  "Something went wrong. Error code": "Irgendwas ist schief gelaufen. Fehlercode",
  "Login": "Anmeldung",
  "Password": "Passwort",
  "Entry": "Einloggen",
  "Demo mode": "Demo-Modus",
  "Vechicle info": "Informationen zur Maschine: Symbol des Maschinentyps (Farbe: grau – „Keine Verbindung, schwarz – „Aus“ (Arbeit ohne eingeschaltete Zündung), rot – „Stillstand mit laufendem Motor“, blau – „Fahrt“, grün – „Ernte/Arbeit“). Beim Tippen auf die Modellbezeichnung oder Seriennummer wird die Seite mit ausführlichen Informationen zur Maschine geöffnet.",
  "The time of the first start of the engine for the current day": "Zeit des ersten Motorstarts während des laufenden Tages",
  "Date and time of the last communication session": "Datum und Zeit der letzten Verbindungssitzung",
  "Pie chart modes of operation for the current day": "Kreisdiagramm der Betriebsmodi der Technik während des laufenden Tages",
  "Average speed of equipment in the mode of combining / working for the current day": "Durchschnittliche Geschwindigkeit der Technik beim Mähdrusch/bei der Arbeit für den laufenden Tag",
  "Fuel consumption for the current day": "Kraftstoffverbr. der Technik für den laufenden Tag",
  "Cleaned / treated area for the current day": "Geerntete/bearbeitete Fläche für den laufenden Tag",
  "Productivity of technology for the current day": "Leistung der Technik für den laufenden Tag",
  "Tracking Button": "Taste der Aktivierung der Verfolgungsfunktion",
  "Notification text": "Text der Meldung",
  "Notification category": "Kategorie der Meldung",
  "Date and time start of notification": "Datum und Zeit der Einschaltung der Meldefunktion",
  "Date and time finish of notification": "Datum und Zeit der Rücksetzung der Meldefunktion",
  "Duration of notification": "Dauer der Meldung",
  "Notification location": "Standort der Meldung",
  "List of farm equipment (indicating the number of monitored equipment)": "Liste der Technik des Landwirtschaftsbetriebs (mit Angabe der Anzahl der verfolgten Technik)",
  "dont machine": "Maschine nicht gefunden oder nicht im Besitz des Benutzers",
  "Timezone": "Zeitzone",
  "Phone": "Telefon",
  "Phone number must be at least 11 characters": "Die Telefonnummer muss aus mindestens 11 Zeichen bestehen",
  "Planning": "RSM Router",
  "Agrotronic": "Agrotronic",
  "Alerts": "Warnungen",
  "Fields": "Felder",
  "Production operations": "Produktionsbetrieb",
  "Technological maps": "Technologische Karten",
  "Alert tasks": "Benachrichtigungsaufgaben",
  "Inbox": "Posteingang",
  "Catalogs": "Kataloge",
  "Are you sure you want to delete the geozone": "Möchten Sie den Geofence wirklich löschen?",
  "Currently edit imported track do not support": "Derzeit importierte Titel nicht unterstützen",
  "Productivity map": "Produktivitätskarte",
  "Productivity map wet KUK": "Produktivitätskarte KUK nach Nassgewicht",
  "Productivity map dry KUK": "Produktivitätskarte KUK nach Trockengewicht",
  "Productivity": "Produktivität",
  "Create map": "Karte erstellen",
  "Download map": "Karte herunterladen",
  "Part width": "Teilebreite",
  "Choose vehicles": "Wählen Sie Fahrzeuge",
  "Create": "Erstellen",
  "Deactivated": "Deaktiviert",
  "Activated in the automatic NC hold mode": "Aktiviert im automatischen NC-Hold-Modus",
  "Activated in the mode of automatic maintenance of the cut height": "Aktiviert im Modus der automatischen Wartung der Schnitthöhe",
  "Activated in the automatic pressure maintenance mode": "Aktiviert im automatischen Druckhaltemodus",
  "403 FORBIDDEN \"Too many machines selected\"": "Zu viele Autos ausgewählt",

  "km/h": "km/h",
  "l": "l",
  "ha/h": "ha/h",
  "ha": "ha",
  "ms": "ms",
  "h": "h",
  "m": "м",
  "min": "min",
  "t/ha": "t/ha",
  "cwt/ha": "cwt/ha",
  // MAP
  "fullscreen": "Karte im Vollbild öffnen",
  "exit fullscreen": "Beenden Sie den Vollbildmodus",
  "zoom in": "Hineinzoomen",
  'zoom out': "Rauszoomen",
  "Search the machine": "Durchsuche die Maschine",
  "Filter": "Filter",
  contact_information: "Kontaktinformationen",
  iAcceptTerms: "Durch die fortgesetzte Nutzung der APK Agrotronic stimmen Sie den Bedingungen zu",
  termsOfUse: "Nutzungsbedingungen",
  deleteInfo: "Daten löschen",
  deleteInfoQuestion: "Möchten Sie wirklich alle Daten löschen?",
  "Apply filter": "Filter anwenden",
  "of": "von",
  "Rows per page": "Zeilen pro Seite",
  "Incorrect time period": "Falscher Zeitraum ausgewählt",
  maxVehicleCountRequest: "Maximale Anzahl der zu analysierenden Maschinen:",
  "The weather info currently unvailable": "Die Wetterinformationen sind derzeit nicht verfügbar",
  "Create geozone for receive weather info": "Create geozone for receive weather info",
  "square": "Bereich",
  "Total amount of cultivated area": "Gesamtmenge der Anbaufläche",
  "All units": "Gesamtausrüstung",
  "unit": "Maschinen",
  "unit_plural": "Maschinen",
  "Maintance": "Wartung",
  "weather": "Weer",
  "Widgets": "Widget-Bibliothek",
  "antialiasing": "Kantenglättung",
  "Move forward": "Vorwärts",
  "Neutral gear": "Neutral",
  "Back": "Zurück zurück",
  "Parking": "Parken",
  "download app": "Laden Sie die App herunter",
  predictUnloading: 'Geschätztes Entladen',
  "Download app for any device": 'Laden Sie die App für jedes Gerät herunter',
  "license plate": "Regierungskennzeichen",
  brand: "Marke",
  allowed: "Erlaubt",
  forbidden: "Verboten",
  transport: "Fahrzeug",
  "Error load file: need type file zip": "Fehler beim Laden der Datei: Typdatei .zip erforderlich",
  "Error load file: need type file txt": "Fehler beim Laden der Datei: Typdatei .txt erforderlich",
  "Error load file": "Fehler Datei laden.",
  "Need file types": "Gültige Dateitypen:",
  "File downloaded": "Datei heruntergeladen",
  "File read error": "Fehler beim Lesen der Datei",
  "Coordinates are missing in the file": "Koordinaten fehlen in der Datei",
  "Shape file is not correct": "Formdatei ist nicht korrekt",
  "Shape file have not supported type": "Die Shape-Datei dieser Struktur wird nicht unterstützt. Diese Version unterstützt nur Strukturen wie Polygon, MultiLineString, LineString, MultiPoint",
  "num of unloading": "Anzahl der Entladungen",
  "osht": "OSHT",
  "download apk": "APK herunterladen",
  "no data": "Keine Daten für den ausgewählten Zeitraum",
  "report equipment": "Betriebszeitberichte der Geräte",
  "report performance": "Leistung",
  "operating time": "Laufzeit",
  "eh": "Std",
  "aggregate": "Einheit",
  "aggregates": "Aggregate",
  "Unknown transport": "Unbekannte Strecke",
  "value must be": "Der Wert muss",
  "greater than previous one": "größer als der vorherige sein",
  "less than following": "weniger als folgen",
  "value cannot be less than 0": "Wert darf nicht kleiner als 0",
  "Date and time": "Datum und Uhrzeit",
  "Report": "Leistungsbericht",
  "during the period": "während der phase",
  "Parameters": "Parameter",
  "Parameter": "Parameter",
  "Meaning": "Wert",
  "Error creating file": "Fehler beim Erstellen der Datei",

  //  Культуры
  "bean": "Bohne",
  "mustard": "Senf",
  "pea": "Erbse",
  "buckwheat": "Buchweizen",
  "dactylis glomerata": "Dactylis glomerata",
  "clover": "Klee",
  "castor-oil plant": "Rizinuspflanze",
  "hemp": "Hanf",
  "maize corn": "Mais",
  "sesame": "Sesam",
  "lucerne": "Luzerne",
  "flax": "Flachs",
  "papaver": "Papaver",
  "chick-pea": "Kichererbse",
  "oat": "Hafer",
  "festuca pratensis": "festuca pratensis",
  "panicum": "panicum",
  "sunflower": "Sonnenblume",
  "wheat": "Weizen",
  "wheat winter": "Weizenwinter",
  "wheat spring": "Weizenfrühling",
  "arrhenatherum": "arrhenatherum",
  "rape": " Raps",
  "rice": "Reis",
  "rye": "Roggen",
  "safflower": "Distel",
  "sugar-beet": "Zuckerrüben",
  "sorghum": "Sorghum",
  "triticale": "Triticale",
  "soy": "Soja",
  "haricot": "Haricot",
  "cotton": "Baumwolle",
  "lentil": "Linse",
  "barley": "Gerste",
  "barley winter": " Gerstenwinter",
  "barley spring": "Gerstenfrühling",
  "user1": "user1",
  "user2": "user2",
  "user3": "user3",
  "user4": "user4",
  "corn long wet": "mais lang nass",
  "corn long": "mais lang",
  "corn long dry": "mais lang trocken",
  "corn short wet": "mais kurz nass",
  "corn short": "corn kurz",
  "corn short dry": "Mais kurz trocken",
  "silage": "Silage",
  "grass": "Gras",
  "grass dry": "Gras trocken",
  "grass normal": "Gras normal",
  "grass wet": "Gras nass",
  "User manual Agrotronic Pilot": "Bedienungsanleitung Agrotronic Pilot",
  "User manual Agrotronic": "Bedienungsanleitung Agrotronic",
  "Agrotronic Administrator Tools Description": "Beschreibung der Agrotronic APK Admin Tools",
  "User manual cruise control KUK": "Benutzerhandbuch Cruise control KUK",
  "Humidity sensor FH": "User's Guide Yield Mapping for chopper",
  "n/d": "keine Daten verfügbar",
  "about field": "über das Feld",
  "about operator": "über Betreiber",
  "about technique": "über Technik",
  "about task": "über die Aufgabe",
  "about operation": "über die Operation",
  "operation": "Betrieb",
  "task": "Übung",
  "reporting service is not available": "Meldedienst ist nicht verfügbar",
  "Select data source": "Wenn diese Funktion aktiviert ist, werden die Ertrags- und Feuchtigkeitskarten in Bereichen, in denen keine Daten vom PCM des Agrotronikpiloten (Tablet) vorhanden sind, auf der Grundlage der Daten des normalen Bordsystems erstellt",
  "Select type of map": "Wählen Sie den Kartentyp",
  "service is not available": "Service ist nicht verfügbar",
  "track service is not available": "Gleisbauservice ist nicht verfügbar",
  "all sht": "Alle Landmaschinen",
  "operators": "Betreiber",
  "all fields": "Alle Felder",
  "all operators": "Alle Betreiber",
  "find operator": "Suchen Sie einen Betreiber",
  "find field": "Feld suchen",
  "find technique": "eine Technik finden",
  "formed": "Gebildet",
  "performance report for the period": "Leistungsbericht für den Zeitraum",
  "the report is generated with filters": "Der Bericht wird mit Filtern erstellt",
  "twenty-four hours": "tag",
  "hours": "uhr",
  "minutes": "protokoll",
  "incl": "inkl.",
  "total according to the report": "insgesamt laut bericht",
  "driver report": "Fahrerbericht",
  "title": "Titel",
  "date added": "Datum hinzugefügt",
  "change history": "Geschichte verändern",
  "changed to": "Gewechselt zu",
  "changed": "Geändert",
  "date of change": "Datum der Änderung",
  "driver": "treiber",
  "biometrics changed": "biometrie geändert",
  "information": "Information",
  "driver information": "Fahrerinformationen",
  "the report was generated without filters": "Der Bericht wurde ohne Filter erstellt",
  "holding": "halten",
  "Threshing sensor used": "Der Dreschsensor wird verwendet",
  "n period": "Zeitraum Nr.",
  "startHarvesting": "Beginn der Ernte",
  "endHarvesting": "Ende der Ernte",
  "periodHarvesting": "Erntezeit",
  "harvested agriculture": "Geerntete Pflanzen",
  "density": "Dichte, kg / Kubikmeter",
  "Harvesting time for period": "Reinigungszeit für den Zeitraum, h",
  "Harvested crop for period": "Geerntete Ernte für den Zeitraum, t",
  "Harvesting time": "Erntezeit",
  "Harvested crop": "Geerntete Ernte",
  "Users": "Benutzer",
  "Farms": "Bauernhöfe",
  "AdminPanel": "Adm. menü",
  "Organizations types": "Arten von Organisationen",
  "Roles": "Rollen",

  "report unloading": "Bericht zum Hochladen",
  "You can request the code again via": "Sie können den Code erneut anfordern",
  "Confirm": "Bestätigen",
  "Send SMS code": "SMS-Code senden",
  "SMS code": "SMS-Code",
  "Confirmation": "Bestätigung",
  "Change phone": "Telefon ändern",
  "To change the phone number, you must enter a confirmation code": "Um die Telefonnummer zu ändern, müssen Sie den Bestätigungscode eingeben, der in einer SMS-Nachricht an die neue Telefonnummer gesendet wird",
  "Two-factor authentication": "Zwei-Faktor-Authentifizierung",
  "To confirm the action": "Um die Aktion zu bestätigen, müssen Sie einen Bestätigungscode eingeben, der in einer SMS-Nachricht an Ihr Telefon gesendet wird.",
  "To confirm the changes": "Um die Änderungen zu bestätigen, müssen Sie einen Bestätigungscode eingeben, der in einer SMS-Nachricht an Ihr Telefon gesendet wird.",
  "Error confirming code": "Fehler bei der Bestätigung",
  "Error occurred during validation process": "Während des Validierungsprozesses ist ein Fehler aufgetreten",
  "Invalid address format": "Die E-Mail-Adresse ist im falschen Format angegeben",
  "The phone number is specified in the wrong format": "Die Telefonnummer ist im falschen Format angegeben",
  "Wrong code": "Ungültiger Code",
  "Phone number successfully changed": "Telefonnummer wurde erfolgreich geändert",
  "Changes successfully saved": "Änderungen erfolgreich gespeichert",
  "Display machine data": "Daten nach Maschinen anzeigen",
  "Map Legend Management": "Map Legend Management",
  "Error loading calendar coloring pages": "Fehler beim Laden der Kalender-Färbung",
  "Error while receiving data": "Fehler beim Empfangen der Daten",
  "Serial number": "Seriennummer",
  "Cocked": "Gespannt",
  "Reset": "Zurückgesetzt",
  "Duration, hh:mm:ss": "Dauer, hh:mm:ss",
  "hh:mm:ss": "hh:mm:ss",
  "Username is already in use, use a different one": "Benutzername wird bereits verwendet, verwenden Sie einen anderen",
  "Login can contain only numbers and Latin letters": "Login kann nur Zahlen und lateinische Buchstaben enthalten",
  "Login cannot consist only of numbers": "Ein Login kann nicht nur aus Zahlen bestehen",
  "Login cannot be less than 6 characters": "Login kann nicht kleiner als 6 Zeichen sein",
  "The password must contain at least 8 characters": "Mindestens 8 Zeichen, müssen Groß- und Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten",
  "Password can only contain numbers, latin letters and special characters": "Das Passwort kann nur Zahlen, lateinische Buchstaben und Sonderzeichen enthalten",
  "Select a different report or change the filtering conditions": "Wählen Sie einen anderen Bericht aus oder ändern Sie die Filterbedingungen",
  "There is no data on which to build this report": "Es gibt keine Daten, mit denen dieser Bericht erstellt werden kann",
  "Enter the code sent to the number": "Geben Sie den Code ein, der an die Nummer gesendet wurde",
  "Enter the code sent to the address": "Geben Sie den Code ein, der an die Adresse gesendet wurde",
  "An SMS with a confirmation code will be sent to the phone": "Eine SMS mit einem Bestätigungscode wird an das Telefon gesendet, um {number} zu bestätigen",
  "To the address an email with a confirmation code will be sent": "Eine E-Mail mit einem Bestätigungscode wird an {email} gesendet",
  "Only mobile numbers of the Russian Federation with the code +7": "Nur Mobilfunknummern der Russischen Föderation mit dem Code +7",
  "Phone Confirmation": "Telefon bestätigen",
  "Email Confirmation": "E-Mail-Bestätigung",
  "Enter code": "Code eingeben",
  "On": "Ein",
  "Event": "Ereignis",
  "Web": "Web",
  "SMS": "CMC",
  "Action Confirmation": "Aktion bestätigen",
  "To the address": "An die Adresse",
  "confirmation code will be sent": "Eine Bestätigungsmail wird gesendet",
  "To the phone": "Auf das Telefon",
  "SMS with a confirmation code will be sent": "Eine SMS mit einem Bestätigungscode wird gesendet",

  ////account block module warning
  "Logging in using enterprise accounts will be blocked in the near future": "⚠️ Die Anmeldung mit Enterprise accounts wird in Kürze blockiert",
  "Contact the administrator of the company or department to obtain a personal account": "Wenden Sie sich an den Administrator des Unternehmens oder der Abteilung, um ein persönliches Konto zu erhalten",
  "The changes are aimed at improving data security and expanding usercapabilities": "Die Änderungen zielen darauf ab, die Datensicherheit zu verbessern und die Benutzererfahrung zu verbessern",
  "Continue working": "Weiterarbeiten",
  "You have changed the display of machine data": "Sie haben die Anzeige von Maschinendaten geändert. Aktualisieren Sie die Informationen, damit die Ertragsdaten korrekt angezeigt werden.",
  "Select at least one machine to build a field field report": "Wählen Sie mindestens eine Maschine aus, um einen Feldherstellungsbericht zu erstellen",
  "Mapped": "Kartiert",
  "Avg yield": "Durchschnittlicher Ertrag",
  "Total yield": "Geerntete Ernte",
  "The area of the geofence without taking into account the relief of the earth": "Die Fläche der Geofence ohne Berücksichtigung der Erd-Erleichterung",
  "The area of the geofence for which there is data on yield": "Die Fläche der Geofence, für die Daten zum Ertrag vorhanden sind",
  "Average yield of the mapped port of the geofence": "Durchschnittlicher Ertrag eines kartierten Teils des Geofence",
  "Indicative (estimated) data obtained on the yield of the field": "Geschätzte (geschätzte) Daten, die nach dem Ertrag des Feldes abgerufen wurden",
  "Download the field yield report": "Download the field yield report",
  "T": "t",
  "Field Yield Report": "Feld-Ertragsbericht",
  "Equipment (Equipment that was in the defence during the specified period)": "Maschinen (Maschinen, die sich während des angegebenen Zeitraums im Geofence befanden)",
  "Map type": "Kartentyp",
  "Productivity map ZUK": "Produktivitätskarte ZUK",
  "Moisture map ZUK": "Moisture map ZUK",
  "Moisture map KUK": "Moisture map KUK",
  "Appearance": "Aussehen",
  "Elimination": "Beseitigung",
  "Tire pressure": "Reifendruck",
  "Auto-guidance system": "Automatisches Leitsystem",
  "View location is not available, the machine has not yet set the coordinates": "Standort anzeigen ist nicht verfügbar, die Maschine hat die Koordinaten noch nicht gesendet",
  "pcs": "stück",
  "RSM User Manual Auto-unloading KUK": "RSM-Benutzerhandbuch Automatisches Entladen von KUK",
  "Unknown agriculture": "Unbekannte Landwirtschaft"
};
export default de;
