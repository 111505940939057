import { action, computed, configure, makeObservable, observable } from 'mobx'

configure({ enforceActions: 'observed' })

export default class TextInputStore {
  @observable value = '';
  @observable initValue = '';

  constructor(value = '') {
    this.value = value
    makeObservable(this)
  }

  @action.bound setValue(value = '') {
    this.value = value
  }

  @action.bound
  setInitValue(value = '') {
    this.initValue = value
    this.setValue(value)
  }

  @computed
  get newValue() {
    if(this.initValue === this.value){return undefined}

    if(this.value === '' || typeof this.value === 'undefined'){return null}

    return this.value
  }
}
