import { constants, utils } from '@agdt/agrotronic-react-components'
import { assoc, dissoc } from 'ramda'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { TaskType } from 'reducers/notificationTasks/state'
import { AppStateType } from 'reducers/store'
import { UserInfoType } from 'reducers/user/state'
import { userLogout as userLogoutAction } from 'reducers/users/actions'
import { editInfo, updateCurrentUser } from 'services/apiService/modules'
import { useDemoMode } from 'services/hooks'
import { successMessage } from 'services/notification'
import styled from 'styled-components'
import { urlApi } from '../../constants/env'
import { editTask as editTaskAction } from '../../reducers/notificationTasks/actions'
import { userInfo } from '../../reducers/user/actions'
import { parseAccountFromApi } from '../../services/models/account'
import { parseFromApi } from '../../services/models/user'
import { errorMessage } from '../../services/notification'
import { UserAvatar } from './Avatar'
import { Info } from './Info'
import { Notifications } from './Notifications'

const { screenResolutions } = utils

const getAccountFieldName = (fieldName: string): string => {
  return {
    operatorEmail     : 'email',
    operatorFirstName : 'fullName',
    operatorSecondName: 'middleName',
    operatorSurname   : 'lastName',
  }[fieldName] || fieldName
}

export const Profile: FC = () => {
  const notificationTasks = useSelector((state: AppStateType) => state.notificationTasks.tasks)
  const user = useSelector((state: AppStateType) => state.user.info)
  const dispatch = useDispatch()
  const t = useTranslate()
  const isDemo = useDemoMode()
  const [isFieldSave, setIsFieldSave] = useState({})

  const userLogout = () => {
    userLogoutAction()
  }

  const editTask = (response: TaskType) => {
    dispatch(editTaskAction(response))
  }

  const updateUserInfo = (response: UserInfoType) => {
    dispatch(userInfo(response))
  }

  const handleSetIsFieldSave = (fieldName: string, isSaved: boolean) => {
    setIsFieldSave({
      ...isSaved
        ? assoc(fieldName, true, isFieldSave)

        //@ts-expect-error
        : dissoc(fieldName, isFieldSave),
    })
  }

  const handleSave = useCallback((fieldName: string, value) => {
    handleSetIsFieldSave(fieldName, true)
    const isUser = user.accountType === 'user'
    const updateFunction = isUser ? updateCurrentUser : editInfo
    const field = isUser ? fieldName : getAccountFieldName(fieldName)

    updateFunction({ [field]: value })
      .then(response => {
        updateUserInfo(isUser ? parseFromApi(response) : parseAccountFromApi(response))
        successMessage(t('saveProfileDone'))
      })

      .catch(() => errorMessage(t('saveProfileError')))
      .finally(() => {
        if(fieldName === 'operatorLogin'){
          userLogout()
        }

        handleSetIsFieldSave(fieldName, false)
      })
  }, [user.accountType])

  return (
    <Page>
      <LeftColumn>
        <StickyBlock>
          <UserAvatar
            disabled={!!isDemo}
            email={user.email}
            fullName={user.fullName}
            handleSave={handleSave}
            img={user.photo}

            //@ts-expect-error
            isFieldSave={isFieldSave['photo']}
            lastName={user.lastName}
            t={t}
          />
        </StickyBlock>
      </LeftColumn>

      <RightColumn>
        <Info
          disabled={!!isDemo}
          handleSave={handleSave}
          isFieldSave={isFieldSave}
          updateUserInfo={updateUserInfo}
          user={user}
        />

        <Notifications
          editTask={editTask}
          isUser={user.accountType === 'user'}
          notifications={notificationTasks}
          smsChanelIsDisabled={user.accountType === 'user' && !Boolean(user.operatorTelNumber)}
        />

        <Terms>
          <span>{`${t('iAcceptTerms')}`}</span>
          <a href={`${urlApi}/doc/rights.html`} target="_blank" rel="noopener noreferrer">
            {`${t('termsOfUse')}`}
          </a>
        </Terms>
      </RightColumn>
    </Page>
  )
}

const Terms = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${constants.colors.PRIMARY_IMPORTANT};
    padding-left: .25em;
  }
`

const Page = styled.div`
  height: 100%;
  background-color: #EBECE7;
  padding: 20px;
  display: flex;
  @media (max-width: ${screenResolutions.PAL}) {
    flex-direction: column;
  };
`

const LeftColumn = styled.div`
  box-sizing: border-box;
  flex-basis: 278px;
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 20px;
  @media (max-width: ${screenResolutions.PAL}) {
    width: 100%;
    padding: 0;
  }
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
`

const StickyBlock = styled.div`
  position: sticky;
  top: 20px;
  @media (max-width: ${screenResolutions.PAL}) {
    position: static;
  }
`
