import { action, makeObservable, observable } from 'mobx'

export default class StatusesSelector {
  @observable readonly selectedStatuses: Set<number> = new Set()
  @observable readonly selectedStatusesByHouseholds: Map<number, Set<number>> = new Map()

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setSelectedStatus(statusId: number) {
    this.selectedStatusesByHouseholds.clear()
    this.selectedStatuses.has(statusId) ? this.selectedStatuses.delete(statusId) : this.selectedStatuses.add(statusId)
  }

  @action.bound
  setSelectedStatusesForHousehold(householdId: number, statusId: number) {
    this.selectedStatuses.clear()

    if(!this.selectedStatusesByHouseholds.has(householdId)) {
      this.selectedStatusesByHouseholds.set(householdId, new Set([statusId]))
    } else {
      this.selectedStatusesByHouseholds.get(householdId)?.has(statusId)
        ? this.selectedStatusesByHouseholds.get(householdId)?.delete(statusId)
        : this.selectedStatusesByHouseholds.get(householdId)?.add(statusId)

      if(this.selectedStatusesByHouseholds.get(householdId)?.size === 0) {
        this.selectedStatusesByHouseholds.delete(householdId)
      }
    }
  }

  @action.bound
  clear() {
    this.selectedStatuses.clear()
    this.selectedStatusesByHouseholds.clear()
  }
}