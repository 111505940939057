import { CheckBox } from '@agdt/agrotronic-react-components'
import React from 'react'
import styled from 'styled-components'

export type TProps = {
  checked: boolean
  color?: string
  name: string
  setChecked: (unit: string) => void
  value: string
}

export const SingleCheckbox = ({
  checked,
  color,
  name,
  setChecked,
  value,
}: TProps) => {
  const handlerChecked = () => {
    setChecked(value)
  }

  return (
    <CheckBoxContainer>
      <CheckBox
        checked={checked}
        onChange={handlerChecked}
      />
      <CheckBoxLabel color={color} onClick={handlerChecked}>{name}</CheckBoxLabel>
    </CheckBoxContainer>
  )
}

const CheckBoxContainer = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const CheckBoxLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #202020;
  cursor: pointer;

  &:before {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${({ color }) => color ? color : 'none'};
    content: "";
    display: ${({ color }) => color ? 'inline-block' : 'none'};
    margin-right: 12px;
  }
`
