import { Search } from '@agdt/agrotronic-react-components'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { getStore } from '../../../stores/storesRegistry'

type Props = {
  translate: TTranslate
}

const SearchMachines: FC<Props> = ({ translate }) => {
  const store = getStore('notices')

  return (
    <SearchWrapperStyled>
      <Search
        value={store.units.list.searchValue.value}
        onChange={store.units.list.searchValue.setValue}
        placeholder={translate('Search the machine')}
        disableBorder
      />
    </SearchWrapperStyled>
  )
}

const SearchWrapperStyled = styled.div`
  display: flex;
  min-height: 86px;
  align-items: center;
  border-bottom: 1px solid #E2E5EC;
`

export default withTranslate(observer(SearchMachines))
