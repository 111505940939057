import { formats } from '@agdt/agrotronic-react-components'
import { isNil } from 'ramda'

/**
 * Хук для работы с часовым поясом пользователя
 * @returns объект с двумя функциями: getDateWithUserTimeZone и setTimeZone
 */
export const useUserTimezone = () => {
  /**
   * Функция, преобразующая дату в часовой пояс пользователя
   * @param date - дата для преобразования
   * @returns дата с учётом часового пояса пользователя
   */
  const getDateWithUserTimeZone = (date: Date): Date => new Date().user.new(date)

  /**
   * Функция для установки часового пояса
   * @param gmt - смещение часового пояса
   */
  const setTimeZone = (gmt?: number): void => {
    formats.date.extendDate(isNil(gmt) ? new Date().getTimezoneOffset() / -60 : gmt)
  }

  return {
    getDateWithUserTimeZone,
    setTimeZone,
  }
}
