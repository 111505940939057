import { IconButton } from '@agdt/agrotronic-react-components'
import React, { useMemo } from 'react'
import { ResizeIcon } from './ResizeIcon'

export type TProps = {
  toggle?: () => void
  iconColor: string
  isCompact?: boolean
}

export default function ResizeButton({
  toggle,
  iconColor,
  isCompact,
}: TProps) {
  const iconProps = useMemo(() => ({ color: iconColor, isCompact }), [iconColor, isCompact])

  return <IconButton

    /*@ts-expect-error*/
    icon={ResizeIcon}
    iconProps={iconProps}
    onClick={toggle}
    title={''}
  />
}
