import L from 'leaflet'
import { observer } from 'mobx-react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Map } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { filteredUsersOfType } from 'reducers/users/selectors'
import { UnitType, UserType } from 'reducers/users/state'
import { getStore } from 'stores/storesRegistry'
import Track from '../Track'
import { Markers } from './Markers'

type EquipmentTrackProps = {
  currentMap: Map
}

export const EquipmentTrack: FC<EquipmentTrackProps> = observer(({ currentMap }) => {
  const users: UserType[] = useSelector(filteredUsersOfType)
  const showArrows = getStore('householdUnits').showTrackArrow
  const [noZoomTrack, setNoZoomTrack] = useState(false)

  const handleDeleteTrack = useCallback(({ imei }: {imei: number}) => {
    getStore('householdUnits').getUnit(imei).deleteTrack()
    getStore('householdUnits').deleteTrackArrow()
  }, [])

  useEffect(() => {
    const findUnit = users.reduce((acc: UnitType | undefined, { units: { units } }) => {
      acc = units.find(({ track }) => track?.activeTrack?.[track?.type]?.length)

      return acc
    }, undefined)

    if(findUnit) {
      const arrayPolyline = findUnit?.track?.activeTrack?.[findUnit?.track?.type]?.reduce(
        (acc: [number, number][], item) => [
          ...acc,
          ...item.path.map(it => [it.lat, it.lng]) as [number, number][],
        ], []) || []

      //TODO изменять границы карты чтобы все маркеры было видно
      currentMap.leafletElement.flyToBounds(L.latLngBounds(arrayPolyline), {
        maxZoom: 18,
      })
    }

    return () => {
      getStore('householdUnits').clearHouseholdUnitsTrack()
    }
  }, [])

  return <>
    <Track
      arrow={showArrows}
      deleteTrack={handleDeleteTrack}
      map={currentMap}
      noZoom={noZoomTrack}
    />

    <Markers
      currentMap={currentMap}
      setNoZoomTrack={setNoZoomTrack}
    />
  </>
})
