import DropdownSortMachines from 'pages/Notification/MachinesList/DropdownSortMachines'
import Machines from 'pages/Notification/MachinesList/Machines'
import React, { FC } from 'react'
import styled from 'styled-components'

const MachinesList: FC = () => {
  return (
    <MachinesListStyled>
      <DropdownSortMachines />
      <Machines />
    </MachinesListStyled>
  )
}

const MachinesListStyled = styled.section``

export default MachinesList
