import { isEmpty, map, prop } from 'ramda'
import React, { FC,useCallback, useMemo } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { MultiselectWithSearch } from '../MultiselectFilter'

type Props = {
  items: {
    name: string
    id: unknown
  }[]
  section: string
  result: unknown
  onStartFilterArrayMachine: (value: unknown[]) => void
}

const getModel = ({ model }: {model: unknown}) => model

export const FilterItemMultyWrapper: FC<Props> = ({ items, onStartFilterArrayMachine, result, section }) => {
  const t = useTranslate()

  const machinesPrepared = useMemo(() => {
    return [{
      categoryId: 1,
      items     : items,
      name      : t('sht'),
    }]
  }, [items, t])

  const preparedCheckedOptionsIds = useMemo(() => {
    const checkedOptionsIds = map(prop('id'), result)

    return !isEmpty(checkedOptionsIds)
      ? { 1: checkedOptionsIds }
      : {}
  }, [result])

  const setCheckedIds = useCallback((_, filterValue) => {
    onStartFilterArrayMachine(filterValue[1] || [])
  }, [onStartFilterArrayMachine])

  return <MultiselectWithSearch
    checkedOptionsIds={preparedCheckedOptionsIds}
    filterName={section}
    label={t('sht')}
    options={machinesPrepared}
    searchInputLabel={t('find technique')}
    setCheckedIds={setCheckedIds}

    //@ts-expect-error
    getTypeName={getModel}
  />
}
