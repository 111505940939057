import { GeoZoneActionsType,GeoZoneActionTypeEnum } from './actions'
import * as State from './state'

export default function geoZone(state = State.init(), action: GeoZoneActionsType): State.GeoZone {
  switch(action.type) {
    case GeoZoneActionTypeEnum.SET_GEOZONE_OPEN:
      return State.setOpen(state, action.payload )

    case GeoZoneActionTypeEnum.GEOZONE_SEARCH_TEXT_CHANGED:
      return State.setSearchText(state, action.payload)

    case GeoZoneActionTypeEnum.PUSH_GEOZONE:
      return State.pushZones(state, action.payload )

    case GeoZoneActionTypeEnum.PUSH_GEOZONE_GEOJSON:
      return State.pushGeoJson(state, action.payload )

    case GeoZoneActionTypeEnum.WATCH_GEOZONE:
      return State.watch(state, action.payload )

    case GeoZoneActionTypeEnum.UNWATCH_ALL_GEOZONES:
      return State.unwatchAll(state)

    case GeoZoneActionTypeEnum.DELETE_GEOZONE:
      return State.deleteZone(state, action.payload )

    case GeoZoneActionTypeEnum.UPDATE_GEOZONE:
      return State.updateZone(state, action.payload)

    case GeoZoneActionTypeEnum.ADD_GEOZONE:
      return State.addZone(state, action.payload)

    case GeoZoneActionTypeEnum.SET_GEOZONE_COLORING:
      return State.setZoneColoring(state, action.payload)

    case GeoZoneActionTypeEnum.SET_GEOZONE_COLORING_LEGEND_VALUES:
      return State.setZoneColoringLegends(state, action.payload)

    case GeoZoneActionTypeEnum.DELETE_GEOZONE_COLORING:
      return State.deleteZoneColoring(state, action.payload)

    case GeoZoneActionTypeEnum.GEOZONE_COLORING_LOAD:
      return State.setZoneColoringLoad(state, action.payload)

    default:
      return state
  }
}
