import { ModalPortal, Spinner } from '@agdt/agrotronic-react-components'
import { GeoJsonObject } from 'geojson'
import L from 'leaflet'
import { observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import {
  deleteGeoZone,
  geoZoneSearchTextChanged,
  setGeoZoneOpen,
  unwatchAllGeoZones,
  watchGeoZone,
} from 'reducers/geoZone/actions'
import { pushGeoZone, pushGeoZoneGeoJson } from 'reducers/geoZone/actions'
import { centerPolygon } from 'reducers/map/actions'
import { deleteObjectById, getObjectById } from 'services/apiService/modules'
import { getAllAccountObjectsById } from 'services/apiService/modules'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { mapRef } from 'ui/Map'
import TooltipD from 'ui/Tooltip'
import { Details } from './Details'
import { geoZoneWithSearchTextSelector } from './geoZoneSelector'
import { Row } from './Row'

const styles = {
  tooltip: { left: 0 },
}

type TGeoZone = {
  id: number
  isOpened: boolean
  location: GeoJsonObject & {
    coordinates: number[]
  }

  name: string
  isMultiPolygon: boolean
  isWatching: boolean
}

type TProps = {
  pushGeoZone: (result: unknown) => void
  selectedFarmId: number
  unwatchAllGeoZones: () => void
  geoZoneSearchTextChanged: (text: string) => void
  setGeoZoneOpen: (id: number, isOpened: boolean) => void
  translate: TTranslate
  deleteGeoZone: (id: number) => void
  geoZones: TGeoZone[]
  pushGeoZoneGeoJson: (object: unknown) => void
  watchGeoZone: (id: number, isWatching: boolean) => void
  rawGeoZones: TGeoZone[]
  searchText: string

  setGeoZone: (data: {
    data: number[] | number | undefined
    name?: string
    typeSave: string
    idZone: number
  }) => void
}

class GeoZone extends React.Component<TProps> {
  state = {
    loadingId       : undefined,
    objectsIsLoading: false,
  }

  geoZonesStore = getStore('geoZones')
  outerElement: HTMLElement | null = null

  getGeoZones = (id: number) => {
    this.setState({ objectsIsLoading: true })

    getAllAccountObjectsById({ id })
      .then(result => this.props.pushGeoZone(result))
      .catch(() => {})
      .finally(() => this.setState({ objectsIsLoading: false }))
  }

  componentDidUpdate(prevProps: TProps) {
    if(this.props.selectedFarmId && this.props.selectedFarmId !== prevProps.selectedFarmId) {
      this.getGeoZones(this.props.selectedFarmId)
    }
  }

  componentDidMount() {
    if(this.props.selectedFarmId) {
      this.getGeoZones(this.props.selectedFarmId)
    }

    this.outerElement = document.getElementById('detailsOuter')
  }

  componentWillUnmount() {
    this.props.unwatchAllGeoZones()
    this.props.geoZoneSearchTextChanged('')
  }

  onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.geoZoneSearchTextChanged(e.target.value)
  };

  onClickDeleteGeozone = (id: number) => {
    const isConfirm = window.confirm(this.props.translate('Are you sure you want to delete the geozone'))

    if(isConfirm) {
      deleteObjectById({ id })
        .then(() => this.props.deleteGeoZone(id))
    }
  };

  onClickEditGeozone = async (id: number) => {
    const targetGeozone = await this.getTargetGeozone(id)

    if(targetGeozone?.location.type === 'MultiPolygon' )
    {
      return alert(this.props.translate('Currently edit imported track do not support'))
    }

    const coordinates = Array.isArray(targetGeozone?.location.coordinates[0]) ?
      targetGeozone.location.coordinates[0] : targetGeozone?.location.coordinates

    this.props.setGeoZone({
      data    : coordinates,
      name    : targetGeozone?.name,
      typeSave: 'settings',
      idZone  : id,
    })
  };

  getTargetGeozone = async (id: number) => {
    this.setState({ loadingId: id })
    let targetGeozone = this.props.geoZones.find(item => item.id === id)

    if(!targetGeozone?.location) {
      const object = await getObjectById({ id })
      this.props.pushGeoZoneGeoJson(object)
      targetGeozone = this.props.geoZones.find(item => item.id === id)
    }

    this.setState({ loadingId: undefined })
    return targetGeozone
  }

  flyTo = (targetGeozone: TGeoZone) => {
    if(targetGeozone.isMultiPolygon || targetGeozone?.location.type === 'MultiPolygon') {
      const geojsonLayer = L.geoJson([])
      geojsonLayer.addData(targetGeozone.location)

      if(mapRef) {
        mapRef.flyToBounds(geojsonLayer.getBounds())
      }
    } else {
      if(targetGeozone.location && targetGeozone.location.coordinates && targetGeozone.location.coordinates.length) {
        if(Array.isArray(targetGeozone.location.coordinates[0])) {
          if(mapRef) {
            mapRef.flyToBounds(
              L.latLngBounds(
                targetGeozone.location.coordinates[0].map(item => [item[1], item[0]]),
              ),
              {
                maxZoom: 18,
              },
            )
          }
        } else {
          if(mapRef) {
            mapRef.flyTo(
              L.latLng(
                [targetGeozone.location.coordinates[1], targetGeozone.location.coordinates[0]],
              ), 15)
          }
        }
      }
    }
  }

  onClickTrack = async (id: number) => {
    const targetGeozone = await this.getTargetGeozone(id)
    const isWatching = !targetGeozone?.isWatching
    this.props.watchGeoZone(id, isWatching)

    if(isWatching && targetGeozone) {
      this.flyTo(targetGeozone)
    }
  };

  select = async (id: number) => {
    const targetGeozone = await this.getTargetGeozone(id)
    this.geoZonesStore.setSelectedGeoZone(targetGeozone)

    if(targetGeozone) {
      this.flyTo(targetGeozone)
    }
  }

  closeDetails = () => {
    this.geoZonesStore.setSelectedGeoZone(undefined)
  }

  render() {
    const { geoZones, rawGeoZones, searchText } = this.props

    return (
      <div className="geoZone" style={{ position: 'relative', zIndex: 1 }}>
        <TooltipD
          text={this.props.translate('List of user geozones with the search function by name')}
          style={styles.tooltip}
        />

        {!this.state.objectsIsLoading && rawGeoZones.length > 0 && <div className="tracking-page__search">
          <input
            type="text"
            className="tracking-page__input"
            placeholder={this.props.translate('search_for_geozone')}
            value={searchText}
            onChange={this.onChangeSearch}
          />
          <button className="tracking-page__button" />
        </div>}

        <div className="geoZones-scroll">
          {this.state.objectsIsLoading && <StyledSpinner/>}

          {!this.state.objectsIsLoading && !isEmpty(geoZones) &&
            geoZones.map(geoZone =>
              <Row
                id={geoZone.id}
                isWatching={geoZone.isWatching}
                key={geoZone.id}
                loading={geoZone.id === this.state.loadingId}
                name={geoZone.name}
                onClickTrack={this.onClickTrack}
                select={this.select}
                translate={this.props.translate}
              />
              ,
            )
          }

          {/*@ts-expect-error*/}
          {this.outerElement && <ModalPortal
            id="first"
            outerElement={this.outerElement}
            showModal={Boolean(this.geoZonesStore.selectedGeoZone)}
            type="sidebarLeft"
            noBlur
            transparentBackground
          >
            {Boolean(this.geoZonesStore.selectedGeoZone) && <Details
              haveColoringData={Boolean(this.geoZonesStore.selectedGeoZone.coloringData)}
              close={this.closeDetails}
              id={this.geoZonesStore.selectedGeoZone.id}
              location={this.geoZonesStore.selectedGeoZone.location}
              name={this.geoZonesStore.selectedGeoZone.name}
              onClickEditGeozone={this.onClickEditGeozone}
              onClickDeleteGeozone={this.onClickDeleteGeozone}
              t={this.props.translate}
            />}
          </ModalPortal>}

          {!this.state.objectsIsLoading && isEmpty(geoZones) && <NoDataMessage>
            {this.props.translate('not_of_data')}
          </NoDataMessage>}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    geoZones: geoZoneWithSearchTextSelector(state),

    //@ts-expect-error
    searchText: state.geoZone.searchText,

    //@ts-expect-error
    preLoaded: state.user.preLoaded,

    //@ts-expect-error
    accessToken: state.user.accessToken,

    //@ts-expect-error
    rawGeoZones: state.geoZone.static,
  }),
  dispatch => ({

    //@ts-expect-error
    pushGeoZone: result => dispatch(pushGeoZone(result)),

    //@ts-expect-error
    pushGeoZoneGeoJson: result => dispatch(pushGeoZoneGeoJson(result)),

    //@ts-expect-error
    watchGeoZone: (id, show) => dispatch(watchGeoZone(id, show)),

    //@ts-expect-error
    centerPolygon: id => dispatch(centerPolygon(id)),

    //@ts-expect-error
    deleteGeoZone: id => dispatch(deleteGeoZone(id)),

    //@ts-expect-error
    geoZoneSearchTextChanged: searchText => dispatch(geoZoneSearchTextChanged(searchText)),

    //@ts-expect-error
    setGeoZoneOpen: (id, isOpened) => dispatch(setGeoZoneOpen(id, isOpened)),

    //@ts-expect-error
    unwatchAllGeoZones: () => dispatch(unwatchAllGeoZones()),
  }),
)(withTranslate(observer(GeoZone)))

const NoDataMessage = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  color: #444343;
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 2;
`
