import { action, makeObservable, observable } from 'mobx'
import { getMultiNotices } from 'services/apiService/modules'
import { SortableListStore } from 'stores/generic/SortableListStore'
import { TNoteice } from 'types'
import { errorMessage } from '../../services/notification'
import { getStore } from '../storesRegistry'

export class NoticesListStore {
  @observable isLoading = false
  list = new SortableListStore<TNoteice>()

  constructor() {
    makeObservable(this)
  }

  setData(items: TNoteice[]) {
    // Add synthetic id
    items.forEach((item, index) => item.id = index)

    this.list.setItems(items)
  }

  //@ts-expect-error
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading
  }

  //@ts-expect-error
  async load(filter, imei) {
    let items
    this.setIsLoading(true)

    const queryParamsData = {
      ...filter,
      imeis: [imei],
    }

    try {
      items = await getMultiNotices(queryParamsData)
    } catch(e){
      const t = getStore('context').t
      errorMessage(t('Error while receiving data'))
      console.error(e)
    }

    //@ts-expect-error
    this.setData(items[imei] ?? [])
    this.setIsLoading(false)
  }
}
