import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { Marker } from 'react-leaflet'
import { getStore } from 'stores/storesRegistry'
import { TTranslate } from 'types'
import { marker2, marker3, predictUnloadingRed, predictUnloadingYellow } from '../markersType'
import { FuelingAndDrain } from './FuelingAndDrain'
import { NotificationMarkers } from './NotificationMarkers'
import { StopIntervalMarkers } from './StopIntervalMarkers'
import { UnloadingLoadingMarker } from './UnloadingLoadingMarker'

type MarkersProps = {
  imei: number
  translate: TTranslate
}

const Markers: FC<MarkersProps> = ({ imei, translate }) => {
  const unitTrack = getStore('householdUnits').getUnit(imei)
  const selectedMarkers = unitTrack.track.markers.selected

  return <>
    {selectedMarkers['loading'] && unitTrack.track.markers.value?.loading?.map(marker =>
      <UnloadingLoadingMarker
        icon={marker2}
        key={`${marker.lat}-${marker.sign}-${marker.lon}`}
        marker={marker}
        t={translate}
      />)}

    {selectedMarkers['unloading'] && unitTrack.track.markers.value?.unloading?.map(marker =>
      <UnloadingLoadingMarker
        icon={marker3}
        key={`${marker.lat}-${marker.sign}-${marker.lon}`}
        marker={marker}
        t={translate}
      />)
    }

    {selectedMarkers['notification'].size &&
      <NotificationMarkers
        markers={unitTrack.track.markers.value?.notification || []}
        selectedOptions={selectedMarkers['notification']}
        t={translate}
      />
    }

    {selectedMarkers['stopIntervals'].size &&
      <StopIntervalMarkers
        markers={unitTrack.track.markers.value?.stopIntervals || {}}
        selectedOptions={selectedMarkers['stopIntervals']}
        t={translate}
      />
    }

    {selectedMarkers['filingAndDrain'] && unitTrack.track.markers.value?.filingAndDrain?.map(marker =>
      <FuelingAndDrain
        key={`${marker.lat}-${marker.sign}-${marker.lon}`}
        marker={marker}
        t={translate}
      />)
    }

    {selectedMarkers['predictUnloading'] && unitTrack.track.markers.value?.predictUnloading &&
      <Marker
        key={unitTrack.track.markers.value.predictUnloading.lat}

        position={[
          unitTrack.track.markers.value.predictUnloading.lat,
          unitTrack.track.markers.value.predictUnloading.lon,
        ]}

        icon={unitTrack.track.markers.value.predictUnloading.level >= 75
          ? predictUnloadingRed
          : predictUnloadingYellow}

        zIndexOffset={10000}
      />
    }
  </>
}

export default observer(Markers)
