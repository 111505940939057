export type TaskType = Readonly<{
  id: string
  typeId: string
  active: unknown
  online: unknown
  email: unknown
  sms: unknown
  typeName: unknown
}>

export type TasksArrayType = ReadonlyArray<TaskType>

export type NotificationTasks = Readonly<{
  tasks: TasksArrayType
}>

export const init = (): NotificationTasks => ({
  tasks: [],
})

export const setTasks = (state: NotificationTasks, tasks: TaskType[]): NotificationTasks => ({
  ...state,
  tasks,
})

export const editTask = (state: NotificationTasks, task?: TaskType): NotificationTasks => ({
  ...state,
  ...task && { tasks: state.tasks.map(operation => operation.id === task.id ? task : operation) },
})
