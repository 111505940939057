import Tippy from '@tippyjs/react'
import { observer } from 'mobx-react'
import React from 'react'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { C_STATUES } from '../../../constants'
import { TUnitsIdsByStatus } from 'stores/HomeWidgets/ActivityWidgetStore/types'

type TProps = {
  id: number
  statuses: TUnitsIdsByStatus[]
  t: TTranslate
}

export const Statuses = observer(({ id, statuses, t }: TProps) => {
  const activityStore = getStore('activityWidget')
  const selectedStatusesByHouseholds = activityStore.statusesSelector.selectedStatusesByHouseholds.get(id)

  return <>
    {statuses.map(S => <Tippy
      content={t(C_STATUES[S.status].title)}
      key={C_STATUES[S.status].color}
      placement="top"
      maxWidth={200}
    >
      <ActivityNumber
        isActive={Boolean(selectedStatusesByHouseholds?.size && selectedStatusesByHouseholds.has(S.status))}
        onClick={() => activityStore.statusesSelector.setSelectedStatusesForHousehold(id, S.status)}
        style={{ background: C_STATUES[S.status].color }}
      >
        <CountContainer isWide={S.unitIds.length > 99}>{S.unitIds.length}</CountContainer>
      </ActivityNumber>
    </Tippy>,
    )}
  </>
})

const CountContainer = styled.span<{ isWide: boolean }>`
  padding-left: ${({ isWide }) => isWide ? '10px' : ''};
  padding-right: ${({ isWide }) => isWide ? '10px' : ''};
`

const ActivityNumber = styled.div<{ isActive: boolean }>`
  min-width: 23px;
  height: 23px;
  background: #a9a9a9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  text-decoration: none;
  
  /* Semibold 12 */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  text-align: center;
  
  /* Color 9 */
  color: #FFFFFF;
  
  border-radius: 25px;
  box-shadow: ${({ isActive }) => isActive ? '0 0 5px 5px rgba(78, 0, 0, 0.35)' : 'none'};

  & span {
    padding: 0 6px;
  }
  
  @media (max-width: 1000px) {
    margin-right: 4px;
  }
`
