import { computed, flow, makeObservable, observable, toJS } from 'mobx'
import { groupBy, head, map, pick, pipe, prop } from 'ramda'
import { errorMessage } from 'services/notification'
import dictionariesStore from 'stores/DictionariesStore'
import { getStore } from 'stores/storesRegistry'
import FormSelectStore from '../formFieldsStores/SelectStore'

export default class AccountsTreeStore {
  selectStore = new FormSelectStore()
  @observable isLoading = false

  constructor() {
    makeObservable(this)
    this.load()
  }

  load = flow(function* (this: AccountsTreeStore) {
    try {
      this.isLoading = true
      this.selectStore.setOptions(yield dictionariesStore.usersTree.getValue())
    } catch(error) {
      errorMessage(pick(['message'], error))
    }

    this.isLoading = false
  })

  @computed
  get parentsListMap() {
    //@ts-expect-error
    const parentsMap = pipe(groupBy(prop('id')), map(head))(this.selectStore.options)

    const parentsMapList = {}

    this.selectStore.options.forEach(F => {
      //@ts-expect-error
      if(!parentsMapList[F.id]) {
        //@ts-expect-error
        parentsMapList[F.id] = new Set([F.parentId])
      }

      //   @ts-expect-error
      let parent = parentsMap[F.parentId]

      while(parent) {
        //@ts-expect-error
        parentsMapList[F.id].add(parent.parentId)

        //@ts-expect-error
        parent = parentsMap[parent.parentId]
      }
    })

    return parentsMapList
  }

  @computed
  get root() {
    const rootUserId = getStore('context').selectedFarmId

    if(!rootUserId){throw 'selectedFarmId must be calculated first'}

    return this.selectStore.options
      .filter(user => rootUserId ? user.id === rootUserId : !user.parentId)
      .map(toJS)
  }

  @computed
  get childrenTree() {
    const rootUserId = getStore('context').selectedFarmId

    // Рассчёт дерева можно производить только если уже установлен selectedFarmId,
    // но некоторые потребители хотя получить данные раньше
    if(!rootUserId) {
      return []
    }

    const parents = { [rootUserId]: true }
    const tree = this.selectStore.options.filter(O => O.id === rootUserId).map(toJS)
    let restart = true

    while(restart) {
      restart = false

      for(let index = 0; index < this.selectStore.options.length; ++index) {
        const option = this.selectStore.options[index]

        if(option.parentId && parents[option.parentId] && !parents[option.id]) {
          parents[option.id] = true
          tree.push(toJS(option))
          restart = true
        }
      }
    }

    return tree
  }

  //@ts-expect-error
  childrenTreeForId(id) {
    if(!id){throw 'selectedFarmId must be calculated first'}

    const rootUserId = id
    const parents = { [rootUserId]: true }
    const tree = this.selectStore.options.filter(O => O.id === rootUserId).map(toJS)
    let restart = true

    while(restart) {
      restart = false

      for(let index = 0; index < this.selectStore.options.length; ++index) {
        const option = this.selectStore.options[index]

        if(option.parentId && parents[option.parentId] && !parents[option.id]) {
          parents[option.id] = true
          tree.push(toJS(option))
          restart = true
        }
      }
    }

    return tree
  }
}
