import { openWeatherAppId } from 'constants/env'
import L from 'leaflet'
import { useEffect, useState } from 'react'
import { withLeaflet } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { errorMessage } from 'services/notification'

type TProps = {
  dateTime: number
  layerCode: string
  leaflet: {
    map: Object
  }
}

const WeatherMapLayerCreator = ({ dateTime, layerCode, leaflet: { map } }: TProps) => {
  const t = useTranslate()
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [dateTime, layerCode])

  useEffect(() => {
    if(error) { return }

    const weatherTile = L.tileLayer(
      `https://agrotronic.rostselmash.com/maps/2.0/weather/${layerCode}/{z}/{x}/{y}?date=${dateTime}&appid=${openWeatherAppId}&opacity=0.8`,
    )

    weatherTile

      //@ts-expect-error
      .addTo(map)
      .on('tileerror', () => !error && setError(true))

    return () => {
      weatherTile
        .off('tileerror')
        .remove()
    }
  }, [dateTime, error, layerCode, map])

  useEffect(() => {
    if(error) {
      errorMessage(t('The weather info currently unvailable'))
    }
  }, [error, t])

  return null
}

//@ts-expect-error
const WeatherMapLayer = withLeaflet(WeatherMapLayerCreator)

export default WeatherMapLayer
