import React, { FC, useCallback, useRef } from 'react'
import { Map } from 'react-leaflet'
import { useTranslate } from 'react-redux-multilingual'
import { TUserUnit } from 'types'
import ItemMachine from './ItemMachine'

type ListMachineProps = {
  mapRef: Map
  rowId: number
  units: TUserUnit[]
  handleSetRowHeights: (index: number, size: number) => void
}

export const ListMachine:FC<ListMachineProps> = ({ handleSetRowHeights, mapRef, rowId, units }) => {
  const translate = useTranslate()

  const resizeObserver = useRef(new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const height = entries?.[0]?.contentRect?.height

    if(height) {
      handleSetRowHeights(rowId, height)
    }
  }))

  const containerRef = useCallback(container => {
    if(container !== null) {
      resizeObserver.current.observe(container)
    }

    //При размонтировании ref callback вызывается с аргументом равным null
    else if(resizeObserver.current) {
      handleSetRowHeights(rowId, 0)
      resizeObserver.current.disconnect()
    }
  }, [])

  return (
    <div ref={containerRef} className="tracking-item__machines">
      {units.map(item =>
        <ItemMachine
          data={item.data}
          id={item.id}
          imei={item.imei}
          key={`${rowId}-${item.imei}`}
          mapRef={mapRef}
          model={item.model}
          name={item.name}
          translate={translate}
          typeName={item.typeName}
        />,
      )}
    </div>
  )
}
