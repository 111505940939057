import { Avatar, icons, Spinner, utils } from '@agdt/agrotronic-react-components'
import React, { ChangeEvent,FC } from 'react'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { TTranslate } from 'types'

const { screenResolutions } = utils

const preparePhoto = (photo: string | ArrayBuffer | null) =>
  photo && typeof photo === 'string'
    ? photo.split('base64,')[1]
    : undefined

type Props = {
  isFieldSave: boolean
  disabled: boolean
  email?: string
  fullName?: string
  lastName?: string
  img?: string
  handleSave: (fieldName: string, value: unknown) => void
  t: TTranslate
}

export const UserAvatar: FC<Props> = ({
  disabled,
  email,
  fullName,
  handleSave,
  img,
  isFieldSave,
  lastName,
  t,
}) => {
  const handleSetPhoto = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const validType = file?.type ? ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) : false
    const validSize = file?.size ? file.size <= 2097152 : false

    if(validType && validSize && file) {
      const reader = new FileReader()

      reader.onloadend = function () {
        handleSave('photo', preparePhoto(reader.result))
      }

      reader.readAsDataURL(file)
    } else if(!validType) {
      event.target.value = ''
      errorMessage(t('wrongPhotoFormat'))
    } else if(!validSize) {
      event.target.value = ''
      errorMessage(t('photoTooLarge'))
    }
  }

  const handleDeletePhoto = () => {
    handleSave('photo', null)
  }

  return (
    <Block>
      <ImageSelect data-testid='profile-image' isFieldSave={isFieldSave}>
        <Avatar src={img} size="135px" />

        {isFieldSave && <StyledSpinner data-testid='profile-image__spinner' color="#c6c6c6"/>}

        {img ? <>
          <EditPhotoBtn data-testid='profile-image__edit-btn'>
            <icons.EditIcon color="#FFF" data-testid='profile-image-edit__icon' />

            <input
              accept=".jpg, .jpeg, .png"
              data-testid='profile-image-edit__input'
              onChange={handleSetPhoto}
              type="file"
            />
          </EditPhotoBtn>

          <DeletePhotoBtn data-testid='profile-image__delete-btn' onClick={handleDeletePhoto}>
            <icons.DeleteIcon color="#FFF" data-testid='profile-image__delete-icon' />
          </DeletePhotoBtn>
        </>

          : <>
            <AddPhotoBtn data-testid='profile-image__add-btn'>
              <icons.PhotoIcon data-testid='profile-image__add-icon' color="#FFF" />

              <input
                accept=".jpg, .jpeg, .png"
                data-testid='profile-image__add-input'
                disabled={disabled}
                onChange={handleSetPhoto}
                type="file"
              />
            </AddPhotoBtn>
          </>
        }
      </ImageSelect>

      <Name data-testid='profile__name'>{`${fullName} ${lastName}`}</Name>
      <Email data-testid='profile__email'>{email}</Email>
    </Block>
  )
}

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 40%;
`

const Block = styled.div`
  background-color: #fff;
  padding: 32px 0;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7px;
`

const ImageSelect = styled.div<{isFieldSave: boolean}>`
  position: relative;
  margin-bottom: 17px;
  opacity: ${({ isFieldSave }) => isFieldSave ? .4 : 1};
`

const StyledLabel = styled.label`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  svg {
    background-color: rgba(0,0,0,0.2);
    box-shadow: 0 0 12px rgba(0,0,0,0.5);
  }
  input {
    position: absolute;
    width: 100%;
    opacity: 0;
  }
`

const AddPhotoBtn = styled(StyledLabel)`
  left: 55px;
`

const EditPhotoBtn = styled(StyledLabel)`
  left: 41px;
`

const DeletePhotoBtn = styled(StyledLabel)`
  left: 70px;
`

const Name = styled.h2`
  max-width: 220px;
  font-weight: bold;
  font-size: 1.6em;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media(max-width: ${screenResolutions.PAL}) {
    white-space: normal;
    max-width: 80%;
  }
`

const Email = styled.span`
  color: #A1A1A1;
`
