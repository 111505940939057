import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { Link, Route,Switch } from 'react-router-dom'
import { AppStateType } from 'reducers/store'
import { link } from '../../router'
import Export from './Export'
import { WatchMultiple } from './WatchMultiple'
import { WatchSingle } from './WatchSingle'

type WatchProps = {
  location: {
    pathname: string
  }
}

export const Watch: FC<WatchProps> = ({ location: { pathname } }) => {
  const translate = useTranslate()
  const fixedDataFilter = useSelector((state: AppStateType) => state.reports.get('fixedDataFilter'))
  const demoMode = useSelector((state: AppStateType) => state.user.demoMode)

  return (
    <div className="watch">
      {!demoMode ?
        <React.Fragment>
          <div className="watch__title">
            <div className="content__filter-type-analysis ant-btn-group">
              <Link
                to={`${link.watchMultiple}${fixedDataFilter || ''}`}
                className={`ant-btn ant-btn-button ${pathname.includes(link.watchMultiple) ? 'active' : ''}`}
              >
                {translate('group')}
              </Link>

              <Link
                to={`${link.watchSingle}${fixedDataFilter || ''}`}
                className={`ant-btn ant-btn-button ${pathname.includes(link.watchSingle) ? 'active' : ''}`}
              >
                {translate('single')}
              </Link>

              <Link
                to={`${link.watchExport}${fixedDataFilter || ''}`}
                className={`ant-btn ant-btn-button ${pathname === link.watchExport ? 'active' : ''}`}
              >
                {translate('export')}
              </Link>
            </div>
          </div>
          <Switch>
            <Route path={link.watchMultiple} component={WatchMultiple} />
            <Route path={link.watchSingle} component={WatchSingle} />
            <Route path={link.watchExport} component={Export} />
          </Switch>
        </React.Fragment>
        :
        <p className="watch__demoText">Этот раздел не доступен в демо режиме</p>}
    </div>
  )
}
