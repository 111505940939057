import React from 'react'

const Rsm3535Icon = ({ size = 20, color = 'black' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.452 13.2717C10.452 11.643 11.7725 10.3225 13.4013 10.3225H14.6753L15.6132 5.16132H11.1893L11.0551 5.89863H1.47009L0.64502 10.4368L6.35107 11.705L7.50279 14.0091H10.5567C10.4948 13.7724 10.452 13.5283 10.452 13.2717ZM6.02817 14.0091C6.02817 14.823 5.36754 15.4837 4.55354 15.4837C3.73953 15.4837 3.0789 14.823 3.0789 14.0091C3.0789 13.1951 3.73953 12.5344 4.55354 12.5344C5.36754 12.5344 6.02817 13.1951 6.02817 14.0091ZM16.3505 13.272V13.0928L18.7416 15.4839H15.3337C15.9516 14.9427 16.3505 14.1575 16.3505 13.272ZM13.4013 11.0598C12.1796 11.0598 11.1893 12.05 11.1893 13.2717C11.1893 14.4935 12.1796 15.4837 13.4013 15.4837C14.623 15.4837 15.6132 14.4935 15.6132 13.2717C15.6132 12.05 14.623 11.0598 13.4013 11.0598Z"
        fill={color}
      />
    </svg>
  )
}

export default Rsm3535Icon
