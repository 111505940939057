import { action, configure, makeObservable, observable, observe } from 'mobx'
import { identity, path } from 'ramda'
import { getStore } from 'stores/storesRegistry'

configure({ enforceActions: 'observed' })

export default class ContextStore {
  //@ts-expect-error
  @observable isLoading

  //@ts-expect-error
  @observable isDemo

  //  @observable parent2Childrens,

  //@ts-expect-error
  @observable selectedFarmId

  //@ts-expect-error
  @observable setSelectedFarmId

  //@ts-expect-error
  @observable gmt

  //@ts-expect-error
  @observable lang

  //@ts-expect-error
  @observable user
  @observable t = identity

  //@ts-expect-error
  waitPromise: Promise<boolean>

  //@ts-expect-error
  @action setTranslate(t) { this.t = t }

  //@ts-expect-error
  @action updateByContext(context) {
    [
      ['isDemo'],
      ['isLoading', ['farm', 'isLoading']],
      ['selectedFarmId', ['farm', 'selectedFarmId']],
      ['setSelectedFarmId', ['farm', 'setSelectedFarmId']],
      ['gmt'],
      ['lang'],
      ['user'],
    ].forEach(([key, propPath]) => {
      const propPathOrKey = propPath ? propPath : [key] as any

      //@ts-expect-error
      if(path(propPathOrKey, context) !== this[key as string]) {
        //@ts-expect-error
        this[key as string] = path(propPathOrKey, context)
      }
    })
  }

  async waitLoading() {
    return new Promise(resolve => {
      const accounts = getStore('accountsTree')
      let waitCount = 0

      if(this.selectedFarmId && !accounts.isLoading) {
        return resolve(true)
      }

      observe(this, 'selectedFarmId', changes => {
        if(changes.newValue) {
          waitCount++

          if(waitCount === 2) {
            resolve(true)
          }
        }
      }, true)

      observe(accounts, 'isLoading', changes => {
        if(!changes.newValue) {
          waitCount++

          if(waitCount === 2) {
            resolve(true)
          }
        }
      }, true)
    })
  }

  constructor() {
    makeObservable(this)
  }
}
