import moment from 'moment'

export const prepareDuration = (duration?: number) => {
  let formatDuration = ''

  if(duration) {
    const absDuration = Math.abs(duration)
    const hoursDuration = ~~moment.duration(absDuration).asHours()
    const minutesDuration = ~~moment.duration(absDuration).minutes()
    const secondsDuration = ~~moment.duration(absDuration).seconds()
    formatDuration += `${hoursDuration < 10 ? '0' : ''}${hoursDuration}:`
    formatDuration += `${minutesDuration < 10 ? '0' : ''}${minutesDuration}:`
    formatDuration += `${secondsDuration < 10 ? '0' : ''}${secondsDuration}`
  }
  else {
    formatDuration = '00:00:00'
  }

  return formatDuration
}
