import { utils } from '@agdt/agrotronic-react-components'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { link } from '../../../router'
import { useDemoMode, useWindowDimension } from '../../../services/hooks'
import { MenuItemConfiguration, MenuItemDefinition, menuItems } from './menuItems'

const { screenResolutions } = utils

export type MenuItems = ReturnType<typeof useMenuItems>

type UseMenuItemType = MenuItemDefinition & {
  active: boolean
}

// API пользователя не имплементированно, поэтому как временное решение администратор определяется по логину "admin".
// user?.login только для этого и, вероятно, будет удалено.
export function useMenuItems(options: Partial<MenuItemConfiguration>): UseMenuItemType[] {
  const location = useLocation()
  const demoMode = useDemoMode()
  const { width } = useWindowDimension()

  const configuration: MenuItemConfiguration = {
    isInDebug          : location.search.includes('debug'),
    isInDemoMode       : Boolean(demoMode),
    isPAL              : (width || 0) < parseInt(screenResolutions.PAL),
    ...options,
    isAdmin            : options.isAdmin || false,
    isAdminLogin       : options.isAdminLogin || false,
    isSysAdmin         : options.isSysAdmin || false,
    isOrgAdmin         : options.isOrgAdmin || false,
    canEditOrganization: options.canEditOrganization || false,
  }

  return useMemo(() =>
    menuItems.reduce<UseMenuItemType[]>((acc, item) => {
      if(!item.condition ||
            item.condition({ ...configuration, link: item.link })) {
        return [
          ...acc,
          {
            ...item,
            ...item.items?.length && {
              items: item.items.filter(
                element =>
                  !element.condition ||
                element.condition({ ...configuration, link: element.link }),
              ).map(menuItem => ({
                ...menuItem,
                linkTo: link[menuItem.link],
              })),
            },
            active: item.matchPath ? Boolean(matchPath(location.pathname, item.matchPath)) : false,
            linkTo: item.link ? link[item.link] : undefined,
          },
        ]
      }

      return acc
    } , []),

  [
    demoMode,
    configuration.isAdmin,
    configuration.isAdminLogin,
    configuration.isSysAdmin,
    configuration.isOrgAdmin,
    configuration.canEditOrganization,
    location.pathname,
    location.search,
    width,
  ])
}
