import Tippy from '@tippyjs/react'
import { icons } from '@agdt/agrotronic-react-components'
import React from 'react'
import styled from 'styled-components'

const { HelpIcon } = icons

type TProps = {
  helpText?: string
  measure?: string
  title: string
  value?: string | number
}

export const Row = ({
  helpText,
  measure,
  title,
  value,
}: TProps) => <RowWrapper>
  <ParamWrapper>
    <TitleWrapper>
      <Title>{title}</Title>

      {helpText && <StyledTippy
        content={helpText}
        interactive
        placement='right'
      >
        <span><HelpIcon color="#CCCCCC" size="20px"/></span>
      </StyledTippy>}
    </TitleWrapper>

    <Value>{value} {measure}</Value>
  </ParamWrapper>
</RowWrapper>

const RowWrapper = styled.div`
  height: 38px;
  padding-right: 10px;
`

const ParamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  [data-tippy-root] {
    max-width: 55%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #111111;
`

const StyledTippy = styled(Tippy)`
  box-shadow: 0 3.75px 11px 0 rgba(0, 0, 0, 0.19), 0 0.5px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff !important;
  border-radius: 8px !important;

  .tippy-content {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 10px;
    color: #111111;

    > div {
      font-size: 14px;
    }
  }

  .tippy-arrow {
    color: #ffffff;
    z-index: 1;
  }
`
