import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'

type Props = {
  renderList: unknown[]
  textAfterSelect: string
  result: string[]
  select: (value?: string) => void
}

export const Selector: FC<Props> = ({ result, textAfterSelect, renderList, select }) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = (event: MouseEvent) => {
    if(!rootRef.current || !rootRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const handleToggle = () => {
    setOpen(value => !value)
  }

  const onClickItem = (value?: string | unknown) => {
    if(value) {
      //@ts-expect-error
      return () => select(value)
    }

    return () => select()
  }

  return (
    <div className="filter-parameter__selector" ref={rootRef}>
      <div
        className="filter-parameter__selector-result"
        onClick={handleToggle}
      >
        <p>{result.length ? result[result.length - 1] : textAfterSelect}</p>
      </div>
      <div className={`filter-parameter__selector-items${open ? ' active' : ''}`}>
        <div
          className={`filter-parameter__selector-item${!result.length ? ' active' : ''}`}
          onClick={onClickItem()}
        >
          {translate('All')}
        </div>
        {renderList && renderList.length && renderList.map((item, index) =>
          <div
            key={index}
            className={`filter-parameter__selector-item${result.some(param => param === item) ? ' active' : ''}`}
            onClick={onClickItem(item)}
          >
            {item}
          </div>)}
      </div>
    </div>
  )
}
