import { TProps as TWidgetProps, Widget } from 'components/Widget'
import { HeaderElement } from 'components/Widget/Header'
import ResizeButton from 'components/Widget/Header/ResizeButton'
import {
  Subtitle,
  TitleAndCounter,
  TitleCaption,
  TitleContainer,
} from 'components/Widget/Header/Title'
import SystemContextMenu from 'components/Widget/SystemContextMenu'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { TUnit } from 'types'
import { getServiceData } from '../../../reducers/users/selectors'
import { useWidgetResize } from '../common/hooks'
import { TUseWidgetResizeProps } from '../common/hooks/useWidgetResize'
import NoteMessage from '../common/NoteMessage'
import Row from './Row'

type TProps = {
  className: string
  config: {
    isResizable: boolean
  }

  items: TUnit[]
  onClose: () => void
} & TWidgetProps & TUseWidgetResizeProps

function ServiceWidget({
  changeConfig,
  className,
  config,
  items,
  onClose,
  style,
  ...restProps
}: TProps) {
  const t = useTranslate()
  const { isCompact, toggleSize } = useWidgetResize(config, changeConfig)
  const message = t('Maintenance expired, contact customer service')
  const count = useMemo(() => items.length, [items])

  return (
    <WidgetStyled
      className={className}
      style={style}
      {...restProps}
    >
      <HeaderStyled
        isSubtitle={!isCompact}
        className="draggable"
        isCompact={isCompact}
      >
        <TitleContainer>
          <TitleAndCounter>
            <TitleCaptionStyled>{isCompact ? message : t('Maintance')}</TitleCaptionStyled>
          </TitleAndCounter>

          {!isCompact && <SubtitleStyled>{t('Maintenance Alert')}</SubtitleStyled>}
        </TitleContainer>

        <ResizeButton toggle={toggleSize} isCompact={isCompact} iconColor="white" />
        <SystemContextMenu onClose={onClose} iconColor="#FFFFFF" />
      </HeaderStyled>

      <BigCounter isCompact={isCompact}>
        { count } <BigCounterSubtitle>{t('unit')}</BigCounterSubtitle>
      </BigCounter>

      <NoteMessageStyled iconColor="#ffffff" message={message} />

      {/*@ts-expect-error*/}
      {!isCompact && <VehicleTable
        height={135}
        itemCount={items.length}
        itemSize={24}
      >
        {({ index, style }) => {
          const item = items[index]

          return (
            <Row
              t={t}
              key={item.imei}
              typeName={item.typeName}

              /*@ts-expect-error*/
              model={item.model}
              name={item.name}

              /*@ts-expect-error*/
              serviceType={item.serviceType}

              /*@ts-expect-error*/
              serviceValue={item.serviceValue}
              style={style}
            />
          )
        }}
      </VehicleTable>}
    </WidgetStyled>
  )
}

/*@ts-expect-error*/
const mapStateToProps = state => {
  return {
    items: getServiceData(state),
  }
}

export default connect(
  mapStateToProps,
)(ServiceWidget)

const HeaderStyled = styled(HeaderElement)`
  background: #C43535;
  color: white;
  /* Color 9 */
  border-bottom: ${({ isCompact }) => isCompact ? 'none' : '1px dashed #FFFFFF'};
`

const WidgetStyled = styled(Widget)`
  background: #C43535;
`

const TitleCaptionStyled = styled(TitleCaption)`
  /* Bold 14 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;
`

const SubtitleStyled = styled(Subtitle)`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;
`

const BigCounter = styled.div<{ isCompact: boolean }>`
  background: #C43535;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: ${({ isCompact }) => isCompact ? '72px' : '70px'};
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;

  padding-left: 12px;
`

const BigCounterSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 38px;
  margin-left: 8px;
  margin-top: 7px;
`

const NoteMessageStyled = styled(NoteMessage)`
  /* Regular 10 */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Color 9 */
  color: #FFFFFF;

  background: #C43535;
  margin: 0;
  padding-left: 10px;

  height: 28px;
`

const VehicleTable = styled(List)`
  background: #FFF;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 6px;
  padding-right: 15px;
  overflow-x: hidden !important;
`
