import Tippy from '@tippyjs/react'
import React from 'react'
import styled from 'styled-components'
import { followCursor } from 'tippy.js'

type TProps = {
  children: React.ReactElement
  title?: string
  hint?: string
}

export default function Hint({ children, title, hint }: TProps) {
  const Content = () =>
    <>
      <Title>{ title }</Title>
      <Value>{ hint }</Value>
    </>


  return (
    <StyledTippy
      content={<Content />}
      disabled={!hint}
      followCursor
      plugins={[followCursor]}
    >
      {children}
    </StyledTippy>
  )
}

const StyledTippy = styled(Tippy)`
  background-color: #484848 !important;
  border-radius: 0 !important;
`

const Title = styled.div`
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 9px;

  /* Color 9 */
  color: #FFFFFF;
`

const Value = styled.div`
  /* Bold 10 */
  font-style: normal;
  font-weight: bold;
  font-size: 10px;

  /* Color 9 */
  color: #FFFFFF;
`
