import { UsersActionsType,UsersActionTypeEnum } from './actions'
import * as State from './state'

export default function users(state = State.init(), action: UsersActionsType): State.Users {
  switch(action.type) {
    case UsersActionTypeEnum.SET_MODELS_HOUSEHOLD:
      return State.setModelsHousehold(state, action.payload)

    case UsersActionTypeEnum.SET_STATUSES_HOUSEHOLD:
      return State.setStatusesHousehold(state, action.payload)

    case UsersActionTypeEnum.SET_SEARCH_HOUSEHOLD:
      return State.setSearchHousehold(state, action.payload)

    case UsersActionTypeEnum.SUCCES_REQUEST_USERS:
      return State.setSuccesRequestUsers(state, action.payload)

    case UsersActionTypeEnum.PUSH_TRACK:
      return State.pushTrack(state, action.payload)

    case UsersActionTypeEnum.FLY_TRACK:
      return State.flyTrack(state, action.payload)

    case UsersActionTypeEnum.START_LOAD_TRACK_DETAIL:
      return State.startLoadTrackDetail(state, action.payload)

    case UsersActionTypeEnum.DELETE_TRACK:
      return State.deleteTrack(state, action.payload)

    case UsersActionTypeEnum.UPDATE_DATA_MACHINE:
      return State.updateMachine(state, action.payload)

    case UsersActionTypeEnum.SET_ALLOWEDS_SENSORS:
      return State.setAllowedSensors(state, action.payload)

    case UsersActionTypeEnum.FILTER_TYPE_USERS:
      return State.filterUsersType(state, action.payload)

    default:
      return state
  }
}
