import { action, autorun, computed, makeObservable, observable, observe } from 'mobx'
import { all, anyPass, isEmpty, isNil, map, pipe, sum, uniq, values } from 'ramda'
import { getMultiNotices } from 'services/apiService/modules'
import { PeriodStore } from 'stores/generic/PeriodStore'
import { NoticesListStore } from 'stores/Notices/NoticesListStore'
import { NoticesUnitsListStore } from 'stores/Notices/NoticesUnitsListStore'
import { TNoteiceFilter } from 'stores/Notices/types'
import { getStore } from 'stores/storesRegistry'
import { TNoteice, TUnit } from 'types'
import { notificationCategory, notificationStatus } from 'utils'
import { errorMessage } from '../../services/notification'

export class NoticesStore {
  @observable isLoading = false

  //@ts-expect-error
  @observable data: {[key: string]: TNoteice[]}
  @observable filter: TNoteiceFilter | undefined
  @observable selectedUnitImei: TUnit['imei'] | undefined
  units = new NoticesUnitsListStore()
  notices = new NoticesListStore()
  period = new PeriodStore('today')

  constructor() {
    makeObservable(this)

    // Если переключили период загружаем события с сервера
    autorun(async () => {
      if(this.period.period !== 'today') {
        await this.loadNoteices()
        this.actualSelectedUnitAndNoticesList()
      }
    })

    // Когда получены новые данные надо их перенести в список
    observe(this, 'data', () => {
      this.units.list.setItems(this.unitsListForDataSource)
      this.actualSelectedUnitAndNoticesList()
    }, false)

    // Когда переключают на "сегодня" с другого режима, меняется источник данных без повторного запроса
    observe(this.period, 'period', () => {
      this.units.list.setItems(this.unitsListForDataSource)
    }, false)
  }

  async actualSelectedUnitAndNoticesList() {
    if(!this.selectedUnitImei) {
      // Если еденица не выбрана очищаем список событий
      return this.notices.list.setItems([])
    }

    if(this.period.period !== 'today' || !this.isFilterEmpty) {
      if(this.data && this.data[this.selectedUnitImei]?.length) {
        // Если в полученных данных есть машина которая была выбрана показываем ее события
        this.notices.setData(this.data[this.selectedUnitImei])
      } else if(this.units.list.sortedItems.length) {
        // если нет машины - которая была выбрана ранее выбираем первую из списка
        this.selectUnit(this.units.list.sortedItems[0].imei)
      } else {
        //  иначе снимаем выбор очизаем список
        this.unselectUnit()
      }
    } else {
      //@ts-expect-error
      // Если данные за сегодня
      if(this.getUnitsFromRegisters.find(U => U.imei === this.selectedUnitImei)) {
        // Если выбранная единица есть в registers - загружаем ее события
        this.noticesLoad(this.selectedUnitImei)
      } else if(this.units.list.sortedItems.length) {
        // Если в списке есть юниты - выбираем первый
        this.noticesLoad(this.units.list.sortedItems[0].imei)
      } else {
        //  иначе снимаем выбор очизаем список
        this.unselectUnit()
      }
    }
  }

  @action

  //@ts-expect-error
  setLoading(isLoading) {
    this.isLoading = isLoading
  }

  @action
  selectUnit(imei: TUnit['imei']) {
    this.selectedUnitImei = imei

    if(this.data && this.data[this.selectedUnitImei]?.length) {
      // Если в полученных данных есть машина которая была выбрана показываем ее события
      this.notices.setData(this.data[this.selectedUnitImei])
    } else {
      this.noticesLoad(imei)
    }
  }

  //@ts-expect-error
  async noticesLoad(imei) {
    await this.notices.load(this.queryParamsData, imei)
  }

  @action
  unselectUnit() {
    this.selectedUnitImei = undefined
    this.notices.list.setItems([])
  }

  @action

  //@ts-expect-error
  setFilter(newFilter) {
    this.filter = newFilter

    if(!this.isFilterEmpty || this.period.period !== 'today') {
      // Если фильтр не пустой - загружаем данные
      this.loadNoteices()
    } else {
      // Если фильтр пустой и за сегодня - показываем данные из регистерс
      this.setData({})
    }
  }

  @computed
  get isFilterEmpty() {
    return all(anyPass([isEmpty, isNil]))(values(this.filter || {}))
  }

  get queryParamsData(): unknown {
    const { lang, gmt } = getStore('context')

    //@ts-expect-error
    const status = []

    //@ts-expect-error
    const category = []

    if(this.filter?.status && this.filter.status.length) {
      this.filter.status.map(item => {
        const findStatus = Object.keys(notificationStatus).find(

          //@ts-expect-error
          S => notificationStatus[S].typeTranslate === item,
        )

        if(findStatus) {
          //@ts-expect-error
          status.push(notificationStatus[findStatus].type)
        }
      })
    }

    if(this.filter?.category && this.filter.category.length) {
      this.filter.category.map(item => {
        const findCategory = Object.keys(notificationCategory).find(

          //@ts-expect-error
          C => notificationCategory[C].typeTranslate === item,
        )

        if(findCategory) {
          //@ts-expect-error
          category.push(notificationCategory[findCategory].type)
        }
      })
    }

    let imeis = this.filter?.machine && !isEmpty(this.filter.machine)
      ? uniq(values(this.filter.machine).flat().map(item => Number(item.imei)))
      : undefined

    return {
      from: this.period.start.utc,
      ...isNil(imeis) || isEmpty(imeis) ? {} : { imeis },
      lang: lang.toUpperCase(),

      //@ts-expect-error
      maskCategory: isEmpty(category) ? undefined : category,

      //@ts-expect-error
      maskStatus           : isEmpty(status) ? undefined : status,
      timezoneOffsetMinutes: gmt * 60,
      to                   : this.period.end.utc,
    }
  }

  @computed
  get unitsListForDataSource() {
    return this.period.period !== 'today' || !this.isFilterEmpty
      ? this.unitsWithNotifications
      : this.getUnitsFromRegisters
  }

  get unitsWithNotifications() {
    //@ts-expect-error
    return getStore('dictionaries').targets2.filterDataForCurrentFarm.reduce((acc, unit) => {
      if(this.data && this.data[unit.imei] && this.data[unit.imei].length) {
        unit.sumNotifications = this.data[unit.imei].reduce(
          (accNotice, item) => {
            const findNoticeType = accNotice.find(

              //@ts-expect-error
              accNoticeItem => accNoticeItem.status === item.status,
            )

            if(findNoticeType) {
              //@ts-expect-error
              ++findNoticeType.value
            } else {
              //@ts-expect-error
              accNotice.push({ status: item.status, value: 1 })
            }

            return accNotice
          },
          [],
        )

        acc.push(unit)
      }

      return acc
    }, [])
  }

  get getUnitsFromRegisters() {
    //@ts-expect-error
    return getStore('dictionaries').targets2.filterDataForCurrentFarm.reduce((acc, unit) => {
      const registerNotificationCounts = pipe(
        values,
        map<any, any>(I => ({ value: unit.registers[I.registersField] || 0, status: I.value })),
      )(notificationStatus)

      if(sum(registerNotificationCounts.map(N => N.value)) > 0) {
        unit.sumNotifications = registerNotificationCounts
        acc.push(unit)
      }

      return acc
    }, [])
  }

  async loadNoteices() {
    console.log('load notices')

    this.setLoading(true)
    this.units.setLoading(true)
    this.notices.setIsLoading(true)

    // const isSameFilterQueryAsBefore = !notification.queryParams
    //   || notification.queryParams.from - this.period.end.utc === 0
    //   && notification.queryParams.to - this.period.end.utc === 0
    //   && notification.queryParams.maskCategory === queryParamsData.maskCategory
    //   && notification.queryParams.maskStatus === queryParamsData.maskStatus
    //   && isEmpty(symmetricDifference(notification.queryParams?.imeis || [], imeis || []))
    //
    // if(this.period.period === 'today') {
    //   if(!imeis) {
    //     queryParamsData.imeis = [this.selectedUnitImei]
    //   } else {
    //     queryParamsData.imeis = imeis
    //   }
    // } else if(isSameFilterQueryAsBefore) {
    //   continue
    // }

    // const notices = yield call(getMultiNotices, queryParamsData)

    try {
      this.setData(await getMultiNotices(this.queryParamsData))
    } catch(error) {
      console.error(error)
      const t = getStore('context').t
      errorMessage(t('Error while receiving data'))
      this.setData({})
    }

    this.setLoading(false)
    this.units.setLoading(false)
    this.notices.setIsLoading(false)
  }

  initialLoad() {
    this.units.list.setItems(this.unitsListForDataSource)
  }

  unload() {
    // TODO release data
  }

  @action

  //@ts-expect-error
  setData(data) {
    this.data = data
  }
}
