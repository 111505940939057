import {
  NotificationActionsType,
  NotificationActionTypeEnum,
} from './actions'
import * as State from './state'

export default function notification(state = State.init(), action: NotificationActionsType): State.Notification {
  switch(action.type) {
    case NotificationActionTypeEnum.CHANGE_LOADING_NOTIFICATIONS:
      return State.setLoadingNotifications(state, action.payload)

    case NotificationActionTypeEnum.CHANGE_LOADING_CALENDAR_PAINTING:
      return State.setLoadingCalendarPainting(state, action.payload)

    case NotificationActionTypeEnum.SET_SELECTED_MACHINE:
      return State.setSelectedMachine(state, action.payload)

    case NotificationActionTypeEnum.SET_DATE_RANGE:
      return State.setDateRange(state, action.payload)

    case NotificationActionTypeEnum.SET_FILTER:
      return State.setFilter(state, action.payload)

    case NotificationActionTypeEnum.SET_REGISTER_VALUES:
      return State.setRegisterValue(state, action.payload)

    case NotificationActionTypeEnum.SET_REGISTER:
      return State.setRegister(state, action.payload)

    case NotificationActionTypeEnum.CLEAR_REGISTER:
      return State.clearRegister(state)

    case NotificationActionTypeEnum.SET_REGISTER_DATA:
      return State.setRegisterData(state, action.payload)

    case NotificationActionTypeEnum.SET_CURRENT_REGISTER:
      return State.setCurrentRegister(state, action.payload)

    case NotificationActionTypeEnum.SET_PAGE:
      return State.setPage(state, action.payload)

    case NotificationActionTypeEnum.SET_PAGE_SIZE:
      return State.setPageSize(state, action.payload)

    default:
      return state
  }
}
