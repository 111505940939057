import 'regenerator-runtime/runtime'
import { MobilePlaceholder, ModalPortalProvider } from '@agdt/agrotronic-react-components'
import LangProvider from 'components/LangProvider'
import { prop } from 'ramda'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import App from './App'
import store from './reducers/store'

const parser = new UAParser()
const isMobile = prop('type', parser.getDevice()) === 'mobile'

ReactDOM.render(

  /*@ts-expect-error*/
  <Provider store={store}>
    <LangProvider>
      <ModalPortalProvider>
        {isMobile ? <MobilePlaceholder/> : <App/>}
      </ModalPortalProvider>
    </LangProvider>
  </Provider>,
  document.getElementById('root'),
)
