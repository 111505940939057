import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { ActiveRange,ColorType,Day, DayShadow } from './Day'

type ListDaysProps = {
  format: string
  activeRange?: ActiveRange
  date: moment.MomentInput
  permissions?: number
  history?: number
  isSoloCalendar: boolean
  oneActiveDay?: moment.MomentInput
  setRange: (value: moment.Moment) => void
  colors?: ColorType[]
}

export const ListDays: FC<ListDaysProps> = ({
  format,
  activeRange,
  date,
  permissions,
  history,
  isSoloCalendar,
  oneActiveDay,
  setRange,
  colors,
}) => {
  const getStartEndMonth = (value: moment.MomentInput) => ({
    end  : moment(value, format).endOf('month').endOf('week'),
    start: moment(value, format).startOf('month').startOf('week'),
  })

  const getNewArrayList = (mm: moment.MomentInput) => {
    const arrayList = []
    const sizeWeeks = 5

    for(let weekNumber = 0; weekNumber <= sizeWeeks; weekNumber++) {
      for(let dayNumber = 0; dayNumber <= 6; dayNumber++) {
        arrayList.push(moment(getStartEndMonth(mm).start).add({
          days: dayNumber,
          week: weekNumber,
        }))
      }
    }

    return arrayList
  }

  const [arrayList, setArrayList] = useState<moment.Moment[]>(getNewArrayList(date))

  useEffect(()=>{
    setArrayList(getNewArrayList(date))
  },[date])

  const renderDays = () => {
    return arrayList.map(day => {
      if(moment(day).month() !== moment(date, format).month()) {
        return <DayShadow
          activeRange={activeRange}
          key={moment(day).format(format)}
          day={day}
          format={format}
        />
      }

      return <Day
        key={moment(day).format(format)}
        day={day}
        permissions={permissions}
        history={history}
        isSoloCalendar={isSoloCalendar}
        format={format}
        activeRange={activeRange}
        oneActiveDay={oneActiveDay}
        setRange={setRange}
        colors={colors}
      />
    })
  }

  return <div className="Date-Month__month">{renderDays()}</div>
}
