import { HeaderCell, HeaderRow, Table, TableBody, TableCell, TableHeader, TableRow } from '@agdt/agrotronic-react-components'
import DimensionsMeasurer from 'components/DimensionsMeasurer'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { link } from '../../../router'
import { Statuses } from '../Statuses'

type TProps = {
  t: TTranslate
}

export const FarmList = observer(({ t }: TProps) => {
  const setSelectedFarmId = getStore('context').setSelectedFarmId
  const preparedFarmList = getStore('activityWidget').preparedWidgetData
  const selectFarm = useCallback((id: number) => setSelectedFarmId(id), [setSelectedFarmId])

  return (
    <Container height={350}>
      {(dimension: { height: number, width: number }) =>
        <Table>
          <TableHeader>
            <HeaderRow backgroundColor="#F5F6F8">
              <HeaderCell maxWidth="100%" key="name">{t('farming_name')}</HeaderCell>
              <HeaderCell maxWidth="120px" key="counter">{t('machines_quantity')}</HeaderCell>
              <HeaderCell maxWidth="320px" key="activity">{t('activities')}</HeaderCell>
            </HeaderRow>
          </TableHeader>

          <TableBody>
            <List
              height={dimension.height}
              itemCount={preparedFarmList.length}
              itemData={preparedFarmList}
              itemSize={32}
              width={dimension.width}>
              {({ index, style }) => {
                const item = preparedFarmList[index]

                return (
                  <HouseholdTableRow
                    key={item.id}
                    backgroundColor="white"
                    style={style}
                  >
                    <TableCell key="name" maxWidth="100%" >
                      <HouseholdName
                        to={link.machines}
                        onClick={() => selectFarm(item.id)}
                      >
                        {item.name}
                      </HouseholdName>
                    </TableCell>

                    <TableCellCount key="counter" maxWidth="120px" >{item.total}</TableCellCount>

                    <TableCell key="activity" maxWidth="320px" >
                      {item.statuses && <Statuses
                        id={item.id}
                        statuses={item.statuses}
                        t={t}
                      />}
                    </TableCell>
                  </HouseholdTableRow>
                )
              }}
            </List>
          </TableBody>
        </Table>
      }
    </Container>
  )
})

const Container = styled(DimensionsMeasurer)`
  width: 100%;
  height: 59%;
  flex-grow: 1;
`

const HouseholdTableRow = styled(TableRow)`
  cursor: inherit;
  border-bottom: 1px solid #E2E5EC;
  min-height: 31px;
`

const HouseholdName = styled(Link)`
  /* Regular 12 */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Color 4 */
  color: #0072CF;

  text-decoration: none;
`

const TableCellCount = styled(TableCell)`
  /* Regular 12 */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  /* Color 3 */
  color: #202020;
`
