import { prepareDuration } from 'ui/Map/duration'
import { C_TIME_COUNTER_REGISTERS } from '../../../../constants'

export const formatter = (key: string, val: number, measure: string) => {
  let value: string | number = val

  if(value !== null && value !== undefined) {
    if(key === 'ACTIVE') {
      value = value ? new Date(value).user.format('DD.MM.YY HH:mm:ss') : '-'
    } else if(key.indexOf('TIME_') === 0) {
      value = prepareDuration(value)
    }

    // параметры, которые содержат единицу измерения часы (ч) нужно не показывать "ч" и переформатировать в чч:мм:сс
    else if(C_TIME_COUNTER_REGISTERS.includes(key)) {
      value = prepareDuration(val * 3600000)
    } else {
      if(!isNaN(parseFloat(String(value))) && isFinite(value)) {
        value = `${Math.round(value * 100) / 100} ${measure}`
      } else {
        value = `${value} ${measure}`
      }
    }
  } else {
    value = '-'
  }

  return value
}
