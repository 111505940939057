import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { SoloMachineMap } from '../Map/soloMachineMap'

type Props = {
  closePopup: () => void
  lat?: number
  lon?: number
}

export const Popup: FC<Props> = ({ closePopup, lat, lon }) => {
  const translate = useTranslate()

  return (
    <div className="mm-popup">
      <div
        className="mm-popup__overlay"
        onClick={closePopup}
      />
      <div className="mm-popup__box">
        <span
          className="mm-popup__close"
          onClick={closePopup}
        >
          x
        </span>
        {lat && lon ?
          <SoloMachineMap marker={[lat, lon]} />
          :
          <div className="mm-popup__error">{translate('No location data')}</div>
        }
      </div>
    </div>
  )
}
