import { observer } from 'mobx-react'
import { compose, isEmpty, keys } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { TUser } from 'types'
import { notificationNumName } from 'utils'
import { filteredUsersOfType } from '../../reducers/users/selectors'
import { link } from '../../router'
import { getStore } from '../../stores/storesRegistry'

export type TFilter = {
  id : number
  items : Array<number | string>
  labels?: string[]
  name : string
  notTranslating?: boolean
  result : string[]
  section : string
  search?: boolean
  type : string
}


const C_SECTION_SORT_ITEMS = ['agriculture', 'type']

//@ts-expect-error TODO этот компонент необходимо переписать
export const InitFilter = compose(
  connect(
    state => ({
      users: filteredUsersOfType(state),
    }),
  ),

  withTranslate,
  observer,

  ComposedComponent => class extends React.Component {
  state = {
    filter    : [],
    result    : [],
    filtration: false,
  };

  componentDidMount() {
    //@ts-expect-error
    if(this.props.users) {
      this.searchParameters(this.state.filter)
    }
  }

  componentDidUpdate(prevProps: { users: TUser[] }) {
    //@ts-expect-error
    if(prevProps.users !== this.props.users) {
      this.searchParameters(this.state.filter)
      this.onFilter(this.state)
    }
  }

  searchParameters = (stateFilter?: TFilter[]) => {
    let filter: TFilter[] = [
      {
        id            : 0,
        type          : 'text',
        section       : 'text',
        name          : 'Имя',
        items         : [],
        result        : [],
        notTranslating: true,
      },
      {
        id     : 1,
        type   : 'checkbox',
        section: 'status',

        //@ts-expect-error
        name          : this.props.translate('status'),
        items         : [],
        labels        : [],
        result        : [],
        notTranslating: true,
      },
      {
        id     : 2,
        type   : 'checkbox',
        section: 'type',

        //@ts-expect-error
        name          : this.props.translate('type_of_machine'),
        items         : [],
        result        : [],
        search        : true,
        notTranslating: true,
      },
      {
        id     : 4,
        type   : 'checkbox',
        section: 'notification',

        //@ts-expect-error
        name  : this.props.translate('notifications'),
        items : [],
        result: [],
        labels: [],
      },
      {
        id     : 5,
        type   : 'checkbox',
        section: 'agriculture',

        //@ts-expect-error
        name          : this.props.translate('agriculture'),
        items         : [],
        result        : [],
        notTranslating: true,
      },
    ]

    filter = this.addItems(filter, stateFilter)

    const preparedFilters = filter.reduce((acc: TFilter[], filterItem) => {
      if(!isEmpty(filterItem.items)) {
        acc.push(C_SECTION_SORT_ITEMS.includes(filterItem.section)
          ? { ...filterItem, items: filterItem.items.sort() }
          : filterItem)
      }

      return acc
    }, [])

    this.setState({ filter: preparedFilters })
  };


  addItems = (filter: TFilter[], stateFilter?: TFilter[]) => {
    let filterNorm: Record<string, TFilter> = {}

    filter.forEach(item => {
      filterNorm[item.section] = { ...item }
    })

    getStore('dictionaries').targets2.sortedUnitsWithRegistersData.map(unit => {
      if(!filterNorm['status'].items.filter(status => unit.data.STATUS && status === unit.data.STATUS.value).length) {
        if(unit.data.STATUS) {
          filterNorm['status'].items.push(unit.data.STATUS.value)
          filterNorm['status'].labels?.push(unit.data.STATUS.valueF)
        }
      }

      if(!filterNorm['type'].items.filter(typeName => typeName === unit.typeName).length) {
        filterNorm['type'].items.push(unit.typeName)
      }

      keys(notificationNumName).forEach(noticeKey => {
        if(unit.data[noticeKey] && unit.data[noticeKey].value) {
          if(!filterNorm['notification'].items.filter(status => status === notificationNumName[noticeKey].value).length) {
            filterNorm['notification'].items.push(notificationNumName[noticeKey].value)
            filterNorm['notification'].labels?.push(notificationNumName[noticeKey].typeTranslate)
          }
        }
      })

      if(unit.data.AGRICULTURE && unit.data.AGRICULTURE.value && (!filterNorm['agriculture'] || !filterNorm['agriculture'].items.filter(typeName => typeName === unit.data.AGRICULTURE.value).length)) {
        filterNorm['agriculture'].items.push(unit.data.AGRICULTURE.value)
      }

      filterNorm['text'].items = [...filterNorm['text'].items, unit.name]
    })

    if(stateFilter && stateFilter.length) {
      stateFilter.forEach(item => {
        if(item.section !== 'text') {
          filterNorm[item.section].result = item.result.filter(resultItem => filterNorm[item.section].items.includes(resultItem))
        } else {
          filterNorm[item.section].result = item.result
        }
      })
    }

    return filter.map(item => filterNorm[item.section])
  };

  soloParam = (section: string, value: TFilter) => {
    if(section !== 'text') {
      this.setState((prevState: { filter: TFilter[] }) => {
        const findSection = prevState.filter.find(item => item.section === section)

        //@ts-expect-error
        findSection.result = findSection.result.filter(item => item !== value)

        //@ts-expect-error
      }, () => this.onFilter(this.state, section))
    } else {
      this.setState(prevState => {
        //@ts-expect-error
        const findSection = prevState.filter.find(item => item.section === section)
        findSection.result = []

        //@ts-expect-error
      }, () => this.onFilter(this.state, section))
    }
  };

  //@ts-expect-error
  multiParam = (section: string, value) => {
    if(section !== 'text') {
      this.setState(prevState => {
        //@ts-expect-error
        const findSection = prevState.filter.find(item => item.section === section)
        findSection.result = [...findSection.result, value]

        //@ts-expect-error
      }, () => this.onFilter(this.state, section))
    } else {
      this.setState(prevState => {
        //@ts-expect-error
        const findSection = prevState.filter.find(item => item.section === section)
        findSection.result = [value]

        //@ts-expect-error
      }, () => this.onFilter(this.state, section))
    }
  };

  //@ts-expect-error
  onStartFilter = (section: string, value) => {
    //@ts-expect-error
    if(this.state.filter

      //@ts-expect-error
      .find(item => item.section === section).result

      //@ts-expect-error
      .find(item => item === value) !== undefined) {
      this.soloParam(section, value)
    } else {
      this.multiParam(section, value)
    }
  };

  //@ts-expect-error
  getParam = (param, state, unit) => {
    switch(param.section) {
      case 'status': {
        if(state.filter.find((item: TFilter) => item.section === 'status') && state.filter.find((item: TFilter) => item.section === 'status').result.length) {
          return param.result.includes(unit.data.STATUS.value)
        }

        break
      }

      case 'type': {
        if(state.filter.find((item: TFilter) => item.section === 'type').result.length) {
          return param.result.includes(unit.typeName)
        }

        break
      }

      case 'main': {
        if(state.filter.find((item: TFilter) => item.section === 'main').result.length) {
          return param.result.includes(unit.section)
        }

        break
      }

      case 'notification': {
        if(state.filter.find((item: TFilter) => item.section === 'notification') && state.filter.find((item: TFilter) => item.section === 'notification').result.length) {
          return !!keys(notificationNumName).find(notice =>
            param.result.includes(notificationNumName[notice].value) && unit.data[notice] && unit.data[notice].value,
          )
        }

        break
      }

      case 'agriculture': {
        if(state.filter.find((item: TFilter) => item.section === 'agriculture') && state.filter.find((item: TFilter) => item.section === 'agriculture').result.length) {
          if(!unit.data.AGRICULTURE || !unit.data.AGRICULTURE.value) {
            return false
          } else {
            return param.result.includes(unit.data.AGRICULTURE.value)
          }
        }

        break
      }

      case 'text': {
        if(state.filter.find((item: TFilter) => item.section === 'text').result[0]) {
          return unit.name.toLowerCase().includes(state.filter.find((item: TFilter) => item.section === 'text').result[0].toLowerCase())
        }

        return true
      }

      default: {
        break
      }
    }
  };

  addSection = () => {
    //@ts-expect-error
    this.props.users.map(item => {
      //@ts-expect-error
      item.units.units.map(it => {
        it.section = item.name
      })
    })
  };

  //@ts-expect-error
  onFilter = state => {
    this.addSection()

    if(state.filter.find((item: TFilter) => item.result.length !== 0)) {
      this.setState({ filtration: true })
    } else {this.setState({ filtration: false })}

    const units = getStore('dictionaries').targets2.sortedUnitsWithRegistersData
    const parameters = state.filter.filter((item: TFilter) => !isEmpty(item.result.length))

    let result = []

    for(let unit of units) {
      //@ts-expect-error
      const resultParam = parameters.map(param => this.getParam(param, state, unit))

      if(!resultParam.includes(false)) {
        result.push(unit)
      }
    }

    this.setState({ result })
  };

  deleteParameters = (section: string, value: TFilter) => {
    this.setState(prevState => {
      //@ts-expect-error
      const findSection = prevState.filter.find((item: TFilter) => item.section === section)
      findSection.result = findSection.result.filter((item: TFilter) => item !== value)
      return prevState
    }, () => {
      if(!this.state.filter.find((item: TFilter) => item.result.length)) {
        this.setState({ filtration: false })
      }
    })
  };

  clearFilter = () => {
    this.searchParameters()

    //@ts-expect-error
    this.props.history.push(`${link.machines}`)

    this.setState({
      result    : [],
      filtration: false,
    })
  };

  render() {
    const units = getStore('dictionaries').targets2.sortedUnitsWithRegistersData

    return (
      <ComposedComponent
        {...this.props}
        clearFilter={this.clearFilter}
        deleteParameters={this.deleteParameters}
        filter={this.state.filter}
        filtration={this.state.filtration}
        onStartFilter={this.onStartFilter}
        result={this.state.result}

        //@ts-expect-error
        translate={this.props.translate}
        units={units}
      />
    )
  }
  })
