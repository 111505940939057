import { ActivityWidget } from '../ActivityWidget'
import FuelWidget from '../FuelWidget'
import MapWidget from '../MapWidget'
import ServiceWidget from '../ServiceWiget'
import SquareInfoWidget from '../SquareInfoWidget'
import TimeWidget from '../TimeWidget'

//https://agdt.evateam.ru/desk/Task/AT-3066#vypilit-servis-pogody-iz-vseh-mest-ispolzovaniya-v-web-prilozhenii
//import WeatherWidget from '../WeatherWidget'
import * as icons from './icons'

export const C_COMPACT_HEIGHT = 4
export const C_MIDDLE_HEIGHT = 8
export const C_LARGE_HEIGHT = 16

const widgetsConfig = [
  {
    id         : 'fuel',
    isMultiple : true,
    isResizable: true,
    Component  : FuelWidget,
    defaultSize: { h: C_MIDDLE_HEIGHT, w: 1 },
    Preview    : icons.FuelWidgetPreviewIcon,
    title      : 'fuel',
  },
  {
    id         : 'map',
    isMultiple : false,
    Component  : MapWidget,
    defaultSize: { h: C_LARGE_HEIGHT, w: 2 },
    Preview    : icons.MapWidgetPreviewIcon,
    title      : 'map',
  },
  {
    id         : 'square',
    isMultiple : true,
    isResizable: true,
    Component  : SquareInfoWidget,
    defaultSize: { h: C_MIDDLE_HEIGHT, w: 1 },
    Preview    : icons.SquareInfoWidgetPreviewIcon,
    title      : 'Combine Harvesters',
  },
  {
    id         : 'service',
    isMultiple : true,
    isResizable: true,
    Component  : ServiceWidget,
    defaultSize: { h: C_MIDDLE_HEIGHT, w: 1 },
    Preview    : icons.ServiceWidgetPreviewIcon,
    title      : 'Maintance',
  },

  //https://agdt.evateam.ru/desk/Task/AT-3066#vypilit-servis-pogody-iz-vseh-mest-ispolzovaniya-v-web-prilozhenii
  // {
  //   id         : 'weather',
  //   isMultiple : true,
  //   isResizable: true,
  //   Component  : WeatherWidget,
  //   defaultSize: { h: C_MIDDLE_HEIGHT, w: 1 },
  //   Preview    : icons.WeatherWidgetPreviewIcon,
  //   title      : 'weather',
  // },
  {
    id         : 'time',
    isMultiple : true,
    isResizable: true,
    Component  : TimeWidget,
    defaultSize: { h: C_MIDDLE_HEIGHT, w: 1 },
    Preview    : icons.TimeWidgetPreviewIcon,
    title      : 'time',
  },
  {
    id         : 'activity',
    isMultiple : false,
    Component  : ActivityWidget,
    defaultSize: { h: C_LARGE_HEIGHT, w: 2 },
    Preview    : icons.ActivityWidgetPreviewIcon,
    title      : 'All units',
  },
]

export default widgetsConfig
