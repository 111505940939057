import { Tooltip } from 'antd'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { TTranslate } from 'types'
import { link } from '../../router'
import { Icons } from './icons'

export const statusName = {
  shadow: 0,
  blue  : 1,
  black : 2,
  red   : 3,
  green : 4,
}

const progressColor = {
  shadow: 'shadow',
  red   : 'red',
  yellow: 'yellow',
  green : 'green',
}

type ProgressProps = {
  translate: TTranslate
  size?: string
  fuel: number
}

export const Progress: FC<ProgressProps> = ({ fuel, size = '', translate }) => {
  const getColor = () => {
    if(fuel === 0) {
      return progressColor.shadow
    }

    if(fuel <= 20 && fuel !== 0) {
      return progressColor.red
    }

    if(fuel <= 60 && fuel > 20) {
      return progressColor.yellow
    }

    return progressColor.green
  }

  return (
    <Tooltip title={translate('Level fuel in tank')}>
      <div className={`detail-main__data-fiel with-white ${size}`}>
        <div
          className={`detail-main__data-fiel-progres ${getColor()}`}
          style={{ height: `${fuel}%` }}
        />
      </div>
    </Tooltip>
  )
}

type MachineCardProps = {
  imei: number
  fuel: number
  model: string
  id: number
  statusF: string
  color: string
  type: string
  name: string
  messages: {
    count?: number
    status: number
  }[]
  translate: TTranslate
}

export const MachineCard: FC<MachineCardProps> = ({
  statusF,
  imei,
  id,
  model,
  color,
  fuel,
  type,
  name,
  messages,
  translate,
}) => {
  return (
    <div className="data-machine">
      <div className="data-machine__status">
        <div className="data-machine__status-wrap">
          <Tooltip title={statusF}>
            <div className="data-machine__icon">
              <Link
                className="data-machine__name-a"
                to={`${link.detail}/?machine=${id}`}
              >
                {Icons[model]?.(color) || Icons.NINJA('#b1b1b1')}
              </Link>
            </div>
          </Tooltip>
          <Progress fuel={fuel} translate={translate} />
        </div>
      </div>
      <div className="data-machine__text">
        <p className="data-machine__name">
          <Link
            className="data-machine__name-a"
            to={`${link.detail}/?machine=${id}`}
          >
            {type}
          </Link>
          {messages.map(item =>
            <Link
              key={item.status}
              to={`${link.notifications}?machine=${id}&status=${item.status}`}
              style={{
                background:
                    item.status === 1
                      ? '#D10B41'
                      : item.status === 2
                        ? '#f1c340'
                        : item.status === 4
                          ? '#66ce5a'
                          : '#5ac3ce',
              }}
              className="data-machine__circle data-machine__warning"
            >
              {item.count}
            </Link>,
          )}
        </p>
        <Link
          to={`${link.detail}/?machine=${id}`}
          className="data-machine__code"
        >
          {name}
        </Link>
        <Link
          to={`${link.detail}/?machine=${id}`}
          className="data-machine__code"
        >
          {imei}
        </Link>
      </div>
    </div>
  )
}
