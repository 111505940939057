import React, { FC } from 'react'
import { TMapTypes, TTranslate } from 'types'

type StyleMapProps = {
  type: TMapTypes
  switchType: (value: TMapTypes) => void
  translate: TTranslate
}

export const StyleMap:FC<StyleMapProps> = ({ type, switchType, translate }) => {
  const switchOsmType = () => {
    switchType('osm')
  }

  const switchSputnicType = () => {
    switchType('sputnic')
  }

  const switchGoogleType = () => {
    switchType('google')
  }

  const switchGoogle1Type = () => {
    switchType('google1')
  }

  const switchGoogle2Type = () => {
    switchType('google2')
  }

  return (
    <div className="map-type__controll">
      <div className="map-type__item">
        <input id="type2" type="radio" name="type" defaultChecked={type === 'osm'} />
        <label
          tabIndex={0}
          role="button"
          htmlFor="type2"
          onClick={switchOsmType}
        >
          <span>OSM {translate('map')}</span>
        </label>
      </div>
      <div className="map-type__item">
        <input id="type3" type="radio" name="type" defaultChecked={type === 'sputnic'} />
        <label
          tabIndex={0}
          role="button"
          htmlFor="type3"
          onClick={switchSputnicType}
        >
          <span>{translate('sputnic')}</span>
        </label>
      </div>
      <div className="map-type__item">
        <input id="type4" type="radio" name="type" defaultChecked={type === 'google'} />
        <label
          tabIndex={0}
          role="button"
          htmlFor="type4"
          onClick={switchGoogleType}
        >
          <span>Google {translate('map')}</span>
        </label>
      </div>
      <div className="map-type__item">
        <input id="type5" type="radio" name="type" defaultChecked={type === 'google1'} />
        <label
          tabIndex={0}
          role="button"
          htmlFor="type5"
          onClick={switchGoogle1Type}
        >
          <span>Google {translate('hybrid')}</span>
        </label>
      </div>
      <div className="map-type__item">
        <input id="type6" type="radio" name="type" defaultChecked={type === 'google2'} />
        <label
          tabIndex={0}
          role="button"
          htmlFor="type6"
          onClick={switchGoogle2Type}
        >
          <span>Google {translate('terrain')}</span>
        </label>
      </div>
    </div>
  )
}
