import { formats } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
import React, { FC,useEffect, useState } from 'react'
import { UserInfoType } from 'reducers/user/state'
import { TTranslate } from 'types'
import { ValidateInput } from '../common/ValidateInput'
import { emailValidators, phoneValidator, validate } from '../common/validators'
import { CodeConfirmWrapper } from './CodeConfirmWrapper'

const { phone } = formats

type Props = {
  user: UserInfoType
  disabled: boolean
  t: TTranslate
  handleUpdateUserInfo: (fieldName: string, value: unknown) => void
}

const useFormInputWithConfirm = (initialValue?: string) => {
  const [inputState, setInputState] = useState({
    initialValue,
    inputValue      : initialValue,
    showConfirmation: false,
    validationError : '',
  })

  const handleSetState = (field: string) => (value: string | boolean | undefined) => setInputState(state => ({
    ...state,
    [field]: value,
  }))

  const handleSetInitialValue = handleSetState('initialValue')
  const handleSetShowConfirmation = handleSetState('showConfirmation')
  const handleSetValidationError = handleSetState('validationError')
  const handleSetValue = handleSetState('inputValue')

  const handleOnBlur = () => {
    if(isEmpty(inputState.inputValue)) {
      handleSetValue(inputState.initialValue)
      handleSetValidationError(false)
      return
    }

    if(!inputState.validationError && inputState.inputValue !== inputState.initialValue) {
      handleSetShowConfirmation(true)
    }
  }

  return {
    handleOnBlur,
    handleSetInitialValue,
    handleSetShowConfirmation,
    handleSetValidationError,
    handleSetValue,
    initialValue,
    inputValue      : inputState.inputValue,
    reset           : () => handleSetValue(inputState.initialValue),
    showConfirmation: inputState.showConfirmation,
    validationError : inputState.validationError,
  }
}

const emailValidate = validate(emailValidators)
const phoneValidate = validate(phoneValidator)

export const UserPhoneEmailInputs: FC<Props> = ({ disabled, handleUpdateUserInfo, t, user }) => {
  const phoneInput = useFormInputWithConfirm(user.operatorTelNumber || '')
  const emailInput = useFormInputWithConfirm(user.operatorEmail)

  useEffect(() => {
    phoneInput.handleSetInitialValue(user.operatorTelNumber || '')
  }, [user.operatorTelNumber])

  useEffect(() => {
    emailInput.handleSetInitialValue(user.operatorEmail)
  }, [user.operatorEmail])

  return <>
    <ValidateInput
      disableClear
      disabled={disabled}
      error={Boolean(phoneInput.validationError)}
      handleOnBlur={phoneInput.handleOnBlur}

      helperText={
        phoneInput.validationError || t('Only mobile numbers of the Russian Federation with the code +7')
      }

      initValue={phoneInput.initialValue}
      label={t('Phone')}
      onEnter={phoneInput.handleOnBlur}
      postProcessValue={phone.onlyDigits}
      required={!user.operatorEmail}
      setValidateError={phoneInput.handleSetValidationError}
      setValue={phoneInput.handleSetValue}
      t={t}
      testid='profile-info__user-phone'
      validator={phoneValidate}
      value={phone.phoneMask(phoneInput.inputValue)}
    />

    <ValidateInput
      disableClear
      disabled={disabled}
      error={Boolean(emailInput.validationError)}
      handleOnBlur={emailInput.handleOnBlur}
      helperText={emailInput.validationError}
      initValue={emailInput.initialValue}
      label='E-mail'
      required={!user.operatorTelNumber}
      setValidateError={emailInput.handleSetValidationError}
      setValue={emailInput.handleSetValue}
      t={t}
      testid='profile-info__user-email'
      validator={emailValidate}
      value={emailInput.inputValue || ''}
    />

    <CodeConfirmWrapper
      closeHandler={phoneInput.reset}
      fieldName='operatorTelNumber'
      handleUpdateUserInfo={handleUpdateUserInfo}
      setShowConfirmation={phoneInput.handleSetShowConfirmation}
      showConfirmation={phoneInput.showConfirmation}
      t={t}
      value={phoneInput.inputValue}
    />

    <CodeConfirmWrapper
      closeHandler={emailInput.reset}
      fieldName='operatorEmail'
      handleUpdateUserInfo={handleUpdateUserInfo}
      setShowConfirmation={emailInput.handleSetShowConfirmation}
      showConfirmation={emailInput.showConfirmation}
      t={t}
      value={emailInput.inputValue}
    />
  </>
}
