// @todo отказаться от immutable
import { List, Map } from 'immutable'
import { TWorkMode } from 'types'

type ModesType = {
  [Key in TWorkMode]: number
}

export type TimeReportType = {
  from: string
  to: string
  modes: ModesType
}


export enum ReportType {
  AgroOperations = 'agrooperations',
  Fueling = 'fueling',
  FuelReport = 'fuelReport',
  Groupmachinery = 'groupmachinery',
  Machine = 'machine',
  PerformanceIndicators = 'performanceIndicators',
  Time = 'time',
  Equipment = 'equipment',
  Cleaning = 'cleaning',
  Export = 'export',
  Unloading = 'unloading',
}

export type EquipmentType = {
  carrierReport: unknown
  load: boolean
  report: unknown
  vehicleReport: unknown
}

export const init = () => Map({
  agrooperations: Map({
    agrooperations: List([]),
    detailLoad    : List([]),
    numberLoad    : 0,
    onStopReport  : false,
    request       : {},
    resultLoad    : List([]),
  }),
  cleaning: Map({
    load  : false,
    report: null,
  }),
  equipment: Map({
    carrierReport: null,
    load         : false,
    report       : null,
  }),
  exportReport   : List([]),
  fixedDataFilter: '',
  fueling        : Map({
    detailLoad  : List([]),
    fueling     : List([]),
    indicators  : {},
    markers     : null,
    numberLoad  : 0,
    onStopReport: false,
    request     : null,
    resultLoad  : List([]),
    track       : {},
  }),
  fuelReport: Map({
    detailLoad  : List([]),
    fuelReport  : null,
    numberLoad  : 0,
    onStopReport: false,
    request     : {},
    resultLoad  : List([]),
  }),
  groupmachinery: Map({
    detailLoad    : List([]),
    groupmachinery: List([]),
    numberLoad    : 0,
    onStopReport  : false,
    request       : {},
    resultLoad    : List([]),
  }),
  machine: Map({
    detailLoad  : List([]),
    machine     : List([]),
    numberLoad  : 0,
    onStopReport: false,
    request     : {},
    resultLoad  : List([]),
    track       : null,
  }),
  performanceIndicators: Map({
    detailLoad           : List([]),
    numberLoad           : 0,
    onStopReport         : false,
    performanceIndicators: List([]),
    request              : {},
    resultLoad           : List([]),
  }),
  time: Map({
    detailLoad  : List([]),
    numberLoad  : 0,
    onStopReport: false,
    request     : {},
    resultLoad  : List([]),
    time        : List<TimeReportType[][]>([]),
  }),
  unloading: Map({
    carrierReport: null,
    load         : false,
    report       : null,
  }),
})

export type Reports = ReturnType<typeof init>

export const pushReports = (
  state: Reports,
  payload: {
    type: ReportType
    resultLoad: unknown[]
    data: unknown[]
  },
): Reports => {
  if(!state.getIn([payload.type, 'onStopReport'])) {
    if(payload.resultLoad) {
      return state
        .setIn(
          [payload.type, payload.type],
          List([
            ...state
              .getIn([payload.type, payload.type])
              .toJS(),
            payload.data,
          ]),
        )
        .setIn(
          [payload.type, 'resultLoad'],
          List([
            ...state.getIn([payload.type, 'resultLoad']).toJS(),
            payload.resultLoad,
          ]),
        )
    }

    return state.setIn(
      [payload.type, payload.type],
      List([
        ...state.getIn([payload.type, payload.type]).toJS(),
        payload.data,
      ]),
    )
  }

  return state
    .setIn([payload, payload], List([]))
    .setIn([payload, 'resultLoad'], List([]))
    .setIn([payload, 'detailLoad'], List([]))
}

export const setError = (
  state: Reports,
  payload: ReportType,
  text: string,
): Reports => {
  return state.setIn([payload, 'error'], text)
}

export const startLoadReport = (
  state: Reports,
  payload: {
    type: ReportType
    from: unknown
    to: unknown
    ids: unknown
    detail: unknown[]
  },
): Reports => {
  return state
    .setIn([payload.type, 'request'], {
      from: payload.from,
      ids : payload.ids,
      to  : payload.to,
    })
    .setIn([payload.type, 'onStopReport'], false)
    .setIn(
      [payload.type, 'numberLoad'],
      payload.detail.length,
    )
    .setIn(
      [payload.type, 'detailLoad'],
      List(payload.detail),
    )
}

export const finishLoadReport = (
  state: Reports,
  payload: ReportType,
): Reports => {
  return state
    .setIn([payload, 'resultLoad'], List([]))
    .setIn([payload, 'detailLoad'], List([]))
}

export const clearResult = (
  state: Reports,
  payload: ReportType | 'cleaning',
): Reports => {
  if(payload === 'time') {
    return state
      .setIn([payload, payload], List([]))
      .setIn([payload, 'resultLoad'], List([]))
      .setIn([payload, 'detailLoad'], List([]))
  } else if(payload === 'cleaning') {
    return state
      .setIn([payload, 'report'], null)
      .setIn([payload, 'load'], false)
  } else {
    return state
      .setIn([payload, payload], List([]))
      .setIn([payload, 'onStopReport'], true)
      .setIn([payload, 'track'], {})
      .setIn([payload, 'indicators'], {})
      .setIn([payload, 'markers'], null)
  }
}

export const pushTrackReport = (
  state: Reports,
  payload: {
    type: Exclude<ReportType, ReportType.Time>
    imei: number | string
    data: unknown
  },
): Reports => {
  return state.setIn([payload.type, 'track'], { ...state
    .getIn([payload.type, 'track']), ...{ [payload.imei]: payload.data } })
}

export const pushIndicatorsReport = (
  state: Reports,
  payload: {
    type: Exclude<ReportType, ReportType.Time>
    imei: number | string
    data: unknown
  },
): Reports => {
  return state.setIn(
    [payload.type, 'indicators'],
    { ...state
      .getIn([payload.type, 'indicators']), ...{ [payload.imei]: payload.data } },
  )
}

export const stopLoadReport = (
  state: Reports,
  payload: ReportType,
): Reports => {
  return state
    .setIn([payload, payload], List([]))
    .setIn([payload, 'onStopReport'], true)
    .setIn([payload, 'resultLoad'], List([]))
    .setIn([payload, 'detailLoad'], List([]))
}

export const clearFixedDataFilter = (state: Reports): Reports => {
  return state.set('fixedDataFilter', '')
}

export const setFixedDataFilter = (
  state: Reports,
  payload?: string,
): Reports => {
  if(payload) {
    if(payload !== state.get('fixedDataFilter')) {
      const sortParam = payload.replace('&startFilter=true', '')
      return state.set('fixedDataFilter', sortParam)
    }

    return state
  }

  return state.set('fixedDataFilter', '')
}

export const toggleCleaningReport = (state: Reports): Reports => {
  return state.setIn(
    ['cleaning', 'load'],
    !state.getIn(['cleaning', 'load']),
  )
}

export const pushCleaningReport = (state: Reports, payload: unknown): Reports => {
  return state.setIn(['cleaning', 'report'], payload)
}

export const toggleUnloadingReport = (state: Reports): Reports => {
  return state.setIn(
    ['unloading', 'load'],
    !state.getIn(['unloading', 'load']),
  )
}

export const pushUnloadingReport = (state: Reports, payload: unknown): Reports => {
  return state.setIn(['unloading', 'report'], payload)
}

export const pushUnloadingCarrierReport = (state: Reports, payload: unknown): Reports => {
  return state.setIn(['unloading', 'carrierReport'], payload)
}

export const pushReportByPath = (
  state: Reports,
  payload: {
    data: unknown
    reportPath: string[]
  },
): Reports => {
  return state.setIn(payload.reportPath, payload.data)
}

export const changeDensity = (
  state: Reports,
  payload: {
    parent: string
    index: number
    value: number
  },
): Reports => {
  const { parent, index: payloadIndex, value } = payload

  const newData = state.getIn(['cleaning', 'report'])
  newData.targets[parent].data[payloadIndex - 1].density = value

  newData.targets[parent].data[payloadIndex - 1].harvest =
        value * newData.targets[parent].data[payloadIndex - 1].volume / 1000

  const copy = JSON.parse(JSON.stringify(newData))
  return state.setIn(['cleaning', 'report'], copy)
}
