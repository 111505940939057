import { applyMiddleware, createStore,Reducer,Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import * as storage from 'redux-storage'
import thunk from 'redux-thunk'
import { GeoZone } from './geoZone/state'
import { reducers } from './index'
import { Map } from './map/state'
import { Notification } from './notification/state'
import { NotificationTasks } from './notificationTasks/state'
import { Reports } from './reports/state'
import rootSaga from './rootSaga'
import { Settings } from './settings/state'
import { IntlStateType } from './translate'
import { User } from './user/state'
import { Users } from './users/state'

const reducer = storage.reducer(reducers)
const sagaMiddleware = createSagaMiddleware()
const middleware = [thunk, sagaMiddleware]

const store: AppStore = createStore<AppStateType>(
  reducer as Reducer<AppStateType>,
  composeWithDevTools(applyMiddleware(...middleware)),
)

sagaMiddleware.run(rootSaga)

export default store

export type AppDispatch = typeof store.dispatch
export type AppStore = Store<AppStateType>

export type AppStateType = {
  geoZone: GeoZone
  Intl: IntlStateType
  map: Map
  notification: Notification
  notificationTasks: NotificationTasks
  reports: Reports
  settings: Settings
  user: User
  users: Users
}
