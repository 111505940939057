import {
  Button,
  icons,
  ModalDialog,
  ModalFooterActions,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
} from '@agdt/agrotronic-react-components'
import React, { ChangeEvent,FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { useHistory } from 'react-router-dom'
import { uploadFileToOfflineReceiver } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { TTranslate } from 'types'
import { assertNever } from 'utils/assertNever'
import { link } from '../../router'
import { getAllUnits, updateTaskStatuses } from '../../services/apiService/modules'
import { UnitCol } from './UnitCol'

type Props = {
  translate: TTranslate
}

type Unit = {
  imei: number
  typeName: string
}

type FileStructType = {
  imei: string | undefined
  name: string
  status: boolean
  typeName: string
}

type StateType = Readonly<{
  files: ReadonlyArray<FileStructType>
  loading: boolean
  selected: ReadonlyArray<File>
}>

enum ActionsEnum {
  CANCEL = 'CANCEL',
  LOADED = 'LOADED',
  PROCESSED = 'PROCESSED',
  SELECT_FILES = 'SELECT_FILES',
}

type ActionsType = {
  action: ActionsEnum.CANCEL
} | {
  action: ActionsEnum.LOADED
} | {
  action: ActionsEnum.PROCESSED
  file: FileStructType
} | {
  action: ActionsEnum.SELECT_FILES
  files: FileList | null
}

const initialState: StateType = {
  files   : [],
  loading : false,
  selected: [],
}

const reducer = function (state: StateType, payload: ActionsType): StateType {
  switch(payload.action) {
    case ActionsEnum.PROCESSED: {
      return { ...state, files: [...state.files, payload.file] }
    }

    case ActionsEnum.SELECT_FILES: {
      return { ...state, loading: true, selected: [...payload.files || []] }
    }

    case ActionsEnum.LOADED: {
      return { ...state, loading: false, selected: [] }
    }

    case ActionsEnum.CANCEL: {
      return initialState
    }

    default:
      return assertNever(payload, state)
  }
}

const Import: FC<Props> = ({ translate }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const fileRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const [units, setUnits] = useState<Unit[]>([])
  useMemo(() => getAllUnits({ limit: 0 }).then(value => setUnits(value as Unit[])), [])

  const openFiles = () => {
    if(fileRef.current) {
      fileRef.current.click()
    }
  }

  const getTypename = useCallback(imei => {
    const unit = units.find(U => U.imei === Number(imei))

    return unit?.typeName || translate('noname')
  }, [units])

  const getFileStruct = useCallback((file: File, imei: string | undefined, status: boolean): FileStructType => {
    return {
      imei,
      name    : file.name,
      status,
      typeName: getTypename(imei),
    }
  }, [getTypename])

  const handleSetFiles = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ action: ActionsEnum.SELECT_FILES, files: event.target.files })
  }

  useEffect(() => {
    (async function () {
      await Promise.all(state.selected.map(async file => {
        //routerTO_9716aa4O1d67a4a2_210701115135243.json
        const matchWialon = file.name.match(/^wialon_(?<imei>\d+)_.*/)
        const matchFromRouter = file.name.match(/^routerFROM_(?<imei>\d+)_.*/)

        if(matchFromRouter){
          if(!['application/json'].includes(file.type)) {
            errorMessage(`${translate('Error load file')} ${translate('Need file types')} zip.`)
            return
          }

          if(file) {
            try {
              await updateTaskStatuses({ statuses: JSON.parse(await file.text()) })

              dispatch({
                action: ActionsEnum.PROCESSED,
                file  : getFileStruct(file, matchFromRouter?.groups?.imei, true),
              })
            }
            catch(e) {
              dispatch({
                action: ActionsEnum.PROCESSED,
                file  : getFileStruct(file, matchFromRouter?.groups?.imei, false),
              })
            }
          }
        }

        if(matchWialon){
          if(!['application/zip', 'application/x-zip-compressed'].includes(file.type)) {
            errorMessage(`${translate('Error load file')} ${translate('Need file types')} zip.`)
            return
          }

          try {
            const result = await uploadFileToOfflineReceiver({ file, imei: matchWialon?.groups?.imei })

            dispatch({
              action: ActionsEnum.PROCESSED,
              file  : getFileStruct(file, matchWialon?.groups?.imei, !!result),
            })
          } catch(err) {
            console.error(err)

            dispatch({
              action: ActionsEnum.PROCESSED,
              file  : getFileStruct(file, matchWialon?.groups?.imei, false),
            })
          }
        }

        if(!matchWialon && !matchFromRouter) {
          errorMessage(translate('Wrong file format'))
        }
      }))

      if(state.selected.length) {
        dispatch({ action: ActionsEnum.LOADED })
      }
    })()
  }, [getFileStruct, getTypename, translate, state.selected, state.selected.length])

  const close = () => {
    history.push(link.home)
  }

  return (
    <ModalOverlay
      visible={true}
      onClose={close}
    >
      <ModalDialog>
        <ModalHeader onClose={close}>
          <StyledHeader>
            {!state.loading && state.files.length > 0 ?
              `${translate('import completed')}`
              : `${translate('Loading data')}...`
            }
          </StyledHeader>
        </ModalHeader>

        <AddPhotoBtn>
          <input
            ref={fileRef}
            type="file"
            accept=".json, .zip"
            multiple
            onChange={handleSetFiles}
          />
        </AddPhotoBtn>

        <Content
          loading={state.loading ? true : undefined}
        >
          {state.loading && <Spinner />}

          {state.files.map(({ status, imei, typeName }) =>
            <Row key={`${imei}-${typeName}`}>
              {status ? <icons.SaveIcon color="#22C38E" /> : <icons.ErrorIcon color="#B82D2D" />}
              <UnitCol serial={imei}>{typeName}</UnitCol>
            </Row>,
          )}
        </Content>

        <ModalFooterActions>
          <Spacer size={270} horizontal />
          {state.loading
            && <ButtonStyled width="112" variant="border" onClick={close}>{translate('Cancel')}</ButtonStyled>
          }

          {!state.loading && !state.files.length && <ButtonStyled
            width="170px"
            onClick={openFiles}
          >{translate('Open')}</ButtonStyled>}

          {!state.loading
            && state.files.length > 0 && <ButtonStyled width="70" onClick={close}>{translate('OK')}</ButtonStyled>
          }
        </ModalFooterActions>
      </ModalDialog>
    </ModalOverlay>
  )
}

export default withTranslate(Import)


const ButtonStyled = styled(Button)`
  min-width: ${({ width }) => width};
`

const Row = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Content = styled.div<{loading?: boolean}>`
  width: 100%;
  min-width: 555px;
  min-height: 140px;
  display: flex;
  justify-content: ${({ loading }) => loading ? 'center' : 'normal'};
  flex-direction: column;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
`


const StyledLabel = styled.label`
  cursor: pointer;

  input {
    position: absolute;
    width: 100%;
    opacity: 0;
  }
`

const AddPhotoBtn = styled(StyledLabel)`
  left: 55px;
`
